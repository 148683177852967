<script>
  import "./AddNewExercise.scss";
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher, tick } from "svelte";
  import { exercises, user, userData } from "../../../lib/store";
  import { getExercises, getExercisesList, maxPage, saveExercise } from "../../../services/exercisesServices";
  import Chip, { Text } from "@smui/chips";
  import Dialog, { Content } from "@smui/dialog";
  import ExerciseDetail from "../../Exercises/ExerciseDetail.svelte";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import IconButton from "@smui/icon-button";
  import List, { Item, Label } from "@smui/list";
  import MenuSurface from "@smui/menu-surface";
  import SvelteTooltip from 'svelte-tooltip';
  
  export let dirtyExercise = false;
  export let draggable = true;
  export let exerciseSelected;
  export let inputLabel = `+ ${$_("aggiungi_esercizio")}`;
  export let newExerciseField = false;
  export let search = "";

  const dispatch = createEventDispatcher();
  const triggerCloseImageDialog = false;
  const triggerCloseVideo = false;

  let addButtonClicked = false;
  let exercisesDialogClicked = false;
  let exitDialogOpened = false;
  let filtered = [];
  let formSurface;
  let formSurfaceEnabled = false;
  let nameNewExInvalid = false;
  let newExerciseDialog;
  let selectedExercise;
  let showInfoIcon = true;
  let tagsFiltered = [];

  $: filtered = $userData.show_base_exercises 
    ? $exercises.filter(e => e.is_active === 1).slice(0, 5) 
    : $exercises.filter(e => e.is_active === 1 && e.base === 0).slice(0, 5);

  $: showInfoIcon = selectedExercise.id && search == selectedExercise.name && selectedExercise.is_active;

  $: if (!search || search) { 
    if (!search || search != selectedExercise.name) { 
      dispatch("fieldEmpty");
      draggable = false;
      // initSelectedExercise();
    } else {
      dispatch("fieldFilled");
      draggable = true;
    }
  };

  $: if (draggable) checkDirty();
  $: dispatch("dirtyExercise", { dirtyExercise });

  const checkDirty = () => {
    if (!newExerciseField || (newExerciseField && search != '')) {
      dirtyExercise = !draggable;
    } else if (newExerciseField && search.length == 0) {
      dirtyExercise = false;
    }
  }

  const initSelectedExercise = () => {
    selectedExercise = {
      id: "",
      name: "",
      base: "",
      note: "",
      link: "",
      tagsSelected: [],
      image: "",
      image_thumbnail: "",
      is_active: "",
      video: "",
      video_thumbnail: "",
      video_youtube: "",
    };
  };
  initSelectedExercise();

  $: if (exerciseSelected) {
    const temp = $exercises.find(
      (exercise) => exercise.id == exerciseSelected.id
    );
    if (temp) {
      //-----------------------//
      formSurfaceEnabled = false;
      //-----------------------//
      selectedExercise = {
        ...temp,
        tagsSelected: temp.tags ? temp.tags : [],
        link: temp.video_youtube || "",
      };
      selectedExercise.note = selectedExercise.note
        ? selectedExercise.note
        : "";
      delete selectedExercise.tags;
    }
  }

  $: nameNewExInvalid = search.length < 3;

  $: filterExercises(search);

  const filterExercises = (search) => {
    if (!search) {
      filtered = $userData.show_base_exercises 
        ? $exercises.filter(e => e.is_active === 1).slice(0, 5) 
        : $exercises.filter(e => e.is_active === 1 && e.base === 0).slice(0, 5);
      return;
    }

    const exercisesFilteredByBase = $userData.show_base_exercises 
      ? $exercises.filter(e => e.is_active === 1)
      : $exercises.filter(e => e.is_active === 1 && e.base === 0);

    let arraySearchLCase = [];
    let checkMatch = 0;
    
    const searchLCase = search.toLowerCase();
    const exactMatch = exercisesFilteredByBase.filter((exercise) => {
      if (exercise.name.toLowerCase() == searchLCase.trim()) {
        return true;
      }
    });
    const filteredExercises = exercisesFilteredByBase.filter((exercise) => {
      checkMatch = 0;
      arraySearchLCase = searchLCase.trim().split(' ');
      for (let search of arraySearchLCase) {
        // Nel controllo viene esclusa l'uguaglianza, perché già gestita dal metodo exactMatch()
        if (
          exercise.name.toLowerCase().includes(search) && 
          exercise.name.toLowerCase() != searchLCase.trim()
        ) {
          checkMatch++;
        }
      }

      if (arraySearchLCase.length === checkMatch) {
        return true;
      }
    });

    filtered = exactMatch.length > 0 
      ? [...exactMatch, ...filteredExercises].slice(0, 5)
      : filteredExercises.slice(0, 5);
  };

  const selectItem = async (ex) => {
    search = ex.name;
    exerciseSelected = ex;
    formSurface.setOpen(false);
    formSurfaceEnabled = false;
    nameNewExInvalid = false;
    dispatch("exerciseSelected", { exercise: ex });
    filtered = $userData.show_base_exercises 
      ? $exercises.filter(e => e.is_active === 1) 
      : $exercises.filter(e => e.is_active === 1 && e.base === 0);
    initSelectedExercise();
    search = "";
    draggable = true;
    checkDirty();
  };

  const handleExerciseSubmit = async (ev) => {
    newExerciseDialog.close();
    //await fetchExercises(true);
    if (ev.detail.id) {
      $exercises = $exercises.map((e) =>
        e.id == ev.detail.id ? ev.detail.res : e
      );
      const temp = ev.detail.res;
      if (temp) {
        selectedExercise = {
          ...temp,
          tagsSelected: temp.tags ? temp.tags : [],
          link: temp.video_youtube || "",
        };
        selectedExercise.note = selectedExercise.note
          ? selectedExercise.note
          : "";
        delete selectedExercise.tags;
      }
      dispatch("exerciseSelected", { exercise: ev.detail.res });
      initSelectedExercise();
    }
    draggable = true;
  };

  const exerciseAlreadyExists = (name) => {
    let matchesPtExercises = $exercises.filter(e => e.is_active === 1 && e.base === 0).filter(i => 
      i.name.toLowerCase().trim() === name.toLowerCase().trim()
    );
    
    let matchesBaseExercises = $exercises.filter(e => e.is_active === 1 && e.base === 1).filter(i => 
      i.name.toLowerCase().trim() === name.toLowerCase().trim()
    );

    return matchesPtExercises.length > 0 || matchesBaseExercises.length > 1;
  };

  const newExerciseClick = async () => {
    try {
      addButtonClicked = true;
      if (exerciseAlreadyExists(search)) nameNewExInvalid = true;
      if (nameNewExInvalid) return;
      const body = {
        name: search,
        base: 0,
      };
      exerciseSelected = await saveExercise(body);
      formSurface.setOpen(false);
      formSurfaceEnabled = false;
      dirtyExercise = false;
      nameNewExInvalid = false;
      dispatch("exerciseSelected", { exercise: exerciseSelected });
      initSelectedExercise();
      addButtonClicked = false;
      search = "";
      draggable = true;
      await fetchExercises(true);
    } catch (err) {
      console.log("submit exercise error", err);
    }
  };

  const handleOpenExerciseDialog = async () => {
    exercisesDialogClicked = true;
    await tick();
    newExerciseDialog.open();
  };

  const handleKeyUp = async () => {
    formSurfaceEnabled = true;
    await tick();
    formSurface.setOpen(true);
    checkDirty();
  };

  const handleClick = async () => {
    if (!draggable && search.length > 0) {
      formSurfaceEnabled = true;
      await tick();
      formSurface.setOpen(true);
    }
  };

  const handleFocusOut = async () => {
    if (newExerciseField) {
      setTimeout(() => {
        search = '';
        checkDirty();
      }, 300);
    } else {
      checkDirty();
    }
  };

  const fetchExercises = async (updateStoreValue = true) => {
    try {
      let base = $user.is_admin ? 2 : 0;
      // return await getExercises(updateStoreValue, base);
      return await getExercisesList(true);
    } catch (err) {
      console.log("getExercises err", err);
      dispatch("error", {});
    }
  };

  const openExitDialog = () => {
    exitDialogOpened = true;
  };

  const handleCloseExitDialog = () => {
    exitDialogOpened = false;
  };

  const handleGoBack = () => {
    exitDialogOpened = false;
    newExerciseDialog.close();
  };
</script>

<style>
  * :global(.mdc-chip) {
    background-color: black;
    color: white;
  }
  .header {
    align-items: center;
    background-color: #f9f9f9;
    justify-content: space-between;
  }
  .title {
    font-size: 1.25rem;
  }

  .label {
    font-size: 0.8rem;
    font-weight: 500;
    opacity: 0.7;
  }

  :global(.mdc-text-field.textfield--large) {
    min-height: 3.5rem !important;
  }

  :global(.combinationsWrapper) :global(.mdc-icon-button) {
    padding-top: 3px !important;
  }

  .dirtyInput {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
  }

  * :global(.selectExerciseBtn--item img) {
    width: 1rem;
  }

  * :global(.selectExerciseBtn--item label) {
    margin-bottom: 0;
  }

  * :global(.tooltip) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>

{#if exercisesDialogClicked}
  <Dialog
    bind:this={newExerciseDialog}
    aria-labelledby="dialog-for-new-exercise"
    aria-describedby="new-exercise"
    class="dialog--small no-click-backdrop">
    <Content class="px-0 pt-0">
      <div class="header p-4 row no-gutters">
        <div class="title bold">{$_('dettagli_esercizio')}</div>
        <IconButton class="material-icons" on:click={openExitDialog}>
          close
        </IconButton>
      </div>
      <ExerciseDetail
        {triggerCloseImageDialog}
        {triggerCloseVideo}
        {exitDialogOpened}
        on:closeExitDialog={handleCloseExitDialog}
        exercise={selectedExercise}
        on:goBack={handleGoBack}
        on:onSubmit={handleExerciseSubmit} />
    </Content>
  </Dialog>
{/if}

<span class={`label ${dirtyExercise && !newExerciseField ? 'dirtyInput' : ''}`}>{inputLabel}</span>
{#if nameNewExInvalid && addButtonClicked && search && search != ''}
  <FormMessage>
    {search.length > 2 ? $_('esiste_già_un_esercizio_con_questo_nome') : $_('il_nome_deve_essere_di_almeno_3_caratteri')}
  </FormMessage>
{/if}
<label
  class="mdc-text-field w100 mdc-text-field--outlined mdc-text-field--no-label"
  aria-haspopup="true"
  aria-expanded="false">
  <input
    type="text"
    on:click={handleClick}
    on:keyup={handleKeyUp}
    on:focusout={handleFocusOut}
    maxlength="191"
    placeholder={$_('cerca_per_nome_o_tag')}
    bind:value={search}
    class={`mdc-text-field__input add-new-exercise__input ${dirtyExercise && !newExerciseField ? 'dirtyInput' : ''}`} />
  {#if showInfoIcon}
    <IconButton
      class="material-icons exerciseInfoBtn"
      on:click={handleOpenExerciseDialog}>
      info
    </IconButton>
  {/if}
  <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
    <div
      class={`mdc-notched-outline__leading ${dirtyExercise && !newExerciseField ? 'dirtyInput' : ''}`} />
    <div class="mdc-notched-outline__notch" />
    <div
      class={`mdc-notched-outline__trailing ${dirtyExercise && !newExerciseField ? 'dirtyInput' : ''}`} />
  </div>
</label>

{#if formSurfaceEnabled}
  <MenuSurface
    class="menuSurfaceCustom"
    bind:this={formSurface}
    anchorCorner="BOTTOM_LEFT">
    <List class="list">
      {#each filtered as ex}
        <div class="selectExerciseBtn" on:click={selectItem(ex)}>
          <Item class="d-flex justify-content-between selectExerciseBtn--item">
            <Label>{ex.name}</Label>
            {#if ex.base} 
              <SvelteTooltip tip="{$_('esercizio_revoo')}" left >
                <img src="r_logo.png" alt="R" class="" />
              </SvelteTooltip>
            {/if}
          </Item>
        </div>
      {/each}
      {#if (exerciseAlreadyExists(search) == false) && search.length >= 3}
        <div class="addExerciseBtn" on:mousedown={newExerciseClick}>
          <Item>
            <Chip class="newExerciseBtn">
              <Text>{`${$_('aggiungi')} '${search}'`}</Text>
            </Chip>
          </Item>
        </div>
      {/if}
    </List>
  </MenuSurface>
{/if}
