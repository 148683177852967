<script>
	import { _ } from "../../../services/i18n";
	import { getExpiringTrainingCards } from "../../../services/dashboardServices";
    import { onMount } from "svelte";
	import DashboardListWrapper from "../../ReusableLayouts/DashboardListWrapper.svelte";

	let attribute = $_("piano");
	let emptyMessage = $_("non_ci_sono_piani_di_allenamento_in_scadenza");
	let list = [];
	let loading = true;
	let title = $_("piani_allenamento");

	const handleUpdateList = async (e) => {
		loading = true;
		emptyMessage = e.detail.range == "next_7" || e.detail.range == "next_14"
			? $_("non_ci_sono_piani_di_allenamento_in_scadenza")
			: $_("non_ci_sono_piani_di_allenamento_scaduti");
		list = await getExpiringTrainingCards(e.detail.range);
		loading = false;
	}

	onMount(async () => {
		list = await getExpiringTrainingCards();
		loading = false;
	});
</script>

<div class="w100 pr-lg-4">
	<DashboardListWrapper {attribute} {list} {title} {loading} {emptyMessage} on:updateList={handleUpdateList} />
</div>
  
<style>
</style>