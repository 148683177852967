<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { 
    getInvitationCode, 
    resetInvitationCode, 
    updateDefaultCustomerActive 
  } from "../../services/teamServices.js";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import { user } from "../../lib/store";
  import Button, { Label } from "@smui/button";
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import LoadingInline from "../ReusableLayouts/LoadingInline.svelte";
  import SvelteTooltip from 'svelte-tooltip';
  import Switch from "@smui/switch";

  export let atGroupId = null;
  export let atGroupName = null;
  export let openDialog;  
  export let redirect = false;
  
  const dispatch = createEventDispatcher();

  let teamInvitationCode = {};
  let temporaryCode = false;
  let defaultActiveCustomer;
  let dialog;
  let textActive = $_('codice_invito_clienti_default_attivi');
  let textNotActive = $_('codice_invito_clienti_default_non_attivi');

  $: {
    if (openDialog == true) {
      dialog.open();
      getCode();
    }
    if (dialog && dialog.isOpen()) {
      dispatch("closeInvitationDialog");
    }
  }

  const getCode = async () => {
    teamInvitationCode = await getInvitationCode(atGroupId);
    defaultActiveCustomer = teamInvitationCode.default_active_customer;
  }

  const resetCode = async (code) => {
    temporaryCode = teamInvitationCode.code;
    teamInvitationCode = await resetInvitationCode(code);
    await tick();
    await getCode();
    temporaryCode = false;
  }

  const copyInvitationCode = () => {
    let elementId = "invitationCodeLabel-" + !temporaryCode ? teamInvitationCode.code : temporaryCode;
    let element = document.getElementById(elementId);
    let elementText = element.textContent;
    navigator.clipboard.writeText(elementText);
  }

  const handleSwitchChange = async () => {
    defaultActiveCustomer === true ? false : true;
    await updateDefaultCustomerActive(teamInvitationCode.code, defaultActiveCustomer);
    await getCode();
  }

  onMount(async () => {
    teamInvitationCode.code = $user.team_invitation_code && $user.team_invitation_code != null ? $user.team_invitation_code : null;
  });

</script>

<style>
  h5 {
    padding: 0;
  }
  * :global(.defaultActiveSwitch) {
    min-height: 100px;
  }
  * :global(.invitationCode) { 
    color: #000000;
  }
  * :global(.material-icons) {
    font-size: 1.8rem;
  }
  * :global(.invitationActions) {
    padding-bottom: 8px;
  }
  * :global(.mdc-dialog__title + .mdc-dialog__content) {
    padding: 0px 24px;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  * :global(.mdc-dialog__title) {
    display: flex;
    padding: 10px 39px;
    background-color: #f9f9f9;
    opacity: 0.8;
  }
  * :global(.whatsAppButton) {
    height: 50px;
    background-color: #22D366;
    color: white;
    border: 1px solid #ebeaea;
  }
  * :global(.mdc-text-field__icon--trailing.customLabel) {
    color: #212121;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    opacity: 0.5;
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }

  * :global(.spinner) {
    width: 2rem;
    height: 2rem;
  }

  @media (max-width: 767px) {
    * :global(.mdc-dialog__content) {
      max-height: fit-content;
      flex-grow: 0;
    }
  }
</style>

<div on:click|stopPropagation>
  <Dialog class="dialog--xsmall invitationDialog" bind:this={dialog}>
    <Title class="d-flex">
      <h5 class="bold pt-3" style="flex-grow: 1;">
        {atGroupName === null ? $_('invita_nuovi_clienti') : $_('invita_nuovi_clienti_al_gruppo') + ' ' + atGroupName}
      </h5>
      <span on:click|stopPropagation={() => {
          if (redirect === false) {
            dialog.close();
            dispatch('refreshAtGroupCode');
          } else {
            dialog.close();
            navigate(redirect);
          }
        }}>
        <IconButton class="material-icons">
          close
        </IconButton>
      </span>
    </Title>
    <Content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 py-4">
            {atGroupName === null ? $_('codice_invito_label') : $_('codice_invito_label_gruppo') + ' ' + atGroupName + '.'}
          </div>
        </div>
        <div class="row py-1 d-flex align-items-center">
          <div class="col-12 col-md-3 text-center text-md-left">
            {#if !temporaryCode && teamInvitationCode.code && teamInvitationCode.code.length != 6} 
              <LoadingInline />
            {:else}
              <h3 class="invitationCode my-0">
                <strong id={`invitationCodeLabel-` + !temporaryCode ? teamInvitationCode.code : temporaryCode}>
                  {!temporaryCode ? teamInvitationCode.code : temporaryCode}
                </strong>
              </h3>
            {/if}
          </div>
          <div class="col-12 col-md-9 text-center text-md-left">
            <div class="d-block d-md-none invitationActions">
              <IconButton
                class="material-icons"
                on:click={(e) => {
                  copyInvitationCode(teamInvitationCode.code);
                  e.stopPropagation();
                }}>
                content_copy
              </IconButton>
              <IconButton
                class="material-icons"
                on:click={(e) => {
                  resetCode(teamInvitationCode.code);
                  e.stopPropagation();
                }}>
                refresh
              </IconButton>
              <IconButton
                class="material-icons"
                on:click={(e) => {
                  window.open('http://how.revoo-app.com/knowledge/come-funziona-il-codice-invito-per-i-nuovi-clienti', '_blank');
                  e.stopPropagation();
                }}>
                help
              </IconButton>
            </div>
            <div class="invitationActions d-none d-md-block">
              <SvelteTooltip tip="{$_('copia_negli_appunti')}" top >
                <IconButton
                  class="material-icons"
                  on:click={(e) => {
                    copyInvitationCode(teamInvitationCode.code);
                    e.stopPropagation();
                  }}>
                  content_copy
                </IconButton>
              </SvelteTooltip>
              <SvelteTooltip tip="{$_('rigenera_codice_invito')}" top >
                <IconButton
                  class="material-icons"
                  on:click={(e) => {
                    resetCode(teamInvitationCode.code);
                    e.stopPropagation();
                  }}>
                  refresh
                </IconButton>
              </SvelteTooltip>
              <SvelteTooltip tip="{$_('help')}" top >
                <IconButton
                  class="material-icons"
                  on:click={(e) => {
                    window.open('http://how.revoo-app.com/knowledge/come-funziona-il-codice-invito-per-i-nuovi-clienti', '_blank');
                    e.stopPropagation();
                  }}>
                  help
                </IconButton>
              </SvelteTooltip>
            </div>
          </div>
        </div>
        <!-- <div class="row py-1 d-flex align-items-center">
          <div class="col-12">
            <Button
              class="primaryButton whatsAppButton btn d-flex align-items-center"
              variant="unelevated"
              style=""
              on:click={() => dialog.close()}>
              <Icon
                role="button"
                class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing buttonIcon
                       pr-2">
                textsms
              </Icon>
              <Label>Invita con WhatsApp</Label>
            </Button>
          </div>
        </div> -->
        {#if !env.isProd}
          <hr class="line" />  
          <div class="row py-1 d-flex align-items-center defaultActiveSwitch">
            <div class="col-9 col-sm-10 label" style="flex-grow: 1;">{defaultActiveCustomer ? textActive : textNotActive}</div>
            <div class="d-block d-lg-none col-2 d-flex justify-content-end" on:click|stopPropagation>
              <Switch 
                on:change={handleSwitchChange}
                bind:checked={defaultActiveCustomer} 
              />
            </div>
            <div class="d-none d-lg-block pl-lg-3 ml-lg-4" on:click|stopPropagation>
              <Switch 
                on:change={handleSwitchChange}
                bind:checked={defaultActiveCustomer} 
              />
            </div>
          </div>
        {/if}
      </div>
      <hr />
    </Content>
  </Dialog>
</div>
