<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { customer } from "../../../../lib/store";
	import { deleteCustomerSubscriptionInfo } from "../../../../services/customerServices";
	import { navigate } from "svelte-routing";
	import CustomerSubscriptionActions from "./CustomerSubscriptionActions.svelte";
	import CustomerSubscriptionInfo from "./CustomerSubscriptionInfo.svelte";
	import CustomerSubscriptionPanel from "./CustomerSubscriptionPanel.svelte";
	import Card, { Content } from "@smui/card";
	
	export let customerId;
	export let firstCustomerSubscriptions = [];

	const dispatch = createEventDispatcher();

	let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
	let panels = [];
	let showMobileIcons;
	let withoutErasedEmptyFilteredSubscriptions = [];

	$: if (firstCustomerSubscriptions) {
		panels = new Array(firstCustomerSubscriptions.length).fill(false);
		withoutErasedEmptyFilteredSubscriptions = firstCustomerSubscriptions.filter((s) => {
			if (!s.is_active)
				return;
			else return s;
		});
	}

	const openCustomerSubscription = async (customerSubscription) => {
		if ($customer.active && customerSubscription.is_active) {
			navigate(`/customer-subscription?tab=info&customerSubscriptionId=` + customerSubscription.id + "&customerId=" + customerId, {
				replace: true,
				state: {
					customerId,
					customerSubscription,
				},
			});
		}
	};

	const handleEditButtonClicked = (e) => {
		openCustomerSubscription(e.detail.customerSubscription);
	}

	const handleDeleteCustomerSubscription = async ({ detail }) => {
		const { customerSubscription, is_active } = detail;
		if ($customer.active && is_active) {
			try {
				await deleteCustomerSubscriptionInfo(customerSubscription.id)
				dispatch("removeCustomerSubscription", { customerSubscription });
			} catch (err) {
				console.log("delete error", err);
				dispatch("onError", {});
			}
		}
	};
</script>

<style>
	.spaceBetween {
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}
</style>

{#each withoutErasedEmptyFilteredSubscriptions as customerSubscription, i}
	<Card class="mb-3">
		<Content>
			<div 
				class="row px-2 spaceBetween"
				on:click={() => { panels[i] = !panels[i] }}>
				<CustomerSubscriptionInfo 
					on:updateDraftTrainingCard 
					{customerSubscription} 
					{isMobile} 
					/>
				<CustomerSubscriptionActions
					on:deleteAssignment={handleDeleteCustomerSubscription}
					on:editButtonClicked={handleEditButtonClicked}
					bind:showMobileIcons
					{customerSubscription}
					{customerId}
					{isMobile}
					index={i} />
			</div>
		</Content>
		{#if isMobile}
			<CustomerSubscriptionPanel
				{customerId} 
				{customerSubscription}
				{isMobile}
				{panels}
				index={i} />
		{/if}
	</Card>
{/each}
