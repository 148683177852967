<script>
  import { _ } from "../../services/i18n";
  import Button, { Label } from "@smui/button";
  import Switch from "@smui/switch";
  import Textfield, { Input } from "@smui/textfield";
  import { notificationStatus } from "../../lib/store";
  import { updateNotifications } from "../../services/userServices";

  let msgError;
  let mobileEnabled;

  try { 
    if ($notificationStatus.mobile.enabled === true ||
        $notificationStatus.mobile.enabled === false) {
      mobileEnabled = $notificationStatus.mobile.enabled;
    }
  } catch (e) {
    console.log(e)
  }
  
  let mobileNotifications = $notificationStatus.mobile.details;

  let isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  const submit = async () => {
    try {
      const notifications = {
        mobileEnabled: mobileEnabled ? true : false,
        details: mobileNotifications,
      };
      const res = await updateNotifications(notifications);
      $notificationStatus.mobile.enabled = res.mobile.enabled;
      $notificationStatus.mobile.details = res.mobile.details;
    } catch (err) {
      console.log("err updateNotifications", err);
      displayError();
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

</script>

<style>
  .label {
    text-align: end;
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
  }
  .textOpacity {
    opacity: 0.8;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  * :global(.mdc-text-field--outlined .mdc-text-field__input) {
    padding: 0px 5px 0px !important;
  }
  * :global(.mdc-text-field--outlined) {
    height: 30px !important;
  }
  * :global(.mdc-text-field__input) {
    text-align: center;
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
</style>

<div class="py-3">
  <div class="row">
    <div class="col-12 py-4">
      <span class="textOpacity">
        {$_('notifiche_push_beta')}
      </span>
    </div>
    <div class="col-9 col-md-6 col-lg-4 label">Notifiche Push</div>
    <div class="col-3 col-md-6 col-lg-8">
      <Switch bind:checked={mobileEnabled} />
    </div>
  </div>

  <hr />

  <div class="row pushToEnd">
    <Button
      class="btn ml-5 mr-3"
      variant="unelevated"
      on:click={submit}>
      <Label>{$_('salva')}</Label>
    </Button>
  </div>
  
  <div class="row">
    <div class="col-12 py-4">
      <span class="textOpacity">
        {$_('notifiche_attivare_disattivare')}
      </span>
    </div>
  </div>

  {#each Object.values(mobileNotifications) as notification (notification.id)}
    <div class="row py-2">
      <div class="col-9 col-md-6 col-lg-4 label">
        <span>
          {notification.label}
        </span>
        {#if notification.label == "Piano di allenamento in scadenza" && notification.parameter && notification.parameter != null}
          <div>
            <span class="pr-1">
              (notifica
            </span>
            <Textfield
              class="w10"
              variant="outlined"
              input$min="0"
              input$max="31"
              input$step="1"
              bind:value={notification.parameter}
              type="number" />
            <span class="pl-1">
              giorni prima)
            </span>
          </div>
        {/if}
      </div>
      <div class="col-3 col-md-6 col-lg-8 d-flex align-items-center">
        <Switch 
          disabled={!mobileEnabled}
          bind:checked={notification.enabled} 
        />
      </div>
    </div>
  {/each}
  
  <hr />

  {#if msgError}
    <div class="alert alert-danger fade show" role="alert">{msgError}</div>
  {/if}

  <div class="row pushToEnd">
    <Button
      class="btn ml-5 mr-3"
      variant="unelevated"
      on:click={submit}>
      <Label>{$_('salva')}</Label>
    </Button>
  </div>
</div>
