<script>    
    import { _ } from "../../services/i18n";

    export let totalViews = 0;

    let max = 50;
    let progress = 0;

    $: if (!isNaN(totalViews) && totalViews > 0) {
        if      (totalViews <= 50)  max = 50;
        else if (totalViews <= 150) max = 150;
        else if (totalViews <= 300) max = 300;
        else if (totalViews <= 600) max = 600;
        else                        max = 3000;
        progress = Math.floor(totalViews * 100 / max).toFixed(2);
    } else {
        totalViews = 0;
    }
</script>

<span class="title__label">
    {$_('visualizzazioni_mensili')}: {totalViews ?? 0} {max <= 600 ? '/ ' + max : ``}
</span>

<div class="progress mt-1">
    <div
      class="progress-bar"
      role="progressbar"
      style={`width: ${progress}%; background-color: ${totalViews <= 50 ? `#212121` : `#0CD2C8`}`}
      aria-valuenow="{progress}"
      aria-valuemin="0"
      aria-valuemax="{max}" />
</div>

<style>
    .progress {
        width: 18em;
    }
</style>