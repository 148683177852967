<script>
    import { _ } from "svelte-i18n";
    import Card, { Content } from "@smui/card";
    import Button, { Label, Icon } from "@smui/button";
    import IconButton from "@smui/icon-button";
    import SectionTitle from "../SectionTitle.svelte";
    import { copyToClipboard } from "../../../../lib/utils";
    import "../BillingOverview.scss";

    export let referral_code;
    export let balance;
</script>

<style>
      .importantInfoBold {
    font-weight: bold;
    font-size: 1.7em;
  }
    .importantInfo {
        font-size: 1.7em;
    }
    .justFlex {
        display: flex;
    }
</style>

<Card>
    <Content class="customCardContent h100">
        <SectionTitle text={$_('credito_e_codici_sconto')} />
        <div class="row mb-2">
            <p>
                <span class="col-12 importantInfo justFlex">
                    {`${$_('credito_residuo')}: ${balance} ${$_('euro')} `}
                    <button
                        class="btn btnTooltip mdc-ripple-upgraded"
                        data-tooltip={$_('questo_è_il_credito')}>
                        <Icon class="material-icons">info</Icon>
                    </button>
                </span>
            </p>
            {#if referral_code}
                <span class="col-12">
                    {`${$_('referral_code_personale')}`}
                </span>
                <span class="col-12 importantInfoBold">
                    {referral_code}
                    <IconButton
                        on:click={() => copyToClipboard(referral_code)}
                        class="material-icons">
                        content_copy
                    </IconButton>
                </span>
            {:else}
                <span
                    class="col-12">{`${$_('non_hai_codici_sconto_attivi')}`}</span>
            {/if}
        </div>
        <div style="text-align:end" class="mt-2">
            <!-- <Button variant="unelevated" on:click>
                <Label>{$_('scopri_di_piu')}</Label>
            </Button> -->
        </div>
    </Content>
</Card>
