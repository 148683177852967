<script>
  import { onMount, onDestroy } from "svelte";
  import "@splidejs/splide/dist/css/splide.min.css";
  import Splide from "@splidejs/splide";
  import { groups } from "./store";

  export let autoHideArrows;
  export let autoHideArrowsOffset = 0;
  export let currentIndex;
  export let group;
  export let splideOptions;
  
  const SYNC_EVENT = "move.customsync";
  
  let splide;
  let element;

  $: if (splideOptions) {
    splide && splide.refresh();
  }

  $: if (splide && splideOptions && splideOptions.arrows && autoHideArrows) {
    checkLeftArrow(currentIndex == 0);
    checkRightArrow(currentIndex >= splide.length - 1 - autoHideArrowsOffset);
  }

  $: if (splide && group) {
    if (!$groups[group] || !$groups[group].includes(splide)) {
      $groups[group] = $groups[group] ? [...$groups[group], splide] : [splide];
    }

    splide.off(SYNC_EVENT).on(SYNC_EVENT, moveGroup);
  }

  function moveGroup(newIndex, prevIndex, destIndex) {
    $groups[group].map((el) =>
      el
        .off(SYNC_EVENT)
        .go(el.is("loop") ? destIndex : newIndex, false)
        .on(SYNC_EVENT, moveGroup)
    );
  }

  function checkLeftArrow(hide) {
    const arrow = element.querySelector(
      "button.splide__arrow.splide__arrow--prev"
    );
    arrow.style.display = hide ? "none" : "block";
  }

  function checkRightArrow(hide) {
    const arrow = element.querySelector(
      "button.splide__arrow.splide__arrow--next"
    );
    arrow.style.display = hide ? "none" : "block";
  }

  onMount(() => {
    splide = new Splide(element, splideOptions);

    splide.on("move", (newIndex, oldIndex, destIndex) => {
      currentIndex = splide.is("loop") ? destIndex : newIndex;
    });

    splide.mount();

    currentIndex = splide.index;
  });

  onDestroy(() => {
    if (group) {
      $groups[group] = $groups[group].filter((el) => el != splide);
    }

    splide && splide.destroy();
  });
</script>

<svelte:options accessors />

<div class="splide" bind:this={element}>
  <div class="splide__track">
    <slot />
  </div>
</div>
