export const validateWorkouts = (workouts) => {
  let i = 0;
  const validatedWorkouts = workouts.map((w) => {
    if (!w.workoutName) w.workoutName = `Scheda ${i}`;
    i++;
    const copyW = Object.assign({}, w);
    for (let comb of copyW.combinations) {
      if (comb.method == "time_circuit")
        comb.supersets = formatTime(comb.supersets);
      else if (comb.method == "superset" || comb.method == "circuit") {
        comb.supersets = parseInt(comb.supersets) || 1;
        comb.supersets = validateCombinationValues(comb.supersets, 'sets');
      } else if (comb.method == "emom") {
        comb.emom_time = formatTime(comb.emom_time);
        comb.emom_sets = parseInt(comb.emom_sets) || 1;
        comb.emom_sets = validateCombinationValues(comb.emom_sets, 'sets');
      }
      for (let ex of comb.exercises) {
        for (let detail of ex.details) {
          if (comb.method == "normal") {
            if (detail.sets === 0 || detail.sets === '' || detail.sets === null) {
              detail.sets = 1;
            }
          }

          if (detail.reps.value === undefined) {
            detail.reps.value = "";
          }

          if (detail.weight.value === undefined) {
            detail.weight.value = "";
          }

          if (detail.reps.format == "max") {
            detail.reps.value = "";
          } 
          if (detail.reps.type == "time") {
            detail.reps.value = formatTime(detail.reps.value);
          } 
          if (detail.reps.format != "range") {
            detail.reps.value_high = "";
          }
          if (detail.weight.format == "max") {
            detail.weight.value = "";
          }
          // if (detail.weight.value === undefined) {
          //   detail.weight.value = "";
          // }
          if (detail.weight.value < 0 && detail.weight.format == "%") {
            detail.weight.value = Math.abs(detail.weight.value);
          }
          // Validate Combination Values
          detail.reps.value        = validateCombinationValues(detail.reps.value, detail.reps.type);
          detail.reps.value_high   = validateCombinationValues(detail.reps.value_high, detail.reps.type);
          detail.weight.value      = validateCombinationValues(detail.weight.value, detail.weight.type);
          detail.weight.value_high = validateCombinationValues(detail.weight.value_high, detail.weight.type);

          detail.rest = formatTime(detail.rest);
        }
      }
    }
    return copyW;
  });
  return validatedWorkouts;
};

const formatTime = (time) => {
  if (!time || typeof time != "string") return "00:00";
  let [minutes, seconds] = time.split(":");
  if (!minutes) minutes = "00";
  else if (minutes.length == 1) minutes = `0${minutes}`;
  if (!seconds) seconds = "00";
  else if (seconds.length == 1) seconds = `${seconds}0`;
  return `${minutes}:${seconds}`;
};

export const validateCombinationValues = (value, type) => {
  if (type != undefined) {
    switch (type.toLowerCase()) {
      // Sets
      case 'sets'   : return setsValidator(value, 0, 999);
      // Reps
      case 'reps'   : return validator(value, 0, 9999);
      case 'kcal'   : return validator(value, 1, 9999);
      case 'km' 	  : return validator(value, 1, 999);
      case 'meters' : return validator(value, 0, 10000);
      // Weight
      case 'kg'	    : return validator(value, 0, 9999);
      case 'buffer' : return validator(value, 0, 9999);
      case 'km/h'   : return validator(value, 0, 999);
      case 'level'  : return validator(value, 1, 100);
      case 'm/s' 	  : return validator(value, 1, 999);
      case 'min/km' : return validator(value, 0, 10.00);
      case 'rm' 	  : return validator(value, 0, 10000);
      case 'rpe' 	  : return validator(value, 0, 10000);
      case 'watt'   : return validator(value, 1, 9999);
      // Default
      default 	    : return validator(value, 0, 9999);
    }
  }

  return value;
}

const validator = (value, min, max) => {
  if (value < min) return min;
  if (value > max) return max;

  return value;
}

const setsValidator = (value, min, max) => {
  if (value <= min) return '';
  if (value > max) return max;

  return value;
}