<script>
  import { onDestroy, onMount } from "svelte";
  import { _ } from "../../../services/i18n";
  import { navigate } from "svelte-routing";
  import Dialog from "@smui/dialog";
  import VodAssignmentHeader from "./VodAssignmentHeader.svelte";
  import VodAssignmentDetail from "./VodAssignmentDetail.svelte";
  import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
  import { activeAssignment } from "../../../lib/store";
  import { saveVodAssignment, savePlaylistAssignment } from "../../../services/vodsServices";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;
  
  let assignment = {
    id                     : '',
    vod_id                 : '',
    vod_assignment_id      : '',
    playlist_id            : '',
    playlist_assignment_id : '',
    vimeo_id               : '',
    vimeo_thumbnail_id     : '',
    status                 : '',
    thumbnail              : '',
    name                   : '',
    description            : '',
    is_active              : '',
    is_playlist            : '',
    is_vod                 : '',
    start                  : "",
    weeks                  : "",
    note                   : "",
  };    
  let component;    
  let customerId;
  let exitDialog;
  let groupId;
  let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
  let msgError;
  let navbar;
  let props = {};
  let vod = {
    id          : "",
    name        : "",
    description : "",
    thumbnail   : "",
    start       : "",
    weeks       : "",
    note        : "",
  };

  if (location && location.state && location.state.assignment) {
    const { assignment: a } = location.state;

    if (a.vod_assignment_id) {
      assignment.id = a.vod_assignment_id;
    } else if (a.playlist_assignment_id) {
      assignment.id = a.playlist_assignment_id;
    }

    assignment = {
      vod_id                 : a.vod_id                 ? a.vod_id                 : '',                
      vod_assignment_id      : a.vod_assignment_id      ? a.vod_assignment_id      : '',
      playlist_id            : a.playlist_id            ? a.playlist_id            : '',
      playlist_assignment_id : a.playlist_assignment_id ? a.playlist_assignment_id : '',
      vimeo_id               : a.vimeo_id               ? a.vimeo_id               : '',
      vimeo_thumbnail_id     : a.vimeo_thumbnail_id     ? a.vimeo_thumbnail_id     : '',
      status                 : a.status                 ? a.status                 : '',
      thumbnail              : a.thumbnail              ? a.thumbnail              : '',
      name                   : a.name                   ? a.name                   : '',
      description            : a.description            ? a.description            : '',
      is_active              : a.is_active              ? a.is_active              : '',
      is_playlist            : a.is_playlist            ? a.is_playlist            : '',
      is_vod                 : a.is_vod                 ? a.is_vod                 : '',
      start                  : a.start                  ? a.start                  : '',
      weeks                  : a.weeks                  ? a.weeks                  : '',
      note                   : a.note                   ? a.note                   : '',
    };

    $activeAssignment = assignment;

    if (location.state.customerId) {
      customerId = location.state.customerId;
    }
  } else if (location && location.state && location.state.exerciseToBeCloned) {
    const { exerciseToBeCloned: ex } = location.state;
    exercise = {
      base: a.base,
      name: a.name,
      note: a.note || "",
      link: a.video_youtube || "",
      tagsSelected: a.tags,
      image: a.image,
      video: a.video,
      image_thumbnail: a.image_thumbnail,
      video_thumbnail: a.video_thumbnail, 
    };
    if (location.state.customerId) {
      customerId = location.state.customerId;
    }
  }
  
  $: if (location && location.state && location.state.customerId)
    customerId = location.state.customerId;

  $: if (location && location.state && location.state.groupId)
    groupId = location.state.groupId;

  $: console.log('groupId', groupId);

  const handleGoBack = () => {
    exitDialog.close();
    if (customerId) {
      navigate("/customer?tab=vods", {
        state: { customerId },
      });
    } else if (groupId) {
      navigate("/group?tab=vods&groupId=" + groupId);
    } else {
      navigate("/vods");
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleOnSubmit = async (e) => {
    let itemToBeAssigned = e.detail.assignment;
    try {
      itemToBeAssigned.customer_id = customerId ?? '';
      itemToBeAssigned.group_id = groupId ?? '';
      // VOD
      if (itemToBeAssigned.is_vod) {
        const res = await saveVodAssignment(itemToBeAssigned);
        if (res) {
          if (customerId) {
            navigate('/customer?tab=vods&customerId=' + customerId);
          } else if (groupId) {
            navigate('/group?tab=vods&groupId=' + groupId);
          }
        }
      } 
      // PLAYLIST
      else if (itemToBeAssigned.is_playlist) {
        const res = await savePlaylistAssignment(itemToBeAssigned);
        if (res) {
          if (customerId) {
            navigate('/customer?tab=vods&customerId=' + customerId);
          } else if (groupId) {
            navigate('/group?tab=vods&groupId=' + groupId);
          }
        }
      }
    } catch (err) {
      console.log("AssigningVod err", err);
      displayError();
    }
  }

  onMount(async () => {
    if (location && location.state && location.state.vodToBeAssigned) {
      const vodToBeAssigned = location.state.vodToBeAssigned;
      customerId = location.state.customerId ? location.state.customerId : "";
      vod = {
        id          : vodToBeAssigned.id          ? vodToBeAssigned.id          : "",
        vimeo_id    : vodToBeAssigned.vimeo_id    ? vodToBeAssigned.vimeo_id    : "",
        name        : vodToBeAssigned.name        ? vodToBeAssigned.name        : "",
        description : vodToBeAssigned.description ? vodToBeAssigned.description : "",
        thumbnail   : vodToBeAssigned.thumbnail   ? vodToBeAssigned.thumbnail   : "",
        start       : vodToBeAssigned.start       ? vodToBeAssigned.start       : "",
        weeks       : vodToBeAssigned.weeks       ? vodToBeAssigned.weeks       : "",
        note        : vodToBeAssigned.note        ? vodToBeAssigned.note        : "",
      };
    } 
  });
</script>
  
  <style>
    * :global(.warning) {
      background-color: #f9f9fb;
      opacity: 0.8;
    }
    * :global(.warning__icon) {
      font-size: 3rem;
    }
    @media screen and (max-width: 896px) {
      .tc-container {
        width: 100%;
        max-width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  </style>
  
  <Dialog bind:this={exitDialog}>
    <ConfirmationDialog
      text={$_('sei_sicuro_di_voler_uscire')}
      confirmationButtonLabel={$_('esci')}
      on:closeDialog={() => exitDialog.close()}
      on:onConfirmation={handleGoBack} />
  </Dialog>
  
  <VodAssignmentHeader
    name={$_('indietro')}
    id={vod.id}
    {customerId}
    {groupId}
    buttonVisible={true}
    on:error={() => displayError()}
    on:goBack={() => exitDialog.open()} />
  
  <div
    class="container fluid tc-container"  data-recording-gdpr-safe>
    {#if msgError}
      <div class="alert alert-danger fade show mt-2" role="alert">{msgError}</div>
    {/if}
  
    <VodAssignmentDetail
      bind:assignment
      bind:customerId
      bind:groupId
      bind:vod
      on:error={() => displayError()}
      on:submit={handleOnSubmit}
      class="" />
  </div>
  