<script>
  import { _ } from "../../../../services/i18n";
  import { checkPaymentSucceeded } from "../../../../services/customerServices.js";
  import { Icon } from "@smui/common";
  import { onMount } from "svelte";
  import Loading from "../../../ReusableLayouts/Loading.svelte";

  export let location;
  export const showNavbar = false;

  $: console.log('showNavbar', showNavbar);

  const params = new URLSearchParams(location.search);

  let checkoutCancel = false;
  let checkoutSuccess = false;
  let customerId = params.has("customerId") ? customerId = params.get("customerId") : null;;
  let internalProductId = params.has("internalProductId") ? internalProductId = params.get("internalProductId") : null;
  let loading = false;
  let msgError;
  let productName = "";
  let ptName = "";
  let thankYouMessage = "";
  let urlAfterPurchase = "";

  const checkoutValidation = async () => {
    const res = await checkPaymentSucceeded(customerId, internalProductId);
    productName = res.productName;
    ptName = res.pt_name;
    thankYouMessage = res.thank_you_message;
    urlAfterPurchase = res.url_after_purchase;
    loading = false;
  }

  if (params.has("cancel")) {
    checkoutCancel = true;
  } else if (params.has("success")) {
    if (customerId && internalProductId) {
      loading = true;
      checkoutValidation();
      checkoutSuccess = true;
    }
  }

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };
</script>

<div class="container fluid">
  {#if msgError}
    <div class="alert alert-danger fade show" role="alert">{msgError}</div>
  {/if}

  {#if loading}
    <Loading />
  {:else}
    <div>
      <div class="row mb-2 importantInfo">
        <div class="col-12 d-flex align-items-center">
          <Icon class="material-icons">arrow_upward</Icon>
          <span class="pl-2 pt-1">Clicca qui per tornare all'app</span>
        </div>
      </div>
    </div>
    <div class="row mb-2 importantInfo">
      <div class="col-12">
        <div class="d-flex flex-column justify-content-center align-items-center" style="height: 70vh;">
          <div class="pb-4 mb-4">
            <img src={"/assets/revoologo.svg"} alt="" style="max-width:20vh;">
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            {#if checkoutSuccess}
              <span style="white-space: pre-wrap;">{thankYouMessage}</span>
              <span>- <i>{ptName}</i></span>
              <!-- {#if urlAfterPurchase != ""}
                <span style="white-space: pre-wrap;"><a href="{urlAfterPurchase}" target="_blank">Completa il tuo ordine</a></span>
              {/if} -->
            {:else if checkoutCancel}
              <h5> </h5>             
            {/if}
          </div>
        </div>
      </div> 
    </div> 
  {/if}
</div>

<style>
  @media (max-width: 767px) {
    :global(.headerText) {
      font-size: 0.875rem !important;
    }
  }
</style>
