<script>
    import { _ } from "../../../../services/i18n";
    import IconButton from "@smui/icon-button";
    import Dialog from "@smui/dialog";
    import "./Carousel/Carousel.scss";

    export let photoDialog = undefined;
    export let photoToEnlargeDate;
    export let photoToEnlargeIndex;
    export let photoToEnlargeList;
    export let photoToEnlarge;
    export let getDateFormatted;

    const showPrevPhoto = () => {
        const prevView = photoToEnlargeList[++photoToEnlargeIndex];
        photoToEnlarge = prevView.image;
        photoToEnlargeDate = getDateFormatted(prevView.date);
    };

    const showNextPhoto = () => {
        const nextView = photoToEnlargeList[--photoToEnlargeIndex];
        photoToEnlarge = nextView.image;
        photoToEnlargeDate = getDateFormatted(nextView.date);
    };
</script>

<style>
    .justifyCenter {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .imgContainer {
        background-color: #fff;
        width: 100%;
        height: 60vh;
        align-self: center;
    }
    .imgContainer > img {
        max-width: 60vw;
        height: auto;
    }
    .imgContainer > span.divider {
        flex-grow: 1;
    }
    .header {
        align-items: center;
        background-color: #f9f9f9;
    }
    .alignHeader {
        align-items: center;
        justify-content: space-between;
    }
    .alignHeader > .headline {
        margin: auto;
    }
    @media (max-width: 767px) {
        .imgContainer {
            height: 100vh;
        }
    }
</style>

<Dialog class="photoToEnlargeDialog" bind:this={photoDialog}>
    <div class="header p-3">
        <div class="row no-gutters alignHeader">
            <span class="headline bold">{photoToEnlargeDate}</span>
            <div>
                <IconButton
                    class="material-icons"
                    on:click={() => photoDialog.close()}>
                    close
                </IconButton>
            </div>
        </div>
    </div>
    <div class="justifyCenter imgContainer">
        {#if photoToEnlargeIndex != photoToEnlargeList.length - 1}
            <div class="photoProgress-btnLeft">
                <IconButton class="material-icons" on:click={showPrevPhoto}>
                    chevron_left
                </IconButton>
            </div>
        {/if}

        <span class="divider" />

        <img class="img-fluid" src={photoToEnlarge} alt="" />

        <span class="divider" />

        {#if photoToEnlargeIndex != 0}
            <div class="photoProgress-btnRight">
                <IconButton class="material-icons" on:click={showNextPhoto}>
                    chevron_right
                </IconButton>
            </div>
        {/if}
    </div>
</Dialog>
