<script>
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher, tick } from "svelte";
  import { getTrainingCards } from "../../../services/trainingCardServices";
  import Button, { Icon, Label } from "@smui/button";
  import Dialog from "@smui/dialog";
  import FromAthleteDialog from "./FromAthleteDialog.svelte";
  import FromTemplateDialog from "./FromTemplateDialog.svelte";
  import SvelteTooltip from 'svelte-tooltip';
  
  export let addWorkoutDisabled;

  const dispatch = createEventDispatcher();

  let ascOrder = false;
  let athleteTrainingCards = [];
  let fromAthleteDialog;
  let fromTemplateDialog;
  let loading = false;
  let msgError;
  let query = [];
  let search = "";
  let templateTrainingCards = [];

  $: query = {
    ascOrder,
    search,
  };

  const handleFromAthleteButtonClicked = async () => {
    fromAthleteDialog.open();
    if (athleteTrainingCards.length == 0) await getAthleteTrainingCards();
  };
  const handleFromTemplateButtonClicked = async () => {
    if (templateTrainingCards.length == 0) await getTemplates();
    fromTemplateDialog.open();
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    await getAthleteTrainingCards();
  };

  const handleOrderChanged = async (e) => {
    ascOrder = e.detail.ascOrder;
    await getAthleteTrainingCards();
  };

  const getTemplates = async () => {
    try {
      loading = true;
      let withGroups = true;
      await tick();
      const workouts = true;
      const res = await getTrainingCards(500, 1, search, !ascOrder, true, withGroups);
      templateTrainingCards = res.data;
      loading = false;
      await tick();
    } catch (err) {
      console.log(" getTemplates err", err);
      displayError();
    }
  };

  const getAthleteTrainingCards = async () => {
    try {
      loading = true;
      await tick();
      const res = await getTrainingCards(500, 1, search, !ascOrder, false);
      athleteTrainingCards = res.data;
      loading = false;
      await tick();
    } catch (err) {
      console.log("error getAthleteTrainingCards", err);
      displayError();
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 5000);
  };
</script>

<style>
  .actions-container {
    justify-content: flex-end;
  }
  .alignItemsCenter {
    align-items: center;
  }

  * :global(.outlinedCustom) {
    border-color: black;
    color: black;
  }
  * :global(.unelevatedCustom) {
    background-color: black;
  }
  @media (min-width: 280px) and (max-width: 896px) and (orientation: landscape) {
    .actions-container {
      justify-content: space-evenly;
    }
  }
</style>

<Dialog class="dialog--bigger" bind:this={fromAthleteDialog}>
  <FromAthleteDialog
    {msgError}
    {loading}
    bind:ascOrder
    bind:search
    cards={athleteTrainingCards}
    on:orderChange={handleOrderChanged}
    on:searchChange={handleSearchChanged}
    on:importWorkout={(e) => {
      dispatch('importWorkout', { id: e.detail.id });
      fromAthleteDialog.close();
    }}
    on:importCard={(e) => {
      dispatch('importCard', { id: e.detail.id });
      fromAthleteDialog.close();
    }}
    on:closeDialog={() => fromAthleteDialog.close()} />
</Dialog>

<Dialog class="dialog--xsmall" bind:this={fromTemplateDialog}>
  <FromTemplateDialog
    {msgError}
    cards={templateTrainingCards}
    on:importWorkout={(e) => {
      dispatch('importWorkout', { id: e.detail.id });
      fromTemplateDialog.close();
    }}
    on:importCard={(e) => {
      dispatch('importCard', { id: e.detail.id });
      fromTemplateDialog.close();
    }}
    on:closeDialog={() => fromTemplateDialog.close()} />
</Dialog>

<div class="row mx-0 my-3 actions-container alignItemsCenter">
  <Button
    class="btn unelevatedCustom"
    variant="unelevated"
    disabled={addWorkoutDisabled}
    on:click={() => dispatch('addWorkout', {})}>
    <Label>
      <Icon
        role="button"
        class="material-icons mdc-text-field__icon
          mdc-text-field__icon--trailing">
        note_add
      </Icon>
      {$_('aggiungi_scheda')}
    </Label>
  </Button>
  <SvelteTooltip tip="{$_('importa_da_modello')}" top >
    <Button
      class="btn ml-3 outlinedCustom"
      variant="outlined"
      on:click={handleFromTemplateButtonClicked}>
      <Label>
        <Icon
          role="button"
          class="material-icons mdc-text-field__icon
            mdc-text-field__icon--trailing">
          fitness_center
        </Icon>
        {$_('da_modello')}
      </Label>
    </Button>
  </SvelteTooltip>
  <SvelteTooltip tip="{$_('importa_da_atleta')}" top >
    <Button
      class="btn ml-3 outlinedCustom trainingCardDuplicate"
      variant="outlined"
      on:click={handleFromAthleteButtonClicked}>
      <Label>
        <Icon
          role="button"
          class="material-icons mdc-text-field__icon
            mdc-text-field__icon--trailing">
          content_copy
        </Icon>
        {$_('da_atleta')}
      </Label>
    </Button>
  </SvelteTooltip>
</div>
