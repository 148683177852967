<script>
  import { _ } from "../../../../services/i18n";
  import { navigate } from "svelte-routing";
  import Header from "../../../ReusableLayouts/Header.svelte";
  import NewGroupInfo from "./NewGroupInfo.svelte";
  import { createGroup, editGroup } from "../../../../services/teamServices.js";

  export let location;

  let groupToBeEdited = '';
  let imageValid = true;
  let msgError;
  let edit = false;

  if (location && location.state.groupToBeEdited && location.state.groupToBeEdited.id != null)  {
    edit = true;
    groupToBeEdited = location.state.groupToBeEdited;
  }

  const handleCreateGroup = async (e) => {
    try {
      console.log(e.detail);
      const body = { ...e.detail.group };
      const nextUrl = e.detail.nextUrl;
      const res = await createGroup(body);
      navigate(nextUrl, { state: { groupId: res.group_id }} 
      );
    } catch (err) {
      console.log("handleCreateGroup err", err);
      msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 4000);
    }
  };

  const handleEditGroup = async (e) => {
    try {
      const body = { ...e.detail.group };
      const res = await editGroup(body);
      navigate("/team?tab=gruppi", { state: { groupId: res.group_id } });
    } catch (err) {
      console.log("handleEditGroup err", err);
      msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 4000);
    }
  };
</script>

<style>
  * :global(.customCard) {
    margin: 0 auto;
    text-align: center;
    border: none;
  }
  * :global(.customUploader) {
    height: 200px;
    width: 200px;
    border-radius: 50%;
  }
</style>

<Header
  text={$_('indietro')}
  asTextAndIcon={true}
  noBackground={true}
  on:goBack={() => navigate('/team?tab=gruppi')} />

<div class="container fluid mt-4">
  <div class="row">
    {#if msgError}
      <div class="col-12 alert alert-danger fade show" role="alert">
        {msgError}
      </div>
    {/if}

    <div class="col-xs-12 col-lg-8">
      <NewGroupInfo 
        {imageValid} 
        {groupToBeEdited} 
        {edit} 
        on:createGroup={handleCreateGroup}
        on:editGroup={handleEditGroup} />
    </div>
  </div>
</div>
