<script>
  import { _ } from "svelte-i18n";
  import { navigate } from "svelte-routing";
  import Button, { Label } from "@smui/button";
  import Card, { Content } from "@smui/card";
  import SetNewPasswordInput from "./SetNewPasswordInput.svelte";
  import { checkEmail, checkPassword } from "../validation/authValidation";
  import { restorePassword } from "../../../services/authServices";

  let token;
  $: token = new URLSearchParams(location.search).get("token");

  let body = {
    email: "",
    newPassword: "",
    repeatPw: "",
  };

  const show = {
    newPassword: false,
    repeatPw: false,
  };

  let alert = {
    show: false,
    msg: "",
    error: false,
  };

  const items = [
    {
      label: "email",
      value: "email",
      errorMsg: "email_non_valida",
    },
    {
      label: "nuova_password",
      value: "newPassword",
      errorMsg: "la_password_non_rispetta_i_criteri",
    },
    {
      label: "ripeti_nuova_password",
      value: "repeatPw",
      errorMsg: "password_non_coincidono",
    },
  ];

  let errors = {};
  $: errors = {
    email: body.email ? !checkEmail(body.email) : false,
    newPassword: body.newPassword ? !checkPassword(body.newPassword) : false,
    repeatPw: body.repeatPw ? body.repeatPw != body.newPassword : false,
  };

  let formValid = false;
  $: formValid =
    body.email &&
    body.newPassword &&
    body.repeatPw &&
    Object.values(errors).every((i) => !i);

  const handleSubmit = async () => {
    try {
      const res = await restorePassword({
        email: body.email,
        password: body.newPassword,
        resetToken: token,
      });
      if (res.redirect) {
        alert = {
          show: true,
          msg: $_("password_modificata_con_successo"),
          error: false,
        };
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      console.log("restore password error", error);
      alert = {
        show: true,
        msg: $_("si_è_verificato_un_errore"),
        error: true,
      };
    }
  };
</script>

<style>
  img {
    height: 2.5rem;
  }
  * :global(.mdc-card) {
    border-radius: 2px;
    width: 25rem;
  }
</style>

<div>
  <Card class="borderNone" variant="outlined" padded>
    <div class="flexy py-4">
      <img src="/assets/revoologo.svg" alt="" />
    </div>
    <Content class="col-12 py-2 textWrapper">
      {#each items as item}
        <SetNewPasswordInput {item} bind:body {errors} {show} />
      {/each}
      {#if alert.show}
        <div
          class="alert alert-success fade show py-2"
          class:alert-danger={alert.error}
          role="alert">
          {alert.msg}
        </div>
      {/if}
      <div class="flexy py-2">
        <Button
          type="submit"
          disabled={!formValid}
          class="btn"
          on:click={handleSubmit}
          variant="unelevated">
          <Label>{$_('modifica_password')}</Label>
        </Button>
      </div>
    </Content>
  </Card>
</div>
