<script>
  import "echo-js/dist/echo";
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { exercises, userData } from "../../../lib/store";
  import { Icon } from "@smui/common";
  import Button, { Label } from "@smui/button";
  import Dialog from "@smui/dialog";
  import DropdownTagFilter from "../../Exercises/DropdownTagFilter.svelte";
  import ExerciseDetail from "../../Exercises/ExerciseDetail.svelte";
  import IconButton from "@smui/icon-button";
  import Loading from "../../ReusableLayouts/Loading.svelte";
  import Textfield from "@smui/textfield";
  import VirtualList from "@sveltejs/svelte-virtual-list";

  const dispatch = createEventDispatcher();

  $: if (clicked && search) filterExercises();

  export let search = "";
  export let clicked = false;

  $: if (clicked) {
    initValues();
    configSearch();
  }

  let dialogExercises = [];
  let evenExercises = [];
  let tagsFiltered = [];
  let openExercise = false;
  let detailsDialog;
  let exercise = {
    name: "",
    base: "",
    note: "",
    link: "",
    tagsSelected: [],
    image: "",
    video: "",
  };
  let firstSearch = false;
  let loading = false;

  let showList;
  let triggerCloseVideo = false;
  let triggerCloseImageDialog = false;
  let resetFilter = false;
  let exitDialogOpened = false;
  let typingTimer;
  let doneTypingInterval = 0;
  let showItems = false;

  $: if (dialogExercises)
    if (dialogExercises.length > 0)
      evenExercises = dialogExercises.flatMap((element, index) => {
        if (index % 2 === 0) return [{ ...element, nextIndex: index + 1 }];
        else return [];
      });
    else evenExercises = [...dialogExercises];

  const initValues = () => {
    dialogExercises = $userData.show_base_exercises ? [...$exercises] : [...$exercises.filter(e => e.base === 0)];
    tagsFiltered = [];
    search = "";
    resetFilter = false;
  };

  const handleExerciseClicked = (item) => {
    exercise = {
      id: item.id,
      base: item.base,
      name: item.name,
      note: item.note || "",
      link: item.video_youtube || "",
      tagsSelected: item.tags,
      image: item.image,
      video: item.video,
      image_thumbnail: item.image_thumbnail,
      video_thumbnail: item.video_thumbnail,
    };
    detailsDialog.open();
  };

  const handleTagsFiltered = async (e) => {
    tagsFiltered = e.detail.tags.map((i) => i.id);
    await handleUpdateFiltratedExercises();
  };

  const handleExerciseSubmit = async (ev) => {
    detailsDialog.close();
    if (ev.detail.id) {
      dialogExercises = dialogExercises.map((e) =>
        e.id == ev.detail.id ? ev.detail.res : e
      );
      dispatch('checkAndUpdateDuplicates', {
        exerciseId: ev.detail.id, 
        newExerciseName: ev.detail.res.name,
      });
    } else {
      await handleUpdateFiltratedExercises();
    }
  };

  const handleUpdateFiltratedExercises = async () => {
    loading = true;
    // Effettua ricerca
    let query = search.toLowerCase().split(" ").filter(sub => sub != ""); 

    dialogExercises = $exercises.filter(ex => 
      query.every((subQuery) => {
        return JSON.stringify(ex.name).toLowerCase().match(new RegExp(subQuery, 'i')) && ex.is_active === 1
      })
    );

    // Applica il filtro per esercizi base selezionato globalmente nella sezione "esercizi"
    if ($userData.show_base_exercises === 0) {
      dialogExercises = dialogExercises.filter(e => e.base === 0);
    }

    // Applica filtro tag
    if (tagsFiltered.length > 0) {
      dialogExercises = dialogExercises.filter(ex => {
        let exerciseTags = ex.tags.map(t => parseInt(t.id));
        return tagsFiltered.every(subQueryTag => exerciseTags.includes(subQueryTag))
      });
    }
    
    // Pulisce risultati da esercizi eliminati (is_active === 0), nel caso in cui i filtri contengano esercizi eliminati
    dialogExercises = dialogExercises.filter(e => e.is_active === 1);
    loading = false;
  };

  const handleAddExercise = (e) => {
    dispatch("addSelectedExercise", { ex: e });
  };

  const handleCloseDialog = () => {
    resetFilter = true;
    tagsFiltered = [];
    search = "";
    clicked = false;
    dispatch("closeDialog", {});
  };

  const openExitDialog = () => {
    exitDialogOpened = true;
  };

  const handleCloseExitDialog = () => {
    exitDialogOpened = false;
  };

  const handleGoBack = () => {
    exitDialogOpened = false;
    detailsDialog.close();
  };

  const configSearch = () => {
    let searchInput = document.getElementById("searchInputExercises");
    if (searchInput) {
      searchInput.addEventListener("keyup", () => {
        filterExercises();
      });
    }
  };

  const filterExercises = async () => {
    if (tagsFiltered && tagsFiltered.length > 0 || search) {
      await handleUpdateFiltratedExercises();
      showItems = true;
    } else showItems = false;
  };

  onMount(async () => {
    echo.init();
    resetFilter = false;
  });
</script>

<style>
  * :global(.mdc-text-field) {
    height: 40px;
    width: 12.2em;
    margin-right: 0.8em;
    opacity: 0.5;
    max-width: 100%;
  }
  * :global(.mdc-text-field:not(.mdc-text-field--disabled)) {
    background-color: #fff;
  }
  * :global(.mdc-text-field__input) {
    height: auto;
    margin: auto;
    padding: 0 0.5em !important;
  }
  *
    :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
      .mdc-floating-label) {
    top: 12px;
  }
  * :global(.material-icons.searchIcon) {
    padding: 10px 0px 0px 4px !important;
  }

  .header {
    align-items: center;
    background-color: #f9f9f9;
    justify-content: space-between;
  }
  .title {
    font-size: 1.25rem;
  }

  .box-container {
    position: relative;
  }

  .grid-box {
    max-width: 100%;
    position: relative;
    height: 55vh;
  }

  .box {
    border-radius: 3px;
    box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.05);
    height: 11.25rem;
    position: relative;
    width: 20rem;
    /* flex: 0 0 100px; */
    /* margin: 0.5rem; */
  }

  .box-right {
    position: absolute;
    top: 0;
    right: 1rem;
  }
  .box--content {
    width: auto;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .box--content::after {
    border-radius: 3px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.66)
    );
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  .box--content > img {
    border-radius: 3px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  .box--content > span {
    bottom: 0;
    color: #fff;
    font-family: "Avenir Next";
    font-size: 14px;
    font-weight: 600;
    left: 0;
    letter-spacing: 0;
    line-height: 15px;
    position: absolute;
    right: 0;
    text-align: center;
    text-shadow: 0 1px 3px #000000;
    z-index: 3;
  }
  .box__placeholder {
    background: #fff url("/assets/placeholder_logo.png") no-repeat center;
    background-size: 5rem;
    height: 11.25rem;
    max-height: 100%;
  }

  .box__placeholder--empty {
    background-size: 5rem;
    height: 7rem;
    max-height: 100%;
  }
  .bringToFront {
    align-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-evenly;
    left: 0;
    max-width: 100%;
    opacity: 0.9;
    position: absolute;
    visibility: hidden;
    width: 100%;
  }
  .box:hover > .bringToFront {
    visibility: visible;
    z-index: 5;
  }
  .spaceBetween {
    justify-content: space-between;
  }
  .alignCenter {
    align-items: center;
    align-content: center;
  }
  :global(.customButton.openExercise) {
    margin-left: 33%;
  }

  .textWrapper {
    align-self: center;
    margin: 0 auto;
    opacity: 0.5;
    word-break: break-word;
  }

  .textWrapperCenter {
    margin: 0 auto;
  }

  * :global(.customButton) {
    height: 2.25rem;
    width: 7.65rem;
  }

  .container-grid {
    display: flex;
    justify-content: center;
    min-height: 10rem;
  }

  .exercise-dialog-wrapper {
    min-height: 610px;
  }

  .box--content .r-logo {
    position: absolute;
    width: 1rem;
    height: 1rem;
    right: .5rem;
    top: .5rem;
    opacity: .7;
  }

  @media (max-width: 767px) {
    .grid-box {
      grid-template-columns: unset;
    }

    .box {
      width: 20rem;
      margin: 0.5rem auto;
    }
    .box-right {
      position: relative;
      top: auto;
      right: auto;
    }
    * :global(.searchbar) {
      width: 100%;
    }
    * :global(.dropdown .customButton) {
      width: 100%;
    }
  }
</style>

<Dialog
  bind:this={detailsDialog}
  aria-labelledby="dialog-for-new-exercise"
  aria-describedby="new-exercise"
  on:MDCDialog:closed={() => {
    triggerCloseVideo = true;
    triggerCloseImageDialog = true;
  }}
  class="dialog--small">
  <div class="header p-4 row no-gutters">
    <div class="title bold">
      {exercise.id ? exercise.name : $_('nuovo_esercizio')}
    </div>
    <IconButton class="material-icons" on:click={openExitDialog}>
      close
    </IconButton>
  </div>
  <ExerciseDetail
    {exercise}
    bind:triggerCloseVideo
    bind:triggerCloseImageDialog
    {exitDialogOpened}
    on:closeExitDialog={handleCloseExitDialog}
    on:goBack={handleGoBack}
    on:onSubmit={handleExerciseSubmit} />
</Dialog>

<div class="exercise-dialog-wrapper">
  <div class="header p-4">
    <div class="row no-gutters spaceBetween alignCenter">
      <h5 class="demiBold title mb-0">{$_('esercizi')}</h5>
      <IconButton class="material-icons" on:click={handleCloseDialog}>
        close
      </IconButton>
    </div>

    <div class="row no-gutters alignCenter">
      <div class="col-12 col-md-4">
        <Textfield
          class="searchbar alignCenter"
          variant="outlined"
          id="searchInputExercises"
          withLeadingIcon
          bind:value={search}
          label={$_('cerca_per_nome')}>
          <Icon class="material-icons searchIcon">search</Icon>
        </Textfield>
      </div>
      <div class="col-12 col-md-4 ml-auto">
        <DropdownTagFilter {resetFilter} on:tagsFiltered={handleTagsFiltered} />
      </div>
    </div>
  </div>

  <div class="container fluid py-4">
    {#if (search && showItems) || (tagsFiltered && tagsFiltered.length > 0)}
      {#if !loading}
        <div
          class={`${evenExercises.length > 0 ? 'grid-box' : 'row no-gutters'}`}>
          {#if evenExercises.length != 0}
            <VirtualList items={evenExercises} let:item>
              <div class="box-container mb-3">
                <div class="box">
                  <div class="box--content">
                    {#if item.image_thumbnail || item.video_thumbnail}
                      <img
                        loading="lazy"
                        src={item.image_thumbnail ? item.image_thumbnail : item.video_thumbnail}
                        alt={`exercise ${item.name}`} />
                    {:else if item.base}
                      <div class="box__placeholder" />
                    {:else}
                      <div class="box__placeholder--empty" />
                    {/if}
                    <span class="pb-2">{item.name}</span>
                    {#if item.base === 1}
                      <img class="r-logo" src="r_logo.png" alt="R" />
                    {/if}
                  </div>
                  <div class="bringToFront">
                    <Button
                      class="btn customButton"
                      variant="unelevated"
                      on:click={() => handleAddExercise(item)}
                      target="_blank">
                      <Label>{$_('aggiungi')}</Label>
                      <Icon
                        role="button"
                        class="material-icons mdc-text-field__icon
                          mdc-text-field__icon--trailing">
                        arrow_forward
                      </Icon>
                    </Button>
                    <Button
                      class="btn customButton"
                      color="secondary"
                      variant="outlined"
                      target="_blank"
                      on:click={() => {
                        handleExerciseClicked(item);
                      }}>
                      <Label>{$_('dettaglio')}</Label>
                    </Button>
                  </div>
                </div>

                {#if dialogExercises[item.nextIndex]}
                  <div class="box box-right">
                    <div class="box--content">
                      {#if dialogExercises[item.nextIndex].image_thumbnail || dialogExercises[item.nextIndex].video_thumbnail}
                        <img
                          loading="lazy"
                          src={dialogExercises[item.nextIndex].image_thumbnail ? dialogExercises[item.nextIndex].image_thumbnail : dialogExercises[item.nextIndex].video_thumbnail}
                          alt={`exercise ${dialogExercises[item.nextIndex].name}`} />
                      {:else if item.base}
                        <div class="box__placeholder" />
                      {:else}
                        <div class="box__placeholder--empty" />
                      {/if}
                      <span class="pb-2">{dialogExercises[item.nextIndex].name}</span>
                      {#if dialogExercises[item.nextIndex].base}
                        <img class="r-logo" src="r_logo.png" alt="R" />
                      {/if}
                    </div>
                    <div class="bringToFront">
                      <Button
                        class="btn customButton"
                        variant="unelevated"
                        on:click={() => handleAddExercise(dialogExercises[item.nextIndex])}
                        target="_blank">
                        <Label>{$_('aggiungi')}</Label>
                        <Icon
                          role="button"
                          class="material-icons mdc-text-field__icon
                            mdc-text-field__icon--trailing">
                          arrow_forward
                        </Icon>
                      </Button>
                      <Button
                        class="btn customButton"
                        color="secondary"
                        variant="outlined"
                        target="_blank"
                        on:click={() => {
                          handleExerciseClicked(dialogExercises[item.nextIndex]);
                        }}>
                        <Label>{$_('dettaglio')}</Label>
                      </Button>
                    </div>
                  </div>
                {/if}
              </div>
            </VirtualList>
          {:else}
            <span
              class="textWrapper demiBold">{$_('nessun_esercizio_trovato')}</span>
          {/if}
        </div>
      {:else}
        <div class="row no-gutters position-relative">
          <Loading />
        </div>
      {/if}
    {:else}
      <div class="container-grid">
        <span
          class="textWrapper textWrapperCenter demibold">{`${$_('inizia_ricerca')}...`}</span>
      </div>
    {/if}
  </div>
</div>
