<script>
  import { _ } from "../../../../services/i18n";
  import IconButton from "@smui/icon-button";
  import Resizer from "react-image-file-resizer"; 

  export let image;

  const resize = Resizer.imageFileResizer; 

  let msgError;
  let urlImage;
  let rawImage;

  $: if (typeof image == "string") { urlImage = image;}

  const onInputChange = async (e) => {
    const file = e.target.files[0];
    if (file.size > 10000000) {
      msgError = $_("la_dimensione_massima_consentita_è_di", {
      values: {
        dimension: 10,
      },
    })
      setTimeout(() => {
        msgError = "";
      }, 3000);
      return;
    } else {
      urlImage = URL.createObjectURL(file);
      try {
        image = await resizeImage(file);
      } catch (err) {
        image = file;
        console.log('error resizing image', err);
      }
    }
  };

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      resize(file, 1920, 1080, "JPEG", 85, 0, uri => resolve(uri), "blob");
    });
  };
</script>

<style>
  .container-image {
    background-color: white;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(111, 97, 99, 0.3);
    height: 12em;
    overflow: hidden;
    position: relative;
    width: 8em;
  }

  input {
    height: inherit;
    width: inherit;
    opacity: 0;
  }
  * :global(.customIcon) {
    background-image: url('/assets/camera_red.svg');
    bottom: 50%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
  }
</style>

<div class="container-image" style={`background-image: url(${urlImage})`}>
  <IconButton class="customIcon"></IconButton>
  <input
    type="file"
    class="customInput"
    accept="image/jpeg, image/png, image/webp"
    bind:files={rawImage}
    on:change={onInputChange} />
</div>
{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}
