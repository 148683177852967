<script>
    import { createEventDispatcher, onMount, onDestroy } from "svelte";
    import VirtualList from "@sveltejs/svelte-virtual-list";
    import { _ } from "../../../services/i18n";
    import Card, { Content, Media } from "@smui/card";
    import { Icon } from "@smui/common";
    import IconButton from "@smui/icon-button";
    import Textfield from "@smui/textfield";
    import Loading from "../../ReusableLayouts/Loading.svelte";
    import {
      assignVodToPlaylist,
      updatePlaylistRank,
      getVodsPlaylistRelations,
      removeVodFromPlaylist,
    } from "../../../services/vodsServices";
    import { playlists, vodsPlaylistRelations } from "../../../lib/store";
    import { dndzone } from 'svelte-dnd-action';
	  import { flip } from 'svelte/animate';
  
    export let text;
    export let playlistToBeAssigned;
    let playlistToBeRemoved = playlistToBeAssigned;
    // export let vods = [];
    export let msgError;
    export let loading;

    let dataRefreshing = false;
    let filteredByName = false;
    let notAssignedVods = [];
    let assignedVods = [];

    let flipDurationMs = 300;
    let idPropertyName = "id";

    const dispatch = createEventDispatcher();

    let search = "";
    let previousSearch = "";

    const handleConsider = (e) => { 
      assignedVods = e.detail.items;
    };

    const handleFinalize = (e) => {
      onDrop(e.detail.items);
    };

    const onDrop = async (newItems) => { 
      assignedVods = newItems;
      await updatePlaylistRank(assignedVods, playlistToBeAssigned);
    };

    const handleSearchChanged = (string) => {
      getVodsLists(string);
    };

    const handleAssignVodToPlaylist = (vod) => {
      let updatedRelations;
      try {
        updatedRelations = {
          assigned_vods     : [...$vodsPlaylistRelations.assigned_vods, vod],
          not_assigned_vods : $vodsPlaylistRelations.not_assigned_vods.filter(c => { return c.id !== vod.id; }),
          playlist_id       : $vodsPlaylistRelations.playlist_id,
        };
        vodsPlaylistRelations.set(updatedRelations);
        assignVodToPlaylist(vod, playlistToBeAssigned);
        getVodsLists(search);
      } catch (err) {
        console.log("handleAssignVodToPlaylist err", err);
      }
    };

    const handleRemoveVodFromPlaylist = (vod) => {
      let updatedRelations;
      try {
        updatedRelations = {
          assigned_vods     : $vodsPlaylistRelations.assigned_vods.filter(c => { return c.id !== vod.id; }),
          not_assigned_vods : [...$vodsPlaylistRelations.not_assigned_vods, vod],
          playlist_id               : $vodsPlaylistRelations.playlist_id,
        };
        vodsPlaylistRelations.set(updatedRelations);
        removeVodFromPlaylist(vod, playlistToBeRemoved);
        getVodsLists(search);
      } catch (err) {
        console.log("handleRemoveVodFromPlaylist err", err);
      }
    };

    const getVodsLists = async (name = '') => {
      let waitingTime = 0;
      name = name.trim();
      if ((name.length === 0 || previousSearch.length > name.length) && filteredByName === true) {
        filteredByName = name == '' ? false : true;
        dataRefreshing = true;
        vodsPlaylistRelations.set(await getVodsPlaylistRelations(playlistToBeAssigned, 'all', name));
        dataRefreshing = false;
        setNewLists(name);
      } else if ((name != null && name.trim() != '') || (filteredByName === false && search.length > 0)) {
        filteredByName = true;
        waitingTime = dataRefreshing === true ? 500 : 0;
        setTimeout(() => {
          vodsPlaylistRelations.set(filterByName(name));
          dataRefreshing = false;
          setNewLists(name);
        }, waitingTime);
      } else {
        setNewLists(name);
      }
    }

    const setNewLists = (name) => {
      assignedVods    = $vodsPlaylistRelations.assigned_vods;
      notAssignedVods = $vodsPlaylistRelations.not_assigned_vods;
      previousSearch  = name.trim();
    }

    const filterByName = (name) => {
      let assigned = [];
      let not_assigned = [];
      $vodsPlaylistRelations.assigned_vods.forEach(c => { 
        if ((c.first_name.toLowerCase().split(" ").join("") + c.last_name.toLowerCase().split(" ").join("")).includes(name.trim().toLowerCase())) {
          assigned.push(c);
        }
      });
      $vodsPlaylistRelations.not_assigned_vods.forEach(c => { 
        if ((c.first_name.toLowerCase().split(" ").join("") + c.last_name.toLowerCase().split(" ").join("")).includes(name.trim().toLowerCase())) {
          not_assigned.push(c);
        }
      });
      return {
        assigned_vods: assigned,
        not_assigned_vods: not_assigned,
        playlist_id: $vodsPlaylistRelations.playlist_id,
      }
    }
  
    onMount(async () => {
      loading = true;
      vodsPlaylistRelations.set(await getVodsPlaylistRelations(playlistToBeAssigned, 'all', name));
      getVodsLists();
      loading = false;
    });

    onDestroy(() => {
      console.log('onDestroy')
      vodsPlaylistRelations.set('');
    })
  </script>
  
  <style>
    * :global(.mdc-text-field) {
      height: 40px;
      margin-right: 0.8em;
    }
    * :global(.mdc-text-field__input) {
      height: auto;
      margin: auto;
      padding: 0 0.5em !important;
    }
    * :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
        .mdc-floating-label) {
      top: 12px;
    }
    * :global(.material-icons.searchIcon) {
      padding: 10px 0px 0px 4px !important;
    }
    .justifyContentEnd {
      display: flex;
      justify-content: flex-end;
    }
    * :global(.material-icons.deleteIconButton:hover) {
      --mdc-ripple-left: 10px !important;
      --mdc-ripple-top: 10px !important;
      --mdc-ripple-fg-size: 28px !important;
      --mdc-ripple-fg-scale: 1.71429 !important;
    }
    .alignCenter {
      display: flex;
      align-items: center;
      align-content: center;
    }
    .alignCenterIcons {
      display: flex;
      align-items: center;
      align-content: center;
    }
    .textLeft {
      text-align: left;
    }
    .nameWrapper {
      font-weight: 600;
      opacity: 0.8;
    }
    .bold {
      font-weight: bold;
    }
    .imageWrapper {
      background-color: grey;
      border-radius: 5px;
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
    }
    .imagevod {
      border-radius: 5px;
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      object-fit: cover;
    }
    .statusCircle {
      border-radius: 50%;
      display: inline-block;
      height: 0.9rem;
      margin-left: 1rem;
      width: 0.9rem;
    }
    .header {
      background-color: #f9f9f9;
      align-items: center;
    }
    .header-sub {
      background-color: #fff;
    }
    .alignBaseline {
      align-content: baseline;
      align-items: baseline;
      display: flex;
    }
    * :global(.mdc-button--raised), * :global(.mdc-button--unelevated) {
      height: 2rem;
    }
    .searchbar {
      order: initial;
    }
    .containerVod {
      max-width: 100%;
      position: relative;
      height: 700px;
      max-height: 1000px; 
    }
    @media (max-width: 767px) {
      .searchbar {
        order: 3;
      }
      .header__title {
        margin: 0 !important;
      }
      * :global(.mdc-text-field__input) {
        margin-left: 0;
      }
      .desktop {
        display: none;
      }
    }
  </style>
  
  <div class="header px-4 py-2 row no-gutters">
    <div class="header__title col-11 col-md-7 my-4">
      <div class="mdc-typography--headline6 bold">
        {`${text}`}
      </div>
      <div class="mdc-typography--headline6 bold mb-2">
        {playlistToBeAssigned.name ? playlistToBeAssigned.name : ''}
      </div>
    </div>
    <div class="col-12 col-md-4 searchbar">
      <Textfield
        variant="outlined"
        class="w100"
        withLeadingIcon
        id="searchInputAssignTC"
        bind:value={search}
        on:click={(e) => e.stopPropagation()}
        on:keyup={handleSearchChanged(search)}
        label={$_('cerca_per_nome')}>
        <div class="centrated">
          <Icon class="material-icons searchIcon">search</Icon>
        </div>
      </Textfield>
    </div>
    <div class="col-1 col-md-1 justifyContentEnd">
      <IconButton
        class="material-icons deleteIconButton"
        on:click={(e) => { dispatch('closeDialog', {}); e.stopPropagation();}}>
        close
      </IconButton>
    </div>
  </div>
  
  <div class="container fluid py-3">
    {#if msgError}
      <div class="alert alert-danger fade show" role="alert">{msgError}</div>
    {/if}
  
    {#if !loading}
      <div class="row no-gutters">
        <div class="col-6 px-0 px-md-2">
          <div class="header header-sub px-2 my-3">
            <div class='header__title'>
              <div class="mdc-typography--headline6 bold">
                {$_('scegli_video')}
              </div>
            </div>
          </div>
          <div
            class={`${notAssignedVods.length > 0 ? 'containerVod' : 'container fluid py-2'}`}>
            {#if notAssignedVods.length > 0}
              <VirtualList items={notAssignedVods} let:item>
                <Card class="my-1 mx-1 mx-md-2">
                  <Content>
                    <div class="row no-gutters alignCenter">
                      <div class="col-2" style="padding-left:0px">
                        <div
                          class="icon--action"
                          on:click|stopPropagation={handleAssignVodToPlaylist(item)}>
                          <IconButton
                            data-mobile-icon
                            class="btn material-icons primary"
                            style="width: fit-content">
                            add_circle
                          </IconButton>
                        </div>
                      </div>
                      <div class="col-2 d-none d-md-block">
                        <Media>
                          {#if item.thumbnail}
                            <img src={item.thumbnail} alt="" class="imagevod" />
                          {:else}
                            <div class="imageWrapper">
                              <div style="" />
                            </div>
                          {/if}
                        </Media>
                      </div>
                      <div class="col-9 col-md-6 px-4 textLeft alignCenter">
                        <span class="nameWrapper">
                          {item.name}
                        </span>
                      </div>
                      <div class="d-none d-md-block col-2 px-1 alignCenterIcons">
                      </div>
                    </div>
                  </Content>
                </Card>
              </VirtualList>
            {/if}
          </div>
        </div>
        <div class="col-6 px-0 px-md-2">
          <div class="header header-sub px-2 my-3">
            <div class='header__title'>
              <div class="mdc-typography--headline6 bold">
                {$_('assegnati')}
              </div>
            </div>
          </div>
          <div
            class={`${assignedVods.length > 0 ? 'containerVod' : 'containerVod py-2'}`}>
            {#if assignedVods.length > 0}
              <!-- <VirtualList items={assignedVods} let:item class=""> -->
              <section use:dndzone={{items: assignedVods, flipDurationMs}} on:consider={handleConsider} on:finalize={handleFinalize}>
                {#each assignedVods as item(item[idPropertyName])}
                  <Card class="my-1 mx-1 mx-md-2">
                    <Content>
                      <div class="row no-gutters alignCenter">
                        <div class="col-2" style="padding-left:0px">
                          <div
                            class="icon--action"
                            on:click|stopPropagation={handleRemoveVodFromPlaylist(item)}>
                            <IconButton
                              data-mobile-icon
                              class="btn material-icons primary"
                              style="width: fit-content">
                              remove_circle
                            </IconButton>
                          </div>
                        </div>
                        <div class="col-2 d-none d-md-block">
                          <Media>
                            {#if item.thumbnail}
                              <img src={item.thumbnail} alt="" class="imagevod" />
                            {:else}
                              <div class="imageWrapper">
                                <div style="" />
                              </div>
                            {/if}
                          </Media>
                        </div>
                        <div class="col-9 col-md-6 pl-4 textLeft alignCenter">
                          <span class="nameWrapper">
                            {item.name}
                          </span>
                        </div>
                        <div class="d-none d-md-block col-2 px-1 alignCenterIcons">
                        </div>
                      </div>
                    </Content>
                  </Card>
                {/each}
              </section>
              <!-- </VirtualList> -->
            {/if}
          </div>
        </div>
      </div>
    {:else}
      <div class="containerVod">
        <div class="row no-gutters position-relative mt-4">
          <Loading />
        </div>
      </div>
    {/if}
  </div>
  