<script>
  import { createEventDispatcher } from "svelte";
  import { Option } from "@smui/select";

  export let selected;
  export let items;
  export let width = "100";
  export let outlined = false;
  export let disabled = false;

  const dispatch = createEventDispatcher();

  $: if (selected) dispatch("changedItem", { selected });
</script>

<style>
  * :global(.mdc-select__native-control) {
    word-wrap: break-word;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap;
    display: inline-block;
    line-height: normal;
  }
</style>

<div
  class={`mdc-select w${width} ${outlined ? 'mdc-select--outlined' : 'smui-select--standard'}`}
  style="height: 2.5rem; padding-top: 0px !important; ">
  <i
    class="mdc-select__dropdown-icon"
    style="z-index: 3; right: 0px; top: 9px;" />
  <select
    class="mdc-select__native-control"
    id="SMUI-select-1"
    bind:value={selected}
    style={`height: 2.5rem; padding-left: 0.5rem; ${outlined ? '' : 'border-bottom-color: transparent'}`}>
    {#each items as { value, text }}
      <Option {value} selected={selected === value}>{text}</Option>
    {/each}
  </select>
  {#if outlined}
    <div
      class="mdc-notched-outline mdc-notched-outline--upgraded
        mdc-notched-outline--notched"
      style="height: 2.5rem;">
      <div class="mdc-notched-outline__leading" />
      <div class="mdc-notched-outline__notch" style="width: 0px;" />
      <div class="mdc-notched-outline__trailing" />
    </div>
  {/if}
</div>
