<script>
  //import FormMessage from "../ReusableLayouts/FormMessage.svelte";
  import "./FlowRegistrationForms.scss";
  import { _ } from "../../../services/i18n";
  import { completeRegistration } from "../../../services/authServices";
  import { fieldsForGooglePlace, getGenderItems } from "../../../lib/utils";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Actions } from "@smui/card";
  import CC from "../../../lib/countryCode.js";
  import CropDialog from "./CropDialog.svelte";
  import DatePicker from "../../ReusableLayouts/DatePicker.svelte";
  import Dialog from "@smui/dialog";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import LinearProgress from "@smui/linear-progress";
  import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";
  import moment from "moment";
  import MsgError from "../../ReusableLayouts/MsgError.svelte";
  import ProfileImageUploader from "./ProfileImageUploader.svelte";
  import Select, { Option } from "@smui/select";
  import Textfield from "@smui/textfield";

  export let businessLogo;
  export let businessNickname;
  export let name;
  export let phoneNumber;
  export let prefix;
  export let surname;

  let autocomplete;
  let birth_date = "";
  let birth_dateFormatted = "";
  let city = "";
  let cityAlert = false;
  let countries_code = [];
  let country = "IT";
  let countryRestrict = { country: "it" };
  let croppedSquare = true;
  let dateDialogItalian;
  let formValid;
  let gender = "male";
  let genderItems = [];
  let imageCrop = "";
  let imageDialog = false;
  let imageToBeSaved;
  let isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;
  let msgError = "";
  let placeSelected;
  let profileImage = "";
  let saving = false;

  $: dateDialogItalian = birth_date
    ? moment(birth_date).format("DD/MM/YYYY")
    : "";

  $: {
    if (country && autocomplete) {
      setAutocompleteCountry();
    }
  }

  $: if (!croppedSquare) {
    msgError = $_("immagine_quadrata");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  }

  for (const [key, value] of Object.entries(CC)) {
    countries_code.push(value);
  }

  $: genderItems = getGenderItems($_);
  $: formValid = city && gender && country && croppedSquare;

  const init = () => {
    autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */ document.getElementById("autocomplete"),
      {
        types: ["(cities)"],
        componentRestrictions: countryRestrict,
        fields: fieldsForGooglePlace,
      }
    );
    autocomplete.addListener("place_changed", onPlaceChanged);
  };

  const onPlaceChanged = () => {
    let place = autocomplete.getPlace();
    if (place.geometry) {
      placeSelected = place;
      city = placeSelected.address_components[0].long_name;
    } else {
      placeSelected = null;
      city = null;
    }
    checkValidCity();
  };

  const setAutocompleteCountry = () => {
    if (!country) {
      autocomplete.setComponentRestrictions({ country: [] });
    } else {
      autocomplete.setComponentRestrictions({ country: country });
    }
  };

  const handleImageAdded = (e) => {
    croppedSquare = false;
    profileImage = e.detail.img;
    imageCrop = URL.createObjectURL(e.detail.img);
    imageDialog.open();
  };

  const handleImageError = (e) => {
    msgError = $_("la_dimensione_massima_consentita_è_di", {
      values: {
        dimension: 5,
      },
    });
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const updateImage = (e) => {
    msgError = "";
    croppedSquare = true;
    imageToBeSaved = e.detail.image;
    profileImage = URL.createObjectURL(e.detail.image);
    const img = document.getElementById("profileImg");
    img.src = URL.createObjectURL(e.detail.image);
  };

  const checkValidCity = () => {
    cityAlert = city && country ? false : true;
  }

  const handleDate = (e) => {
    birth_date = e.detail.date;
    birth_dateFormatted = moment(birth_date).format("YYYY-MM-DD");
  };

  const submit = async () => {
    try {
      saving = true;
      const body = {
        name,
        surname,
        bussiness_nickname: businessNickname,
        business_logo: businessLogo,
        country_code: prefix,
        phoneNumber,
        image: imageToBeSaved,
        city,
        country,
        birth_date: birth_dateFormatted ? birth_dateFormatted : '1990-01-01',
        gender,
        placeSelected,
      };
      const res = await completeRegistration(body);
      saving = false;
      try {
        // TagManager Event | Registration Completed
        window.dataLayer = window.dataLayer || []; 
        dataLayer.push({'event': 'registrazione_utente'}); 
        console.log('Registration Completed');
      } catch (err) {
        console.error(err);
      }
      navigate("/team");
    } catch (err) {
      saving = false;
      console.log("err completeRegistration", err);
      msgError = $_("registrazione_non_riuscita");
      setTimeout(() => {
        msgError = "";
      }, 3000);
    }
  };

  onMount(() => {
    init();
    try {
      // TagManager Event | Registration Step One
      window.dataLayer = window.dataLayer || []; 
      dataLayer.push({'event': 'registrazione_utente_step_due'}); 
      console.log('Registration Step Two');
    } catch (err) {
      console.error(err);
    }
  });
</script>

<style>
  * :global(.headline) {
    font-size: 1.25rem;
  }

  * :global(.customUploader) {
    background-color: #f3f3f3;
    border-radius: 50%;
    height: 200px;
    overflow: hidden;
    width: 200px;
  }

  * :global(.customCard) {
    margin: 0 auto;
    text-align: center;
  }

  * :global(.primary) {
    color: var(--primary) !important;
  }
  form {
    width: 25rem;
  }

  * :global(.mdc-card__media:first-child) {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  * :global(.mdc-button__label) {
    text-transform: inherit;
  }

  .label {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1rem;
    margin-left: 0.5rem;
    opacity: 0.7;
  }

  * :global(.alignLeft) {
    text-align: left;
  }

  * :global(.mdc-text-field) {
    height: 2.8125rem;
  }

  * :global(.mdc-text-field--outlined .mdc-text-field__input) {
    padding: 1rem 0.7rem;
  }

  *
    :global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__input) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
  }

  * :global(.alignLeft) {
    text-align: left;
  }

  * :global(.mdc-text-field__icon.mdc-text-field__icon--trailing) {
    top: 0.7rem;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  * :global(.mdc-select--outlined .mdc-select__selected-text) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
    max-width: 100%;
    align-items: center;
    height: auto;
    padding: 1rem 0.7rem;
  }

  * :global(.mdc-select.mdc-select--outlined) {
    height: 2.8125rem;
    max-width: 100%;
    width: 100%;
    padding-top: 0 !important;
  }

  * :global(.mdc-select__dropdown-icon) {
    top: 0.7rem;
  }
</style>

<Dialog
  bind:this={imageDialog}
  aria-labelledby="dialog-for-cropping-image"
  aria-describedby="image-cropper">
  <CropDialog
    {imageCrop}
    squared={true}
    mustCrop={true}
    on:closeDialog={() => imageDialog.close()}
    on:imageHasBeenCropped={updateImage} />
</Dialog>

<form name="flowRegistration2" on:submit|preventDefault={submit}>
  <LinearProgress class="loading-progress-bar" progress={1} />

  <Card class="borderNone customCard" variant="outlined" padded>
    <Content class="col-12 py-3 headline">
      <div class="mb-2 demiBold">{$_('benvenuto')}</div>
      <span class="bold">{$_('ultimo_step_e_ci_siamo')}</span>
    </Content>

    {#if !isMobile}
      <ProfileImageUploader
        {profileImage}
        on:updateImageProfile={handleImageAdded}
        on:error={handleImageError} />
    {/if}

    <MsgError {msgError} />

    <Content>
      <div class="form-group alignLeft">
        <span class="label">{`${$_('paese')} *`}</span>
        <Select
          enhanced
          variant="outlined"
          class="w100 mdc-menu-surface--fullwidth"
          bind:value={country}
          label="">
          {#each countries_code as item}
            <Option value={item.iso2} selected={country === item.iso2}>
              {item.name}
            </Option>
          {/each}
        </Select>
      </div>
      <div class="form-group alignLeft">
        <span class="label">{`${$_('città')} *`}</span>
        <Textfield variant="outlined" class="w100">
          <input
            type="text"
            id="autocomplete"
            on:input={(e) => {
              if (!e.target.value) {
                placeSelected = null;
                city = null;
              }
            }}
            on:focusout={(e) => {
              checkValidCity();
            }}
            class="mdc-text-field__input" />
          <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
            <div class="mdc-notched-outline__leading" />
            <div class="mdc-notched-outline__notch" />
            <div class="mdc-notched-outline__trailing" />
          </div>
        </Textfield>
        {#if cityAlert}
          <FormMessage>
            {$_('digita_la_localita_e_scegli_una_voce_dal_menu_a_tendina')}
          </FormMessage>
        {/if}
      </div>
      <div class="form-group alignLeft">
        <span class="label">{`${$_('data_di_nascita')}`}</span>
        <DatePicker
          on:date={handleDate}
          defaultDate={dateDialogItalian}
          placeholder="01-01-1990"
          width="100"
          maxDate={moment().subtract(18, 'years').toISOString()}
          dateFormat="yyyy-mm-dd" />
      </div>
      <div class="form-group alignLeft">
        <div class="label">{`${$_('sesso')} *`}</div>
        <Select
          enhanced
          variant="outlined"
          class="w100"
          menu$class="gender-select-width"
          bind:value={gender}
          label="">
          {#each genderItems as { value, text }}
            <Option {value} selected={gender === value}>{text}</Option>
          {/each}
        </Select>
      </div>
    </Content>
    <Actions style="justify-content:flex-end" class="mr-2">
      <Button
        class="btn"
        variant="unelevated"
        type="submit"
        disabled={!formValid || saving}>
        <Label>
          <span>
            {$_('salva_e_inizia')}
          </span>
          {#if saving}
            <LoadingInline />
          {/if}
        </Label>
      </Button>
    </Actions>
  </Card>
</form>
