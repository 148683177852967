<script>
  import "flatpickr/dist/flatpickr.css";
  import "flatpickr/dist/themes/light.css";
  import { _, locale } from "../../../../services/i18n";
  import { customer } from "../../../../lib/store";
  import { getCompletedWorkout, getCompletedWorkoutsEnabledDates, } from "../../../../services/customerServices";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import { Italian } from "flatpickr/dist/l10n/it.js"
  import Button, { Icon } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import DataTable from "./DataTable.svelte";
  import flatpickr from "flatpickr";
  import Header from "../../../ReusableLayouts/Header.svelte";
  import IconButton from "@smui/icon-button";
  import List, { Item, Label, Meta } from "@smui/list";
  import moment from "moment";

  export let location;

  let calendar;
  let completedWorkouts = [];
  let currentIndexEnableDate;
  let customerId;
  let date;
  let datePicker;
  let enabledDates = [];
  let filterValue;
  let flatpickrOptions;
  let isMobile = false;
  let msgError;
  let showCalendarMobile = false;
  let trainingCardId;
  let workout;
  let workouts;
  let workoutSelected = [];

  $: {
    customerId = location.state.customerId;
    workout = location.state.workout;
    workouts = location.state.workouts;
    trainingCardId = location.state.trainingCardId;
  }
  
  $: filterValue =
    workoutSelected.length > 0
      ? `${$_("filtra")} (${workoutSelected.length})`
      : $_("tutte_le_schede");

  $: flatpickrOptions = {
    enable: enabledDates,
    locale: $locale == 'it' ? Italian : 'default',
    static: true,
    inline: true,
    position: "auto left",
    onChange: (selectedDates, dateStr, instance) => {
      date = dateStr;
    },
  };

  $: if (workoutSelected.length > 0) {
    getEnabledDates();
  }
  $: if (date && workoutSelected) {
    getCompletedWorkouts();
  }

  $: {
    if (enabledDates.length > 0) {
      calendar.destroy();
      calendar = flatpickr(datePicker, flatpickrOptions);
    }
  }

  // $: isMobile = window.screen.width < 896 && navigator.userAgent.indexOf("Mobi") > -1;
  $: isMobile = window.screen.width < 896;

  $: if (calendar && showCalendarMobile && isMobile)
    document
      .getElementsByClassName("flatpickr-wrapper")[0]
      .classList.remove("hide");
  else if (calendar && isMobile) {
    document
      .getElementsByClassName("flatpickr-wrapper")[0]
      .classList.add("hide");
  }

  const getCompletedWorkouts = async () => {
    try {
      const res = await getCompletedWorkout(
        customerId,
        trainingCardId,
        date,
        workoutSelected.join(",")
      );
      completedWorkouts = res;
    } catch (err) {
      console.log("getCompletedWorkouts err", err);
      msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 3000);
    }
  };

  const getEnabledDates = async () => {
    try {
      const res = await getCompletedWorkoutsEnabledDates(
        customerId,
        workoutSelected
      );
      enabledDates = res.reverse().sort();
      date = enabledDates[enabledDates.length - 1];
      currentIndexEnableDate = enabledDates.length - 1;
    } catch (err) {
      console.log("getEnabledDates err", err);
      msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 3000);
    }
  };

  const goToPreviousDate = () => {
    date = enabledDates[currentIndexEnableDate - 1];
    currentIndexEnableDate--;
    calendar.setDate(date);
  };

  const goToNextDate = () => {
    date = enabledDates[currentIndexEnableDate + 1];
    currentIndexEnableDate++;
    calendar.setDate(date);
  };

  onMount(() => {
    calendar = flatpickr(datePicker, flatpickrOptions);
    workoutSelected = [workout.workout_id];
    date = enabledDates[enabledDates.length - 1];
    calendar.setDate(date);
  });
</script>

<style>
  * :global(.customButtom) {
    background-color: white;
    border: 1px solid black;
    color: black;
    font-size: 0.8rem;
    height: 40px;
    padding: 10px 10px;
    text-transform: capitalize;
    width: fit-content;
  }
  * :global(.mdc-button) {
    width: -webkit-fill-available;
  }
  * :global(.calendarColumn) {
    margin-top: -85px;
    padding-top: 90px;
  }
  * :global(.calendarColumnMobile) {
    display: flex;
    width: 100%;
  }
  * :global(.flatpickr-calendar) {
    border: 1px solid #dfe4ef;
    box-shadow: none;
    margin: 0 auto;
    max-width: 100%;
  }
  * :global(.flatpickr-rContainer) {
    max-width: 100%;
  }
  * :global(.flatpickr-days) {
    border: none;
    max-width: 100%;
  }
  * :global(.dayContainer) {
    max-width: 100%;
    min-width: unset;
  }
  * :global(.flatpickr-current-month .flatpickr-monthDropdown-months) {
    background: inherit;
  }
  * :global(.flatpickr-months .flatpickr-month) {
    background: inherit;
  }

  * :global(.flatpickr-weekdays) {
    background: inherit;
  }

  * :global(span.flatpickr-weekday) {
    background: inherit;
  }
  * :global(.list) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  * :global(.mdc-data-table__header-row)  {
    color: #212121;
    font-size: 14px;
    opacity: 0.5;
    text-align: center;
  }
  * :global(.mdc-data-table__header-cell) {
    text-align: center;
  }
  * :global(.mdc-data-table__header-cell) {
    text-align: center;
  }
  * :global(.customDataTable) {
    text-align: center;
  }
  :global(.hide) {
    display: none !important;
  }
  .show {
    display: block;
  }
  .sidebarMobile {
    display: flex;
    flex-wrap: wrap;
  }

  * :global(.mb-sidebar-mobile__flatpickrOpen) {
    margin-bottom: 320px;
  }
  
  @media (max-width: 896px) {
    * :global(.completed-workouts-calendar .flatpickr-wrapper) {
      position: absolute;
      top: 50px;
      background-color: white;
    }   
  }
</style>

<Header
  text={`${workout.workout_name} - ${$customer.first_name} ${$customer.last_name} `}
  asTextAndIcon={true}
  noBackground={true}
  on:goBack={() => navigate(`/customer?tab=workouts`, {
      state: { customerId },
    })} />

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

<div class="container fluid mt-2">
  <div class="row">
    <div class={`col-12 col-lg-3 mb-3 ${isMobile ? 'sidebarMobile' : ''}`}>
      <div class="sticky-top completed-workouts-calendar {isMobile ? `calendarColumnMobile` : `calendarColumn`} {isMobile && showCalendarMobile ? `mb-sidebar-mobile__flatpickrOpen` : ``}"> 
        <div
          class="dropdown"
          style={isMobile ? 'flex:80%; align-self: center;' : ''}>
          <Button
            color="secondary"
            variant="outlined"
            class="customButtom"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <Label>
              {filterValue}
              <Icon class="material-icons">keyboard_arrow_down</Icon>
            </Label>
          </Button>
          <div class="dropdown-menu w100" aria-labelledby="dropdownMenuButton">
            <List checklist class="list">
              {#each workouts as workout}
                <Item class="w100">
                  <Meta class="ml-0">
                    <Checkbox
                      bind:group={workoutSelected}
                      value={workout.workout_id} />
                  </Meta>
                  <Label class="mb-0">{workout.workout_name}</Label>
                </Item>
              {/each}
            </List>
          </div>
        </div>
        <IconButton
          class="material-icons d-block d-lg-none"
          on:click={() => (showCalendarMobile = !showCalendarMobile)}>
          event
        </IconButton>
        <div class="flatpickr mt-3" id="my-picker" bind:this={datePicker}>
          <input class="hide" type="text" data-input />
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-9 pr-0">
      <div class="mdc-typography--headline5 flexy">
        <IconButton
          class="material-icons"
          disabled={currentIndexEnableDate == 0}
          on:click={goToPreviousDate}>
          keyboard_arrow_left
        </IconButton>
        <span class="bold">{moment(date).format('DD MMMM YYYY')}</span>
        <IconButton
          class="material-icons"
          disabled={currentIndexEnableDate == enabledDates.length - 1}
          on:click={goToNextDate}>
          keyboard_arrow_right
        </IconButton>
      </div>
      <hr />
      {#each completedWorkouts as workout, i}
        <DataTable {workout} />
      {/each}
    </div>
  </div>
</div>
