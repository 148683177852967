<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import { _ } from "../../../services/i18n";
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import Slider from "@smui/slider";
  import Cropper from "svelte-easy-crop";
  import Button, { Label } from "@smui/button";
  import "./CropDialog.scss";

  export let imageCrop;
  export let squared;
  export let mustCrop;
  let crop = { x: 0, y: 0 };
  let cropSize = { width: 272, height: 272 };
  let zoom = 1;
  let croppedAreaPixels = null;
  let croppedImage = null;

  $: if (imageCrop) {
    document.getElementById("cropper").childNodes[0].className =
      "container cropper svelte";
    document.getElementById(
      "cropper"
    ).childNodes[0].childNodes[2].className = `cropperArea svelte ${
      squared ? "" : ""
    }`;
  }

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    canvas.width = squared ? pixelCrop.height : pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const x = squared ? pixelCrop.x : pixelCrop.x;
    ctx.drawImage(
      image,
      x,
      pixelCrop.y,
      canvas.width,
      pixelCrop.height,
      0,
      0,
      canvas.width,
      pixelCrop.height
    );

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => resolve(file));
    });
  };

  const saveImage = async () => {
    const cropped = await getCroppedImg(imageCrop, croppedAreaPixels);
    zoom = 1;
    croppedImage = cropped;
    dispatch("imageHasBeenCropped", {
      image: cropped,
    });
  };

  const closeDialog = () => {
    dispatch("closeDialog", {});
  };
</script>

<Title>{$_('edita_immagine')}</Title>
<Content><span>{$_('trascina_immagine_per_tagliare')}</span></Content>
<Content style="height: 27em !important;" id="cropper">
  <Cropper
    image={imageCrop}
    bind:crop
    bind:zoom
    bind:cropSize
    minZoom={0.4}
    maxZoom={3}
    restrictPosition={false}
    on:cropcomplete={(e) => {
      croppedAreaPixels = e.detail.pixels;
    }} />
</Content>
<Content class="pt-2">
  <span>{$_('regola_lo_zoom')}</span>
  <Slider id="zoomSlider" bind:value={zoom} min={0.4} max={3} />
</Content>
<Actions
  style={`${mustCrop ? 'justify-content:flex-end' : 'justify-content:space-between'}`}>
  {#if !mustCrop}
    <Button on:click={closeDialog}>
      <Label>{$_('chiudi')}</Label>
    </Button>
  {/if}
  <Button on:click={saveImage}>
    <Label>{$_('salva')}</Label>
  </Button>
</Actions>
