<script>
  import { createEventDispatcher } from "svelte";
  import Button, { Label } from "@smui/button";
  import Dialog from "@smui/dialog";
  import Card, { Content } from "@smui/card";
  import Textfield, { Input } from "@smui/textfield";
  import IconButton from "@smui/icon-button";
  import Deal from "./Deal.svelte";
  const dispatch = createEventDispatcher();

  export let deals = [];

  let newDealDialog;
  let panels = [];
  let orderChanged = false;

  $: if (deals) panels = new Array(deals.length).fill(false);

  const forwardSaveDeal = (e) => {
    dispatch("saveDeal", { deal: e.detail.deal });
    newDealDialog.close();
  };

  const moveDeal = (index, direction) => {
    orderChanged = true;
    const deal = deals[index];
    const dealToBeSwapped = deals[direction === "up" ? index - 1 : index + 1];
    let reordered = [];
    if (direction === "up") {
      if (index === 0) return;
      const firstPart = deals.slice(0, index - 1);
      const secondPart = deals.slice(index + 1, deals.length);
      reordered = [...firstPart, deal, dealToBeSwapped, ...secondPart];
    } else {
      if (index === deals.length - 1) return;
      const firstPart = deals.slice(0, index);
      const secondPart = deals.slice(index + 2, deals.length);
      reordered = [...firstPart, dealToBeSwapped, deal, ...secondPart];
    }
    dispatch("dealsReordered", {
      deals: reordered,
    });
  };
</script>

<style>
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
  }
  .dealList {
    background-color: #f9f9f9;
    padding: 16px;
  }
  .hide {
    display: none;
  }
  .titleWrapper {
    text-align: start;
    align-items: center;
    display: flex;
    opacity: 0.8;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>

<Dialog
  bind:this={newDealDialog}
  aria-labelledby="dialog-for-new-deal"
  aria-describedby="new-deal">
  <Deal on:cancel={newDealDialog.close()} on:saveDeal={forwardSaveDeal} />
</Dialog>

<div class="row mx-0 spaceBetween py-4">
  <Button
    class="btn"
    variant="unelevated"
    on:click={() => newDealDialog.open()}>
    <Label>create deal</Label>
  </Button>
  <Button
    class="btn"
    disabled={!orderChanged}
    variant="unelevated"
    color="secondary"
    on:click={() => dispatch('saveOrder', {})}>
    <Label>save deals order</Label>
  </Button>
</div>

{#each deals as deal, i}
  <Card class="mb-3">
    <Content>
      <div
        class="row px-2 spaceBetween"
        on:click={() => (panels[i] = !panels[i])}>
        <div class="col-9 titleWrapper bold">{deal.brand_name}</div>
        <div class="col-3 iconWrapper">
          <div on:click|stopPropagation={() => moveDeal(i, 'up')}>
            <IconButton disabled={i == 0} class="material-icons">
              arrow_circle_up
            </IconButton>
          </div>
          <div on:click|stopPropagation={() => moveDeal(i, 'down')}>
            <IconButton
              disabled={i == deals.length - 1}
              class="material-icons ml-1">
              arrow_circle_down
            </IconButton>
          </div>
          <IconButton class="material-icons ml-1">
            keyboard_arrow_down
          </IconButton>
        </div>
      </div>
    </Content>
    <div class={`dealList ${panels[i] ? '' : 'hide'}`}>
      <Deal
        {deal}
        panel={panels[i]}
        on:cancel={() => (panels[i] = !panels[i])}
        on:saveDeal={(e) => {
          dispatch('saveDeal', { deal: e.detail.deal });
          panels[i] = !panels[i];
        }} />
    </div>
  </Card>
{/each}
