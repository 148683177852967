<script>
  import { _ } from "../../../services/i18n";
  import BillingOverview from "./BillingOverview.svelte";
  import NextChargeDetails from "./NextChargeDetails.svelte";
  import InvoicesHistory from "./InvoicesHistory.svelte";
  import PaymentDetails from "./PaymentDetails.svelte";
  import ReferralDetails from "./ReferralDetails.svelte";
  import BannerSubscriptionStatus from "../../ReusableLayouts/BannerSubscriptionStatus.svelte";

  let options = {
    overview: BillingOverview,
    nextCharge: NextChargeDetails,
    referral: ReferralDetails,
    history: InvoicesHistory,
    payment: PaymentDetails,
  };
  let props = {};

  let component = "overview";

  const handleSetComponent = (e) => {
    component = e.detail.component;
    props = e.detail.props;
  };

  const handleSaveBillingInfo = (e) => {
    dispatch("saveBillingInfo", {
      body: e.detail.body,
    });
  };
</script>

<div class="pt-3">
  <BannerSubscriptionStatus updateDataButton={false} />
</div>
<svelte:component
  this={options[component]}
  {...props}
  on:setComponent={handleSetComponent}
  on:saveBillingInfo={handleSaveBillingInfo} />
