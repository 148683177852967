export const checkForm = (obj) => {
  let validityCheck = {};
  for (let item of Object.keys(obj)) {
    if (item == "metabolic_age") {
      validityCheck[item] = obj[item] > 99 ? true : false;
    } else if (
      ["body_fat_perc", "visceral_fat", "body_temperature", "tbw"].includes(
        item
      )
    ) {
      validityCheck[item] = obj[item] > 99.9 ? true : false;
    } else if (item == "tda" || item == "bmr") {
      validityCheck[item] = obj[item] > 9999 ? true : false;
    } else if (item != "customer_id") {
      validityCheck[item] = obj[item] > 999.9 ? true : false;
    } else continue;
  }
  return validityCheck;
};

export const checkPlicoForm = (obj, keys) => {
  let validityCheck = {};
  if (keys) {
    keys.some(k => {
      if (k == "age") {
        validityCheck[k] = obj[k] > 99 ? true : false;
      } else {
        validityCheck[k] = obj[k] > 99.9 ? true : false;
      }
    });
  }
  return validityCheck;
};
