<script>
	import { _ } from "../../../services/i18n";
	import { createEventDispatcher, onMount } from "svelte";
	import { storeTrainingCardAttachment } from "../../../services/trainingCardServices";
  import Button, { Icon, Label } from '@smui/button';
  import CharacterCounter from "../../ReusableLayouts/CharacterCounter.svelte";
	import Dialog, { Title, Content, Actions } from '@smui/dialog';
  import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";
  import Select, { Option } from "@smui/select";
  import Textfield from "@smui/textfield";

	const dispatch = createEventDispatcher();

	export let attachment;
	export let buttonText = $_('carica');
	export let openDialog;
	export let categories = [];
	export let trainingCardId;

	let attachmentRawFile;
	let attachmentUrl;
	let dialog;
	let input;
	let label;
	let labelVal;
	let msgError = "";
	let saving = false;
	let savingDisabled = true;

	$: savingDisabled = attachment.id 
		? !attachment.name || !attachment.category || saving
		: !attachment.name || !attachment.category || saving || !attachment.file 

	$: if (openDialog == true) {
		dialog.open();
		label.innerHTML = buttonText;
	}
	
	categories = [
    { label: $_("alimentazione"), value: 0 },
    { label: $_("modulo_progressi"), value: 1 },
    { label: $_("calendario"), value: 2 },
    { label: $_("guida"), value: 3 },
    { label: $_("altro"), value: 4 },
  ];

	const getShortFileName = (text) => {
		if (text.length >= 18) {
			let justFileName = text.substring(text.length - 4, 0);
			let extension = text.substring(text.length, text.length - 4);
			return justFileName.substring(0, 5) + '...' + justFileName.substring(justFileName.length, justFileName.length - 2) + extension;
		}
		return text;
	}

	const onInputChange = async (e) => {
		const file = e.target.files[0];
		if (file && file.size > 5000000) {
			msgError = $_("la_dimensione_massima_consentita_è_di", {
				values: {
					dimension: 5,
				},
			})
			setTimeout(() => {
				msgError = "";
			}, 3000);
			return;
		} else {
			let fileName = e.target.value.replace(/^.*[\\\/]/, '');
			if (fileName) {
				label.innerHTML = getShortFileName(fileName);
				if (attachment.id)
					document.getElementById('fileAlreadyUploaded').style.display = "none";
			} else {
				label.innerHTML = labelVal;
			}
			attachment.file = file;
			updateElements();
		}
	};

	const storeAttachment = async () => {
		try {
			saving = true;
			await storeTrainingCardAttachment(attachment, trainingCardId);
			input.value = '';
			label.innerHTML = buttonText;
			saving = false;
			openDialog = false;
			dialog.close();
			dispatch("fetchAttachments");
			dispatch("closeAttachmentDialog");
		} catch (err) {
			if (err.status == 422) {
				displayError(err.message.message);
			}
			console.log("storeAttachment error", err);
			saving = false;
		}
	}

	const displayError = (error) => {
		msgError = error;
		setTimeout(() => {
			msgError = "";
		}, 5000);
	};


	const updateElements = () => {
		input = document.getElementById('file');
		label = document.getElementById('labelValue');
		labelVal = label.innerHTML;
	}

	onMount(async () => {
		updateElements();
	});
</script>

<div>
	<Dialog
		bind:this={dialog} 
    class="dialog--medium"
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="mandatory-title"
    aria-describedby="mandatory-content">
		<Title class="justify-content-center">
			<h5 class="bold pt-3">{attachment.id ? $_('modifica_allegato') : $_('aggiungi_allegato')}</h5>
		</Title>
		<Content>
			<div class="row">
				{#if msgError}
					<div class="col-12">
						<div class="alert alert-danger fade show mt-2" role="alert">
							{msgError}
						</div>
					</div>
				{/if}
				<div class="col-4 label">{$_('nome_allegato')}</div>
				<div class="col-8">
					<div class="row d-flex align-items-center">
						<div class="col-11">
							<Textfield
								variant="outlined"
								class="w100 my-2"
								input$maxlength="36"
								bind:value={attachment.name}
								type="text"
								label=''
							/>
						</div>
					</div>
				</div>
				<div class="col-4 label">{$_('categoria')}</div>
				<div class="col-8">
					<div class="row d-flex align-items-center">
						<div class="col-11">
							<Select 
								enhanced
								variant="outlined"
								class="w100 mb-2"
								label=""
								bind:value={attachment.category}
							>
								{#each categories as category}
									<Option
										value={category.value}
										selected={attachment.category === category.value}
									>
										{category.label}
									</Option>
								{/each}
							</Select>
						</div>
					</div>
				</div>
				<div class="col-4 mt-2 label">{$_('file')}</div>
				<div class="col-8 mt-2">
					<div class="row">
						<div class="d-flex flex-column flex-md-row align-items-sm-center justify-content-center" style="padding-left: 0.95em;">
							{#if attachment.filename && attachment.exists}
								<strong class="pr-4 demibold" id="fileAlreadyUploaded">{getShortFileName(attachment.filename)}</strong>
							{/if}
							<input type="file" name="file" id="file" class="inputfile" accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" bind:files={attachmentRawFile} on:change={onInputChange}/>
							<label for="file" class="d-flex align-items-center justify-content-center mt-2 px-2">
								<Icon role="button" class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing pr-1">
									note_add
								</Icon>
								<span class="px-1" id="labelValue">
									{buttonText}
								</span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<hr />
		</Content>
		<div class="attachment-dialog__actions d-flex justify-content-center">
			<Button
					class="secondaryButton btn"
					style=" background-color: white !important; color: #cd293d !important; border: 1px solid white !important;"
					variant="unelevated" 
					on:click={() => { 
						input.value = '';
						dialog.close();
						openDialog = false;
						dispatch("closeAttachmentDialog");
					}}
			>
					<Label>{$_('annulla')}</Label>
			</Button>
			<Button
					class="primaryButton btn ml-2"
					variant="unelevated"
					disabled={savingDisabled}
					on:click={() => storeAttachment()}
			>
					<Label>
						<span class="{saving ? `pr-2` : ``}">
							{$_('salva')}
						</span>
						{#if saving}
							<LoadingInline white={true} />
						{/if}
					</Label>
			</Button>
		</div>
	</Dialog>
</div>

<style>
	.inputfile {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.inputfile + label {
		border-radius: 5px;
    height: 36px;
		min-width: 7em;
		font-size: 1em;
    font-weight: 600;
    color: white;
    background-color: black;
    display: inline-block;
	}

	.inputfile:focus + label,
	.inputfile + label:hover {
			background-color: var(--primary);;
	}

	.inputfile + label {
		cursor: pointer;
	}

	.inputfile:focus + label {
		outline: 1px dotted #000;
		outline: -webkit-focus-ring-color auto 5px;
	}
  * :global(.mdc-button__label) {
    text-align: left;
  }
  .label {
    text-align: end;
    align-self: center;
    opacity: 0.8;
    font-weight: 600;
  }
  * :global(.icon--help) {
    color: rgba(0, 0, 0, 0.54) !important;
  }
  * :global(.tags-textfield) {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
  }
  
  * :global(.tags-textfield input) {
    height: 2.2rem !important;
  }

  * :global(.textUppercase) {
    text-transform: uppercase;
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }

	/* DIALOG */
	h5 {
    padding: 0;
  }
  * :global(.mdc-button) {
    background-color: #cd293d !important;
    color: white !important;
    border: 1px solid #cd293d !important;
    width: 160px !important;
  }
  * :global(.mdc-button .secondaryButton) {
    background-color: white !important;
    color: #cd293d !important;
    border: 1px solid white !important;
    width: 160px !important;
  }

  * :global(.mdc-dialog__title + .mdc-dialog__content) {
    padding: 0px 24px;
  }
  * :global(.mdc-dialog__title) {
    display: flex;
		margin-bottom: 24px;
    padding: 10px 39px;
    background-color: #f9f9f9;
    opacity: 0.8;
  }
  * :global(.mdc-button.secondaryCustom) {
    background-color: #000 !important;
    border-color: black !important;
    color: #fff !important;
    max-width: 105px !important;
    padding: 0 10px !important;
  }

	* :global(.mdc-dialog__surface .mdc-dialog__title) {
    display: flex;
    padding: 10px 39px;
    background-color: #f9f9f9;
    opacity: 0.8;
  }

	* :global(.mdc-dialog__content) {
		padding-top: 30px;
	}

	.attachment-dialog__actions {
		padding: 22px 0;
	}

	@media (max-width: 767px) {
    * :global(.mdc-dialog__content) {
      max-height: fit-content;
      flex-grow: 0;
    }
  }
</style>
