<script>
  import { createEventDispatcher } from "svelte";
  import moment from "moment";
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import Button, { Icon, Label } from "@smui/button";
  import SvelteTooltip from 'svelte-tooltip';
  import PlicoDialog from "./PlicoDialog.svelte";
  import { _ } from "../../../../services/i18n";
  import Textfield from "@smui/textfield";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import DatePicker from "../../../ReusableLayouts/DatePicker.svelte";
  import {
    createNewCircumference,
    createNewWeight,
    createNewComposition,
  } from "../../../../services/teamServices.js";
  import { checkForm } from "./ProgressAndCheckValidation/ProgressAndCheckValidation";

  const DATEFORMAT = "YYYY-MM-DD";
  const dispatch = createEventDispatcher();

  export let openDialog;
  export let group;
  export let customerId;
  export let defaultDialogDate;
  export let elements;

  let plicometryGenerated = false;
  let objectDialog;
  let dateDialog = moment(new Date()).format(DATEFORMAT);
  let refresh = false;
  let dialog;
  let validityCheck = {};
  let isFormInvalid = false;
  let emptyDialog = true;
  let isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  // Plicometrics
  let openPlicoDialog = false;

  let is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));

  $: {
    if (objectDialog)
      for (let k in objectDialog) {
        if (k == "customer_id") continue;
        else if (!objectDialog[k] || objectDialog[k].length == 0)
          emptyDialog = true;
        else {
          emptyDialog = false;
          break;
        }
      }
  }

  $: if (objectDialog) validityCheck = checkForm(objectDialog);
  $: isFormInvalid =
    Object.values(validityCheck).some((e) => e == true) || emptyDialog;

  $: dateDialogItalian = moment(dateDialog).format("DD/MM/YYYY");

  $: {
    if (openDialog == true) {
      if (elements.length != 0) {
        dateDialog = defaultDialogDate
          ? defaultDialogDate
          : moment(new Date()).format(DATEFORMAT);
        refresh = false;
        objectDialog = {};
        objectDialog.customer_id = customerId;
        for (let row of elements) {
          for (let ele of row) {
            objectDialog[ele.key] = ele.value ? ele.value : "";
            validityCheck[ele.key] = false;
          }
        }
      }
      dialog.open();
    }
    if (dialog && dialog.isOpen()) {
      dispatch("close", { group });
    }
  }

  const saveObj = async () => {
    try {
      const objectToSave = {};
      for (const [key, value] of Object.entries(objectDialog)) {
        // add decimals according to key
        const float = parseFloat(value);
        objectToSave[key] = value
          ? ["metabolic_age", "bmr", "tda", "customer_id"].includes(key)
            ? parseInt(float)
            : float.toFixed(1)
          : null;
      }
      objectToSave.created_at = dateDialog;
      if (group == "composition") {
        let { customer_id, created_at, weight_base } = objectToSave;
        if (weight_base == "NaN") weight_base = null;
        for (let key in objectToSave) {
          if (key != 'created_at' && isNaN(objectToSave[key])) objectToSave[key] = null;
        }
        const promises = [
          createNewWeight({
            customer_id,
            created_at,
            weight: weight_base,
            note: null,
          }),
          createNewComposition(objectToSave),
        ];
        await Promise.all(promises);
      } else if (group == "circumferences") {
        await createNewCircumference(objectToSave);
      }
      refresh = true;
      dispatch("refresh", { refresh, group, date: dateDialog });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDate = (e) => {
    const { date } = e.detail;
    dateDialog = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
  };

  const handleOpenPlicoDialog = (e) => {
    plicometryGenerated = false;
    openPlicoDialog = true;
  };

  const handleClosePlicoDialog = (e) => {
    openPlicoDialog = false;
  };

  const handlestoreBodyFatPerc = async (e) => {
    objectDialog.body_fat_perc = Math.round(e.detail.plicometry.body_fat_perc * 10) / 10;
  };

  const keyCheck = (key) => {
    let check =  
        key != 'metabolic_age' &&
        key != 'bmr'  &&
        key != 'tda';
    return check;
  }

  const isRevooPtApp = () => {
    return /Convertify/i.test(navigator.userAgent);
  }

  const isAndroidDevice = () => {
    return /Android/i.test(navigator.userAgent);
  }

  // isAndroidVersion < 10
  function isOldAndroid() {
    if (isAndroidDevice() && !isRevooPtApp()) {
      let ua = navigator.userAgent.toLowerCase();
      var match = ua.match(/android\s([0-9\.]*)/i);
      if (match && match[1]) {
        return parseInt(match[1], 10) < 10;
      }
    }
    return false;
  };

  const handleNumericTextFieldOnKeyDown = (e, key) => {
    if (!isOldAndroid()) {
      if (e.keyCode == 110 || e.keyCode == 190 || e.keyCode == 188 || e.keyCode == 229 || e.key == "." || e.key == "," || e.code == "NumpadDecimal") {
        e.preventDefault();
        e.stopPropagation();
        e.target.value = keyCheck(key) ? parseFloat(e.target.value).toFixed(1) : Number(e.target.value);
        return false;
      }
    }
  }

  const handleNumericTextFieldOnInput = (e) => {
    if (!isOldAndroid()) {
      if (!isNaN(e.data) && (e.target.value % 1 != 0)) {
        e.target.value = Math.trunc(e.target.value) + (e.data / 10);
      }
    }
  }
</script>

<style>
  h5 {
    padding: 0;
  }
  * :global(.mdc-button) {
    background-color: #cd293d !important;
    color: white !important;
    border: 1px solid #cd293d !important;
    width: 160px !important;
  }
  * :global(.mdc-button .secondaryButton) {
    background-color: white !important;
    color: #cd293d !important;
    border: 1px solid white !important;
    width: 160px !important;
  }

  * :global(.mdc-dialog__title + .mdc-dialog__content) {
    padding: 0px 24px;
  }
  * :global(.mdc-dialog__title) {
    display: flex;
    padding: 10px 39px;
    background-color: #f9f9f9;
    opacity: 0.8;
  }
  * :global(.mdc-button.secondaryCustom) {
    background-color: #000 !important;
    border-color: black !important;
    color: #fff !important;
    max-width: 105px !important;
    padding: 0 10px !important;
  }

  * :global(.mdc-button.outlinedCustom span) {
    font-size: 0.9rem;
  }
  .textLabel {
    color: #212121;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1rem;
    margin-left: 0.3rem;
    opacity: 0.7;
  }
  * :global(.textfield--short) {
    width: 105px !important;
  }
  .spaceBetween {
    display: flex;
    justify-content: space-between;
  }
  * :global(.mdc-text-field__icon--trailing.customLabel) {
    color: #212121;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    opacity: 0.5;
  }

  @media (max-width: 767px) {
    * :global(.mdc-dialog__content) {
      max-height: fit-content;
      flex-grow: 0;
    }
  }
</style>

<div>
  <Dialog class="dialog--xxsmall" bind:this={dialog}>
    <Title>
      <h5 class="bold pt-3">
        {#if emptyDialog}
          {group == 'composition' ? $_('nuovo_rilevamento_composizione') : $_('nuovo_rilevamento_misure')}
        {:else}
          {$_('modifica_rilevazione')}
        {/if}
      </h5>
    </Title>
    <Content>
      <div class="container-fluid">
        <div class="row my-3">
          <div class="col-7">
            <DatePicker
              on:date={handleDate}
              defaultDate={dateDialogItalian}
              width="100" />
          </div>
        </div>

        {#each elements as row}
          <div class="row no-gutters spaceBetween mb-2">
            {#each row as ele}
              {#if ele.role == 'button' && ele.key == 'plicometry'}
                <div>
                  <div class="textLabel mb-1">{$_('plicometria')}</div>
                  <div>
                    {#if !isMobile}
                      <SvelteTooltip tip="Calcola BF%" top >
                        <Button
                          class="btn secondaryCustom"
                          variant="unelevated"
                          on:click={handleOpenPlicoDialog}>
                          <Label class="d-flex justify-content-center align-items-center">
                            <Icon
                              role="button"
                              class="material-icons mdc-text-field__icon
                                mdc-text-field__icon--trailing">
                              straighten
                            </Icon>
                            <span>
                              {$_('calcola')}
                            </span>
                          </Label>
                        </Button>
                      </SvelteTooltip>
                    {:else}
                      <Button
                        class="btn secondaryCustom"
                        variant="unelevated"
                        on:click={handleOpenPlicoDialog}>
                        <Label class="d-flex justify-content-center align-items-center">
                          <Icon
                            role="button"
                            class="material-icons mdc-text-field__icon
                              mdc-text-field__icon--trailing">
                            straighten
                          </Icon>
                          <span>
                            {$_('calcola')}
                          </span>
                        </Label>
                      </Button>
                    {/if}
                  </div>
                </div>
              {:else}
                <div>
                  <div class="textLabel mb-1">{ele.label}</div>
                  <Textfield
                    withTrailingIcon
                    class={`textfield--short parameter ${validityCheck[ele.key] ? 'mdc-text-field--invalid' : ''}`}
                    bind:value={objectDialog[ele.key]}
                    variant="outlined"
                    label=""
                    input$min={0}
                    input$max={100}
                    input$step={.1}
                    input$pattern={"\d+((\.|,)\d+)?"}
                    on:keydown={(e) => handleNumericTextFieldOnKeyDown(e, ele.key)}
                    on:input={handleNumericTextFieldOnInput}
                    type="{isOldAndroid() && !isRevooPtApp() ? `tel` : `number`}"
                    id="parameter--{ele.key}">
                    <div>
                      {#if ele.unit}
                        <Label
                          class="mdc-text-field__icon
                            mdc-text-field__icon--trailing customLabel">
                          {ele.unit}
                        </Label>
                      {/if}
                    </div>
                  </Textfield>
                  {#if validityCheck[ele.key]}
                    <FormMessage>
                      {$_('il_valore_inserito_non_è_valido')}
                    </FormMessage>
                  {/if}
                </div>
              {/if}
            {/each}
          </div>
        {/each}
      </div>
      <hr />
    </Content>
    <Actions style="justify-content: center">
      <Button
        class="secondaryButton btn"
        variant="unelevated"
        style=" background-color: white !important; color: #cd293d !important;
        border: 1px solid white !important;"
        on:click={() => dialog.close()}>
        <Label>{$_('annulla')}</Label>
      </Button>
      <Button
        disabled={isFormInvalid}
        class="primaryButton btn ml-2"
        variant="unelevated"
        on:click={saveObj}>
        <Label>{$_('salva')}</Label>
      </Button>
    </Actions>
  </Dialog>
  <PlicoDialog
    {openPlicoDialog}
    {customerId}
    bind:dateDialog
    bind:dateDialogItalian
    bind:plicometryGenerated
    on:close={handleClosePlicoDialog}
    on:storeBodyFatPerc={handlestoreBodyFatPerc} />
</div>
