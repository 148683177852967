<script>
  import { _ } from "../../../../services/i18n";
  import DataTable, { Head, Row, Cell } from "@smui/data-table";
  import IconButton from "@smui/icon-button";
  import SvgIcon from "../../../ReusableLayouts/SvgIcon.svelte";
  import DataTableBody from "./DataTableBody.svelte";
  import { reviewCry, reviewOk, reviewHappy } from "../../../../lib/icons";
  import { fancyTimeFormat } from "../../../../lib/utils";

  export let workout;

  let clamp = true;
  const headers = [
    $_("esercizio"),
    $_("tipologia"),
    $_("set"),
    $_("reps"),
    $_("peso"),
    $_("volume"),
  ];

  const getSatisfaction = (satisfaction) => {
    if (satisfaction == 1) return reviewHappy;
    else if (satisfaction == 2) return reviewOk;
    else if (satisfaction == 3) return reviewCry;
    else return "";
  };
</script>

<style>
  * :global(.customButtom) {
    height: 40px;
    padding: 10px 10px;
    width: fit-content;
    font-size: 0.8rem;
    text-transform: capitalize;
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  * :global(.list) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  * :global(.mdc-data-table__header-cell) {
    text-align: center;
  }
  * :global(.mdc-data-table__header-cell) {
    text-align: center;
  }
  * :global(.customDataTable) {
    text-align: center;
  }
  .noteSpan {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
</style>

<div class="flexy mt-2 mb-3">
  <span>
    <span class="bold mdc-typography--headline5 mr-2">
      {workout.workout_name}
    </span>
    {#if workout.is_progression && workout.progression_rank}
      <span class="bold mdc-typography--headline5 mr-2">
        ({$_('settimana')} {workout.progression_rank})
      </span>
    {/if}
    {`${$_('durata')} ${fancyTimeFormat(workout.total_time)}`}
  </span>
</div>
<div class="row no-gutters flexy">
  {#if workout.note}
    <SvgIcon
      class="col-1"
      paths={getSatisfaction(workout.satisfaction)}
      viewBox="0 0 40 40" />
    <span
      class="my-2 noteSpan col-10"
      style={`-webkit-line-clamp: ${clamp ? '1' : '0'}`}>
      {workout.note}
    </span>
    <IconButton class="material-icons col-1" on:click={() => (clamp = !clamp)}>
      {`keyboard_arrow_${clamp ? 'down' : 'up'}`}
    </IconButton>
  {/if}
</div>
<DataTable
  class="w100 mb-3 customDataTable"
  table$aria-label="Completed workout">
  <Head>
    <Row>
      {#each headers as header}
        <Cell>{header}</Cell>
      {/each}
    </Row>
  </Head>
  <DataTableBody {workout} />
</DataTable>
