<script>
  import { beforeUpdate, onMount } from "svelte";
  import { getEvents, getEventsCustomer } from "../../services/calendar";

  export let events;
  export let qs;
  export let updateEvents;
  let localExtendedProps = {};
  let updateEventAlreadyCalled = false;

  beforeUpdate(async () => {
    if (updateEvents && !updateEventAlreadyCalled) {
      // check if qs is empty
      const filteredSearch = {};
      for (const [key, value] of Object.entries(qs)) {
        if (value) filteredSearch[key] = value;
      }
      const querystring = Object.entries(filteredSearch);
      //if qs isn't empty not accept other updates
      if (querystring.length > 0) {
        updateEventAlreadyCalled = true;
        await handleEvents(filteredSearch);
        updateEvents = false;
        updateEventAlreadyCalled = false;
      }
    }
  });

  //cicla nell'array (customers o at_groups) per popolare l'attributo "extendedProps" del calendar
  // "extendedProps" verrà utilizzato per mostrare i nomi dei clienti nella views "listWeek" del calendar
  const handleExtendedProps = (array, value) => {
    if (value == "customer")
      for (let i = 0; i < array.length; i++) {
        localExtendedProps[`name${i + 1}`] = array[i].first_name
          ? `${array[i].first_name} ${array[i].last_name}`
          : array[i].customer_email;
      }
    else if (value == "groups")
      for (let i = 0; i < array.length; i++) {
        localExtendedProps[`name${i + 1}`] = `${array[i].name}`;
      }
  };

  const handleEvents = async (filteredSearch) => {
    let eventsBE = filteredSearch.hasOwnProperty("customer")
      ? await getEventsCustomer(filteredSearch.customer, filteredSearch)
      : await getEvents(filteredSearch);
    events = eventsBE.map((el) => {
      localExtendedProps = {};
      if (el.at_groups.length > 0) handleExtendedProps(el.at_groups, "groups");
      if (el.customers.length > 0)
        handleExtendedProps(el.customers, "customer");

      const objToReturn = {
        id: el.id,
        title: el.title,
        start: el.start_datetime,
        end: el.end_datetime,
        color: el.type == 1 ? "#F98131" : "#0CD2C8",
        extendedProps: Object.assign({}, localExtendedProps),
        durationEditable: el.type == 1 ? false : true,
      };
      objToReturn.extendedProps.type =
        el.type == 1 ? "reminder" : "appointment";
      return objToReturn;
    });
  };

  // onMount(async () => {
  //   await handleEvents();
  // });
</script>
