import { POST, GET } from "../lib/http";
import { exercises, exercisesTotalPages, tags, jwt } from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const maxPage = 5000;

export const getExercises = async (updateStoreValue = true, base = 2) => {
  // Se la variabile exercises nel local storage è vuota, OPPURE se non contiene nessun esercizio base, OPPURE se exercises non esiste
  // si forzano il parametro base a 2 (restituisce ex base + custom) e il SET della variabile nel local storage
  if (
    (localStorage.getItem('exercises') && localStorage.getItem('exercises').length == 0) ||
    (localStorage.getItem('exercises') && JSON.parse(localStorage.getItem('exercises')).filter(e => e.base === 0).length == 0) ||
    (!localStorage.getItem('exercises')))
  {
    base = 2;
    updateStoreValue = true;
  }

  try {
    const res = await GET("/v2/get_exercises_v2", {
      headers: { ...commonHeaders },
      query: { base },
    });

    // Condizioni di update della variabile exercises nel local Storage
    if (updateStoreValue) {

      // base == 0 | Solo esercizi custom del pt
      if (base == 0) {
        let baseExercises = JSON.parse(localStorage.getItem('exercises')).filter(e => e.base === 1);
        if (Array.isArray(res)) {
          let ptExercises = [...res];
          let allExercises = [
            ...ptExercises.sort(
              function(a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              }
            ), 
            ...baseExercises.sort(
              function(a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              }
            )
          ];
          exercises.set(allExercises);          
        }

      // base == 1 | Solo esercizi base
      // ////// //

      // base == 2 | Esercizi base + esercizi custom del pt
      } else if (base == 2) {
        exercises.set(res);
        exercisesTotalPages.set(Math.floor(res.length / 20));
      }
    }
    return res;
  } catch (error) {
    if (error.message.message == "Unauthenticated." || error.status == 401) {
      console.log('Unauthenticated.')
      console.log(error)
      if (jwt === "") {
        console.log('Empty jwt.')
      } else {
        console.log('Resetting jwt.')
        jwt.set("");
        window.location.reload();
      }
    }
    throw error;
  }
};

export const getExercisesList = async (updateStoreValue = false) => {
  try {
    const res = await GET("/v2/pt/exercises", {
      headers: { ...commonHeaders },
      query: {},
    });

    if (updateStoreValue) {
      exercises.set(res);
      exercisesTotalPages.set(Math.floor(res.length / 20));
    }
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const refreshExerciseData = async (id) => {
  try {
    const res = await GET("/v2/exercises/refresh_data/" + id, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteExercise = async (id) => {
  const res = await POST("/v2/delete_exercises", {
    headers: { ...commonHeaders },
    body: {
      id,
    },
  });
  return res;
};

export const updateShowBaseExercises = async (value) => {
  const res = await POST("/v2/update_show_base_exercises", {
    headers: { ...commonHeaders },
    body: {
      show_base_exercises: value,
    },
  });
  return res;
};

export const getTags = async () => {
  GET("/v2/tags")
    .then((res) => {
      tags.set(res);
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteTag = async (id) => {
  const res = await POST("/destroy_tag", {
    headers: { ...commonHeaders },
    body: {
      id,
    },
  });
  return res;
};

export const createTag = async (tag) => {
  const res = await POST("/v2/tags", {
    headers: { ...commonHeaders },
    body: {
      name: tag,
    },
  });
  return res;
};

export const saveExercise = async (body) => {
  const res = await POST(
    "/v2/store_exercise",
    {
      headers: { ...commonHeaders },
      body,
    },
    true,
    "multipart/form-data"
  );
  return res;
};
