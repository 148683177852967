<script>
  import { onMount } from "svelte";
  import { _ } from "../../../services/i18n";
  import SectionTitle from "./SectionTitle.svelte";
  import InvoicesList from "./InvoicesList.svelte";
  import { getInvoices } from "../../../services/billingServices";

  let invoices = [
    {
      date: "25 febbraio 2020",
      number: 12345575876,
      status: "Pagata",
      total: 159
    },
    {
      date: "25 gennaio 2020",
      number: 12345575876,
      status: "Pagata",
      total: 147
    },
    {
      date: "25 febbraio 2020",
      number: 12345575876,
      status: "Pagata",
      total: 159
    },
    {
      date: "25 gennaio 2020",
      number: 12345575876,
      status: "Pagata",
      total: 147
    }
  ];
  let headerItems = [
    {
      colSize: 1,
      value: "#"
    },
    {
      colSize: 3,
      value: $_("data")
    },
    {
      colSize: 2,
      value: $_("numero")
    },
    {
      colSize: 2,
      value: $_("stato")
    },
    {
      colSize: 2,
      value: $_("totale")
    },
    {
      colSize: 2,
      value: $_("scarica_ricevuta")
    }
  ];

  onMount(async () => {
    // const res = await getInvoices();
    // console.log("res", res);
    // invoices = res.invoices;
  });
</script>

<SectionTitle text={$_('storico_ricevute')} on:setComponent canGoBack={true} />

<InvoicesList {invoices} {headerItems} />
