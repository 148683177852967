<script>
  import "moment/locale/en";
  import "moment/locale/it";
  import { _, locale } from "../../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { customer } from "../../../../lib/store";
  import { navigate } from "svelte-routing";
  import Card, { Content } from "@smui/card";
  import IconButton from "@smui/icon-button";
  import moment from "moment";
  import SvelteTooltip from 'svelte-tooltip';
  
  export let histories = [];
  export let customerId;

  const dispatch = createEventDispatcher();

  let isMobile = false;
  let showMobileIcons = undefined;
  
  $: isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  const getDateFormatted = (date) => {
    const momentDate = moment(date);
    return $locale == "en"
      ? momentDate.locale("en").format("YYYY MMMM D")
      : momentDate.locale("it").format("D MMMM YYYY");
  };

  const goToHistory = (history) => {
    if (history.is_draft && !$customer.active) return;
    navigate(
      `${
        history.is_draft
          ? `/draft-history?customerId=${customerId}&historyId=${history.id}`
          : `/history?customerId=${customerId}&historyId=${history.id}`
      }`
    );
  };

  const editHistory = (historyId) => {
    if (!$customer.active) return;
    navigate(`/draft-history?customerId=${customerId}&historyId=${historyId}`);
  };

  const deleteHistory = (historyId) => {
    if (!$customer.active) return;
    dispatch("openDialog", {
      id: historyId,
    });
  };
</script>

<style>
  .textWrapper {
    display: flex;
    align-items: center;
    opacity: 0.8;
  }
  .dateText {
    font-weight: 600;
    font-size: 1.1rem;
  }
  .draftText {
    font-size: 0.9rem;
  }
  * :global(.textToTheLeft) {
    text-align: start !important;
  }
  * :global(.mdc-card) {
    text-align: center;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }
  * :global(.primary) {
    color: var(--primary);
  }
  .hide {
    display: none;
  }
</style>

<div class="container fluid">
  {#each histories as history, i}
    <Card class="mb-2 textToTheLeft" on:click={goToHistory(history)}>
      <Content>
        <div class="row">
          <div class="col-4 textWrapper dateText">
            {getDateFormatted(history.date_of_creation)}
            {#if history.is_draft}
              <span class="primary draftText ml-2">{`(${$_('bozza')})`}</span>
            {/if}
          </div>
          <div class="col-6 textWrapper">{history.created_by}</div>
          {#if isMobile}
            <div
              class="col-2 flexy"
              on:focusout={(e) => {
                if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
                showMobileIcons = undefined;
              }}>
              <div
                class="icon--action"
                class:primary={showMobileIcons === i}
                on:click|stopPropagation={() => (showMobileIcons === i ? deleteHistory(history.id) : (showMobileIcons = i))}>
                <IconButton
                  disabled={!$customer.active && showMobileIcons === i}
                  class="material-icons p-0"
                  style="width: fit-content">
                  {showMobileIcons === i ? 'delete' : 'more_vert'}
                </IconButton>
              </div>
              <div class="flexy">
                {#if !history.is_draft}
                  <div
                    class:hide={showMobileIcons != i}
                    on:click|stopPropagation={() => {
                      if (!$customer.active) return;
                      dispatch('cloneHistory', { id: history.id });
                    }}>
                    <IconButton
                      disabled={!$customer.active}
                      class="material-icons">
                      content_copy
                    </IconButton>
                  </div>
                {:else if history.is_draft}
                  <div
                    class:hide={showMobileIcons != i}
                    on:click|stopPropagation={() => {if($customer.active) editHistory(history.id)}}>
                    <IconButton
                      disabled={!$customer.active}
                      class="material-icons">
                      edit
                    </IconButton>
                  </div>
                {/if}
              </div>
            </div>
          {:else}
            <div class="col-2 flexy">
              <div on:click|stopPropagation={deleteHistory(history.id)}>
                <SvelteTooltip tip="{$_('elimina')}" top >
                  <IconButton
                    disabled={!$customer.active}
                    class={`material-icons ${$customer.active ? 'primary' : ''}`}>
                    delete
                  </IconButton>
                </SvelteTooltip>
              </div>
              {#if !history.is_draft}
                <div
                  on:click|stopPropagation={() => {
                    if (!$customer.active) return;
                    dispatch('cloneHistory', { id: history.id });
                  }}>
                  <SvelteTooltip tip="{$_('duplica')}" top >
                    <IconButton
                      disabled={!$customer.active}
                      class="material-icons">
                      content_copy
                    </IconButton>
                  </SvelteTooltip>
                </div>
              {:else if history.is_draft}
                <div on:click|stopPropagation={() => editHistory(history.id)}>
                  <SvelteTooltip tip="{$_('modifica')}" top >
                    <IconButton
                      disabled={!$customer.active}
                      class="material-icons">
                      edit
                    </IconButton>
                  </SvelteTooltip>
                </div>
              {/if}
            </div>
          {/if}
        </div>
      </Content>
    </Card>
  {/each}
</div>
