<script>
  import { _ } from "svelte-i18n";
  import { navigate } from "svelte-routing";
  import Card, { Content, Actions } from "@smui/card";
  import Button, { Label } from "@smui/button";
</script>

<Card
  style="max-width: 400px; margin:0 auto; text-align:center"
  class="borderNone"
  variant="outlined"
  padded>
  <div class="col-xs-6 col-xs-offset-3 pt-4 pb-2">
    <img
      src="/assets/revoologo.svg"
      alt=""
      style="height:2.5rempx" />
  </div>
  <Content class="col-12 py-4 textWrapper">
    <div>{$_('invio_recupero_password')}</div>
    <div>{$_('controlla_la_tua_inbox')}</div>
  </Content>
  <Actions style="justify-content:center" class="mr-2 mb-5">
    <Button
      color="primary"
      class="btn btn--form"
      variant="unelevated"
      on:click={() => navigate('/login')}>
      <Label class="textUppercase">{$_('torna_al_login')}</Label>
    </Button>
  </Actions>
</Card>
