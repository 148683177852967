<script>
    import { createEventDispatcher, onMount } from "svelte";
    import VirtualList from "@sveltejs/svelte-virtual-list";
    import { _ } from "../../../services/i18n";
    import Card, { Content, Media } from "@smui/card";
    import { Icon } from "@smui/common";
    import IconButton from "@smui/icon-button";
    import Textfield from "@smui/textfield";
    import Loading from "../../ReusableLayouts/Loading.svelte";
    import {
      assignCustomerToGroup,
      getCustomersGroupRelations,
      removeCustomerFromGroup,
    } from "../../../services/teamServices";
    import { group, customersGroupRelations } from "../../../lib/store";
  
    // export let customers = [];
    export let assignmentDialogLoaded = false;
    export let groupId;
    export let loading;
    export let msgError;
    export let text;
    
    const dispatch = createEventDispatcher();

    let assignedCustomers = [];
    let dataRefreshing = false;
    let filteredByName = false;
    let groupToBeAssigned = $group;
    let groupToBeRemoved = groupToBeAssigned;
    let notAssignedCustomers = [];
    let previousSearch = "";
    let search = "";

    $: if (assignmentDialogLoaded) {
      assignedCustomers = [];
      notAssignedCustomers  = [];
      initCustomerGroupRelations();
    }

    const handleSearchChanged = (string) => {
      getCustomersLists(string);
    };

    const handleAssignCustomerToGroup = (customer) => {
      let updatedRelations;
      try {
        updatedRelations = {
          assigned_customers     : [...$customersGroupRelations.assigned_customers, customer],
          not_assigned_customers : $customersGroupRelations.not_assigned_customers.filter(c => { return c.id !== customer.id; }),
          group_id               : $customersGroupRelations.group_id,
        };
        customersGroupRelations.set(updatedRelations);
        assignCustomerToGroup(customer, groupId);
        getCustomersLists(search);
      } catch (err) {
        console.log("handleAssignCustomerToGroup err", err);
      }
    };

    const handleRemoveCustomerFromGroup = (customer) => {
      let updatedRelations;
      try {
        updatedRelations = {
          assigned_customers     : $customersGroupRelations.assigned_customers.filter(c => { return c.id !== customer.id; }),
          not_assigned_customers : [...$customersGroupRelations.not_assigned_customers, customer],
          group_id               : $customersGroupRelations.group_id,
        };
        customersGroupRelations.set(updatedRelations);
        removeCustomerFromGroup(customer, groupId);
        getCustomersLists(search);
      } catch (err) {
        console.log("handleRemoveCustomerFromGroup err", err);
      }
    };

    const getCustomersLists = async (name = '') => {
      let waitingTime = 0;
      name = name.trim();
      if ((name.length === 0 || previousSearch.length > name.length) && filteredByName === true) {
        filteredByName = name == '' ? false : true;
        dataRefreshing = true;
        customersGroupRelations.set(await getCustomersGroupRelations(groupId, 'all', name));
        dataRefreshing = false;
        setNewLists(name);
      } else if ((name != null && name.trim() != '') || (filteredByName === false && search.length > 0)) {
        filteredByName = true;
        waitingTime = dataRefreshing === true ? 500 : 0;
        setTimeout(() => {
          customersGroupRelations.set(filterByName(name));
          dataRefreshing = false;
          setNewLists(name);
        }, waitingTime);
      } else {
        setNewLists(name);
      }
    }

    const initCustomerGroupRelations = async () => {
      dataRefreshing = true;
      customersGroupRelations.set(await getCustomersGroupRelations(groupId, 'all', ''));
      dataRefreshing = false;
      setNewLists();
    }

    const setNewLists = (name = '') => {
      assignedCustomers    = $customersGroupRelations.assigned_customers;
      notAssignedCustomers = $customersGroupRelations.not_assigned_customers;
      previousSearch       = name.trim();
    }

    const filterByName = (name) => {
      let assigned = [];
      let not_assigned = [];
      $customersGroupRelations.assigned_customers.forEach(c => { 
        if ((c.first_name.toLowerCase().split(" ").join("") + c.last_name.toLowerCase().split(" ").join("")).includes(name.trim().toLowerCase())) {
          assigned.push(c);
        }
      });
      $customersGroupRelations.not_assigned_customers.forEach(c => { 
        if ((c.first_name.toLowerCase().split(" ").join("") + c.last_name.toLowerCase().split(" ").join("")).includes(name.trim().toLowerCase())) {
          not_assigned.push(c);
        }
      });
      return {
        assigned_customers: assigned,
        not_assigned_customers: not_assigned,
        group_id: $customersGroupRelations.group_id,
      }
    }
  
    const getCustomerStatusColor = (status) => {
      switch (status) {
        case "Active"          : return "userIconBgr-active";        break;
        case "Not very active" : return "userIconBgr-notVeryActive"; break;
        case "Inactive"        : return "userIconBgr-inactive";      break;
        case "Disabled"        : return "userIconBgr-disactive";     break;
        default:                 return "userIconBgr-inactive";      break;
      }
    };

    $: if (groupId !== undefined) {
      loading = true;
      initCustomerGroupRelations();
      loading = false;
    }
  </script>
  
  <style>
    * :global(.mdc-text-field) {
      height: 40px;
      margin-right: 0.8em;
    }
    * :global(.mdc-text-field__input) {
      height: auto;
      margin: auto;
      padding: 0 0.5em !important;
    }
    * :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
        .mdc-floating-label) {
      top: 12px;
    }
    * :global(.material-icons.searchIcon) {
      padding: 10px 0px 0px 4px !important;
    }
    .justifyContentEnd {
      display: flex;
      justify-content: flex-end;
    }
    * :global(.material-icons.deleteIconButton:hover) {
      --mdc-ripple-left: 10px !important;
      --mdc-ripple-top: 10px !important;
      --mdc-ripple-fg-size: 28px !important;
      --mdc-ripple-fg-scale: 1.71429 !important;
    }
    .alignCenter {
      display: flex;
      align-items: center;
      align-content: center;
    }
    .alignCenterIcons {
      display: flex;
      align-items: center;
      align-content: center;
    }
    .textLeft {
      text-align: left;
    }
    .nameWrapper {
      font-weight: 600;
      opacity: 0.8;
    }
    .bold {
      font-weight: bold;
    }
    .imageWrapper {
      background-color: grey;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
    }
    .imagecustomer {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      object-fit: cover;
    }
    .statusCircle {
      border-radius: 50%;
      display: inline-block;
      height: 0.9rem;
      margin-left: 1rem;
      width: 0.9rem;
    }
    .header {
      background-color: #f9f9f9;
      align-items: center;
    }
    .header-sub {
      background-color: #fff;
    }
    .alignBaseline {
      align-content: baseline;
      align-items: baseline;
      display: flex;
    }
    * :global(.mdc-button--raised), * :global(.mdc-button--unelevated) {
      height: 2rem;
    }
    .searchbar {
      order: initial;
    }
    .containerCustomer {
      max-width: 100%;
      position: relative;
      height: 700px;
      max-height: 1000px; 
    }
    @media (max-width: 767px) {
      .searchbar {
        order: 3;
      }
      .header__title {
        margin: 0 !important;
      }
      * :global(.mdc-text-field__input) {
        margin-left: 0;
      }
      .desktop {
        display: none;
      }
    }
  </style>
  
  <div class="header px-4 py-2 row no-gutters">
    <div class="header__title col-11 col-md-7 my-4">
      <div class="mdc-typography--headline6 bold">
        {`${text}`}
      </div>
      <div class="mdc-typography--headline6 bold mb-2">
        {#if groupToBeAssigned}
          {groupToBeAssigned.name ? groupToBeAssigned.name : ''}
        {/if}
      </div>
    </div>
    <div class="col-12 col-md-4 searchbar">
      <Textfield
        variant="outlined"
        class="w100"
        withLeadingIcon
        id="searchInputAssignTC"
        bind:value={search}
        on:click={(e) => e.stopPropagation()}
        on:keyup={handleSearchChanged(search)}
        label={$_('cerca_per_nome')}>
        <div class="centrated">
          <Icon class="material-icons searchIcon">search</Icon>
        </div>
      </Textfield>
    </div>
    <div class="col-1 col-md-1 justifyContentEnd">
      <IconButton
        class="material-icons deleteIconButton"
        on:click={(e) => { dispatch('closeDialog', {}); e.stopPropagation();}}>
        close
      </IconButton>
    </div>
  </div>
  
  <div class="container fluid py-3">
    {#if msgError}
      <div class="alert alert-danger fade show" role="alert">{msgError}</div>
    {/if}
  
    {#if !loading}
      <div class="row no-gutters">
        <div class="col-6 px-0 px-md-2">
          <div class="header header-sub px-2 my-3">
            <div class='header__title'>
              <div class="mdc-typography--headline6 bold">
                {$_('scegli_clienti')}
              </div>
            </div>
          </div>
          <div
            class={`${notAssignedCustomers.length > 0 ? 'containerCustomer' : 'container fluid py-2'}`}>
            {#if notAssignedCustomers.length > 0}
              <VirtualList items={notAssignedCustomers} let:item>
                <Card class="my-1 mx-1 mx-md-2">
                  <Content>
                    <div class="row no-gutters alignCenter">
                      <div class="col-2" style="padding-left:0px">
                        <div
                          class="icon--action"
                          on:click|stopPropagation={handleAssignCustomerToGroup(item)}>
                          <IconButton
                            data-mobile-icon
                            class="btn material-icons primary"
                            style="width: fit-content">
                            add_circle
                          </IconButton>
                        </div>
                      </div>
                      <div class="col-2 d-none d-md-block">
                        <Media>
                          {#if item.image}
                            <img src={item.image} alt="" class="imagecustomer" />
                          {:else}
                            <div class="imageWrapper">
                              <div style="" />
                            </div>
                          {/if}
                        </Media>
                      </div>
                      <div class="col-9 col-md-6 px-4 textLeft alignCenter">
                        <span class="nameWrapper">
                          {item.first_name + ' ' + item.last_name}
                        </span>
                      </div>
                      <div class="d-none d-md-block col-2 px-1 alignCenterIcons">
                        <span 
                          class={`statusCircle desktop ${getCustomerStatusColor(item.status)}`} />
                      </div>
                    </div>
                  </Content>
                </Card>
              </VirtualList>
            {/if}
          </div>
        </div>
        <div class="col-6 px-0 px-md-2">
          <div class="header header-sub px-2 my-3">
            <div class='header__title'>
              <div class="mdc-typography--headline6 bold">
                {$_('assegnati')}
              </div>
            </div>
          </div>
          <div
            class={`${assignedCustomers.length > 0 ? 'containerCustomer' : 'containerCustomer py-2'}`}>
            {#if assignedCustomers.length > 0}
              <VirtualList items={assignedCustomers} let:item class="">
                <Card class="my-1 mx-1 mx-md-2">
                  <Content>
                    <div class="row no-gutters alignCenter">
                      <div class="col-2" style="padding-left:0px">
                        <div
                          class="icon--action"
                          on:click|stopPropagation={handleRemoveCustomerFromGroup(item)}>
                          <IconButton
                            data-mobile-icon
                            class="btn material-icons primary"
                            style="width: fit-content">
                            remove_circle
                          </IconButton>
                        </div>
                      </div>
                      <div class="col-2 d-none d-md-block">
                        <Media>
                          {#if item.image}
                            <img src={item.image} alt="" class="imagecustomer" />
                          {:else}
                            <div class="imageWrapper">
                              <div style="" />
                            </div>
                          {/if}
                        </Media>
                      </div>
                      <div class="col-9 col-md-6 pl-4 textLeft alignCenter">
                        <span class="nameWrapper">
                          {item.first_name + ' ' + item.last_name}
                        </span>
                      </div>
                      <div class="d-none d-md-block col-2 px-1 alignCenterIcons">
                        <span 
                          class={`statusCircle desktop ${getCustomerStatusColor(item.status)}`} />
                      </div>
                    </div>
                  </Content>
                </Card>
              </VirtualList>
            {/if}
          </div>
        </div>
      </div>
    {:else}
      <div class="containerCustomer">
        <div class="row no-gutters position-relative mt-4">
          <Loading />
        </div>
      </div>
    {/if}
  </div>
  