<script>
  import { _ } from "svelte-i18n";
  import { Body, Row, Cell } from "@smui/data-table";
  import { fancyTimeFormat, getCombinationString } from "../../../../lib/utils";

  export let workout;

  const getWeightType = (type) => {
    if (type == "buffer") return "buffer";
    if (type == "km/h")   return "km/h";
    if (type == "level")  return "level";
    if (type == "m/s")    return "m/s";
    if (type == "min/km") return "min/km";
    if (type == "rm")     return "rm";
    if (type == "rpe")    return "rpe";
    if (type == "watt")   return "watt";
    else return type;
  };

  const getWeightFormat = (format) => {
    if (format == "max")      return "max";
    if (format == "%")        return "%";
    if (format == "range")    return "range";
    if (format == "standard") return "standard";
    else return format;
  };

  const dataValidation = (data) =>
    data.value && data.value != "0/0" && data.type;

  const getRepsType = (type) => {
    if (type == "kcal")  return "kcal";
    if (type == "km")    return "km";
    if (type == "metri") return "meters";
    if (type == "reps")  return "reps";
    if (type == "time")  return "time";
    return type;
  };

  const getRepsFormat = (format) => {
    if (format == "max")      return "max";
    if (format == "per_side") return "per_side";
    if (format == "range")    return "range";
    if (format == "standard") return "standard";
    else return format;
  };

  const getRepsValueType = (reps) => {
    if (reps.type == "time" || reps.type == "seconds")
      return (
        fancyTimeFormat(reps.value.split("/")[0]) +
        "/" +
        fancyTimeFormat(reps.value.split("/")[1])
      );
    else if (reps.type == "minutes")
      return (
        fancyTimeFormat(reps.value.split("/")[0] * 60) +
        "/" +
        fancyTimeFormat(reps.value.split("/")[1] * 60)
      );
    else return getMax(reps.value) + " " + getRepsType(reps.type);
  };

  const getRepsValueTypeForEmom = (reps) => {
    if (reps.type == "time" || reps.type == "seconds" || reps.type == "minutes")
      return '-';
    else if (reps.value.split("/")[1] == 9999)
      return 'Max';
    else return getMax(reps.value.split("/")[0]) + " " + getRepsType(reps.type);
  };

  const compareNumDen = (value) => {
    const first = parseInt(value.split("/")[0]);
    const second = parseInt(value.split("/")[1]);
    if (first > second) return "fraction--green";
    else if (first < second && second != 9999 && second != "MAX")
      return "fraction--red";
    else return "";
  };

  const getEmomExecutedTime = (combExercises) => {
    let emomExecutedTimeArray = [];
    let emomData = [];
    Object.entries(combExercises).map((detail) => {
      detail.map((set) => {
        if (set.emom_time_executed === '' || set.emom_time_executed === null) set.emom_time_executed = "00:00";
        set.emom_time_executed ? emomExecutedTimeArray.push(set.emom_time_executed + '/' + set.emom_time_planned) : '';
      });
    });
    emomExecutedTimeArray.map((et) => {
      emomData.push({
        value : et, 
        class : compareNumDen(et),
      });
    });
    return Object.values(emomData);
  }

  const getMax = (value) => value.replace(/9999/gi, "MAX");

  const isOdd = (value) => value % 2 == 1;

  const median = (value) => {
    if (value < 2) return value;
    let half = Math.floor(value / 2);
    if (isOdd(value)) return half + 1;
    return value / 2.0;
  };
</script>

<style>
  .fraction--green {
    color: green;
  }

  .fraction--red {
    color: red;
  }

  .flex--columns {
    display: flex;
    flex-direction: column;
  }

  .nameColumn {
    width: 10rem;
    white-space: pre-wrap;
  }
</style>

<Body>
  {#each workout.combinations as comb}
    <Row>
      <Cell>
        <div class="row no-gutters nameColumn">
          {#each comb.exercises as exercise}
            {#if !isOdd(exercise.details.length) && comb.method !== 'emom'}
              <span class="col-12 my-3" />
            {/if}
            {#each exercise.details as detail, i}
              {#if i == median(exercise.details.length) - 1}
                <span class="col-12 my-3">{exercise.name}</span>
              {:else if comb.method !== 'emom'}
                <span class="col-12 my-3" />
              {/if}
            {/each}
          {/each}
        </div>
      </Cell>
      <Cell>{getCombinationString($_, comb.method)}</Cell>
      <Cell>
        {#if comb.method == 'time_circuit'}
          <div
            class={`row no-gutters flexy ${compareNumDen(`${comb.sets_executed}/${comb.supersets}`)}`}>
            <span class="col-12 my-3">
              {fancyTimeFormat(comb.sets_executed) + '/' + fancyTimeFormat(comb.supersets)}
            </span>
          </div>
        {:else if comb.method == 'normal'}
          <div class="row no-gutters flexy">
            <span class="col-12 my-3">
              {#if Array.isArray(comb.exercises) && comb.exercises.length > 0
                && Array.isArray(comb.exercises[0].details) && comb.exercises[0].details.length > 0}
                {comb.exercises[0].details[0].sets ?? '-'}
              {:else}
                ---
              {/if}
            </span>
          </div>
        {:else if comb.method == 'superset'}
          {comb.supersets || '-'}
        {:else if comb.method == 'emom'}
          <div class="row no-gutters flexy">
            <span class="col-12 my-3">
              {#each getEmomExecutedTime(comb.exercises[0].details) as executedTime}
                <div>
                  <span class={executedTime.class}>{executedTime.value ?? '-'}</span>
                </div>
              {/each}
            </span>
          </div>
        {:else}{comb.supersets || '-'}{/if}
      </Cell>
      <Cell>
        <div class="row no-gutters flexy flex--columns">
          {#each comb.exercises as exercise}
            <div class="row no-gutters flexy">
              {#if comb.method !== 'emom'}
                {#each exercise.details as detail}
                  {#if comb.method == 'time_circuit'}
                    <span class={`col-12 my-3`} />
                  {:else if comb.method == 'normal'}
                    <span
                      class={`col-12 my-3 ${compareNumDen(detail.reps.value)}`}>
                      {dataValidation(detail.reps) ? `${getRepsValueType(detail.reps)}` : '-'}
                    </span>
                  {:else}
                    <div
                      class={`col-12 my-3 w100 ${compareNumDen(detail.reps.value)}`}>
                      {dataValidation(detail.reps) ? `${getRepsValueType(detail.reps)}` : '-'}
                    </div>
                  {/if}
                {/each}
              {:else}
                <span class={`col-12 my-3`} >
                  {#if exercise.details && exercise.details[0] && exercise.details[0].reps}
                    {dataValidation(exercise.details[0].reps) ? `${getRepsValueTypeForEmom(exercise.details[0].reps)}` : '-'}
                  {/if}
                </span>
              {/if}
            </div>
          {/each}
        </div>
      </Cell>
      <Cell>
        <div class="row no-gutters flexy flex--columns">
          {#each comb.exercises as exercise}
            <div class="row no-gutters flexy">
              {#if comb.method !== 'emom'}
                {#each exercise.details as detail}
                  {#if comb.method == 'time_circuit'}
                    <span class={`col-12 my-3`} />
                  {:else}
                    <span
                      class={`col-12 my-3 ${compareNumDen(detail.weight.value)}`}>
                      {dataValidation(detail.weight) ? `${getMax(detail.weight.value)} ${getWeightType(detail.weight.type)}` : '-'}
                    </span>
                  {/if}
                {/each}
              {:else}
                <span
                  class={`col-12 my-3`}>
                  {#if exercise.details && exercise.details[0] && exercise.details[0].weight}
                    {dataValidation(exercise.details[0].weight) ? `${getMax(exercise.details[0].weight.value.split('/')[0])} ${getWeightType(exercise.details[0].weight.type)}` : '-'}
                  {/if}
                </span>
              {/if}
            </div>
          {/each}
        </div>
      </Cell>
      <Cell>
        <div class="row no-gutters flexy flex--columns">
          {#if comb.method == 'time_circuit' || comb.method == 'circuit'}
            {''}
          {:else}
            {#each comb.exercises as exercise}
              {#if !isOdd(exercise.details.length) && comb.method !== 'emom'}
                <span class="col-12 my-3" />
              {/if}
              {#each exercise.details as detail, i}
                {#if i == median(exercise.details.length) - 1}
                  <span
                    class="col-12 my-3">{exercise.volume && exercise.volume != '0.00' ? Math.round(exercise.volume * 100) / 100 : '-'}</span>
                {:else if comb.method !== 'emom'}<span class="col-12 my-3" />{/if}
              {/each}
            {/each}
          {/if}
        </div>
      </Cell>
    </Row>
  {/each}
</Body>
