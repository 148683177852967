<script>
  import { _, locale } from "../../services/i18n";
  import { jwt } from "../../lib/store";
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { addScriptToHead } from "../../services/authServices";
  import Card, { Content, Actions } from "@smui/card";
  import Button, { Label } from "@smui/button";
  import Textfield, { Input } from "@smui/textfield";
  import FormMessage from "../ReusableLayouts/FormMessage.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";
  import LoadingInline from "../ReusableLayouts/LoadingInline.svelte";
  import { Icon } from "@smui/common";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import {
    checkEmail,
    checkPassword,
    checkReferralCode,
  } from "./validation/authValidation";
  import { register, existEmail, existReferral } from "../../services/authServices";

  onMount(() => {
    if ($jwt) {
      navigate("/team");
    }
  });

  let email = "";
  let password = "";
  let referral_code = "";
  let showPassword = false;
  let terms = false;
  let gdpr = false;
  let saving = false;

  let emailInvalid = false;
  let emailError = "";
  let passwordInvalid = false;
  let referralCodeInvalid = false;
  let referralCodeExists = false;
  let referralCodeMessage = "";
  let referralCodeParam = new URLSearchParams(location.search).get("referral");
  let logoUrl = '/assets/revoologo.svg';
  let logoHeight = 'height:2.5rem';

  $: if (referralCodeParam) {
    referral_code = referralCodeParam;
  }

  $: if (referral_code) {
    if (referral_code.length > 5 && 
        referral_code.toLowerCase() == 'invictus20') {
      logoUrl = '/assets/invictus_academy.png';
      logoHeight = 'height:7rem';
    } else {
      logoUrl = '/assets/revoologo.svg';
      logoHeight = 'height:2.5rem';
    }
  } else {
    logoUrl = '/assets/revoologo.svg';
    logoHeight = 'height:2.5rem';
  }

  let msgError = "";

  $: formValid = !emailInvalid && 
                 password &&
                 !passwordInvalid && 
                 (referral_code == '' || referralCodeExists) && 
                 terms && 
                 gdpr;

  $: passwordInvalid = password && !checkPassword(password);
  $: validateEmail(email);
  $: validateReferral(referral_code);

  const validateEmail = (email) => {
    try {
      if (!email) {
        emailInvalid = true;
        return;
      }
      if (!checkEmail(email)) {
        emailError = $_("email_errata");
        emailInvalid = true;
        return;
      }
      existEmail({ email }).then((res) => {
        if (res) {
          emailInvalid = true;
          emailError = $_("email_esiste_già");
        } else {
          emailInvalid = false;
        }
      });
    } catch (error) {
      console.log("validateEmail err", error);
    }
  };

  const validateReferral = (referral_code) => {
    try {
      if (!referral_code) {
        referralCodeInvalid = false;
        referralCodeExists = false;
        referralCodeMessage = "";
        return;
      }
      if (!checkReferralCode(referral_code)) {
        referralCodeInvalid = true;
        referralCodeExists = false;
        referralCodeMessage = $_("referral_code_errato");
        return;
      }
      existReferral({ referral_code }).then((res) => {
        if (res) {
          referralCodeExists = true;
          referralCodeInvalid = false;
          referralCodeMessage = $_("referral_code_applicato");
        } else {
          referralCodeExists = false;
          referralCodeInvalid = false;
          referralCodeMessage = $_("referral_code_non_esistente");
        }
      });
    } catch (error) {
      console.log("validateReferral err", error);
    }
  };

  const submit = async () => {
    try {
      saving = true;
      const res = await register({
        email,
        password,
        referral_code,
        terms,
        gdpr,
        language: $locale,
      });
      saving = false;
      navigate("flow-registration");
    } catch (error) {
      saving = false;
      console.log("error register", error);
      displayError();
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };
</script>

<style>
  * :global(.mdc-button) {
    height: 2.5rem;
  }

  * :global(.cardClass) {
    border-radius: 2px;
    margin: 0 auto;
    width: 25rem;
    text-align: center;
  }

  * :global(.textWrapper) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    opacity: 0.8;
  }

  .registerClauses {
    font-size: 0.75rem;
    opacity: 0.5;
  }

  * :global(.textUppercase) {
    text-transform: uppercase;
  }

  * :global(.password--show) {
    color: rgba(33, 33, 33, 0.5);
  }

  .label {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1rem;
    margin-left: 0.5rem;
    opacity: 0.7;
  }

  * :global(.mdc-text-field) {
    height: 2.8125rem;
  }
  * :global(.mdc-text-field--outlined .mdc-text-field__input) {
    padding: 1rem 0.7rem;
  }

  *
    :global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__input) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
  }

  * :global(.alignLeft) {
    text-align: left;
  }

  * :global(.mdc-text-field__icon.mdc-text-field__icon--trailing) {
    top: 0.7rem;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }
</style>

<MsgError {msgError} />

<form name="signin" on:submit|preventDefault={submit}>
  <Card class="borderNone cardClass" variant="outlined" padded>
    <div class="col-xs-6 col-xs-offset-3 py-4">
      <img
        src="{logoUrl}"
        alt=""
        style="{logoHeight}" />
    </div>
    <Content class="col-12 py-2 textWrapper">
      <div>{$_('registrati_su_revoo')}</div>
    </Content>
    <Content class="alignLeft">
      <div class="form-group">
        <span class="label">{`${$_('email')} *`}</span>
        <Textfield
          variant="outlined"
          class={`textbox w100 ${email !== '' && emailInvalid ? 'mdc-text-field--invalid' : ''}`}
          bind:value={email}
          type="email" />
        {#if email !== '' && emailInvalid}
          <FormMessage textAlign="right">{emailError}</FormMessage>
        {/if}
      </div>
      <div class="form-group">
        <span class="label">{`${$_('password')} *`}</span>
        <Textfield
          variant="outlined"
          bind:value={password}
          withTrailingIcon
          type={showPassword ? 'text' : 'password'}
          class={`textbox w100 ${passwordInvalid ? 'mdc-text-field--invalid' : ''}`}>
          <Icon
            on:click={() => {
              showPassword = !showPassword;
            }}
            role="button"
            tabindex="1"
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing password--show">
            {showPassword ? 'visibility_off' : 'visibility'}
          </Icon>
        </Textfield>
        {#if passwordInvalid}
          <FormMessage textAlign="right">
            {$_('la_password_non_rispetta_i_criteri')}
          </FormMessage>
        {/if}
      </div>
      <!-- <div class="form-group">
        <span class="label">{$_('codice_referral')} {$_('facoltativo')}</span>
        <Textfield
          variant="outlined"
          class={`textbox w100 ${referral_code !== '' ? 'mdc-text-field--invalid' : ''}`}
          bind:value={referral_code}
          type="text" />
        {#if (referralCodeInvalid || referralCodeExists !== null)}
          <FormMessage textAlign="right">{referralCodeMessage}</FormMessage>
        {/if}
      </div> -->
      <div class="col-xs-12" style="text-align: start">
        <FormField>
          <Checkbox bind:checked={terms} />
          <span class="textWrapper" slot="label">
            {@html $_('accetto_i_termini_e_le_condizioni')}
          </span>
        </FormField>
      </div>
      <div class="col-xs-12" style="text-align: start">
        <FormField>
          <Checkbox bind:checked={gdpr} />
          <span class="textWrapper" slot="label">
            {@html $_('accetto_la_privacy_policy')}
          </span>
        </FormField>
      </div>
    </Content>
    <Actions>
      <div class="col-xs-8 col-md-7 registerClauses">
        <span>{`* ${$_('obbligatorio')}`}</span>
        <br />
        <span>{$_('no_credit_card_required')}</span>
      </div>
      <div class="col-xs-4 pl-md-0 col-md-5" style="padding-right:6px">
        <Button
          class="btn btn--form"
          variant="unelevated"
          type="submit"
          disabled={!formValid || saving}>
          <Label class="textUppercase">
            <span>
              {$_('registrati')}
            </span>
            {#if saving}
              <LoadingInline />
            {/if}
          </Label>
        </Button>
      </div>
    </Actions>
  </Card>
</form>
