<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { Actions } from "@smui/card";
  	import { user, subscription } from "../../../../lib/store";
  	import Button, { Label } from "@smui/button";

  	const dispatch = createEventDispatcher();

	export let state;

	let buttonLabel = $_('torna_a_revoo');
	let message = "";
	let thankYouMessage = $_('popup_trial_grazie');

	$: switch (state) {
		case 'pricing_change':
			message = $_('subscription_dialog_final_step_pricing_change_message', {values: { firstName: $user.first_name, activation_date: $subscription.next_invoice_date },});
			thankYouMessage = $_('popup_trial_grazie_per_la_fiducia')
			break;
		case 'trialing':
			message = $_('popup_trial_complimenti_piano_aggiornato', {values: { firstName: $user.first_name },});
			break;
		case 'trial_ended':
			buttonLabel = $_('inizia_a_lavorare');
			message = $_('popup_trial_complimenti_abbonamento_attivo', {values: { firstName: $user.first_name },});
			break;
		default :
			message = $_('popup_trial_complimenti_piano_aggiornato', {values: { firstName: $user.first_name },});
			thankYouMessage = "";
	}
</script>

<div class="my-4 text-center">
	<span>
		{@html message}
	</span>
	<br>
	<span>
		{thankYouMessage}
	</span>
</div>
<div class="pb-4">
	<Actions class="d-flex justify-content-center">
		<Button
			class="justify-content-center"
			variant="unelevated"
			on:click={() => dispatch('stepsCompleted')}
		>
			<Label>{buttonLabel}</Label>
		</Button>
	</Actions>
</div>
	
