<script>
  import { _ } from "../../services/i18n";
  import { exercises, groups, jwt, oneSignalPlayerId, user, userData } from "../../lib/store";
  import { getAtGroups, getCustomers, getCustomersInfo, getToUpdateHubspotPt, updateOneSignalPlayerId } from "../../services/teamServices";
  import { getExercises, getExercisesList } from "../../services/exercisesServices";
  import { getSubscription, getSubscriptionInfo } from "../../services/subscriptionServices";
  import { navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import { smartlookRefresh } from "../../services/authServices";
  import BannerHelp from "../ReusableLayouts/BannerHelp.svelte";
  import BannerSubscriptionStatus from "../ReusableLayouts/BannerSubscriptionStatus.svelte";
  import GroupList from "./GroupList.svelte";
  import Header from "../ReusableLayouts/Header.svelte";
  import HeaderOneLine from "../ReusableLayouts/HeaderOneLine.svelte";
  import InvitationDialog from "./InvitationDialog.svelte";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";
  import TeamList from "./TeamList.svelte";
  
  // export let location = {};
  export let navbarDisabled = false;
  export let showNavbar = true;

  const mapComponentsToTab = {
    [$_("atleti")]: "atleti",
    [$_("gruppi")]: "gruppi",
  };
  const mapTabsToComponent = {
    atleti: $_("atleti"),
    gruppi: $_("gruppi"),
  };

  let activeCustomers;
  let activeTab = $_("atleti");
  let buttonInvitationDialog;
  let buttonInvitationlLabel;
  let buttonLabel;
  let buttonNavigate;
  let component = [$_("atleti")];
  let filtratedCustomers = [];
  let filtratedGroups = [];
  let groupPage = 1;
  let headerProps = {};
  let itemsRow = 20;
  let loading = true;
  let loadingCustomers = false;
  let loadingGroups = false;
  let msgError;
  let openInvitationDialog = false;
  let options = {
    [$_("atleti")]: TeamList,
    [$_("gruppi")]: GroupList,
  };
  let orderBy = "last_name";
  let orderByGroups = "name";
  let orderDesc = false;
  let page = 1;
  let props = {};
  let searchName = "";
  let searchParamsTab = "atleti";
  let selectedStatus = [];
  let sticky = true;
  let tabs = [
    $_("atleti"),
    $_("gruppi"),
  ];
  let totalCustomers;
  let totalGroupPages = 1;
  let totalPages = 1;

  $: headerProps = {
    buttonIcon: component == $_('atleti') ? "person_add" : "group_add",
    buttonInvitationDialog: buttonInvitationDialog,
    buttonInvitationlLabel: buttonInvitationlLabel,
    buttonLabel: buttonLabel,
    buttonNavigate: buttonNavigate,
    nameTitle: $_("team"),
    number: `${totalCustomers} ${$_("clienti")} | ${activeCustomers} ${$_("attivi")}`,
    search: searchName,
  };

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");
  $: searchParamsTab = new URLSearchParams(window.location.search).get("tab");

  $: if (searchParamsTab) {
    activeTab = mapTabsToComponent[searchParamsTab];
    component = [mapTabsToComponent[searchParamsTab]];
  }

  $: if (component == $_("atleti")) {
    buttonInvitationDialog = true;
    buttonInvitationlLabel = $_('invita_clienti');
    buttonLabel = $_("nuovo_cliente");
    buttonNavigate = "new-customer";
    getFiltratedCustomers();
  } else {
    buttonInvitationDialog = false;
    buttonInvitationlLabel = false;
    buttonLabel = $_("nuovo_gruppo");
    buttonNavigate = "new-group";
  }

  // For athletes tab
  const getFiltratedCustomers = async () => {
    try {
      loadingCustomers = true;
      const status =
        selectedStatus.length > 0 ? selectedStatus.toString() : null;
      const name = searchName ? searchName : null;
      const { data, last_page } = await getCustomers(
        itemsRow,
        page,
        status,
        name,
        orderBy,
        orderDesc
      );
      totalPages = last_page;
      filtratedCustomers = data.map((ele) => {
        ele.selected = false;
        return ele;
      });
      loadingCustomers = false;
    } catch (error) {
      loadingCustomers = false;
      console.log("getFiltratedCustomers error", error);
      displayError(error);
    }
  };

  const handleUpdateFiltratedCustomers = async (e) => {
    if (e.detail.goToInitialPage) page = 1;
    await getFiltratedCustomers();
  };  

  // For groups tab
  const getFiltratedGroups = async () => {
    try {
      loadingGroups = true;
      const name = searchName ? searchName : null;
      const { data, last_page } = await getAtGroups(
        itemsRow,
        groupPage,
        name,
        orderByGroups,
        orderDesc
      );
      totalGroupPages = last_page;
      filtratedGroups = data.map((ele) => {
        ele.selected = false;
        return ele;
      });
      await tick();
      groups.set(filtratedGroups);
      loadingGroups = false;
    } catch (error) {
      loadingGroups = false;
      console.log("getFiltratedGroups error", error);
      displayError(error);
    }
  };

  const handleUpdateFiltratedGroups = (e) => {
    if (e && e.detail != null && e.detail.goToInitialPage) groupPage = 1;
    getFiltratedGroups();
  };  

  const displayError = (err = null) => {
    let timeoutTime = 3000;
    msgError = $_("si_è_verificato_un_errore");
    if (err.message.exception && err.message.exception === 'PlanConflictException') {
      
      msgError = $_("plan_conflict_exception");
      timeoutTime = 9000000;
    }
    setTimeout(() => {
      msgError = "";
    }, timeoutTime);
  };

  const handleActiveTabChanged = (e) => {
    activeTab = e.detail.tab;
    component = [e.detail.tab];
    navigate(`/team?tab=${mapComponentsToTab[e.detail.tab]}`, {
      replace: true,
    });
  }

  // const handleLocalChanged = (e) => {
  //   onLocaleChanged(e.detail);
  // };

  const handleSearchChanged = async (e) => {
    searchName = e.detail.search;
    page = 1;
    await getFiltratedCustomers();
  };

  const handleGroupSearchChanged = async (e) => {
    searchName = e.detail.search;
    await getFiltratedGroups();
  };

  const handleOpenInvitationDialog = () => {
    openInvitationDialog = true;
  }

  const handleCloseInvitationDialog = () => {
    openInvitationDialog = false;
  }

  onMount(async () => {
    if (!$jwt) {
      navigate("/login");
      return;
    } else if (!$user.first_name) {
      navigate("/flow-registration");
      return;
    }
    showNavbar = true;
    navbarDisabled = false;
    try {
      const { customers, active_customers } = await getCustomersInfo();
      totalCustomers = customers;
      activeCustomers = active_customers;
      loading = false;
      await getToUpdateHubspotPt();
      await getSubscriptionInfo();
      await getSubscription();
      $user.chat_enabled    = $userData.chat_enabled;
      $user.image           = $userData.image;
      $user.is_alpha_tester = $userData.is_alpha_tester;
      $user.is_beta_tester  = $userData.is_beta_tester;
      $user.is_tester       = $userData.is_tester;
      $user.logo            = $userData.logo;
      $user.xtravision_id   = $userData.xtravision_id ?? null;
      // Smartlook
      smartlookRefresh($user.id, $user.email, $user.first_name, $user.last_name);
      if ($oneSignalPlayerId != null) {
        await updateOneSignalPlayerId($oneSignalPlayerId, $user.id);
      }
      // If empty, get base + pt exercises and save them in local storage
      if ($jwt && (!localStorage.getItem('exercises') || $exercises.length == 0 || $exercises.filter(e => e.is_active).length === 0)) {
        // await getExercises(true, 2);
        await getExercisesList(true);
      }
      // Groups (save in local storage)
      // await getFiltratedGroups();
    } catch (error) {
      loading = false;
      console.log("getCustomersInfo error", error);
      displayError(error);
    }
  });
</script>

<style>
  :global(.headerBackground) {
    background-color: #f9f9f9;
  }

  @media (max-width: 767px) {
    :global(.headerBackground) {
      position: -webkit-sticky;
      position: sticky;
      top: 3.4rem;
      z-index: 2;
    }
  }
</style>

{#if loading}
  <Loading />
{:else}
  <Header
    {activeTab}
    {sticky}
    {tabs}
    asTextAndIcon={false}
    asTitle={false}
    component={HeaderOneLine}
    noBackground={false}
    on:activeTabChanged={handleActiveTabChanged}
    on:onComponentEvent={component == $_("atleti") ? handleSearchChanged : handleGroupSearchChanged}
    on:openInvitationDialog={handleOpenInvitationDialog}
    props={headerProps}
    subTitle={false}
    summary={false}
    tabNavigationEnabled={true} 
    text={''} />

    <div class="container fluid pt-md-4">
      <BannerSubscriptionStatus updateDataButton={true} />
    </div>

    {#if $exercises && $exercises.length > 0 }
      <div class="container fluid pt-md-4">
        <BannerHelp state='exercises_update'/>
      </div>
    {/if}

  {#if component == $_("atleti")}
    <MsgError {msgError} />
    <TeamList
      {...props}
      {filtratedCustomers}
      {searchName}
      {totalPages}
      bind:orderBy
      bind:orderDesc
      bind:page
      bind:selectedStatus
      loading={loadingCustomers} 
      on:updateFiltratedCustomers={handleUpdateFiltratedCustomers} />
      <InvitationDialog 
       openDialog={openInvitationDialog}
       on:closeInvitationDialog={handleCloseInvitationDialog} />
  {:else if component == $_("gruppi")}
    <MsgError {msgError} />
    <GroupList
      {...props}
      {filtratedGroups}
      {searchName}
      {totalGroupPages}
      bind:groupPage
      bind:orderByGroups
      bind:orderDesc
      {loadingGroups}
      on:updateFiltratedGroups={handleUpdateFiltratedGroups} />
  {/if}
{/if}

<!-- {#if !loading && component == $_('atleti')}
  <div class="container pb-4">
    <div class="row d-flex justify-content-center">
      <div class="col-12">
        <BannerHelp state='referral'/>
      </div>
    </div>
  </div>
{/if} -->