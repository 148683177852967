<script>
  import { _, setupI18n, isLocaleLoaded } from "../../services/i18n";
  import { onMount, afterUpdate, tick } from "svelte";
  import { links, navigate } from "svelte-routing";
  import {
    user,
    loading,
    activeSection,
    previousSection,
    jwt,
  } from "../../lib/store";
  import Menu from "@smui/menu";
  import { Anchor } from "@smui/menu-surface";
  import List, { Item, Text, Graphic, Meta } from "@smui/list";
  import Tab, { Label } from "@smui/tab";
  import Dialog from "@smui/dialog";
  import TabBar from "@smui/tab-bar";
  import Loading from "../Loading/Loading.svelte";
  import MenuMobileList from "./MenuMobileList.svelte";
  import MenuSettingsList from "./MenuSettingsList.svelte";
  import MenuForYouList from "./MenuForYouList.svelte";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";

  export let navbarDisabled;
  export let navbarTabsDisabled;

  let exitDialogPath = "/";
  let mobileDialog;
  let anchorMobile;
  let anchorSettings;
  let menuMobile;
  let menuSettings;
  let menuForYou;
  let logoUrl = "/assets/revoologo.svg";
  let activeTab = {
    label: $_("team"),
    route: "/team",
    icon: "group",
  };
  let tabs = [
    {
      label: $_("team"),
      route: "/team?tab=atleti",
      icon: "group",
      isExternal: false,
    },
    {
      label: $_("calendario"),
      route: "/calendar",
      icon: "insert_invitation",
      isExternal: false,
    },
    {
      label: $_("piani_di_allenamento"),
      route: "/training-cards",
      icon: "fitness_center",
      isExternal: false,
    },
    {
      label: $_("esercizi"),
      route: "/exercises",
      icon: "sports_handball",
      isExternal: false,
    },
    {
      label: $_("video"),
      route: "/vods?tab=video",
      icon: "ondemand_video",
      isExternal: false,
    },
    {
      label: $_("for_you"),
      icon: "star",
      isExternal: true,
      externalLink: "https://www.revoo-app.com/ecosistema-revoo/",
    },
  ];

  if (window.location.host == "trainmobile.revoo-app.com") {
    tabs = tabs.filter(
      (item) => item.externalLink != "https://www.revoo-app.com/ecosistema-revoo/"
    );
  }

  $: if (mobileDialog) mobileDialog.open();

  $: if ($user.referral_code && $user.referral_code.length > 5) {
    logoUrl =
      $user.referral_code.toLowerCase() == "invictus20"
        ? `/assets/invictus_academy_white.png`
        : `/assets/revoologo.svg`;
  }

  $: if (activeTab) {
    if ($previousSection == "" || $previousSection == activeTab.label) {
      $previousSection = $activeSection;
    }
    $activeSection = activeTab.label;
  }

  afterUpdate(() => {
    jQuery('[data-toggle="tooltip"]').tooltip("dispose");
    jQuery('[data-toggle="tooltip"]').tooltip({ html: true });
  });

  const handleLogoClick = () => {
    if (navbarDisabled || navbarTabsDisabled) return;
    let path = "/home";
    if (
      window.location.pathname == "/training-card" ||
      window.location.pathname == "/new-training-card"
    ) {
      exitDialog.open();
      exitDialogPath = path;
    } else {
      navigate(path);
    }
  };

  const handleOpenExitDialog = (e) => {
    exitDialogPath = e.detail.path;
    exitDialog.open();
  };

  let exitDialog;

  const closeExitDialog = () => {
    exitDialog.close();
  };

  const handleConfirmExit = () => {
    exitDialog.close();
    if (exitDialogPath == "login") {
      jwt.set("");
    }
    navigate(exitDialogPath);
  };

  onMount(async () => {
    const { href, host } = window.location;
    const location = href.slice(href.indexOf(host) + host.length);
    const filteredTab = tabs.filter((i) => i.route == location)[0];
    await tick();
    activeTab = filteredTab ? filteredTab : false;
  });
</script>

<Dialog class="no-click-backdrop" bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={closeExitDialog}
    on:onConfirmation={handleConfirmExit}
  />
</Dialog>

<div class="navbar__container fixed-top">
  <div
    class="navbarDisableOverlay"
    style={navbarDisabled ? 'visibility: visible;' : ''}
  />
  <nav
    class="navbar navbar-expand-lg navbar-dark row no-gutters
      justify-content-center navCustom"
    use:links
  >
    <div class="container fluid">
      <div class="navbar_brand cursorPointer">
        <img
          class="navBrandCustom"
          src={logoUrl}
          alt=""
          on:click={handleLogoClick}
        />
      </div>

      <div style="text-align:end" use:Anchor bind:this={anchorMobile}>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          on:click={() => menuMobile.setOpen(true)}
        >
          <span class="navbar-toggler-icon" />
        </button>
        <Menu
          bind:this={menuMobile}
          anchor={false}
          bind:anchorElement={anchorMobile}
          anchorCorner="BOTTOM_LEFT"
        >
          <MenuMobileList {tabs} on:openExitDialog={handleOpenExitDialog} />
        </Menu>
      </div>
      <div class="navbar-collapse collapse">
        <TabBar
          {tabs}
          let:tab
          bind:active={activeTab}
          disabled={navbarDisabled || navbarTabsDisabled}
          on:click={(e) => {
            if (navbarDisabled || navbarTabsDisabled) return;
            if (!activeTab.isExternal) {
              activeTab = activeTab;
              if (window.location.pathname == '/training-card' || window.location.pathname == '/new-training-card') {
                exitDialog.open();
                exitDialogPath = activeTab.route;
              } else {
                navigate(activeTab.route);
              }
            }
          }}
        >
          {#if !tab.isExternal}
            <Tab disabled={navbarDisabled || navbarTabsDisabled} {tab} minWidth>
              <Label>{tab.label}</Label>
            </Tab>
          {/if}
        </TabBar>
        <!-- PER TE // Da ottimizzare e spostare nella TabBar -->
        {#if window.location.host != 'trainmobile.revoo-app.com'}
          <div use:Anchor bind:this={anchorSettings} class="forYou">
            <List avatarList singleSelection>
              <Item on:SMUI:action={() => menuForYou.setOpen(true)}>
                <Label class="mdc-tab__text-label">{$_('for_you')}</Label>
                <Meta class="material-icons" style="color: white">
                  keyboard_arrow_down
                </Meta>
              </Item>
            </List>
            <Menu
              bind:this={menuForYou}
              anchor={false}
              bind:anchorElement={anchorSettings}
              anchorCorner="BOTTOM_LEFT"
            >
              <MenuForYouList />
            </Menu>
          </div>
        {/if}

        {#if $user.first_name}
          <div style="text-align:end" use:Anchor bind:this={anchorSettings}>
            <List avatarList singleSelection>
              <Item on:SMUI:action={() => menuSettings.setOpen(true)}>
                <Graphic>
                  <img
                    class="mdc-list-item__graphic userImage"
                    src={$user.image}
                    alt=""
                  />
                </Graphic>
                <Text style="color: white">{$user.first_name}</Text>
                <Meta class="material-icons" style="color: white">
                  keyboard_arrow_down
                </Meta>
              </Item>
            </List>
            <Menu
              bind:this={menuSettings}
              anchor={false}
              bind:anchorElement={anchorSettings}
              anchorCorner="BOTTOM_LEFT"
            >
              <MenuSettingsList on:openExitDialog={handleOpenExitDialog} {navbarTabsDisabled} />
            </Menu>
          </div>
        {/if}
      </div>
    </div>
  </nav>
  <Loading closed={!$loading} />
</div>

<style>
  * :global(.mdc-tab) {
    height: 5rem;
  }
  * :global(.mdc-tab-indicator .mdc-tab-indicator__content--underline) {
    border-color: var(--mdc-theme-primary, #cd293d);
  }
  * :global(.mdc-tab--active .mdc-tab__text-label) {
    color: white;
  }
  * :global(.mdc-tab .mdc-tab__text-label) {
    color: white;
  }
  * :global(.menuListItems .mdc-list-item__graphic.material-icons) {
    margin-right: 18px;
  }
  * :global(.menuListItems .mdc-list-item__text) {
    padding-right: 8px;
  }
  * :global(.menuListItems .mainLabel) {
    font-weight: 700;
    text-align: center;
    width: 100%;
  }
  * :global(.mdc-list-item__graphic.userImage) {
    margin-right: 10px;
  }
  * :global(.mdc-menu-surface--open) {
    touch-action: none;
  }
  .navbar__container {
    z-index: 1040;
  }
  .navbarDisableOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 5rem;
    background: rgba(27, 24, 24, 0.3);
    visibility: hidden;
    z-index: 999;
  }
  .cursorPointer {
    cursor: pointer;
  }
  .navbar_brand {
    height: auto;
    margin-right: 3rem;
    width: 7rem;
  }
  .navCustom {
    background-color: var(--mdc-theme-secondary, #212121);
    height: 80px;
  }
  .userImage {
    object-fit: cover;
  }
  .forYou {
    margin-right: 20px;
    width: 10.3rem;
  }
  .forYou::after {
    content: "New";
    background: var(--theme-primary, #cb293d);
    width: 3rem;
    height: 1.1rem;
    color: #fff;
    box-sizing: border-box;
    line-height: 1rem;
    font-size: 0.7rem;
    font-weight: 700;
    position: absolute;
    top: 0.4rem;
    right: -0.4rem;
    padding: 1px;
    text-align: center;
    text-transform: capitalize;
    border-radius: 3px;
  }

  * :global(.forYou .mdc-tab__text-label) {
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  @media (min-device-width: 280px) and (max-device-width: 767px) {
    .navCustom {
      height: 54px;
    }
  }
  @media (max-width: 991px) {
    .navbar_brand {
      margin-right: 0;
    }
    .container.fluid {
      padding: 0;
    }
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    .navbar_brand {
      margin-right: 1em;
    }
    :global(.navbar-collapse .mdc-tab) {
      padding: 0px 16px;
    }
    .forYou {
      width: 8rem;
    }
    :global(.forYou .mdc-list-item) {
      padding: 0px;
    }
  }
</style>
