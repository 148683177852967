<script>
	import { _ } from "../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { Icon } from "@smui/common";
	import Card, { Content } from "@smui/card";

	export let ascOrderByExpiringDate;
	export let ascOrderByName;
	export let attribute;
	export let rangeValue;

  const dispatch = createEventDispatcher();

	const orderByName = (e) => {
		dispatch('sortList', { key: 'assignee_name', ascOrder: !ascOrderByName });
	}

	const orderByExpiringDate = (e) => {
		dispatch('sortList', { key: 'expiring_date', ascOrder: !ascOrderByExpiringDate });
	}
</script>

<div class="container">
	<Card class="invisibleCard">
		<Content class="p-0">
			<div class="row d-flex align-items-center">
				<div class="col-4 d-flex px-0">
					<span class="pl-2 mdc-typography--headline6 textTitle nameWrapper" on:click={orderByName}>
						{$_('cliente')}
						<Icon class="material-icons mdc-text-field__iconmdc-text-field__icon--trailing ml-2">
							{ascOrderByName ? 'arrow_drop_up' : 'arrow_drop_down'}
						</Icon>
					</span>
				</div>
				<span class="col-5 mdc-typography--headline6 textTitle tagWrapper">
					{attribute}
				</span>
				<span class="col-3 mdc-typography--headline6 textTitle nameWrapper justify-content-center" on:click={orderByExpiringDate}>
					{rangeValue == 'next_7' || rangeValue == 'next_14' ? $_('scadenza') : $_('scaduto')}
					<Icon class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing ml-2">
						{ascOrderByExpiringDate ? 'arrow_drop_up' : 'arrow_drop_down'}
					</Icon>
				</span>
			</div>
		</Content>
	</Card>
</div>

<hr class="line" />

<style>
  .nameWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  .tagWrapper {
    text-align: center !important;
  }
  .nameWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 14px;
    justify-content: start;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

	* :global(.mdc-card.invisibleCard) {
    box-shadow: none !important;
  }
</style>