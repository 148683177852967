<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { sendProductNotification } from "../../../../services/productServices";
	import Card, { Content, Media } from "@smui/card";
    import { Icon } from "@smui/common";
	import IconButton from "@smui/icon-button";
	import LoadingInline from "../../../ReusableLayouts/LoadingInline.svelte";
  	import SvelteTooltip from 'svelte-tooltip';

	export let isMobile = false;
	export let item = {};
	export let i;

	const dispatch = createEventDispatcher();
	
	let sendingNotification = false;
  	let showMobileIcons;
  	let showNotificationSent = false;

	const redirectTo = () => {
		dispatch('redirectTo', { productId: item.id })
	}

	// Notification
	const handleNotifyButtonClicked = async () => {
		if (!showNotificationSent && !sendingNotification && item.notification_status === 0) {
			sendingNotification = true;
			const res = await sendProductNotification(item);
			if (res.status == 400) {
				console.log('error handleNotifyButtonClicked', res);
				sendingNotification = false;
			} else {
				sendingNotification = false;
				showNotificationSent = true;
				setTimeout(() => {
					setNotificationStatus();
					showNotificationSent = false;
				}, 5000);
			}
		}
	};

  	const setNotificationStatus = () => {
		item.notification_status = 1;
	}
</script>

<Card class="mb-2 dashboard-card" on:click={item.approval_status !== 0 ? redirectTo(item.id) : ``}>
	<Content>
		<div class="row align-items-center">
			<div class="d-none d-md-block col-1 text-center"><Icon class="material-icons">drag_indicator</Icon></div>
			<div class="col-4 {isMobile ? `` : `d-flex text-left align-items-center`}">
				<!-- {#if !isMobile}
					<Media class="card-media-square customUploader" aspectRatio="square">
						{#if item.image}
							<img
								src={item.image}
								alt=""
								style="min-width: 50px;"
								class="imagecustomer d-flex" />
						{:else}
							<div class="imageWrapper d-flex align-items-center {item.is_assigned_to_group ? `imageWrapper__group` : ``}"></div>
						{/if}
					</Media>
				{/if} -->
				<span class="demiBold assigneeName {isMobile ? `pl-0` : `pl-1`} ">
					{item.name}
				</span>
			</div>
			<div class="col-3 col-md-2 pl-0 pl-md-2 text-right item-overflow">
				<div>
					<span class="nameWrapper">
						{item.price}<span class="pl-1">&euro;</span>
					</span>
				</div>
			</div>
			<div class="col-2 col-md-2 text-center item-overflow d-flex justify-content-center justify-content-md-start">
				{#if item.is_active === 0}
					<div class="statusCircle userIconBgr-disactive" />
					<div class="textWrapper d-none d-md-block">
						<SvelteTooltip tip="{$_('disattivato')}" top >
							{$_('disattivato')}
						</SvelteTooltip>
					</div>
				{:else if item.approval_status === 0}
					<div class="statusCircle userIconBgr-notVeryActive" />
					<div class="textWrapper d-none d-md-block">
						<SvelteTooltip tip="{$_('il_prodotto_e_in_revisione')}" top >
							{$_('in_revisione')}
						</SvelteTooltip>
					</div>
				{:else if item.approval_status === 1}
						<div class="statusCircle userIconBgr-active" />
						<div class="textWrapper d-none d-md-block">
							<SvelteTooltip tip="{$_('il_prodotto_e_stato_approvato')}" top >
								{$_('attivo')}
							</SvelteTooltip>
						</div>
				{:else if item.approval_status === 2}
					<div class="statusCircle userIconBgr-inactive" />
					<div class="textWrapper d-none d-md-block">
						<SvelteTooltip tip="{$_('il_prodotto_non_e_stato_approvato')}" top >
							{$_('non_approvato')}
						</SvelteTooltip>
					</div>
				{:else}
					<div class="statusCircle userIconBgr-disactive" />
					<div class="textWrapper d-none d-md-block">
						{$_('disattivato')}
					</div>
				{/if}
			</div>
			<!-- ACTIONS -->
			<div class="col-3 d-flex justify-content-end">
				<div class="row">
					{#if isMobile}
						<div style="background-color:white;" class="col-12 bg__color flexy bar--icons alignContentEnd mobile"
							on:focusout={(e) => {
								if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
								showMobileIcons = undefined;
							}}
						>
							<div
								class="icon--action"
								class:primary={showMobileIcons === i}
								disabled={showMobileIcons === i && item.approval_status === 0}
								on:click|stopPropagation={() => (showMobileIcons === i && item.approval_status !== 0 ? dispatch('deleteProduct', { productId: item.id }) : (showMobileIcons = i))}>
								<IconButton class="material-icons p-0" style="width: fit-content">
									{showMobileIcons === i ? 'delete' : 'more_vert'}
								</IconButton>
							</div>
							{#if item.is_active}
								{#if !sendingNotification}
									<div class="icon--action" id="notify-icon" class:hide={showMobileIcons != i}
										on:click|stopPropagation={handleNotifyButtonClicked(item)}>
										<SvelteTooltip tip="{showNotificationSent ? $_('notifica_inviata') : $_('invia_notifica_ai_clienti')}" top >
											<IconButton disabled={!item.is_active || item.notification_status === 1 || item.approval_status !== 0} data-mobile-icon class="material-icons pr-1" style="width: fit-content">
												{showNotificationSent ? `done` : `notifications`}
											</IconButton>
										</SvelteTooltip>
									</div>
								{:else}
									<SvelteTooltip tip="{$_('notifica_in_corso')}" top >
										<LoadingInline />
									</SvelteTooltip>
								{/if}
							{/if}
							<div class="flexy">
								<div
									class="icon--action"
									id="edit-icon"
									class:hide={showMobileIcons != i}
									on:click|stopPropagation={() => { if (item.approval_status !== 0) dispatch('editProduct', { productId: item.id })}}>
									<IconButton
										data-mobile-icon
										class="material-icons p-0 mx-0"
										disabled={item.approval_status === 0}
										style="width: fit-content">
										edit
									</IconButton>
								</div>
								<!-- <div
									class:hide={showMobileIcons != i}
									on:click|stopPropagation={dispatch('cloneProduct', { productId: item.id })}>
									<IconButton
										data-mobile-icon
										class="material-icons p-0 ml-3"
										style="width: fit-content">
										content_copy
									</IconButton>
								</div> -->
							</div>
						</div>
					{:else}
						<div class="col-12 flexy alignContentEnd desktop">
							<div on:click|stopPropagation={() => {
								if (item.approval_status !== 0) 
									dispatch('deleteProduct', { productId: item.id })
								}}>
								<SvelteTooltip tip="{item.approval_status !== 0 ? $_('elimina') : $_('non_puoi_eliminare_un_prodotto_in_revisione')}" top >
									<IconButton disabled={item.approval_status === 0} class="material-icons primary">delete</IconButton>
								</SvelteTooltip>
							</div>
							{#if item.is_active}
								{#if !sendingNotification}
									<div on:click|stopPropagation={handleNotifyButtonClicked(item)}>
										<SvelteTooltip tip="{showNotificationSent || item.notification_status === 1 ? $_('notifica_inviata') : $_('invia_notifica_ai_clienti')}" top >
											<IconButton disabled={!item.is_active || item.notification_status === 1 || item.approval_status !== 1} class="material-icons">
												{showNotificationSent ? `done` : `notifications`}
											</IconButton>
										</SvelteTooltip>
									</div>
								{:else}
									<div class="px-2">
										<SvelteTooltip tip="{$_('notifica_in_corso')}" top >
											<LoadingInline />
										</SvelteTooltip>
									</div>
								{/if}
							{/if}
							<div on:click|stopPropagation={() => { 
								if (item.approval_status !== 0) 
									dispatch('editProduct', { productId: item.id })
								}}>
								<SvelteTooltip tip="{item.approval_status !== 0 ? $_('modifica') : $_('non_puoi_modificare_un_prodotto_in_revisione')}" top >
									<IconButton disabled="{item.approval_status === 0}" class="material-icons">edit</IconButton>
								</SvelteTooltip>
							</div>
							<!-- <div
								on:click|stopPropagation={dispatch('cloneProduct', { productId: item.id })}>
								<SvelteTooltip tip="{$_('duplica')}" top >
									<IconButton class="material-icons">content_copy</IconButton>
								</SvelteTooltip>
							</div> -->
						</div>
					{/if}
				</div>
			</div>
		</div>
	</Content>
</Card>

<style>
	.textWrapper {
    opacity: 0.8;
    word-break: break-word;
  }
  * :global(.mdc-card.customerCard) {
    cursor: pointer;
  }

	* :global(button:disabled) {
		color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
	}

	.statusCircle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    height: 15px;
    width: 15px;
    margin-right: 10px;
    margin-top: 4px;
  }
  .centered {
    margin: auto;
  }
  @media (max-width: 767px) {
    .statusCircle {
      margin: 0;
    }
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }
  }

	.assigneeName {
		opacity: 0.8;
		min-width: 75%;
	}

	:global(.dashboard-card) {
		cursor: pointer;
	}

	.imageWrapper {
    background-color: grey;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

	.item-overflow {
		overflow: hidden;
    text-overflow: ellipsis;
	}

	* :global(.imageWrapper__group) {
		background: var(--icon-active);
	}
  .imagecustomer {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    align-items: center;
    object-fit: cover;
  }

	* :global(.boxShadowBottom) {
    -webkit-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  }	

	* :global(.primary) {
    color: var(--primary);
  }
</style>