<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { getInvitationCode } from "../../services/teamServices.js";
  import { navigate } from "svelte-routing";
  import Checkbox from "@smui/checkbox";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import InvitationDialog from "./InvitationDialog.svelte";

  export let filtratedGroup = {};
  export let isMobile;
  export let index;
  export let panels = [];
  
  let showMobileIcons;
  let confirmationDeleteDialog;
  let idOfGroupToBeDeleted;
  let msgError;
  let loading = false;
  let loadingAtGroupCode = false;
  let openInvitationDialog = false;
  let groupInvitationCode = null;

  const dispatch = createEventDispatcher();

  const handleChecked = (e) => {
    dispatch("filtratedGroupChecked", {
      checked: filtratedGroup.selected,
    });
  };

  const handleEditButtonClicked = (groupToBeEdited) => {
    navigate('new-group', { state: { groupToBeEdited } });
  };

  const handleDeleteButtonClicked = (id) => {
    idOfGroupToBeDeleted = id;
    confirmationDeleteDialog.open();
  };

  const handleDeleteConfirmation = () => {
    dispatch("deleteAtGroup", {
      id: idOfGroupToBeDeleted,
    });
    confirmationDeleteDialog.close();
  };

  const handleOpenInvitationDialog = () => {
    openInvitationDialog = true;
  }

  const handleCloseInvitationDialog = () => {
    openInvitationDialog = false;
    refreshAtGroupInvitationCode();
  }

  const refreshAtGroupInvitationCode = async () => {
    loadingAtGroupCode = true;
    let res = await getInvitationCode(filtratedGroup.id);
    filtratedGroup.group_invitation_code = res.code;
    loadingAtGroupCode = false;
  }

  const handleRefreshAtGroupCode = async () => {
    refreshAtGroupInvitationCode();
  }

  onMount(() => {
    // refreshAtGroupInvitationCode();
  });
</script>

<style>
  .textWrapper {
    opacity: 0.8;
    word-break: break-word;
  }
  .imageWrapper {
    background-color: grey;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  .imagecustomer {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    align-items: center;
    object-fit: cover;
  }
  * :global(.mdc-card.customerCard) {
    cursor: pointer;
  }
  .statusCircle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    height: 15px;
    width: 15px;
    margin-right: 10px;
    margin-top: 4px;
  }
  .centered {
    margin: auto;
  }

  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  * :global(.dialog--big .mdc-dialog__container),
    :global(.dialog--big .mdc-dialog__surface) {
      min-height: 65vh !important;
      height: auto !important;
    }

  * :global(.primary) {
    color: var(--primary);
  }

  @media (max-width: 767px) {
    .statusCircle {
      margin: 0;
    }
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }
  }
</style>

<Dialog bind:this={confirmationDeleteDialog} on:click={(e) => {e.stopPropagation()}}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_il_gruppo')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => confirmationDeleteDialog.close()}
    on:onConfirmation={handleDeleteConfirmation} />
</Dialog>

<div class="col-1" style="padding-left:7px">
  <FormField>
    <Checkbox
      bind:checked={filtratedGroup.selected}
      on:click={(e) => {e.stopPropagation();}}
      on:change={(e) => { handleChecked(e); }} />
  </FormField>
</div>
<div class="col-7 col-sm-3 col-lg-3 pl-4 pl-md-2">
  <div class="demiBold textWrapper">
    {filtratedGroup.name}
  </div>
</div>
<div class="col-2 col-md-2 col-lg-2 textWrapper d-none d-sm-block text-center">
  {filtratedGroup.quantity}
</div>
<div class="col-2 col-md-2 col-lg-2 textWrapper d-none d-sm-block text-center">
  {#if filtratedGroup.group_invitation_code !== null}
    <div class="d-flex justify-content-center align-items-center">
      <span class="d-none d-md-block">
        {filtratedGroup.group_invitation_code}
        <!-- {groupInvitationCode} -->
      </span>
      <span class="d-none d-md-block"
        on:click|stopPropagation={handleOpenInvitationDialog}>
        <IconButton 
          class="material-icons ml-1">
          vpn_key
        </IconButton>
      </span>
    </div>
  {/if}
</div>
<div class="col-4 col-sm-4">
  {#if isMobile}
    <div
      class="col-12 col-md-3 col-lg-3 pr-0 bg__color flexy bar--icons mobile justify-content-end"
      on:focusout={(e) => {
        if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
        showMobileIcons = undefined;
      }}>
      <div
        class="icon--action"
        class:primary={showMobileIcons === index}
        on:click|stopPropagation={() => (showMobileIcons === index ? handleDeleteButtonClicked(filtratedGroup.id) : (showMobileIcons = index))}>
        <IconButton class="material-icons px-1" style="width: fit-content">
          {showMobileIcons === index ? 'delete' : 'more_vert'}
        </IconButton>
      </div>
      <div class="flexy">
        <div 
          class="icon--action"
          class:hide={showMobileIcons != index}
          on:click|stopPropagation={handleOpenInvitationDialog}>
          <IconButton 
            data-mobile-icon
            class="material-icons px-1"
            style="width: fit-content">
            vpn_key
          </IconButton>
        </div>
      </div>
    </div>
  {:else}
    <div class="flexy justify-content-end desktop">
      <div on:click|stopPropagation={handleDeleteButtonClicked(filtratedGroup.id)}>
        <IconButton class="material-icons primary">delete</IconButton>
      </div>
    </div>
  {/if}
</div>

<InvitationDialog 
  openDialog={openInvitationDialog}
  atGroupId={filtratedGroup.id}
  atGroupName={filtratedGroup.name}
  on:closeInvitationDialog={handleCloseInvitationDialog}
  on:refreshAtGroupCode={handleRefreshAtGroupCode} />