<script>
  import { _ } from "../../../../services/i18n";
  import { customer, vodsQuantity } from "../../../../lib/store";
  import { Embed } from "@vime/svelte";
  import { getVodsAndPlaylistsQuantity } from "../../../../services/vodsServices";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import { onMount, createEventDispatcher } from "svelte";
  import AssignedVodsList from "./AssignedVodsList.svelte";
  import BannerHelp from "../../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label } from "@smui/button";
  import Dialog from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import NoItemsToShow from "../../../ReusableLayouts/NoItemsToShow.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  
  export let customerId = null;
  export let firstPlaylistAssignments = [];
  export let firstVideoAssignments = [];
  export let loadingPlaylists = true;
  export let loadingVods = true;
  export let nextPlaylistAssignments = [];
  export let nextVideoAssignments = [];
  
  const dispatch = createEventDispatcher();

  let msgError;
  let play = false;
  let vodPlayerDialog;
  let vodToPlay = {};

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };
  
  const addVideoCards = () => {
    // display 3 more cards
    const videoAssignmentsToBeDisplayed = nextVideoAssignments.slice(0, 5);
    firstVideoAssignments = [...firstVideoAssignments, ...videoAssignmentsToBeDisplayed];
    nextVideoAssignments = nextVideoAssignments.slice(5);
  };

  const addPlaylistCards = () => {
    // display 3 more cards
    const playlistAssignmentsToBeDisplayed = nextPlaylistAssignments.slice(0, 5);
    firstPlaylistAssignments = [...firstPlaylistAssignments, ...playlistAssignmentsToBeDisplayed];
    nextPlaylistAssignments = nextPlaylistAssignments.slice(5);
  };

  const closeVodPlayerDialog = () => {
    play = false; 
    vodToPlay = {}
    vodPlayerDialog.close();
  };

  const checkPtHasVods = async () => {
    const res = await getVodsAndPlaylistsQuantity();
    $vodsQuantity = res;
  };

  const handleRemoveAssignment = (e) => {
    if (e.detail.assignment.vod_assignment_id) {
      firstVideoAssignments = firstVideoAssignments.filter(i => i.vod_assignment_id != e.detail.assignment.vod_assignment_id);
    } else if (e.detail.assignment.playlist_assignment_id) {
      firstPlaylistAssignments = firstPlaylistAssignments.filter(i => i.playlist_assignment_id != e.detail.assignment.playlist_assignment_id);
    }
  };

  const handleUpdateDraftTrainingCard = (e) => {
    // e.detail.is_draft = e.detail.is_draft ? 1 : 0;
    // firstVideoAssignments = firstVideoAssignments.map((c) => {
    //   if (c.id === e.detail.id) c.is_draft = e.detail.is_draft;
    //   return c;
    // });
  };

  const handlePlayVod = async (e) => {
    e.stopPropagation();
    vodToPlay = e.detail.vod;
    play = true;
    vodPlayerDialog.open();
  }

  const handleUpdateAssignedVodsAndPlaylists = () => {
    dispatch('updateAssignedVodsAndPlaylists');
  }

  onMount(() => {
    checkPtHasVods();
  });
</script>

<style>
  * :global(.embed-responsive-item) {
    background-color: #000;
  }
  .close-icon-wrapper {
    position: absolute;
    right: 38px;
    transform: translate(20px, 16px);
    background-color: rgba(255, 255, 255, 0.99);
    border-radius: 50px;
    z-index: 2;
  }
  .headline {
    font-size: 1.25rem;
  }
  .rowSpace {
    padding: 2rem 0rem;
  }
  .pushToEnd {
    display: flex;
    justify-content: flex-end;
  }
  * :global(.saveBtn) {
    background: #cd293d !important;
    color: #fff !important;
    border: 0 !important;
  }
  * :global(.mdc-text-field.equipmentDescription) {
    height: auto;
    width: 100% !important;
  }
  * :global(.mdc-text-field--disabled .mdc-text-field__input) {
    color: black !important;
  }
</style>

<Dialog bind:this={vodPlayerDialog} class="dialog--big no-click-backdrop">
  <div class="close-icon-wrapper">
    <IconButton class="material-icons" on:click={closeVodPlayerDialog}>
      close
    </IconButton>
  </div>
  {#if play}
  <div class="embed-responsive embed-responsive-1by1">
    <Embed
      class="embed-responsive-item"
      autoplay={true}
      color="#fc929e"
      embedSrc="https://player.vimeo.com/video/{vodToPlay.vimeo_id}"
      origin="https://www.vimeo.com"
      mediaTitle="{vodToPlay.name}"
      controls={0}
    />
  </div>
  {/if}
</Dialog>

<div class="container fluid">
  <MsgError {msgError} />

  <div class="my-3">
    <BannerHelp state='vod_disclaimer'/>
  </div>

  {#if $vodsQuantity.vods == 0}
    <NoItemsToShow
      upperText={$_('nessun_video_da_assegnare')}
      biggerText={`${$_('aggiungi_i_tuoi_video')}`}
      icon="ondemand_video"
      buttonText={$_('nuovo_video')}
      pathToNavigateTo="new-video" />
  {:else}
    <!-- PLAYLIST SECTION -->
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
          <div class="pushToEnd my-3">
            <Button
              class="ml-4"
              color="secondary"
              variant="unelevated"
              disabled={!$customer.active || $vodsQuantity.vods === null || $vodsQuantity.vods == 0}
              on:click={() => navigate('/vod-assignment', {
                state: { customerId },
              })}>
              <Label>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing">
                  playlist_add_check
                </Icon>
                {$_('assegna')}
              </Label>
            </Button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="d-flex flex-column rowSpace">
            <div class="headline bold mb-2">{$_('playlist')}</div>
          </div>
        </div>
      </div>
    </div>
    {#if loadingPlaylists}
      <Loading />
    {:else}
      {#if $vodsQuantity.playlists > 0}
        {#if firstPlaylistAssignments && firstPlaylistAssignments.length > 0}
          <AssignedVodsList
            firstAssignments={firstPlaylistAssignments}
            {customerId}
            on:onError={displayError}
            on:playVod={handlePlayVod}
            on:removeAssignment={handleRemoveAssignment}
            on:updateDraftTrainingCard={handleUpdateDraftTrainingCard}
            on:updateAssignedVodsAndPlaylists={handleUpdateAssignedVodsAndPlaylists} />
          <div class="flexy my-3">
            <Button
              color="secondary"
              variant="outlined"
              disabled={nextPlaylistAssignments.length == 0}
              on:click={addPlaylistCards}>
              <Label>{$_('mostra_altro')}</Label>
            </Button>
          </div>
        {:else}
          <NoItemsToShow
            upperText={$_('nessuna_playlist')}
            biggerText={`${$_('assegna_la_prima_playlist_per')} ${$customer.first_name}`}
            icon="playlist_add_check"
            buttonText={$_('assegna_playlist')}
            pathToNavigateTo="vod-assignment"
            routeState={{ state: { customerId } }} />
        {/if}
      {/if}
    {/if}

    <!-- VIDEO SECTION -->
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="d-flex flex-column rowSpace">
            <div class="headline bold mb-2">{$_('video')}</div>
          </div>
        </div>
      </div>
    </div>
    {#if loadingVods}
      <Loading />
    {:else}
      {#if $vodsQuantity.vods > 0}
        {#if firstVideoAssignments && firstVideoAssignments.length > 0}
          <AssignedVodsList
            firstAssignments={firstVideoAssignments}
            {customerId}
            on:onError={displayError}
            on:playVod={handlePlayVod}
            on:removeAssignment={handleRemoveAssignment}
            on:updateDraftTrainingCard={handleUpdateDraftTrainingCard}
            on:updateAssignedVodsAndPlaylists={handleUpdateAssignedVodsAndPlaylists} />
          <div class="flexy my-3">
            <Button
              color="secondary"
              variant="outlined"
              disabled={nextVideoAssignments.length == 0}
              on:click={addVideoCards}>
              <Label>{$_('mostra_altro')}</Label>
            </Button>
          </div>
        {:else}
          <NoItemsToShow
            upperText={$_('nessun_video')}
            biggerText={`${$_('assegna_il_primo_video_per')} ${$customer.first_name}`}
            icon="playlist_add_check"
            buttonText={$_('assegna_video')}
            pathToNavigateTo="vod-assignment"
            routeState={{ state: { customerId } }} />
        {/if}
      {/if}
    {/if}
  {/if}
</div>

