<script>
  import { createEventDispatcher, tick } from "svelte";
  import { onMount } from "svelte";
  import { _ } from "../../services/i18n";
  import Button from "@smui/button";
  import Card, { Content } from "@smui/card";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { Icon } from "@smui/common";
  import IconButton from "@smui/icon-button";
  import NoItemsToShow from "../ReusableLayouts/NoItemsToShow.svelte";
  import Pagination from "../ReusableLayouts/Pagination.svelte";
  import PlaylistListItem from "./PlaylistListItem.svelte";
  import { deletePlaylist } from "../../services/vodsServices";
  import { playlists } from "../../lib/store";
  import AssignPlaylistDialog from "./AssignPlaylistDialog.svelte";
  import { getCustomers, getAtGroups } from "../../services/teamServices.js";
  import Dialog from "@smui/dialog";

  export let filtratedPlaylists = [];
  export let loading = false;
  export let orderByPlaylists = "name";
  export let orderDesc = false;
  export let playlistPage = 1;
  export let searchName;
  export let totalPlaylistPages = 1;

  let activePage = 0;
  let allSelected = false;
  let assignmentDialog;
  let assignmentDialogClicked = false;
  let assignmentLoading = false;
  let customers;
  let filterDialog;
  let groups;
  let isMobile = false;
  let itemsTypeSelected = $_('atleti');
  let msgError;
  let page;
  let pageReady = false;
  let panels = [];
  let playlistToBeAssigned;
  let search = ""; // assignment dialog search
  let showButton = false;
  let totalPages;

  const dispatch = createEventDispatcher();
  const jq = window.$;
  
  $: activePage = playlistPage - 1;
  $: page = playlistPage;
  $: totalPages = totalPlaylistPages;

  // $: if (filtratedPlaylists) {
  //   $playlists.map(g => { 
  //     g.id ? customers[g.id] = g.customers : '';
  //   });
  // }

  const handleAssignButtonClicked = async (e) => {
    playlistToBeAssigned = e.detail.playlist;
    assignmentDialogClicked = true;
    await tick();
    assignmentDialog.open();
    assignmentLoading = true;
    await getClients();
    await getGroups();
    assignmentLoading = false;
    await tick();
  }

  const getClients = async () => {
    try {
      const { data } = await getCustomers(500, 1, "", search);
      customers = data;
    } catch (error) {
      console.log("getCustomers error", error);
      displayError();
    }
  };

  const getGroups = async () => {
    try {
      const { data } = await getAtGroups(500, 1, search);
      groups = data;
    } catch (error) {
      console.log("getAtGroups error", error);
      displayError();
    }
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    itemsTypeSelected == $_('atleti') ? await getClients() : await getGroups();
  };

  const switchPage = async (event) => {
    playlistPage = event.detail.page + 1;
    dispatch("updateFiltratedPlaylists", {});
  };

  const setCheckboxes = (value) => {
    filtratedPlaylists = filtratedPlaylists.map((ele) => {
      ele.selected = value;
      return ele;
    });
  };

  $: {
    if (allSelected) {
      setCheckboxes(true);
    } else setCheckboxes(false);
  }

  const orderByField = async (field) => {
    orderDesc = orderByPlaylists == field ? !orderDesc : true;
    orderByPlaylists = field;
    playlistPage = 1;
    panels = [];
    dispatch("updateFiltratedPlaylists", {});
  };

  const checkboxChanged = () => {
    if (pageReady)
      dispatch("updateFiltratedPlaylists", { goToInitialPage: true });
  };

  const handleFiltratedPlaylistChecked = (e) => {
    if (e.detail.checked && !showButton) {
      showButton = true;
    } else if (
      !Object.values(filtratedPlaylists).some((el) => el.selected === true)
    ) {
      showButton = false;
    }
  };

  const handleUpdatePlaylists = () => {
    dispatch("updateFiltratedPlaylists", {});
  }

  const handleDeletePlaylist = async (e) => {
    try {
      const res = await deletePlaylist(e.detail.id);
      dispatch("updateFiltratedPlaylists", {});
    } catch (err) {
      console.log("err handleDeletePlaylist", err);
      displayError(err);
    }
  };

  const displayError = (err) => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  onMount(async () => {
    pageReady = true;
    jq(document).ready(() => {
      if (jq(".desktop").css("display") == "none") {
        isMobile = true;
      } else isMobile = false;
    });

    try {
      loading = true;
      // await getFiltratedPlaylists();
      dispatch("updateFiltratedPlaylists", {});
      loading = false;
    } catch (error) {
      loading = false;
      console.log("getCustomersInfo error", error);
    }
  });
</script>

<style>
  .text--no-elements {
    font-size: 1.25rem;
    opacity: 0.5;
  }
  .opacity--eighty {
    opacity: 0.8;
  }
  * :global(.demo-list) {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .alignCenter {
    align-items: center;
  }
  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 0.875rem;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .inerithPad {
    padding: inherit;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
    border-radius: 0px;
  }
  .filterContainer {
    display: flex;
    justify-content: space-between;
  }
  .allCheckbox {
    justify-content: center;
    display: flex;
  }
  * :global(.filter .mdc-list-item__text) {
    margin: 0;
  }
  * :global(.filter .material-icons) {
    margin: 0;
  }
  * :global(.boxShadowBottom) {
    -webkit-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  }
  @media (min-width: 280px) and (max-width: 767px) {
    .desktop {
      display: none;
    }
    .subheader__options {
      position: -webkit-sticky;
      position: sticky;
      top: 9.95rem;
      z-index: 2;
    }
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }
  }
  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }
</style>

{#if assignmentDialogClicked}
  <Dialog class="dialog--big" bind:this={assignmentDialog}>
    <AssignPlaylistDialog
      bind:itemsTypeSelected
      {customers}
      {groups}
      {loading}
      {msgError}
      {playlistToBeAssigned}
      on:searchChange={handleSearchChanged}
      on:closeDialog={() => assignmentDialog.close()} 
      text={$_('assegna_playlist')} />
  </Dialog>
{/if}

<div class="container fluid desktop">
  {#if msgError}
    <div class="alert alert-danger fade show mt-2" role="alert">
      {msgError}
    </div>
  {/if}
  <Card class="invisibleCard">
    <Content class="pb-0">
      <div class="row no-gutters flexy">
        <div class="col-2 col-md-1 pl-0">
          <FormField>
            <Checkbox bind:checked={allSelected} />
          </FormField>
        </div>
        <div class="col-md-3">
          <div class="row no-gutters alignCenter">
            <div
              class="demiBold textTitle"
              style="cursor:pointer"
              on:click={(event) => orderByField('name')}>
              {$_('playlist')}
            </div>
            {#if orderByPlaylists == 'name'}
              <span
                on:click={() => {
                  orderByField('name');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if}
          </div>
        </div>
        <div class="col-md-2">
          <div class="row no-gutters d-flex justify-content-center align-items-center">
            <!-- <div
                class="demiBold textTitle text-align"
                style="cursor:pointer"
                on:click={(event) => orderByField('views')}>
              {$_('visualizzazioni_totali')}
            </div>
            {#if orderByPlaylists == 'views'}
              <span
                on:click={() => {
                  orderByField('views');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if} -->
          </div>
        </div>
        <div class="col-md-2">
          <div class="row no-gutters d-flex justify-content-center align-items-center">
            <div
              class="demiBold textTitle"
              style="cursor:pointer"
              on:click={(event) => orderByField('quantity')}>
              {$_('numero_video')}
            </div>
            {#if orderByPlaylists == 'quantity'}
              <span
                on:click={() => {
                  orderByField('quantity');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if}
          </div>
        </div>
        <div class="col-md-3">
        </div>
        <div class="col-md-1">
        </div>
      </div>
    </Content>
  </Card>
  <hr class="line" />
</div>
<div class="subheader__options mobile">
  <Card class="boxShadowBottom mb-3">
    <Content class="container fluid card--subheader py-0">
      <div class="row no-gutters align-items-center justify-content-end">
        <div class="allCheckbox pl-1 d-none d-md-block">
          <Checkbox bind:checked={allSelected} />
        </div>
        <div style="padding-left:30px">
          <div class="row no-gutters align-self-end opacity--eighty">
            <div class="col-6 pr-2">
              <IconButton
                class="material-icons"
                on:click={() => orderByField('name')}>
                sort
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </Card>
</div>

{#if loading}
  <!-- <Loading /> -->
{:else if filtratedPlaylists.length > 0}
  <div class="container fluid">
    {#each filtratedPlaylists as filtratedPlaylist, i}
      <Card class="my-2">
        <Content>
          <div
            class="row px-2 spaceBetween align-items-center"
            style="cursor:pointer">
            <PlaylistListItem
              {filtratedPlaylist}
              on:filtratedPlaylistChecked={handleFiltratedPlaylistChecked}
              on:assignButtonClicked={handleAssignButtonClicked}
              on:updatePlaylists={handleUpdatePlaylists}
              on:deletePlaylist={handleDeletePlaylist}
              {isMobile}
              {panels}
              index={i} />
          </div>
        </Content>
      </Card>
    {/each}
  </div>
  <Pagination {totalPages} {activePage} on:page={switchPage} />
{:else if !searchName}
  <div class="flexy">
    <Card class="invisibleCard">
      <Content>
        <NoItemsToShow
          upperText={$_('nessuna_playlist')}
          biggerText={$_('aggiungi_le_tue_playlist')}
          icon="playlist_play"
          buttonText={$_('nuova_playlist')}
          pathToNavigateTo="new-playlist" />
      </Content>
    </Card>
  </div>
{:else}
  <div class="container-fluid my-4 text-center">
    <span class="text--no-elements demiBold">{$_('nessuna_playlist_trovata')}</span>
  </div>
{/if}
