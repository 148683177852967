<script>
  import { _ } from "../../../services/i18n";
  import { Content } from "@smui/card";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import SvelteTooltip from 'svelte-tooltip';
  import { progressionsPage, workouts } from "../../../lib/store";
  import { assignSortIds, removeWorkoutIds } from "../../../lib/utils";
  import { tick } from "svelte";

  export let workout;

  let editWorkoutName = !workout.workoutName.length;
  let nameInput = "";

  const updateName = () => {
    $workouts = $workouts.map((w) => {
      if (w.sortId == workout.sortId) {
        w.name = editWorkoutName;
      }
      return w;
    });
    editWorkoutName = false;
  };

  const removeWorkout = () => {
    $workouts = $workouts.filter((w) => w.sortId != workout.sortId);
  };

  const cloneWorkout = () => {
    $workouts.forEach((w) => delete w.workoutPlaceholder);
    const temp = { ...workout };
    const copy = removeWorkoutIds(temp);
    const clone = assignSortIds([copy]);
    $workouts = [...$workouts, ...clone];
  };

  const focusOnName = async () => {
    editWorkoutName = true;
    await tick();
    // nameInput.focus();
    if (nameInput != undefined) {
      nameInput.focus();
    } else {
      editWorkoutName = false;
    }
  };
</script>

<style>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header__name {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .nameWrapper {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }
  .label {
    font-size: 0.8rem;
    font-weight: 500;
    opacity: 0.7;
  }
  * :global(.mdc-text-field) {
    height: 2.5em;
    min-width: 25rem;
  }

  * :global(.mdc-text-field__input) {
    max-height: 100%;
    padding: 0 0.5em;
  }

  * :global(.mdc-text-field__input) {
    max-height: 100%;
    padding: 0 0.5em;
  }
</style>

<Content>
  <div class="row">
    <div class="col-1 px-0 workouts-drag-handle">
      <IconButton class="material-icons px-0">drag_indicator</IconButton>
    </div>
    <div class="col-11 pl-0 header">
      <div class="header__name">
        <span class="label">{$_('nome_scheda')}</span>
        {#if editWorkoutName}
          <div on:focusout={() => updateName()}>
            <label
              class="mdc-text-field w100 mdc-text-field--outlined
                mdc-text-field--no-label"
              aria-haspopup="true"
              aria-expanded="false">
              <input
                type="text"
                placeholder="Es. Lunedì"
                maxlength="34"
                bind:value={workout.workoutName}
                bind:this={nameInput}
                on:keydown={(e) => {
                  if (e.key === 'Enter') updateName();
                }}
                class="mdc-text-field__input w100" />
              <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
                <div class="mdc-notched-outline__leading" />
                <div class="mdc-notched-outline__notch" />
                <div class="mdc-notched-outline__trailing" />
              </div>
            </label>
          </div>
        {:else}
          <span class="nameWrapper {$progressionsPage > 0 ? `nameWrapper--progression` : ``} demiBold">
            {workout.workoutName}
            <IconButton
              on:click={focusOnName}
              disabled={$progressionsPage > 0}
              tabIndex="1"
              class="material-icons mdc-text-field__icon
                mdc-text-field__icon--trailing ml-2">
              edit
            </IconButton>
          </span>
        {/if}
      </div>
      <div>
        <SvelteTooltip tip="{$_('duplica')}" top >
          <IconButton
            on:click={cloneWorkout}
            class="material-icons trainingCardDuplicate">
            content_copy
          </IconButton>
        </SvelteTooltip>
        <SvelteTooltip tip="{$_('elimina')}" top >
          <IconButton
            on:click={removeWorkout}
            class="material-icons ml-1 primary workoutDelete">
            delete_outline
          </IconButton>
        </SvelteTooltip>
      </div>
    </div>
  </div>
</Content>
