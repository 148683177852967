<script>
  import { _ } from "../../services/i18n";
  import { deleteTrainingCard, getTemplateTrainingCards,} from "../../services/trainingCardServices";
  import { exercises, jwt } from "../../lib/store";
  import { getExercises, getExercisesList } from "../../services/exercisesServices";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import BannerHelp from "../ReusableLayouts/BannerHelp.svelte";
  import Header from "../ReusableLayouts/Header.svelte";
  import HeaderOneLine from "../ReusableLayouts/HeaderOneLine.svelte";
  import ListWrapper from "./ListWrapper.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";

  export const navbarDisabled = false;
  export let location;
  export let onLocaleChanged;
  export let showNavbar = true;

  let activePage = 0;
  let activeTab = $_("modelli");
  let ascOrder = true;
  let component = [$_("modelli")];
  let headerProps = {};
  let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
  let isTemplate = true;
  let itemsPerPage = 20;
  let loading = true;
  let msgError;
  let number;
  let options = {
    [$_("modelli")]: ListWrapper,
  };
  let props = {};
  let search = "";
  let searchParamsTab = "modelli";
  let selectedGroups = [];
  let tabs = [
    $_("modelli"),
  ];
  let totalPages = 1;
  let trainingCards = [];

  $: number = trainingCards.length;
  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: if (searchParamsTab) {
    activeTab = mapTabsToComponent[searchParamsTab];
    component = [mapTabsToComponent[searchParamsTab]];
  }

  $: headerProps = {
    buttonIcon: "fitness_center",
    buttonLabel: $_("nuovo_piano"),
    buttonNavigate: "/new-training-card",
    labelNumber: activeTab,
    nameTitle: isMobile ? $_("piani") : $_("piani_di_allenamento"),
    number,
    search,
  };

  $: props = {
    activePage,
    ascOrder,
    handleDeleteTrainingCard,
    isMobile,
    isTemplate,
    search,
    totalPages,
    trainingCards,
  };

  const mapTabsToComponent = {
    modelli: $_("modelli"),
  };

  const mapComponentsToTab = {
    [$_("modelli")]: "modelli",
  };

  const handleActiveTabChanged = (e) => {
    activeTab = e.detail.tab;
    component = [e.detail.tab];
    // isTemplate = !isTemplate;
    isTemplate = activeTab == $_('modelli') ? true : false;
    navigate(`/training-cards?tab=${mapComponentsToTab[e.detail.tab]}`, {
      replace: true,
    });
    handleUpdateTrainingCards();
  };

  const handleDeleteTrainingCard = async (e) => {
    try {
      const res = await deleteTrainingCard(e.detail.id);
      handleUpdateTrainingCards();
    } catch (err) {
      console.log("err", err);
      displayError(err);
    }
  };

  const handleActivePageChange = async (e) => {
    activePage = e.detail.page;
    await getTemplates();
  };

  const handleOrderChanged = async (e) => {
    ascOrder = e.detail.ascOrder;
    await handleUpdateTrainingCards();
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    activePage = 0;
    await handleUpdateTrainingCards();
  };

  const displayError = (err = null) => {
    let timeoutTime = 3000;
    msgError = $_("si_è_verificato_un_errore");
    if (err.message.exception && err.message.exception === 'PlanConflictException') {
      
      msgError = $_("plan_conflict_exception");
      timeoutTime = 9000000;
    }
    setTimeout(() => {
      msgError = "";
    }, timeoutTime);
  };

  const getTemplates = async (deleted = false) => {
    try {
      const workouts = false;
      let res = await getTemplateTrainingCards(
        workouts,
        itemsPerPage,
        activePage + 1,
        search,
        ascOrder
      );
      if (deleted && activePage != 0 && res.data.length == 0) {
        res = await getTemplateTrainingCards(
          workouts,
          itemsPerPage,
          activePage,
          search,
          ascOrder
        );
        activePage--;
      }
      trainingCards = res.data;
      totalPages = res.last_page;
      loading = false;
    } catch (err) {
      console.log("error getTemplateTrainingCards", err);
      displayError(err);
    }
  };

  const handleUpdateTrainingCards = async () => {
    loading = true;
    await getTemplates();
  }

  onMount(async () => {
    handleUpdateTrainingCards();

    // If empty, get base + pt exercises and save them in local storage
    if ($jwt && (!localStorage.getItem('exercises') || $exercises.length == 0 || $exercises.filter(e => e.is_active).length === 0)) {
      // await getExercises(true, 2);
      await getExercisesList(true);
    }
  });
  
</script>

<style>
  .headerBackground {
    background-color: #f9f9f9;
  }
  @media (max-width: 767px) {
    .headerBackground {
      position: -webkit-sticky;
      position: sticky;
      top: 3.375rem;
      z-index: 2;
    }

    * :global(.icons .buttonIcon.templates) {
      background-image: url("/assets/plan-add-dark.svg");
      background-position: center;
      background-repeat: no-repeat;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
</style>

<div class="headerBackground">
  <Header
    {activeTab}
    {tabs}
    asTextAndIcon={false}
    asTitle={false}
    component={HeaderOneLine}
    noBackground={true}
    on:activeTabChanged={handleActiveTabChanged}
    on:onComponentEvent={handleSearchChanged}
    props={headerProps}
    sticky={false}
    subTitle={false}
    summary={false}
    tabNavigationEnabled={true} 
    text={false} />
</div>

{#if isTemplate && searchParamsTab !== 'assegnati_ai_gruppi'}
  <div class="container my-3">
    <BannerHelp state='training_card_templates'/>
  </div>
{/if}

<div class="container fluid">
  <MsgError {msgError} />

  <svelte:component
    this={options[component]}
    {...props}
    bind:selectedGroups
    bind:loading
    bind:activeTab
    on:activePageChange={handleActivePageChange}
    on:orderChange={handleOrderChanged}       
    on:updateTrainingCards={handleUpdateTrainingCards} />
</div>
