<script>
  const dispatch = createEventDispatcher();
  import "./FlowRegistrationForms.scss";
  import { _ } from "../../../services/i18n";
  import { addImage } from "../../../lib/utils";
  import { createEventDispatcher, tick, onMount } from "svelte";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Actions, Media } from "@smui/card";
  import CC from "../../../lib/countryCode.js";
  import CropDialog from "./CropDialog.svelte";
  import Dialog, { Title } from "@smui/dialog";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import LinearProgress from "@smui/linear-progress";
  import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";
  import MsgError from "../../ReusableLayouts/MsgError.svelte";
  import Select, { Option } from "@smui/select";
  import Textfield, { Input } from "@smui/textfield";

  let businessLogo = "";
  let businessNickname = "";
  let countries_code = [];
  let formValid;
  let imageCrop = "";
  let imageDialog = false;
  let imageToBeSaved;
  let msgError = "";
  let name = "";
  let nameInvalid = false;
  let phoneNumber = "";
  let prefix = 39;
  let saving = false;
  let surname = "";
  let surnameInvalid = false;

  for (const [key, value] of Object.entries(CC)) {
    countries_code.push(value);
  }
  
  $: formValid = name.length > 2 && surname.length > 1;
  $: if (name) {
    nameInvalid = name.length < 3;
  }
  $: if (surname) {
    surnameInvalid = surname.length < 2;
  }

  const onInputChange = (e) => {
    const file = e.target.files[0];
    if (!file || file.size > 5000000) {
      dispatch("error", {});
      return;
    }
    addImage(file, "businessLogoImg");
    handleImageAdded(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    dispatch("updateImageProfile", {
      img: file,
    });
    addImage(file, "businessLogoImg");
    handleImageAdded(file);
  };

  const handleImageAdded = (file) => {
    businessLogo = file;
    imageCrop = URL.createObjectURL(file);
    imageDialog.open();
  };

  const updateLogo = (e) => {
    if (e.detail.image) {
      imageToBeSaved = e.detail.image;
      businessLogo = URL.createObjectURL(e.detail.image);
      const img = document.getElementById("businessLogoImg");
      img.src = URL.createObjectURL(e.detail.image);
    }
  };

  const submit = async () => {
    saving = true;
    dispatch("firstFormCompleted", {
      info: {
        name,
        surname,
        businessNickname,
        businessLogo: imageToBeSaved || businessLogo,
        prefix,
        phoneNumber,
      },
    });
    await tick();
    saving = false;
  };

  onMount(() => {
    try {
      // TagManager Event | Registration Step One
      window.dataLayer = window.dataLayer || []; 
      dataLayer.push({'event': 'registrazione_utente_step_uno'}); 
      console.log('Registration Step One');
    } catch (err) {
      console.error(err);
    }
  });
</script>

<style>
  * :global(.headline) {
    font-size: 1.25rem;
  }

  * :global(.customUploader) {
    background-color: #f3f3f3;
    border-radius: 10px !important;
    height: 200px;
    overflow: hidden;
    width: 200px;
  }

  * :global(.customCard) {
    text-align: center;
    margin: 0 auto;
  }

  * :global(.mdc-button__label) {
    text-transform: inherit;
  }

  .customContentInMedia {
    flex-direction: column;
    position: absolute;
  }

  .customFileInput {
    left: 0;
    opacity: 0;
    position: absolute;
    z-index: 5;
  }

  .imageWrapper {
    align-items: center;
    background-color: black;
    border-radius: 10px;
    display: flex;
    height: 200px;
    width: 200px;
  }

  :global(.imageWrapper img) {
    height: 100%;
    max-width: none;
    width: auto;
  }
  form {
    width: 25rem;
  }

  * :global(.opacityReduce) {
    opacity: 0.5;
  }

  * :global(.icon--info) {
    color: #212121 !important;
  }

  .uploaderText {
    text-align: center;
    opacity: 0.8;
  }

  * :global(.alignLeft) {
    text-align: left;
  }

  * :global(.cardClass) {
    border-radius: 2px;
    margin: 0 auto;
    width: 25rem;
    text-align: center;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }
  .justifyBetween {
    justify-content: space-between;
  }

  .label {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1rem;
  margin-left: 0.5rem;
  opacity: 0.7;
}

* :global(.mdc-text-field) {
    height: 2.8125rem;
  }

  * :global(.mdc-select.mdc-select--outlined) {
    height: 2.8125rem;
    max-width: 100%;
    width: 100%;
    padding-top: 0 !important;
  }

  * :global(.mdc-text-field--outlined .mdc-text-field__input) {
    padding: 1rem 0.7rem;
  }

  * :global(.mdc-select--outlined .mdc-select__selected-text) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
    max-width: 100%;
    align-items: center;
    height: auto;
    padding: 1rem 0.7rem;
  }

  *
    :global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__input) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
  }

  * :global(.alignLeft) {
    text-align: left;
  }

  * :global(.mdc-text-field__icon.mdc-text-field__icon--trailing) {
    top: 0.7rem;
  }

  * :global(.mdc-select__dropdown-icon) {
    top: 0.7rem;
  }
</style>

<Dialog
  bind:this={imageDialog}
  aria-labelledby="dialog-for-cropping-image"
  aria-describedby="image-cropper">
  <CropDialog
    {imageCrop}
    squared={true}
    mustCrop={false}
    on:closeDialog={() => imageDialog.close()}
    on:imageHasBeenCropped={updateLogo} />
</Dialog>

<form name="flowRegistration1" on:submit|preventDefault={submit}>
  <LinearProgress class="loading-progress-bar" progress={0.5} />

  <Card class="borderNone cardClass" variant="outlined" padded>
    <Content class="col-12 py-3 headline">
      <div class="mb-2 demiBold">{$_('benvenuto')}</div>
      <span class="bold"> {$_('completa_il_tuo_profilo_per_iniziare')} </span>
    </Content>

    <!-- uploader -->
    {#if !env.isProd}
      <Content class="customCard uploaderCard">
        <Media class="card-media-square customUploader" aspectRatio="square">
          <input
            class="mdc-card__media card-media-square mdc-card__media--square
              customUploader customContentInMedia customFileInput"
            type="file"
            id="file"
            accept="image/jpeg, image/png, image/webp"
            on:change={onInputChange} />
          {#if !businessLogo}
            <div
              class="mdc-card__media card-media-square mdc-card__media--square
                customUploader customContentInMedia d-flex flex-column-reverse">
              <div class="row pt-2">
                <div class="col-12">
                  <span class="uploaderText">PNG (Max 5MB)</span>
                  <br />
                  <span
                    class="uploaderText">{$_('con_sfondo_trasparente')}</span>
                  <br />
                  <Icon
                    role="button"
                    tabindex="1"
                    style="color: var(--primary)"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing pt-2 pb-3">
                    camera_alt
                  </Icon>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span
                    class="demiBold uploaderText"
                    style="font-size: 1.125rem">
                    {$_('logo_business')}
                  </span>
                </div>
              </div>
              <div class="row" />
            </div>
          {:else}
            <div class="imageWrapper">
              <div
                style="width: auto; height: 100%; overflow: hidden; margin: 0 auto;"
                id="output" />
            </div>
          {/if}
        </Media>
      </Content>
    {/if}

    <MsgError {msgError} />

    <Content>
      <div class="form-group alignLeft">
        <span class="label">{`${$_('nome')} *`}</span>
        <Textfield
          variant="outlined"
          class={`textbox w100 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
          bind:value={name}
          type="text"
          bind:invalid={nameInvalid} />
        {#if nameInvalid}
          <FormMessage textAlign="right">
            {name ? $_('il_nome_deve_essere_di_almeno_3_caratteri') : $_('il_nome_é_obbligatorio')}
          </FormMessage>
        {/if}
      </div>
      <div class="form-group alignLeft">
        <span class="label">{`${$_('cognome')} *`}</span>
        <Textfield
          variant="outlined"
          bind:value={surname}
          type="text"
          bind:invalid={surnameInvalid}
          class={`textbox w100 ${surnameInvalid ? 'mdc-text-field--invalid' : ''}`} />
        {#if surnameInvalid}
          <FormMessage textAlign="right">
            {surname ? $_('il_cognome_deve_essere_di_almeno_3_caratteri') : $_('il_cognome_é_obbligatorio')}
          </FormMessage>
        {/if}
      </div>
      <div class="form-group alignLeft">
        <span class="label">{$_('business_nickname')}</span>
        <Textfield
          variant="outlined"
          withTrailingIcon
          bind:value={businessNickname}
          type="text"
          class="textbox w100">
          <Icon
            role="button"
            tabindex="1"
            data-toggle="tooltip"
            data-placement="left"
            title={$_('il_tuo_brand_commerciale')}
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing opacityReduce icon--info">
            info
          </Icon>
        </Textfield>
      </div>
      <div class="form-group alignLeft">
        <span class="label">{$_('telefono')}</span>
        <div class="row justifyBetween">
          <div class="col-4 pr-0">
            <Select enhanced variant="outlined" bind:value={prefix} label="">
              <Option value="" />
              {#each countries_code as country_code}
                <Option
                  value={country_code.code}
                  selected={prefix === country_code}>
                  {` +${country_code.code} ${country_code.iso2}`}
                </Option>
              {/each}
            </Select>
          </div>
          <div class="col-8">
            <Textfield
              variant="outlined"
              withTrailingIcon
              bind:value={phoneNumber}
              type="tel"
              class="textbox w100 mb-2">
              <Icon
                role="button"
                tabindex="1"
                data-toggle="tooltip"
                data-placement="left"
                title={$_('potremmo_utilizzare_questo_numero')}
                class="material-icons mdc-text-field__icon
                  mdc-text-field__icon--trailing opacityReduce icon--info">
                info
              </Icon>
            </Textfield>
          </div>
        </div>
      </div>
    </Content>
    <Actions style="justify-content:flex-end" class="mr-2">
      <Button
        class="btn"
        variant="unelevated"
        type="submit"
        disabled={!formValid || saving}>
        <Label class="textUppercase">
          <span>
            {$_('avanti')}
          </span>
          {#if saving}
            <LoadingInline />
          {/if}
        </Label>
      </Button>
    </Actions>
  </Card>
</form>
