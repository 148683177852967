<script>
  import { _ } from "../../../services/i18n";
  import List, { Item, Separator, Text, Graphic, Meta } from "@smui/list";
  import { Icon } from "@smui/button";

  export let name = "Abbonamento REVOO";
  export let discount_name = "Sconto Revoo";
  export let discount_percent_off = 0;
  export let discount_amount = 0;
  export let invoice_items = [];
  export let start_date = "25/11/2020";
  export let end_date = "25/12/2020";
  export let total_quantity = 1;
  export let total = 0;
  export let subtotal = 0;
  export let amount_due = 0;
  export let starting_balance = 0;
</script>

<style>
  * :global(.textTitle) {
    color: #212121;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 500;
  }

  * :global(.priceWrapper) {
    text-align: end;
  }

  * :global(.line) {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }

  .totalWrapper {
    text-align: end;
  }

  .totalWrapperTotal {
    text-align: end;
    padding-bottom: 5rem;
  }

  * :global(.sizeForSubtotal) {
    font-size: 0.75rem;
  }

  .sizeForTotal {
    font-size: 1.7rem;
  }
  * :global(.iconInfo) {
    font-size: 16px;
    color: grey;
  }
</style>

<div class="container fluid">
  <List avatarList nonInteractive>
    <Item class="py-2">
      <div class="col-8">
        <Text class="mx-4 textTitle">{$_('descrizione_maiusc')}</Text>
      </div>
      <div class="col-2 totalWrapper">
        <Text class="mx-2 textTitle">{$_('quantità')}</Text>
      </div>
      <div class="col-2 totalWrapper">
        <Text class="mx-2 textTitle">{$_('prezzo')}</Text>
      </div>
    </Item>
    <Separator class="line" />
    <Item class="py-2">
      <div class="col-8">
        <Text class="mx-4">{`${start_date} - ${end_date}`}</Text>
      </div>
      <div class="col-2" />
      <div class="col-2" />
    </Item>
    <Separator />
    {#if Array.isArray(invoice_items)}
      {#each invoice_items as item}
        <Item class="py-2">
          <div class="col-8">
            <Text class="mx-4">{item.name}</Text>
          </div>
          <div class="col-2 totalWrapper">
            <Text class="mx-2">{item.quantity}</Text>
          </div>
          <div class="col-2 totalWrapper">
            <Text class="mx-2">{(item.amount / 100).toFixed(2)} €</Text>
          </div>
        </Item>
        <Separator />
      {/each}
    {/if}
  </List>
</div>
<div class="row mr-4 mb-5 totalWrapperTotal">
  {#if discount_name}
    <div class="col-4" />
    <div class="col-6 sizeForSubtotal">
      <strong>{`${discount_name} (${$_('coupon_percent')} ${discount_percent_off})%`}</strong>
    </div>
    <div class="col-2"><span class="mr-3">{discount_amount} €</span></div>
  {/if}

  <div class="col-4" />
  <div class="col-6 sizeForSubtotal">
    <strong>{`${$_('totale')}: `}</strong>
  </div>
  <div class="col-2"><span class="mr-3">{total} €</span></div>

  <div class="col-4" />
  <div class="col-6 sizeForSubtotal">
    <strong>{`${$_('saldo_applicato')}: `}</strong>
  </div>
  <div class="col-2"><span class="mr-3">{starting_balance} €</span></div>

  <div class="col-4" />
  <div class="col-6 sizeForTotal">
    <span class="col-12 importantInfo justFlex">
      <button
        class="btn btnTooltip btnTooltipCharges mdc-ripple-upgraded"
        data-tooltip={$_('dettaglio_iva')}>
        <Icon class="material-icons">info</Icon>
      </button>
      <!-- <Icon
      role="button"
      tabindex="1"
      data-toggle="tooltip"
      data-placement="top"
      title={$_('dettaglio_iva')}
      class="material-icons iconInfo">
      info
    </Icon> -->
      {`${$_('importo_dovuto')}: `}
    </span>
  </div>
  <div class="col-2 sizeForTotal"><span class="mr-3">{amount_due} €</span></div>
</div>
