<script>
    import { createEventDispatcher } from "svelte";
    import { _ } from "../../services/i18n";
    import Dialog from "@smui/dialog";
    import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";
    import { user } from "../../lib/store";

    export let openDialog;
    export let text;

    const dispatch = createEventDispatcher();
    let confirmationDialog;

    $: {
        if (openDialog == true && !confirmationDialog.isOpen()) {
            confirmationDialog.open();
        }
    }

    const handleDialog = (value) => {
        if (value) dispatch("onConfirm");
        else dispatch("onCancel");
        confirmationDialog.close();
        openDialog = false;
    };
</script>

<div>
    <Dialog bind:this={confirmationDialog}>
        <ConfirmationDialog
            bind:text
            confirmationButtonLabel={$_('conferma')}
            on:closeDialog={() => handleDialog(false)}
            on:onConfirmation={() => handleDialog(true)}
        />
    </Dialog>
</div>
