<script>
  import "../../TrainingCards/AssignTrainingCardDialog.scss";
  import { _ } from "../../../services/i18n";
  import { getAtGroups, getGroupTrainingCards, getGroupVodsAndPlaylists, updateMassAssignment } from "../../../services/teamServices";
  import { group, groups } from "../../../lib/store";
  import { navigate } from "svelte-routing";
  import { onMount, tick } from "svelte";
  import AssignedTrainingCardsLayout from "./Workouts/AssignedTrainingCardsLayout.svelte";
  import AssignedVodsLayout from "./Vods/AssignedVodsLayout.svelte";
  import GroupSubHeader from "./GroupSubHeader.svelte";
  import Header from "../../ReusableLayouts/Header.svelte";
  import Members from "./Members/Members.svelte";
  import moment from "moment";
  import MsgError from "../../ReusableLayouts/MsgError.svelte";
  import Settings from "./Settings/Settings.svelte";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  const params = new URLSearchParams(location.search);
  
  let activeTab = $_("membri");
  let allCards = [];
  let allPlaylistAssignments = [];
  let allVideoAssignments = [];
  let assignedCustomers = [];
  let component = [$_("membri")];
  let expiredCards = [];
  let expiredPlaylistAssignments = [];
  let expiredVideoAssignments = [];
  let filtratedGroups = [];
  let firstCards = [];
  let firstPlaylistAssignments = [];
  let firstVideoAssignments = [];
  let groupId;
  let groupPage = 1;
  let hasFetchedTrainingCards = false;
  let hasFetchedPlaylistAssignments = false;
  let hasFetchedVideoAssignments = false;
  let isMobile = false;
  let itemsRow = 20;
  let loadingGroups = false;
  let loadingPlaylists = true;
  let loadingTrainingCards = true;
  let loadingVods = true;
  let massAssignment = false;
  let msgError;
  let nextCards = [];
  let nextPlaylistAssignments = [];
  let nextVideoAssignments = [];
  let options = {
    [$_("membri")]       : Members,
    [$_("allenamenti")]  : AssignedTrainingCardsLayout,
    [$_("video")]        : AssignedVodsLayout,
    [$_("impostazioni")] : Settings,
  };
  let orderByGroups = "name";
  let orderDesc = false;
  let playlistAssignments = [];
  let profileImage = "";
  let props = {};
  let customersRemovingRulesEnabled = false;
  let removeCustomersAtSpecificDate = false;
  let removeCustomersAfterPeriod = true;
  let ruleSpecificDate;
  let ruleIntervalCount = 30;
  let ruleInterval = 0;
  let searchParamsTab = "members";
  let searchName = "";
  let subHeaderProps;
  let tabs = [
    $_("membri"),
    $_("allenamenti"),
    $_("video"),
    $_("impostazioni"),
  ];
  let totalGroupPages = 1;
  let trainingCards = [];
  let videoAssignments = [];

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  $: {
    if (searchParamsTab == "members") {
      activeTab = $_("membri");
      component = $_("membri");
      props = { 
        assignedCustomers,
        groupId,
        isMobile,
        massAssignment,
      };
    } else if (searchParamsTab == "workouts") {
      activeTab = $_("allenamenti");
      component = $_("allenamenti");
      props = { 
        firstCards,
        groupId,
        isMobile,
        loadingTrainingCards,
        nextCards,
      };
    } else if (searchParamsTab == "vods") {
      activeTab = $_("video");
      component = $_("video");
      props = { 
        groupId,
        isMobile,
        loadingPlaylists,
        loadingVods,
      };
    } else {
      activeTab = $_("impostazioni");
      component = $_("impostazioni");
      props = { 
        groupId,
        massAssignment,
        customersRemovingRulesEnabled,
        removeCustomersAtSpecificDate,
        removeCustomersAfterPeriod,
        ruleSpecificDate,
        ruleIntervalCount,
        ruleInterval,
      };
    }
  }

  $: subHeaderProps = {
    profileImage,
  };
  
  $: firstCards = allCards.slice(0, 5);
  $: firstPlaylistAssignments = allPlaylistAssignments.slice(0, 5);
  $: firstVideoAssignments = allVideoAssignments.slice(0, 5);
  
  $: nextCards = allCards.slice(5);
  $: nextPlaylistAssignments = allPlaylistAssignments.slice(5);
  $: nextVideoAssignments = allVideoAssignments.slice(5);

  $: (async () => {
    if (group) {
      if (component == $_("allenamenti")) {
        if (!hasFetchedTrainingCards) await getTrainingCardsInfo();
        // props = {
        //   firstCards,
        //   group,
        //   groupId,
        //   nextCards,
        // };
      } else if (component == $_("video")) {
        if (!hasFetchedVideoAssignments) await getVideoAssignments();
        if (!hasFetchedPlaylistAssignments) await getPlaylistAssignments();
        props = {
          firstPlaylistAssignments,
          firstVideoAssignments,
          groupId,
          nextPlaylistAssignments,
          nextVideoAssignments,
        };
      } else {
        // props = { groupId };
      }
    }
  })();

  $: if (location && location.state && location.state.groupId) {
    groupId = location.state.groupId;
  } else if (params.has("groupId")) {
    groupId = params.get("groupId");
  }

  const handleUpdateCards = async () => {
    await getTrainingCardsInfo();
  }

  const handleUpdateAssignedVodsAndPlaylists = async () => {
    getVideoAssignments();
  }

  const handleUpdateMassAssignment = async (e) => {
    try {
      const res = await updateMassAssignment(
        e.detail.groupId,
        e.detail.massAssignment
      );
      await tick();
      await handleUpdateAtGroups();
    } catch (error) {
      console.log("handleUpdateMassAssignment error", error);
      displayError(error);
    }
  }

  const handleUpdateAtGroups = async () => {
    try {
      // loadingGroups = true;
      const name = searchName ? searchName : null;
      const { data, last_page } = await getAtGroups(
        itemsRow,
        groupPage,
        name,
        orderByGroups,
        orderDesc
      );
      totalGroupPages = last_page;
      filtratedGroups = data.map((ele) => {
        ele.selected = false;
        return ele;
      });
      await tick();
      groups.set(filtratedGroups);
      await tick();
      initGroup(groupId);
      // loadingGroups = false;
    } catch (error) {
      loadingGroups = false;
      console.log("getFiltratedCustomers error", error);
      displayError(error);
    }
  }

  const sortByDates = (arrayDates) => {
    const toBeSorted = [...arrayDates];
    return toBeSorted.sort((a, b) => new Date(b.end) - new Date(a.end));
  };

  const getTrainingCardsInfo = async () => {
    try {
      trainingCards = [];
      trainingCards = await getGroupTrainingCards(groupId);
      await tick();
      let endNullCards = [];
      let validCards = [];
      // display only cards that are not expired (or do not expire at all)
      for (const card of trainingCards) {
        const isExpired = moment(card.end).isBefore(new Date());
        if (!card.end) {
          endNullCards = [...endNullCards, card];
        } else if (!isExpired) {
          validCards = [...validCards, card];
        } else {
          expiredCards = [...expiredCards, card];
        }
      }
      const sortedCards = sortByDates(validCards);
      const sortedEndNullCards = sortByDates(endNullCards);
      const sortedExpiredCards = sortByDates(expiredCards);

      allCards = [...sortedCards, ...sortedEndNullCards, ...sortedExpiredCards];
      hasFetchedTrainingCards = true;
    } catch (err) {
      console.log("err getTrainingCardsInfo", err);
      displayError(err);
    }
    loadingTrainingCards = false;
  };

  const initGroup = async (groupId) => {
    let tempArray = [];
    $groups.map(g => {
      if (g.id == groupId) {
        // Distribute customers on columns
        tempArray = JSON.parse(JSON.stringify(g.customers));
        assignedCustomers = [...distributeCustomers(tempArray)];
        // Options
        massAssignment = g.mass_assignment,
        // Options - Automation rules
        customersRemovingRulesEnabled = g.automations.active                    ?? false,
        removeCustomersAfterPeriod    = g.automations.active_after_period_rule  ?? false,
        removeCustomersAtSpecificDate = g.automations.active_specific_date_rule ?? false,
        ruleSpecificDate              = g.automations.rule_date                 ?? '',
        ruleIntervalCount             = g.automations.rule_interval_count       ?? 1,
        ruleInterval                  = g.automations.rule_interval             ?? 'month',
        // Setting group in local storage
        group.set({...g});
      }
    });
  };

  const distributeCustomers = (customersList) => {
    let rows;
    let columns = 1;
    let array = [];

    if (customersList != undefined) {
      if (!isMobile) {
        if (customersList.length < 5) { 
          columns = 1; 
        } else if (customersList.length >= 5 && customersList.length < 9) {
          columns = 2;
        } else {
          columns = 3;
        }

        rows = Math.ceil(customersList.length / columns);
        rows = rows < 4 ? 4 : rows;
        
        return array = new Array(rows)
          .fill()
          .map(_ => customersList.splice(0, rows));
      } else {
        rows = Math.ceil(customersList.length / columns);
        return array = new Array(rows)
          .fill()
          .map(_ => customersList.splice(0, rows));
      }
    }
  }

  const getVideoAssignments = async () => {
    try {
      videoAssignments = await getGroupVodsAndPlaylists(groupId, 'video');
      let endNullVideoAssignments = [];
      let validVideoAssignments = [];
      // display only cards that are not expired (or do not expire at all)
      for (const assignment of videoAssignments) {
        const isExpired = moment(assignment.end).isBefore(new Date());
        if (!assignment.end) {
          endNullVideoAssignments = [...endNullVideoAssignments, assignment];
        } else if (!isExpired) {
          validVideoAssignments = [...validVideoAssignments, assignment];
        } else {
          expiredVideoAssignments = [...expiredVideoAssignments, assignment];
        }
      }
      const sortedVideoAssignments = sortByDates(validVideoAssignments);
      const sortedEndNullVideoAssignments = sortByDates(endNullVideoAssignments);
      const sortedExpiredVideoAssignments = sortByDates(expiredVideoAssignments);

      allVideoAssignments = [...sortedVideoAssignments, ...sortedEndNullVideoAssignments, ...sortedExpiredVideoAssignments];

      hasFetchedVideoAssignments = true;
    } catch (err) {
      console.log("err getVideoAssignments", err);
      displayError(err);
    }
    loadingVods = false;
  };

  const getPlaylistAssignments = async () => {
    try {
      playlistAssignments = await getGroupVodsAndPlaylists(groupId, 'playlist');
      let endNullPlaylistAssignments = [];
      let validPlaylistAssignments = [];
      // display only cards that are not expired (or do not expire at all)
      for (const assignment of playlistAssignments) {
        const isExpired = moment(assignment.end).isBefore(new Date());
        if (!assignment.end) {
          endNullPlaylistAssignments = [...endNullPlaylistAssignments, assignment];
        } else if (!isExpired) {
          validPlaylistAssignments = [...validPlaylistAssignments, assignment];
        } else {
          expiredPlaylistAssignments = [...expiredPlaylistAssignments, assignment];
        }
      }
      const sortedPlaylistAssignments = sortByDates(validPlaylistAssignments);
      const sortedEndNullPlaylistAssignments = sortByDates(endNullPlaylistAssignments);
      const sortedExpiredPlaylistAssignments = sortByDates(expiredPlaylistAssignments);

      allPlaylistAssignments = [...sortedPlaylistAssignments, ...sortedEndNullPlaylistAssignments, ...sortedExpiredPlaylistAssignments];
      
      hasFetchedPlaylistAssignments = true;
    } catch (err) {
      console.log("err getPlaylistAssignments", err);
      displayError(err);
    }
    loadingPlaylists = false;
  };

  const handleActiveTabChanged = (e) => {
    if ([e.detail.tab] == $_("membri"))
      navigate(`/group?tab=members&groupId=` + groupId, {
        replace: true,
        state: { groupId },
      });
    else if ([e.detail.tab] == $_("allenamenti"))
      navigate(`/group?tab=workouts&groupId=` + groupId, {
        replace: true,
        state: { groupId },
      });
    else if ([e.detail.tab] == $_("video"))
      navigate(`/group?tab=vods&groupId=` + groupId, {
        replace: true,
        state: { groupId },
      });
    else
      navigate(`/group?tab=profile&groupId=` + groupId, {
        replace: true,
        state: { groupId },
      });
  };

  const displayError = (err = null) => {
    let timeoutTime = 3000;
    msgError = $_("si_è_verificato_un_errore");
    if (err.message.exception && err.message.exception === 'PlanConflictException') {
      
      msgError = $_("plan_conflict_exception");
      timeoutTime = 9000000;
    }
    setTimeout(() => {
      msgError = "";
    }, timeoutTime);
  };

  onMount(async () => {
    initGroup(groupId);
    window.scrollTo(0, 0);
  });
</script>

<style>
  @media (max-width: 767px) {
     :global(.headerText) {
      font-size: 0.875rem !important;
    }
  }
</style>

<Header
  text={`${isMobile ? $_('indietro') : $_('gruppi')}`}
  asTextAndIcon={true}
  asTitle={false}
  noBackground={false}
  subTitle={false}
  summary={false}
  sticky={false}
  {tabs}
  {activeTab}
  component={GroupSubHeader}
  props={subHeaderProps}
  on:activeTabChanged={handleActiveTabChanged}
  tabNavigationEnabled={true}
  on:goBack={() => navigate('/team?tab=gruppi')} />

<div class="container fluid">
  <MsgError {msgError} />

  <svelte:component
    this={options[component]}
    {...props}
    on:updateMassAssignment={handleUpdateMassAssignment}
    on:updateCards={handleUpdateCards}
    on:updateAtGroups={handleUpdateAtGroups}  />
</div>
