import { POST, GET } from "../lib/http";
import { notificationStatus, oneSignalPlayerId, user } from "../lib/store";

const commonHeaders = {
    ["Auth"]: "REVOO2019",
    ["Access-Control-Allow-Origin"]: "*",
    ["Accept"]: "application/json",
};

export const getExpiringTrainingCards = async (range = 'next_7') => {
  try {
      const res = await GET("/v2/get_expiring_training_cards", {
          headers: { ...commonHeaders },
          query: {
            range
          },
      });
      return res;
  } catch (error) {
      throw error;
  }
};

export const getExpiringVodsAndPlaylists = async (range = 'next_7') => {
  try {
      const res = await GET("/v2/get_expiring_vods_and_playlists", {
          headers: { ...commonHeaders },
          query: {
            range
          },
      });
      return res;
  } catch (error) {
      throw error;
  }
};

export const getExpiringSubscriptions = async (range = 'next_7') => {
  try {
      const res = await GET("/v2/get_expiring_subscriptions", {
          headers: { ...commonHeaders },
          query: {
            range
          },
      });
      return res;
  } catch (error) {
      throw error;
  }
};

export const getAtGroups = async (
    per_page,
    page,
    name,
    order_by,
    order_by_dir
  ) => {
    try {
      const queryObj = {
        per_page: per_page,
        page: page,
      };
      name ? (queryObj.name = name) : null;
      queryObj.order_by = order_by ? order_by : "name";
      order_by_dir ? (queryObj.order_by_dir = "DESC") : null;
  
      const res = await GET("/v2/get_at_groups", {
        headers: { ...commonHeaders },
        query: queryObj,
      });
      const { data, last_page } = res;
      return { data, last_page };
    } catch (error) {
      console.log("getAtGroups err", error);
      throw error;
    }
  };

export const sortList = (list, key = 'expiring_date', ascOrder = true) => {
  let sortedList = list;
  if (key == 'assignee_name') {
    sortedList = list.sort((a, b) => {
      if (a.assignee_name.toLowerCase() > b.assignee_name.toLowerCase()) return 1;
      if (a.assignee_name.toLowerCase() < b.assignee_name.toLowerCase()) return -1;
      return 0;
    });
  } else if (key == 'expiring_date') {
    // Ordina date in formato dd/mm/yyyy, poi priorità all'ordinamento per fullname, sempre ascendente
    sortedList = list.sort((a, b) => {
      if (a.expiring_date.split("/").reverse().join("/") > b.expiring_date.split("/").reverse().join("/")) return 1;
      if (a.expiring_date.split("/").reverse().join("/") < b.expiring_date.split("/").reverse().join("/")) return -1;
      if (ascOrder) {
        if (a.assignee_name.toLowerCase() > b.assignee_name.toLowerCase()) return 1;
        if (a.assignee_name.toLowerCase() < b.assignee_name.toLowerCase()) return -1;
      } else {
        if (a.assignee_name.toLowerCase() < b.assignee_name.toLowerCase()) return 1;
        if (a.assignee_name.toLowerCase() > b.assignee_name.toLowerCase()) return -1;
      }
      return 0;
    });
  }
  if (!ascOrder) return sortedList.reverse();
  return sortedList;
}