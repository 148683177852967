import { _ } from "../../../services/i18n";

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

export const referralCodeRegex = /^[a-z0-9]+$/i;

export const checkEmail = (email) => emailRegex.test(email);

export const checkPassword = (password) => {
  if (password.length >= 6) return true;
  else return false;
};

export const checkReferralCode = (code) => referralCodeRegex.test(code);
