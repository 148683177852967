<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import { _ } from "../../../services/i18n";
  import Card, { Content, Actions } from "@smui/card";
  import { Icon } from "@smui/common";
  import Button, { Label } from "@smui/button";
  import CropDialog from "../../Auth/FlowRegistration/CropDialog.svelte";
  import Dialog, { Title } from "@smui/dialog";
  import Header from "../../ReusableLayouts/Header.svelte";
  import MsgError from "../../ReusableLayouts/MsgError.svelte";

  export let profileImage = "";
  let imageCrop = "";
  let croppedSquare = true;
  let imageDialog = false;
  let msgError;
  let formInvalid;

  const handleImageSelected = (file) => {
    croppedSquare = false;
    imageCrop = URL.createObjectURL(file);
    imageDialog.open();
  };

  const updateImage = (e) => {
    msgError = "";
    croppedSquare = true;
    profileImage = URL.createObjectURL(e.detail.image);
    dispatch('imageUpdated', {
      image: e.detail.image
    })
  };

  const onInputChange = (e) => {
    const file = e.target.files[0];
    if (!file || file.size > 5000000) {
      msgError = $_("la_dimensione_massima_consentita_è_di", {
        values: {
          dimension: 5,
        },
      });
      setTimeout(() => {
        msgError = "";
      }, 3000);
      return;
    }
    handleImageSelected(file);
  };
</script>

<style>
  .customAvatar {
    background-color: lightblue;
    border-radius: 50%;
    height: 80px;
    object-fit: cover;
    position: relative;
    width: 80px;
  }

  .image__container {
    height: 80px;
    position: absolute;
    top: 0;
    width: 80px;
  }
  .customInput {
    height: 1.5rem;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 1rem;
    width: 1.5rem;
    z-index: 1032;
  }
  * :global(.customIcon) {
    color: #fff;
    height: 1.5rem;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    bottom: 1rem;
    width: 1.5rem;
  }
  @media (min-width: 576px) {
    .customAvatar, .image__container {
      height: 120px;
      width: 120px;
    }
  }
</style>

<Dialog
  bind:this={imageDialog}
  aria-labelledby="dialog-for-cropping-image"
  aria-describedby="image-cropper">
  <CropDialog
    {imageCrop}
    squared={true}
    mustCrop={true}
    on:closeDialog={() => imageDialog.close()}
    on:imageHasBeenCropped={updateImage} />
</Dialog>

<div>
  <img src={profileImage} class="customAvatar" alt="" />
  <div class="image__container">
    <input
      type="file"
      class="customInput"
      accept="image/jpeg, image/png, image/webp"
      on:change={onInputChange} />
    <Icon role="button" tabindex="1" class="material-icons customIcon">
      camera_alt
    </Icon>
  </div>
  <MsgError {msgError} />
</div>
