<script>
  import { _ } from "../../../../services/i18n";
  import { Icon } from "@smui/common";
  import Button, { Label } from "@smui/button";
  import CarouselLayout from "./CarouselLayout.svelte";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import Chart from "./Chart.svelte";
  import Circumference from "./Circumference.svelte";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import DatePicker from "../../../ReusableLayouts/DatePicker.svelte";
  import Dialog from "./Dialog.svelte";
  import SvelteTooltip from "svelte-tooltip";
  import GenericDialog from "@smui/dialog";
  import moment from "moment";
  import Select, { Option } from "@smui/select";
  import Textfield from "@smui/textfield";
  import {
    getCustomerGraphic,
    getCircumferences,
    getLastCircumferences,
    getCompositions,
    getLastCompositions,
    getProgressEnabledDates,
    getFitnessSync,
    getCustomerGraphicFit,
    getGoal,
    setGoal,
    deleteComposition,
    deleteCircumferences,
  } from "../../../../services/teamServices";
  import {
    getProgressCheckTypes,
    getCompositionTypes,
    getCicumferenceTypes,
  } from "../../../../lib/progressCheckInfo";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import { onMount, tick } from "svelte";
  import { customer } from "../../../../lib/store";
  import BannerHelp from "../../../ReusableLayouts/BannerHelp.svelte";

  const DATEFORMAT = "YYYY-MM-DD";
  export let customerId = null;

  // donuts graph
  let compositionDate = "";
  let compositionDateFormat = "";
  let compositionMeasure = [];
  const emptyComposition = [0, 0, 0];
  let compositionMeasureLabel = [
    $_("massa_magra"),
    $_("massa_grassa"),
    $_("massa_ossea"),
  ];

  let showCompositionChart = true;
  let mounted = false;
  let missingParameters = [];

  // circumference component
  let circumferenceDate = "";
  let circumferenceDateFormat = "";

  let circumferencesMeasure = {
    bicep_l: null,
    bicep_r: null,
    calf_l: null,
    calf_r: null,
    chest: null,
    hip: null,
    id: null,
    leg_l: null,
    leg_r: null,
    waist: null,
  };

  // line graph
  let graphTypeSelected = "weights";
  let periodSelected = "last_6_month";
  let graphValues = [];
  let graphLabels = ["bone_mass_kg", "bmr", "lean_mass_kg", "body_fat_perc"];
  let graphUnit;
  let periods = [
    { key: "last_week", label: $_("ultima_settimana") },
    { key: "last_1_month", label: $_("ultimi_30_giorni") },
    { key: "last_3_month", label: $_("ultimi_3_mesi") },
    { key: "last_6_month", label: $_("ultimi_6_mesi") },
    { key: "last_year", label: $_("ultimo_anno") },
    { key: "all_time", label: $_("tutte_le_date") },
  ];
  
  const endDate = moment(new Date()).format(DATEFORMAT);

  let circumferenceDateIsFetched = false;
  let circumferencesDateToDelete = null;
  let circumferenceType = [];
  let compositionDateIsFetched = false;
  let compositionDateToDelete = null;
  let compositionType = [];
  let deleteDialog;
  let deleteMessage = "";
  let deleteMethod = null;
  let dialogDate = null;
  let elementsDialog = [];
  let enabledDatesCircumferences = [];
  let enabledDatesCompositions = [];
  let fitnessSyncDisabled;
  let goal = '';
  let goalId = null;
  let goalLoaded = false;
  let group = "composition";
  let lastCircumferencesIsFetched = false;
  let lastCompositionsIsFetched = false;
  let noChartValue = null;
  let openDialog = false;
  let previousGoal = '';
  let showChartProgress = true;
  let startDate;
  let syncFitnessToPtDisabled;
  let types = [];
  let typingGoal = false;

  $: types = getProgressCheckTypes($_);
  $: compositionType = getCompositionTypes($_);
  $: circumferenceType = getCicumferenceTypes($_);

  const keysCircumferenceType = [
    "bicep_r",
    "bicep_l",
    "chest",
    "waist",
    "hip",
    "leg_r",
    "leg_l",
    "calf_r",
    "calf_l",
  ];

  const keysGoogleFitType = [
    "distance",
    "steps",
    "calories",
    "nutrition.fat.total",
    "nutrition.carbs.total",
    "nutrition.protein",
  ];

  $: (async () => {
    if (customerId) {
      if (
        !lastCircumferencesIsFetched &&
        !lastCompositionsIsFetched &&
        mounted
      ) {
        await getEnabledDates();
        await setGetLastCircumferences();
        await setGetLastCompositions();
      }
      await getFitnessSyncData();
      if (!goalLoaded && $customer.active) {
        getGoalData();
      }
    }
  })();

  $: {
    if (customerId && circumferenceDate) {
      if (lastCircumferencesIsFetched) {
        setGetCircumferences();
      }
      circumferenceDateIsFetched = true;
    }
  }
  $: {
    if (customerId && compositionDate) {
      if (lastCompositionsIsFetched) {
        setGetComposition();
      }
      compositionDateIsFetched = true;
    }
  }

  $: {
    if (periodSelected) {
      const now = moment(new Date());
      switch (periodSelected) {
        case "last_week":
          startDate = now.add(-6, "days").format(DATEFORMAT);
          break;
        case "last_1_month":
          startDate = now.add(-1, "months").format(DATEFORMAT);
          break;
        case "last_3_month":
          startDate = now.add(-3, "months").format(DATEFORMAT);
          break;
        case "last_6_month":
          startDate = now.add(-6, "months").format(DATEFORMAT);
          break;
        case "last_year":
          startDate = now.add(-1, "year").format(DATEFORMAT);
          break;
        case "all_time":
          startDate = moment(new Date(0)).format(DATEFORMAT);
          break;
      }
    }
  }

  $: (async () => {
    if (customerId && graphTypeSelected && startDate && endDate) {
      if (keysGoogleFitType.includes(graphTypeSelected)) {
        if (fitnessSyncDisabled || syncFitnessToPtDisabled) {
          showChartProgress = false;
          noChartValue = null;
        } else {
          showChartProgress = true;
          await getCustomerFitData();
        }
      } else {
        showChartProgress = true;
        await getCustomerData();
      }
    }
  })();

  let isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  $: {
    if (isMobile && graphTypeSelected.split("_").length > 1)
      if (document.getElementById("SMUI-select-1-smui-selected-text") && 
          document.getElementById("SMUI-select-1-smui-selected-text").innerText) 
      {
        document.getElementById("SMUI-select-1-smui-selected-text").innerText =
          "...";
      }
  }

  const setGetLastCircumferences = async () => {
    try {
      const res = await getLastCircumferences(customerId);
      circumferenceDate = res.date;
      circumferenceDateFormat = null;
      await tick();
      circumferenceDateFormat = moment(res.date).format("DD/MM/YYYY");
      circumferencesMeasure = res;
      lastCircumferencesIsFetched = true;
    } catch (error) {
      console.log("setGetLastCircumferences err", error);
    }
  };

  const setGetCircumferences = async (date = null) => {
    if (lastCircumferencesIsFetched) {
      try {
        if (date) circumferenceDate = date;
        circumferencesMeasure = await getCircumferences(
          customerId,
          circumferenceDate
        );
        circumferenceDateFormat = null;
        await tick();
        circumferenceDateFormat = moment(circumferenceDate).format(
          "DD/MM/YYYY"
        );
      } catch (error) {
        console.log("setGetCircumferences err", error);
      }
    }
  };

  const setGetLastCompositions = async () => {
    try {
      const res = await getLastCompositions(customerId);
      compositionDate = res.date;
      const { bone_mass_kg, lean_mass_kg, body_fat_perc, chart_check } = res;
      showCompositionChart = chart_check ? chart_check.show : false;
      missingParameters = chart_check ? chart_check.missing_parameters : [];
      compositionMeasure = chart_check
        ? [
            chart_check.lean_mass_kg || 0,
            chart_check.body_fat_kg || 0,
            chart_check.bone_mass_kg || 0,
          ]
        : [lean_mass_kg || 0, body_fat_perc || 0, bone_mass_kg || 0];
      lastCompositionsIsFetched = true;
      compositionDateFormat = null;
      await tick();
      compositionDateFormat = moment(res.date).format("DD/MM/YYYY");
    } catch (error) {
      console.log("setGetLastCompositions err", error);
    }
  };

  const setGetComposition = async (date = null) => {
    if (lastCompositionsIsFetched) {
      try {
        if (date) compositionDate = date;
        const res = await getCompositions(customerId, compositionDate);
        const { bone_mass_kg, lean_mass_kg, body_fat_perc, chart_check } = res;
        missingParameters = chart_check ? chart_check.missing_parameters : [];
        compositionMeasure = chart_check
          ? [
              chart_check.lean_mass_kg || 0,
              chart_check.body_fat_kg || 0,
              chart_check.bone_mass_kg || 0,
            ]
          : [lean_mass_kg || 0, body_fat_perc || 0, bone_mass_kg || 0];
        showCompositionChart = chart_check ? chart_check.show : false;
        compositionDateFormat = null;
        await tick();
        compositionDateFormat = moment(compositionDate).format("DD/MM/YYYY");
      } catch (error) {
        console.log("setGetComposition err", error);
      }
    }
  };

  const getCustomerData = async () => {
    try {
      const data = await getCustomerGraphic(
        customerId,
        graphTypeSelected,
        startDate,
        endDate
      );
      const newData = [];
      const newLabels = [];
      for (const [key, value] of Object.entries(data)) {
        if (value.length != 0 && value[value.length - 1].created_at) {
          let obj = { value: value[value.length - 1].value, date: key };
          newData.push(obj);
          newLabels.push(moment(key).format("DD/MM/YYYY"));
        }
      }
      if (newData.length > 0) {
        graphValues = newData;
        noChartValue = null;
        showChartProgress = true; //
      } else {
        noChartValue = $_("non_ci_sono_dati_presenti");
        showChartProgress = false;
      }
      graphLabels = newLabels;
      graphUnit = types.find((el) => el.key == graphTypeSelected).unit;
    } catch (error) {
      console.log("getCustomerGraphic err", error);
    }
  };
  const formattingDate = (date, dateFormat) => {
    if (date) {
      return moment(date, "DD/MM/YYYY").format(
        dateFormat ? dateFormat.toUpperCase() : "YYYY-MM-DD"
      );
    }
  };

  const handleCompositionDispatch = (e) => {
    const { date, dateFormat } = e.detail;
    compositionDate = formattingDate(date, dateFormat);
  };

  const handleCircumferenceDispatch = (e) => {
    const { date, dateFormat } = e.detail;
    circumferenceDate = formattingDate(date, dateFormat);
  };

  const handleSelectedData = async (e) => {
    try {
      const { date } = e.detail.data;
      if (keysCircumferenceType.includes(graphTypeSelected)) {
        const res = await getCircumferences(customerId, date);
        group = "circumferences";
        dialogDate = date;
        openDialog = true;
        const newCircumferenceType = circumferenceType.map((row) => {
          const newRow = row.map((obj) => {
            if (res[obj.key] || res[obj.key] === null) {
              const newObj = obj;
              newObj.value = res[obj.key];
              return newObj;
            } else return obj;
          });
          return newRow;
        });
        elementsDialog = circumferenceType;
      } else if (keysGoogleFitType.includes(graphTypeSelected)) { 
        return;
      } else {
        const res = await getCompositions(customerId, date);
        group = "composition";
        dialogDate = date;
        openDialog = true;
        const newCompositionType = compositionType.map((row) => {
          const newRow = row.map((obj) => {
            if (res[obj.key] || res[obj.key] === null) {
              const newObj = obj;
              newObj.value = res[obj.key];
              return newObj;
            } else if (res.chart_check && res.chart_check[obj.key]) {
              const newObj = obj;
              newObj.value = res.chart_check[obj.key];
              return newObj;
            } else return obj;
          });
          return newRow;
        });
        elementsDialog = compositionType;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseDialog = (e) => {
    openDialog = false;
  };

  const handleRefreshGraph = async (e) => {
    const { group, date, refresh } = e.detail;
    try {
      if (refresh) {
        await getCustomerData();
        await getEnabledDates(group);
        if (group == "composition") {
          compositionDate = null;
          await tick();
          compositionDate = date;
        } else {
          circumferenceDate = null;
          await tick();
          circumferenceDate = date;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEnabledDates = async (group = null) => {
    try {
      const res = await getProgressEnabledDates(customerId);
      if (group == "composition")
        enabledDatesCompositions =
          res.compositions.length > 0 ? res.compositions : false;
      else if (group == "circumferences")
        enabledDatesCircumferences =
          res.circumferences.length > 0 ? res.circumferences : false;
      else {
        enabledDatesCircumferences =
          res.circumferences.length > 0 ? res.circumferences : false;
        enabledDatesCompositions =
          res.compositions.length > 0 ? res.compositions : false;
      }
    } catch (error) {
      console.log("getEnabledDates err", error);
    }
  };

  const getFitnessSyncData = async () => {
    try {
      const res = await getFitnessSync(customerId);
      fitnessSyncDisabled = res.fitness_sync_enabled
        ? false
        : $_("atleta_non_ha_attivato_sincronizzazione");
      syncFitnessToPtDisabled = res.sync_fitness_to_pt
        ? false
        : $_("cliente_non_ha_consentito_a_fornirti_i_dati");
    } catch (error) {
      console.log("getFitnessSync err", error);
    }
  };  

  const getCustomerFitData = async () => {
    try {
      const data = await getCustomerGraphicFit(
        customerId,
        graphTypeSelected,
        startDate,
        endDate
      );
      const newData = [];
      const newLabels = [];
      for (const [key, value] of Object.entries(data)) {
        if (value.length != 0 && value[value.length - 1].created_at) {
          let obj = { value: value[value.length - 1].value, date: key };
          newData.push(obj);
          newLabels.push(moment(key).format("DD/MM/YYYY"));
        }
      }
      if (newData.length > 0) {
        graphValues = newData;
        noChartValue = null;
      } else {
        noChartValue = $_("non_ci_sono_dati_presenti");
        showChartProgress = false;
      }
      graphLabels = newLabels;
      graphUnit = types.find((el) => el.key == graphTypeSelected).unit;
    } catch (error) {
      console.log("getCustomerGraphicFit err", error);
    }
  };

  const getGoalData = async () => {
    if (goalLoaded === false) {
      try {
        if (customerId) {
          const res = await getGoal(customerId);
          if (res.goal_description && res.goal_description != '') {
            goal = res.goal_description;
            goalId = res.id;
            previousGoal = goal != null ? goal.slice() : null;
            goalLoaded = true;
          }
        }
      } catch (error) {
        if (error.status == 404) {
          console.log('No goal');
        }
      }
    }
  }

  const setGoalData = async () => {
    try {
      const res = await setGoal(customerId, goal, goalId);
      goal = res.goal_description != null ? res.goal_description : '';
      previousGoal = goal != null ? goal.slice() : '';
    } catch (error) {
      console.log('error');
    }
  }

  const handleDeleteCompositionDialog = (e) => {
    const { selectedDate } = e.detail;
    compositionDateToDelete = selectedDate;
    deleteMessage = $_("sei_sicuro_di_voler_eliminare_la_composizione_del", { values: { date: selectedDate }});
    deleteMethod = handleDeleteComposition;
    deleteDialog.open();
  };

  const handleDeleteCircumferencesDialog = (e) => {
    const { selectedDate } = e.detail;
    circumferencesDateToDelete = selectedDate;
    deleteMessage = $_("sei_sicuro_di_voler_eliminare_le_circonferenze_del", { values: { date: selectedDate }});
    deleteMethod = handleDeleteCircumferences;
    deleteDialog.open();
  };

  const handleDeleteComposition = async () => {
    await deleteComposition(
      customerId, 
      moment(compositionDateToDelete, "DD/MM/YYYY").format("YYYY-MM-DD")
    );
    await tick();
    compositionDateToDelete = null;
    // Reload composition chart
    await getEnabledDates();
    await setGetLastCompositions();
    await getCustomerData();

    deleteDialog.close();
  };

  const handleDeleteCircumferences = async () => {
    await deleteCircumferences(
      customerId, 
      moment(circumferencesDateToDelete, "DD/MM/YYYY").format("YYYY-MM-DD")
    );
    await tick();
    circumferencesDateToDelete = null;
    
    // Reload circumferences chart
    await getEnabledDates();
    await setGetLastCircumferences();
    await getCustomerData();

    deleteDialog.close();
  };

  onMount(async () => {
    mounted = true;
  });
</script>

<div class="my-3">
  <BannerHelp state='progresses'/>
</div>

<div class="containerProgress">
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('obiettivi')}</div>
          <div class="d-flex justify-content-end">
            {#if !typingGoal}
              <Button
                color="secondary"
                variant="outlined"
                class={$customer.active ? `` : `action-disabled`}
                disabled={!$customer.active}
                on:click={() => {
                  typingGoal = true;
                }}>
                <Label>
                  <Icon
                    role="button"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing">
                    edit
                  </Icon>
                  {goal != null ? $_('modifica') : $_('aggiungi')}
                </Label>
              </Button>
            {:else}
              <Button
                color="secondary"
                variant="outlined"
                class={$customer.active ? `saveBtn` : `action-disabled`}
                disabled={!$customer.active}
                on:click={() => {
                  typingGoal = false;
                  setGoalData();
                }}>
                <Label>
                  <Icon
                    role="button"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing">
                    save
                  </Icon>
                  {$_('salva')}
                </Label>
              </Button>
              <Button 
                class="btn undoBtn ml-2"
                on:click={() => {
                  typingGoal = false;
                  previousGoal = goal != null ? goal.slice() : null;
              }}>
                <Label>{$_('annulla')}</Label>
              </Button>
            {/if}
          </div>
          <div class="spaceBetween">
            <Textfield
              textarea
              input$maxlength="200"
              class="fullWidth my-2 goalDescription"
              bind:value={goal}
              disabled={!typingGoal}
              label="">
              <CharacterCounter>{goal !== null && goal !== '' ? `${goal.length} / 200` : `0 / 200`}</CharacterCounter>
            </Textfield>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('composizione')}</div>
          <div class="spaceBetween">
            <div class="d-flex">
              <DatePicker
                enabledDates={enabledDatesCompositions}
                on:date={handleCompositionDispatch}
                on:deleteComposition={handleDeleteCompositionDialog}
                defaultDate={compositionDateFormat}
                withDeleteButton={compositionMeasure === [] ? false : true}
                customerId={customerId}
                item={"composition"}
                width="100" />
            </div>
            <Button
              color="secondary"
              variant="outlined"
              class={$customer.active ? `` : `action-disabled`}
              disabled={!$customer.active}
              on:click={() => {
                elementsDialog = getCompositionTypes($_);
                group = 'composition';
                openDialog = true;
                dialogDate = null;
              }}>
              <Label>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing">
                  opacity
                </Icon>
                {$_('nuova_rilevazione')}
              </Label>
            </Button>
          </div>
        </div>
        <div class="row compositionContainer no-gutters">
          {#if showCompositionChart}
            <Chart
              chartType={'doughnut'}
              data={compositionMeasure}
              labels={compositionMeasureLabel} />
          {:else}
            <Chart
              chartType={'doughnut'}
              data={emptyComposition}
              labels={compositionMeasureLabel} />
            <div class="col-12">
              <FormMessage>{$_('dati_non_completi')}</FormMessage>
            </div>
            {#if missingParameters && missingParameters.length > 0}
              <div class="col-12">
                <FormMessage>
                  {$_('non_sono_stati_inseriti_i_seguenti_dati')} : {missingParameters.join(', ')}
                </FormMessage>
              </div>
            {/if}
          {/if}
        </div>
        <div class="row no-gutters flexy rowSpace spaceBetween">
          <Select
            variant="outlined"
            enhanced
            bind:value={periodSelected}
            label=""
            class="demo-select-width"
            menu$class="demo-select-width">
            {#each periods as period}
              <Option
                value={period.key}
                selected={periodSelected === period.key}>
                {period.label}
              </Option>
            {/each}
          </Select>
          <Select
            class="shaped-outlined demo-select-width select--colored"
            variant="outlined"
            enhanced
            bind:value={graphTypeSelected}
            label=""
            menu$class="demo-select-width select--max-height">
            {#each types as type}
              <Option
                value={type.key}
                selected={graphTypeSelected === type.key}>
                {type.label}
              </Option>
            {/each}
          </Select>
        </div>
        <div class="row no-gutters">
          {#if showChartProgress}
            <Chart
              chartType={'line'}
              data={graphValues}
              labels={graphLabels}
              unit={graphUnit}
              on:selectedData={handleSelectedData} />
          {:else}
            <div class="message__container">
              {noChartValue || fitnessSyncDisabled || syncFitnessToPtDisabled}
            </div>
          {/if}
        </div>
      </div>
      <hr class="mobile" />
      <div class="col-12 col-md-5 offset-md-1">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('misure')}</div>
          <div class="spaceBetween">
            <div class="d-flex">
              <DatePicker
                enabledDates={enabledDatesCircumferences}
                on:date={handleCircumferenceDispatch}
                on:deleteCircumferences={handleDeleteCircumferencesDialog}
                defaultDate={circumferenceDateFormat}
                withDeleteButton={circumferencesMeasure.length === 0 ? false : true}
                customerId={customerId}
                item={"circumference"}
                width="100" />
            </div>
            <Button
              color="secondary"
              variant="outlined"
              class={$customer.active ? `` : `action-disabled`}
              disabled={!$customer.active}
              on:click={() => {
                group = 'circumferences';
                elementsDialog = circumferenceType;
                openDialog = true;
                dialogDate = null;
              }}>
              <Label>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing">
                  straighten
                </Icon>
                {$_('nuove_misure')}
              </Label>
            </Button>
          </div>
        </div>
        <div class="row no-gutters">
          <Circumference measure={circumferencesMeasure} />
        </div>
      </div>
    </div>
  </div>
  <hr class="my-4" />
  <CarouselLayout {customerId} />
  <Dialog
    elements={elementsDialog}
    {openDialog}
    {group}
    {customerId}
    defaultDialogDate={dialogDate}
    on:close={handleCloseDialog}
    on:refresh={handleRefreshGraph} />
  <GenericDialog
    bind:this={deleteDialog}
    aria-labelledby="delete-confirmation-dialog"
    aria-describedby="delete-confirmation-dialog"
    class="confirmation">
    <ConfirmationDialog
      on:closeDialog={() => { deleteDialog.close(); }}
      on:onConfirmation={deleteMethod} 
      confirmationButtonLabel="Conferma"
      text={deleteMessage}
    />
  </GenericDialog>
</div>

<style>
  .headline {
    font-size: 1.25rem;
  }
  .mobile {
    display: none;
  }

  .rowSpace {
    padding: 2rem 0rem;
  }

  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.saveBtn) {
    background: #cd293d !important;
    color: #fff !important;
    border: 0 !important;
  }
  * :global(.undoBtn) {
    border: 0 !important;
  }
  * :global(.select--colored) {
    padding: 0 1rem;
    width: fit-content !important;
  }
  * :global(.select--colored .mdc-select__selected-text) {
    color: #cd293d;
    font-weight: bold;
    text-transform: uppercase;
    width: fit-content;
  }
  * :global(.select--colored .mdc-select__dropdown-icon) {
    background: url("assets/expand_more_red.svg") no-repeat center;
  }
  *
    :global(.select--colored.mdc-select--outlined
      .mdc-notched-outline__leading) {
    border-color: #cd293d;
  }
  *
    :global(.select--colored.mdc-select--outlined:not(.mdc-select--disabled)
      .mdc-notched-outline__notch) {
    border-top: 1px solid #cd293d !important;
  }
  *
    :global(.select--colored.mdc-select--outlined:not(.mdc-select--disabled)
      .mdc-notched-outline__trailing) {
    border-color: #cd293d;
  }
  *
    :global(.select--colored.mdc-select--outlined:hover
      .mdc-notched-outline__leading) {
    border-color: #cd293d;
  }
  *
    :global(.select--colored.mdc-select--outlined:not(.mdc-select--disabled):hover
      .mdc-notched-outline__notch) {
    border-color: #cd293d;
  }
  *
    :global(.select--colored.mdc-select--outlined:not(.mdc-select--disabled):hover
      .mdc-notched-outline__trailing) {
    border-color: #cd293d;
  }
  * :global(.mdc-button) {
    height: 40px;
    width: fit-content;
    min-width: fit-content;
    /* margin: 10px 5px 10px; */
    font-size: 0.8rem;
    text-transform: capitalize;
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  * :global(.mdc-text-field) {
    height: 40px;
    width: 150px;
  }
  * :global(.mdc-text-field.goalDescription) {
    height: auto;
  }
  * :global(.mdc-select) {
    height: 40px;
    width: 160px;
  }
  * :global(.demo-select-width) {
    display: flex;
    align-items: center;
  }
  * :global(select), * :global(.demo-select-width .mdc-select--activated) {
    height: auto;
  }
  * :global(.mdc-select__dropdown-icon) {
    right: 0px;
    bottom: 5px;
  }
  * :global(.mdc-select__selected-text) {
    width: 90% !important;
    padding: 0 0.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  * :global(.mdc-select--activated .mdc-select__dropdown-icon) {
    transform: rotate(180deg);
  }
  * :global(.shaped) {
    border-radius: 16px 16px 0 0;
  }
  * :global(.shaped-outlined .mdc-text-field__input) {
    padding-left: 32px;
    padding-right: 0;
  }
  *
    :global(.shaped-outlined
      .mdc-notched-outline
      .mdc-notched-outline__leading) {
    border-radius: 28px 0 0 28px;
    width: 28px;
  }
  *
    :global(.shaped-outlined
      .mdc-notched-outline
      .mdc-notched-outline__trailing) {
    border-radius: 0 28px 28px 0;
  }
  * :global(.shaped-outlined .mdc-notched-outline .mdc-notched-outline__notch) {
    max-width: calc(100% - 28px * 2);
  }
  * :global(.shaped-outlined + .mdc-text-field-helper-line) {
    padding-left: 32px;
    padding-right: 28px;
  }

  * :global(.mdc-text-field--with-trailing-icon .mdc-text-field__icon) {
    bottom: 7px;
    right: 10px;
  }
  .message__container {
    padding: 1rem;
  }

  :global(.select--max-height) {
    max-height: 250px !important;
  }
  .spaceBetween {
    display: flex;
    justify-content: space-between;
  }

  .compositionContainer {
    min-height: 15rem;
    align-content: baseline;
  }

  :global(.action-disabled) {
    color: rgba(0, 0, 0, 0.37) !important;
  }

  * :global(.mdc-text-field--disabled .mdc-text-field__input) {
    color: black !important;
  }

  @media (max-width: 767px) {
    .rowSpace:first-of-type {
      padding-bottom: 1rem;
    }
    .mobile {
      display: block;
    }
    .compositionContainer {
      min-height: unset;
    }
  }
</style>