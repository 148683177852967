<script>
  import { _, locale, setupI18n } from "../../services/i18n";
  import { checkEmail } from "./validation/authValidation";
  import { hsLogin } from "../../services/hubspot";
  import { Icon } from "@smui/common";
  import { jwt, oneSignalPlayerId, user } from "../../lib/store";
  import { login, addScriptToHead } from "../../services/authServices";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import { updateOneSignalPlayerId } from "../../services/teamServices";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Actions } from "@smui/card";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import FormMessage from "../ReusableLayouts/FormMessage.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";
  import LoadingInline from "../ReusableLayouts/LoadingInline.svelte";
  import Textfield from "@smui/textfield";

  let email = "";
  let emailInvalid = false;
  let formValid = false;
  let language = $locale;
  let loading = false;
  let msgError;
  let password = "";
  let rememberToken = false;
  let showPassword = false;

  $: formValid = password && checkEmail(email) && !emailInvalid;
  $: if (email) emailInvalid = !checkEmail(email);

  const submit = async () => {
    try {
      loading = true;
      const res = await login({ email, password, rememberToken, language });
      try {
        if ($oneSignalPlayerId != null) {
          await updateOneSignalPlayerId($oneSignalPlayerId, res.id);
        }
      } catch (e) { 
        console.log(e); 
      }
      setupI18n({ withLocale: res.language });
      loading = false;
      navigate("/team");
    } catch (error) {
      console.log("login err", error);
      if (error.message.error == "Unauthorized | Not registered email") {
        msgError = $_("email_non_registrata");
      } else if (error.message.error == "Unauthorized | Not a PT") {
        msgError = $_("not_a_pt");
      } else {
        msgError = $_("password_non_corretta");
      }
      loading = false;
      setTimeout(() => {
        msgError = "";
      }, 5000);
    }
  };

  onMount(() => {
    if ($jwt) {
      hsLogin($user.email);
      navigate("/team");
    }

    try {
      //Tag Manager Event
      window.dataLayer = window.dataLayer || []; 
      dataLayer.push({'event': 'login'}); 
      console.log('Login');
    } catch (err) {
      console.error(err);
    }
  });
</script>

<style>
  * :global(.cardClass) {
    border-radius: 2px;
    margin: 0 auto;
    width: 25rem;
    text-align: center;
  }
  * :global(.mdc-button__label) {
    text-transform: inherit;
  }
  .cardImg {
    height: 2.5rem;
  }
  * :global(.textWrapper) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    opacity: 0.8;
  }
  .label {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1rem;
    margin-left: 0.7rem;
    opacity: 0.7;
  }
  * :global(.mdc-text-field) {
    height: 2.8125rem;
  }
  * :global(.mdc-text-field--outlined .mdc-text-field__input) {
    padding: 1rem 0.7rem;
  }
  *
    :global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__input) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
  }

  * :global(.alignLeft) {
    text-align: left;
  }
  * :global(.mdc-text-field__icon.mdc-text-field__icon--trailing) {
    top: 0.7rem;
  }
</style>

<form
  name="signin"
  on:submit|preventDefault={submit}
  on:change={formValid}
  on:input={formValid}>
  <Card class="borderNone cardClass" variant="outlined" padded>
    <div class="col-xs-6 col-xs-offset-3 py-4">
      <img src="/assets/revoologo.svg" alt="" class="cardImg" />
    </div>
    <Content class="col-12 py-2 textWrapper">
      <div>{$_('bentornato')}</div>
      <div>{$_('accedi_per_gestire_i_tuoi_clienti')}</div>
    </Content>
    <Content class="alignLeft">
      <div class="form-group">
        <span class="label">{$_('email')}</span>
        <Textfield
          variant="outlined"
          class="textbox w100"
          updateInvalid
          bind:value={email}
          type="email"
          input$autocomplete="email"
          bind:invalid={emailInvalid} />
        {#if emailInvalid}
          <FormMessage textAlign="right">
            {email ? $_('email_errata') : $_('l_email_è_obbligatoria')}
          </FormMessage>
        {/if}
      </div>
      <div class="form-group">
        <span class="label">{$_('password')}</span>
        <Textfield
          class="w100"
          variant="outlined"
          bind:value={password}
          withTrailingIcon
          type={showPassword ? 'text' : 'password'}
          input$autocomplete="current-password">
          <Icon
            on:click={() => (showPassword = !showPassword)}
            role="button"
            tabindex="1"
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing">
            {showPassword ? 'visibility_off' : 'visibility'}
          </Icon>
        </Textfield>
        <div style="text-align:right; margin-top: 0.5rem; font-size: smaller">
          <span
            class="cursorPointer"
            style="color: var(--primary);"
            on:click={() => navigate('/password_recover')}>
            {$_('recupera_password')}
          </span>
        </div>
      </div>
      <MsgError {msgError} />
    </Content>
    <Actions>
      <div class="col-xs-8 col-md-7" style="padding-left:0px;text-align: left;">
        <FormField style="margin-left=0px !important">
          <Checkbox bind:checked={rememberToken} />
          <span class="textWrapper" slot="label">{$_('ricordami')}</span>
        </FormField>
      </div>
      <div class="col-xs-4 col-md-5" style="padding-right:6px">
        <Button
          style="max-width:140px;width:inherit "
          class="btn btn--form"
          variant="unelevated"
          type="submit"
          disabled={!formValid || loading}>
          <Label>
            <span>{$_('login')}</span>
            {#if loading}
              <LoadingInline />
            {/if}
          </Label>
        </Button>
      </div>
    </Actions>
  </Card>
</form>
