<script>
	import { _ } from "../../../../services/i18n";
	import moment from "moment";

	export let customerSubscription;

	const getExpiration = (expirationDays) => {
		if (expirationDays == 0) {
			return $_("scaduto");
		} else if (expirationDays == "-") { 
			return $_("nessuna_scadenza");
		} else { 
			return expirationDays[0] == '-' ? `${$_("scaduto")} (${expirationDays.slice(1)})` : `${$_("scadenza")} - ${expirationDays} `;
		};
	};

	const getDaysTilExpiration = (start, end) => {
		const diff = moment(end).diff(moment(Date.now()), "days");
		if (diff == 0) return "oggi";
		return isNaN(diff)
			? "-"
			: $_("giorni", { values: { giorni: diff } });
	};

	const getProgressPercentage = (start, end) => {
		try {
			if (!start || !end) return 0; 
			const starting = new Date(start);
			const ending   = new Date(end);
			const today    = new Date();
			return (
				Math.round(((today - starting) / (ending - starting)) * 100) / 100
			);
		} catch (err) {
			console.log(err);
		}
	};

	const getColor = (end) => {
		const diff = moment(end).diff(moment(Date.now()), "days");
		if (diff > 10 || !end) return "#0CD2C8";
		else if (diff <= 10 && diff > 5) return "#FFCB00";
		else if (diff <= 5 && diff > 0) return "#FF7000";
		else return "#e9ecef";
	};
</script>

<style>
	* :global(.progress) {
		height: 0.3em;
	}
	.label {
		opacity: 0.5;
		font-size: 0.75rem;
	}
</style>


<div class="col-12 px-0 px-lg-3 text-center label">
	{#if customerSubscription.start && customerSubscription.end}
		{getExpiration(getDaysTilExpiration(customerSubscription.start, customerSubscription.end))}
	{:else}
		{$_("nessuna_scadenza")}
	{/if}
</div>
<div class="col-12 px-0 px-lg-3 mt-2 text-left">
	<div class="progress">
		<div 
			class="progress-bar"
			role="progressbar"
			style={`width: ${customerSubscription.start && customerSubscription.end 
								? getProgressPercentage(customerSubscription.start, customerSubscription.end) * 100
								: 0}%; 
							background-color: ${customerSubscription.start && customerSubscription.end
								? getColor(customerSubscription.end)
								: 0};`
						}
			aria-valuenow="{getProgressPercentage(customerSubscription.start, customerSubscription.end) * 100}}"
			aria-valuemin="0"
			aria-valuemax="100" />
	</div>
</div>
