<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import { _ } from "../../../../services/i18n";
  import Card from "@smui/card";
  import CropDialog from "../../../Auth/FlowRegistration/CropDialog.svelte";
  import Dialog from "@smui/dialog";
  import LogoUploader from "../../../PtInfo/LogoUploader.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import ProfileImageUploader from "../../../Auth/FlowRegistration/ProfileImageUploader.svelte";

  export let profileImage = "";
  export let logo = "";
  export let hasLogo = false;

  let imageToBeSaved;
  let imageCrop = "";
  let croppedSquare = true;
  let imageDialog = false;
  let msgError;

  const closeHandler = () => {
    if (!croppedSquare) {
      msgError = $_("immagine_quadrata");
      dispatch("imageValidityChange", { valid: false });
      setTimeout(() => {
        msgError = "";
      }, 3000);
    }
  };

  const handleImageAdded = (e) => {
    croppedSquare = false;
    profileImage = e.detail.img;
    imageCrop = URL.createObjectURL(e.detail.img);
    imageDialog.open();
  };

  const handleLogoAdded = (e) => {
    croppedSquare = false;
    logo = e.detail.img;
    imageCrop = URL.createObjectURL(e.detail.img);
    imageDialog.open();
  };

  const handleImageError = (e) => {
    msgError = $_("la_dimensione_massima_consentita_è_di", {
      values: {
        dimension: 5,
      },
    });
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const updateImage = (e) => {
    msgError = "";
    croppedSquare = true;
    const newImage = URL.createObjectURL(e.detail.image);
    dispatch("imageValidityChange", { valid: true, image: e.detail.image });
    imageToBeSaved = e.detail.image;
    profileImage = newImage;
    const img = document.getElementById("profileImg");
    img.src = newImage;
  };
</script>

<Dialog
  on:MDCDialog:closed={closeHandler}
  bind:this={imageDialog}
  aria-labelledby="dialog-for-cropping-image"
  aria-describedby="image-cropper">
  <CropDialog
    {imageCrop}
    squared={true}
    mustCrop={true}
    on:closeDialog={() => imageDialog.close()}
    on:imageHasBeenCropped={updateImage} />
</Dialog>

<Card variant="outlined" class="customCard uploaderCard">
  {#if hasLogo}
    <LogoUploader
      {logo}
      on:updateLogo={handleLogoAdded}
      on:error={handleImageError} />
  {:else}
    <ProfileImageUploader
      {profileImage}
      on:updateImageProfile={handleImageAdded}
      on:error={handleImageError} />
  {/if}
  <MsgError {msgError} />
</Card>
