<script>
  import { _ } from "../../../services/i18n";
  import Card, { Content, Actions } from "@smui/card";
  import Button, { Label } from "@smui/button";
  import Textfield from "@smui/textfield";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import MsgError from "../../ReusableLayouts/MsgError.svelte";
  import PostPasswordRecoverForm from "./PostPasswordRecoverForm.svelte";
  import { checkEmail } from "../validation/authValidation";
  import { recoverPassword } from "../../../services/authServices";

  let msgError = "";

  let recovered = false;
  let email = "";
  let formValid = false;
  let emailInvalid = false;

  $: formValid = checkEmail(email) && !emailInvalid;
  $: if (email) {
    emailInvalid = !checkEmail(email);
  }

  const askRecoverPassword = async () => {
    try {
      formValid = true;
      await recoverPassword({ email });
      formValid = false;
      recovered = true;
    } catch (error) {
      msgError = $_("si_è_verificato_un_errore");
      displayError();
      console.log(error);
      recovered = false;
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };
</script>

<style>
  * :global(.mdc-button) {
    height: 2.5rem;
  }
  * :global(.cardClass) {
    border-radius: 2px;
    height: 31.25rem;
    margin: 0 auto;
    text-align: center;
    width: 25rem;
  }
  * :global(.textUppercase) {
    text-transform: uppercase;
  }
  * :global(.textWrapper) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    opacity: 0.8;
  }
  .label {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1rem;
    margin-left: 0.5rem;
    opacity: 0.7;
  }
  * :global(.mdc-text-field) {
    height: 2.8125rem;
  }
  * :global(.mdc-text-field--outlined .mdc-text-field__input) {
    padding: 1rem 0.7rem;
  }
  *
    :global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__input) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
  }
  * :global(.alignLeft) {
    text-align: left;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
</style>

<MsgError {msgError} />

{#if !recovered}
  <form
    name="signin"
    on:submit|preventDefault={askRecoverPassword}
    on:change={formValid}
    on:input={formValid}>
    <Card class="borderNone cardClass" variant="outlined" padded>
      <div class="col-xs-6 col-xs-offset-3 pt-4 pb-2">
        <img
          src="/assets/revoologo.svg"
          alt=""
          style="height:2.5rem" />
      </div>
      <Content>
        <div class="col-12 pt-2 pb-2 textWrapper">
          {$_('inserisci_email_registrazione')}
        </div>
        <div class="form-group mt-4 alignLeft">
          <span class="label">{$_('email')}</span>
          <Textfield
            variant="outlined"
            class={`textbox w100 mb-2 ${emailInvalid ? 'mdc-text-field--invalid' : ''}`}
            bind:value={email}
            type="email"
            input$autocomplete="email"
            bind:invalid={emailInvalid} />
          {#if emailInvalid}
            <FormMessage textAlign="right">
              {email ? $_('email_errata') : $_('l_email_è_obbligatoria')}
            </FormMessage>
          {/if}
        </div>
      </Content>
      <Actions style="justify-content:flex-end" class="mr-2">
        <Button
          color="primary"
          class="btn btn--form"
          variant="unelevated"
          type="submit"
          disabled={!formValid}>
          <Label class="textUppercase">{$_('recupera_password')}</Label>
        </Button>
      </Actions>
    </Card>
  </form>
{:else}
  <PostPasswordRecoverForm />
{/if}
