import { POST, GET, PATCH, PUT } from "../lib/http";
import { vodTags } from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: ["*"],
  ["Access-Control-Allow-Methods"]: ["*"],
  ["Accept"]: "application/json",
};

export const maxPage = 5000;

export const getVods = async (per_page, page, name, orderBy = null, ascOrder, updateStoreValue = true) => {
  try {
    const queryObj = {
      per_page,
      page,
    };
    if (name) queryObj.name = name;
    queryObj.order_by = orderBy;
    ascOrder ? null : (queryObj.order_by_dir = "DESC");
    const res = await GET("/v2/get_vods", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    if (updateStoreValue) {
      // vods.set(res.data);
      // vodsTotalPages.set(res.last_page);
    }
    return res;
  } catch (error) {
    console.log("getVods err", error);
    throw error;
  }
};

export const getVodsAndPlaylistsQuantity = async () => {
  try {
    const res = await GET("/v2/get_vods_and_playlists_quantity", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    console.log("getVodsAndPlaylistsQuantity err", error);
    throw error;
  }
}

export const getTotalViews = async () => {
  try {
    const res = await GET("/v2/get_total_views", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    console.log("getTotalViews err", error);
    throw error;
  }
}

export const getTotalViewsPerBillingCycle = async () => {
  try {
    const res = await GET("/v2/get_total_views_per_billing_cycle", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    console.log("getTotalViewsPerBillingCycle err", error);
    throw error;
  }
}

export const deleteVod = async (id) => {
  const res = await POST("/v2/delete_vod", {
    headers: { ...commonHeaders },
    body: {
      id,
    },
  });
  return res;
};

export const saveVodInfo = async (body) => {
  const endpoint = "/v2/store_vod_info";
  const data = {
    headers: { ...commonHeaders },
    body,
  };

  // Default
  body.method = "POST";

  if (body.id) {
    body.method = body.new_video === true
      ? "PUT" 
      : "PATCH";
  } 

  const res = await POST(endpoint, data, true, "multipart/form-data");
  return res;
}

// export const editVodInfo = async (body) => {
//   body.method = "PUT";
//   const res = await POST(
//     "/v2/store_vod_info",
//     {
//       headers: { ...commonHeaders },
//       body,
//     },
//     true,
//     "multipart/form-data"
//   );
//   return res;
// }

export const handleVodAfterUpload = async (body) => {
  const res = await POST(
    "/v2/handle_vod_after_upload",
    {
      headers: { ...commonHeaders },
      body,
    },
    "multipart/form-data"
  );
  return res;
}

export const saveVod = async (body) => {
  const res = await POST(
    "/v2/store_vod",
    {
      headers: { ...commonHeaders },
      body,
    },
    true,
    "multipart/form-data"
  );
  return res;
};


// Playlists

export const createPlaylist = async (playlist) => {
  const res = await POST(
    "/v2/create_playlist",
    {
      body: playlist,
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const editPlaylist = async (playlist) => {
  const res = await POST(
    "/v2/update_playlist",
    {
      body: playlist,
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const getPlaylists = async (
  per_page,
  page,
  name,
  order_by,
  order_by_dir
) => {
  try {
    const queryObj = {
      per_page: per_page,
      page: page,
    };
    name ? (queryObj.name = name) : null;
    queryObj.order_by = order_by ? order_by : "name";
    order_by_dir ? (queryObj.order_by_dir = "DESC") : null;

    const res = await GET("/v2/get_playlists", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    const { data, last_page } = res;
    return { data, last_page };
  } catch (error) {
    console.log("getPlaylists err", error);
    throw error;
  }
};

export const getPlaylistEmails = async (playlistIds) => {
  try {
    const queryObj = {
      playlist_ids: playlistIds,
    };

    const res = await GET("/v2/get_playlist_emails", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getPlaylistEmails err", error);
    throw error;
  }
}

export const assignVodToPlaylist = async (vod, playlistToBeAssigned) => {
  const res = await POST(
    "/v2/update_playlist_assignments",
    {
      body: {
        vod_id: vod.id, 
        playlist_id: playlistToBeAssigned.playlist_id,
        option: 'attach',
      },
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const updatePlaylistRank = async (assignedVods, playlist) => {
  let data = JSON.stringify(assignedVods);
  const res = await POST(
    `/v2/update_playlist_rank`,
    {
      body: {
        assigned_vods: data,
        playlist_id: playlist.playlist_id,
      },
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const removeVodFromPlaylist = async (vod, playlistToBeRemoved) => {
  const res = await POST(
    "/v2/update_playlist_assignments",
    {
      body: {
        vod_id: vod.id, 
        playlist_id: playlistToBeRemoved.playlist_id,
        option: 'detach',
      },
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const getVodsPlaylistRelations = async (
  playlist,
  filter,
  name,
  order_by,
  order_by_dir
) => {
  try {
    let queryObj = {};
    name ? (queryObj.name = name) : null;
    queryObj.order_by = order_by ?? "name";
    queryObj.playlist_id = playlist.playlist_id ?? null;
    queryObj.filter = filter ?? "all";
    order_by_dir ? (queryObj.order_by_dir = "DESC") : '';
    const res = await GET("/v2/get_vods_playlist_relations", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getVodsPlaylistRelations err", error);
    throw error;
  }
};

export const getVodsAndPlaylists = async (name) => {
  try {
    let queryObj = {};
    name ? (queryObj.name = name) : null;
    const res = await GET("/v2/get_vods_and_playlists", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getVodsAndPlaylists err", error);
    throw error;
  }
};

export const deletePlaylist = async (id) => {
  const res = await POST(`/v2/delete_playlist/${id}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const saveVodAssignment = async (assignment) => {
  const endpoint = "/v2/store_vod_assignment";
  const data = {
    headers: { ...commonHeaders },
    body: {
      vod_assignment_id      : assignment.vod_assignment_id ?? null,
      playlist_assignment_id : assignment.playlist_assignment_id ?? null, // If existing previous assignment as PLAYLIST
      customer_id            : assignment.customer_id,
      group_id               : assignment.group_id,
      note                   : assignment.note,
      start                  : assignment.start,
      vod_id                 : assignment.vod_id,
      weeks                  : assignment.weeks ?? '',
      method                 : "POST",
    },
  }
  const res = await POST(endpoint, data, true, "multipart/form-data");
  return res;
}

export const savePlaylistAssignment = async (assignment) => {
  const res = await POST(
    "/v2/store_playlist_assignment",
    {
      body: {
        playlist_assignment_id : assignment.playlist_assignment_id ?? null,
        vod_assignment_id      : assignment.vod_assignment_id ?? null, // If existing previous assignment as single VOD
        customer_id            : assignment.customer_id,
        group_id               : assignment.group_id,
        note                   : assignment.note,
        playlist_id            : assignment.playlist_id,
        start                  : assignment.start,
        weeks                  : assignment.weeks ?? '',
      },
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
}


export const deletePlaylistAssignment = async (id) => {
	try {
		const res = await POST(`/v2/delete_playlist_assignment`, {
			headers: { ...commonHeaders },
      body: {
        id,
      },
		});
		return res;
	} catch (err) {
		throw err;
	}
};

export const deleteVodAssignment = async (id) => {
	try {
		const res = await POST(`/v2/delete_vod_assignment`, {
			headers: { ...commonHeaders },
      body: {
        id,
      },
		});
		return res;
	} catch (err) {
		throw err;
	}
};

export const getVodDisclaimerStatus = async () => {
  try {
    const res = await GET(`/v2/get_vod_disclaimer_status`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    console.log("getVodDisclaimerStatus err", err);
  };
};

export const setVodDisclaimerStatus = async () => {
  try {
    const res = await POST(`/v2/set_vod_disclaimer_status`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    console.log("setVodDisclaimerStatus err", err)
  };
};

export const getVodTags = async () => {
  GET("/v2/tags/vod")
    .then((res) => {
      vodTags.set(res);
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteVodTag = async (id) => {
  const res = await POST("/destroy_tag", {
    headers: { ...commonHeaders },
    body: {
      id,
    },
  });
  return res;
};

export const createVodTag = async (tag) => {
  const res = await POST("/v2/tags/vod", {
    headers: { ...commonHeaders },
    body: {
      name: tag,
    },
  });
  return res;
};