<script>
  import { _ } from "../../../services/i18n";
  import { allCombinations, progressionsPage, workouts } from "../../../lib/store";
  import { createEventDispatcher, tick, onMount } from "svelte";
  import { generateUID, reassignCombinationSortIds, removeCombinationIds } from "../../../lib/utils";
  import AddNewCombination from "./AddNewCombination.svelte";
  import Card from "@smui/card";
  import Combination from "./Combination.svelte";
  import Dialog from "@smui/dialog";
  import ExercisesDialog from "./ExercisesDialog.svelte";
  import IconButton from "@smui/icon-button";
  import SortableList from "@palsch/svelte-sortablejs";
  import SvelteTooltip from 'svelte-tooltip';
  import WorkoutHeader from "./WorkoutHeader.svelte";
  
  export let isProgression;
  export let workout = {};
  
  const dispatch = createEventDispatcher();

  // ExercisesDialog
  let dialogSelectedExercise = false;
  let exercisesDialog = true;
  let exercisesDialogClicked = false;
  let exercisesDialogCombination = 'normal';
  let exercisesDialogComponent = '';
  let exerciseToBeChanged = {};
  let isMobile = false;

  $: isMobile = document.documentElement.clientWidth < 768 && document.documentElement.clientHeight < 768;

  const handleCombinationSelected = (e) => {
    const { exercise, combination } = e.detail;
    const workoutsModified = $workouts.map((w) => {
      if (w.sortId != workout.sortId) return w;
      const workoutWithNewCombination = { ...w };
      workoutWithNewCombination.combinations.push({
        method: combination,
        supersets: "",
        sortId: generateUID(),
        exercises: [
          {
            name: exercise.name,
            note: "",
            id: exercise.id,
            sortId: generateUID(),
            details: [
              {
                sets: "",
                reps: {
                  format: "standard",
                  type: combination == "time_circuit" ? "time" : "reps",
                  value_high: "",
                  value: "",
                },
                weight: {
                  format: "standard",
                  type: "kg",
                  value_high: "",
                  value: "",
                },
                rest: "",
                tut: "",
              },
            ],
          },
        ],
      });
      return workoutWithNewCombination;
    });

    $workouts = workoutsModified;
  };

  const changeExerciseAfterSelectedFromDialog = (e) => {
    const { exercise, combination } = e.detail;
    $workouts = $workouts.map((w) => {
      if (w.sortId == workout.sortId) {
        w.combinations.map((c) => {
          if (c.sortId == combination.sortId) {
            c.exercises.map((ex) => {
              if (exerciseToBeChanged && 
                  exerciseToBeChanged.sortId && 
                  ex.sortId == exerciseToBeChanged.sortId) {
                ex.name = exercise.name;
                ex.id = exercise.id;
              }
            });
          }
        });
      }
      return w;
    });
    // reset exerciseToBeChanged
    exerciseToBeChanged = {};
  };

  const cloneCombination = (item) => {
    const newComb = removeCombinationIds(item);
    $workouts = $workouts.map((w) => {
      if (w.sortId == workout.sortId) {
        w.combinations = [
          ...w.combinations,
          reassignCombinationSortIds({ ...newComb }),
        ];
      }
      return w;
    });
  };

  const removeCombination = (item) => {
    $workouts = $workouts.map((w) => {
      if (w.sortId == workout.sortId) {
        w.combinations = workout.combinations.filter(
          (i) => i.sortId != item.sortId
        );
      }
      return w;
    });
  };

  const sortableOptions = {
    group: "combinations",
    animation: 150,
    handle: ".combinations-drag-handle",
  };

  function getItemById(id) {
    return $allCombinations.find((item) => item.sortId == id);
  }

  const handleCheckAndUpdateDuplicates = (e) => {
    dispatch('checkAndUpdateDuplicates', {
      exerciseId: e.detail.exerciseId, 
      newExerciseName: e.detail.newExerciseName, 
    });
  }

  const handleDirtyExercise = (e) => {
    dispatch("dirtyExercise", { dirtyExercise: e.detail.dirtyExercise });
  };

  const handleOpenExercisesDialog = async (e) => {
    const { component, combination, exercise = {}} = e.detail;
    if (exercise) {
      exerciseToBeChanged = exercise;
    }
    exercisesDialogCombination = combination;
    exercisesDialogComponent = component;
    exercisesDialogClicked = true;
    await tick();
    exercisesDialog.open();
  }

  const handleExercisesDialogClose = () => {
    exercisesDialogClicked = false;
    exercisesDialog.close();
  };

  const handleAddSelectedExercise = (e) => {
    exercisesDialog.close();
    e.detail.combination = exercisesDialogCombination;
    e.detail.exercise = e.detail.ex;
    delete e.detail.ex;
    switch (exercisesDialogComponent) {
      case 'AddNewCombination' : 
        handleCombinationSelected(e);             
        break;
      case 'Combination' : 
        dialogSelectedExercise = e;
        break;
      case 'Exercise' : 
        changeExerciseAfterSelectedFromDialog(e); 
        break;
    }
  };

  const switchClass = (originClass, newClass) => {
    document.querySelectorAll(`.${originClass}`).forEach((elem) => {
      elem.classList.add(newClass);
      elem.classList.remove(originClass);
    });
  };
  
  const disableElement = (className, disabled) => {
    document.querySelectorAll(`.${className}`).forEach((elem) => {
      elem.disabled = disabled;
    });
  }

  const disableElementCustom = (classNames, disabled) => {
    document.querySelectorAll(`.${classNames[0]} .${classNames[1]}`).forEach((elem) => {
      elem.disabled = disabled;
    });
  }

  const removeElement = (className) => {
    document.querySelectorAll(`.${className}`).forEach((elem) => {
      elem.remove();
    });
  }
  
  onMount(() => {
    if (isProgression !== false) {
      // Drag & drop disabilitato nelle progressioni
      switchClass("exercises-drag-handle", "exercises-drag-handle-disabled");
      switchClass("combinations-drag-handle","combinations-drag-handle-disabled");
      switchClass("workouts-drag-handle", "workouts-drag-handle-disabled");
      // Duplicazioni e input testuali disabilitati nelle progressioni
      disableElementCustom(['combination-select', 'mdc-select__native-control'], true);
      disableElement('add-new-exercise__input', true);
      disableElement("combinationDelete", true);
      // Azioni rimosse nelle progressioni
      removeElement('trainingCardDuplicate');
      removeElement('workoutDelete');
      removeElement('exerciseDelete');
      removeElement('addPyramidSet');
      removeElement('searchIcon');
      // Nasconde elimina combinazione
      switchClass("combinationDelete", "combinationDelete--hidden");
    }
    if (isProgression && $progressionsPage == 1) {
      disableElement('mdc-select__native-control', true);
    }
  });
</script>

<style>
  * :global(.primary) {
    color: var(--primary);
  }
  .combinationsLayout {
    background-color: #f9f9f9;
    padding: 16px;
  }
  * :global(.combinationsLayout > ul) {
    padding: 0;
  }

  * :global(.first-progression ~ .tooltip-wrapper) {
    width: 100%;
  }

  /* Drag & drop nascosto */
  * :global(.exercises-drag-handle-disabled),
  * :global(.combinations-drag-handle-disabled),
  * :global(.workouts-drag-handle-disabled) {
    color: #fff;
  }

  * :global(.exercises-drag-handle-disabled button:hover),
  * :global(.combinations-drag-handle-disabled button:hover),
  * :global(.workouts-drag-handle-disabled button:hover),
  * :global(.exercises-drag-handle-disabled button:active),
  * :global(.combinations-drag-handle-disabled button:active),
  * :global(.workouts-drag-handle-disabled button:active),
  * :global(.exercises-drag-handle-disabled button:focus),
  * :global(.combinations-drag-handle-disabled button:focus),
  * :global(.workouts-drag-handle-disabled button:focus),
  * :global(.combinationDelete--hidden) {
    opacity: 0;
  }

  * :global(.nameWrapper.nameWrapper--progression > button) {
    opacity: 0;
  }

  * :global(.combinations .tooltip),
  * :global(.combinations .tooltip > div) {
    background-color: #FFCB00 !important;
    color: black;
    width: 100%;
  }

  * :global(.combinations .tooltip) {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }
</style>

{#if exercisesDialogClicked}
  <Dialog class="dialog--medium" bind:this={exercisesDialog}>
    <ExercisesDialog
      clicked={exercisesDialogClicked}
      on:closeDialog={handleExercisesDialogClose}
      on:checkAndUpdateDuplicates={handleCheckAndUpdateDuplicates}
      on:addSelectedExercise={handleAddSelectedExercise} />
  </Dialog>
{/if}

<li class="mb-2">
  <Card>
    <WorkoutHeader {workout} />
    <div class="combinationsLayout">
      <SortableList
        {sortableOptions}
        bind:items={workout.combinations}
        {getItemById}
        idKey="sortId"
        liClass="row"
        let:item>
        <span class="col-12">
          <div class="row combinations">
            <div class="col-12 flexy px-0">
              <div class="combinations-drag-handle">
                <IconButton class="material-icons">drag_indicator</IconButton>
              </div>
              {#if $progressionsPage == 1 && isProgression}
                <span class="d-none first-progression"></span>
                <SvelteTooltip tip="{$_('parametri_modificabili_da_sezione_schede')}" top >
                  <Combination
                    combination={item}
                    bind:dialogSelectedExercise
                    bind:isProgression
                    on:openExercisesDialog={handleOpenExercisesDialog}
                    on:dirtyExercise={handleDirtyExercise}
                    on:combinationSelected={handleCombinationSelected}
                    on:checkAndUpdateDuplicates={handleCheckAndUpdateDuplicates}
                    on:deleteCombination={(e) => { 
                      handleDirtyExercise(e);
                      removeCombination(e.detail.combination); 
                    }} />
                </SvelteTooltip>
              {:else}
                <Combination
                  combination={item}
                  bind:dialogSelectedExercise
                  bind:isProgression
                  {isMobile}
                  on:openExercisesDialog={handleOpenExercisesDialog}
                  on:dirtyExercise={handleDirtyExercise}
                  on:combinationSelected={handleCombinationSelected}
                  on:checkAndUpdateDuplicates={handleCheckAndUpdateDuplicates}
                  on:deleteCombination={(e) => { 
                    handleDirtyExercise(e);
                    removeCombination(e.detail.combination); 
                  }} />
              {/if}
              <div>
                <IconButton
                  on:click={() => cloneCombination(item)}
                  class="material-icons trainingCardDuplicate">
                  content_copy
                </IconButton>
                <IconButton
                  on:click={(e) => {
                    handleDirtyExercise(e);
                    removeCombination(item)}
                  }
                  class="material-icons primary combinationDelete">
                  delete_outline
                </IconButton>
              </div>
            </div>
          </div>
        </span>
      </SortableList>
      {#if isProgression === false}
        <AddNewCombination 
          on:openExercisesDialog={handleOpenExercisesDialog}
          on:combinationSelected={handleCombinationSelected} 
          on:checkAndUpdateDuplicates={handleCheckAndUpdateDuplicates} />
      {/if}
    </div>
  </Card>
</li>
