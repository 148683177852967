<script>
  import { _ } from "../../../../services/i18n";
  import { checkEmail } from "../../TeamValidation/teamValidation";
  import { createEventDispatcher } from "svelte";
  import { getContactFunnels, getTypes, getGenderItems } from "../../../../lib/utils.js";
  import { isFeatureEnabled } from "../../../../services/subscriptionServices";
  import { navigate } from "svelte-routing";
  import { user, subscription } from "../../../../lib/store";
  import Button, { Label } from "@smui/button";
  import CC from "../../../../lib/countryCode";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import LoadingInline from "../../../ReusableLayouts/LoadingInline.svelte";
  import Select, { Option } from "@smui/select";
  import Switch from "@smui/switch";
  import Textfield, { Input } from "@smui/textfield";  
  
  export let imageValid;
  export let saving = false;

  const dispatch = createEventDispatcher();

  let chatEnabledForCustomer = false;
  let contactFunnel = "";
  let contactFunnels = getContactFunnels($_);
  let countries_code = [];
  let country = "IT";
  let email = "";
  let emailInvalid = false;
  let gender = "male";
  let genderItems = getGenderItems($_);
  let name = "";
  let nameInvalid = false;
  let surname = "";
  let surnameInvalid = false;
  let type = "";
  let types = getTypes($_);

  for (const [key, value] of Object.entries(CC)) {
    countries_code.push(value);
  }

  $: formValid =
    name.length > 2 &&
    surname.length > 1 &&
    imageValid &&
    email &&
    !emailInvalid;
  $: if (name) {
    nameInvalid = name.length < 3;
  }
  $: if (surname) {
    surnameInvalid = surname.length < 2;
  }
  $: if (email) {
    emailInvalid = !checkEmail(email);
  }

  $: if (!isFeatureEnabled('customers', $subscription)) {
      dispatch('showSubscriptionDialog');
    }

  const setInitialValues = () => {
    name = "";
    surname = "";
    email = "";
    gender = "male";
    country = "IT";
    navigate("/team");
  };

  const onSubmit = () => {
    if (!isFeatureEnabled('customers', $subscription)) {
      console.log('submit blocked');
      dispatch('showSubscriptionDialog');
      return;
    }

    dispatch("createCustomer", {
      customer: {
        name,
        surname,
        gender,
        email,
        country,
        contact_funnel: contactFunnel,
        type,
        has_chat: chatEnabledForCustomer,
      },
    });
  };
</script>

<div class="py-3">
  <div class="row">
    <div class="col-4 label">{`${$_('nome')} *`}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class={`textbox w100 my-2 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
        bind:value={name}
        type="text" />
      {#if nameInvalid}
        <FormMessage>
          {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
        </FormMessage>
      {/if}
    </div>
    <div class="col-4 label">{`${$_('cognome')} *`}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class={`textbox w100 my-2 ${surnameInvalid ? 'mdc-text-field--invalid' : ''}`}
        bind:invalid={surnameInvalid}
        bind:value={surname}
        type="text" />
      {#if surnameInvalid}
        <FormMessage>
          {$_('il_cognome_deve_essere_di_almeno_3_caratteri')}
        </FormMessage>
      {/if}
    </div>
    <div class="col-4 label">{`${$_('sesso')} *`}</div>
    <div class="col-8">
      <Select
        enhanced
        variant="outlined"
        class="w50 mb-2"
        label=""
        bind:value={gender}>
        {#each genderItems as { value, text }}
          <Option {value} selected={gender === value}>{text}</Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label">{`${$_('email')} *`}</div>
    <div class="col-8">
      <Textfield
        bind:value={email}
        variant="outlined"
        type="text"
        bind:invalid={emailInvalid}
        class={`textbox w100 my-2 ${emailInvalid ? 'mdc-text-field--invalid' : ''}`} />
      {#if emailInvalid}
        <FormMessage>{$_('email_non_valida')}</FormMessage>
      {/if}
    </div>
    <div class="col-4 label">{`${$_('paese')} *`}</div>
    <div class="col-8">
      <Select
        enhanced
        variant="outlined"
        label=""
        class="w50 mb-2"
        bind:value={country}>
        {#each countries_code as item}
          <Option value={item.iso2} selected={country === item.iso2}>
            {item.name}
          </Option>
        {/each}
      </Select>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-4 label">{$_('tipo_di_cliente')}</div>
    <div class="col-8">
      <Select
        enhanced
        variant="outlined"
        class="w100 mb-2"
        label=""
        bind:value={type}>
        {#each types as { value, label }}
          <Option {value} selected={type === value}>{label}</Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label">{$_('come_lo_hai_conosciuto')}</div>
    <div class="col-8">
      <Select
        enhanced
        variant="outlined"
        class="w100 mb-2"
        label=""
        bind:value={contactFunnel}>
        {#each contactFunnels as { value, label }}
          <Option {value} selected={contactFunnel === value}>{label}</Option>
        {/each}
      </Select>
    </div>
  </div>

  <hr />

  {#if $user.chat_enabled}
    <div class="row">
      <div class="col-4 label">{$_('attiva_chat_per_questo_cliente')}</div>
      <div class="col-8">
        <Switch bind:checked={chatEnabledForCustomer} on:click={() => { chatEnabledForCustomer = !chatEnabledForCustomer}} />
      </div>
    </div>
    <hr />
  {/if}

  <div class="row justify-content-center justify-content-md-end">
    <Button class="btn" on:click={setInitialValues}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-5"
      variant="unelevated"
      on:click={onSubmit}
      disabled={!formValid || saving}>
      <Label>
        <span>
          {$_('salva_e_invita_cliente')}
        </span>
        {#if saving}
          <LoadingInline />
        {/if}
      </Label>
    </Button>
  </div>

</div>

<style>
  .label {
    text-align: end;
    align-self: center;
    font-weight: bold;
    opacity: 0.8;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
</style>