<script>
  import { createEventDispatcher, onMount } from "svelte";
  import VirtualList from "@sveltejs/svelte-virtual-list";
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Media } from "@smui/card";
  import { Icon } from "@smui/common";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";
  import PlaylistLabel from "../ReusableLayouts/PlaylistLabel.svelte";
  
  export let items = [];
  export let itemToBeAssigned;
  export let loading = true;
  export let msgError;
  export let text;
  export let vodToBeAssigned = "";

  const dispatch = createEventDispatcher();
  
  let search = "";
  let typingTimer;
  let doneTypingInterval = 600;
  let placeholderThumb = 'https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/vod_thumb_100.png';

  const configSearch = () => {
    let searchInput = document.getElementById("searchInputAssignment");
    if (searchInput) {
      searchInput.addEventListener("keyup", () => {
        delaySearch();
      });
    }
  };

  const handleItemSelected = (item) => {
    // console.log(item);
    // item.id = item.vod_id;
    item.description = item.description ?? "";
    dispatch('itemSelected', { item });
    dispatch('closeDialog');
  }

  const delaySearch = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      (_) => dispatch("searchChange", { search }),
      doneTypingInterval
    );
  };

  onMount(() => {
    configSearch();
  });
</script>

<style>
  * :global(.mdc-text-field) {
    height: 40px;
    margin-right: 0.8em;
  }
  * :global(.mdc-text-field__input) {
    height: auto;
    margin: auto;
    padding: 0 0.5em !important;
  }
  *
    :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
      .mdc-floating-label) {
    top: 12px;
  }
  * :global(.material-icons.searchIcon) {
    padding: 10px 0px 0px 4px !important;
  }
  .justifyContentEnd {
    display: flex;
    justify-content: flex-end;
  }
  .alignCenter {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .textLeft {
    text-align: left;
  }
  .nameWrapper {
    font-weight: 600;
    opacity: 0.8;
  }
  .bold {
    font-weight: bold;
  }
  .imageWrapper {
    background-color: grey;
    height: 100px;
    width: 100px;
  }
  .imagevod {
    border-radius: 7px;
    height: 100px;
    width: 100px;
    align-items: center;
    object-fit: cover;
    max-width: inherit;
  }
  .statusCircle {
    border-radius: 50%;
    display: inline-block;
    height: 0.9rem;
    margin-left: 1rem;
    width: 0.9rem;
  }
  .header {
    background-color: #f9f9f9;
    align-items: center;
  }
  .alignBaseline {
    align-content: baseline;
    align-items: baseline;
    display: flex;
  }
  * :global(.mdc-button--raised), * :global(.mdc-button--unelevated) {
    height: 2rem;
  }
  .searchbar {
    order: initial;
  }
  @media (max-width: 767px) {
    .searchbar {
      order: 3;
    }
    .header__title {
      margin: 0 !important;
    }
    * :global(.mdc-text-field__input) {
      margin-left: 0;
    }
    .desktop {
      display: none;
    }
  }
</style>

<div class="header px-4 py-2 row no-gutters">
  <div class="header__title col-11 col-md-7 my-4">
    <div class="mdc-typography--headline6 bold">
      {`${text}`}
    </div>
  </div>
  <div class="col-12 col-md-4 searchbar">
    <Textfield
      variant="outlined"
      class="w100"
      withLeadingIcon
      id="searchInputAssignment"
      bind:value={search}
      label={$_('cerca_per_nome')}>
      <div class="centrated">
        <Icon class="material-icons searchIcon">search</Icon>
      </div>
    </Textfield>
  </div>
  <div class="col-1 col-md-1 justifyContentEnd">
    <IconButton
      class="material-icons"
      on:click={() => dispatch('closeDialog', {})}>
      close
    </IconButton>
  </div>
</div>

<div class="container fluid py-3">
  <MsgError {msgError} />

  {#if !loading}
    <div
      class={`${items.length > 0 ? 'containerCustomer' : 'container fluid py-2'}`}>
      {#if items.length > 0}
        <VirtualList items={items} let:item>
          <Card class="mb-2">
            <Content>
              <div class="row no-gutters alignCenter">
                <div class="col-4 col-md-2">
                  <Media>
                    {#if (item.thumbnail && item.thumbnail.length > 0)}
                      <img src={item.thumbnail} alt="" class="imagevod" />
                    {:else}
                      <img src={placeholderThumb} alt="" class="imagevod" />
                    {/if}
                  </Media>
                </div>
                <div class="col-3 ml-2 textLeft alignCenter d-flex flex-column align-items-start">
                  {#if item.is_playlist}
                      <PlaylistLabel mx={false}/>
                  {/if}
                  <div>
                      <span class="nameWrapper">
                          {item.name}
                      </span>
                  </div>
                </div>

                <div class="col-4 col-md-3 ml-auto justifyContentEnd">
                  <Button
                    variant="unelevated"
                    disabled={!item.is_active}
                    on:click={handleItemSelected(item)}>
                    <Label>{$_('inserisci')}</Label>
                  </Button>
                </div>
              </div>
            </Content>
          </Card>
        </VirtualList>
      {/if}
    </div>
  {:else}
    <div class="row no-gutters position-relative">
      <Loading />
    </div>
  {/if}
</div>
