<script>
  import { tick } from "svelte";
  import { _, locale } from "../../../../services/i18n";
  import Carousel from "./Carousel/Carousel.svelte";
  import PhotoUploadDialog from "./PhotoUploadDialog.svelte";
  import PhotoEnlargedDialog from "./PhotoEnlargedDialog.svelte";
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import { Icon } from "@smui/common";
  import moment from "moment";
  import "moment/locale/en";
  import "moment/locale/it";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import {
    getPhotoProgress,
    deletePhotoProgress,
  } from "../../../../services/customerServices.js";
  import "./Carousel/Carousel.scss";
  import { customer } from "../../../../lib/store";

  export let customerId;

  let msgError;
  let loading = true;
  let insertedPhotos = false;

  $: {
    if (uploadPhoto)
      insertedPhotos = Object.values(uploadPhoto).some((i) => {
        if (typeof i !== "string") return true;
      });
  }

  const DATEFORMAT = "YYYY-MM-DD";
  const autoHideArrowsOffset = 6;
  let currentIndex = 0;
  const splideOptions = {
    arrows: true,
    pagination: false,
    autoWidth: true,
    rewind: false,
    direction: "rtl",
    gap: 25,
  };
  let uploadDialog;
  let dateDialog = moment(new Date()).format(DATEFORMAT);
  let photoDialog;
  let photoToEnlarge = "";
  let photoToEnlargeIndex = 0;
  let photoToEnlargeList = [];
  let photoToEnlargeDate = "";
  let image = "";
  let uploadPhoto = {
    front_view:
      "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/front_view.jpg",
    rear_view:
      "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/rear_view.jpg",
    left_view:
      "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/left_view.jpg",
    right_view:
      "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/right_view.jpg",
  };

  let selectedImages = [];
  $: if (selectedImages && lastCheckedImage) {
    if (
      selectedImages.filter((i) => {
        return i === lastCheckedImage;
      }).length > 0
    ) {
      selectedImages.forEach((i) => {
        document.getElementById(i).classList.add("visible");
        document.getElementById(`${i}-layer`).classList.add("visible");
      });
    } else {
      document.getElementById(lastCheckedImage).classList.remove("visible");
      document
        .getElementById(`${lastCheckedImage}-layer`)
        .classList.remove("visible");
    }
  }

  let lastCheckedImage = "";

  let photos = [];
  let frontViews = [];
  let rearViews = [];
  let leftViews = [];
  let rightViews = [];
  let views = [];
  $: views = [
    { items: frontViews, label: "front_view" },
    { items: rearViews, label: "rear_view" },
    { items: rightViews, label: "right_view" },
    { items: leftViews, label: "left_view" },
  ];
  $: {
    let front = [];
    let rear = [];
    let left = [];
    let right = [];
    for (let item of photos) {
      front.push({
        image:
          item.front_view ||
          item.front_view_thumb ||
          "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/front_view.jpg",
        image_thumb:
          item.front_view_thumb ||
          item.front_view ||
          "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/front_view.jpg",
        date: item.created_at,
        date_string: getFromNow(item.created_at),
        id: item.id,
      });
      rear.push({
        image:
          item.rear_view ||
          item.rear_view_thumb ||
          "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/rear_view.jpg",
        image_thumb:
          item.rear_view_thumb ||
          item.rear_view ||
          "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/rear_view.jpg",
        date: item.created_at,
        date_string: getFromNow(item.created_at),
        id: item.id,
      });
      left.push({
        image:
          item.left_view ||
          item.left_view_thumb ||
          "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/left_view.jpg",
        image_thumb:
          item.left_view_thumb ||
          item.left_view ||
          "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/left_view.jpg",
        date: item.created_at,
        date_string: getFromNow(item.created_at),
        id: item.id,
      });
      right.push({
        image:
          item.right_view ||
          item.right_view_thumb ||
          "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/right_view.jpg",
        image_thumb:
          item.right_view_thumb ||
          item.right_view ||
          "https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/right_view.jpg",
        date: item.created_at,
        date_string: getFromNow(item.created_at),
        id: item.id,
      });
    }
    frontViews = front;
    rearViews = rear;
    rightViews = right;
    leftViews = left;
  }
  $: if (customerId) getPhotos();

  const getFromNow = (currDate) => {
    return moment(currDate).isSame(moment(), "day")
      ? $_("oggi_fromNow")
      : moment(currDate).from(moment().startOf("day"));
  };

  $: dateDialogItalian = dateDialog
    ? moment(dateDialog).format("DD/MM/YYYY")
    : "";

  const displayError = (message) => {
    msgError = message ? message : $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const openDialog = () => {
    dateDialog = moment(new Date()).format(DATEFORMAT);
    uploadDialog.open();
  };

  const deletePhotos = async () => {
    try {
      let body = [];
      selectedImages.forEach((i) => {
        const [id, view] = i.split("-");
        const photoSelected = photos.filter((p) => p.id == id)[0][view];
        const isPhotoNotPresent =
          photoSelected == null || photoSelected.includes("/PlaceHolder");
        if (body.filter((i) => i.id == id).length == 0 && !isPhotoNotPresent) {
          body.push({
            id,
            [view]: "delete",
            [`${view}_thumb`]: "delete",
          });
        }
        body = body.map((el) => {
          return el.id == id
            ? { ...el, [view]: "delete", [`${view}_thumb`]: "delete" }
            : el;
        });
      });
      if (body.length == 0) {
        displayError($_("attenzione_sono_stati_selezionati_solo_placeholder"));
        return;
      }
      await deletePhotoProgress(customerId, body);
      selectedImages.forEach((i) => {
        const [id, view] = i.split("-");
        document.getElementById(i).classList.remove("visible");
        document.getElementById(`${i}-layer`).classList.remove("visible");
      });
      selectedImages = [];
      lastCheckedImage = false;
      loading = true;
      getPhotos();
    } catch (err) {
      loading = false;
      console.log("err deletePhotos", err);
      displayError();
    }
  };

  const getPhotos = async () => {
    try {
      const res = await getPhotoProgress(customerId);
      photos = Object.values(res);
      currentIndex = 0;
      await tick();
      loading = false;
    } catch (error) {
      console.log("getPhotos error", error);
      displayError();
    }
  };

  const getDateFormatted = (date) => {
    const momentDate = moment(date);
    return $locale == "en"
      ? momentDate.locale("en").format("YYYY MMMM D")
      : momentDate.locale("it").format("D MMMM YYYY");
  };

  const handlePhotoClicked = (view, viewList, index) => {
    photoToEnlargeDate = getDateFormatted(view.date);
    let toRemove = 0;
    photoToEnlargeList = viewList.filter((vImg, oldIndex) => {
      if (!isPlaceholder(vImg.image)) {
        return true;
      } else if (index >= oldIndex) {
        toRemove++;
        return false;
      } else {
        return false;
      }
    });
    photoToEnlargeIndex = index - toRemove;
    photoToEnlarge = view.image;
    photoDialog.open();
  };

  const isPlaceholder = (inputImage) =>
    Object.values(uploadPhoto).includes(inputImage);

  const obtainCheckboxImage = (id, view) =>
    (lastCheckedImage = `${id}-${view}`);
</script>

<style>
  * :global(.spinner) {
    position: unset;
    top: unset;
    bottom: unset;
  }
  .alignCenter {
    align-items: center;
    align-content: center;
  }
  .headline {
    font-size: 1.25rem;
  }
  .customSlideImage {
    height: 14em;
    overflow: hidden;
    position: relative;
    width: 8em;
  }
  .customSlideImage > img {
    box-shadow: 0 0 10px 0 rgba(111, 97, 99, 0.3);
    border-radius: 10px;
    width: 100%;
    height: 12em;
    object-fit: cover;
  }
  * :global(.checkboxSlideImage) {
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: 2;
  }
  * :global(.customSlideImage:hover .checkboxSlideImage) {
    visibility: visible;
  }
  * :global(.overlay) {
    -webkit-transition: all 1s;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    bottom: 0;
    cursor: pointer;
    height: 12rem;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.1s;
    visibility: hidden;
    width: 100%;
  }
  * :global(.customSlideImage:hover .overlay) {
    visibility: visible;
  }
  .carouselLabel {
    font-size: 1.25rem;
    font-weight: 600;
  }
  * :global(.mdc-dialog) {
    padding-top: 66px;
  }
  * :global(.mdc-dialog__title + .mdc-dialog__content) {
    padding: 0px 24px;
  }
  * :global(.mdc-dialog__title) {
    background-color: #f9f9f9;
    display: flex;
    opacity: 0.8;
    padding: 10px 39px;
  }
  .notClickable {
    cursor: auto;
  }
  .fromNow {
    direction: ltr;
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 0.5rem;
    opacity: 0.8;
  }
  .spaceBetween {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    * :global(.mdc-dialog__content) {
      max-height: fit-content;
      flex-grow: 0;
    }
  }
</style>

<PhotoEnlargedDialog
  bind:photoDialog
  bind:photoToEnlargeDate
  bind:photoToEnlarge
  {photoToEnlargeIndex}
  {photoToEnlargeList}
  {getDateFormatted} />

<div class="container mt-2">
  <div class="row no-gutters spaceBetween alignCenter">
    <div class="headline bold">{$_('foto_progressi')}</div>
    <Button 
      color="primary" 
      variant="unelevated" 
      disabled={!$customer.active}
      on:click={() => openDialog()}>
      <Label>
        <Icon
          role="button"
          class="material-icons mdc-text-field__icon
            mdc-text-field__icon--trailing">
          photo_camera
        </Icon>
        {$_('nuove_foto')}
      </Label>
    </Button>
  </div>

  {#if msgError}
    <div class="alert alert-danger fade show mt-2" role="alert">{msgError}</div>
  {/if}

  {#if selectedImages.length > 0}
    <div class="row no-gutters">
      <Button
        color="secondary"
        style={!$customer.active ? `` : `background-color:#cd293d; border: 1px #cd293d;`}
        variant="unelevated"
        disabled={!$customer.active}
        on:click={deletePhotos}>
        <Label>
          <Icon
            role="button"
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing">
            delete
          </Icon>
          {$_('elimina')}
        </Label>
      </Button>
    </div>
  {/if}

  {#if loading}
    <Loading />
  {:else if photos.length > 0}
    {#each views as { items, label }}
      <div class="my-4">
        <span class="carouselLabel">{$_(`${label}`)}</span>
        <Carousel
          group="photoProgress"
          {splideOptions}
          {currentIndex}
          autoHideArrows
          {autoHideArrowsOffset}>
          <ul class="splide__list">
            {#each items as view, i}
              <li class="splide__slide customSlideImage">
                <img src={view.image_thumb || view.image} alt="" />
                {#if isPlaceholder(view.image_thumb)}
                  <div class="notClickable" id={`${view.id}-${label}-layer`} />
                {:else}
                  <div class="fromNow">{view.date_string}</div>
                  <Checkbox
                    on:click={() => obtainCheckboxImage(view.id, label)}
                    bind:group={selectedImages}
                    value={`${view.id}-${label}`}
                    id={`${view.id}-${label}`}
                    class="checkboxSlideImage" />
                  <div
                    class="overlay"
                    id={`${view.id}-${label}-layer`}
                    on:click|stopPropagation={() => {
                      handlePhotoClicked(view, items, i);
                    }} />
                {/if}
              </li>
            {/each}
          </ul>
        </Carousel>
      </div>
    {/each}
  {:else}
    <div class="py-2 headline demiBold">{$_('nessuna_foto_da_mostrare')}</div>
  {/if}

  <PhotoUploadDialog
    bind:uploadDialog
    bind:uploadPhoto
    bind:dateDialog
    bind:loading
    {dateDialogItalian}
    {insertedPhotos}
    {customerId}
    on:error={displayError}
    on:getPhotos={getPhotos} />
</div>
