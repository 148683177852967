<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { deleteAtGroup, editGroup, updateChatEnabledForNewMembers, updateGroupAutomations } from "../../../../services/teamServices.js";
  import { getIntervals } from "../../../../lib/utils.js";
  import { group, user } from "../../../../lib/store";
  import { navigate } from "svelte-routing";
  import Button, { Icon, Label } from "@smui/button";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import DatePicker from "../../../ReusableLayouts/DatePicker.svelte";
  import Dialog from "@smui/dialog";
  import FormField from '@smui/form-field';
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import moment from "moment";
  import Textfield from "@smui/textfield";
  import Radio from '@smui/radio';
  import Select, { Option } from "@smui/select";
  import SvelteTooltip from "svelte-tooltip";
  import Switch from "@smui/switch";
  
  const dispatch = createEventDispatcher();

  export let groupId;
  export let massAssignment;
  export let customersRemovingRulesEnabled;
  export let removeCustomersAtSpecificDate;
  export let removeCustomersAfterPeriod;
  export let ruleSpecificDate;
  export let ruleIntervalCount;
  export let ruleInterval;

  let chatEnabledForNewMembers;
  let confirmationMassAssignmentDialog;
  let confirmationMassAssignmentDialogOpen;
  let customersRemovingRules = [];
  let durationOptions = getIntervals($_);
  let groupDeletionDialog;
  let msgError;
  let name = "";
  let nameInvalid = false;
  let nameNotModified = false;
  let ruleSpecificDateFormatted = ''; 
  let selectedRule = 'after_period'; // Default

  // Questi dati vanno recuperati dal backend tramite GroupLayout e aggiornati reattivamente
  $: customersRemovingRules = [
    {
      id             : 'after_period',
      active         : selectedRule == 'after_period',
      disabled       : !customersRemovingRulesEnabled || massAssignment,
      interval_count : ruleIntervalCount,
      interval       : ruleInterval,
      label          : $_('dopo'),
      label_end      : $_('dal_loro_ingresso'),
    },
    {
      id             : 'specific_date',
      active         : selectedRule == 'specific_date',
      disabled       : !customersRemovingRulesEnabled || massAssignment,
      label          : $_('in_data'),
      date           : ruleSpecificDate ? moment(ruleSpecificDate).format("DD/MM/YYYY") : moment().add(1, 'months').format("DD/MM/YYYY"),
    }
  ];

  $: selectedRule = removeCustomersAtSpecificDate 
    ? 'specific_date'
    : 'after_period';

  $: formValid = !nameInvalid && !nameNotModified;
  $: nameInvalid = name.length < 3 || name.length > 191;
  $: if (name) {
    nameNotModified = name === $group.name;
  } 
  $: if ($group && $group.has_chat != undefined) {
    chatEnabledForNewMembers = $group.has_chat;
  }

  const handleRadioChange = (e) => {
    if (selectedRule === 'specific_date' && ruleSpecificDate == '') {
      ruleSpecificDate = moment().add(1, 'months').format("YYYY-MM-DD");
    }
  };

  const handleStartDate = (e) => {
    if (moment(e.detail.date, "YYYY-MM-DD", true).isValid() === true) {
      ruleSpecificDate = e.detail.date;
      ruleSpecificDateFormatted = moment(ruleSpecificDate).format("YYYY-MM-DD");
    } else {
      ruleSpecificDate = '';
      ruleSpecificDateFormatted = '';
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const onAutomationsSubmit = async () => {
    try {
      // Prepare payload
      const body = { 
        id: groupId, 
        customers_removing_rules_enabled : customersRemovingRulesEnabled === true ? 1 : 0,
        remove_customer_after_period     : selectedRule === 'after_period' ? 1 : 0,
        remove_customer_at_specific_date : selectedRule === 'specific_date' === true ? 1 : 0,
        rule_interval_count              : selectedRule === 'after_period'  === true ? ruleIntervalCount : '',
        rule_interval                    : selectedRule === 'after_period'  === true ? ruleInterval : '',
        rule_specific_date               : selectedRule === 'specific_date' === true ? ruleSpecificDate : '',
      };
      await updateGroupAutomations(body);
      await tick();
      dispatch('updateAtGroups');
    } catch (err) {
      displayError();
      console.log("err onAutomationsSubmit", err);
    }
  };

  const onSubmit = async () => {
    try {
      const body = { 
        id: groupId, 
        newGroupName: name,
      };
      await editGroup(body);
      await tick();
      dispatch('updateAtGroups');
    } catch (err) {
      displayError();
      console.log("err editGroup", err);
    }
  };

  // Mass assignment
  const handleMassAssignmentChange = async () => {
    confirmationMassAssignmentDialogOpen = true;
    confirmationMassAssignmentDialog.open();
  };

  const handleDeleteGroup = async () => {
    groupDeletionDialog.close();
    try {
      const res = await deleteAtGroup(groupId);
      setTimeout(() => {
        navigate("/team?tab=gruppi");
      }, 2000);
    } catch (err) {
      console.log("err deleteGroup", err);
      displayError();
    }
  };

  const handleChatEnabledChange = async () => {
    chatEnabledForNewMembers = !chatEnabledForNewMembers;
    await updateChatEnabledForNewMembers({
      at_group_id : groupId,
      chat_enabled_for_new_members : chatEnabledForNewMembers
    });
  };

  const handleRemovingCustomersRulesActiveChange = async () => {
    customersRemovingRulesEnabled = !customersRemovingRulesEnabled;
  };

  onMount(() => {
    name = $group.name;
    dispatch('updateAtGroups');
  })
</script>

<style>
  .label {
    align-self: center;
    font-weight: bold;
    opacity: 0.8;
  }
  .pushToEnd {
    justify-content: flex-end;
  }

  * :global(.interval_count) {
    width: 70px;
  }

  * :global(.interval_count input) {
    text-align: center;
    padding-bottom: 6px !important;
  }

  * :global(.interval_count div.mdc-select__selected-text) {
    text-align: center;
    padding-top: 18px !important;
  }

  * :global(.interval_count i.mdc-select__dropdown-icon) {
    top: 18px;
  }

  * :global(.radio-label) {
    font-size: 16px;
  }

  * :global(.fullWidth) {
    width: 100% !important;
  }

  * :global(.primary) {
    color: var(--primary);
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
</style>

<Dialog bind:this={groupDeletionDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_il_gruppo')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => groupDeletionDialog.close()}
    on:onConfirmation={handleDeleteGroup} />
</Dialog>

<Dialog 
  bind:this={confirmationMassAssignmentDialog} 
  on:click={(e) => {e.stopPropagation()}}
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  >
  <ConfirmationDialog
    text={massAssignment ? $_('conferma_assegnazione_di_massa_attivata') : $_('conferma_assegnazione_di_massa_disattivata')}
    confirmationButtonLabel={$_('conferma')}
    on:closeDialog={() => {
      massAssignment = !massAssignment;
      customersRemovingRulesEnabled = false;
      confirmationMassAssignmentDialogOpen = false;
      confirmationMassAssignmentDialog.close();
    }}
    on:onConfirmation={() => {
      dispatch('updateMassAssignment', {
        groupId: groupId,
        massAssignment: massAssignment,
      });
      confirmationMassAssignmentDialogOpen = false;
      confirmationMassAssignmentDialog.close();
    }} />
</Dialog>

<div class="container py-3">
  <!-- Options -->
  <div class="row py-2">
    <div class="col-2 d-flex justify-content-end align-items-center">
      <Switch bind:checked={massAssignment} disabled={customersRemovingRulesEnabled} on:click={handleMassAssignmentChange} />
    </div>
    <div class="col-10 label">
      <span class="pl-2">{$_('assegna_tutti_i_tuoi_clienti')}</span>
    </div>
  </div>
  {#if $user.chat_enabled}
    <div class="row py-2">
      <div class="col-2 d-flex justify-content-end align-items-center">
        <Switch bind:checked={chatEnabledForNewMembers} on:click={handleChatEnabledChange} />
      </div>
      <div class="col-10 label">
        <span class="pl-2">{$_('attiva_chat_per_i_nuovi_membri_del_gruppo')}</span>
      </div>
    </div>
  {/if}

  <hr />

  <!-- Options - Removing rules -->

  <div class="row py-2">
    <div class="col-2 d-flex justify-content-end align-items-center">
      <Switch bind:checked={customersRemovingRulesEnabled} disabled={massAssignment} on:click={handleRemovingCustomersRulesActiveChange} />
    </div>
    <div class="col-10 label">
      <span class="pl-2">{$_('rimuovi_dal_gruppo_programmaticamente')}</span>
    </div>
  </div>

  <div class="row py-2">
    <div class="col-12 radio-demo offset-2 ">
      {#each customersRemovingRules as rule}
        <FormField class="d-flex mb-2" disabled={!customersRemovingRulesEnabled}>
          <Radio
            on:change={handleRadioChange}
            bind:group={selectedRule}
            value={rule.id}
            disabled={rule.disabled}
          />
          <span slot="label" class="d-flex align-items-center radio-label">
            <span class="mr-2">{rule.label}</span>
            <span class="mr-2">
              <div class="d-flex">
                {#if rule.id == 'after_period'}
                  <div class="pr-2">
                    <Textfield
                      class="interval_count"
                      input$maxlength="3"
                      input$maxvalue="999"
                      bind:value={ruleIntervalCount}
                      type="number" 
                    />
                  </div>
                  <div>
                    <Select
                      enhanced
                      class="w30 mb-2 interval_count"
                      bind:value={ruleInterval}>
                      {#each durationOptions as { value, label }}
                        <Option {value} selected={rule.interval === value}>
                          {label.toLowerCase()}
                        </Option>
                      {/each}
                    </Select>
                  </div>
                {/if}
                {#if rule.id == 'specific_date'}
                  <div class="pr-2">
                    <DatePicker
                      on:date={handleStartDate}
                      dateFormat="yyyy-mm-dd" 
                      defaultDate={rule.date}
                      disabled={rule.disabled}
                      minDate={moment().toISOString()}
                      maxDate={moment().add(90, 'years').toISOString()}
                      width="100"
                    />
                  </div>
                {/if}
              </div>
            </span>
            {#if rule.id == 'after_period'}
              <span class="mr-2">{rule.label_end.toLowerCase()}</span>
            {/if}
          </span>
        </FormField>
      {/each}
    </div>
  </div>

  <div class="row pushToEnd profileUpdate">
    <Button
      class="btn mx-3"
      variant="unelevated"
      on:click={onAutomationsSubmit}>
      <Label>{$_('salva_automazioni')}</Label>
    </Button>
  </div>

  <hr />
  
  <!-- Rename and delete options -->
  <div class="row mb-3 pb-3">
    <div class="col-5 label" style="text-align: end;">{`${$_('nome')} *`}</div>
    <div class="col-7">
      <Textfield
        variant="outlined"
        class={`textbox w100 my-2 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
        bind:value={name}
        type="text" />
      {#if nameInvalid}
        <FormMessage>
          {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
        </FormMessage>
      {/if}
    </div>
  </div>

  <MsgError {msgError} />

  <div class="row pushToEnd profileUpdate">
    <button
      class="btn btnTooltip mdc-ripple-upgraded"
      on:click={() => groupDeletionDialog.open()}
      data-tooltip={$_('elimina_profilo_overlay')}>
      <Icon class="material-icons">delete</Icon>
      <Label>{$_('elimina_gruppo')}</Label>
    </button>
    <Button
      class="btn mx-3"
      variant="unelevated"
      on:click={onSubmit}
      disabled={!formValid}>
      <Label>{$_('rinomina_gruppo')}</Label>
    </Button>
  </div>
</div>
