<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { navigate } from "svelte-routing";
	import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
	import Dialog from "@smui/dialog";
	import IconButton from "@smui/icon-button";
	import SvelteTooltip from 'svelte-tooltip';

	export let assignment;
	export let groupId;
	export let index;
	export let isMobile;
	export let showMobileIcons = undefined;

	const dispatch = createEventDispatcher();

	let assignmentToBeDeleted = {};
	let confirmationDeleteDialog;

	const handleDeleteButtonClicked = (assignment) => {
		assignmentToBeDeleted = assignment;
		confirmationDeleteDialog.open();
	};

</script>

<style>
	* :global(.primary) {
			color: var(--primary);
	}
	* :global(.op-0) {
			opacity: 0 !important;
			cursor: auto;
	}
	.hide {
			display: none;
	}
	.actions > div {
			width: 35px;
	}
</style>

<Dialog bind:this={confirmationDeleteDialog}>
	<ConfirmationDialog
		text={assignment.vod_id 
			? $_('sei_sicuro_di_voler_eliminare_il_video_assegnato')
			: $_('sei_sicuro_di_voler_eliminare_la_playlist_assegnata')
		}
		confirmationButtonLabel={$_('elimina')}
		on:closeDialog={(e) => {
			e.stopPropagation();
			confirmationDeleteDialog.close();
		}}
		on:onConfirmation={(e) => {
			e.stopPropagation();
			dispatch('deleteAssignment', {
					assignment : assignmentToBeDeleted,
					is_active  : assignmentToBeDeleted.is_active,
			});
			confirmationDeleteDialog.close();
		}} />
</Dialog>

{#if isMobile}
	<div
		class="col-2 col-md-3 pr-0 bg__color flexy bar--icons justify-content-end"
		on:focusout={(e) => {
			if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
			showMobileIcons = undefined;
	}}>
		<div
			class="icon--action"
			class:primary={showMobileIcons == index}
			on:click|stopPropagation={() => {
				(showMobileIcons === index 
					? handleDeleteButtonClicked(assignment) 
					: (showMobileIcons = index)
				)}}>
			<IconButton 
				class="material-icons px-0" 
				style="width: fit-content">
				{showMobileIcons == index 
					? `delete` 
					: 'more_vert'}
			</IconButton>
		</div>
		<div class="flexy">
			<div
				class="icon--action"
				id="edit-icon"
				class:hide={showMobileIcons != index}
				on:click|stopPropagation={() => {
					if (assignment.is_active) navigate(
						'/vod-assignment', {
							state: {
								assignment,
								groupId,
							},
						}
					);
				}}>
				<IconButton
					data-mobile-icon
					class="material-icons pr-1"
					style="width: fit-content">
					edit
				</IconButton>
			</div>
		</div>
	</div>
{:else}
	<div class="actions col-3 col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
		<div 
				class="mr-2"
				on:click|preventDefault={handleDeleteButtonClicked(assignment)}>
				<SvelteTooltip tip="{$_('elimina')}" top >
					<IconButton
						disabled={!assignment.is_active}
						class={`material-icons ${!assignment.is_active ? '' : 'primary'}`}>
						delete
					</IconButton>
				</SvelteTooltip>
			</div>
		<div
			class="mr-1"
			on:click|stopPropagation={() => {
				if (assignment.is_active)
					navigate('vod-assignment', { 
						state: { 
							assignment, 
							groupId
						} 
				});
			}}>
			<SvelteTooltip tip="{$_('modifica')}" top >
				<IconButton
						class="material-icons">
						edit
				</IconButton>
			</SvelteTooltip>
		</div>
	</div>
{/if}
