<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { customer } from "../../../../lib/store";
  import { deleteVodAssignment, deletePlaylistAssignment } from "../../../../services/vodsServices";
  import { removeCustomerFromGroup } from "../../../../services/teamServices";
  import AssignedVodActions from "./AssignedVodActions.svelte";
  import AssignedVodInfo from "./AssignedVodInfo.svelte";
  import Card, { Content } from "@smui/card";
  
  export let customerId;
  export let firstAssignments = [];
  
  const dispatch = createEventDispatcher();
  const jq = window.$;

  let isMobile = false;
  let showMobileIcons;
  let withoutErasedEmptyFilteredAssignments = [];

  $: if (firstAssignments) {
    withoutErasedEmptyFilteredAssignments = firstAssignments.filter((fc) => {
      if (!fc.is_active)
        return;
      else return fc;
    });
  }

  const handleDeleteAssignment = async ({ detail }) => {
    const { assignment, is_active } = detail;
    if ($customer.active && is_active) {
      try {
        if (assignment.vod_assignment_id) {
          await deleteVodAssignment(assignment.vod_assignment_id)
        } else if (assignment.playlist_id) {
          await deletePlaylistAssignment(assignment.playlist_assignment_id)
        }
        dispatch("removeAssignment", { assignment });
      } catch (err) {
        console.log("delete error", err);
        dispatch("onError", {});
      }
    }
  };

  const handlePlayVod = (e) => {
    dispatch("playVod", { vod: e.detail.vod });
  }

  const handleRemoveCustomerFromGroups = async ({ detail }) => {
    const { customer, groupsRemoving } = detail;
    customer.id = customer.customer_id;
    if (customer.active && groupsRemoving) {
      try {
        await Promise.all(groupsRemoving.map(async (group) => {
            await removeCustomerFromGroup(customer, group.id);
        }));
        dispatch('updateAssignedVodsAndPlaylists');
      } catch (err) {
        console.log("delete error", err);
        dispatch("onError", {});
      }
    }
  }

  onMount(() => {
    jq(document).ready(() => {
      if (jq(".desktop").css("display") == "none") {
        isMobile = true;
      } else isMobile = false;
    });
  });
</script>

<style>
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
</style>

{#each withoutErasedEmptyFilteredAssignments as assignment, i}
  <Card class="mb-3">
    <Content>
      <div class="row px-2 spaceBetween">
        <AssignedVodInfo {assignment} {isMobile} on:updateDraftTrainingCard on:playVod={handlePlayVod} />
        <AssignedVodActions
          bind:showMobileIcons
          {assignment}
          {customerId}
          {isMobile}
          index={i}
          on:deleteAssignment={handleDeleteAssignment}
          on:removeCustomerFromGroups={handleRemoveCustomerFromGroups} />
      </div>
    </Content>
  </Card>
{/each}
