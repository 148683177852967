<script>
  import { _ } from "../../../../services/i18n";
  import { cloneMedicalHistory, deleteMedicalHistory,} from "../../../../services/teamServices";
  import { createEventDispatcher, onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import BannerHelp from "../../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label, Icon } from "@smui/button";
  import Card, { Content } from "@smui/card";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import HistoriesList from "./HistoriesList.svelte";
  import Loading from "../../../ReusableLayouts/Loading.svelte";

  export let active = undefined;
  export let customerId = null;
  export let histories = [];
  export let loadingHistories = true;

  const dispatch = createEventDispatcher();

  let deleteDialog;
  let historyToBeDeleted;
  let msgError;

  const handleOpenDialog = (e) => {
    historyToBeDeleted = e.detail.id;
    deleteDialog.open();
  };

  const handleCloneHistory = async (e) => {
    try {
      const res = await cloneMedicalHistory(e.detail.id);
      dispatch("historyAdded", { history: res });
    } catch (err) {
      console.log("handleCloneHistory err", err);
      displayError();
    }
  };

  const handleDeleteHistory = async () => {
    try {
      const res = await deleteMedicalHistory(historyToBeDeleted, customerId);
      deleteDialog.close();
      dispatch("historyRemoved", { id: historyToBeDeleted });
      historyToBeDeleted = "";
    } catch (err) {
      console.log("delete history error", err);
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };
</script>

<style>
  * :global(.alignInlineCenter) {
    align-content: center;
    align-items: center;
    display: inline-flex;
  }

  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 14px;
    text-align: start;
  }
  .textToEnd {
    text-align: end;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
  * :global(.mdc-card) {
    text-align: center;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }
  .headline {
    font-size: 2.5rem;
  }

  .upperText {
    font-size: 1.25rem;
  }
  * :global(.icon--anamnesis) {
    background-image: url("/assets/new-anamnesis.svg");
    background-position: center;
    background-repeat: no-repeat;
    height: 1.5rem;
    width: 1.5rem;
  }
</style>

<Dialog bind:this={deleteDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_l_anamnesi')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => {
      deleteDialog.close();
      historyToBeDeleted = '';
    }}
    on:onConfirmation={handleDeleteHistory} />
</Dialog>

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

<div class="my-3">
  <BannerHelp state='histories'/>
</div>

<div class="row mt-5 mb-2">
  <div class="col textToEnd">
    <Button
      disabled={!active}
      color="secondary"
      variant="unelevated"
      on:click={() => {
        navigate(`/new-history?customerId=${customerId}`);
      }}>
      <Label class="alignInlineCenter">
        <Icon
          role="button"
          class="icon--anamnesis material-icons mdc-text-field__icon
            mdc-text-field__icon--trailing" />
        {$_('nuova_anamnesi')}
      </Label>
    </Button>
  </div>
</div>

<div class="container fluid">
  <Card class="invisibleCard">
    <Content style="padding-bottom: 0px;">
      <div class="row">
        <div class="col-4 mdc-typography--headline6 textTitle">
          {$_('data')}
        </div>
        <div class="col-6 mdc-typography--headline6 textTitle">
          {$_('creata_da')}
        </div>
      </div>
      <div class="">
        <div class="col-2 mdc-typography--headline6 textTitle" />
      </div>
    </Content>
  </Card>
  <hr class="line" />
</div>

{#if loadingHistories}
  <Loading />
{:else}
  {#if histories && histories.length > 0}
    <HistoriesList
      {histories}
      {customerId}
      on:openDialog={handleOpenDialog}
      on:cloneHistory={handleCloneHistory} />
  {:else}
    <div class="flexy">
      <Card class="invisibleCard h100">
        <Content>
          <div class="row">
            <span class="col-12 py-3 upperText flexy">
              {$_('nessuna_anamnesi')}
            </span>
            <span class="col-12 py-3 headline flexy">
              <strong>{$_('crea_la_prima_anamnesi')}</strong>
            </span>
            <div class="col-12 py-3 flexy">
              <Button
                color="primary"
                variant="unelevated"
                disabled={!active}
                on:click={() => {
                  navigate(`/new-history?customerId=${customerId}`);
                }}>
                <Label class="alignInlineCenter">
                  <Icon
                    role="button"
                    class="icon--anamnesis mdc-text-field__icon
                      mdc-text-field__icon--trailing" />
                  {$_('nuova_anamnesi')}
                </Label>
              </Button>
            </div>
          </div>
        </Content>
      </Card>
    </div>
  {/if}
{/if}
