<script>
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";
  import Card, { Content } from "@smui/card";
  import Button, { Label } from "@smui/button";

  export let isOnRegisterPage;
  export let isOnSetNewPasswordPage;
</script>

<style>
  * :global(.mdc-button) {
    height: 2.5rem;
  }

  * :global(.textWrapper) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0;
    opacity: 0.8;
  }

  * :global(.textUppercase) {
    text-transform: uppercase;
  }

  * :global(.customCard) {
    align-items: center;
    border-radius: 2px;
    height: 4.375rem;
    justify-content: center;
    margin: 5px auto;
    width: 25rem;
  }

  @media screen and (max-width: 480px) {
    * :global(.btn) {
      height: 2.8125rem;
      min-width: 12.5rem;
    }
  }
</style>

<div class="container-fluid">
  <div class="card-container row justify-content-center">
    <div class="col-xs-12 col-md-12">
      <Card
        style="max-width: 400px; margin: 5px auto"
        class="borderNone customCard"
        variant="outlined">
        <Content>
          <p class="textWrapper">
            {$_(isOnRegisterPage ? 'hai_un_account' : 'non_hai_un_account')}
            <span
              class="bold"
              style="color: var(--primary); cursor: pointer;"
              on:click={() => {
                navigate(isOnRegisterPage ? '/login' : '/register');
              }}>
              {$_(isOnRegisterPage ? 'accedi' : 'registrati')}
            </span>
          </p>
        </Content>
      </Card>
    </div>
  </div>
</div>
{#if !isOnSetNewPasswordPage}
  {#if (window.location.host != "trainmobile.revoo-app.com")}
    <div class="col-xs-12 mt-3">
      <Button
        style="width:inherit "
        class="btn"
        color="primary"
        variant="unelevated"
        on:click={() => {
          window.open('https://meet.revoo-app.com/meetings/silvia/demo-revoo', '_blank');
        }}>
          <Label class="textUppercase">{$_('richiedi_webinar')}</Label>    
      </Button>
    </div>
  {/if}
{/if}
