<script>
  import { _ } from "../../services/i18n";
  import { customer as storedCustomer, user } from "../../lib/store";
  import { getCustomerInfo } from "../../services/teamServices";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import CustomerSubHeader from "../Team/Customer/CustomerSubHeader.svelte";
  import Header from "../ReusableLayouts/Header.svelte";
  import Deadlines from "./Deadlines/DeadlinesLayout.svelte";
  import Report from "./Report/ReportWrapper.svelte";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  const params = new URLSearchParams(location.search);

  let active;
  let activeTab = $_("scadenze");
  let component = [$_("scadenze")];
  let customer;
  let customerId;
  let msgError;
  let options = {
    [$_("scadenze")]: Deadlines,
    // [$_("report")]: Report,
  };
  let profileImage = "";
  let props = {};
  let searchParamsTab = "deadlines";
  let subHeaderProps;
  let tabs = [
    $_("scadenze"),
    // $_("report"),
  ];

  $: customerId = params.has("customerId") 
    ? params.get("customerId") 
    : location && location.state && location.state.customerId 
        ? location.state.customerId
        : $storedCustomer.customer_id;

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  $: {
    if (searchParamsTab == "deadlines") {
      component = $_("scadenze");
      activeTab = $_("scadenze");
    } else if (searchParamsTab == "report") {
      component = $_("report");
      activeTab = $_("report");
    }
  }

  $: subHeaderProps = {
    active,
    customerId,
    profileImage,
  };

  $: (async () => {
    if (customer) {
      props = { customerId };
    }
  })(); 

  const handleActiveTabChanged = (e) => {
    if ([e.detail.tab] == $_("scadenze"))
      navigate(`/home?tab=deadlines`, {
        replace: true,
      });
    else if ([e.detail.tab] == $_("report"))
      navigate(`/home?tab=report`, {
        replace: true,
      });
  };

  onMount(async () => {
    window.scrollTo(0, 0);
  });
</script>

<!-- <Header
  {activeTab}
  {tabs}
  asTitle={true}
  component={CustomerSubHeader} 
  noBackground={false}
  on:activeTabChanged={handleActiveTabChanged}
  on:onComponentEvent={console.log('')}
  props={subHeaderProps}
  sticky={false}
  subTitle={false}
  summary={false}
  tabNavigationEnabled={true}
  text={"Dashboard"}
/> -->

<Header
  {activeTab}
  {tabs}
  asTitle={true}
  noBackground={false}
  on:activeTabChanged={handleActiveTabChanged}
  sticky={false}
  subTitle={false}
  summary={false}
  tabNavigationEnabled={true}
  text={"Dashboard"}
/>

<div class="container fluid">
  <svelte:component
    this={options[component]}
    {...props}
  />
</div>

<style>
  @media (max-width: 767px) {
    :global(.headerText) {
      font-size: 0.875rem !important;
    }
  }
</style>
