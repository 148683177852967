<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { navigate } from 'svelte-routing';
  import { onMount } from "svelte";
  import Button, { Icon, Label } from "@smui/button";
  import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import NoItemsToShow from "../../../ReusableLayouts/NoItemsToShow.svelte";
  import SvelteTooltip from 'svelte-tooltip';

	export let activePage = 1;
	export let ascOrderByExpiringDate = true;
	export let ascOrderByName = true;
	export let downloading = false;
	export let itemsPerPage = 5;
  export let lastUpdatedValue = 0;
	export let list = [];
  export let loading = true;
  export let stripeAccount;

  const dispatch = createEventDispatcher();

  let biggerTextConnect = 'Connetti il tuo account REVOO a Stripe';
  let buttonTextConnect = 'Connetti';
  let isMobile;
  let iconConnect = 'account_balance_wallet';
  let upperTextConnect = 'Nessun account';

  // Pagination
  // $: if (list && Array.isArray(list) && list.length > 0) {
  //   paginatedItems = list.slice(
  //     (activePage - 1) * itemsPerPage,
  //     (activePage - 1) * itemsPerPage + itemsPerPage
  //   )
  // }

  $: if (list && (lastUpdatedValue === 0 || loading === false)) {
    getLastUpdate();
  }

  const handleFetchButtonClicked = () => {
    dispatch('fetchList');
  }

  const handleExportButtonClicked = () => {
    dispatch('exportList');
  }

  const toHumanDate = (timestamp) => {
    let d = new Date(timestamp * 1000);
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth()+1).toString().padStart(2, '0')}/${d.getFullYear().toString().padStart(4, '0')} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`;
  }

  // Check window size
  const windowSizeUpdated = () => {
    isMobile = document.documentElement.clientWidth < 576;
	}

  // Get last update value
  const getLastUpdate = () => {
    if (list && Array.isArray(list))
    list.map(i => {
      if (i.updated_at > lastUpdatedValue) {
        lastUpdatedValue = i.updated_at;
      }
    });
  }

	onMount(() => {
		window.addEventListener("resize", windowSizeUpdated);
		windowSizeUpdated();
	});	
</script>


  {#if stripeAccount}
    <div class="row mb-3">
      <div class="col-12 d-flex justify-content-end align-items-center mb-2">
        <div class="pl-2">
          <Button
            class="btn unelevatedCustom"
            color="secondary"
            variant="unelevated"
            disabled={downloading || loading}
            on:click={handleFetchButtonClicked}>
            <Label>
              <Icon
                role="button"
                class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing">
                sync
              </Icon>
              {$_('aggiorna')}
            </Label>
          </Button>
        </div>
        <div class="pl-2">
          <Button
            class="btn unelevatedCustom"
            variant="unelevated"
            disabled={downloading || loading || (Array.isArray(list) && list.length == 0) }
            on:click={handleExportButtonClicked}>
            <Label>
              <Icon
                role="button"
                class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing">
                file_download
              </Icon>
              {$_('esporta')}
            </Label>
          </Button>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end align-items-center">
        <i style="font-size: 0.85em;">{lastUpdatedValue == 0 ? `---` : $_('ultimo_aggiornamento') + ': ' + toHumanDate(lastUpdatedValue)}</i>
      </div>
    </div>
    {#if loading}
      <div class="d-flex position-relative pt-4">
        <Loading />
      </div>
    {:else}
      <DataTable table$aria-label="User list" style="width: 100%;">
        <Head>
          <Row>
            <Cell>Data</Cell>
            <Cell>Cliente</Cell>
            <Cell>Prodotto</Cell>
            <Cell>Descrizione</Cell>
            <Cell style="text-align: right;">Importo</Cell>
          </Row>
        </Head>
        <Body>
          {#each list as item (item.id)}
            <Row>
              <Cell>{toHumanDate(item.created)}</Cell>
              <Cell>{item.name ? item.name : `---`}</Cell>
              <Cell>{item.product_name ? item.product_name : `---`}</Cell>
              <Cell>{item.description != null ? $_(item.description) : `---`}</Cell>
              <Cell style="text-align:right;">{Number.parseFloat(item.amount).toFixed(2)} €</Cell>
            </Row>
          {/each}
        </Body>
      </DataTable>
    {/if}
  {:else}
    {#if !loading}
      <NoItemsToShow 
        biggerText={biggerTextConnect}
        buttonText={buttonTextConnect}
        icon={iconConnect}
        upperText={upperTextConnect}
        startConnectOnboarding={true}
      />
    {/if}
  {/if}
