<script>
  import { getExercises, getExercisesList, maxPage } from "./services/exercisesServices";
  import { hsLogin } from "./services/hubspot";
  import {
    jwt,
    user,
    exercises,
    oneSignalPlayerId,
    subscription,
    subscriptionInfo,
  } from "./lib/store";
  import { getSubscription, getSubscriptionInfo, isFeatureEnabled } from "./services/subscriptionServices";
  import { onDestroy, onMount, tick } from "svelte";
  import { Router, Route, navigate } from "svelte-routing";
  import { setupI18n, isLocaleLoaded } from "./services/i18n";
  import { updateLanguage } from "./services/userServices";
  import AdminLayout from "./components/Admin/AdminLayout.svelte";
  import AuthLayout from "./components/Auth/AuthLayout.svelte";
  import Calendar from "./components/Calendar/Calendar.svelte";
  import CompletedWorkouts from "./components/Team/Customer/Workouts/CompletedWorkouts.svelte";
  import CustomerCheckout from "./components/Team/Customer/Checkout/CheckoutLayout.svelte";
  import CustomerLayout from "./components/Team/Customer/CustomerLayout.svelte";
  import CustomerSubscriptionWrapper from "./components/Team/Customer/NewCustomerSubscription/CustomerSubscriptionWrapper.svelte";
  import DashboardLayout from "./components/Dashboard/DashboardLayout.svelte";
  import DockedChat from "./components/Chat/DockedChat.svelte";
  import ExercisesLayout from "./components/Exercises/ExercisesLayout.svelte";
  import ExerciseWrapper from "./components/Exercises/ExerciseWrapper.svelte";
  import FlowRegistrationLayout from "./components/Auth/FlowRegistration/FlowRegistrationLayout.svelte";
  import GroupLayout from "./components/Team/Group/GroupLayout.svelte";
  import History from "./components/Team/Customer/MedicalHistories/History.svelte";
  import Navbar from "./components/Header/Navbar.svelte";
  import NewCustomerWrapper from "./components/Team/Customer/NewCustomer/NewCustomerWrapper.svelte";
  import NewGroupWrapper from "./components/Team/Group/NewGroup/NewGroupWrapper.svelte";
  import NewHistoryLayout from "./components/Team/Customer/MedicalHistories/NewHistoryLayout.svelte";
  import NewPlaylistWrapper from "./components/Vods/Playlist/NewPlaylist/NewPlaylistWrapper.svelte";
  import NoteLayout from "./components/Team/Customer/Notes/NoteLayout.svelte";
  import NotFound from "./components/NotFound.svelte";
  import PrintTrainingCard from "./components/Team/Customer/Workouts/PrintTrainingCard.svelte";
  import ProductsLayout from "./components/Connect/Products/ProductsLayout.svelte";
  import ProductWrapper from "./components/Connect/Products/Detail/ProductWrapper.svelte";
  import PtInfoLayout from "./components/PtInfo/PtInfoLayout.svelte";
  import Redirect from "./components/ReusableLayouts/Redirect.svelte";
  // import ReferralCodeLayout from "./components/PtInfo/ReferralCodeLayout.svelte";
  import SubscriptionDialog from "./components/PtInfo/Billing/SubscriptionDialog.svelte";
  import TeamLayout from "./components/Team/TeamLayout.svelte";
  import TrainingCardLayout from "./components/TrainingCards/TrainingCard/TrainingCardLayout.svelte";
  import TrainingCardsLayout from "./components/TrainingCards/TrainingCardsLayout.svelte";
  import VodAssignmentWrapper from "./components/Vods/Vod/VodAssignmentWrapper.svelte";
  import VodsLayout from "./components/Vods/VodsLayout.svelte";
  import VodWrapper from "./components/Vods/Vod/VodWrapper.svelte";

  export let url = "";

  let navbarDisabled                = false;
  let navbarTabsDisabled            = false;
  let showExpiredSubscriptionDialog = false;
  let showNavbar                    = false;
  let state                         = 'trial_ended';

  $: if (!$isLocaleLoaded) {
    const lan = navigator.language;
    const sigle = lan.substring(0, 2);
    if (
      ($user !== undefined &&
        $user &&
        $user.language &&
        $user.language == "it") ||
      $user.language == "en"
    ) {
      setupI18n({ withLocale: $user.language });
    } else {
      if (sigle == "it" || sigle == "en") {
        setupI18n({ withLocale: sigle });
      } else {
        setupI18n({ withLocale: "it" });
      }
    }
  }

  $: inAuth = [
    "/login",
    "/register",
    "password_recover",
    "set_new_password",
  ].includes(window.location.pathname);

  $: routes = [
    {
      path: "/new-customer",
      component: NewCustomerWrapper,
    },
    {
      path: "/new-group",
      component: NewGroupWrapper,
    },
    {
      path: "/new-video",
      component: VodWrapper,
    },
    {
      path: "/new-playlist",
      component: NewPlaylistWrapper,
    },
    {
      path: "/vod-assignment",
      component: VodAssignmentWrapper,
    },
    {
      path: "/customer-subscription",
      component: CustomerSubscriptionWrapper,
    },
    {
      path: "/product",
      component: ProductWrapper,
    },
    {
      path: "/products",
      component: ProductsLayout,
    },
    // {
    //   path: "/team",
    //   component: TeamLayout,
    // },
    {
      path: "/home",
      component: DashboardLayout,
    },
    {
      path: "/calendar",
      component: Calendar,
    },
    {
      path: "/customer",
      component: CustomerLayout,
    },
    {
      path: "/group",
      component: GroupLayout,
    },
    {
      path: "/completed-workouts",
      component: CompletedWorkouts,
    },
    {
      path: "/print-training-card",
      component: PrintTrainingCard,
    },
    {
      path: "/new-training-card",
      component: TrainingCardLayout,
    },
    {
      path: "/training-card",
      component: TrainingCardLayout,
    },
    {
      path: "/training-cards",
      component: TrainingCardsLayout,
    },
    {
      path: "/exercises",
      component: ExercisesLayout,
    },
    {
      path: "/exercise",
      component: ExerciseWrapper,
    },
    {
      path: "/new-exercise",
      component: ExerciseWrapper,
    },
    {
      path: "/pt-info",
      component: PtInfoLayout,
    },
    {
      path: "/vods",
      component: VodsLayout,
    },
    {
      path: "/vod",
      component: VodWrapper,
    },
    {
      path: "/admin",
      component: $user.is_admin ? AdminLayout : Redirect,
    },
    // window.location.host != "trainmobile.revoo-app.com"
    //   ? {
    //       path: "/referral-code",
    //       component: ReferralCodeLayout,
    //     }
    //   : "",
  ];

  let protectedRoutes = [];

  $: if (!$jwt) {
    protectedRoutes = routes.map((r) => {
      const route = { ...r };
      route.component = Redirect;
      return route;
    });
    showExpiredSubscriptionDialog = false;
    navbarTabsDisabled = false;
  } else {
    hsLogin($user.email);
    showNavbar = true;
    protectedRoutes = [...routes];
  }

  $: (async () => {
    if (
      $jwt &&
      (!localStorage.getItem("exercises") ||
        $exercises.length == 0 ||
        $exercises.filter((e) => e.is_active).length === 0)
    ) {
      // Get base + pt exercises and save them in local storage (except for registration step)
      if (
        window.location.pathname !== "/register" &&
        window.location.pathname !== "/flow-registration"
      ) {
        // await getExercises(true, 2);
        await getExercisesList(true);
      }
    }
  })();

  const switchLanguage = async (lang) => {
    try {
      setupI18n({ withLocale: lang });
      await updateLanguage({
        language: lang,
      });
      console.log(lang);
      $user.language = lang;
      showNavbar = false;
      await tick();
      showNavbar = true;
      // Get base exercises + pt exercises and save them in local storage with locale language applied
      // getExercises(true, 2);
      getExercisesList(true);
      navigate("/team");
      window.location.reload();
    } catch (error) {
      console.log("error switching language", error);
    }
  };

  try {
    // One Signal | Save Player ID in local storage
    oneSignalPlayerId.set(
      new URLSearchParams(location.search).get("player_id")
    );
  } catch (error) {
    console.log(error);
  }

  $: {
    if ($subscriptionInfo.name === 'revoo') {
      // Subscription name REVOO (new)
      if ($subscriptionInfo.default_trial_product === true) {
        // Trial con prodotto di default ("REVOO | Trial")
        if ($subscriptionInfo.trial_over === true) {
          // Trial finito, mostrare SubscriptionDialog 
          navbarTabsDisabled = true;
          showExpiredSubscriptionDialog = true;
        }
      }
    }
  }

  onMount(async () => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement && document.activeElement.type === "number")
        document.activeElement.blur();
    });
    showExpiredSubscriptionDialog = false;
    navbarTabsDisabled = false;
    if ($jwt) {
      await getSubscriptionInfo();
      await getSubscription();
    }
  });

  onDestroy(() => {
    showExpiredSubscriptionDialog = false;
    navbarTabsDisabled = false;
  });
</script>

<style>
  .padded-bottom {
    padding-bottom: 8rem;
  }

  .padding-navbar {
    padding-top: 80px;
  }

  .main__content--blur {
    filter: blur(5px);
  }
  * :global(.expired-dialog .mdc-dialog .mdc-dialog__scrim) {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  @media (min-device-width: 767px) {
    * :global(.expired-dialog .mdc-dialog__surface) {
      width: 1040px !important;
      max-width: 90vw;
      min-height: 250px;
    }
  }

  @media (min-device-width: 280px) and (max-device-width: 767px) {
    .padding-navbar {
      padding-top: 54px;
    }
  }

  @media (max-width: 767px) {
    main {
      min-height: 100%;
    }
  }

  /* SWIPER */
  * :global(swiper-container) {
    /* width: 940px; */
		max-width: 100% !important;
    height: auto;
    padding: 20px 55px;
    --swiper-navigation-color: gray;
  }

  * :global(swiper-slide) {
    width: auto;
    height: auto;
  }

</style>

{#if $isLocaleLoaded}
  <main role="main">
    {#if showNavbar}
      <Navbar {navbarDisabled} {navbarTabsDisabled} />
    {/if}
    {#if showExpiredSubscriptionDialog}
      <SubscriptionDialog state={state} trigger={""} />
    {/if}
    <div
      class:pad-bottom-calendar={window.location.pathname == '/calendar'}
      class={`main__content ${showNavbar ? 'padding-navbar' : ''} ${inAuth ? '' : 'padded-bottom'} ${showExpiredSubscriptionDialog && navbarTabsDisabled ? `` : ``} h100`}>
      <Router {url}>
        {#each protectedRoutes as { component, path }}
          <Route
            {path}
            {component}
            bind:showNavbar
            onLocaleChanged={switchLanguage} />
        {/each}
        <Route path="/">
          <Redirect to="/team" />
        </Route>
        <Route path="/login">
          <AuthLayout component="login" bind:showNavbar />
        </Route>
        <Route path="/password_recover">
          <AuthLayout component="passRecover" bind:showNavbar />
        </Route>
        <Route path="/set_new_password">
          <AuthLayout component="setNewPassword" bind:showNavbar />
        </Route>
        <Route path="/register">
          <AuthLayout component="register" bind:showNavbar />
        </Route>
        <Route path="/flow-registration">
          <FlowRegistrationLayout
            component="firstStep"
            bind:showNavbar
            bind:navbarDisabled />
        </Route>
        <Route path="/team">
          <TeamLayout bind:showNavbar bind:navbarDisabled />
        </Route>
        <Route
          path="/new-history"
          component={NewHistoryLayout}
          bind:showNavbar />
        <Route
          path="/draft-history"
          component={NewHistoryLayout}
          bind:showNavbar />
        <Route path="/new-note" component={NoteLayout} />
        <Route path="/note" component={NoteLayout} />
        <Route path="/history" component={History} bind:showNavbar />
        <Route
          path="/customer-checkout"
          component={CustomerCheckout}
          bind:showNavbar />
        <Route component={NotFound} />
      </Router>
    </div>
    {#if $user.chat_enabled && isFeatureEnabled('chat', $subscription)}
      <DockedChat />
    {/if}
  </main>
{:else}
  <p>Loading...</p>
{/if}
