<script>
  import { onMount, tick } from "svelte";
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";
  import UsersTable from "./UsersTable.svelte";
  import DealLayout from "./DealLayout.svelte";
  import BillingLayout from "./BillingLayout.svelte";
  import Tabs from "../ReusableLayouts/Tabs.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";
  import {
    getUsers,
    getDeals,
    updateDeal,
    saveDealOrder,
    updateUser,
    sendEmails,
    deleteUsers,
  } from "../../services/adminServices";
  import { user } from "../../lib/store";
  import "./AdminLayout.scss";

  let component = "users";
  let activeTab = "users";
  let tabs = ["users", "deals", "billing"];
  let options = {
    users: UsersTable,
    deals: DealLayout,
    billing: BillingLayout,
  };

  let users = [];
  let deals = [];
  let dealsFetched = false;
  let dealsPrevOrder = [];

  let usersFetched = false;
  let per_page = 20;
  let page = 1;
  let userName = '';
  let totalPages;

  let props = {};
  $: props = { users, deals, totalPages, page };

  $: if (component == "deals") {
    if (!dealsFetched) fetchDeals();
  }

  $: if (!usersFetched) fetchUsers();

  let msgError;

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleActiveTabChanged = (e) => {
    component = [e.detail.tab];
  };

  const handleDealsReordered = (e) => {
    dealsPrevOrder = [...deals];
    deals = e.detail.deals;
  };

  const handleSaveDeal = async (e) => {
    const newDeal = e.detail.deal;
    try {
      const res = await updateDeal(newDeal);
      if (newDeal.id) {
        deals = deals.map((i) => (i.id == newDeal.id ? res : i));
      } else {
        deals = [...deals, res];
      }
    } catch (error) {
      console.log("handleUpdateDeal err", error);
      displayError();
    }
  };

  const handleSaveOrder = async () => {
    try {
      const order = [];
      for (let i = 0; i < deals.length; i++) {
        order.push({ id: deals[i].id, rank: i });
      }
      await saveDealOrder({ sorted_deals: JSON.stringify(order) });
    } catch (error) {
      console.log("handleUpdateDeal err", error);
      deals = dealsPrevOrder;
      displayError();
    }
  };

  const fetchDeals = async () => {
    try {
      const res = await getDeals();
      deals = res;
      dealsFetched = true;
    } catch (err) {
      console.log("getDeals err", err);
      displayError();
    }
  };

  const handleUpdateUser = async (e) => {
    try {
      await updateUser(e.detail.user);
      await tick();
      fetchUsers();
    } catch (err) {
      console.log("handleUpdateUser err", err);
      displayError();
    }
  };

  const handleSendEmails = async (e) => {
    try {
      const res = await sendEmails(e.detail.users);
    } catch (err) {
      console.log("handleSendEmails err", err);
      displayError();
    }
  };

  const handleDeleteUsers = async (e) => {
    try {
      const res = await deleteUsers(e.detail.users);
      console.log("res", res);
    } catch (err) {
      console.log("handleSendEmails err", err);
      displayError();
    }
  };

  const fetchUsers = async () => {
    try {
      users = [];
      const { data, last_page } = await getUsers(per_page, page, userName);
      totalPages = last_page;
      users = [...data];
      usersFetched = true;
    } catch (err) {
      console.log("fetchUsers err", err);
      displayError();
    }
  }

  const handleUpdateUsersPaging = async (e) => {
    if (e.detail.page) page = e.detail.page;
    await fetchUsers();
  };

  const handleUpdateUserSearch = async (e) => {
    userName = e.detail.search;
    await fetchUsers();
  };

  onMount(async () => {
    try {
      // aaconst res = await getUsers();
      // users = res;
    } catch (err) {
      console.log("get users err", err);
      displayError();
    }
  });
</script>

<Tabs
  {tabs}
  {activeTab}
  on:activeTabChanged={handleActiveTabChanged}
  tabNavigationEnabled={true} />

<div class="row no-gutters flexy">
  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
    <MsgError {msgError} />

    <svelte:component
      this={options[component]}
      {...props}
      on:saveDeal={handleSaveDeal}
      on:saveOrder={handleSaveOrder}
      on:dealsReordered={handleDealsReordered}
      on:updateUser={handleUpdateUser}
      on:sendEmails={handleSendEmails}
      on:deleteUsers={handleDeleteUsers}
      on:updateUsersPaging={handleUpdateUsersPaging}
      on:updateUserSearch={handleUpdateUserSearch} />
  </div>
</div>
