<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { customer } from "../../../../lib/store";
	import { navigate } from "svelte-routing";
	import { sendTrainingCardNotifications } from "../../../../services/trainingCardServices.js";
	import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
	import Dialog from "@smui/dialog";
	import IconButton from "@smui/icon-button";
	import LoadingInline from "../../../ReusableLayouts/LoadingInline.svelte";
	import SvelteTooltip from 'svelte-tooltip';

	export let customerId;
	export let index;
	export let isMobile;
	export let showMobileIcons = undefined;
	export let tc;

	const dispatch = createEventDispatcher();

	let confirmationDeleteDialog;
	let confirmationEditDialog;
	let confirmationRemoveFromGroupDialog;
	let customerToBeRemovedFromGroups;
	let dialogText = '';
	let groupsRemoving = [];
	let groupsRemovingNames = [];
	let sendingNotification = false;
	let showNotificationSent = false;
	let tcToBeDeleted = {};
	let tcToBeEdited = {};

	const handleDeleteButtonClicked = (tc) => {
		tcToBeDeleted = tc;
		confirmationDeleteDialog.open();
	};

	const checkExecutedWorkouts = () => {
		let workoutCount = 0;
		if (tc.workouts && tc.workouts.length > 0) {
			tc.workouts.map(w => {
				workoutCount += w.workout_count 
					? w.workout_count 
					: 0;
			})
		}
		return workoutCount > 0
			? true 
			: false;
	};

	const handleEditButtonClicked = (tc) => {
		// La finestra di dialogo viene mostrata se 1) il cliente ha spuntato la voce "parametri precedenti" e 2) ha eseguito workout dopo l'ultima modifica della scheda
		if ($customer.previous_volume && checkExecutedWorkouts()) {
			tcToBeEdited = tc;
			confirmationEditDialog.open();   
		} else {
			navigateToEditSection(tc);
		}
	};

	const handleNotifyButtonClicked = async () => {
		if (!showNotificationSent && !sendingNotification && tc.notification_status === 0) {
			sendingNotification = true;
			const res = await sendTrainingCardNotifications(tc);
			if (res.status == 400) {
				console.log('error handleNotifyButtonClicked', res);
				sendingNotification = false;
			} else {
				sendingNotification = false;
				showNotificationSent = true;
				setTimeout(() => {
					setNotificationStatus();
					showNotificationSent = false;
				}, 5000);
			}
		}
	};

	const setNotificationStatus = () => {
		tc.notification_status = 1;	
	}

	const navigateToEditSection = (tc) => {
		navigate('/training-card?tab=workouts&trainingCardId=' + tc.id + '&customerId=' + customerId, {
			state: {
				trainingCard: tc,
				customerId,
			},
		});
	}

	const handleRemoveFromGroupsButtonClicked = (tc, removingCustomer) => {
		customerToBeRemovedFromGroups = removingCustomer;
		groupsRemoving = tc.groups;
		dialogText = groupsRemovingNames.length > 1 
			? $_('sei_sicuro_di_voler_rimuovere_l_atleta_dai_gruppi_no_question') + ' '
			: $_('sei_sicuro_di_voler_rimuovere_l_atleta_dal_gruppo_no_question') + ' ';
		groupsRemoving.forEach((group, i) => {
			dialogText += group.name;
			if      ((i + 1) == (groupsRemoving.length)) dialogText += '?';
			else if ((i + 2) == (groupsRemoving.length)) dialogText += ' ' + $_('e') + ' ';
			else                                         dialogText += ', ';
		});
		confirmationRemoveFromGroupDialog.open();
	}
</script>

<style>
	* :global(.primary) {
		color: var(--primary);
	}
	* :global(.op-0) {
		opacity: 0 !important;
		cursor: auto;
	}
	.hide {
		display: none;
	}
	.actions > div {
		width: 35px;
	}
</style>

<Dialog bind:this={confirmationEditDialog}>
	<ConfirmationDialog
	  text={$_('sei_sicuro_di_voler_modificare_il_piano')}
	  confirmationButtonLabel={$_('modifica')}
	  on:closeDialog={(e) => {
		e.stopPropagation();
		confirmationEditDialog.close();
	  }}
	  on:onConfirmation={(e) => {
		e.stopPropagation();
		navigateToEditSection(tc);
		confirmationEditDialog.close();
	  }} />
</Dialog>

<Dialog bind:this={confirmationDeleteDialog}>
	<ConfirmationDialog
	  text={$_('sei_sicuro_di_voler_eliminare_il_piano')}
	  confirmationButtonLabel={$_('elimina')}
	  on:closeDialog={(e) => {
		e.stopPropagation();
		confirmationDeleteDialog.close();
	  }}
	  on:onConfirmation={(e) => {
		e.stopPropagation();
		dispatch('deleteTC', {
			id: tcToBeDeleted.id,
			is_active: tcToBeDeleted.is_active,
		});
		confirmationDeleteDialog.close();
	  }} />
</Dialog>

<Dialog bind:this={confirmationRemoveFromGroupDialog}>
	<ConfirmationDialog
	  text={dialogText}
	  confirmationButtonLabel={$_('rimuovi')}
	  on:closeDialog={(e) => {
		e.stopPropagation();
		confirmationRemoveFromGroupDialog.close();
	  }}
	  on:onConfirmation={(e) => {
		e.stopPropagation();
		dispatch('removeCustomerFromGroups', {
			customer: customerToBeRemovedFromGroups,
			groupsRemoving,
		});
		confirmationRemoveFromGroupDialog.close();
	  }} />
</Dialog>

{#if isMobile}
	<div class="col-3 col-xs-4 col-md-3 pr-0 bg__color flexy bar--icons justify-content-end"
		on:focusout={(e) => {
			if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
			showMobileIcons = undefined;
		}}>
		<div class="icon--action" class:primary={showMobileIcons == index} 
			on:click|stopPropagation={() => {
				(showMobileIcons === index 
					? !tc.is_assigned_to_group 
						? handleDeleteButtonClicked(tc) 
						: handleRemoveFromGroupsButtonClicked(tc, $customer) 
					: (showMobileIcons = index)
				)}}>
			<IconButton class="material-icons px-0" style="width: fit-content">
				{showMobileIcons == index 
					? !tc.is_assigned_to_group 
						? `delete` 
						: !tc.is_active_group_customer_relation 
							? `` 
							: `person_remove` 
					: 'more_vert'}
			</IconButton>
		</div>
		{#if tc.is_active && !tc.is_draft && !tc.is_assigned_to_group}
			{#if !sendingNotification}
				<div class="flexy">
					<div class="icon--action" id="notify-icon" class:hide={showMobileIcons != index}
						on:click|stopPropagation={() => {
							if ($customer.active && tc.is_active) {
								handleNotifyButtonClicked(tc);
							}
						}}>
						<SvelteTooltip tip="{showNotificationSent ? $_('notifica_inviata') : $_('invia_notifica_al_cliente')}" top >
							<IconButton disabled={!tc.is_active || tc.notification_status === 1} data-mobile-icon class="material-icons pr-1" style="width: fit-content">
								{showNotificationSent ? `done` : `notifications`}
							</IconButton>
						</SvelteTooltip>
					</div>
				</div>
			{:else}
				<SvelteTooltip tip="{$_('notifica_in_corso')}" top >
					<LoadingInline />
				</SvelteTooltip>
			{/if}
		{/if}
		<div class="flexy">
			{#if !tc.is_assigned_to_group}
				<div class="icon--action" id="edit-icon" class:hide={showMobileIcons != index}
					on:click|stopPropagation={() => {
						if ($customer.active && tc.is_active) {
							handleEditButtonClicked(tc);
						}
					}}>
					<IconButton data-mobile-icon class="material-icons pr-1" style="width: fit-content">
						edit
					</IconButton>
				</div>
			{/if}
		</div>
		<!-- <div>
			<IconButton class={`material-icons ${tc.workouts.length != 0 ? 'visible' : 'invisible'}`}>keyboard_arrow_down</IconButton>
		</div> -->
	</div>
{:else}
	<div class="actions col-3 col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
		{#if ($customer.active && tc.is_active)}
			<div 
				class="mr-2"
				on:click|preventDefault={
				!tc.is_assigned_to_group 
					? handleDeleteButtonClicked(tc) 
					: handleRemoveFromGroupsButtonClicked(tc, $customer)}>
				<SvelteTooltip tip="{!tc.is_assigned_to_group ? $_('elimina') : !tc.is_active_group_customer_relation ? `` : $_('rimuovi_dal_gruppo')}" top >
					<IconButton
						disabled={!tc.is_active || !$customer.active || !tc.is_active_group_customer_relation}
						class={`material-icons ${!tc.is_active || !$customer.active ? '' : 'primary'}`}>
						{!tc.is_assigned_to_group ? `delete` : !tc.is_active_group_customer_relation ? `` : `person_remove`}
					</IconButton>
				</SvelteTooltip>
			</div>
			{#if tc.is_active && !tc.is_draft && !tc.is_assigned_to_group}
				{#if !sendingNotification}
					<div class="mr-1" on:click|stopPropagation={handleNotifyButtonClicked(tc)}>
						<SvelteTooltip tip="{showNotificationSent || tc.notification_status === 1 ? $_('notifica_inviata') : $_('invia_notifica_al_cliente')}" top >
							<IconButton disabled={!tc.is_active || tc.notification_status === 1} class="material-icons">
								{showNotificationSent ? `done` : `notifications`}
							</IconButton>
						</SvelteTooltip>
					</div>
				{:else}
					<div class="px-2">
						<SvelteTooltip tip="{$_('notifica_in_corso')}" top >
							<LoadingInline />
						</SvelteTooltip>
					</div>
				{/if}
			{/if}
		{:else}
			<div
				class="mr-2" 
				on:click|stopPropagation>
				<SvelteTooltip tip="{!tc.is_assigned_to_group ? $_('elimina') : !tc.is_active_group_customer_relation ? `` : $_('rimuovi_dal_gruppo')}" top >
					<IconButton
						disabled={!tc.is_active || !$customer.active || !tc.is_active_group_customer_relation}
						class={`material-icons ${!tc.is_active || !$customer.active ? '' : 'primary'}`}>
						{!tc.is_assigned_to_group ? `delete` : `person_remove`}
					</IconButton>
				</SvelteTooltip>
			</div>
		{/if}
		
		{#if !tc.is_assigned_to_group}
			<div
				class="mr-1"
				on:click|stopPropagation={() => {
					if ($customer.active && tc.is_active && !tc.is_assigned_to_group) {
						handleEditButtonClicked(tc);
					}
				}}>
				<SvelteTooltip tip="{$_('modifica')}" top >
					<IconButton
						disabled={!tc.is_active || !$customer.active}
						class="material-icons">
						edit
					</IconButton>
				</SvelteTooltip>
			</div>
		{/if}
		{#if document.documentElement.clientWidth >= 960 && !isMobile}
			<div
				on:click|stopPropagation={() => {
					if ($customer.active && tc.is_active) dispatch('print', { tc });
				}}>
				<SvelteTooltip tip="{$_('stampa')}" top >
					<IconButton
						disabled={!tc.is_active || !$customer.active || tc.is_active_group_customer_relation === false}
						class="material-icons">
						print
					</IconButton>
				</SvelteTooltip>
			</div>
		{/if}
		<div class="d-none d-sm-block d-lg-block d-xl-block">
			<IconButton class={`material-icons ${tc.workouts.length != 0 ? 'visible' : 'invisible'}`}>keyboard_arrow_down</IconButton>
		</div>
		<!-- <IconButton class={`material-icons ${tc.workouts.length != 0 ? 'visible' : 'invisible'}`}>keyboard_arrow_down</IconButton> -->
	</div>
{/if}
