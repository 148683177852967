import moment from "moment";
import { _, locale } from "../services/i18n";

export const getDateFormatted = (date) => {
  const momentDate = moment(date);
  return locale == "it"
    ? momentDate.format("D MMMM YYYY")
    : momentDate.locale("it").format("D MMMM YYYY");
};

export const addImage = (file, id) => {
  const fileReader = new FileReader();

  if (file.type.match("image")) {
    fileReader.onload = function () {
      var img = document.createElement("img");
      img.id = id;
      img.src = fileReader.result;
      document.getElementById("output").innerHTML = "";
      document.getElementById("output").appendChild(img);
      this.bgBlack = true;
    };
    fileReader.readAsDataURL(file);
  }
};

export const addVideo = (file, id) => {
  const fileReader = new FileReader();
  fileReader.onload = function () {
    var blob = new Blob([fileReader.result], { type: file.type });
    var url = URL.createObjectURL(blob);
    var video = document.createElement("video");
    var timeupdate = function () {
      if (snapImage()) {
        video.removeEventListener("timeupdate", timeupdate);
        video.pause();
      }
    };
    video.addEventListener("loadeddata", function () {
      if (snapImage()) {
        video.removeEventListener("timeupdate", timeupdate);
      }
    });
    var snapImage = function () {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      var image = canvas.toDataURL();
      var success = image.length > 100000;
      if (success) {
        var img = document.createElement("img");
        img.src = image;
        document.getElementById("printVideo").innerHTML = "";
        document.getElementById("printVideo").appendChild(img);
        URL.revokeObjectURL(url);
      }
      return success;
    };
    video.addEventListener("timeupdate", timeupdate);
    video.preload = "metadata";
    video.src = url;
    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;
    video.play();
  };
  fileReader.readAsArrayBuffer(file);
};

export const getContactFunnels = (context) => {
  return [
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
    { value: "linkedin", label: "Linkedin" },
    { value: "gym", label: context("in_palestra") },
    { value: "wordOfMouth", label: context("passaparola") },
    { value: "other", label: context("altro") },
    { value: "dontKnow", label: context("non_so") },
  ];
};

export const getTypes = (context) => {
  return [
    { value: "onSite", label: context("dal_vivo") },
    { value: "remote", label: context("da_remoto") },
    { value: "onSiteAndRemote", label: context("dal_vivo_e_remoto") },
  ];
};

export const getGenderItems = (context) => {
  return [
    {
      text: context("maschio"),
      value: "male",
    },
    {
      text: context("femmina"),
      value: "female",
    },
    {
      text: context("nd"),
      value: "other",
    },
  ];
};

export const getWorkoutLocations = (context) => {
  return [
    { value: "1", text: context("palestra") },
    { value: "2", text: context("palestra_casalinga") },
    { value: "3", text: context("in_casa_con_poca_attrezzatura") },
    { value: "4", text: context("all_aperto") },
    { value: "5", text: context("altro") },
  ];
};

export const getLifestyles = (context) => {
  return [
    { value: "1", text: context("letargico") },
    { value: "2", text: context("sedentario") },
    { value: "3", text: context("poco_attivo") },
    { value: "4", text: context("attivo") },
    { value: "5", text: context("molto_attivo") },
    { value: "6", text: context("estremamente_attivo") },
  ];
};

export const getBloodGroups = () => {
  return [
    { value: "0-", text: "0 -" },
    { value: "0+", text: "0 +" },
    { value: "A-", text: "A -" },
    { value: "A+", text: "A +" },
    { value: "B-", text: "B -" },
    { value: "B+", text: "B +" },
    { value: "AB-", text: "AB -" },
    { value: "AB+", text: "AB +" },
  ];
};

export const getWorkoutFreqs = (context) => {
  return [
    { value: "1", text: context("1_volta_a_settimana") },
    { value: "2", text: context("2_volte_a_settimana") },
    { value: "3", text: context("3_volte_a_settimana") },
    { value: "4", text: context("4_volte_a_settimana") },
    { value: "5", text: context("5_volte_a_settimana") },
    { value: "6", text: context("6_volte_a_settimana") },
    { value: "7", text: context("7_volte_a_settimana") },
  ];
};

export const getGoals = (context) => {
  return [
    { value: "1", text: context("perdita_di_grasso") },
    { value: "2", text: context("definizione") },
    { value: "3", text: context("aumento_massa_muscolare") },
    { value: "4", text: context("preparazione_atletica") },
    { value: "5", text: context("riabilitazione") },
    { value: "6", text: context("correzione_posturale") },
    { value: "7", text: context("altro") },
  ];
};

export const getLevels = (context) => {
  return [
    { label: context("elementare"), value: "elementary" },
    { label: context("principiante"), value: "beginner" },
    { label: context("intermedio"), value: "intermediate" },
    { label: context("avanzato"), value: "advanced" },
  ];
};

export const getSubscriptionDurations = (context) => {
  return [
    { value: 0, label: context("giorni_")},
    { value: 1, label: context("settimane_")},
    { value: 2, label: context("mesi_")},
  ];
};

export const getIntervals = (context) => {
  return [
    { value: 'day',   label: context("giorni_")},
    { value: 'week',  label: context("settimane_")},
    { value: 'month', label: context("mesi_")},
    { value: 'year',  label: context("anni_")},
  ];
};

export const getPriceOptions = (context) => {
  return [
    { value: 0, label: context("mese")},
    { value: 5, label: context("sei_settimane")},
    { value: 1, label: context("tre_mesi")},
    { value: 2, label: context("sei_mesi")},
    { value: 3, label: context("anno")},
  ];
};

export const getPaymentTypes = (context) => {
  return [
    { value: 0, label: context("abbonamento")},
    { value: 1, label: context("acquisto_unico")},
  ];
};

export const getCombinations = (context) => {
  return [
    { text: context("singolo"),          value: "normal"        },
    { text: context("superset"),         value: "superset"      },
    { text: context("circuito"),         value: "circuit"       },
    { text: context("circuito_a_tempo"), value: "time_circuit", },
    { text: context("emom"),             value: "emom"          },
  ];  
};

export const getRepsItems = (context) => {
  if (env.environment === 'local' || env.environment === 'dev') {
    return [
      { text: context("reps"),  value: "reps"     },
      { text: context("time"),  value: "time"     },
      { text: "Kcal",           value: "kcal"     },
      { text: "Km",             value: "km"       },
      { text: context("metri"), value: "meters"   },
    ];
  } else {
    return [
      { text: context("reps"),  value: "reps"     },
      { text: context("time"),  value: "time"     },
    ];
  }
};

export const getRepsFormatItems = (context) => {
  if (env.environment === 'local' || env.environment === 'dev') {
    return [
      { text: "---",               value: "standard" },
      { text: "Max",               value: "max"      },
      { text: context("per_lato"), value: "per_side" },
      { text: "Range",             value: "range"    },
    ];
  } else {
    return [
      { text: "---",               value: "standard" },
      { text: "Max",               value: "max"      },
    ];
  }
};

export const getWeightItems = (context) => {
  if (env.environment === 'local' || env.environment === 'dev') {
    return [
      { text: "Kg",               value: "kg"     },
      { text: "Buffer",           value: "buffer" },
      { text: "Km/h",             value: "km/h"   },
      { text: context("livello"), value: "level"  },
      { text: "m/s",              value: "m/s"    },
      { text: "Min/km",           value: "min/km" },
      { text: "RM",               value: "rm"     },
      { text: "RPE",              value: "rpe"    },
      { text: "Watt",             value: "watt"   },
    ];
  } else {
    return [
      { text: "Kg",               value: "kg"     },
      { text: "RM",               value: "rm"     },
      { text: "RPE",              value: "rpe"    },
    ];
  }

};

export const getWeightFormatItems = (context) => {
  if (env.environment === 'local' || env.environment === 'dev') {
    return [
      { text: "---",   value: "standard" },
      { text: "Max",   value: "max"      },
      { text: "%",     value: "%"        },
      { text: "Range", value: "range"    },
    ];
  } else {
    return [
      { text: "---",   value: "standard" },
      { text: "Max",   value: "max"      },
      { text: "%",     value: "%"        },
    ];
  }
};


export const fieldsForGooglePlace = [
  "address_components",
  "adr_address",
  "formatted_address",
  "geometry",
  "icon",
  "name",
  "place_id",
  "type",
  "utc_offset_minutes",
];

export const fancyTimeFormat = (time) => {
  let minutes = Math.floor(time / 60);
  let seconds = time - minutes * 60;
  let hours = Math.floor(time / 3600);
  let totTime = time - hours * 3600;
  const str_pad_left = (string, pad, length) =>
    (new Array(length + 1).join(pad) + string).slice(-length);
  return str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
};

export const convertPhotos = async (photos) => {
  try {
    const getBase64 = (newFile) =>
      new Promise((resolve, reject) => {
        try {
          const fileReader = new FileReader();
          fileReader.onload = (_) =>
            resolve(fileReader.result.replace(/^data:image.+;base64,/, ""));
          fileReader.readAsDataURL(newFile);
        } catch (error) {
          reject(error);
        }
      });

    const promises = [];
    for (const [key, value] of Object.entries(photos)) {
      if (value == null) continue;
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            const base64 = await getBase64(value);
            resolve({ [key]: base64 });
          } catch (error) {
            reject(error);
          }
        })
      );
    }
    const res = await Promise.all(promises);
    return res;
  } catch (error) {
    throw error;
  }
};

export const generateUID = (length = 8) => {
  return window
    .btoa(
      Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2)))
        .map((b) => String.fromCharCode(b))
        .join("")
    )
    .replace(/[+/]/g, "")
    .substring(0, length);
};

export const assignSortIds = (workouts) => {
  const result = JSON.parse(JSON.stringify(workouts));
  for (const workout of result) {
    workout.sortId = generateUID();
    for (const comb of workout.combinations) {
      comb.sortId = generateUID();
      for (const ex of comb.exercises) {
        ex.sortId = generateUID();
      }
    }
  }
  return result;
};

export const assignIdAndSetStrings = (workouts) => {
  const result = JSON.parse(JSON.stringify(workouts));
  for (const workout of result) {
    for (let key in workout) {
      if (!workout[key]) workout[key] = "";
    }
    workout.sortId = workout.sortId || generateUID();
    for (const comb of workout.combinations) {
      for (let key in comb) {
        if (!comb[key]) comb[key] = "";
      }
      comb.sortId = comb.sortId || generateUID();
      for (const ex of comb.exercises) {
        for (let key in ex) {
          if (!ex[key]) ex[key] = "";
        }
        for (const detail of ex.details) {
          for (let k in detail) {
            if (!detail[k]) detail[k] = "";
            if (k == "weight" || k == "reps") {
              detail[k].type = detail[k].type ? detail[k].type : "";
              detail[k].value = detail[k].value ? detail[k].value : "";
              if (detail[k].value == 9999) {
                detail[k].value = "";
                detail[k].format = "max";
              }
            }
          }
        }
        ex.sortId = ex.sortId || generateUID();
      }
    }
  }
  return result;
};
export const reassignCombinationSortIds = (combination) => {
  // const newCombination = Object.assign({}, combination); → not working, children are copied as reference
  const newCombination = JSON.parse(JSON.stringify(combination));
  newCombination.sortId = generateUID();
  for (const ex of newCombination.exercises) {
    ex.sortId = generateUID();
  }
  return newCombination;
};

export const getCombinationString = (context, value) => {
  const combinationsMap = {
    superset: context("superset"),
    normal: context("singolo"),
    circuit: context("circuito"),
    time_circuit: context("circuito_a_tempo"),
    emom: context("emom"),
  }
  return combinationsMap[value];
};

export const removeWorkoutIds = (workout) => {
  // const newWorkout = Object.assign({}, workout); → not working, children are copied as reference
  const newWorkout = JSON.parse(JSON.stringify(workout));
  delete newWorkout.workout_id;
  delete newWorkout.sortId;
  // newWorkout.combinations.forEach((comb) => removeCombinationIds(comb));
  const cleanWorkout = removeCombinationAndExIds(newWorkout)
  return cleanWorkout;
};

export const removeCombinationIds = (comb) => {
  // const newComb = Object.assign({}, comb); → not working, children are copied as reference
  const newComb = JSON.parse(JSON.stringify(comb));
  delete newComb.superset_id;
  delete newComb.sortId;
  newComb.exercises.forEach((ex) => {
    delete ex.code_id;
    delete ex.sortId;
  });

  return newComb;
};

const removeCombinationAndExIds = (woWorkout) => {
  woWorkout.combinations.map(comb => {
    delete comb.sortId;
    delete comb.superset_id;
    comb.exercises.map(ex => {
      delete ex.code_id;
      delete ex.sortId;
    });
  });
  return woWorkout;
};

export const copyToClipboard = (text) => {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  el.setSelectionRange(0, 99999); //for mobile
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const phoneNumberValid = (phone, isIt) =>
  isIt
    ? /^\d+$/.test(phone) && (phone.length == 9 || phone.length == 10)
    : true;


export const addDaysToYmdDate = (date, days) => {
  let startDate = new Date(date);
  let result = new Date(date);
  result.setDate(startDate.getDate() + days);
  return new Date(result).toISOString().split('T')[0];
}

export const dateTodayDifferenceInDays = (date) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  return dateDifference(today, date);
}

export const dateDifference = (date1, date2) => {
  const date_1 = new Date(date1);
  const date_2 = new Date(date2);
  const diffTime = Math.ceil(date_2 - date_1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
}