import { POST, GET, DELETE } from "../lib/http";
import { get } from "svelte/store";
import { calendar } from "../lib/mockupCalendarData";

const commonHeaders = {
    ["Auth"]: "REVOO2019",
    ["Access-Control-Allow-Origin"]: "*",
    ["Accept"]: "application/json",
};

export const addEvent = async (event) => {
    try {
        const res = await POST(`/v2/store_calendar_event`, {
            headers: { ...commonHeaders },
            body: event,
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const generateXtraLinks = async (event) => {
    try {
        const res = await POST(`/v2/generate_xtra_links`, {
            headers: { ...commonHeaders },
            body: event,
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const getEvents = async (queryString) => {
    try {
        let path = "/v2/get_calendar_events";
        if (queryString) {
            let qs = Object.entries(queryString);
            if (qs.length > 0) {
                path += "?";
                for (const [key, value] of qs) {
                    path += `${key}=${value}&`
                }
                path = path.substring(0, path.length - 1);
            }
        }
        const res = await GET(path, {
            headers: { ...commonHeaders }
        });
        return res;
    } catch (err) {
        throw err;
    }
};

export const getEventsCustomer = async (id, queryString) => {
    try {
        let path = `/v2/get_calendar_events/customer/${id}`;
        if (queryString) {
            let qs = Object.entries(queryString);
            if (qs.length > 0) {
                path += "?";
                for (const [key, value] of qs) {
                    if (key != "customer")
                        path += `${key}=${value}&`
                }
                path = path.substring(0, path.length - 1);
            }
        }
        const res = await GET(path, {
            headers: { ...commonHeaders }
        });
        return res;
    } catch (err) {
        throw err;
    }
};

export const getEvent = async (id) => {
    try {
        // return get(calendar)
        const res = await GET(`/v2/get_calendar_event/${id}`, {
            headers: { ...commonHeaders }
        });
        return res;
    } catch (err) {
        throw err;
    }
};

export const deleteEvent = async (id) => {
    try {
        const res = await DELETE(`/v2/delete_calendar_event/${id}`, {
            headers: { ...commonHeaders }
        });
        return res;
    } catch (err) {
        throw err;
    }
};

export const getCustomersList = async () => {
    try {
        const res = await GET(`/v2/get_customers_list`, {
            headers: { ...commonHeaders }
        });
        return res;
    } catch (err) {
        throw err;
    }
};

export const getGroupsList = async () => {
    try {
        const res = await GET(`/v2/get_at_groups_list`, {
            headers: { ...commonHeaders }
        });
        return res;
    } catch (err) {
        throw err;
    }
};

export const hasCustomApp = async () => {
    try {
        const res = await GET(`/v2/has_custom_app`, {
            headers: { ...commonHeaders }
        });
        return res;
    } catch (err) {
        throw err;
    }
};