<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import {
    getBloodGroups,
    getWorkoutLocations,
    getLifestyles,
    getWorkoutFreqs,
    getGoals,
  } from "../../../../lib/utils";
  import { navigate } from "svelte-routing";
  import Button, { Label, Icon } from "@smui/button";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import Checkbox from "@smui/checkbox";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import List, { Item, Meta } from "@smui/list";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import Select, { Option } from "@smui/select";
  import Textfield from "@smui/textfield";
  
  export let active;
  export let blood_group = "";
  export let customerId;
  export let goal = "";
  export let goal_description = "";
  export let height = "";
  export let history;
  export let job = "";
  export let lifestyle = "";
  export let sport = "";
  export let weight = "";
  export let workout_freq = "";
  export let workout_location = [];

  const dispatch = createEventDispatcher();
  
  let bloodGroups = getBloodGroups($_);
  let exitDialog;
  let formInvalid = false;
  let goalsItems = getGoals($_);
  let heightInvalid = false;
  let lifestyleItems = getLifestyles($_);
  let msgError;
  let weightInvalid = false;
  let workoutFreqItems = getWorkoutFreqs($_);
  let workoutLocations = getWorkoutLocations($_);

  $: formInvalid = !active || heightInvalid || weightInvalid;

  $: if (weight) {
    const int = parseFloat(weight);
    weightInvalid = int < 0 || int > 999;
    if (isNaN(weight)) {
      weight = "";
    }
  }
  $: if (height) {
    const int = parseFloat(height);
    heightInvalid = int < 50 || int > 300;
    if (isNaN(height)) {
      height = "";
    }
  }

  const updateHistory = () => {
    dispatch("updateHistory", {
      history: {
        weight: weight ? parseFloat(weight).toFixed(2) : "",
        height: height ? parseFloat(height).toFixed(1) : "",
        blood_group,
        job,
        workout_location,
        sport,
        workout_freq,
        goal,
        goal_description,
        lifestyle,
        is_draft: true,
      },
    });
  };

  const onGoForth = () => {
    console.log("weight forth", weight);
    dispatch("goForth", {
      history: {
        weight: weight ? parseFloat(weight).toFixed(2) : "",
        height: height ? parseFloat(height).toFixed(1) : "",
        blood_group,
        job,
        workout_location,
        sport,
        workout_freq,
        goal,
        goal_description,
        lifestyle,
        is_draft: true,
      },
      tab: $_("fisica"),
    });
  };
</script>

<style>
  .label {
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
    text-align: end;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.textUppercase) {
    text-transform: uppercase;
  }
  .buttonTextfield {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    height: 56px;
    position: relative;
    text-align: left;
  }
  .buttonTextfield > span {
    align-items: center;
    bottom: 0;
    display: inline-block;
    display: inline-flex;
    height: 100%;
    left: 0;
    padding: 12px 52px 12px 16px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* * :global(.mdc-select) {
    width: 33.5rem;
    max-width: 100%;
  }
  
  * :global(.mdc-select.mdc-menu-surface) {
    width: 33.5rem;
    max-width: 100%;
  } */

  * :global(.mdc-text-field__icon--trailing.customText) {
    opacity: 0.5;
    color: #212121;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
</style>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={() => navigate(`/customer?tab=histories`, {
        state: { customerId },
      })} />
</Dialog>

<div class="py-3">
  <div class="row">
    <div class="col-4 label">{$_('peso_attuale')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        withTrailingIcon
        disabled={!active}
        class={`textbox w50 my-2 ${weightInvalid ? 'mdc-text-field--invalid' : ''}`}
        bind:value={weight}
        type="number"
        input$step="0.01">
        <Icon
          class="mdc-text-field__icon mdc-text-field__icon--trailing customText">
          {$_('kg')}
        </Icon>
      </Textfield>
      {#if weightInvalid}
        <FormMessage>{$_('il_peso_inserito_non_è_valido')}</FormMessage>
      {/if}
    </div>
    <div class="col-4 label">{$_('altezza')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        withTrailingIcon
        disabled={!active}
        class={`textbox w50 my-2 ${heightInvalid ? 'mdc-text-field--invalid' : ''}`}
        bind:value={height}
        type="number"
        input$step="0.1">
        <Icon
          class="mdc-text-field__icon mdc-text-field__icon--trailing customText">
          {$_('cm')}
        </Icon>
      </Textfield>
      {#if heightInvalid}
        <FormMessage>{$_('altezza_inserita_non_è_valida')}</FormMessage>
      {/if}
    </div>
    <div class="col-4 label">{$_('gruppo_sanguigno')}</div>
    <div class="col-8">
      <Select
        disabled={!active}
        enhanced
        variant="outlined"
        class="w50 mb-2"
        label=""
        bind:value={blood_group}>
        {#each bloodGroups as { value, text }}
          <Option {value} selected={blood_group === value}>{text}</Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label">{$_('professione')}</div>
    <div class="col-8">
      <Textfield
        disabled={!active}
        textarea
        input$maxlength="2000"
        class={`fullWidth my-2`}
        bind:value={job}>
        <CharacterCounter>{`${job.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('luogo_di_allenamento')}</div>
    <div class="col-8">
      <div class="dropdown">
        <button
          class="customButtom buttonTextfield w100 mt-2"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
          <span>
            {workoutLocations
              .filter((i) => workout_location.includes(i.value))
              .map(({ text }) => ' ' + text)}
          </span>
          <Icon class="mdc-select__dropdown-icon" />
        </button>

        <div
          class="dropdown-menu w100"
          aria-labelledby="dropdownMenuButton"
          on:click={(e) => e.stopPropagation()}>
          <List checklist class="list w100">
            {#each workoutLocations as item}
              <Item class="w100">
                <Meta class="ml-0">
                  <Checkbox bind:group={workout_location} value={item.value} />
                </Meta>
                <Label class="mb-0">{item.text}</Label>
              </Item>
            {/each}
          </List>
        </div>
      </div>
    </div>
    <div class="col-4 label">{$_('attivita_fisica')}</div>
    <div class="col-8 pt-1">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={sport}
        label=""
        disabled={!active}>
        <CharacterCounter>{`${sport.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('frequenza_settimanale_di_allenamento')}</div>
    <div class="col-8">
      <Select
        disabled={!active}
        enhanced
        variant="outlined"
        class="w100 mb-2"
        label=""
        bind:value={workout_freq}>
        {#each workoutFreqItems as { value, text }}
          <Option {value} selected={workout_freq === value}>{text}</Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label">{$_('obiettivo')}</div>
    <div class="col-8">
      <Select
        disabled={!active}
        enhanced
        variant="outlined"
        class="w100"
        label=""
        bind:value={goal}>
        {#each goalsItems as { value, text }}
          <Option {value} selected={goal === value}>{text}</Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label">{$_('descrivi_obiettivo')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={goal_description}
        label="">
        <CharacterCounter>
          {`${goal_description.length} / 2000`}
        </CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('stile_di_vita')}</div>
    <div class="col-8">
      <Select
        disabled={!active}
        enhanced
        variant="outlined"
        class="w100 mb-2"
        label=""
        bind:value={lifestyle}>
        {#each lifestyleItems as { value, text }}
          <Option {value} selected={lifestyle === value}>{text}</Option>
        {/each}
      </Select>
    </div>
  </div>
  <hr />

  <MsgError {msgError} />

  <div class="row pushToEnd">
    <Button class="btn" on:click={() => exitDialog.open()} disabled={!active}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-3"
      variant="outlined"
      color="secondary"
      on:click={updateHistory}
      disabled={formInvalid}>
      <Label>{$_('salva_come_bozza')}</Label>
    </Button>
    <Button
      class="btn ml-3 mr-3"
      variant="unelevated"
      on:click={onGoForth}
      disabled={formInvalid}>
      <Label class="textUppercase">{$_('avanti')}</Label>
    </Button>
  </div>
</div>
