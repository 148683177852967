<script>
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { generateUID } from "../../../lib/utils";
  import { Icon } from "@smui/common";
  import { isFeatureEnabled } from "../../../services/subscriptionServices";
  import { navigate } from "svelte-routing";
  import { saveVodInfo, handleVodAfterUpload, getVodTags, deleteVodTag, createVodTag } from "../../../services/vodsServices";
  import { vodTags, subscription, userData } from "../../../lib/store";
  import * as tus from "tus-js-client";
  import Button, { Label } from "@smui/button";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import Chip, { Set, Text } from "@smui/chips";
  import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import IconButton from "@smui/icon-button";
  import MenuSurface from "@smui/menu-surface";
  import Textfield from "@smui/textfield";
  import UploadingDialog from "./Uploaders/UploadingDialog.svelte";
  import VodImageUploader from "./Uploaders/VodImageUploader.svelte";
  import VodVideoUploader from "./Uploaders/VodVideoUploader.svelte";
  import SvelteTooltip from "svelte-tooltip";
  
  const jq = window.$;
  const dispatch = createEventDispatcher();

  export let exitDialogOpened = undefined;
  export let triggerCloseImageDialog;
  export let triggerCloseVideo;
  export let vod;

  let customChips;
  let edit = false;
  let exitDialog;
  let filteredVodTags;
  let formDirty = false;
  let formSurface;
  let formValid = false;
  let infoDialog;
  let initialVod = Object.assign({}, vod);
  let inputId = generateUID();
  let linkInvalid = false;
  let msgError;
  let nameInvalid = false;
  let newVodTag = "";
  let openUploadingDialog = false;
  let uploadingPercentage = null;
  let uploadStarted = false;
  let uploadStatus = '';
  let uploadUrl = "";
  let vodLink = "";

  $: filterVods(newVodTag);
  $: formValid = vod.name && vod.name.length > 2 && (vod.video != undefined || vod.vimeo_id != "") && !nameInvalid && !linkInvalid;
  $: if (exitDialogOpened) {
    handleGoBack();
  }

  $: if (!isFeatureEnabled('vods', $subscription)) {
    dispatch('showSubscriptionDialog');
  }

  const handleImageAdded = (e) => {
    formDirty = true;
    vod.image = e.detail.img;
  };

  const handleItemSelected = (e) => {
    formDirty = true;
    vod.video = e.detail.video;
  };

  const handleItemRemoved = (e) => {
    formDirty = true;
    vod.video = undefined;
  };

  const filterVods = (newVodTag) => {
    filteredVodTags = $vodTags.filter((tag) =>
      tag.name.toLowerCase().includes(newVodTag.toLowerCase().trim())
    );
  };

  const addChip = (chip) => {
    if (vod.tags.length >= 1) return;
    vod.tags = [...vod.tags, chip];
    //filteredVodTags = filteredVodTags.filter((i) => i != chip);
    cleanTags();
    newVodTag = "";
    formDirty = true;
  };

  // Clean tags removing selected ones (already applied) from filtered ones
  const cleanTags = () => {
    filteredVodTags = $vodTags;
    console.log('filteredVodTags', filteredVodTags);
    filteredVodTags = filteredVodTags.filter(
      x => !vod.tags.some(y => x.id === y.id)
    );
  }

  const removeTagFromSelected = (tag) => {
    vod.tags = vod.tags.filter((i) => i != tag);
    filteredVodTags = [...filteredVodTags, tag];
    filteredVodTags = filteredVodTags.sort((a, b) => a.name.localeCompare(b.name));
    formDirty = true;
    cleanTags();
  };

  const destroyTag = async (tag) => {
    try {
      const res = await deleteVodTag(tag.id);
      vodTags.set($vodTags.filter((i) => i.id != tag.id));
      filteredVodTags = filteredVodTags.filter((i) => i.id != tag.id);
    } catch (error) {
      console.log("destroyTag err", error);
      displayError();
    }
  };

  const submitNewTag = async () => {
    try {
      if (
        filteredVodTags.some((i) => i.name.toLowerCase() === newVodTag.toLowerCase().trim())
      ) {
        addChip(
          filteredVodTags.find(
            (i) => i.name.toLowerCase() === newVodTag.toLowerCase().trim()
          )
        );
      } else {
        const res = await createVodTag(newVodTag.trim());
        addChip(res.find((i) => i.name === newVodTag.trim()));
        newVodTag = "";
        // filteredVodTags = filteredVodTags.filter(
        //   (i) => !vod.tags.includes(i)
        // );
        await tick();
        vodTags.set(res);
        cleanTags();
      }
      formDirty = true;
    } catch (error) {
      console.log("submitNewTag err", error);
      displayError();
    }
  };
  
  const onSubmit = async () => {
    if (!isFeatureEnabled('vods', $subscription)) {
      dispatch('showSubscriptionDialog');
      return;
    }

    const body = {
      name        : vod.name,
      size        : vod.video ? vod.video.size : vod.size,
      tags        : JSON.stringify(vod.tags),
      new_video   : vod.video ? true : false,
      description : vod.description,
    };

    if (vod.image) {
      body.image = vod.image;
    }

    // Edit Vod or Store a new Vod
    if (vod.id) {
      // EDIT VOD
      body.id = vod.id;
      // Edit vod data uploading a new video on vimeo
      if (vod.video) { 
        try {
          formDirty = false;
          uploadStatus = 'Inizializzazione';
          handleOpenUploadingDialog();
          getUploadUrl(body)
          .then(() => {
            uploadStatus = 'Preparazione upload...';
            uploadToVimeo(uploadUrl);

          });
        } catch (err) {
          console.log("edit vod error", err);
          displayError(err);
        }
      } 
      // Edit vod data without uploading new video
      else {
        try {
          formDirty = false;
          await saveVodInfo(body);
          navigate("/vods?tab=video");
        } catch (err) {
          console.log("edit vod error", err);
          displayError(err);
        }
      }
    } 
    else {
      // STORE NEW VOD
      try {
        formDirty = false;
        uploadStatus = 'Inizializzazione';
        handleOpenUploadingDialog();
        getUploadUrl(body)
          .then(() => {
            uploadStatus = 'Preparazione upload';
            uploadToVimeo(uploadUrl);
          });
      } catch (err) {
        console.log("submit vod error", err);
        displayError(err);
      }
    }
  };

  const getUploadUrl = async (body) => {
    const res = await saveVodInfo(body);
    uploadUrl = res.upload_link;
    vodLink   = res.video_link;
    vod.id    = res.vod_id;
  }

  const uploadToVimeo = async () => {
    uploadingPercentage = null;
    uploadStarted = true;
    var upload = new tus.Upload(vod.video, {
        uploadUrl: uploadUrl,
        onError: function(error) {
            uploadStatus = 'Error:' + error;
            console.log("Failed because: " + error)
        },
        onProgress: function(bytesUploaded, bytesTotal) {
            var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
            uploadStatus = 'Uploading';
            uploadingPercentage = percentage;
            console.log(bytesUploaded, bytesTotal, percentage + "%")
        },
        onSuccess: async () => {
            uploadStatus = 'Upload completato';
            console.log("Download %s from %s", upload.file.name, upload.url)
            handleVodAfterUpload({vod_id: vod.id});
            await tick();
            setTimeout(() => {
              navigate("/vods?tab=video");
            }, 1500)
        }
      })
      // Start the upload
      upload.start()
  }

  const displayError = (err) => {
    try {
      switch(err.message.name[0].trim().toLowerCase()) {
        case "the name must be at least 3 characters." : msgError = $_("il_nome_deve_essere_di_almeno_3_caratteri"); break;
        default : msgError = $_("si_è_verificato_un_errore");
      }  
    } catch {
      msgError = $_("si_è_verificato_un_errore");
    }
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const resetVod = () => {
    vod = Object.assign({}, initialVod);
    newVodTag = "";
  };

  const handleGoBack = () => {
    if (formDirty) exitDialog.open();
    else dispatch("goBack", {});
  };

  const closeExitDialog = () => {
    exitDialogOpened = false;
    exitDialog.close();
    dispatch("closeExitDialog");
  };

  const handleConfirmGoBack = () => {
    resetVod();
    exitDialog.close();
    formDirty = false;
    dispatch("goBack", {});
  };

  const handleOpenUploadingDialog = (e) => {
    openUploadingDialog = true;
  };

  const handleCloseUploadingDialog = (e) => {
    openUploadingDialog = false;
  };

  const handleImageError = () => {
    msgError = $_("la_dimensione_massima_consentita_è_di", {
      values: {
        dimension: 16,
      },
    });
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  $: console.log('vod', vod);

  onMount(async () => {
    customChips = document.getElementsByClassName("customChip");
    jq("#dropdown-edit").on("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
    });

    jq(`#dropdownMenuButton-${inputId}`).on("click", () => {
      formSurface.setOpen(true)
    });

    jq(`#dropdownMenuButton-${inputId}`).on("keyup", function () {
      formSurface.setOpen(true);
    });

    await getVodTags();
  });

</script>

<Dialog class="no-click-backdrop" bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={closeExitDialog}
    on:onConfirmation={handleConfirmGoBack} />
</Dialog>

<Dialog class="no-click-backdrop" bind:this={infoDialog}>
  <div class="close-icon-wrapper">
    <IconButton class="material-icons" on:click={() => infoDialog.close()}>
      close
    </IconButton>
  </div>
  <div class="container fluid text-center">
    <p class="p-2 my-2">
      {@html $_('qui_puoi_inserire_link_video')}
    </p>
  </div>
</Dialog>

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

{#if vod}
  <div class="container fluid py-4">
    <div class="row no-gutters">
      <div class="col-4 mr-4 label">{$_('nome_video')}</div>
      <div class="col-7">
        <Textfield
          variant="outlined"
          input$maxlength="100"
          on:change={() => (formDirty = true)}
          bind:value={vod.name}
          class="fullWidth my-2"
          type="text" />
        {#if vod.name.length > 0 && vod.name.length < 3}
          <FormMessage>
            {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
          </FormMessage>
        {/if}
      </div>
      {#if $userData.show_vod_categories}
        <div class="col-4 mr-4 label">{$_('categoria')}</div>
        <div class="col-7">
          <div class="dropdown mt-3">
            <Textfield
              variant="outlined"
              label=""
              bind:value={newVodTag}
              on:change={() => (formDirty = true)}
              class="w100 textfield--large tags-textfield"
              withTrailingIcon
              on:keydown={(e) => {
                if (e.key === 'Enter') submitNewTag();
              }}
              input$maxlength="191"
              id={`dropdownMenuButton-${inputId}`}
              aria-haspopup="true"
              aria-expanded="false">
              <div style="width: 85%;">
                {#if vod.tags && vod.tags.length > 0}
                  {#each vod.tags as tag}
                    <Button class="btn customChip">
                      <Label>
                        {tag.name}
                        <Icon
                          class="material-icons"
                          on:click={() => removeTagFromSelected(tag)}>
                          close
                        </Icon>
                      </Label>
                    </Button>
                  {/each}
                {/if}
              </div>
              <Icon
                tabindex="1"
                on:click={() => cleanTags()}
                class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing">
                keyboard_arrow_down
              </Icon>
            </Textfield>
            <MenuSurface
              class="menuSurfaceCustom"
              bind:this={formSurface}
              anchorCorner="BOTTOM_LEFT">
              <Set chips={filteredVodTags} let:chip>
                <Chip tabindex="0" on:click={() => addChip(chip)}>
                  <Text>{chip.name}</Text>
                  {#if edit && chip.base !== 1}
                    <div on:click|stopPropagation={() => destroyTag(chip)}>
                      <Icon class="material-icons" trailing tabindex="0">
                        cancel
                      </Icon>
                    </div>
                  {/if}
                </Chip>
              </Set>
              <div class="pushToEnd" style="display: flex;">
                {#if !edit}
                  <Icon
                    id="dropdown-edit"
                    role="button"
                    tabindex="1"
                    class="material-icons"
                    on:click={() => (edit = !edit)}>
                    edit
                  </Icon>
                {:else}
                  <span
                    class="primary cursorPointer"
                    on:click={() => (edit = !edit)}>
                    Fatto
                  </span>
                {/if}
              </div>
            </MenuSurface>
          </div>
        </div>
      {/if}
      <div class="col-4 mr-4 label">{$_('descrizione')}</div>
      <div class="col-7">
        <Textfield
          textarea
          class="fullWidth my-2"
          bind:value={vod.description}
          input$maxlength="2000"
          on:change={() => (formDirty = true)}>
          <CharacterCounter>{vod.description != null && vod.description != '' ? `${vod.description.length} / 2000` : `0 / 2000`}</CharacterCounter>
        </Textfield>
      </div>
      <div class="col-4 mr-4 label" />
      <div class="col-12 col-md-7 uploadersWrapper my-2">
        <VodVideoUploader
          on:itemSelected={handleItemSelected}
          on:itemRemoved={handleItemRemoved}
          bind:triggerCloseVideo
          bind:item={vod}
          bind:thumbnail={vod.thumbnail} />
        <VodImageUploader
          bind:vodImage={vod.image}
          bind:vodImageThumbnail={vod.imageThumbnail}
          bind:triggerCloseImageDialog
          on:error={handleImageError}
          on:updateVodImage={handleImageAdded} />
      </div>
    </div>

    <hr />

    <div class="row no-gutters py-2 pushToEnd">
      <Button class="btn" on:click={handleGoBack}>
        <Label>{$_('annulla')}</Label>
      </Button>
      <!-- Wrap button into a tooltip  -->
      <!-- <SvelteTooltip tip={$_('blocco_upload_video')} top> -->
        <Button
          class="btn ml-3 mr-3"
          variant="unelevated"
          on:click={onSubmit}
          disabled={!formValid || uploadStarted}>
          <Label class="textUppercase">
            {vod.id ? $_('salva') : $_('crea_nuovo_video')}
          </Label>
        </Button>
      <!-- </SvelteTooltip> -->
    </div>
  </div>

  <UploadingDialog
    {openUploadingDialog}
    {uploadingPercentage}
    {uploadStatus}
    on:close={handleCloseUploadingDialog} />
{/if}

<style>
  * :global(.customUploader) {
    margin-right: 0.5em;
  }

  * :global(.menuSurfaceCustom) {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    color: #fff;
    min-width: 80%;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.mdc-chip) {
    background-color: #fff;
  }
  * :global(.mdc-chip__icon.mdc-chip__icon--trailing) {
    opacity: 0.2;
    font-size: 1.8em;
    width: 0.8em;
    height: 1em;
  }
  * :global(.customChip) {
    border-radius: 13.5px;
    background-color: #212121 !important;
    color: #fff !important;
    min-width: fit-content;
    text-transform: inherit;
    font-weight: normal;
    height: 2em;
    margin: auto;
    margin-left: 0.7em;
    margin-top: 0.5rem;
  }
  * :global(.customChip span i) {
    opacity: 0.5;
    background-color: #ffffff;
    border-radius: 50%;
    color: #212;
    font-size: 1.3em;
    width: 1em;
    height: 1em;
    margin-right: 0;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  .label {
    text-align: end;
    align-self: center;
    opacity: 0.8;
    font-weight: 600;
  }
  .uploadersWrapper {
    flex-direction: row;
    display: flex;
    justify-content: space-around;
  }
  .cursorPointer {
    cursor: pointer;
  }
  * :global(.textUppercase) {
    text-transform: uppercase;
  }
  * :global(.tags-textfield) {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
  }

  .close-icon-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(255,255,255,.99);
  }

  * :global(.tags-textfield input) {
    height: 2.2rem !important;
  }
  
  @media (max-width: 767px) {
    .uploadersWrapper {
      flex-wrap: wrap;
    }
    * :global(.customUploader) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

</style>