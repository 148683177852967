import { get } from 'svelte/store';
import { jwt, loading } from "./store";

const HTTP = method => async (endpoint, { headers = {}, query, body } = {}, updateLoading = true, contentType = "application/json") => {
    try {
        if (updateLoading) loading.set(true);

        const token = get(jwt);

        if (token && token != "") {
            headers["Authorization"] = `Bearer ${token}`;
        }
        
        contentType = headers["Content-Type"] || headers["content-type"] || contentType;

        if (contentType == "application/json") {
            headers["Content-Type"] = contentType;
            body = JSON.stringify(body);
        } else if (contentType == "multipart/form-data") {
            const form = new FormData();
            for (const [key, value] of Object.entries(body)) {
                form.append(key, value);
            }
            body = form;
        }

        const args = { method, headers, body };
        let url = endpoint.startsWith("/") ? `${env.API_URL}${endpoint}` : endpoint;
        if (query) {
            const params = new URLSearchParams();
            for (const [key, value] of Object.entries(query)) {
                params.append(key, value);
            }
            url += `?${params.toString()}`;
        }

        const res = await fetch(url, args);
        const resContentType = res.headers.get("content-type");

        let result;
        if (resContentType.indexOf("application/json") !== -1) {
            result = await res.json();
        } else if (resContentType.indexOf("application/octet-stream") !== -1) {
            result = await res.blob();
        }
        else {
            result = await res.text();
        }

        if (updateLoading) loading.set(false);

        if (!res.ok) {
            throw {
                status: res.status,
                message: result
            }
        }

        return result;
    } catch (error) {
        if (updateLoading) loading.set(false);
        throw error;
    }
};

export const GET = HTTP("GET");
export const POST = HTTP("POST");
export const PUT = HTTP("PUT");
export const PATCH = HTTP("PATCH");
export const DELETE = HTTP("DELETE");
