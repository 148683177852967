<script>
  import { onMount } from "svelte";
  import { _ } from "../../../services/i18n";
  import { navigate } from "svelte-routing";
  import { user, jwt } from "../../../lib/store";
  import FirstForm from "./FirstForm.svelte";
  import SecondForm from "./SecondForm.svelte";

  const options = {
    firstStep: FirstForm,
    secondStep: SecondForm,
  };

  export let component = "firstStep";
  export const showNavbar = true;
  export const navbarDisabled = true;
  let props = {};

  const goToSecondForm = (e) => {
    component = "secondStep";
    props = { ...e.detail.info };
  };

  onMount(() => {
    if (!$jwt) navigate("/login");
    else if ($user.first_name) navigate("/team");
  });
</script>

<div class="container-fluid flexy flowRegistrationBackground h100 pt-4">
  <div class="row justify-content-center w100 h100 py-2">
    <div class="col-xs-12 col-md-12 col-lg-6 flexy">
      <svelte:component
        this={options[component]}
        on:firstFormCompleted={goToSecondForm}
        {...props} />
    </div>
  </div>
</div>
