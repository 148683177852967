<script>
  import { _ } from "../../../../services/i18n";
  import { createNewNote, updateNote } from "../../../../services/teamServices";
  import { customer } from "../../../../lib/store";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import Note from "./Note.svelte";
  import moment from "moment";
  import Header from "../../../ReusableLayouts/Header.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";

  export let location = {};
  export let note = {
    title: "",
    date: moment().format(dateDBFormat),
    content: "",
  };

  const dateDBFormat = "YYYY-MM-DD";

  let active = true;
  let activeTab = $_("nota");
  let component = [$_("nota")];
  let customerId;
  let exitDialog;
  let noteId;
  let msgError;
  let isSubmitting = false;
  let options = {
    [$_("nota")]: Note,
  };
  let props = {};
  let tabs = [$_("nota")];

  $: customerId = new URLSearchParams(location.search).get("customerId");
  $: noteId = new URLSearchParams(location.search).get("noteId");

  const handleActiveTabChanged = (e) => {
    activeTab = e.detail.tab;
    component = [e.detail.tab];
    if (e.detail.note) {
      note = e.detail.note;
    }
  };

  $: switch (activeTab) {
    case $_("nota"):
      props = { isSubmitting };
      break;
    default:
      break;
  }

  const displayError = () => {
    isSubmitting = false;
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleSaveNote = async (e) => {
    try {
      isSubmitting = true;
      const res = await createNewNote(note, customerId);
      navigate(`/customer?tab=notes`, {
        state: { customerId },
      });
    } catch (err) {
      console.log("handleSaveNote update error", err);
      displayError();
    }
  };

  const handleUpdateNote = async (e) => {
    try {
      isSubmitting = true;
      let res = await updateNote(note, noteId);
      navigate(`/customer?tab=notes`, {
        state: { customerId },
      });
    } catch (err) {
      console.log("handleUpdateNote error", err);
      displayError();
    }
  };

  const handleGoBack = () => exitDialog.open();

  const goBack = () =>
    navigate(`/customer?tab=notes`, {
      state: { customerId },
    });

  const exitConfirmation = () => {
    exitDialog.close();
    goBack();
  };
</script>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={exitConfirmation} />
</Dialog>

<Header
  {activeTab}
  {tabs}
  asTextAndIcon={true}
  asTitle={false}
  component={undefined}
  noBackground={false}
  on:activeTabChanged={handleActiveTabChanged}
  on:goBack={handleGoBack}
  props={{}}
  sticky={false}
  subTitle={false}
  summary={false}
  tabNavigationEnabled={true}
  text={$_('indietro')} />

<div class="container fluid">
  <MsgError {msgError} />
  <svelte:component
    this={options[component]}
    {...props}
    bind:note
    on:cancel={handleGoBack}
    on:updateNote={handleUpdateNote}
    on:saveNote={handleSaveNote} />
</div>
