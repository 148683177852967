<script>
    import { _ } from "../../../services/i18n";
    import Textfield, { Input } from "@smui/textfield";
    import Select, { Option } from "@smui/select";

    export let addNotice = false;
    export let closedAppointmentDialog = false;
    export let closedReminderDialog = false;
    export let object;

    let notifications = [
        { text: "minuti", value: "m" },
        { text: "ore", value: "h" },
        { text: "giorni_label", value: "g" },
    ];

    // Clean notification data on close dialog
    $: if (closedAppointmentDialog || closedReminderDialog) {
        addNotice = false;
        try {
            object.notifications[0] = { notification_time_unit: "h", notification_time_value: 1 };
        } catch (err) {
            console.log('error set default object notification values', err);
        }
        closedAppointmentDialog = false;
        closedReminderDialog = false;
    }

    $: try {
        if (object && Array.isArray(object.notifications)) {
            object.notifications.forEach(i => {
                if (i.notification_time_value > 100) {
                    i.notification_time_value = 1;
                }
                if (isNaN(i.notification_time_value)) {
                    i.notification_time_value = "";
                }
            });
        }
    } catch (err) {
        console.log('error set max notification time values', err);
    }

    const handle2Notification = (value) => {
        addNotice = value;
        //controlla se la seconda notifica è abilitata
        if (!value) {
            if (
                object.notifications.length > 1 &&
                object.notifications[1].hasOwnProperty("id")
            )
                object.notifications[1].delete = true;
            //TODO add calendar to confirm the delete
            else if (object.notifications.length > 1) {
                object.notifications.pop();
                object.notifications = object.notifications;
            }
        }
        // nel caso non fosse abilitata, verifica se nell'array notification
        // sia già presente una notifica scaricata dal BE, altrimenti ne aggiunge una nuova
        else {
            if (object.notifications.length > 1) {
                if (object.notifications[1].hasOwnProperty("delete")) {
                    delete object.notifications[1].delete;
                }
            } else
                object.notifications = [
                    ...object.notifications,
                    { notification_time_unit: "h", notification_time_value: 1 },
                ];
        }
    };

    const handleNotificationForFE = (notifications) => {
        if (
            notifications.length > 1 &&
            !object.notifications[1].hasOwnProperty("delete")
        )
            addNotice = true;
    };

    $: {
        handleNotificationForFE(object.notifications);
    }
</script>

{#await handleNotificationForFE(object.notifications) then value}
    {#if Array.isArray(object.notifications) && object.notifications.length > 0}
        <div class="row-grid-auto mt-2 mt-sm-0">
            <div class="row d-flex align-items-center">
                <div class="col-12 col-sm-4 label">{$_('notifica')}</div>
                <div class="col-12 col-sm-8 mt-2">
                    <div class="row-grid-date">
                        <Textfield
                            variant="outlined"
                            style="margin:0px !important;"
                            input$max=100
                            input$maxlength=3
                            bind:value={object.notifications[0].notification_time_value}
                            type="number"
                            label=""
                        />
                        <Select
                            enhanced
                            variant="outlined"
                            style="padding-top:0px !important"
                            label=""
                            bind:value={object.notifications[0].notification_time_unit}
                        >
                            {#each notifications as unit}
                                <Option
                                    value={unit.value}
                                    selected={object.notifications[0].notification_time_unit == unit.value}
                                >
                                    {$_(unit.text, {
                                        values: {
                                            [unit.text]:
                                                object.notifications[0]
                                                    .notification_time_value,
                                        },
                                    })}
                                </Option>
                            {/each}
                        </Select>
                    </div>
                </div>
            </div>
            {#if !addNotice}
                <div
                    class="d-flex align-items-center mt-4 mt-sm-2
                        justify-content-center justify-content-sm-start"
                >
                    <span
                        class="material-icons cursorPointer"
                        on:click={() => handle2Notification(true)}
                    >
                        add_circle_outline
                    </span>
                </div>
            {/if}
        </div>
        {#if addNotice && object.notifications.length > 1}
            <div class="row-grid-auto mt-2 mt-sm-0">
                <div class="row d-flex align-items-center">
                    <div class="col-12 col-sm-4 label">{`${$_('notifica')} 2`}</div>
                    <div class="col-12 col-sm-8 mt-2">
                        <div class="row-grid-date">
                            <Textfield
                                variant="outlined"
                                style="margin:0px !important"
                                bind:value={object.notifications[1].notification_time_value}
                                type="number"
                                label=""
                                input$max={100}
                                input$maxlength={100}
                            />
                            <Select
                                enhanced
                                variant="outlined"
                                style="padding-top:0px !important"
                                label=""
                                bind:value={object.notifications[1].notification_time_unit}
                            >
                                {#each notifications as unit}
                                    <Option
                                        value={unit.value}
                                        selected={object.notifications[1].notification_time_unit == unit.value}
                                    >
                                        {$_(unit.text, {
                                            values: {
                                                [unit.text]:
                                                    object.notifications[1]
                                                        .notification_time_value,
                                            },
                                        })}
                                    </Option>
                                {/each}
                            </Select>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center mt-4 mt-sm-2
                        justify-content-center justify-content-sm-start"
                >
                    <span
                        class="material-icons cursorPointer"
                        on:click={() => handle2Notification(false)}
                    >
                        remove_circle_outline
                    </span>
                </div>
            </div>
        {/if}
    {:else}
        <div
            class="d-flex align-items-center mt-4 mt-sm-2
                justify-content-center justify-content-sm-start"
        >
            <span
                class="material-icons cursorPointer"
                on:click={() => handle2Notification(true)}
            >
                add_circle_outline
            </span>
        </div>
    {/if}
{/await}

<style>
    .row-grid-auto {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
    }
    .row-grid-date {
        display: grid;
        grid-template-columns: 40% 54%;
        gap: 6%;
        align-items: center;
    }

    * :global(.row-grid-date input) {
        text-align: center;
    }

    @media (max-width: 767px) {
        .row-grid-auto {
            grid-template-columns: 60% 1fr;
            gap: 12px;
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {
        .label {
            max-width: calc((100vw - 64px) * 0.166);
        }
        .row-grid-auto {
            gap: 0px;
        }
    }

    @media (max-width: 576px) {
        .row-grid-auto {
            grid-template-columns: 80% 1fr;
            gap: 12px;
        }
    }

    /* *:global(.row-grid-date .mdc-text-field__input) {
    text-align: center;
  } */
</style>
