<script>
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { getCombinations } from "../../../lib/utils";
  import { Icon } from "@smui/common";
  import AddNewExercise from "./AddNewExercise.svelte";
  import CustomSelect from "./CustomSelect.svelte";
  
  const dispatch = createEventDispatcher();
  const exerciseSelected = null;

  let combinationItems = getCombinations($_);
  let newCombination = "normal";
  let newExerciseField = true;

  const handleExerciseSelected = (e) => {
    dispatch("combinationSelected", {
      combination: newCombination,
      exercise: e.detail.exercise,
    });
    newCombination = "normal";
  };

  const handleSearchExercisesClicked = async () => {
    dispatch('openExercisesDialog', { 
      component: 'AddNewCombination',
      combination: newCombination,
    });
  };

</script>

<style>
  * :global(.mdc-notched-outline) {
    background-color: white;
  }
  * :global(.mdc-text-field) {
    height: 2.5em;
  }

  * :global(.mdc-text-field__input) {
    max-height: 100%;
    padding: 0 0.5em;
  }

  * :global(.mdc-select .mdc-select__native-control) {
    border-bottom: none;
    font-size: 0.8rem;
    padding: unset;
  }
  .label {
    font-size: 0.8rem;
    font-weight: 500;
    opacity: 0.7;
  }
</style>

<div class="row my-1">
  <div class="col-1 px-0" />
  <div class="col-10 px-0">
    <div class="row py-2">
      <div class="col-3">
        <span class="label">{$_('combinazione')}</span>
        <CustomSelect
          outlined={true}
          bind:selected={newCombination}
          items={combinationItems} />
      </div>
      <div class="col-7">
        <AddNewExercise
          {exerciseSelected}
          {newExerciseField}
          on:exerciseSelected={handleExerciseSelected} />
      </div>
      <div class="col-1 pl-0 text-center">
        <span class="label">{$_('cerca')}</span>
        <div class="py-2">
          <Icon
            class="material-icons searchIcon cursorPointer"
            on:click={handleSearchExercisesClicked}>
            search
          </Icon>
        </div>
      </div>
      <div class="col-1 px-0" />
    </div>
  </div>
  <div class="col-1 px-0" />
</div>
