<script>
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { customer, subscriptionInfo } from "../../../lib/store";
  import { getTypes } from "../../../lib/utils";
  import { Icon } from "@smui/common";
  import { updateCustomerImage } from "../../../services/customerServices";
  import { updateCustomerRelation } from "../../../services/teamServices";
  import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
  import CustomerImageUploader from "./CustomerImageUploader.svelte";
  import Dialog from "@smui/dialog";
  import FormField from "@smui/form-field";
  import SubscriptionDialog from "../../PtInfo/Billing/SubscriptionDialog.svelte";
  import SvelteTooltip from 'svelte-tooltip';
  import Switch from "@smui/switch";
  
  const dispatch = createEventDispatcher();

  export let active;
  export let customerId;
  export let profileImage = "";

  let disableCustomerDialog;
  let showSubscriptionDialog = false;
  let statusIcon;
  let statusLabel;
  let subscriptionDialogState = "upgrade_plan";
  let typeLabel = null;
  let types;

  $: types = getTypes($_);
  $: types.forEach((t) => {
    if ($customer.customer_id == customerId && $customer.type == t.value) {
      typeLabel = t.label;
    }
  });

  $: if ($subscriptionInfo.status === 'trialing') {
    subscriptionDialogState = 'trialing';
  }

  $: {
    switch ($customer.status) {
      case "Active":
        statusLabel = $_("molto_attivo");
        statusIcon = "userIcon-active";
        break;
      case "Not very active":
        statusLabel = $_("attivo");
        statusIcon = "userIcon-notVeryActive";
        break;
      case "Inactive":
        statusLabel = $_("poco_attivo");
        statusIcon = "userIcon-inactive";
        break;
      case "No training":
        statusLabel = $_("mai_allenato");
        statusIcon = "userIcon-inactive";
        break;
      default:
        statusLabel = $_("disattivato");
        statusIcon = "userIcon-disactive";
        break;
    }
  }

  const handleSwitchChange = (e) => {
    if (!$customer.active) {
      disableCustomerDialog.open();
    } else {
      changeCustomerStatus();
    }
  };

  const handleImageUpdated = async (e) => {
    try {
      const res = await updateCustomerImage({
        customer_id: customerId,
        image: e.detail.image,
      });
      $customer.image = res.image;
    } catch (err) {
      console.log("err handleImageUpdated", err);
    }
  };

  const changeCustomerStatus = async () => {
    try {
      const temp = await updateCustomerRelation(customerId, $customer.active);
      $customer.status = temp.status;
      disableCustomerDialog.close();
      dispatch("onComponentEvent", { active: $customer.active });
    } catch (error) {
      if (error.message.message && error.message.message.toLowerCase().includes('incompatible')) {
        showSubscriptionDialog = true;
        $customer.active = false;
      }
      console.log("changeCustomerStatus err", error);
    }
  };

  const handleCloseDialog = () => {
    showSubscriptionDialog = false;
  };
</script>

<style>
  .alignContentCenter {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .nameClass {
    font-weight: bold;
    display: inline;
    font-size: 25px;
  }
  .activeLabel {
    display: none;
  }
  .labelsClass {
    font-size: 0.875rem;
    opacity: 0.8;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }
  .emailClass {
    font-size: 1rem;
  }
  .opaque {
    opacity: 0.5;
  }
  * :global(.active) {
    color: #0cd2c8;
  }
  * :global(.smallIcon) {
    font-size: 1.125rem;
  }
  @media (min-width: 576px) {
    .nameClass {
      font-weight: bold;
      display: inline;
      font-size: 40px;
    }
    .activeLabel {
      display: inline;
      opacity: 0.8;
      font-size: 1.125rem;
      font-weight: 600;
    }
    .mobile {
      display: none;
    }

    .desktop {
      display: inline;
    }
  }

  @media (max-width: 576px) {
    .labelsClass:not(.emailClass) {
      padding-top: 0 !important;
    }
    .emailClass {
      padding-top: 0.5rem !important;
    }
    .switcher--customer {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      top: 114px;
    }

    .desktop {
      display: none;
    }
  }
</style>

<Dialog
  bind:this={disableCustomerDialog}
  aria-labelledby="dialog-for-disabling-customer"
  aria-describedby="confirmation-dialog">
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_disattivare')}
    confirmationButtonLabel={$_('conferma')}
    on:closeDialog={() => {
      disableCustomerDialog.close();
      $customer.active = true;
    }}
    on:onConfirmation={changeCustomerStatus} />
</Dialog>

{#if showSubscriptionDialog}
  <SubscriptionDialog 
    state={subscriptionDialogState}
    trigger={'customers'}
    on:closeDialog={handleCloseDialog} 
  />
{/if}

<div class="container fluid switcher--customer mobile">
  <FormField class="pl-4">
    <Switch
      bind:checked={$customer.active}
      id="activeSwitch"
      on:change={handleSwitchChange} />
    <span class="activeLabel" slot="label">
      {$customer.active ? $_('attivato') : $_('disattivato')}
    </span>
  </FormField>
</div>
<div class={`row no-gutters mb-3 ${$customer.active ? '' : 'opaque'}`}>
  <div class="col-3 col-md-2">
    <CustomerImageUploader
      {profileImage}
      on:imageUpdated={handleImageUpdated} />
  </div>
  <div class="col-9 col-md-10">
    <div class="row no-gutters ml-1 h100">
      <div class="col-12 flexy nameClass">
        {`${$customer.first_name} ${$customer.last_name}`}
        <div class="desktop">
          <SvelteTooltip tip="{$customer.active ? $_('disattiva_l_atleta') : $_('attiva_l_atleta')}" top>
            <FormField class="pl-4">
              <Switch
                bind:checked={$customer.active}
                id="activeSwitch"
                on:change={handleSwitchChange} />
              <span class="activeLabel" slot="label">
                {$customer.active ? $_('attivato') : $_('disattivato')}
              </span>
            </FormField>
          </SvelteTooltip>
        </div>
      </div>
      <div class="col-12" style="display: flex">
        <span class="mr-3 labelsClass alignContentCenter">
          <Icon class={`material-icons smallIcon mr-2 ${statusIcon}`}>
            fiber_manual_record
          </Icon>
          {statusLabel ? statusLabel : $customer.active ? $_('attivo') : $_('disattivo')}
        </span>
        {#if typeLabel}
          <span class="labelsClass alignContentCenter">
            <Icon class="material-icons-outlined smallIcon mr-2">
              location_on
            </Icon>
            {typeLabel}
          </span>
        {/if}
      </div>
      <span class="col-12 pt-3 labelsClass emailClass">
        {$customer.email}
        <span class="primary">
          {$customer.email_confirmed ? '' : `(${$_('non_confermata')})`}
        </span>
      </span>
    </div>
  </div>
</div>
