<script>
    import { createEventDispatcher } from "svelte";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import Button, { Label } from "@smui/button";
    import IconButton from "@smui/icon-button";
    import { _ } from "../../services/i18n";

    export let openDialog;

    const dispatch = createEventDispatcher();

    let dialog;
    let clickOnClose = false;

    $: {
        if (openDialog == true) {
            dialog.open();
            clickOnClose = false;
        }
    }

    const handleDialogClose = () => {
        if (dialog && dialog.isOpen()) {
            dialog.close();
            clickOnClose = true;
            dispatch("closeSelectDialog");
        }
        if (!clickOnClose) dispatch("closeSelectDialog");
    };

    let disableButtons = false;
    let quitSaving = false;
    let saving = false;
</script>

<div on:click|stopPropagation>
    <Dialog
        class="dialog--xsmall invitationDialog"
        bind:this={dialog}
        on:MDCDialog:closed={() => handleDialogClose()}
    >
        <Title class="d-flex">
            <h5 class="bold pt-3" style="flex-grow: 1;">
                {$_('cosa_vuoi_aggiungere')}
            </h5>
            <IconButton
                class="material-icons"
                on:click={() => handleDialogClose()}
            >
                close
            </IconButton>
        </Title>
        <Content>
            <div class="container-fluid">
                <div class="row-grid-auto">
                    <Button
                        class="btn button--bigger appointment-color"
                        variant="unelevated"
                        disabled={saving || quitSaving}
                        on:click={() => {
                            handleDialogClose();
                            dispatch('appointment');
                        }}
                    >
                        <Label>{$_('appuntamento')}</Label>
                    </Button>
                    <Button
                        class="btn button--bigger reminder-color"
                        variant="unelevated"
                        disabled={disableButtons || quitSaving}
                        on:click={() => {
                            handleDialogClose();
                            dispatch('reminder');
                        }}
                    >
                        <Label>{$_('promemoria')}</Label>
                    </Button>
                </div>
            </div>
        </Content>
    </Dialog>
</div>

<style>
    * :global(.appointment-color) {
        background-color: #0cd2c8;
    }
    * :global(.reminder-color) {
        background-color: #f98131;
    }
    h5 {
        padding: 0;
    }

    * :global(.material-icons) {
        font-size: 1.8rem;
    }
    * :global(.mdc-dialog__surface),
    * :global(.mdc-dialog__container) {
        max-width: 80vw;
        width: 600px !important;
    }
    * :global(.mdc-dialog__title + .mdc-dialog__content) {
        padding: 24px 64px;
    }
    * :global(.mdc-dialog__actions) {
        padding: 24px 64px;
    }
    * :global(.mdc-dialog__title) {
        display: flex;
        padding: 10px 39px;
        background-color: #f9f9f9;
        opacity: 0.8;
    }
    * :global(.mdc-text-field__icon--trailing.customLabel) {
        color: #212121;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.5px;
        opacity: 0.5;
    }
    * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
        border-color: var(--primary);
    }
    * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
        border-color: var(--primary);
    }

    .row-grid-auto {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 64px;
    }

    @media (max-width: 767px) {
        .row-grid-auto {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        * :global(.mdc-dialog__content) {
            max-height: fit-content;
            flex-grow: 0;
        }
    }
</style>
