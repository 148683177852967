<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { Icon } from "@smui/common";
  import Card, { Content } from "@smui/card";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import NoItemsToShow from "../ReusableLayouts/NoItemsToShow.svelte";
  import Pagination from "../ReusableLayouts/Pagination.svelte";
  import TrainingCardsList from "./TrainingCardsList.svelte";
  
  export let activePage;
  export let activeTab;
  export let ascOrder;
  export let handleDeleteTrainingCard;
  export let isMobile;
  export let isTemplate;
  export let loading;
  export let search;
  export let selectedGroups = [];
  export let totalPages;
  export let trainingCards = [];

  const dispatch = createEventDispatcher();

  let biggerText = $_('crea_i_tuoi_piani'); 
  let buttonText = $_('nuovo_piano'); 
  let filterValue;
  let icon = 'fitness_center';
  let pageReady = false;
  let pathToNavigateTo = 'new-training-card';
  let searchParamsTab  = "modelli";
  let upperText = $_('nessun_piano');

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: filterValue =
    selectedGroups.length > 0
      ? `${$_("filtra")} (${selectedGroups.length})`
      : $_("tutti");

  const switchPage = ({ detail }) => {
    const { page } = detail;
    dispatch("activePageChange", { page });
    activePage = page;
  };

  const changeOrder = () => {
    dispatch('orderChange', {ascOrder: !ascOrder});
  };

  onMount(async () => {
    pageReady = true;
  })
</script>

<style>
  .alignContentCenter {
    align-items: center;
  }

  .nameWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .textWrapper {
    font-size: 1.25rem;
    opacity: 0.5;
    word-break: break-word;
  }
  .opacity--eighty {
    opacity: 0.8;
  }

  * :global(.customButtom) {
    color: black;
    border-color: black;
  }
  * :global(.list) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 14px;
    justify-content: center;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  * :global(.mdc-card) {
    text-align: center;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }
  .textLeft {
    justify-content: flex-start;
  }

  @media (max-width: 767px) {
    .desktop {
      display: none;
    }
  }
</style>

{#if activeTab != $_('assegnati_ai_gruppi')}
  <div class="container fluid mt-3">
    {#if loading}
      <Loading />
    {:else}
      <Card class="invisibleCard">
        <Content style="padding-bottom: 0px;">
          <div class="row no-gutters alignContentCenter desktop">
            <span
              class="col-6 col-xs-6 col-md-3 col-lg-3 mdc-typography--headline6 textTitle textLeft nameWrapper"
              on:click={changeOrder}>
              {$_('nome_modello')}
              <Icon
                class="material-icons mdc-text-field__icon
                mdc-text-field__icon--trailing ml-2">
                {ascOrder ? 'arrow_drop_up' : 'arrow_drop_down'}
              </Icon>
            </span>
            <div class="col-md-2 col-lg-2 mdc-typography--headline6 textTitle">
              {$_('suddiviso')}
            </div>
            <div class="col-md-2 col-lg-2 mdc-typography--headline6 textTitle">
              {$_('durata')}
            </div>
            <div class="col-md-2 col-lg-2 mdc-typography--headline6 textTitle">
              {$_('livello')}
            </div>
            <div class="">
              <div class="col-md-2 col-lg-2 mdc-typography--headline6 textTitle" />
            </div>
          </div>
          <div class="row alignContentCenter mobile">
            
          </div>
        </Content>
      </Card>

      <hr class="line desktop" />
      {#if trainingCards && trainingCards.length > 0}
        <TrainingCardsList
          {isMobile}
          {isTemplate} 
          {trainingCards}
          bind:activeTab
          on:deleteTrainingCard={handleDeleteTrainingCard} />
        <Pagination {totalPages} {activePage} on:page={switchPage} />
      {:else}
        {#if search}
        <div class="container-fluid my-4 text-center">
          <span
            class="textWrapper demiBold">{$_('nessun_piano_di_allenamento_trovato')}</span>
        </div>
        {:else}
          <NoItemsToShow
            {biggerText}
            {buttonText}
            {icon}
            {pathToNavigateTo} 
            {upperText} />
        {/if}
      {/if}
    {/if}
  </div>
{/if}
