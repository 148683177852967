<script>
  export let iW_update;
  let iW;
  let iW_check_before;
  let iW_check_after;
  let updateHeightStatus = false;

  //update the layout after 150ms after a resize of the window width
  $: {
    if (!updateHeightStatus && iW_check_before != iW) {
      updateHeightStatus = true;
      //set iW
      iW_check_before = iW;
      setTimeout(() => {
        iW_check_after = iW;
        if (iW_check_after == iW_check_before) {
          //set iW_update to trigger the reactivity for updating the layout
          iW_update = iW;
        }
        updateHeightStatus = false;
      }, 150);
    }
  }
</script>

<svelte:window bind:innerWidth={iW} />
