<script>
  import { _ } from "../../services/i18n";
  import { checkUrl } from "../Team/TeamValidation/teamValidation";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { createTag, deleteTag, getExercises, getExercisesList, maxPage, saveExercise } from "../../services/exercisesServices";
  import { generateUID } from "../../lib/utils";
  import { Icon } from "@smui/common";
  import { tags, exercises, user, activeSubSection } from "../../lib/store";
  import Button, { Label } from "@smui/button";
  import Chip, { Set, Text } from "@smui/chips";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import ExerciseImageUploader from "./Uploaders/ExerciseImageUploader.svelte";
  import ExerciseVideoUploader from "./Uploaders/ExerciseVideoUploader.svelte";
  import FormMessage from "../ReusableLayouts/FormMessage.svelte";
  import IconButton from "@smui/icon-button";
  import LoadingInline from "../ReusableLayouts/LoadingInline.svelte";
  import MenuSurface from "@smui/menu-surface";
  import Textfield from "@smui/textfield";

  export let exercise;
  export let exitDialogOpened = undefined;
  export let triggerCloseImageDialog;
  export let triggerCloseVideo;

  const dispatch = createEventDispatcher();
  const jq = window.$;

  let customChips;
  let edit = false;
  let exitDialog;
  let filteredTags;
  let firstSubmitAttempt = false;
  let formDirty = false;
  let formSurface;
  let formValid = false;
  let infoDialog;
  let initialExercise = Object.assign({}, exercise);
  let inputId = generateUID();
  let linkElement;
  let linkInvalid = false;
  let msgError;
  let nameInvalid = false;
  let newImageCropped;
  let newTag = "";
  let saving = false;

  $: if (exitDialogOpened) {
    handleGoBack();
  }

  $: filterExercises(newTag);

  $: if (exercise.name) nameInvalid = checkNameValidity();
  $: if (typeof exercise.video_thumbnail === undefined || exercise.video_thumbnail === undefined) exercise.video_thumbnail = '';

  $: linkInvalid = exercise.link ? !checkUrl(exercise.link) || exercise.link.length > 191 : false;
  // $: formValid = exercise.name && exercise.name.length > 2 && !nameInvalid && !linkInvalid;
  $: formValid = exercise.name && exercise.name.length > 2 && !linkInvalid;
  $: {
    if (exercise.link == "" && linkElement) {
      addPlaceholder();
    }
  }
  const addPlaceholder = () => {
    linkElement.childNodes[2].placeholder = "https://";
  };

  const checkNameValidity = () => {
    if ($user.is_admin) return false;
    let matchName = exercise.name.toLowerCase().trim();

    let matchesPtExercises = $exercises.filter(e => e.is_active === 1 && e.base === 0).filter(i => 
      exercise.base
        ? i.name.toLowerCase().trim() === matchName
        : i.name.toLowerCase().trim() == matchName && i.id != exercise.id
    );
    
    let matchesBaseExercises = $exercises.filter(e => e.is_active === 1 && e.base === 1).filter(i => 
      exercise.base
        ? i.name.toLowerCase().trim() === matchName
        : i.name.toLowerCase().trim() == matchName && i.id != exercise.id
    );

    return matchesPtExercises.length > 0 || matchesBaseExercises.length > 1;
  };

  const handleImageAdded = (e) => {
    formDirty = true;
    exercise.image = e.detail.img;
    newImageCropped = null;
  };

  const handleVideoUpdated = (e) => {
    formDirty = true;
    exercise.video = e.detail.video;
  };

  const filterExercises = (newTag) => {
    filteredTags = $tags.filter((tag) =>
      tag.name.toLowerCase().includes(newTag.toLowerCase().trim())
    );
  };

  const addChip = (chip) => {
    if (exercise.tagsSelected.length >= 3) return;
    exercise.tagsSelected = [...exercise.tagsSelected, chip];
    //filteredTags = filteredTags.filter((i) => i != chip);
    cleanTags();
    newTag = "";
    formDirty = true;
  };

  // Clean tags removing selected ones (already applied) from filtered ones
  const cleanTags = () => {
    filteredTags = $tags;
    filteredTags = filteredTags.filter(x => !exercise.tagsSelected.some(y => x.id === y.id));
  }

  const removeTagFromSelected = (tag) => {
    exercise.tagsSelected = exercise.tagsSelected.filter((i) => i != tag);
    filteredTags = [...filteredTags, tag];
    filteredTags = filteredTags.sort((a, b) => a.name.localeCompare(b.name));
    formDirty = true;
    cleanTags();
  };

  const destroyTag = async (tag) => {
    try {
      const res = await deleteTag({
        id: tag.id,
      });
      tags.set($tags.filter((i) => i.id != tag.id));
      filteredTags = filteredTags.filter((i) => i.id != tag.id);
    } catch (error) {
      console.log("destroyTag err", error);
      displayError();
    }
  };

  const submitNewTag = async () => {
    try {
      if (filteredTags.some(i => i.name.toLowerCase() === newTag.toLowerCase().trim())) {
        addChip(
          filteredTags.find(
            (i) => i.name.toLowerCase() === newTag.toLowerCase().trim()
          )
        );
      } else {
        const res = await createTag(newTag.trim());
        addChip(res.find((i) => i.name.toLowerCase() === newTag.toLowerCase().trim()));
        newTag = "";
        // filteredTags = filteredTags.filter(
        //   (i) => !exercise.tagsSelected.includes(i)
        // );
        await tick();

        tags.set(res);
        cleanTags();
      }
      formDirty = true;
    } catch (error) {
      console.log("submitNewTag err", error);
      displayError();
    }
  };

  const onSubmit = async () => {
    if (!nameInvalid) {
      const body = {
        image: newImageCropped || exercise.image,
        video: exercise.video,
        name: exercise.name,
        link: exercise.link,
        matrix_id: exercise.base == 1 ? exercise.id : null,
        tags: JSON.stringify(exercise.tagsSelected),
        base: $user.is_admin ? 1 : 0,
        note: exercise.note,
      };
      if ((exercise.id && exercise.base != 1) || (exercise.id && $user.is_admin))
      body.id = exercise.id;
      try {
        saving = true;
        const res = await saveExercise(body);
        formDirty = false;
        initialExercise = {
          ...res,
          tagsSelected: res.tags,
          link: res.link ? res.link : "",
          note: res.note ? res.note : "",
        };
        // Get only pt exercises and update local storage
        // await getExercises(true, 0);
        await getExercisesList(true);
        dispatch("onSubmit", { id: exercise.id, res });
        saving = false;
      } catch (err) {
        saving = false;
        console.log("submit exercise error", err);
        displayError(err);
      }
    } else {
      firstSubmitAttempt = true;
    }
  };

  const displayError = (err) => {
    try {
      switch(err.message.name[0].trim().toLowerCase()) {
        case "the name must be at least 3 characters." : msgError = $_("il_nome_deve_essere_di_almeno_3_caratteri"); break;
        default : msgError = $_("si_è_verificato_un_errore");
      }  
    } catch {
      msgError = $_("si_è_verificato_un_errore");
    }
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const resetExercise = () => {
    exercise = Object.assign({}, initialExercise);
    newTag = "";
  };

  const handleGoBack = () => {
    if (formDirty) exitDialog.open();
    else dispatch("goBack", {});
  };

  const closeExitDialog = () => {
    exitDialogOpened = false;
    exitDialog.close();
    dispatch("closeExitDialog");
  };

  const handleConfirmGoBack = () => {
    resetExercise();
    exitDialog.close();
    formDirty = false;
    dispatch("goBack", {});
  };

  const handleImageError = () => {
    msgError = $_("la_dimensione_massima_consentita_è_di", {
      values: {
        dimension: 16,
      },
    });
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  onMount(() => {
    linkElement = document.getElementById("linkInput");
    customChips = document.getElementsByClassName("customChip");
    addPlaceholder();
    jq("#dropdown-edit").on("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
    });

    jq(`#dropdownMenuButton-${inputId}`).on("click", () => {
      formSurface.setOpen(true)
    });

    jq(`#dropdownMenuButton-${inputId}`).on("keyup", function () {
      formSurface.setOpen(true);
    });

    cleanTags();

  });

</script>

<style>
  * :global(.customUploader) {
    margin-right: 0.5em;
  }

  * :global(.menuSurfaceCustom) {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    color: #fff;
    min-width: 80%;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.mdc-chip) {
    background-color: #fff;
  }
  * :global(.mdc-chip__icon.mdc-chip__icon--trailing) {
    opacity: 0.2;
    font-size: 1.8em;
    width: 0.8em;
    height: 1em;
  }
  * :global(.customChip) {
    border-radius: 13.5px;
    background-color: #212121 !important;
    color: #fff !important;
    min-width: fit-content;
    text-transform: inherit;
    font-weight: normal;
    height: 2em;
    margin: auto;
    margin-left: 0.7em;
    margin-top: 0.5rem;
  }
  * :global(.customChip span i) {
    opacity: 0.5;
    background-color: #ffffff;
    border-radius: 50%;
    color: #212;
    font-size: 1.3em;
    width: 1em;
    height: 1em;
    margin-right: 0;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  .label {
    text-align: end;
    align-self: center;
    opacity: 0.8;
    font-weight: 600;
  }
  .uploadersWrapper {
    flex-direction: row;
    display: flex;
    justify-content: space-around;
  }
  .cursorPointer {
    cursor: pointer;
  }
  * :global(.textUppercase) {
    text-transform: uppercase;
  }
  * :global(.tags-textfield) {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
  }

  .close-icon-wrapper {
    right: 0;
    position: absolute;
  }

  * :global(.tags-textfield input) {
    height: 2.2rem !important;
  }
  @media (max-width: 767px) {
    .uploadersWrapper {
      flex-wrap: wrap;
    }
    * :global(.customUploader) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
</style>

<Dialog class="no-click-backdrop" bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={closeExitDialog}
    on:onConfirmation={handleConfirmGoBack} />
</Dialog>

<Dialog class="no-click-backdrop" bind:this={infoDialog}>
  <div class="close-icon-wrapper">
    <IconButton class="material-icons" on:click={() => infoDialog.close()}>
      close
    </IconButton>
  </div>
  <div class="container fluid text-center">
    <p class="p-2 my-2">
      {@html $_('qui_puoi_inserire_link_video')}
    </p>
  </div>
</Dialog>

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

{#if exercise}
  <div class="container fluid py-4">
    <div class="row no-gutters">
      <div class="col-4 mr-4 label">{$_('nome_esercizio')}</div>
      <div class="col-7">
        <Textfield
          variant="outlined"
          label=""
          on:change={() => (formDirty = true)}
          bind:value={exercise.name}
          input$maxlength={191}
          disabled={$activeSubSection == $_('progressioni')}
          class={`w100 my-2 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
          type="text" />
        {#if nameInvalid && firstSubmitAttempt}
          <FormMessage>
            {$_('esiste_già_un_esercizio_con_questo_nome')}
          </FormMessage>
        {/if}
        {#if exercise.name.length > 0 && exercise.name.length < 3}
          <FormMessage>
            {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
          </FormMessage>
        {/if}
      </div>
      <div class="col-4 mr-4 label">{$_('tags')}</div>
      <div class="col-7">
        <div class="dropdown mt-3">
          <Textfield
            variant="outlined"
            label=""
            bind:value={newTag}
            disabled={$activeSubSection == $_('progressioni')}
            on:change={() => (formDirty = true)}
            class="w100 textfield--large tags-textfield"
            withTrailingIcon
            on:keydown={(e) => {
              if (e.key === 'Enter') submitNewTag();
            }}
            input$maxlength="191"
            id={`dropdownMenuButton-${inputId}`}
            aria-haspopup="true"
            aria-expanded="false">
            <div style="width: 85%;">
              {#if exercise.tagsSelected && exercise.tagsSelected.length > 0}
                {#each exercise.tagsSelected as tag}
                  <Button class="btn customChip">
                    <Label>
                      {tag.name}
                      <Icon
                        class="material-icons"
                        on:click={() => removeTagFromSelected(tag)}>
                        close
                      </Icon>
                    </Label>
                  </Button>
                {/each}
              {/if}
            </div>
            <Icon
              tabindex="1"
              on:click={() => cleanTags()}
              class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing">
              keyboard_arrow_down
            </Icon>
          </Textfield>
          <MenuSurface
            class="menuSurfaceCustom"
            bind:this={formSurface}
            disabled={$activeSubSection == $_('progressioni')}
            anchorCorner="BOTTOM_LEFT">
            <Set chips={filteredTags} let:chip>
              <Chip tabindex="0" on:click={() => addChip(chip)}>
                <Text>{chip.name}</Text>
                {#if edit && chip.base !== 1}
                  <div on:click|stopPropagation={() => destroyTag(chip)}>
                    <Icon class="material-icons" trailing tabindex="0">
                      cancel
                    </Icon>
                  </div>
                {/if}
              </Chip>
            </Set>
            <div class="pushToEnd" style="display: flex;">
              {#if !edit}
                <Icon
                  id="dropdown-edit"
                  role="button"
                  tabindex="1"
                  class="material-icons"
                  on:click={() => (edit = !edit)}>
                  edit
                </Icon>
              {:else}
                <span
                  class="primary cursorPointer"
                  on:click={() => (edit = !edit)}>
                  Fatto
                </span>
              {/if}
            </div>
          </MenuSurface>
        </div>
      </div>
      <div class="col-4 mr-4 label">{$_('descrizione')}</div>
      <div class="col-7">
        <Textfield
          textarea
          class="fullWidth my-2"
          input$maxlength="5000"
          bind:value={exercise.note}
          disabled={$activeSubSection == $_('progressioni')}
          on:change={() => (formDirty = true)}
          label="" 
          style="min-height: 70px;">
          <CharacterCounter>{`${exercise.note.length} / 5000`}</CharacterCounter>
        </Textfield>
      </div>
      <div class="col-4 mr-4 label">{$_('link_a_video_youtube')}</div>
      <div class="col-7">
        <Textfield
          id="linkInput"
          bind:value={exercise.link}
          disabled={$activeSubSection == $_('progressioni')}
          on:change={() => (formDirty = true)}
          variant="outlined"
          input$maxlength="191"
          type="text"
          withTrailingIcon
          label=""
          class={`w100 my-2 textfield--large ${linkInvalid ? 'mdc-text-field--invalid' : ''}`}>
          <Icon
            role="button"
            tabindex="1"
            data-toggle="tooltip"
            data-placement="top"
            on:click={() => infoDialog.open()}
            class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing">
            help
          </Icon>
        </Textfield>
        {#if linkInvalid}
          {#if exercise.link.length <= 191}
            <FormMessage>{$_('il_link_inserito_non_è_valido')}</FormMessage>
          {:else}
            <FormMessage>{$_('il_link_inserito_supera_i_caratteri_consentiti')}</FormMessage>
          {/if}
        {/if}
      </div>
      <div class="col-4 mr-4 label" />
      <div class="col-12 col-md-7 uploadersWrapper my-2">
        <ExerciseVideoUploader
            on:updateExerciseVideo={handleVideoUpdated}
            converting={exercise.video && typeof exercise.video === 'string' && exercise.video.toLowerCase().endsWith('mov') && exercise.video_emc_status === 0 ? true : false}
            bind:triggerCloseVideo
            bind:video={exercise.video}
            bind:thumbnail={exercise.video_thumbnail} />
        <ExerciseImageUploader
          bind:exerciseImage={exercise.image}
          bind:exerciseImageThumbnail={exercise.image_thumbnail}
          bind:triggerCloseImageDialog
          on:error={handleImageError}
          on:updateExerciseImage={handleImageAdded} />
      </div>
    </div>

    {#if ($activeSubSection != $_('progressioni'))}
      <hr />

      <div class="row no-gutters py-2 pushToEnd">
        <Button class="btn" on:click={handleGoBack}>
          <Label>{$_('annulla')}</Label>
        </Button>
        <Button
          class="btn ml-3 mr-3"
          variant="unelevated"
          on:click={onSubmit}
          disabled={!formValid || saving}>
          <Label class="textUppercase">
            <span>
              {exercise.id && exercise.base == 1 ? $_($user.is_admin ? 'salva' : 'salva_come_nuovo') : exercise.id && exercise.base == 0 ? $_('salva') : $_('crea_nuovo_esercizio')}
            </span>
            {#if saving}
              <LoadingInline />
            {/if}
          </Label>
        </Button>
      </div>
    {/if}
  </div>
{/if}
