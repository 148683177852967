<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { deleteTrainingCard, getTrainingCardWorkouts } from "../../../../services/trainingCardServices";
  import { navigate } from "svelte-routing";
  import AssignedTrainingCardActions from "./AssignedTrainingCardActions.svelte";
  import AssignedTrainingCardInfo from "./AssignedTrainingCardInfo.svelte";
  import Card, { Content } from "@smui/card";
  
  export let groupId;
  export let isMobile;
  export let firstCards = [];
  export let nextCards = [];
  
  const dispatch = createEventDispatcher();
  const jq = window.$;
  
  let showMobileIcons;
  let withoutErasedEmptyFilteredCards = [];

  $: if (firstCards) {
    withoutErasedEmptyFilteredCards = firstCards.filter((fc) => {
      if (!fc.is_active)
        return;
      else return fc;
    });
  }

  const handleDeleteTrainingCard = async ({ detail }) => {
    const { id, is_active } = detail;
    try {
      console.log('AssignedTrainingCardsList | dispatch("removeTrainingCard")');
      await deleteTrainingCard(id);
      dispatch("removeTrainingCard", { id });
    } catch (err) {
      console.log("delete error", err);
      dispatch("onError", {});
    }
  };

  const printCard = async ({ detail }) => {
    const card = detail.tc;
    card.workouts = await getTrainingCardWorkouts(card.id);
    navigate("/print-training-card", {
      state: {
        card,
      },
    });
  };

  onMount(() => {
    isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1; 
  });
</script>

<style>
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
</style>

{#each withoutErasedEmptyFilteredCards as tc, i}
  <Card class="mb-3">
    <Content>
      <div class="row px-2 spaceBetween">
        <AssignedTrainingCardInfo {tc} {isMobile} on:updateDraftTrainingCard />
        <AssignedTrainingCardActions
          bind:showMobileIcons
          {tc}
          {groupId}
          {isMobile}
          index={i}
          on:print={printCard}
          on:deleteTrainingCard={handleDeleteTrainingCard} />
      </div>
    </Content>
  </Card>
{/each}
