<script>
    import { _ } from "../../services/i18n";
    import List, { Item, Separator, Text, Graphic } from "@smui/list";
    import { navigate } from "svelte-routing";
</script>

<List class="menuListItems">
    <Item on:click={() => window.open('https://www.revoo-app.com/ecosistema-revoo/', '_blank')}>
        <Text class="mainLabel">{$_('scopri_i_nostri_servizi')}</Text>
    </Item>
    <Separator />
    <Item on:click={() => window.open('https://www.revoo-app.com/crea-la-tua-app-personalizzata-con-revoo/', '_blank')}>
        <Graphic class="iconList material-icons">phonelink_setup</Graphic>
        <Text>{$_('app_personalizzata')}</Text>
    </Item>
    <Item on:click={() => window.open('https://www.revoo-app.com/diventa-partner-prozis-con-revoo/', '_blank')}>
        <Graphic class="iconList material-icons">whatshot</Graphic>
        <Text>{$_('prozis')}</Text>
    </Item>
    <!-- <Item on:click={() => window.open('https://www.revoo-app.com/crea-il-tuo-sito-web-revoo/', '_blank')}>
        <Graphic class="material-icons">language</Graphic>
        <Text>{$_('crea_il_tuo_sito_web')}</Text>
    </Item> -->
    <!-- {#if (window.location.host != "trainmobile.revoo-app.com")}
        <Item on:click={() => navigate("/referral-code")}>
            <Graphic class="material-icons">monetization_on</Graphic>
            <Text>{$_('porta_un_amico')}</Text>
        </Item>
    {/if} -->
</List>
  