<script>
	import { createEventDispatcher } from "svelte";
	import Card, { Content, Media } from "@smui/card";
	import PlaylistLabel from "./PlaylistLabel.svelte";

	export let item = {};
	export let shortExpiringDate = false;
	export let isMobile = false;

	const dispatch = createEventDispatcher();

	const redirectTo = () => {
		dispatch('redirectTo', { customerId: item.customer_id })
	}
</script>

<Card class="mb-2 position-relative dashboard-card" on:click={redirectTo(item.customer_id)}>
	<Content>
		<div class="row align-items-center">
			<div class="col-4 {isMobile ? `` : `pl-4 pr-2 d-flex justify-content-center align-items-center`}">
				{#if !isMobile}
					<Media class="card-media-square customUploader" aspectRatio="square">
						{#if item.image}
							<img
								src={item.image}
								alt=""
								style="min-width: 50px;"
								class="imagecustomer d-flex" />
						{:else}
							<div class="imageWrapper d-flex align-items-center {item.is_assigned_to_group ? `imageWrapper__group` : ``}"></div>
						{/if}
					</Media>
				{/if}
				<span class="demiBold assigneeName {isMobile ? `pl-0` : `pl-3`} ">
					{item.assignee_name}
				</span>
			</div>
			<div class="col-5 text-center item-overflow">
				{#if item.playlist_id}
					<PlaylistLabel mx={false} centered={true} />
				{/if}
				<div>
					<span class="nameWrapper">
						{item.name}
					</span>
				</div>
			</div>
			<div class="col-3 text-center">
				{shortExpiringDate ? item.expiring_date.substring(-5, 5) : item.expiring_date}
			</div>
		</div>
	</Content>
</Card>

<style>
	.assigneeName {
		opacity: 0.8;
		min-width: 75%;
	}

	:global(.dashboard-card) {
		cursor: pointer;
	}

	.imageWrapper {
    background-color: grey;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

	.item-overflow {
		overflow: hidden;
    text-overflow: ellipsis;
	}

	* :global(.imageWrapper__group) {
		background: var(--icon-active);
	}
  .imagecustomer {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    align-items: center;
    object-fit: cover;
  }

	* :global(.boxShadowBottom) {
    -webkit-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  }	
</style>