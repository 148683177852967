<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { deleteExercise, refreshExerciseData } from "../../services/exercisesServices";
  import { exercises, exercisesActivePage } from "../../lib/store";
  import { Icon } from "@smui/common";
  import { paginate, LightPaginationNav } from 'svelte-paginate'
  import Button, { Label } from "@smui/button";
  import Card, { Content } from "@smui/card";
  import Checkbox from "@smui/checkbox";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import DropdownTagFilter from "./DropdownTagFilter.svelte";
  import ExercisesList from "./ExercisesList.svelte";
  import IconButton from "@smui/icon-button";
  import NoItemsToShow from "../ReusableLayouts/NoItemsToShow.svelte";
  import Pagination from "../ReusableLayouts/Pagination.svelte";
  
  export let activeDropdownTagFilter;
  export let activePage = 1;
  export let activePaginationFilter;
  export let ascOrder;
  export let itemsPerPage = 20;
  export let listExercises = [];
  export let search;
  export let tagsFiltered;
  
  const dispatch = createEventDispatcher();
  
  let allSelected = false;
  let endpointCalls = 0;
  let endpointCallsInterval = 5000;
  let endpointCallsLimit = 5;
  let confirmationDeleteDialog;
  let filter = [];
  let filterDialog;
  let filterValue;
  let paginatedItems = [];
  let selected = [];
  let selectedCheckbox = [];
  let showButton = false;

  $: (async () => { 
    if (listExercises && Array.isArray(listExercises) && listExercises.length > 0) {
      // paginatedItems = paginate({ listExercises, itemsPerPage, activePage });

      paginatedItems = listExercises.slice(
        (activePage - 1) * itemsPerPage,
        (activePage - 1) * itemsPerPage + itemsPerPage
      )
      
      // Controllo esercizi da refreshare
      let notConvertedExercises = paginatedItems.filter(e => e.is_active === 1 && e.video !== null && e.video_emc_status === 0)
      if (notConvertedExercises.length > 0) {
        setTimeout(() => {
          checkConvertedMedia(notConvertedExercises);
        }, endpointCallsInterval);
      }
    }
  })();

  $: filterValue =
    selectedCheckbox.length > 0
      ? `${$_("filtra")} (${selectedCheckbox.length})`
      : $_("tutti");

  $: if (filter.length > 0)
    dispatch("tagsFiltered", {
      tags: filter,
  });

  const handleDeleteExercise = async (e) => {/*  */
    try {
      await deleteExercise(e.detail.id);
      listExercises = listExercises.map(i => {
        if (i.id === e.detail.id) 
          i.is_active = 0;
        return i
      });
      $exercises = $exercises.map(i => {
        if (i.id === e.detail.id) 
          i.is_active = 0;
        return i
      });
      dispatch('refreshExercises');
    } catch (err) {
      console.log("handleDeleteExercise err", err);
      dispatch("error", {});
    }
  };

  const deleteExercises = async () => {
    try {
      await deleteExercise(selected);
      selected.forEach(selectedId => {
        listExercises = listExercises.map(i => {
          if (i.id === selectedId) 
            i.is_active = 0;
          return i
        });
        $exercises = $exercises.map(i => {
          if (i.id === selectedId) 
            i.is_active = 0;
          return i
        });
      });
      selected = [];
      dispatch('refreshExercises');
      confirmationDeleteDialog.close();
    } catch (err) {
      console.log("handleDeleteExercises err", err);
      dispatch("error", {});
    }
  };

  const changeOrder = () => {
    dispatch("orderChange", { ascOrder: !ascOrder });
  };

  const checkConvertedMedia = async (notConvertedExercises) => {
    if (endpointCalls < endpointCallsLimit) {
      try {
        if (notConvertedExercises.length > 0) {
          notConvertedExercises.forEach(async (notConverteExercise) => {
            let itemUpdated = await refreshExerciseData(notConverteExercise.id);
            if (itemUpdated) {
              $exercises.map(exercise => {
                if (exercise.id === itemUpdated.id) {
                  exercise.video = itemUpdated.video;
                  exercise.video_emc_status = itemUpdated.video_emc_status;
                  exercise.video_thumbnail = itemUpdated.video_thumbnail;
                  exercise.video_thumbnail_emc_status = itemUpdated.video_thumbnail_emc_status;
                }
              });
              $exercises = $exercises;

              listExercises.map(exercise => {
                if (exercise.id === itemUpdated.id) {
                  exercise.video = itemUpdated.video;
                  exercise.video_emc_status = itemUpdated.video_emc_status;
                  exercise.video_thumbnail = itemUpdated.video_thumbnail;
                  exercise.video_thumbnail_emc_status = itemUpdated.video_thumbnail_emc_status;
                }
              });
              listExercises = listExercises;

              paginatedItems.map(exercise => {
                if (exercise.id === itemUpdated.id) {
                  exercise.video = itemUpdated.video;
                  exercise.video_emc_status = itemUpdated.video_emc_status;
                  exercise.video_thumbnail = itemUpdated.video_thumbnail;
                  exercise.video_thumbnail_emc_status = itemUpdated.video_thumbnail_emc_status;
                }
              });
              paginatedItems = paginatedItems;
            }
          });
        }
      } catch (error) {
        console.log('checkConvertedMedia', error);
      }
    }
    endpointCalls++;
  }

  onMount (() => {
    if (activePaginationFilter && $exercisesActivePage > 1) {
      dispatch("activePageChange", { page: $exercisesActivePage });
      activePage = $exercisesActivePage;
      exercisesActivePage.set(activePage);
      activePaginationFilter = false;
    } else {
      exercisesActivePage.set(1);
    }
  });
</script>

<style>
  .opacity--eighty {
    opacity: 0.8;
  }
  .center {
    align-items: center;
  }
  .buttonWrapper {
    align-items: center;
    justify-content: space-between;
  }
  .nameWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  .tagWrapper {
    text-align: center !important;
  }
  .nameWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 14px;
    justify-content: start;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  * :global(.mdc-card) {
    text-align: center;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }
  * :global(.boxShadowBottom) {
    -webkit-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  }
  .textWrapper {
    font-size: 1.25rem;
    opacity: 0.5;
    word-break: break-word;
  }

  @media (max-width: 767px) {
    .desktop {
      display: none;
    }
    .subheader__options {
      position: -webkit-sticky;
      position: sticky;
      top: 8.25rem;
      z-index: 2;
    }
  }
  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }

  /**
   * Light Pagination Nav
   */
  :global(.light-pagination-nav) :global(.pagination-nav) {
    box-shadow: none !important;
  }

  :global(.light-pagination-nav) :global(span.option.number) {
    text-decoration: none !important;
    font-weight: 400 !important;
    opacity: .5 !important;
  }

  :global(.light-pagination-nav) :global(span.option.number.active) {
    text-decoration: underline !important;
    font-weight: 700 !important;
    color: inherit !important;
    opacity: 1 !important;
  }

  :global(.light-pagination-nav) :global(.option.number),
  :global(.light-pagination-nav) :global(.option.ellipsis) {
    padding: 7px !important;
  }
  
</style>

<div class="container fluid desktop">
  <div class="row no-gutters buttonWrapper">
    <div class="w100 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center my-3">
        <Button
          class="btn mr-3"
          color="secondary"
          variant="unelevated"
          target="_blank"
          style={`display: ${allSelected || showButton ? 'block' : 'none'}`}
          on:click={() => confirmationDeleteDialog.open()}>
          <Label>{$_('elimina')}</Label>
        </Button>
      </div>
      <div>
        <DropdownTagFilter bind:filter={activeDropdownTagFilter} on:tagsFiltered />
      </div>
    </div>
  </div>
  <Card class="invisibleCard">
    <Content class="p-0">
      <div class="row">
        <div class="col-1">
          <Checkbox bind:checked={allSelected} />
        </div>
        <span
          class="col-5 mdc-typography--headline6 textTitle nameWrapper"
          on:click={changeOrder}>
          {$_('nome_esercizio')}
          <Icon
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing ml-2">
            {ascOrder ? 'arrow_drop_up' : 'arrow_drop_down'}
          </Icon>
        </span>
        <div class="col-4 mdc-typography--headline6 textTitle tagWrapper">
          {$_('tag')}
        </div>
      </div>
      <div class="">
        <div class="col-2 mdc-typography--headline6 textTitle" />
      </div>
    </Content>
  </Card>
  <hr class="line" />
</div>
<div class="subheader__options mobile">
  <Card class="boxShadowBottom mb-3">
    <Content class="container fluid py-0">
      <div class="row no-gutters align-items-center justify-content-between">
        <div class="allCheckbox">
          <Checkbox bind:checked={allSelected} />
        </div>
        <div style="padding-left:30px">
          <div class="row no-gutters align-self-end opacity--eighty">
            <div class="col-6 pr-0">
              <IconButton class="material-icons" on:click={changeOrder}>
                sort
              </IconButton>
            </div>
            <div class="col-6 pl-0">
              <IconButton
                class="material-icons"
                on:click={() => filterDialog.open()}>
                filter_list
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </Card>
  <div class="container fluid mobile mb-3">
    <Button
      class="btn"
      color="secondary"
      variant="unelevated"
      target="_blank"
      style={`display: ${allSelected || showButton ? 'block' : 'none'}`}
      on:click={() => confirmationDeleteDialog.open()}>
      <Label style="text-transform: none;margin-bottom: 0px;">
        {$_('elimina')}
      </Label>
    </Button>
  </div>
</div>
{#if listExercises.length > 0}
  <ExercisesList
    bind:activeDropdownTagFilter
    {allSelected}
    bind:activePage
    bind:selected
    bind:showButton
    exercises={paginatedItems}
    on:deleteExercise={handleDeleteExercise} />
  <LightPaginationNav
    totalItems="{listExercises.length}"
    pageSize="{itemsPerPage}"
    currentPage="{activePage}"
    limit="{2}"
    showStepOptions="{true}"
    on:setPage="{(e) => activePage = e.detail.page}"
  />
{:else if search || tagsFiltered.length > 0}
  <div class="container-fluid text-center my-4">
    <span class="textWrapper demiBold">{$_('nessun_esercizio_trovato')}</span>
  </div>
{:else}
  <NoItemsToShow
    upperText={$_('nessun_esercizio')}
    biggerText={$_('crea_i_tuoi_esercizi')}
    icon="emoji_people"
    buttonText={$_('nuovo_esercizio')}
    pathToNavigateTo="new-exercise" />
{/if}

<Dialog
  bind:this={filterDialog}
  aria-labelledby="list-title"
  aria-describedby="list-content">
  <Content>
    <div class="row no-gutters flexy">
      <div class="col-10 demiBold d-flex justify-content-center">{$_('filtra')}</div>
      <div class="col-2 closeIcon">
        <IconButton
          class="material-icons"
          on:click={() => filterDialog.close()}>
          clear
        </IconButton>
      </div>
    </div>
    <DropdownTagFilter on:tagsFiltered bind:filter={activeDropdownTagFilter} mobile={true} />
  </Content>
</Dialog>

<Dialog bind:this={confirmationDeleteDialog}>
  <ConfirmationDialog
    text={selected.length > 1 ? $_('sei_sicuro_di_voler_eliminare_gli_esercizi') : $_('sei_sicuro_di_voler_eliminare_l_esercizio')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => confirmationDeleteDialog.close()}
    on:onConfirmation={deleteExercises} />
</Dialog>
