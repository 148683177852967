import { POST, GET, PUT, PATCH, DELETE } from "../lib/http";
import {
  notificationStatus,
  oneSignalPlayerId,
  user,
  userData,
  jwt,
} from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const createCustomer = async (customer) => {
  const res = await POST(
    "/v2/create_customer",
    {
      body: customer,
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const createGroup = async (group) => {
  const res = await POST(
    "/v2/create_at_group",
    {
      body: group,
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const editGroup = async (group) => {
  const res = await POST(
    "/v2/update_at_group",
    {
      body: group,
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const updateGroupAutomations = async (group) => {
  const res = await POST(
    "/v2/groups/automations",
    {
      body: group,
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const updateMassAssignment = async (groupId, massAssignment) => {
  const res = await POST(
    "/v2/update_at_group_mass_assignment",
    {
      body: {
        group_id: groupId,
        mass_assignment: massAssignment === true ? 1 : 0,
      },
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const getGroupInfo = async (id) => {
  try {
    console.log("getGroupInfo", id);
    const res = await GET(`/v2/get_at_group_info/`, {
      headers: { ...commonHeaders },
      query: { id: id },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getAtGroups = async (
  per_page,
  page,
  name,
  order_by,
  order_by_dir
) => {
  try {
    const queryObj = {
      per_page: per_page,
      page: page,
    };
    name ? (queryObj.name = name) : null;
    queryObj.order_by = order_by ? order_by : "name";
    order_by_dir ? (queryObj.order_by_dir = "DESC") : null;

    const res = await GET("/v2/get_at_groups", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    const { data, last_page } = res;
    return { data, last_page };
  } catch (error) {
    console.log("getAtGroups err", error);
    throw error;
  }
};

export const getGroupEmails = async (groupIds) => {
  try {
    const queryObj = {
      group_ids: groupIds,
    };

    const res = await GET("/v2/get_at_group_emails", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getGroupEmails err", error);
    throw error;
  }
};

export const assignCustomerToGroup = async (customer, groupId) => {
  const res = await POST(
    "/v2/update_at_group_assignments",
    {
      body: {
        customer_id: customer.id,
        group_id: groupId,
        option: "attach",
      },
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const removeCustomerFromGroup = async (customer, groupId) => {
  const res = await POST(
    "/v2/update_at_group_assignments",
    {
      body: {
        customer_id: customer.id,
        group_id: groupId,
        option: "detach",
      },
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const getCustomersGroupRelations = async (
  groupId,
  filter,
  name,
  order_by,
  order_by_dir
) => {
  try {
    if (groupId) {
      let queryObj = {};
      name ? (queryObj.name = name) : null;
      queryObj.order_by = order_by ? order_by : "last_name";
      queryObj.group_id = groupId ? groupId : null;
      queryObj.filter = filter ? filter : "all";
      order_by_dir ? (queryObj.order_by_dir = "DESC") : "";
      const res = await GET("/v2/get_customers_group_relations", {
        headers: { ...commonHeaders },
        query: queryObj,
      });
      return res;
    }
    return false;
  } catch (error) {
    console.log("getCustomersGroupRelations err", error);
    throw error;
  }
};

export const getGroupTrainingCards = async (groupId) => {
  try {
    const res = await GET(`/v2/get_at_group_training_cards/${groupId}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getGroupVodsAndPlaylists = async (id, type = "all") => {
  try {
    const res = await GET(`/v2/get_at_group_vods_and_playlists/${id}`, {
      headers: { ...commonHeaders },
      query: {
        type,
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getGroupAssignedCustomers = async (groupId) => {
  try {
    let queryObj = {};
    queryObj.group_id = groupId ? groupId : null;
    const res = await GET("/v2/get_at_group_assigned_customers", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getAtGroupAssignedCustomers err", error);
    throw error;
  }
};

export const getInvitationCode = async (atGroupId = null) => {
  try {
    let queryObj = {};
    atGroupId ? (queryObj.at_group_id = atGroupId) : null;
    const res = await GET("/v2/get_invitation_code", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getInvitationCode err", error);
    throw error;
  }
};

export const updateDefaultCustomerActive = async (
  invitation_code,
  default_active_customer
) => {
  invitation_code = invitation_code.toString();
  const res = await POST(`/v2/update_default_customer_active/`, {
    headers: { ...commonHeaders },
    body: {
      invitation_code,
      default_active_customer,
    },
  });
  return res;
};

export const resetInvitationCode = async (invitation_code) => {
  invitation_code = invitation_code.toString();
  const res = await POST(`/v2/reset_invitation_code/`, {
    headers: { ...commonHeaders },
    body: {
      invitation_code,
    },
  });
  return res;
};

export const deleteCustomer = async (id) => {
  const res = await POST(`/v2/delete_customer/${id}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const deleteComposition = async (customerId, dateToDelete) => {
  const res = await DELETE(`/v2/pt/composition/${customerId}/${dateToDelete}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const deleteCircumferences = async (customerId, dateToDelete) => {
  const res = await DELETE(`/v2/pt/circumferences/${customerId}/${dateToDelete}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const deleteAtGroup = async (id) => {
  const res = await POST(`/v2/delete_at_group/${id}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const getCustomers = async (
  per_page,
  page,
  status,
  name,
  order_by,
  order_by_dir
) => {
  try {
    const queryObj = {
      per_page: per_page,
      page: page,
    };
    status ? (queryObj.status = status) : null;
    name ? (queryObj.name = name) : null;
    queryObj.order_by = order_by ? order_by : "last_name";
    order_by_dir ? (queryObj.order_by_dir = "DESC") : null;

    const res = await GET("/v2/pt/customers", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    const { data, last_page } = res;
    return { data, last_page };
  } catch (error) {
    if (error.message.message == "Unauthenticated." || error.status == 401) {
      console.log("Unauthenticated.");
      if (jwt === "") {
        console.log("Empty jwt.");
      } else {
        console.log("Resetting jwt.");
        jwt.set("");
        window.location.reload();
      }
    }
    console.log("getCustomers err", error);
    throw error;
  }
};

export const getCustomersInfo = async () => {
  try {
    const res = await GET(`/v2/get_customers_info`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    console.log("getCustomersInfo err", error);
    throw error;
  }
};

export const getCustomer = async (id) => {
  const res = await GET(`/get_customer/${id}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const getCustomerInfo = async (id) => {
  try {
    const res = await GET(`/v2/get_customer_info/${id}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getCustomerGraphic = async (customerId, property, start, end) => {
  try {
    if (!customerId || !property || !start || !end)
      throw new Error("parameter undefined");
    const res = await GET(
      `/v2/get_customer_graphic/${customerId}/${property}/${start}/${end}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateCustomerRelation = async (id, isActive) => {
  try {
    const res = await POST(`/v2/update_customer_relation`, {
      headers: { ...commonHeaders },
      body: { customer_id: id, is_active: isActive },
    });
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateCustomerProfile = async (customer) => {
  try {
    const res = await POST(`/v2/update_customer_profile`, {
      headers: { ...commonHeaders },
      body: customer,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const resendInvitationEmail = async (id, email) => {
  try {
    const res = await POST(`/v2/resend_customer_invitation_email`, {
      headers: { ...commonHeaders },
      body: { customer_id: id, email },
    });
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getMedicalHistories = async (id) => {
  const res = await GET(`/v2/get_medical_histories/${id}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const getNotes = async (customerId) => {
  const res = await GET(`/v2/notes?customer_id=${customerId}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const getMedicalHistory = async (customerId, historyId) => {
  const res = await GET(
    `/v2/get_medical_histories/${customerId}/${historyId}`,
    {
      headers: { ...commonHeaders },
    }
  );
  return res;
};

export const deleteMedicalHistory = async (id, customerId) => {
  const res = await POST(`/delete_anamnesi/${id}`, {
    headers: { ...commonHeaders },
    query: {
      customer_id: customerId,
    },
  });
  return res;
};

export const deleteNote = async (id) => {
  const res = await DELETE(`/v2/notes/${id}`, {
    headers: { ...commonHeaders },
  });
  return res;
};

export const cloneMedicalHistory = async (id) => {
  const res = await POST(`/v2/clone_medical_history`, {
    headers: { ...commonHeaders },
    body: {
      id,
    },
  });
  return res;
};

export const updateNote = async (note, noteId) => {
  try {
    const res = await PATCH(`/v2/notes/${noteId}`, {
      body: { title: note.title, content: note.content, date: note.date },
    });
  } catch (err) {
    throw err;
  }
};

export const updateMedicalHistory = async (history, historyId, customerId) => {
  try {
    history.created_by = user.id;
    history.created_at = history.date_of_creation;
    delete history.date_of_creation;
    delete history.customer;
    const res = await POST(
      `/v2/update_medical_history/${customerId}/${historyId}`,
      {
        headers: { ...commonHeaders },
        body: {
          ...history,
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createNewMedicalHistory = async (history, customerId) => {
  const res = await POST(`/v2/new_medical_history/${customerId}`, {
    headers: { ...commonHeaders },
    body: {
      ...history,
    },
  });
  return res;
};

export const createNewNote = async (note, customerId) => {
  const res = await POST(`/v2/notes`, {
    headers: { ...commonHeaders },
    body: {
      ...note,
      customer_id: customerId,
    },
  });
  return res;
};

export const createNewComposition = async (body) => {
  try {
    const res = await POST(`/v2/new_composition`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const createNewCircumference = async (body) => {
  try {
    const res = await POST(`/v2/new_circumference`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const createNewPlicometry = async (body) => {
  try {
    const res = await POST(`/v2/new_plicometry`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const createNewWeight = async (body) => {
  try {
    const res = await POST(`/v2/new_weight`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCircumferences = async (customerId, date) => {
  try {
    const res = await GET(`/v2/get_circumferences/${customerId}/${date} `, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLastCircumferences = async (customerId) => {
  try {
    const res = await GET(`/v2/get_last_circumferences/${customerId}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCompositions = async (customerId, date) => {
  try {
    const res = await GET(`/v2/get_composition/${customerId}/${date} `, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getPlicometry = async (customerId, date) => {
  try {
    const res = await GET(`/v2/get_plicometry`, {
      headers: { ...commonHeaders },
      query: {
        created_at: date,
        customer_id: customerId,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLastCompositions = async (customerId, date) => {
  try {
    const res = await GET(`/v2/get_last_composition/${customerId} `, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getProgressEnabledDates = async (customerId, date) => {
  try {
    const res = await GET(`/v2/get_progress_enabled_dates/${customerId} `, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getFitnessSync = async (customerId) => {
  try {
    const res = await GET(`/get_fitness_sync/${customerId} `, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCustomerGraphicFit = async (
  customerId,
  property,
  start,
  end
) => {
  try {
    if (!customerId || !property || !start || !end)
      throw new Error("parameter undefined");
    const res = await GET(
      `/get_customer_graphic_fit/${customerId}/${property}/${start}/${end}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getToUpdateHubspotPt = async () => {
  try {
    const res = await GET("/v2/update_hubspot_pt", {
      headers: { ...commonHeaders },
    });
    notificationStatus.set(res.notifications ? res.notifications : {});
    userData.set({
      chat_enabled        : res.chat_enabled        ? res.chat_enabled        : 0,
      show_vod_categories : res.show_vod_categories ? res.show_vod_categories : 0,
      image               : res.profile_img         ? res.profile_img         : null,
      is_alpha_tester     : res.is_alpha_tester     ? res.is_alpha_tester     : 0,
      is_beta_tester      : res.is_beta_tester      ? res.is_beta_tester      : 0,
      is_tester           : res.is_tester           ? res.is_tester           : 0,
      logo                : res.logo_img            ? res.logo_img            : null,
      show_base_exercises : res.show_base_exercises ? 1                       : 0,
      tc_cover_enabled    : res.tc_cover_enabled    ? 1                       : 0,
      xtravision_id       : res.xtravision_id       ? res.xtravision_id       : null,
    });
  } catch (error) {
    throw error;
  }
};

export const updateOneSignalPlayerId = async (oneSignalPlayerId, userId) => {
  try {
    if (
      oneSignalPlayerId &&
      oneSignalPlayerId != null &&
      oneSignalPlayerId != "null"
    ) {
      console.log("Updating oneSignalPlayerId - " + oneSignalPlayerId);
      const res = await POST("/v2/update_one_signal_player_id", {
        body: {
          oneSignalPlayerId: oneSignalPlayerId,
          userId: userId,
        },
        headers: { ...commonHeaders },
      });
      return res;
    }
  } catch (err) {
    throw err;
  }
};

export const getGoal = async (customerId) => {
  try {
    const queryObj = {
      customer_id: customerId,
    };
    const res = await GET("/v2/get_goal", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getGoal err", error);
  }
};

export const setGoal = async (customerId, goalDescription, goalId = false) => {
  try {
    if (!goalId || isNaN(goalId)) {
      const res = await POST("/v2/store_goal", {
        body: {
          customer_id: customerId,
          goal_description: goalDescription,
        },
        headers: { ...commonHeaders },
      });
      return res;
    } else {
      const res = await PUT("/v2/store_goal", {
        body: {
          customer_id: customerId,
          goal_description: goalDescription,
          goal_id: goalId,
        },
        headers: { ...commonHeaders },
      });
      return res;
    }
  } catch (error) {
    console.log("setGoal err", error);
    throw error;
  }
};

export const getEquipment = async (customerId) => {
  try {
    const queryObj = {
      customer_id: customerId,
    };
    const res = await GET("/v2/get_equipment", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getEquipment err", error);
  }
};

export const setEquipment = async (
  customerId,
  equipmentDescription,
  equipmentId = false
) => {
  try {
    if (!equipmentId || isNaN(equipmentId)) {
      const res = await POST("/v2/store_equipment", {
        body: {
          customer_id: customerId,
          description: equipmentDescription,
        },
        headers: { ...commonHeaders },
      });
      return res;
    } else {
      const res = await PUT("/v2/store_equipment", {
        body: {
          customer_id: customerId,
          description: equipmentDescription,
          equipment_id: equipmentId,
        },
        headers: { ...commonHeaders },
      });
      return res;
    }
  } catch (error) {
    console.log("setEquipment err", error);
  }
};

export const updateChatEnabledForNewMembers = async (body) => {
  const res = await PATCH(
    "/v2/chat/pt/enable_new_at_group_members",
    {
      body,
      headers: { ...commonHeaders },
    },
    true
  );
  return res;
};

export const updateChatEnabledForCustomer = async (body) => {
  const res = await PATCH(
    "/v2/chat/pt/enable_customer",
    {
      body,
      headers: { ...commonHeaders },
    },
    true
  );
  return res;
};
