export const getProgressCheckTypes = (context) => {
  return [
    {
      value: 0,
      unit: context("kg"),
      key: "weights",
      label: context("peso"),
      group: "composition",
    },
    {
      value: 0,
      unit: '',
      key: "metabolic_age",
      label: context("età_metabolica"),
      group: "composition",
    },
    {
      value: 0,
      unit: context("kg"),
      key: "lean_mass_kg",
      label: context("massa_magra"),
      group: "composition",
    },
    {
      value: 0,
      unit: context("kg"),
      key: "bone_mass_kg",
      label: context("massa_ossea"),
      group: "composition",
    },
    {
      value: 0,
      unit: context("%"),
      key: "body_fat_perc",
      label: context("massa_grassa"),
      group: "composition",
    },
    {
      value: 0,
      unit: context("%"),
      key: "visceral_fat",
      label: context("grasso_viscerale"),
      group: "composition",
    },
    {
      key: "bmr",
      label: "BMR",
      group: "composition",
      value: 0,
      unit: context("cal"),
    },
    {
      key: "tda",
      label: "TDEE",
      group: "composition",
      value: 0,
      unit: context("cal"),
    },
    {
      key: "tbw",
      label: "TBW",
      group: "composition",
      value: 0,
      unit: context("%"),
    },
    {
      value: 0,
      unit: '',
      key: "body_composition",
      label: "BMI",
      group: "composition",
    },
    {
      value: 0,
      unit: context("simbolo_c"),
      key: "body_temperature",
      label: context("temperatura_corporea"),
      group: "composition",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "bicep_r",
      label: context("bicipite_destro"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "bicep_l",
      label: context("bicipite_sinistro"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "chest",
      label: context("petto"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "waist",
      label: context("punto_vita"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "hip",
      label: context("fianchi"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "leg_r",
      label: context("gamba_destra"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "leg_l",
      label: context("gamba_sinistra"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "calf_r",
      label: context("polpaccio_destro"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: context("cm"),
      key: "calf_l",
      label: context("polpaccio_sinistro"),
      group: "circumferences",
    },
    {
      value: 0,
      unit: '',
      key: "steps",
      label: context("passi"),
      group: "google_fit",
    },
    {
      value: 0,
      unit: context("m"),
      key: "distance",
      label: context("distanza"),
      group: "google_fit",
    },
    {
      value: 0,
      unit: context("kcal"),
      key: "calories",
      label: context("calorie_bruciate"),
      group: "google_fit",
    },
    {
      value: 0,
      unit: context("g"),
      key: "nutrition.fat.total",
      label: context("grassi"),
      group: "google_fit",
    },
    {
      value: 0,
      unit: context("g"),
      key: "nutrition.carbs.total",
      label: context("carboidrati"),
      group: "google_fit",
    },
    {
      value: 0,
      unit: context("g"),
      key: "nutrition.protein",
      label: context("proteine"),
      group: "google_fit",
    },
  ];
};

export const getCompositionTypes = (context) => {
  return [
    [
      {
        value: null,
        unit: context("kg"),
        key: "weight_base",
        label: context("peso"),
        group: "composition",
      },
      {
        value: null,
        unit: null,
        key: "metabolic_age",
        label: context("età_metabolica"),
        group: "composition",
      },
    ],
    [
      {
        value: null,
        unit: context("kg"),
        key: "lean_mass_kg",
        label: context("massa_magra"),
        group: "composition",
      },
      {
        value: null,
        unit: context("kg"),
        key: "bone_mass_kg",
        label: context("massa_ossea"),
        group: "composition",
      },
    ],
    [
      {
        value: null,
        unit: context("%"),
        key: "body_fat_perc",
        label: context("massa_grassa"),
        group: "composition",
      },
      {
        value: null,
        role: 'button',
        unit: null,
        key: "plicometry",
        label: null,
        group: "composition",
      },
    ],
    [
      {
        value: null,
        unit: context("%"),
        key: "visceral_fat",
        label: context("grasso_viscerale"),
        group: "composition",
      },
      {
        key: "bmr",
        label: "BMR",
        group: "composition",
        value: null,
        unit: null,
      },
    ],
    [
      {
        key: "tda",
        label: "TDEE",
        group: "composition",
        value: null,
        unit: context("cal"),
      },
      {
        key: "tbw",
        label: "TBW",
        group: "composition",
        value: null,
        unit: context("%"),
      },
    ],
    [
      {
        value: null,
        unit: '',
        key: "body_composition",
        label: "BMI",
        group: "composition",
      },
      {
        value: null,
        unit: context("simbolo_c"),
        key: "body_temperature",
        label: context("temperatura_corporea"),
        group: "composition",
      },
    ],
  ];
};

export const getPlicometryTypes = (context, protocol) => {
  let female_7_plics = [
    [
      {
        value: null,
        unit: context("mm"),
        key: "tricep",
        label: context("tricipite"),
        protocol,
      },
      {
        value: null,
        unit: context("mm"),
        key: "abdominal",
        label: context("addome"),
        protocol,
      },
    ],
    [
      {
        value: null,
        unit: context("mm"),
        key: "suprailiac",
        label: context("soprailiaca"),
        protocol,
      },
      {
        value: null,
        unit: context("mm"),
        key: "subscapularis",
        label: context("sottoscapolare"),
        protocol,
      },
    ],
    [
      {
        value: null,
        unit: context("mm"),
        key: "axillary",
        label: context("ascellare"),
        protocol,
      },
      {
        value: null,
        unit: context("mm"),
        key: "chest",
        label: context("pettorale"),
        protocol,
      },
    ],
    [
      {
        value: null,
        unit: context("mm"),
        key: "leg",
        label: context("coscia"),
        protocol,
      },
      {
        value: null,
        unit: null,
        key: "age",
        label: context("età"),
        protocol,
      },
    ],
  ];

  let male_7_plics = female_7_plics;

  let female_3_plics = [
    [
      {
        value: null,
        unit: context("mm"),
        key: "tricep",
        label: context("tricipite"),
        protocol,
      },
      {
        value: null,
        unit: context("mm"),
        key: "suprailiac",
        label: context("soprailiaca"),
        protocol,
      },
    ],
    [
      {
        value: null,
        unit: context("mm"),
        key: "leg",
        label: context("coscia"),
        protocol,
      },
      {
        value: null,
        unit: null,
        key: "age",
        label: context("età"),
        protocol,
      },
    ],
  ];

  let male_3_plics = [
    [
      {
        value: null,
        unit: context("mm"),
        key: "abdominal",
        label: context("addome"),
        protocol,
      },
      {
        value: null,
        unit: context("mm"),
        key: "chest",
        label: context("pettorale"),
        protocol,
      },
    ],
    [
      {
        value: null,
        unit: context("mm"),
        key: "leg",
        label: context("coscia"),
        protocol,
      },
      {
        value: null,
        unit: null,
        key: "age",
        label: context("età"),
        protocol,
      },
    ],
  ];

  switch (protocol) {
    case 'female_3_plics' : return female_3_plics;
    case 'female_7_plics' : return female_7_plics;
    case 'male_3_plics'   : return male_3_plics;
    case 'male_7_plics'   : return male_7_plics;
  }
};

export const getCicumferenceTypes = (context) => {
  return [
    [
      {
        value: null,
        unit: context("cm"),
        key: "bicep_r",
        label: context("bicipite_destro"),
        group: "circumferences",
      },
      {
        value: null,
        unit: context("cm"),
        key: "bicep_l",
        label: context("bicipite_sinistro"),
        group: "circumferences",
      },
    ],
    [
      {
        value: null,
        unit: context("cm"),
        key: "chest",
        label: context("petto"),
        group: "circumferences",
      },
      {
        value: null,
        unit: context("cm"),
        key: "waist",
        label: context("punto_vita"),
        group: "circumferences",
      },
    ],
    [
      {
        value: null,
        unit: context("cm"),
        key: "hip",
        label: context("fianchi"),
        group: "circumferences",
      },
    ],
    [
      {
        value: null,
        unit: context("cm"),
        key: "leg_r",
        label: context("gamba_destra"),
        group: "circumferences",
      },
      {
        value: null,
        unit: context("cm"),
        key: "leg_l",
        label: context("gamba_sinistra"),
        group: "circumferences",
      },
    ],
    [
      {
        value: null,
        unit: context("cm"),
        key: "calf_r",
        label: context("polpaccio_destro"),
        group: "circumferences",
      },
      {
        value: null,
        unit: context("cm"),
        key: "calf_l",
        label: context("polpaccio_sinistro"),
        group: "circumferences",
      },
    ],
  ];
};