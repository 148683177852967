<script>
  import { _ } from "../../../../services/i18n";
  import { checkHistoryValidity } from "./MedicalHistoriesValidation/medicalHistoriesValidation";
  import { createEventDispatcher } from "svelte";
  import { navigate } from "svelte-routing";
  import Button, { Label } from "@smui/button";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import Textfield from "@smui/textfield";
  
  export let active;
  export let allergies = "";
  export let customerId;
  export let disorders = "";
  export let history;
  export let intestinal_irregularity = "";
  export let intolerance = "";
  export let supplements = "";
  export let supply = "";

  const dispatch = createEventDispatcher();

  let exitDialog;
  let formValid;
  let historyModified;
  let msgError;

  $: {
    historyModified = {
      ...history
    };
    historyModified.supply = supply;
    historyModified.supplements = supplements;
    historyModified.intolerance = intolerance;
    historyModified.allergies = allergies;
    historyModified.disorders = disorders;
    historyModified.intestinal_irregularity = intestinal_irregularity;
  }
  $: if (history) {
    formValid = checkHistoryValidity(historyModified);
  }

  const onSubmit = () => {
    dispatch("saveHistory", {
      history: {
        supply,
        supplements,
        intolerance,
        allergies,
        disorders,
        intestinal_irregularity,
        is_draft: false
      }
    });
  };

  const updateHistory = () => {
    dispatch("updateHistory", {
      history: {
        supply,
        supplements,
        intolerance,
        allergies,
        disorders,
        intestinal_irregularity,
        is_draft: true
      }
    });
  };
</script>

<style>
  .label {
    text-align: end;
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.textUppercase) {
    text-transform: uppercase;
  }
</style>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={() => navigate(`/customer?tab=histories`, {
        state: {customerId}
      })} />
</Dialog>

<div class="py-3">
  <div class="row">
    <div class="col-4 label">{$_('alimentazione')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={supply}
        label="">
        <CharacterCounter>{`${supply.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('utilizzo_integratori')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={supplements}
        label="">
        <CharacterCounter>{`${supplements.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('intolleranze')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={intolerance}
        label="">
        <CharacterCounter>{`${intolerance.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('allergie')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={allergies}
        label="">
        <CharacterCounter>{`${allergies.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('disturbi_alimentari')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={disorders}
        label="">
        <CharacterCounter>{`${disorders.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('irregolarita_intestinale')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={intestinal_irregularity}
        label="">
        <CharacterCounter>
          {`${intestinal_irregularity.length} / 2000`}
        </CharacterCounter>
      </Textfield>
    </div>
  </div>

  <hr />

  <MsgError {msgError} />

  <div class="row pushToEnd">
    <Button class="btn" on:click={() => exitDialog.open()} disabled={!active}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-3"
      variant="outlined"
      on:click={updateHistory}
      disabled={!formValid || !active}>
      <Label>{$_('salva_come_bozza')}</Label>
    </Button>
    <Button
      class="btn ml-3 mr-3"
      variant="unelevated"
      on:click={onSubmit}
      disabled={!formValid || !active}>
      <Label class="textUppercase">{$_('invia')}</Label>
    </Button>
  </div>

</div>
