import { POST, GET } from "../lib/http";
// import { user } from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const getUsers = async (per_page, page, name) => {
  try {
    const queryObj = {
      per_page,
      page,
    };
    if (name) queryObj.name = name;
    const res = await GET(
      "/admin/users",
      {
        headers: { ...commonHeaders },
        query: queryObj
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const getDeals = async () => {
  try {
    const res = await GET("/admin/show_deals", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateDeal = async (deal) => {
  try {
    const res = await POST(
      "/admin/update_deal",
      {
        body: deal,
        headers: { ...commonHeaders },
      },
      true,
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveDealOrder = async (order) => {
  try {
    const res = await POST(
      "/admin/sort_deals",
      {
        body: order,
        headers: { ...commonHeaders },
      },
      true,
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateUser = async (user) => {
  try {
    const res = await POST(
      "/admin/update_user",
      {
        body: user,
        headers: { ...commonHeaders },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const sendEmails = async (users) => {
  try {
    const res = await POST(
      "/admin/send_emails",
      {
        body: users,
        headers: { ...commonHeaders },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteUsers = async (users) => {
  try {
    const res = await POST(
      "/admin/delete_users",
      {
        body: users,
        headers: { ...commonHeaders },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};


export const getPTs = async (per_page, page, name) => {
  try {
    const queryObj = {
      per_page,
      page,
    };
    if (name) queryObj.name = name;
    const res = await GET(
      "/v2/admin/get_pts",
      {
        headers: { ...commonHeaders },
        query: queryObj
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const getPT = async (ptId) => {
  try {
    const res = await GET(
      `/v2/admin/get_pt/${ptId}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const storePricing = async (pricing) => {
  try {
    const res = await POST(
      "/v2/admin/store_pt_pricing",
      {
        body: pricing,
        headers: { ...commonHeaders },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const deletePricing = async (pricing) => {
  try {
    const res = await POST(
      "/v2/admin/delete_pt_pricing",
      {
        body: pricing,
        headers: { ...commonHeaders },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};


export const getGroups = async () => {
  try {
    const res = await GET(
      "/v2/admin/get_groups",
      {
        headers: { ...commonHeaders },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const getGroup = async (groupId) => {
  try {
    const res = await GET(
      `/v2/admin/get_group/${groupId}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const storeGroup = async (group) => {
  try {
    const res = await POST(
      "/v2/admin/store_group",
      {
        body: group,
        headers: { ...commonHeaders },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteGroup = async (groupId) => {
  try {
    const res = await POST(
      "/v2/admin/delete_group",
      {
        body: groupId,
        headers: { ...commonHeaders },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const updatePTsGroup = async (ptsGroup) => {
  try {
    const res = await POST(
      "/v2/admin/update_pts_group_id",
      {
        body: ptsGroup,
        headers: { ...commonHeaders },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const disableCustomers = async (body) => {
  try {
    const res = await POST(`/admin/disable_customer_pt_relations`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}