<script>
  import { _, locale, setupI18n } from "../../services/i18n";
  import { checkCorrectPassword, updatePassword, updateChatEnabledForNewCustomers } from "../../services/userServices";
  import { checkPassword } from "../Auth/validation/authValidation";
  import { createEventDispatcher } from "svelte";
  import { Icon } from "@smui/common";
  import { user } from "../../lib/store";
  import Button, { Label } from "@smui/button";
  import FormMessage from "../ReusableLayouts/FormMessage.svelte";
  import LocaleSelector from "../ReusableLayouts/LocaleSelector.svelte";
  import Switch from "@smui/switch";
  import Textfield from "@smui/textfield";

  const dispatch = createEventDispatcher();

  let chatEnabledForNewCustomers = false;
  let formValid = false;
  let newPassword = "";
  let newPasswordInvalid = false;
  let password = "";
  let passwordInvalid = false;
  let repeatNewPassword = "";
  let repeatNewPasswordInvalid = false;
  let showPassword = false;

  const validatePassword = async () => {
    try {
      const body = {
        oldPassword: password,
        email: $user.email,
      };
      const res = await checkCorrectPassword(body);
      passwordInvalid = !res;
    } catch (error) {
      console.log("error during check password", error);
    }
  };

  $: formValid =
    password &&
    newPassword &&
    repeatNewPassword &&
    !passwordInvalid &&
    !newPasswordInvalid &&
    !repeatNewPasswordInvalid;

  $: if (password) {
    validatePassword();
  }

  $: if (newPassword) {
    newPasswordInvalid = !checkPassword(newPassword);
  }

  $: if (repeatNewPassword) {
    repeatNewPasswordInvalid = repeatNewPassword !== newPassword;
  }

  $: if ($user && $user.chat_enabled_for_new_customers) {
    chatEnabledForNewCustomers = $user.chat_enabled_for_new_customers;
  }

  const handleChatEnabledChanged = async () => {
    await updateChatEnabledForNewCustomers({chat_enabled_for_new_customers: !chatEnabledForNewCustomers});
  };

  const submit = async () => {
    try {
      await updatePassword({
        password: newPassword,
      });
      password = "";
      newPassword = "";
      repeatNewPassword = "";
      console.log("submit");
    } catch (error) {
      console.log("error modify password", error);
    }
  };
</script>

<style>
  .alignColumnsStart {
    align-items: flex-start;
  }
  * :gloabal(.mdc-select.mdc-select--outlined) {
    padding-top: 0;
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
</style>

<div class="row flexy py-5 alignColumnsStart">
  <div class="col-md-5">
    <form
      name="signin"
      on:submit|preventDefault={submit}
      on:change={formValid}
      on:input={formValid}>
      <h4 class="mb-3 demiBold">{$_('modifica_password')}</h4>
      <div class="row no-gutters mb-3">
        <Textfield
          variant="outlined"
          bind:value={password}
          bind:invalid={passwordInvalid}
          withTrailingIcon
          type={showPassword ? 'text' : 'password'}
          label={$_('vecchia_password')}
          class={`textbox w100 my-2 ${passwordInvalid} ? 'mdc-text-field--invalid' : ''}`}>
          <Icon
            on:click={() => {
              showPassword = !showPassword;
            }}
            role="button"
            tabindex="1"
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing">
            {showPassword ? 'visibility_off' : 'visibility'}
          </Icon>
        </Textfield>
        {#if passwordInvalid}
          <FormMessage>{$_('password_non_corretta')}</FormMessage>
        {/if}
      </div>
      <div class="row no-gutters mb-3">
        <Textfield
          variant="outlined"
          bind:value={newPassword}
          bind:invalid={newPasswordInvalid}
          withTrailingIcon
          type={showPassword ? 'text' : 'password'}
          label={$_('nuova_password')}
          class={`textbox w100 my-2 ${newPasswordInvalid} ? 'mdc-text-field--invalid' : ''}`}>
          <Icon
            on:click={() => {
              showPassword = !showPassword;
            }}
            role="button"
            tabindex="1"
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing">
            {showPassword ? 'visibility_off' : 'visibility'}
          </Icon>
        </Textfield>
        {#if newPasswordInvalid}
          <FormMessage>{$_('la_password_non_rispetta_i_criteri')}</FormMessage>
        {/if}
      </div>
      <div class="row no-gutters mb-3">
        <Textfield
          variant="outlined"
          bind:value={repeatNewPassword}
          bind:invalid={repeatNewPasswordInvalid}
          withTrailingIcon
          type={showPassword ? 'text' : 'password'}
          label={$_('ripeti_nuova_password')}
          class={`textbox w100 my-2 ${repeatNewPasswordInvalid} ? 'mdc-text-field--invalid' : ''}`}>
          <Icon
            on:click={() => {
              showPassword = !showPassword;
            }}
            role="button"
            tabindex="1"
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing">
            {showPassword ? 'visibility_off' : 'visibility'}
          </Icon>
        </Textfield>
        {#if repeatNewPasswordInvalid}
          <FormMessage>{$_('password_non_coincidono')}</FormMessage>
        {/if}
      </div>

      <hr />

      <Button
        type="submit"
        disabled={!formValid}
        class="btn ml-5 float-right"
        variant="unelevated">
        <Label>{$_('modifica_password')}</Label>
      </Button>
    </form>
  </div>

  <div class="col-md-5 ml-auto">
    <h4 class="mb-3 demiBold">{$_('lingua')}</h4>
    <div class="row no-gutters mb-3 pb-3">
      <LocaleSelector
        language={$locale}
        on:locale-changed={(e) => {
          setupI18n({ withLocale: e.detail });
          dispatch('locale-changed', e.detail);
        }} />
    </div>

    {#if $user.chat_enabled}
      <h4 class="mt-2 mb-3 demiBold">{$_('chat')}</h4>
      <div class="row">
        <div class="col-9 label">
          {$_('attiva_chat_per_i_nuovi_clienti')}
        </div>
        <div class="col-3">
          <Switch on:change={handleChatEnabledChanged} bind:checked={chatEnabledForNewCustomers} />
        </div>
      </div>
    {/if}

    <h4 class="mt-4 pt-2 mb-3 demiBold">{$_('eliminazione_account')}</h4>
    <div class="row">
      <div class="col-12">
        <Button
          class="btn btn-danger"
          variant="outlined"
          on:click={() => {window.open('https://www.revoo-app.com/richiesta-eliminazione-account-revoo/', '_blank');}}>
          <Label>{$_('elimina_account')}</Label>
        </Button>
      </div>
    </div>
  </div>
</div>
