<script>
  import { _ } from "../../../../services/i18n";
  import { navigate } from "svelte-routing";
  import Header from "../../../ReusableLayouts/Header.svelte";
  import NewPlaylistInfo from "./NewPlaylistInfo.svelte";
  import { createPlaylist, editPlaylist } from "../../../../services/vodsServices.js";

  export let location;

  let playlistToBeEdited = '';
  let imageValid = true;
  let msgError;
  let edit = false;

  if (location && location.state.playlistToBeEdited && location.state.playlistToBeEdited.playlist_id != null)  {
    edit = true;
    playlistToBeEdited = location.state.playlistToBeEdited;
  }

  const handleCreatePlaylist = async (e) => {
    try {
      console.log(e.detail);
      const body = { ...e.detail.playlist };
      const nextUrl = e.detail.nextUrl;
      const res = await createPlaylist(body);
      navigate(nextUrl, { state: { playlistId: res.playlist_id }} 
      );
    } catch (err) {
      console.log("handleCreatePlaylist err", err);
      msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 4000);
    }
  };

  const handleEditPlaylist = async (e) => {
    try {
      const body = { ...e.detail.playlist };
      const res = await editPlaylist(body);
      navigate("/vods?tab=playlist", { state: { playlistId: res.playlist_id } });
    } catch (err) {
      console.log("handleEditPlaylist err", err);
      msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 4000);
    }
  };
</script>

<style>
  * :global(.customCard) {
    margin: 0 auto;
    text-align: center;
    border: none;
  }
  * :global(.customUploader) {
    height: 200px;
    width: 200px;
    border-radius: 50%;
  }
</style>

<Header
  text={$_('indietro')}
  asTextAndIcon={true}
  noBackground={true}
  on:goBack={() => navigate('/vods?tab=playlist')} />

<div class="container fluid mt-4">
  <div class="row">
    {#if msgError}
      <div class="col-12 alert alert-danger fade show" role="alert">
        {msgError}
      </div>
    {/if}

    <div class="col-xs-12 col-lg-8">
      <NewPlaylistInfo 
        {imageValid} 
        {playlistToBeEdited} 
        {edit} 
        on:createPlaylist={handleCreatePlaylist}
        on:editPlaylist={handleEditPlaylist} />
    </div>
  </div>
</div>
