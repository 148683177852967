<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { customer } from "../../../../lib/store";
  import { navigate } from "svelte-routing";
  import Button, { Label, Icon } from "@smui/button";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import Checkbox from "@smui/checkbox";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import DatePicker from "../../../ReusableLayouts/DatePicker.svelte";
  import Dialog from "@smui/dialog";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import moment from "moment";
  import Textfield from "@smui/textfield";

  export let active;
  export let asthma = false;
  export let contraceptive_pill = false;
  export let customerId;
  export let diabetes = false;
  export let drugs = "";
  export let fractures = "";
  export let history;
  export let irregular_periods = "";
  export let last_revelation = "";
  export let mmhg = "";
  export let other_pathologies = "";
  export let postural = "";
  export let problems_cardiac = false;
  export let problems_thyroid = false;
  export let surgical = "";

  const dateDBFormat = "YYYY-MM-DD";
  const datePickerFormat = "DD/MM/YYYY";
  const dispatch = createEventDispatcher();

  let bloodPressureInvalid = false;
  let exitDialog;
  let formInvalid = false;
  let msgError;

  $: if (mmhg) {
    bloodPressureInvalid = /[a-zA-Z]/g.test(mmhg);
  } else {
    bloodPressureInvalid = false;
  }
  $: formInvalid = !active || bloodPressureInvalid;

  const handleDateChange = (e) => {
    last_revelation = moment(e.detail.date, datePickerFormat).format(dateDBFormat);
  };

  const updateHistory = () => {
    dispatch("updateHistory", {
      history: {
        asthma,
        problems_thyroid,
        problems_cardiac,
        diabetes,
        mmhg,
        last_revelation,
        surgical,
        postural,
        irregular_periods,
        contraceptive_pill,
        drugs,
        fractures,
        other_pathologies,
        is_draft: true,
      },
    });
  };

  const onGoForth = () => {
    dispatch("goForth", {
      history: {
        asthma,
        problems_thyroid,
        problems_cardiac,
        diabetes,
        mmhg,
        last_revelation,
        surgical,
        postural,
        irregular_periods,
        contraceptive_pill,
        drugs,
        fractures,
        other_pathologies,
        is_draft: true,
      },
      tab: $_("alimentare"),
    });
  };
</script>

<style>
  .label {
    text-align: end;
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  .aligned {
    display: flex;
    align-items: center;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.textUppercase) {
    text-transform: uppercase;
  }
  * :global(.mdc-text-field__icon--trailing.customText) {
    opacity: 0.5;
    color: #212121;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-style: normal;
  }
</style>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={() => navigate(`/customer?tab=histories`, {
        state: { customerId },
      })} />
</Dialog>

<div class="py-3">
  <div class="row">
    <div class="col-4 label">{$_('patologie_diagnosticate')}</div>
    <div class="col-8">
      <div class="row">
        <div class="col-6 aligned">
          <Checkbox bind:checked={asthma} />
          {$_('asma')}
        </div>
        <div class="col-6 aligned">
          <Checkbox bind:checked={problems_thyroid} />
          {$_('problemi_tiroide')}
        </div>
      </div>
      <div class="row">
        <div class="col-6 aligned">
          <Checkbox bind:checked={problems_cardiac} />
          {$_('problemi_cardiaci')}
        </div>
        <div class="col-6 aligned">
          <Checkbox bind:checked={diabetes} />
          {$_('diabete')}
        </div>
      </div>
    </div>
    <div class="col-4 label">{$_('pressione_arteriosa')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        withTrailingIcon
        disabled={!active}
        class={`textbox w50 my-2 ${bloodPressureInvalid ? 'mdc-text-field--invalid' : ''}`}
        bind:value={mmhg}
        type="text">
        <Icon
          class="mdc-text-field__icon mdc-text-field__icon--trailing customText">
          mmhg
        </Icon>
      </Textfield>
      {#if bloodPressureInvalid}
        <FormMessage>{$_('il_valore_inserito_non_è_valido')}</FormMessage>
      {/if}
    </div>
    <div class="col-4 label">{$_('ultima_rilevazione_pressione')}</div>
    <div class="col-8 col-lg-4 col-xl-4 pr-lg-0 pr-xl-0 pt-2">
      <DatePicker
        on:date={handleDateChange}
        defaultDate={''}
        maxDate={Date.now()}
        width="100" />
    </div>
    <div class="col-4 d-none d-sm-block" />
    <div class="col-4 label">{$_('operazioni_chirurgiche')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={surgical}
        label="">
        <CharacterCounter>{`${surgical.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('problemi_posturali')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={postural}
        label="">
        <CharacterCounter>{`${postural.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    {#if $customer.gender != 'male'}
      <div class="col-4 label">{$_('ciclo_irregolare')}</div>
      <div class="col-8">
        <Textfield
          textarea
          input$maxlength="2000"
          class="fullWidth my-2"
          bind:value={irregular_periods}
          label="">
          <CharacterCounter>
            {`${irregular_periods.length} / 2000`}
          </CharacterCounter>
        </Textfield>
      </div>
      <div class="col-4 label">{$_('pillola_contraccettiva')}</div>
      <div class="col-8">
        <Checkbox bind:checked={contraceptive_pill} />
      </div>
    {/if}
    <div class="col-4 label">{$_('utilizzo_farmaci')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={drugs}
        label="">
        <CharacterCounter>{`${drugs.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('fratture')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={fractures}
        label="">
        <CharacterCounter>{`${fractures.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
    <div class="col-4 label">{$_('altre_patologie')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={other_pathologies}
        label="">
        <CharacterCounter>
          {`${other_pathologies.length} / 2000`}
        </CharacterCounter>
      </Textfield>
    </div>
  </div>

  <hr />

  <MsgError {msgError} />

  <div class="row pushToEnd">
    <Button class="btn" on:click={() => exitDialog.open()} disabled={!active}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-3"
      variant="outlined"
      on:click={updateHistory}
      disabled={formInvalid}>
      <Label>{$_('salva_come_bozza')}</Label>
    </Button>
    <Button
      class="btn ml-3 mr-3"
      variant="unelevated"
      on:click={onGoForth}
      disabled={formInvalid}>
      <Label class="textUppercase">{$_('avanti')}</Label>
    </Button>
  </div>
</div>
