<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { Media } from "@smui/card";
	import AssignedVodDeadline from "./AssignedVodDeadline.svelte";
	import GroupLabels from "../../../ReusableLayouts/GroupLabels.svelte";
	import IconButton from "@smui/icon-button";
	import moment from "moment";

	export let assignment;

  const dispatch = createEventDispatcher();

	let placeholderThumb = 'https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/vod_thumb_100.png';

	const getDuration = (length) => {
		return length
			? $_("settimane", {
				values: { settimane: length },
			})
			: "-";
	};

	const handlePlayVod = () => {
	dispatch("playVod", {
	  vod: assignment,
	});
  }
</script>


<style>
	.label {
		opacity: 0.5;
		font-size: 0.75rem;
		color: black;
	}
	.op-8 {
		opacity: 0.8;
	}
	.op-2 {
		opacity: 0.2 !important;
	}
	.tc-start {
		min-width: 89px;
	}

	* :global(.playButtonWrapper) {
	position: absolute;
	opacity: 0;
  }

  * :global(.playButtonWrapper:hover), :global(.playButtonBackground:hover) {
	opacity: 1;
  }

  * :global(.playButtonWrapper > .mdc-icon-button) {
	width: 57px;
	height: 57px;
	font-size: 36px;
  }

  * :global(.playButtonBackground) {
	position: absolute;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background-color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

	* :global(.imagevod) {
		border-radius: 7px;
		height: 80px;
		width: 80px;
		align-items: center;
		object-fit: cover;
		max-width: inherit;
	}

	* :global(.imageContainer) {
		height: 80px;
		width: 80px;
	}
</style>

<div
	class="d-flex flex-column align-items-start justify-content-center
				 col-sm-3 col-md-4 col-lg-3"
	class:op-2={assignment.is_draft}>
	<div class="d-flex align-items-center">
		<div>
			<Media class="card-media-square customUploader pr-4" aspectRatio="square">
				<div class="d-flex justify-content-center align-items-center imageContainer">
					<img
						src={assignment.thumbnail ?? placeholderThumb}
						alt="{assignment.name}"
						class="imagevod d-flex" 
					/>
					{#if assignment.thumbnail && assignment.is_vod}
						<div class="playButtonWrapper" on:click|stopPropagation={handlePlayVod(assignment)}>
							<div class="playButtonBackground"></div>
							<IconButton class="material-icons">play_circle</IconButton>
						</div>
					{/if}
				</div>
			</Media>
		</div>
		<div>
			<!-- {#if assignment.playlist_id != null}
				<PlaylistLabel mx={false}/>
			{/if} -->
			{#if assignment.at_group_id != null}
				<GroupLabels groups={assignment.at_groups} mx={false}/>
			{/if}
			<div>
				<span>
					{assignment.name}
				</span>
			</div>
		</div>
	</div>
</div>
<div class="col-3 col-md-2 d-none d-sm-block px-2 text-center" class:op-2={assignment.is_draft}>
	<AssignedVodDeadline {assignment} />
</div>
{#if assignment.is_active}
	<div
		class:op-2={assignment.is_draft}
		class={`text-center px-0 d-none d-lg-block tc-start`}>
		<div class="col-12 label px-0">{$_('inizio')}</div>
		<div class="col-12 px-0">
			{assignment.start ? moment(assignment.start).format('DD/MM/YYYY') : '-'}
		</div>
	</div>
	<div class:op-2={assignment.is_draft} class="col-lg-2 text-center d-none d-lg-block">
		<div class="col-12 label">{$_('durata')}</div>
		<div class="col-12">{getDuration(assignment.weeks)}</div>
	</div>
{:else}
	<div class="d-none d-sm-block" />
{/if}
