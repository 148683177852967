<script>
  import { _ } from "../../services/i18n";

  export let centered = false;
  export let mx = true;

  let label = $_('gruppo');

  $: label = label.toUpperCase();
</script>

<style>
  .label--group {
    opacity: 1;
    background-color: #05d3c8;
    border-radius: 3px;
  }
  .label--group .label {
    opacity: 1;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
  }
</style>

<div class="groupLabels 
            d-flex flex-row flex-wrap
            {centered ? `justify-content-center` : `` }">
  <div 
    class="label--group px-1 mb-1 d-inline-block">
    <span class="label">
      {label}
    </span>
  </div>
</div>
