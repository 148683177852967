<script>
    import { _ } from "../../../../services/i18n";
    import moment from "moment";
    import { groups } from "../../../../lib/store";

    export let tc;

    const getExpiration = (expirationDays) => {
        if (expirationDays == 0) {
            return $_("scaduto");
        } else if (expirationDays == "-") { 
            return $_("nessuna_scadenza");
        } else { 
            return expirationDays[0] == '-' ? `${$_("scaduto")} (${expirationDays.slice(1)})` : `${$_("scadenza")} - ${expirationDays} `;
        };
    };
    const getDaysTilExpiration = (start, end) => {
        const diff = moment(end).diff(moment(Date.now()), "days");
        if (diff == 0) return "oggi";
        return isNaN(diff)
            ? "-"
            : $_("giorni", { values: { giorni: diff } });
    };

    const getProgressPercentage = (start, end) => {
        if (!start || !end) return 0; 
        const starting = new Date(start);
        const ending   = new Date(end);
        const today    = new Date();
        return (
            Math.round(((today - starting) / (ending - starting)) * 100) / 100
        );
    };

    const getColor = (end) => {
        const diff = moment(end).diff(moment(Date.now()), "days");
        if (diff > 10 || !end) return "#0CD2C8";
        else if (diff <= 10 && diff > 5) return "#FFCB00";
        else if (diff <= 5 && diff > 0) return "#FF7000";
        else return "#e9ecef";
    };

    const relatedGroupExists = (tc) => {
        let check = false;
        if (tc.groups && tc.groups.length > 0) {
            tc.groups.filter((assignedGroup) => {
                $groups.filter((existingGroup) => {
                    if (existingGroup.id === assignedGroup.id) {
                        check = true;
                    };
                });
            });
        }
        return check;
    }
</script>

<style>
    * :global(.progress) {
        height: 0.3em;
    }
    .label {
        opacity: 0.5;
        font-size: 0.75rem;
    }
</style>

{#if tc.is_active && tc.is_active_group_customer_relation !== false}
    <div class="col-12 text-center label">
        {getExpiration(getDaysTilExpiration(tc.start, tc.end))}
    </div>
    <div
        class={`col-12 mt-2 text-left ${tc.is_draft ? 'filteredCard--disabled' : ''}`}>
        <div class="progress">
            <div
                class="progress-bar"
                role="progressbar"
                style={`width: ${getProgressPercentage(tc.start, tc.end) * 100}%; background-color: ${getColor(tc.end)}`}
                aria-valuenow="{getProgressPercentage(tc.start, tc.end) * 100}}"
                aria-valuemin="0"
                aria-valuemax="100" />
        </div>
    </div>
{:else if tc.is_active_group_customer_relation === false}
    <div class="col-12 text-center pr-0">{$_('atleta_rimosso_dal_gruppo')}</div>
{:else if !tc.is_active && tc.is_assigned_to_group && !relatedGroupExists(tc)}
    <div class="col-12 text-center pr-0">{$_('gruppo_associato_eliminato')}</div>
{:else}
    <div class="col-12 text-center pr-0">{$_('eliminata')}</div>
{/if}
