<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import Card, { Content } from "@smui/card";

  	const dispatch = createEventDispatcher();
</script>

<div class="container">
	<Card class="invisibleCard">
		<Content class="p-0">
			<div class="row d-flex align-items-center">
				<div class="d-none d-md-flex col-1 px-0">
					<span class="px-4 mdc-typography--headline6 textTitle nameWrapper">
						{$_('rank')}
					</span>
				</div>
				<div class="col-5 col-md-4 d-flex px-0">
					<span class="pl-3 mdc-typography--headline6 textTitle nameWrapper">
						{$_('prodotto')}
					</span>
				</div>
				<span class="col-2 col-md-2 mdc-typography--headline6 textTitle nameWrapper justify-content-end">
					{$_('prezzo_')}
				</span>
				<span class="col-2 col-md-3 d-none d-md-inline mdc-typography--headline6 textTitle nameWrapper">
					{$_('status')}
				</span>
				<span class="col-3 mdc-typography--headline6 textTitle tagWrapper"></span>
			</div>
		</Content>
	</Card>
</div>

<hr class="line" />

<style>
  .nameWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  .tagWrapper {
    text-align: center !important;
  }
  .nameWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .textTitle {
		display: flex !important;
    color: #212121;
    opacity: 0.5;
    font-size: 14px;
    justify-content: start;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

	* :global(.mdc-card.invisibleCard) {
    box-shadow: none !important;
  }
</style>