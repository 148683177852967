<script>
    import { createEventDispatcher } from "svelte";
    import Button, { Label } from "@smui/button";
    import Textfield from "@smui/textfield";
    import Select, { Option } from "@smui/select";
    import DatePicker from "../ReusableLayouts/DatePicker.svelte";
    import moment from "moment";

    const dispatch = createEventDispatcher();

    const datePickerFormat = "DD/MM/YYYY";
    const dateDBFormat = "YYYY-MM-DD";

    export let pt = {};
    export let groups = {};

    let selectedGroup = {};
    let selectedGroupName = "";
    let clicked = false;

    $: if (!clicked) {
        if (pt) {
            selectedGroupName = pt.group_name ? pt.group_name : "";
        }
    }

    const submit = () => {
        dispatch("save", {
            pt: {
                id: pt.ptId,
                plan_id: pt.plan_id,
                plan_id_value: pt.plan_id_value,
                plan_id_start_date: "",
                plan_id_end_date: pt.plan_id_end_date
                    ? moment(pt.plan_id_end_date).format(dateDBFormat)
                    : "",
                invitato_referral_id: pt.invitato_referral_id,
                invitato_referral_id_value: pt.invitato_referral_id_value,
                invitato_referral_id_start_date: "",
                invitato_referral_id_end_date: pt.invitato_referral_id_end_date
                    ? moment(pt.invitato_referral_id_end_date).format(
                          dateDBFormat
                      )
                    : "",
                invitato_referral_id_month: pt.invitato_referral_id_month,
                invitante_referral_id_value: pt.invitante_referral_id_value,
                invitante_referral_id_start_date: "",
                invitante_referral_id_end_date: pt.invitante_referral_id_end_date
                    ? moment(pt.invitante_referral_id_end_date).format(
                          dateDBFormat
                      )
                    : "",
                invitante_referral_id_month: pt.invitante_referral_id_month,
            },
        });
    };

    const dispUpdateGroup = () => {
        selectedGroup = groups.find((g) => g.nome == selectedGroupName);
        if (selectedGroup.id) {
            dispatch("updateGroup", {
                group: {
                    group_id: selectedGroup.id,
                    pts_id: [pt.ptId],
                },
            });
        }
    };

    const handleDatePlan = (e) => {
        pt.plan_id_end_date = moment(e.detail.date, datePickerFormat).format(
            dateDBFormat
        );
    };
</script>

<style>
    .ptPricingButtons {
        align-self: flex-end;
    }

    .label {
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1rem;
        margin-left: 0.5rem;
        opacity: 0.7;
    }
</style>

<div class="row mx-0 spaceBetween px-2">
    <div class="col-4 my-2">Select Group:</div>
    <div class="col-4 my-2">
        <Select
            enhanced
            variant="outlined"
            class="w100 pt-0 selectStatuses"
            label="Group"
            bind:value={selectedGroupName}>
            <Option value="" selected={selectedGroupName == ''} />
            {#each groups as group}
                <Option
                    value={group.nome}
                    on:click={() => (clicked = true)}
                    selected={selectedGroupName == group.nome}>
                    {group.nome}
                </Option>
            {/each}
        </Select>
    </div>
    <div class="col-4 my-2">
        <Button
            class="btn mx-1"
            variant="unelevated"
            on:click={dispUpdateGroup}>
            <Label>Aggiorna Gruppo</Label>
        </Button>
    </div>

    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={pt.plan_id_value}
            label="Plan value (view-only)"
            type="number">
            <div class="customLabel">
                <Label
                    class="mdc-text-field__icon mdc-text-field__icon--trailing">
                    €
                </Label>
            </div>
        </Textfield>
    </div>
    <div class="col-4 my-2">
        <span class="label">Plan end date</span>
        <DatePicker
            on:date={handleDatePlan}
            defaultDate={pt.plan_id_end_date ? moment(pt.plan_id_end_date).format(datePickerFormat) : ''}
            maxDate={moment().add(18, 'years').toISOString()}
            width="100" />
    </div>
    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={pt.plan_id}
            label="Plan ID Stripe" />
    </div>
    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={pt.invitato_referral_id_value}
            label="Referral value (view-only)"
            type="number">
            <div class="customLabel">
                <Label
                    class="mdc-text-field__icon mdc-text-field__icon--trailing">
                    %
                </Label>
            </div>
        </Textfield>
    </div>
    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={pt.invitato_referral_id_month}
            label="Referral months (view-only)"
            input$min={0}
            input$max={99}
            type="number" />
    </div>
    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={pt.invitato_referral_id}
            label="Referral ID Stripe" />
    </div>
    <div class="col-6 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={pt.invitante_referral_id_value}
            label="Referral inviting value"
            type="number">
            <div class="customLabel">
                <Label
                    class="mdc-text-field__icon mdc-text-field__icon--trailing">
                    %
                </Label>
            </div>
        </Textfield>
    </div>
    <div class="col-6 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={pt.invitante_referral_id_month}
            label="Referral inviting months"
            input$min={0}
            input$max={99}
            type="number" />
    </div>
</div>

<div class="row mx-2 ptPricingButtons spaceBetween p-4">
    <Button
        class="btn mx-1"
        variant="unelevated"
        color="secondary"
        on:click={() => dispatch('cancel', {})}>
        <Label>Chiudi</Label>
    </Button>
    <Button class="btn mx-1" variant="unelevated" on:click={submit}>
        <Label>Salva</Label>
    </Button>
</div>
