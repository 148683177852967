<script>
  import { _ } from "../../../../services/i18n";
	import { getBillingInfo, storeBillingInfo } from "../../../../services/billingServices";
	import { onMount, createEventDispatcher } from "svelte";
	import FattureInCloudForm from "../FattureInCloudForm.svelte";
	
  const dispatch = createEventDispatcher();

  export let state;

  let billingDetails = {
    address_city        : "",
    address_postal_code : "",
    address_province    : "",
    address_street      : "",
    certified_email     : "",
    country             : { name: "Italia", code: "IT" },
    ei_code             : "",
    name                : "",
    tax_code            : "",
    type                : "",
    vat_number          : "",
  };
  let billingInfoComplete = false;
  let loading = false;
  let step = 2;

  const fetchBillingInfo = async (e) => {
    loading = true;
    try {
      const res = await getBillingInfo();
      if (res) {
        billingDetails.address_city        = res.address_city;
        billingDetails.address_postal_code = res.address_postal_code;
        billingDetails.address_province    = res.address_province;
        billingDetails.address_street      = res.address_street;
        billingDetails.certified_email     = res.certified_email;
        billingDetails.country             = res.country;
        billingDetails.ei_code             = res.ei_code;
        billingDetails.name                = res.name;
        billingDetails.tax_code            = res.tax_code;
        billingDetails.type                = res.type;
        billingDetails.vat_number          = res.vat_number;
      }
      billingInfoComplete = true;
    } catch (error) {
      console.log("getBillingInfo error", error);
      billingInfoComplete = false;
    }
    loading = false;
  };

  const handleSaveBillingInfo = async (e) => {
    loading = true;
    try {
      await storeBillingInfo(e.detail.body);
      dispatch('nextStep', { step });
    } catch (error) {
      console.log("saveBillingInfo error", error);
    }
    loading = false;
  }

  onMount(async () => {
    await fetchBillingInfo();
  });
</script>

<FattureInCloudForm
  {billingDetails}
  {state}
  bind:loading
  subscriptionDialogOpen={true}
  on:saveBillingInfo={handleSaveBillingInfo}
  on:previousStep={() => dispatch('previousStep')}
/>