<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import moment from "moment";
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import Button, { Icon, Label } from "@smui/button";
  import SvelteTooltip from 'svelte-tooltip';
  import { _ } from "../../../../services/i18n";
  import Textfield from "@smui/textfield";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import CustomSelect from "../../../TrainingCards/TrainingCard/CustomSelect.svelte";
  import {
    getPlicometry,
    createNewPlicometry,
  } from "../../../../services/teamServices.js";
  import { checkPlicoForm } from "./ProgressAndCheckValidation/ProgressAndCheckValidation";
  import { getPlicometryTypes } from "../../../../lib/progressCheckInfo";
  import { customer } from "../../../../lib/store";

  const dispatch = createEventDispatcher();

  export let openPlicoDialog;
  export let customerId;
  export let dateDialog;
  export let dateDialogItalian;
  export let plicometryGenerated = false;

  let elements;
  let plicometry = {};
  let objectPlicoDialog;
  let dialog;
  let protocol = true;
  let validityCheck = {};
  let isFormInvalid = false;
  let emptyDialog = true;
  let plicometryOptions = [];
  let keys;
  let resume;
  let valuesChanged;
  let body_density = 0;
  let body_fat_perc = 0;
  let plicometryBaseOptions = plicometryOptions = [
    { 
      text: $_('protocollo_uomo_3_pliche'),
      value: 'male_3_plics',
      keys: ['abdominal', 'chest', 'leg', 'age',],
      resume: '(JP-3/male)',
      sex: 'male',
    },
    { 
      text: $_('protocollo_donna_3_pliche'),
      value: 'female_3_plics',
      keys: ['tricep', 'suprailiac', 'leg', 'age',],
      resume: '(JP-3/female)',
      sex: 'female',
    },
    { 
      text: $_('protocollo_uomo_7_pliche'),
      value: 'male_7_plics',
      keys: ['tricep', 'abdominal', 'suprailiac', 'subscapularis', 'axillary', 'chest', 'leg', 'age',],
      resume: '(JP-7/male)',
      sex: 'male',
    },
    { 
      text: $_('protocollo_donna_7_pliche'),
      value: 'female_7_plics',
      keys: ['tricep', 'abdominal', 'suprailiac', 'subscapularis', 'axillary', 'chest', 'leg', 'age',],
      resume: '(JP-7/female)',
      sex: 'female',
    },
  ];

  $: if (objectPlicoDialog) {
    validityCheck = checkPlicoForm(objectPlicoDialog, keys);
    checkEmptyDialog();
  }
  $: isFormInvalid = Object.values(validityCheck).some((e) => e == true) || emptyDialog;

  $: {
    if (openPlicoDialog == true) {
      elements = getPlicometryTypes($_, protocol);
      init();
      dialog.open();
    }
    if (dialog && dialog.isOpen()) {
      dispatch("close");
    }
  }

  const assignProtocol = (plicometryLoaded = false) => {
    if (plicometryLoaded !== false) {
      protocol = plicometryLoaded.sex + '_' + plicometryLoaded.protocol + '_plics';
    } else {
      if ($customer.gender === 'male') {
        protocol = 'male_3_plics';
      } else if ($customer.gender === 'female') {
        protocol = 'female_3_plics';
      } else {
        protocol = 'male_3_plics';
      }
    }
    if ($customer.gender === 'male' || $customer.gender === 'female') {
      if (plicometryLoaded && $customer.gender !== plicometryLoaded.sex) {
        plicometryOptions = plicometryBaseOptions;
      } else {
        plicometryOptions = plicometryBaseOptions.filter(p => {
          return p.sex == $customer.gender;
        });
      }
    } else {
      plicometryOptions = plicometryBaseOptions;
    }
  }

  const init = async (plicometryLoaded = false) => {
    try {
      plicometryLoaded = await getPlicometry($customer.customer_id, dateDialog);
    } catch (error) {
      console.log(error)
    }
    assignProtocol(plicometryLoaded);
    elements = getPlicometryTypes($_, protocol);
    setObjectPlicoDialog(plicometryLoaded);
    plicometryOptions.map(i => { 
      if (i.value == protocol) {
        keys = i.keys;
        resume = i.resume;
      }
    });
    if (plicometryLoaded) {
      calculatePlicometry();
    }
  }

  const setObjectPlicoDialog = async (plicometryLoaded) => {
    objectPlicoDialog = {};
    if (elements.length != 0) {
      for (let row of elements) {
        for (let ele of row) {
          objectPlicoDialog[ele.key] = plicometryLoaded === false 
            ? (ele.value ? ele.value : "")
            : (plicometryLoaded[ele.key] ? plicometryLoaded[ele.key] : "");
          validityCheck[ele.key] = false;
        }
      }
    }
    objectPlicoDialog.customer_id = customerId;
    objectPlicoDialog.age = $customer.date_of_birth != '' 
      ? moment().diff($customer.date_of_birth, 'years') 
      : plicometryLoaded.age 
        ? plicometryLoaded.age 
        : '';
  }

  const checkEmptyDialog = () => {
    if (keys) {
      emptyDialog = keys.some(k => {
        if (!objectPlicoDialog[k] || objectPlicoDialog[k] == null ||objectPlicoDialog[k] == null) {
          return true;
        }
      });
    }
  }

  const handleChangeProtocol = async () => {
    elements = getPlicometryTypes($_, protocol);
    plicometryGenerated = false;
    plicometryOptions.map(i => { 
      if (i.value == protocol) {
        keys = i.keys;
        resume = i.resume;
      }
    });
    if (objectPlicoDialog) {
      for (let row of elements) {
        for (let ele of row) {
          objectPlicoDialog[ele.key] = objectPlicoDialog[ele.key] ? objectPlicoDialog[ele.key] : "";
          validityCheck[ele.key] = false;
        }
      }
      checkEmptyDialog();
    }
  }

  const calculatePlicometry = () => {
    let sum = 0;
    let age = objectPlicoDialog.age;
    plicometry = {};

    for (let row of elements) {
      for (let ele of row) {
        if (!isNaN(objectPlicoDialog[ele.key]) && objectPlicoDialog[ele.key] != 0) {
          plicometry[ele.key] = Number(objectPlicoDialog[ele.key]);
        }
        if (ele.key != 'age') {
          sum += isNaN(objectPlicoDialog[ele.key]) ? 0 : Number(objectPlicoDialog[ele.key]);
        }
      }
    }

    switch (protocol) {
      case 'female_3_plics' : body_density = 1.0994921 - (0.00099290 * sum) + (0.00000230 * (sum * sum)) - (0.00013920 * age); plicometry.plics = 3; plicometry.sex = 'female'; break;
      case 'male_3_plics'   : body_density = 1.1093800 - (0.00082670 * sum) + (0.00000160 * (sum * sum)) - (0.00025740 * age); plicometry.plics = 3; plicometry.sex = 'male';   break;
      case 'female_7_plics' : body_density = 1.0970000 - (0.00046971 * sum) + (0.00000056 * (sum * sum)) - (0.00012828 * age); plicometry.plics = 7; plicometry.sex = 'female'; break;
      case 'male_7_plics'   : body_density = 1.1120000 - (0.00043499 * sum) + (0.00000055 * (sum * sum)) - (0.00028826 * age); plicometry.plics = 7; plicometry.sex = 'male';   break;
    }

    console.log(protocol);
    body_fat_perc = (495 / body_density) - 450;
    plicometry.body_fat_perc = body_fat_perc;
    plicometry.customer_id = $customer.customer_id;
    plicometry.created_at = dateDialog;
    plicometryGenerated = true;
    valuesChanged = false;
  }

  // $: console.log('plicometry =>', plicometry);
  // $: console.log('objectPlicoDialog =>', objectPlicoDialog);
  // $: console.log('protocol =>', protocol);
  // $: console.log('plicometryGenerated =>', plicometryGenerated);
  // $: console.log('isFormInvalid =>', isFormInvalid);
  // $: console.log('emptyDialog =>', emptyDialog);
  // $: console.log('valuesChanged =>', valuesChanged);

  const checkPlicometryUpdates = () => {
    valuesChanged = keys.some(k => {
      if (plicometry[k] !== undefined && 
          objectPlicoDialog[k] !== undefined &&
          plicometry[k] != objectPlicoDialog[k]) {
        return true;
      }
    });
  }

  $: if (plicometryGenerated && objectPlicoDialog) {
    checkPlicometryUpdates();
  }

  const storePlicometry = async () => {
    try {
      await createNewPlicometry(plicometry);
      dispatch('storeBodyFatPerc', {plicometry: plicometry});
    } catch (error) {
      console.log(error);
    }
    await tick();
    handleClosePlicoDialog();
  }

  const handleClosePlicoDialog = () => {
    dialog.close();
    objectPlicoDialog = {};
    plicometry = {};
  }

  const handleNumericTextFieldOnKeyDown = (e) => {
    if (e.keyCode == 110 || e.keyCode == 190 || e.keyCode == 229 || e.key == "." || e.code == "NumpadDecimal") {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = parseFloat(e.target.value).toFixed(1);
      return false;
    }
  }

  const handleNumericTextFieldOnInput = (e) => {
    if (!isNaN(e.data) && (e.target.value % 1 != 0)) {
      e.target.value = Math.trunc(e.target.value) + (e.data / 10);
    } 
  }
</script>

<style>
  h5 {
    padding: 0;
  }
  * :global(.plicoDialog .mdc-dialog__surface) {
    height: 683px;
    min-height: 475px;
  }
  * :global(.plicoDialog .mdc-select) {
    width: 100%;
  }
  * :global(.plicoDialog select) {
    height: 2.7rem !important;
  }
  * :global(.mdc-button) {
    background-color: #cd293d !important;
    color: white !important;
    border: 1px solid #cd293d !important;
    width: 160px !important;
  }
  * :global(.mdc-button .secondaryButton) {
    background-color: white !important;
    color: #cd293d !important;
    border: 1px solid white !important;
    width: 160px !important;
  }

  * :global(.mdc-dialog__title + .mdc-dialog__content) {
    padding: 0px 24px;
  }
  * :global(.mdc-dialog__title) {
    display: flex;
    padding: 10px 39px;
    background-color: #f9f9f9;
    opacity: 0.8;
  }
  * :global(.mdc-button.outlinedCustom) {
    background-color: #fff !important;
    border-color: black !important;
    color: black !important;
    max-width: 105px !important;
  }

  * :global(.mdc-button.outlinedCustom span) {
    font-size: 0.9rem;
  }
  .textLabel {
    color: #212121;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1rem;
    margin-left: 0.3rem;
    opacity: 0.7;
  }
  * :global(.textfield--short) {
    width: 105px !important;
  }
  .spaceBetween {
    display: flex;
    justify-content: space-between;
  }
  * :global(.mdc-text-field__icon--trailing.customLabel) {
    color: #212121;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    opacity: 0.5;
  }
  * :global(.plicoResults.mdc-dialog__content) {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: 767px) {
    * :global(.mdc-dialog__content) {
      max-height: fit-content;
      flex-grow: 0;
    }
  }
</style>

<div>
  <Dialog class="plicoDialog dialog--xxsmall" bind:this={dialog}>
    <Title>
      <h5 class="bold pt-3">
        {$_('plicometria') + ' - ' + dateDialogItalian}
      </h5>
    </Title>
    <Content>
      <div class="container-fluid">
        <div class="row my-3">
          <div class="col-12">
            <!-- SELECT PLICOMETRY PROTOCOL -->
            <CustomSelect
              outlined={true}
              on:changedItem={handleChangeProtocol}
              bind:selected={protocol}
              items={plicometryOptions} />
          </div>
        </div>

        {#if objectPlicoDialog}
          {#each elements as row}
            <div class="row no-gutters spaceBetween mb-2">
              {#each row as ele}
                {#if objectPlicoDialog[ele.key] !== undefined}
                  <div>
                    <div class="textLabel mb-1">{ele.label}</div>
                    <Textfield
                      withTrailingIcon
                      class={`textfield--short ${validityCheck[ele.key] ? 'mdc-text-field--invalid' : ''}`}
                      bind:value={objectPlicoDialog[ele.key]}
                      variant="outlined"
                      label=""
                      input$min={0}
                      input$max={100}
                      on:keydown={handleNumericTextFieldOnKeyDown}
                      on:input={handleNumericTextFieldOnInput}
                      type="number">
                      <div>
                        {#if ele.unit}
                          <Label
                            class="mdc-text-field__icon
                              mdc-text-field__icon--trailing customLabel">
                            {ele.unit}
                          </Label>
                        {/if}
                      </div>
                    </Textfield>
                    {#if validityCheck[ele.key]}
                      <FormMessage>
                        {$_('il_valore_inserito_non_è_valido')}
                      </FormMessage>
                    {/if}
                  </div>
                {/if}
              {/each}
            </div>
          {/each}
        {/if}
      </div>
      <hr />
    </Content>
    <Content class="plicoResults">
      <div class="container-fluid">
        <div class="row my-4">
          {#if plicometryGenerated && !valuesChanged}
            <div class="col-12 py-1 text-center">
              <span>{$_('massa_grassa')} {resume}</span>
            </div>
            <div class="col-12 py-1 text-center">
              <strong style="font-size: 1.5rem;">{Math.round(body_fat_perc * 10) / 10} %</strong>
            </div>
          {:else if valuesChanged || (!emptyDialog && !isFormInvalid)}
            <div class="col-12 py-1 text-center">
              <span>Clicca su "calcola" per ottenere il valore della massa grassa</span>
            </div>
          {:else if emptyDialog}
            <div class="col-12 py-1 text-center">
              <span>Inserisci i dati mancanti per effettuare il calcolo della massa grassa</span>
            </div>
          {:else if isFormInvalid}
            <div class="col-12 py-1 text-center">
              <span>Correggi i dati per effettuare il calcolo della massa grassa</span>
            </div>
          {/if}
        </div>
      </div>
      <hr />
    </Content>
    <Actions style="justify-content: center">
      <Button
        class="secondaryButton btn"
        variant="unelevated"
        style=" background-color: white !important; color: #cd293d !important;
        border: 1px solid white !important;"
        on:click={handleClosePlicoDialog}>
        <Label>{$_('annulla')}</Label>
      </Button>
      <Button
        disabled={isFormInvalid}
        class="primaryButton btn ml-2"
        variant="unelevated"
        on:click={(e) => {
          e.stopPropagation();
          if (plicometryGenerated === false || valuesChanged === true) {
            calculatePlicometry();
          } else {
            storePlicometry();
          }
        }}>
        <Label>{plicometryGenerated === false || valuesChanged === true ? $_('calcola') : $_('inserisci')}</Label>
      </Button>
    </Actions>
  </Dialog>
</div>
