<script>
  import { _ } from "../../../../services/i18n";
  import { activeSubSection, previousSubSection, } from "../../../../lib/store";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import { saveCustomerSubscriptionInfo as saveSubscriptionInfo, updateCustomerSubscriptionInfo as updateSubscriptionInfo } from "../../../../services/customerServices";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import CustomerSubscriptionHeader from "./CustomerSubscriptionHeader.svelte";
  import CustomerSubscriptionInfo from "./CustomerSubscriptionInfo.svelte";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  let activeTab = $_("info_abbonamento");
  let component;
  let customerId;
  let customerSubscription = {
    auto_deactivate     : "",
    name                : "",
    start               : "",
    duration            : "",
    duration_type       : 0,
    price               : "",
    paid                : false,
    payment_date        : "",
    note                : "",
    visible_to_customer : false,
  };
  let exitDialog;
  let msgError;
  let options = { 
    [$_("info_abbonamento")]: CustomerSubscriptionInfo, 
  };
  let props = {};
  let tabs = [ 
    $_("info_abbonamento"), 
  ];

  $: if (location && location.state && location.state.customerId)
    customerId = location.state.customerId;

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: {
    if (searchParamsTab == 'info_abbonamento') {
      activeTab = $_("info_abbonamento");
      component = $_("info_abbonamento");
    }
  }

  $: if (activeTab == $_("info_abbonamento")) {
      props = {
        customerSubscription,
        customerId,
      } 
    }

  const handleGoBack = () => {
    exitDialog.close();
    navigate("/customer?tab=subscriptions", {
      state: { customerId },
    });
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleActiveTabChanged = (e) => {
    $previousSubSection = $activeSubSection;
    $activeSubSection = e.detail.tab;

    activeTab = e.detail.tab;
    component = [e.detail.tab];

    updateTab();
  };

  const updateTab = () => {
    if (component == $_("info_abbonamento"))
      navigate(`/customer-subscription?tab=info&customerSubscriptionId=` + customerSubscription.id + "&customerId=" + customerId, {
        replace: true,
        state: { 
          customerId,
          customerSubscription,
        },
      });
  };

  const storeCustomerSubscriptionInfo = async (e) => {
    try {
      let subscription = e.detail.customerSubscription;
      subscription.customer_id = customerId;
      if (subscription.id) {
        await updateSubscriptionInfo(subscription)
        .then(leavePage);
      } else {
        await saveSubscriptionInfo(subscription)
        .then(leavePage);
      }
    } catch (err) {
      console.log("saveSubscriptionInfo err", err);
      displayError();
    }
  };

  const leavePage = () => {
    navigate("/customer?tab=subscriptions", {
      state: { customerId },
    });
  }

  onMount(async () => {
    if (location.pathname == "/customer-subscription" && searchParamsTab == "info") {
      activeTab = $_("info_abbonamento");
      component = $_("info_abbonamento");
    } else {
      // Default
      activeTab = $_("info_abbonamento");
      component = $_("info_abbonamento");
    }
    if (location && location.state && location.state.customerSubscription) {
      const { customerSubscription: subscription } = location.state;
      customerSubscription = {
        id                  : location.pathname == "/customer-subscription" ? subscription.id : null,
        auto_deactivate     : subscription.auto_deactivate     ?? false,
        duration            : subscription.duration            ?? "",
        duration_type       : subscription.duration_type       ?? 0,
        name                : subscription.name                ?? "",
        note                : subscription.note                ?? "",
        paid                : subscription.paid                ?? false,
        payment_date        : subscription.payment_date        ?? "",
        price               : subscription.price               ?? "",
        start               : subscription.start               ?? "",
        visible_to_customer : subscription.visible_to_customer ?? false,
      };
    }
  });
</script>

<style>
  .visible--landscape {
    display: block;
  }
  * :global(.warning) {
    background-color: #f9f9fb;
    opacity: 0.8;
  }
  * :global(.warning__icon) {
    font-size: 3rem;
  }
  .warning__message {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  @media screen and (min-width: 280px) and (max-width: 896px) and (orientation: portrait) {
    .warning--rotate-screen {
      display: block;
    }
    .visible--landscape {
      display: none;
    }
  }
  @media screen and (max-width: 896px) {
    .tc-container {
      width: 100%;
      max-width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      margin-right: auto;
      margin-left: auto;
    }
  }
</style>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={handleGoBack} 
    confirmationButtonLabel={$_('esci')}
    text={$_('sei_sicuro_di_voler_uscire')} />
</Dialog>

<CustomerSubscriptionHeader
  on:activeTabChanged={handleActiveTabChanged}
  on:error={() => displayError()}
  on:goBack={() => exitDialog.open()} 
  {activeTab}
  {tabs}
  name={$_('indietro')}
  tabNavigationEnabled={customerSubscription.id} />

<div
  class="container fluid tc-container" data-recording-gdpr-safe>
  {#if msgError}
    <div class="alert alert-danger fade show mt-2" role="alert">{msgError}</div>
  {/if}

  <svelte:component
    this={options[component]}
    on:error={() => displayError()}
    on:storeCustomerSubscription={storeCustomerSubscriptionInfo}
    {...props} />
</div>
