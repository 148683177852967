<script>
  import { _ } from "svelte-i18n";
  import { navigate } from "svelte-routing";
  import Button, { Label } from "@smui/button";
  import Card, { Content } from "@smui/card";
  import { Icon } from "@smui/common";
  import { subscription, subscriptionInfo } from "../../lib/store";
  import { getCustomerPortalUrl } from "../../services/billingServices";

  export let updateDataButton;
  
  let state = "done";
  let url = "https://how.revoo-app.com/knowledge/guida-abbonamenti-revoo";
  let isTrainMobile = window.location.host == "trainmobile.revoo-app.com";
  
  $: if ($subscriptionInfo && $subscriptionInfo.name) {

    // Default
    state = 'done';

    // Subscription name REVOO (new)
    if ($subscriptionInfo.name === 'revoo') {
      // È attivo il trial con prodotto di default ("REVOO | Trial")?
      if ($subscriptionInfo.default_trial_product === true) {
        if ($subscription.trial_days_remaining > 0 && $subscription.trial_days_remaining < 10) {
          // Ultimi 10 giorni di trial
          state = "active";
        }
        if ($subscriptionInfo.trial_over === true) {
          // Trial terminato
          // mostrare banner con bottone che apre modale di scelta piani
          state = "expired";
        }
      }
      else {
        // Not Trial
        if ($subscriptionInfo.unpaid === true) {
          // Unpaid
          state = "unpaid";
        }
        else if ($subscriptionInfo.billing_data_missing === true) {
          // Mancano i dati di fatturazione
          state = "dataNotFilled";
        }
        else if ($subscriptionInfo.payment_data_missing === true) {
          // Mancano i dati di pagamento
          // Cosa mostrare?
        }
      }
    }
    else if ($subscriptionInfo.name === 'main') {
      // Subscription name MAIN (old)
      if ($subscriptionInfo.unpaid) {
        state = "unpaid";
      }
      else if ($subscriptionInfo.billing_data_missing) {
        state = "dataNotFilled";
      }
      else if ($subscription.trial_days_remaining > 0 && $subscription.trial_days_remaining < 10) {
        state = "active";
      }
    }
    else {
      // Nessun abbonamento valido
      state = "abandoned";
    }
  }

  const handleStartCustomerPortal = async () => {
    try {
      const res = await getCustomerPortalUrl();
      if (res.success === true) {
        window.location.href = res.data.url;
      }
    } catch (err) {
      console.log('handleStartCustomerPortal Error =>', err);
    }
  }

  const colors = {
    active: "var(--icon-active)",
    expired: "var(--icon-notVeryActive)",
    unpaid: "var(--safety-orange)",
    dataNotFilled: "var(--safety-orange)",
    abandoned: "var(--dirty-input)",
  };

  const textColors = {
    active: "inherit",
    expired: "inherit",
    unpaid: "var(--mdc-theme-on-primary, #fff)",
    dataNotFilled: "var(--mdc-theme-on-primary, #fff)",
    abandoned: "var(--mdc-theme-on-primary, #fff)",
  };

  let labels = {};
  $: labels = {
    active : $_("periodo_prova_scade", {
      values : {
        days : $subscription
          ? $subscription.trial_days_remaining
          : "",
      },
    }),
    dataNotFilled : 
      !isTrainMobile 
        ? $_("dati_fatturazione_mancanti") 
        : $_("dati_fatturazione_mancanti_mobile"),
    expired : 
      !isTrainMobile 
        ? $subscriptionInfo.name === 'revoo' 
          ? $_("periodo_prova_scaduto_new_pricing")
          : $_("periodo_prova_scaduto")
        : $subscriptionInfo.name === 'revoo' 
          ? $_("periodo_prova_scaduto_new_pricing")
          : $_("periodo_prova_scaduto_mobile"),
    unpaid : 
      !isTrainMobile 
        ? $_("errore_pagamento") 
        : $_("errore_pagamento_mobile"),
    abandoned : 
      !isTrainMobile 
        ? $_("abbonamento_annullato_contattaci_in_chat")
        : $_("abbonamento_annullato_contattaci_in_chat"),
  };

</script>

<style>
  * :global(.btn.banner-button) {
    padding: 0 9px;
  }

  * :global(.banner-button) {
    color: var(--secondary);
    background-color: white;
  }
  
  @media screen and (max-width: 367px) {
    * :global(.btn.banner-button) {
      height: auto;
    }
  }
</style>

<div class:d-none={state == 'done'}>
  <Card style={`background-color: ${colors[state]}; color: ${textColors[state]}`}>
    <Content
      class="d-flex justify-content-between align-items-center flex-column
        flex-lg-row">
      <span
        class="subscriptionBannerLabel py-2 text-center text-md-left text-lg-left">{labels[state]}</span>
      <div class="flexy">
        {#if state != 'unpaid'}
          <Button
            class="btn mr-3 banner-button"
            variant="unelevated"
            on:click={() => {
              if (state == 'dataNotFilled') url = 'https://how.revoo-app.com/knowledge/come-funziona-la-fatturazione-su-revoo';
              window.open(url, '_blank');
            }}>
            <Icon class="material-icons-outlined">info</Icon>
            <Label class="textUppercase">{$_('maggiori_info')}</Label>
          </Button>
        {/if}
        {#if $subscriptionInfo.name === 'revoo' && ($subscriptionInfo.status === 'past_due' || $subscriptionInfo.status === 'unpaid' || $subscriptionInfo.status === 'unsolved')}
          <Button
            class="btn banner-button mr-2"
            variant="unelevated"
            on:click={handleStartCustomerPortal}>
            <Icon class="material-icons-outlined">payment</Icon>
            <Label class="textUppercase">{$_('aggiorna_dati_pagamento')}</Label>
          </Button>
        {/if}
        {#if updateDataButton && !isTrainMobile && state != 'abandoned'}
          <Button
            class="btn banner-button"
            variant="unelevated"
            on:click={() => {
              navigate('/pt-info?tab=billing');
            }}>
            <Icon class="material-icons-outlined">payment</Icon>
            <Label class="textUppercase">{$subscriptionInfo.name === 'revoo' ? $_('abbonati') : $_('aggiorna_dati_fatturazione')}</Label>
          </Button>
        {/if}
      </div>
    </Content>
  </Card>
</div>
