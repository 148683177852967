<script>
    import { createEventDispatcher } from "svelte";
    import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
    import Select, { Option } from "@smui/select";
    import { Icon } from "@smui/common";
    import Dialog from "@smui/dialog";
    import Textfield from "@smui/textfield";
    import GroupPricing from "./GroupPricing.svelte";
    import moment from "moment";
    const dispatch = createEventDispatcher();

    export let groups;

    let editGroupDialog;
    const defaultGroupValues = {
        groupId: "",
        plan_id: "",
        nome: "",
        descrizione: "",
        plan_id_value: "",
        plan_id_start_date: "",
        plan_id_end_date: "",
        invitato_referral_id: "",
        invitato_referral_id_value: "",
        invitato_referral_id_end_date: "",
        invitato_referral_id_month: "",
        invitante_referral_id_value: "",
        invitante_referral_id_end_date: "",
        invitante_referral_id_month: "",
    };
    let groupToBeEdited = defaultGroupValues;

    const openDialog = (group = null) => {
        if (group)
            groupToBeEdited = {
                groupId: group.id,
                plan_id: group.plan_id,
                nome: group.nome ? group.nome : "",
                descrizione: group.descrizione ? group.descrizione : "",
                ...group.pricing,
            };
        else groupToBeEdited = defaultGroupValues;
        editGroupDialog.open();
    };

    const closeEditDialog = () => {
        groupToBeEdited = defaultGroupValues;
        editGroupDialog.close();
    };

    const handleUpdateGroupPricing = (e) => {
        dispatch("updateGroupPrincing", { group: e.detail.group });
        closeEditDialog();
    };

    const handleDeleteGroup = (e) => {
        dispatch("deleteGroup", { id: e.detail.id });
        closeEditDialog();
    };
</script>

<style>
    * :global(.mdc-text-field) {
        height: 40px;
        margin-right: 0.8em;
    }
    * :global(.mdc-text-field__input) {
        height: auto;
        margin: auto;
        padding: 0 0.5em !important;
        width: 14em;
    }
    *
        :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
            .mdc-floating-label) {
        top: 12px;
    }
    * :global(.material-icons.searchIcon) {
        padding: 10px 0px 0px 4px !important;
    }
    * :global(.primary) {
        color: var(--primary);
    }

    .flex {
        display: flex;
    }

    .flex > .icon-right {
        margin-left: auto;
    }
</style>

<Dialog
    class="editPtDialogWrapper no-click-backdrop"
    bind:this={editGroupDialog}
    aria-labelledby="dialog-for-new-deal"
    aria-describedby="new-deal">
    <GroupPricing
        group={groupToBeEdited}
        on:cancel={closeEditDialog}
        on:save={handleUpdateGroupPricing}
        on:deleteGroup={handleDeleteGroup} />
</Dialog>

<div>
    <div class="row mx-0 my-3 py-4">
        <div class="col-2">
            <span class="mdc-typography--headline5">Gruppi</span>
        </div>
        <div class="col-8" />
        <div class="col-2 flex">
            <span class="icon-right">
                <Icon
                    on:click={() => openDialog()}
                    class="material-icons clickable">
                    add_circle
                </Icon>
            </span>
        </div>
    </div>
    {#if groups.length > 0}
        <div class="row mx-0 my-3 py-4">
            <div class="col-12 admin-datatable">
                <DataTable class="mb-3">
                    <Head>
                        <Row>
                            <Cell>GRUPPI</Cell>
                            <Cell>NUMERO DI PT</Cell>
                            <Cell>PRICING RISERVATO</Cell>
                            <Cell>REFERRAL INVITATO</Cell>
                            <Cell>REFERRAL INVITANTE</Cell>
                            <Cell>EDIT</Cell>
                        </Row>
                    </Head>
                    <Body>
                        {#each groups as group (group.id)}
                            <Row>
                                <Cell>{group.nome}</Cell>
                                <Cell>{group.pts_count}</Cell>
                                <Cell
                                    class={group.pricing && group.pricing.plan_id_end_date && moment(group.pricing.plan_id_end_date).isBefore(moment()) ? 'primary' : ''}>
                                    {group.pricing ? group.pricing.plan_id_value : ''}
                                </Cell>
                                <Cell
                                    class={group.pricing && group.pricing.invitato_referral_id_end_date && moment(group.pricing.invitato_referral_id_end_date).isBefore(moment()) ? 'primary' : ''}>
                                    {group.pricing ? group.pricing.invitato_referral_id_value : ''}
                                </Cell>
                                <Cell
                                    class={group.pricing && group.pricing.invitante_referral_id_end_date && moment(group.pricing.invitante_referral_id_end_date).isBefore(moment()) ? 'primary' : ''}>
                                    {group.pricing ? group.pricing.invitante_referral_id_value : ''}
                                </Cell>
                                <Cell>
                                    <Icon
                                        on:click={() => openDialog(group)}
                                        class="material-icons clickable">
                                        edit
                                    </Icon>
                                </Cell>
                            </Row>
                        {/each}
                    </Body>
                </DataTable>
            </div>
        </div>
    {/if}
</div>
