<script>
  import { _, locale } from "../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import Select, { Option } from "@smui/select";

  const dispatch = createEventDispatcher();

  function handleLocaleChange(lang) {
    dispatch("locale-changed", lang);
    key++;
  }

  export let language;

  let key = 0;
  const items = [0];

  $: {
    handleLocaleChange(language);
  }

  let languages;
  $: languages = [
    {
      text: $_("italiano"),
      value: "it",
    },
    {
      text: $_("inglese"),
      value: "en",
    },
  ];
</script>

{#each items as x (key)}
  <Select
    label=""
    enhanced
    variant="outlined"
    class="w100"
    bind:value={language}>
    {#each languages as lang}
      <Option value={lang.value} selected={language === lang.value}>
        {lang.text}
      </Option>
    {/each}
  </Select>
{/each}
