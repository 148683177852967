<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import { _ } from "../../../services/i18n";
  import { Icon } from "@smui/button";
  export let text;
  export let canGoBack;
  export let icon;

  const goBackToOverview = () => {
    dispatch("setComponent", { component: "overview" });
  };

  const handleIconClick = () => {
    dispatch("iconHasBeenClicked", {});
  };
</script>

<style>
  .primary {
    color: var(--primary);
  }

  .clickable {
    cursor: pointer;
  }

  .titleWrapper {
    align-items: center;
    display: flex;
  }
</style>

{#if canGoBack}
  <div class="row my-4">
    <div
      class="mdc-typography--headline6 primary titleWrapper clickable"
      on:click={goBackToOverview}>
      <Icon role="button" tabindex="1" class="material-icons">
        keyboard_arrow_left
      </Icon>
      <span>{$_('il_tuo_abbonamento')}</span>
    </div>
  </div>
{/if}
<div class="row mb-4 mdc-typography--headline6">
  <span class="col demiBold">{text}</span>
  {#if icon}
    <Icon
      on:click={handleIconClick}
      role="button"
      tabindex="1"
      class="material-icons mr-3">
      {icon}
    </Icon>
  {/if}
</div>
