<script>
  import { _ } from "../../../services/i18n";
  import { activeSubSection,previousSubSection } from "../../../lib/store";
  import { createEventDispatcher } from "svelte";
  import { deleteTrainingCard } from "../../../services/trainingCardServices";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import BannerHelp from "../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label } from "@smui/button";
  import Dialog from "@smui/dialog";
  import Tabs from "../../ReusableLayouts/Tabs.svelte";

  const dispatch = createEventDispatcher();

  export let activeTab;
  export let buttonVisible;
  export let customerId;
  export let id;
  export let name;
  export let preAssignedGroupId;
  export let tabNavigationEnabled;
  export let tabs;

  let deleteDialog;
  
  $: if (activeTab) {
    $previousSubSection = $activeSubSection;
    $activeSubSection = activeTab;
  }

  const deleteTC = async () => {
    try {
      await deleteTrainingCard(id);
      deleteDialog.close();
      navigate(customerId ? "/customer?tab=workouts" : "/training-cards", {
        state: { customerId },
      });
    } catch (err) {
      console.log("deleteTrainingCard err", err);
      dispatch("error", {});
    }
  };
</script>

<style>
  .titleWrapper {
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .headingsWrapper {
    align-items: center;
    justify-content: space-between;
  }
  .wrapper {
    background-color: #f9f9f9;
  }
  .end {
    text-align: end;
  }
  .dialogText {
    font-size: 1.2rem;
    font-weight: 500;
  }
  * :global(.mdc-tab__content) {
    text-transform: none;
  }
  * :global(.outlinedCustom) {
    border-color: black;
    color: black;
  }
  * :global(.outlinedCustomPrimary) {
    border-color: var (--primary);
    color: var (--primary);
  }
</style>

<Dialog
  bind:this={deleteDialog}
  aria-labelledby="dialog-for-deleting-training-card"
  aria-describedby="delete-training-card">
  <div class="p-4">
    <span class="dialogText">
      {$_('sei_sicuro_di_voler_eliminare_il_piano')}
    </span>
    <div class="flexy mt-3">
      <Button
        variant="outlined"
        class="outlinedCustomPrimary mr-1"
        on:click={deleteTC}>
        <Label>{$_('elimina')}</Label>
      </Button>
      <Button
        variant="outlined"
        class="outlinedCustom ml-1"
        on:click={() => deleteDialog.close()}>
        <Label>{$_('annulla')}</Label>
      </Button>
    </div>
  </div>
</Dialog>

<div class="wrapper">
  <div class="container fluid">
    <div class="row headingsWrapper">
      <div class="col-8 my-4">
        <div
          class="mdc-typography--headline6 primary titleWrapper"
          on:click={() => {
            dispatch('goBack', {});
          }}>
          <Icon role="button" tabindex="1" class="material-icons">
            keyboard_arrow_left
          </Icon>
          <span
            class="demiBold">{name || $_('nuovo_piano_di_allenamento')}</span>
        </div>
      </div>
      <div class="col-4 end">
        {#if buttonVisible}
          <Button on:click={() => deleteDialog.open()}>
            <Icon class="material-icons">delete</Icon>
            <Label>{$_('elimina')}</Label>
          </Button>
        {/if}
      </div>
    </div>
    <Tabs {tabs} {tabNavigationEnabled} {activeTab} on:activeTabChanged />
  </div>
</div>

<div class="container my-4">
  <BannerHelp state='new_training_card'/>
</div>
