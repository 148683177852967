<script>
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import Button, { Label } from "@smui/button";
    import Card, { Content } from "@smui/card";
    import SectionTitle from "../SectionTitle.svelte";

    const dispatch = createEventDispatcher();

    export let endsAt;
    export let payMethodExpireMonth;
    export let payMethodExpireYear;
    export let payMethodLast4;
    export let payMethodUserName;
    export let upgradeRequested = false;
</script>

<style>
    * :global(.customCardContent) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
</style>

<Card>
    <Content class="customCardContent h100">
        <SectionTitle text={$_('dettagli_di_pagamento_e_fatturazione')} />
        <div class="row">
            <span class="col-12">{`${$_('carta_di_credito')}: **** **** **** ${payMethodLast4}`}</span>
            <span class="col-12">{`${$_('intestatario')}: ${payMethodUserName}`}</span>
            <span class="col-12">{`${$_('scadenza')}: ${payMethodExpireMonth}/${payMethodExpireYear}`}</span>
            {#if upgradeRequested}<span class="col-12">{`Il tuo piano cambierà in data ${endsAt}`}</span>{/if}
        </div>
        <div style="text-align:end" class="mt-2">
            <!-- <Button variant="unelevated" 
                on:click={() => dispatch('changeSubscription')}
            >
                <Label>{$_('cambia_abbonamento')}</Label>
            </Button> -->
            <!-- <Button variant="unelevated" 
                on:click={() => dispatch('startCustomerPortal')}
            >
                <Label>Portale Cliente</Label>
            </Button> -->
            <Button variant="unelevated" on:click>
                <Label>{$_('vedi_dettagli')}</Label>
            </Button>
        </div>
    </Content>
</Card>