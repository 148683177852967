<script>
  import { _ } from "../../services/i18n";
  import { exercises, exercisesActiveOrder, exercisesActivePage, exercisesActiveSearch, exercisesActiveTagsFilter, exercisesTotalPages, jwt, userData } from "../../lib/store";
  import { getExercises, getExercisesList, refreshExerciseData, updateShowBaseExercises } from "../../services/exercisesServices";
  import { onMount, tick } from "svelte";
  import BannerHelp from "../ReusableLayouts/BannerHelp.svelte";
  import ExercisesListWrapper from "./ExercisesListWrapper.svelte";
  import HeaderOneLine from "../ReusableLayouts/HeaderOneLine.svelte";
  import Loading from "../ReusableLayouts/Loading.svelte";

  export let location;
  export let navbarDisabled = false;
  export let onLocaleChanged;
  export let showNavbar = true;

  let activeDropdownTagFilter;
  let activePage = 1;
  let activePaginationFilter = false;
  let ascOrder = true;
  let checkAllExercises = false;
  let checkBaseExercises = false;
  let firstSearch = false;
  let headerProps = {};
  let itemsPerPage = 20;
  let listExercises;
  let loading = false;
  let msgError;
  let number;
  let showBaseExercisesFilter = $userData.show_base_exercises;
  let search = "";
  let tagsFiltered = [];
  let totalPages = $exercisesTotalPages;
  
  $: headerProps = {
    buttonIcon: "",
    buttonLabel: $_("nuovo_esercizio"),
    buttonNavigate: "new-exercise",
    labelNumber: $_("esercizi_privato"),
    nameTitle: $_("esercizi"),
    number: Array.isArray(listExercises) ? listExercises.length : '',
    parseLabel: true,
    search,
  };

  $: if (location && location.state && location.state.activeSearch)
    search = location.state.activeSearch;

  $: if (location && location.state && location.state.activeOrder)
    ascOrder = location.state.activeOrder;
  
  $: if (location && location.state && location.state.activeDropdownTagFilter)
    activeDropdownTagFilter = location.state.activeDropdownTagFilter;
  
  $: if (location && location.state && location.state.activeTagsFilter)
    tagsFiltered = location.state.activeTagsFilter;

  $: if (location && location.state && location.state.activePagination)
    activePaginationFilter = location.state.activePagination;

  $: $exercisesActiveSearch = search.slice();
  $: $exercisesActiveOrder = ascOrder;
  $: $exercisesActiveTagsFilter = [...tagsFiltered];

  $: if ($userData.show_base_exercises === 1 || $userData.show_base_exercises === 0) {
    if (showBaseExercisesFilter === !$userData.show_base_exercises) {
      updateShowBaseExercises(showBaseExercisesFilter);
      activePage = 1;
      exercisesActivePage.set(activePage);
    }
    $userData.show_base_exercises = showBaseExercisesFilter ? 1 : 0;
  }

  const handleActivePageChange = async (e) => {
    activePage = e.detail.page;
    loading = true;
    await tick();
    await fetchExercises(false);
  };

  const handleShowBaseExercises = async (e) => {
    await fetchExercises(false, 0, false);
  };

  const handleOrderChanged = async (e) => {
    ascOrder = e.detail.ascOrder;
    // Ordina gli esercizi (fa un eventuale reverse basandosi sul valore asc di default)
    let ptExercises = listExercises.filter(e => e.base === 0);
    let baseExercises = listExercises.filter(e => e.base === 1);
    listExercises = [...ptExercises.reverse(), ...baseExercises.reverse()];
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    activePage = 1;
    await fetchExercises(false);
  };

  const handleTagsFiltered = async (e) => {
    tagsFiltered = e.detail.tags.map((i) => i.id);
    activePage = 1;
    await fetchExercises(false, 0);
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const oldFetchExercises = async (updateStoreValue = true, base = 0, refresh = false) => {
    try {
      if (search || firstSearch || tagsFiltered.length > 0) {
        // Prepara ed effettua query
        let query = search.toLowerCase().split(" ").filter(sub => sub != "");
        listExercises = $exercises.filter(ex => 
          query.every((subQuery) => {
            return JSON.stringify(ex.name).toLowerCase().match(new RegExp(subQuery, 'i')) && ex.is_active === 1
          })
        );
        // firstSearch evita di richiamare l'endpoint quando il campo search viene cancellato dall'utente
        firstSearch = true;
        if (tagsFiltered.length > 0) {
          // Applica filtro per tag
          listExercises = listExercises.filter(ex => {
            let exerciseTags = ex.tags.map(t => t.id);
            return tagsFiltered.every(subQueryTag => exerciseTags.includes(subQueryTag))
          });
        }
        // Resetta activePage
        if ($exercisesActivePage) {
          activePage = $exercisesActivePage;
          await tick();
          exercisesActivePage.set(0);
        } else {
          activePage = 1;
        }
      // Alla prima apertura effettua un fetch degli esercizi 
      } else if (refresh || $exercises.length == 0) {
        if ($exercises.length == 0) {
          base = 2;
        }
        // await getExercises(updateStoreValue, base);
        await getExercisesList(true);
        listExercises = $exercises.filter(e => e.is_active === 1);
        totalPages = $exercisesTotalPages;
      } else {
        listExercises = $exercises.filter(e => e.is_active === 1);
        totalPages = $exercisesTotalPages;
      }
      // Applica il filtro degli esercizi base
      if (!showBaseExercisesFilter) {
        listExercises = listExercises.filter(e => e.base === 0);
      }
      // Pulisce risultati da esercizi eliminati (is_active === 0), nel caso in cui i filtri contengano esercizi eliminati
      listExercises = listExercises.filter(e => e.is_active === 1);

      
      loading = false;
    } catch (err) {
      console.log("fetchExercises err", err);
      displayError();
    }
  };

  const fetchExercises = async (updateStoreValue = true, base = 0, refresh = false) => {
    try {
      if (search || firstSearch || tagsFiltered.length > 0) {
        // Prepara ed effettua query
        let query = search.toLowerCase().split(" ").filter(sub => sub != "");
        listExercises = $exercises.filter(ex => 
          query.every((subQuery) => {
            return JSON.stringify(ex.name).toLowerCase().match(new RegExp(subQuery, 'i')) && ex.is_active === 1
          })
        );
        // firstSearch evita di richiamare l'endpoint quando il campo search viene cancellato dall'utente
        firstSearch = true;
        if (tagsFiltered.length > 0) {
          // Applica filtro per tag
          listExercises = listExercises.filter(ex => {
            let exerciseTags = ex.tags.map(t => parseInt(t.id));
            return tagsFiltered.every(subQueryTag => exerciseTags.includes(subQueryTag))
          });
        }
        // Resetta activePage
        if ($exercisesActivePage) {
          activePage = $exercisesActivePage;
          await tick();
          exercisesActivePage.set(0);
        } else {
          activePage = 1;
        }
      // Alla prima apertura effettua un fetch degli esercizi 
      } else if (refresh || $exercises.length == 0) {
        await getExercisesList(updateStoreValue);
        listExercises = $exercises.filter(e => e.is_active === 1);
        totalPages = $exercisesTotalPages;
      } else {
        listExercises = $exercises.filter(e => e.is_active === 1);
        totalPages = $exercisesTotalPages;
      }
      // Applica il filtro degli esercizi base
      if (!showBaseExercisesFilter) {
        listExercises = listExercises.filter(e => e.base === 0);
      }
      // Pulisce risultati da esercizi eliminati (is_active === 0), nel caso in cui i filtri contengano esercizi eliminati
      listExercises = listExercises.filter(e => e.is_active === 1);
      
      loading = false;
    } catch (err) {
      console.log("fetchExercises err", err);
      displayError();
    }
  };

  onMount(async () => {
    loading = true;
    // Verifica se $exercises ha valori senza is_active (ovvero: accesso effettuato prima dell'implementazione del delete logico degli esercizi) ed eventualmente fetcha esercizi base + pt  
    checkAllExercises = $exercises.filter(e => e.is_active === 1);
    checkBaseExercises = $exercises.filter(e => e.base === 1 && e.is_active === 1);

    // if (checkAllExercises.length == 0 || checkBaseExercises.length == 0) {
    //   // await getExercises(true, 2);
    //   await getExercisesList(true);
    // }

    // // Second Check: if empty, get base + pt exercises and save them in local storage
    // if ($jwt && (!localStorage.getItem('exercises') || $exercises.length == 0 || $exercises.filter(e => e.is_active).length === 0)) {
    //   // await getExercises(true, 2);
    //   await getExercisesList(true);
    // }

    await getExercisesList(true);

    await fetchExercises(true);
  });
</script>

<style>
  .headerBackground {
    background-color: #f9f9f9;
  }
  * :global(.buttonIcon) {
    background-image: url("/assets/new-exercise.svg");
    background-position: center;
    background-repeat: no-repeat;
    height: 1.5rem;
    width: 1.5rem;
  }
  @media (max-width: 767px) {
    .headerBackground {
      position: -webkit-sticky;
      position: sticky;
      top: 3.375rem;
      z-index: 2;
      height: fit-content;
      min-height: 78px; /* Houston-1963 */
    }
    * :global(.icons .buttonIcon) {
      background-image: url("/assets/menu-esercizi.svg");
      background-position: center;
      background-repeat: no-repeat;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
</style>

{#if loading}
  <Loading />
{:else}
  <div>
    {#if msgError}
      <div class="alert alert-danger fade show" role="alert">{msgError}</div>
    {/if}

    <div class="headerBackground">
      <div class="container fluid">
        <HeaderOneLine
          bind:showBaseExercisesFilter
          {...headerProps}
          on:showBaseExercises={handleShowBaseExercises}
          on:onComponentEvent={handleSearchChanged} />
      </div>
    </div>

    <div class="container my-lg-3">
      <BannerHelp state='exercises_list'/>
    </div>

    <ExercisesListWrapper
      bind:activePage
      bind:activeDropdownTagFilter
      bind:activePaginationFilter
      {ascOrder}
      {itemsPerPage}
      {listExercises}
      {search}
      {tagsFiltered}
      on:tagsFiltered={handleTagsFiltered}
      on:activePageChange={handleActivePageChange}
      on:orderChange={handleOrderChanged}
      on:refreshExercises={fetchExercises}
      on:error={displayError} />
  </div>
{/if}
