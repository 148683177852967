<script>
    import { _ } from "../../../services/i18n";
    import Button, { Label, Icon } from "@smui/button";
    import IconButton from "@smui/icon-button";
    import { copyToClipboard } from "../../../lib/utils";
    import SectionTitle from "./SectionTitle.svelte";

    export let balance = "0";
    export let referral_code = "XXXXXX";
    export let invitato_value = "";
    export let invitato_month = "";
    export let invitante_month = "";
    export let invitante_value = "";
</script>

<style>
    .importantInfo {
        font-size: 1.2rem;
    }

    .justFlex {
        display: flex;
    }
</style>

<SectionTitle text="" on:setComponent canGoBack={true} />

<div class="row mb-2 importantInfo">
    <p>
        <span class="col-12 justFlex">
            {`${$_('credito_residuo')}: ${balance} ${$_('euro')} `}
            <button
                class="btn btnTooltip mdc-ripple-upgraded"
                data-tooltip={$_('questo_è_il_credito')}>
                <Icon class="material-icons">info</Icon>
            </button>
        </span>
    </p>
    <div class="col-12">
        <span> {`${$_('referral_code_personale')}: `} </span>
        <span class="bold">
            {referral_code}
            <IconButton
                on:click={() => copyToClipboard(referral_code)}
                class="material-icons">
                content_copy
            </IconButton>
        </span>
    </div>

    <div class="col-12">
        <p>{$_('invita_amico')}</p>
        <ul>
            <li>
                {$_('invita_amico_sconto_amico', {
                    values: { invitato_value, invitato_month },
                })}
            </li>
            <li>
                {$_('invita_amico_sconto_pt', {
                    values: { invitante_value, invitante_month },
                })}
            </li>
        </ul>
    </div>

    <div class="col-12 mt-2">
        <Button
            variant="unelevated"
            on:click={() => window.open('http://how.revoo-app.com/knowledge/come-funziona-il-codice-referral-su-revoo', '_blank')}>
            <Label>{$_('scopri_di_piu')}</Label>
        </Button>
    </div>
</div>
