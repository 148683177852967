<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { Icon } from "@smui/common";
  import Tabs from "../../../ReusableLayouts/Tabs.svelte";

  export let activeTab;
  export let name;
  export let tabNavigationEnabled;
  export let tabs;

  const dispatch = createEventDispatcher();

</script>

<style>
  .titleWrapper {
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .headingsWrapper {
    align-items: center;
    justify-content: space-between;
  }
  .wrapper {
    background-color: #f9f9f9;
  }
  * :global(.mdc-tab__content) {
    text-transform: none;
  }
  * :global(.outlinedCustom) {
    border-color: black;
    color: black;
  }
  * :global(.outlinedCustomPrimary) {
    border-color: var (--primary);
    color: var (--primary);
  }
</style>

<div class="wrapper">
  <div class="container fluid">
    <div class="row headingsWrapper">
      <div class="col-8 my-4">
        <div
          class="mdc-typography--headline6 primary titleWrapper"
          on:click={() => {
            dispatch('goBack', {});
          }}>
          <Icon role="button" tabindex="1" class="material-icons">
            keyboard_arrow_left
          </Icon>
          <span
            class="demiBold">{name}</span>
        </div>
      </div>
    </div>
    <Tabs {tabs} {tabNavigationEnabled} {activeTab} on:activeTabChanged />
  </div>
</div>
