<script>
  import { createEventDispatcher } from "svelte";
  import { _ }              from "svelte-i18n";
  import { navigate }       from "svelte-routing";
  import Card, { Content, Media } from "@smui/card";
  import Checkbox           from "@smui/checkbox";
  import Dialog             from "@smui/dialog";
  import FormField          from "@smui/form-field";
  import IconButton         from "@smui/icon-button";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";
  import PlaylistLabels     from "../ReusableLayouts/PlaylistLabels.svelte";
  
  const dispatch = createEventDispatcher();
  
  export let filtratedGroup = {};
  export let filtratedVod = {};
  export let index;
  export let isMobile;
  
  let confirmationDeleteDialog;
  let showMobileIcons;
  let placeholderThumb = 'https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/vod_thumb_100.png';
  let vodIdToBeDeleted;

  const handleChecked = (e) => {
    dispatch("filtratedVodChecked", {
      checked: filtratedVod.selected,
    });
  };

  const handleAssignButtonClicked = async (vod) => {
    dispatch('assignButtonClicked', { 
      vod
    });
  };

  const handleEditButtonClicked = (vod) => {
    navigate('vod', { state: { vod } });
  };

  const handleDeleteButtonClicked = (id) => {
    vodIdToBeDeleted = id;
    confirmationDeleteDialog.open();
  };

  const handleDeleteConfirmation = () => {
    dispatch("deleteVod", {
      id: vodIdToBeDeleted,
    });
    confirmationDeleteDialog.close();
  };

  const handlePlayVod = () => {
    dispatch("playVod", {
      vod: filtratedVod,
    });
  }
</script>

<style>
  .textWrapper {
    opacity: 0.8;
    word-break: break-word;
  }
  .imagevod {
    border-radius: 7px;
    height: 100px;
    width: 100px;
    align-items: center;
    object-fit: cover;
    max-width: inherit;
  }
  * :global(.mdc-card.vodCard) {
    cursor: pointer;
  }

  * :global(.imageContainer) {
    height: 100px;
    width: 100px;
  }

  * :global(.playButtonWrapper) {
    position: absolute;
    opacity: 0;
  }

  * :global(.playButtonWrapper:hover), :global(.playButtonBackground:hover) {
    opacity: 1;
  }

  * :global(.playButtonWrapper > .mdc-icon-button) {
    width: 57px;
    height: 57px;
    font-size: 36px;
  }

  * :global(.playButtonBackground) {
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  * :global(.primary) {
    color: var(--primary);
  }

  @media (max-width: 767px) {
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }

    * :global(.imageContainer) {
      padding-left: 10px;
    }
  }
</style>

<Dialog bind:this={confirmationDeleteDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_il_video')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => confirmationDeleteDialog.close()}
    on:onConfirmation={handleDeleteConfirmation} />
</Dialog>

<Card
  class="vodCard mb-2">
  <Content>
    <div class="row no-gutters flexy justify-content-between">
      <div class="col-1 col-md-1 d-none d-md-block" style="padding-left:0px">
        <FormField>
          <Checkbox
            bind:checked={filtratedVod.selected}
            on:change={handleChecked} />
        </FormField>
      </div>
      <div class="col-4 col-md-2">
        <Media class="card-media-square customUploader" aspectRatio="square">
          <div class="d-flex justify-content-center align-items-center imageContainer">
            <img
              src={filtratedVod.thumbnail ?? placeholderThumb}
              alt=""
              class="imagevod d-flex" 
              />
              {#if filtratedVod.thumbnail}
                <div class="playButtonWrapper" on:click|stopPropagation={handlePlayVod(filtratedVod)}>
                  <div class="playButtonBackground"></div>
                  <IconButton class="material-icons">play_circle</IconButton>
                </div>
              {/if}
          </div>
        </Media>
      </div>
      <div class="col-4 col-md-2 px-md-2 px-lg-0">
        {#if filtratedVod.status != 'in_progress'}
          <div 
            class="demiBold textWrapper"
            style="cursor: pointer"
            on:click|stopPropagation={handleEditButtonClicked(filtratedVod)}>
            {filtratedVod.name}
          </div>
        {:else}
          <div class="demiBold textWrapper">
            {filtratedVod.name}
          </div>
        {/if}
      </div>
      {#if isMobile}
        <div
          class="col-2 col-md-3 pr-0 bg__color flexy bar--icons mobile justify-content-end"
          on:focusout={(e) => {
            if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
            showMobileIcons = undefined;
          }}>
          <div
            class="icon--action"
            class:primary={showMobileIcons === index}
            on:click|stopPropagation={() => (showMobileIcons === index ? handleDeleteButtonClicked(filtratedVod.vod_id) : (showMobileIcons = index))}>        
            <IconButton class="material-icons px-1" style="width: fit-content">
              {showMobileIcons === index ? 'delete' : 'more_vert'}
            </IconButton>
          </div>
          <div class="flexy">
            <div
              class="icon--action" 
              id="edit-icon" 
              class:hide={showMobileIcons != index}
              on:click|stopPropagation={handleEditButtonClicked(filtratedVod)}
              disabled={filtratedVod.status == 'in_progress' || !filtratedVod.thumbnail}>
              <IconButton data-mobile-icon class="material-icons px-2" style="width: fit-content">edit</IconButton>
            </div>
            <div 
              class="icon--action"
              class:hide={showMobileIcons != index}
              on:click|stopPropagation={handleAssignButtonClicked(filtratedVod)}>
              <IconButton
                data-mobile-icon
                class="material-icons px-1"
                style="width: fit-content"
                disabled={filtratedVod.status == 'in_progress' || !filtratedVod.thumbnail}>
                assignment_ind
              </IconButton>
            </div>
          </div>
        </div>
      {:else}
        {#if filtratedVod.status == 'complete' && filtratedVod.thumbnail}
          <div class="col-md-2 d-none d-md-flex textWrapper justify-content-center">
            {filtratedVod.views}
          </div>
          <div class="col-md-2 col-lg-2 d-none d-md-flex textWrapper justify-content-center">
            {#if filtratedVod.playlists && filtratedVod.playlists.length > 0}
              <div class="flexy justify-content-center">
                <span class="w100 text-center">
                  <PlaylistLabels playlists={filtratedVod.playlists} mx={false} centered={true} />
                </span>
              </div>
            {/if}
          </div>
        {:else if filtratedVod.status == 'in_progress' || !filtratedVod.thumbnail}
          <div class="col-md-4 col-lg-4 textWrapper d-flex">
            Video in elaborazione
          </div>
        {:else if filtratedVod.status == 'ready_for_upload'}
          <div class="col-md-4 col-lg-4 textWrapper d-flex">
            Errore upload (ready for upload)
          </div>
        {:else}
          <div class="col-md-4 col-lg-4 textWrapper d-flex">
            Errore upload video
          </div>
        {/if}
        <div class="col-3 col-sm-3">
          <div class="flexy justify-content-end desktop">
            <!-- <div>
              <IconButton class="material-icons-outlined" disabled>file_download</IconButton>
            </div> -->
            <div class="d-none d-md-block" on:click|stopPropagation={() => {
              handleDeleteButtonClicked(filtratedVod.vod_id);
            }}>
              <IconButton class="material-icons primary" >delete</IconButton>
            </div>
            <div class="d-none d-md-block" on:click|stopPropagation={() => {
              if (filtratedVod.status == 'complete' && filtratedVod.thumbnail) {
                handleEditButtonClicked(filtratedVod);
              }
            }}>
              <IconButton class="material-icons" disabled={filtratedVod.status == 'in_progress' || !filtratedVod.thumbnail}>edit</IconButton>
            </div>
            <div class="d-none d-md-block" on:click|stopPropagation={() => {
              if (filtratedVod.status == 'complete' && filtratedVod.thumbnail) {
                handleAssignButtonClicked(filtratedVod);
              }
            }}>
              <IconButton class="material-icons" disabled={filtratedVod.status == 'in_progress' || !filtratedVod.thumbnail}>assignment_ind</IconButton>
            </div>
          </div>
        </div>
      {/if}
    </div>
  </Content>
</Card>