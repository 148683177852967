<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { deleteAtGroup, getGroupEmails} from "../../services/teamServices";
  import { Icon } from "@smui/common";
  import { Label } from "@smui/list";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import BannerHelp from "../ReusableLayouts/BannerHelp.svelte";
  import Button from "@smui/button";
  import Card, { Content } from "@smui/card";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import GroupListItem from "./GroupListItem.svelte";
  import IconButton from "@smui/icon-button";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import NoItemsToShow from "../ReusableLayouts/NoItemsToShow.svelte";
  import Pagination from "../ReusableLayouts/Pagination.svelte";

  export let filtratedGroups = [];
  export let groupPage = 1;
  export let loadingGroups = true;
  export let orderByGroups = "name";
  export let orderDesc = false;
  export let searchName;
  export let totalGroupPages = 1;

  const jq = window.$;
  const dispatch = createEventDispatcher();

  let activePage = 0;
  let allSelected = false;
  let customers = [];
  let filterDialog;
  let isMobile = false;
  let msgError;
  let page;
  let pageReady = false;
  let showButton = false;
  let totalPages;

  $: activePage = groupPage - 1;
  $: page = groupPage;
  $: totalPages = totalGroupPages;

  const switchPage = async (event) => {
    groupPage = event.detail.page + 1;
    dispatch("updateFiltratedGroups", {});
  };

  const setCheckboxes = (value) => {
    filtratedGroups = filtratedGroups.map((ele) => {
      ele.selected = value;
      return ele;
    });
  };

  $: {
    if (allSelected) {
      setCheckboxes(true);
    } else setCheckboxes(false);
  }

  const sendMultipleEmails = async () => {
    let destinations = [];
    let selectedGroupsIds = [];

    filtratedGroups.map((ele) => {
      ele.selected ? selectedGroupsIds.push(ele.id) : null
    });

    if (selectedGroupsIds.length > 0) {
      destinations = await getGroupEmails(selectedGroupsIds);    
      const to = destinations.toString().replace(",", ";");
      window.location.href = `mailto:${to}`;
    }
    
  };

  const orderByField = async (field) => {
    orderDesc = orderByGroups == field ? !orderDesc : true;
    orderByGroups = field;
    groupPage = 1;
    panels = [];
    dispatch("updateFiltratedGroups", {});
  };

  const checkboxChanged = () => {
    if (pageReady)
      dispatch("updateFiltratedGroups", { goToInitialPage: true });
  };

  const handleFiltratedGroupChecked = (e) => {
    if (e.detail.checked && !showButton) {
      showButton = true;
    } else if (
      !Object.values(filtratedGroups).some((el) => el.selected === true)
    ) {
      showButton = false;
    }
  };

  const handleUpdateAtGroups = () => {
    dispatch("updateFiltratedGroups", {});
  }

  const handleDeleteAtGroup = async (e) => {
    try {
      const res = await deleteAtGroup(e.detail.id);
      dispatch("updateFiltratedGroups", {});
    } catch (err) {
      console.log("err handleDeleteGroup", err);
      displayError(err);
    }
  };

  const displayError = (err) => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  onMount(async () => {
    pageReady = true;
    jq(document).ready(() => {
      if (jq(".desktop").css("display") == "none") {
        isMobile = true;
      } else isMobile = false;
    });

    try {
      dispatch("updateFiltratedGroups", {});
    } catch (error) {
      console.log("getCustomersInfo error", error);
    }
  });
</script>

<style>
  .text--no-elements {
    font-size: 1.25rem;
    opacity: 0.5;
  }
  .opacity--eighty {
    opacity: 0.8;
  }
  * :global(.demo-list) {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .alignCenter {
    align-items: center;
  }
  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 0.875rem;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .inerithPad {
    padding: inherit;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
    border-radius: 0px;
  }
  .filterContainer {
    display: flex;
    justify-content: space-between;
  }
  .allCheckbox {
    justify-content: center;
    display: flex;
  }
  * :global(.filter .mdc-list-item__text) {
    margin: 0;
  }
  * :global(.filter .material-icons) {
    margin: 0;
  }
  * :global(.boxShadowBottom) {
    -webkit-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  }
  @media (min-width: 280px) and (max-width: 767px) {
    .desktop {
      display: none;
    }
    .subheader__options {
      position: -webkit-sticky;
      position: sticky;
      top: 9.95rem;
      z-index: 2;
    }
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }
  }
  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }
</style>

<div class="container my-3">
  <BannerHelp state='groups_list'/>
</div>

<div class="container fluid desktop">
  {#if msgError}
    <div class="alert alert-danger fade show mt-2" role="alert">
      {msgError}
    </div>
  {/if}
  <div class="row no-gutters filterContainer py-3">
    <Button
      color="secondary"
      variant="unelevated"
      style={`visibility: ${allSelected || showButton ? 'visible' : 'hidden'}`}
      on:click={() => {
        sendMultipleEmails();
      }}>
      <Label style="text-transform: none;margin-bottom: 0px;">
        {$_('invia_email')}
      </Label>
    </Button>
  </div>
  <Card class="invisibleCard">
    <Content class="pb-0">
      <div class="row no-gutters flexy">
        <div class="col-2 col-md-1 pl-0">
          <FormField>
            <Checkbox bind:checked={allSelected} />
          </FormField>
        </div>
        <div class="col-md-3">
          <div class="row no-gutters alignCenter">
            <div
              class="demiBold textTitle"
              style="cursor:pointer"
              on:click={(event) => orderByField('name')}>
              {$_('nome_gruppo')}
            </div>
            {#if orderByGroups == 'name'}
              <span
                on:click={() => {
                  orderByField('name');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if}
          </div>
        </div>
        <div class="col-md-2">
          <div class="demiBold textTitle">
            {$_('numero_atleti')}
          </div>
        </div>
        <div class="col-md-2">
          <div class="demiBold textTitle">
            {$_('codice_invito')}
          </div>
        </div>
        <div class="col-md-3">
        </div>
        <div class="col-md-1">
        </div>
      </div>
    </Content>
  </Card>
  <hr class="line" />
</div>
<div class="subheader__options mobile">
  <Card class="boxShadowBottom mb-3">
    <Content class="container fluid card--subheader py-0">
      <div class="row no-gutters align-items-center justify-content-between">
        <div class="allCheckbox pl-1">
          <Checkbox bind:checked={allSelected} />
        </div>
        <div style="padding-left:30px">
          <div class="row no-gutters align-self-end opacity--eighty">
            <div class="col-6 pr-2">
              <IconButton
                class="material-icons"
                on:click={() => orderByField('name')}>
                sort
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </Card>
  <div class="container fluid mobile mb-3">
    <Button
      color="secondary"
      variant="unelevated"
      style={`display: ${allSelected || showButton ? 'block' : 'none'}`}
      on:click={() => {
        sendMultipleEmails();
      }}>
      <Label style="text-transform: none;margin-bottom: 0px;">
        {$_('invia_email')}
      </Label>
    </Button>
  </div>
</div>

{#if loadingGroups}
  <Loading />
{:else if filtratedGroups.length > 0}
  <div class="container fluid">
    {#each filtratedGroups as filtratedGroup, i}
      <Card class="my-2"
        on:click={(event) => {
          if (event.target.tagName != 'INPUT' && event.target.tagName != 'BUTTON') {
            navigate(`/group?tab=members&groupId=` + filtratedGroup.id, {
              state: { groupId: filtratedGroup.id },
            });
          }
        }}>
        <Content>
          <div
            class="row px-2 spaceBetween align-items-center"
            style="cursor:pointer">
            <GroupListItem
                {filtratedGroup}
                on:deleteAtGroup={handleDeleteAtGroup}
                on:filtratedGroupChecked={handleFiltratedGroupChecked}
                {isMobile}
                index={i} />
          </div>
        </Content>
      </Card>
    {/each}
  </div>
  <Pagination {totalPages} {activePage} on:page={switchPage} />
{:else if !searchName}
  <div class="flexy">
    <Card class="invisibleCard">
      <Content>
        <NoItemsToShow
          upperText={$_('nessun_gruppo')}
          biggerText={$_('aggiungi_i_tuoi_gruppi')}
          icon="group_add"
          buttonText={$_('nuovo_gruppo')}
          pathToNavigateTo="new-group" />
      </Content>
    </Card>
  </div>
{:else}
  <div class="container-fluid my-4 text-center">
    <span
      class="text--no-elements demiBold">{$_('nessun_cliente_trovato')}</span>
  </div>
{/if}
