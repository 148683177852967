<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { customer, trainingCard } from "../../../../lib/store";
  import { deleteTrainingCard, getTrainingCardWorkouts, exportExecutedWorkouts } from "../../../../services/trainingCardServices";
  import { navigate } from "svelte-routing";
  import { removeCustomerFromGroup } from "../../../../services/teamServices";
	import * as FileSaver from "file-saver";
  import AssignedTrainingCardActions from "./AssignedTrainingCardActions.svelte";
  import AssignedTrainingCardInfo from "./AssignedTrainingCardInfo.svelte";
  import Card, { Content } from "@smui/card";
  import WorkoutsInTCList from "./WorkoutsInTCList.svelte";
  
  const jq = window.$;
  const dispatch = createEventDispatcher();

  export let customerId;
  export let firstCards = [];

  let downloading = false;
  let isMobile = false;
  let panels = [];
  let showMobileIcons;
  let withoutErasedEmptyFilteredCards = [];

  $: if (firstCards) {
    panels = new Array(firstCards.length).fill(false);
    withoutErasedEmptyFilteredCards = firstCards.filter((fc) => {
      if (!fc.is_active && fc.workouts.every((w) => w.workout_count == 0))
        return;
      else return fc;
    });
  }

  const handleDeleteTC = async ({ detail }) => {
    const { id, is_active } = detail;
    if ($customer.active && is_active) {
      try {
        await deleteTrainingCard(id);
        dispatch("removeTrainingCard", { id });
      } catch (err) {
        console.log("delete error", err);
        dispatch("onError", {});
      }
    }
  };

  const handleRemoveCustomerFromGroups = async ({ detail }) => {
    const { customer, groupsRemoving } = detail;
    customer.id = customer.customer_id;
    if (customer.active && groupsRemoving) {
      try {
        await Promise.all(groupsRemoving.map(async (group) => {
            await removeCustomerFromGroup(customer, group.id);
        }));
        dispatch('updateCards');
      } catch (err) {
        console.log("delete error", err);
        dispatch("onError", {});
      }
    }
  }

  const printCard = async ({ detail }) => {
    const card = detail.tc;
    card.workouts = await getTrainingCardWorkouts(card.id);
    navigate("/print-training-card", {
      state: {
        card,
      },
    });
  };

  const handleExport = async (e) => {
		downloading = true;
		const url = await exportExecutedWorkouts(customerId, e.detail.trainingCardId);
		FileSaver.saveAs(url);
		downloading = false;
	}

  onMount(() => {
    jq(document).ready(() => {
      if (jq(".desktop").css("display") == "none") {
        isMobile = true;
      } else isMobile = false;
    });
  });
</script>

<style>
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
</style>

{#each withoutErasedEmptyFilteredCards as tc, i}
  {#if tc.is_active}
    <Card class="mb-3">
      <Content>
        <div
          class="row px-2 spaceBetween"
          on:click={() => { if(tc.workouts.length != 0) (panels[i] = !panels[i])}}>
          <AssignedTrainingCardInfo {tc} {isMobile} on:updateDraftTrainingCard />
          <AssignedTrainingCardActions
            bind:showMobileIcons
            {tc}
            {customerId}
            {isMobile}
            index={i}
            on:print={printCard}
            on:deleteTC={handleDeleteTC}
            on:removeCustomerFromGroups={handleRemoveCustomerFromGroups} />
        </div>
      </Content>
      <WorkoutsInTCList
        {customerId} 
        {downloading}
        {isMobile}
        {panels}
        trainingCardStartDate={tc.start}
        index={i}
        on:export={handleExport}
        trainingCardId={tc.id}
        workouts={tc.workouts}
        />
    </Card>
  {/if}
{/each}
