<script>
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { getAtGroups } from "../../../services/teamServices";
  import { getLevels, generateUID } from "../../../lib/utils";
  import { group, userData } from "../../../lib/store";
  import { isTrainingCardAssignedToGroups } from "../../../services/trainingCardServices";
  import { navigate } from "svelte-routing";
  import Button, { Icon, Label } from "@smui/button";
  import CharacterCounter from "@smui/textfield/character-counter";
  import Chip, { Set, Text } from "@smui/chips";
  import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
  import DatePicker from "../../ReusableLayouts/DatePicker.svelte";
  import Dialog from "@smui/dialog";
  import ImageUploader from "../../ReusableLayouts/ImageUploader.svelte";
  import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";
  import MenuSurface from "@smui/menu-surface";
  import moment from "moment";
  import Select, { Option } from "@smui/select";
  import Switch from "@smui/switch";
  import Textfield from "@smui/textfield";
  
  export let customerId = null;
  export let preAssignedGroupId = null;
  export let trainingCard;

  const currentDate = moment(Date.now()).format("DD/MM/YYYY");
  const currentDateFormatted = moment(Date.now()).format("YYYY-MM-DD");
  const dispatch = createEventDispatcher();

  let dateDialogItalian; 
  let exitDialog;
  let filteredGroups;
  let formDirty = false;
  let formSurface;
  let fullscreen = 0;
  let groups = [];
  let inputId = generateUID();
  let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
  let levelItems = getLevels($_);
  let loadingGroups = true;
  let searchParamsType = "template";
  let selectedGroup = "";
  let startDateFormatted = moment(trainingCard.start).isValid() ? trainingCard.start : currentDateFormatted;
  let triggerCloseImageDialog = false;

  $: dateDialogItalian = trainingCard.start
      ? moment(trainingCard.start).format("DD/MM/YYYY")
      : currentDate;

  const handleDate = (e) => {
    if (moment(e.detail.date, "YYYY-MM-DD", true).isValid() === true) {
      trainingCard.start = e.detail.date;
      startDateFormatted = moment(trainingCard.start).format("YYYY-MM-DD");  
    }
  };
  
  $: searchParamsType = new URLSearchParams(location.search).get("type");

  const onSubmit = (e) => {
    let next = (e && typeof e == "string")
      ? e
      : '';
  
    try {
      trainingCard.fullscreen = fullscreen;
      trainingCard.length = Math.abs(trainingCard.length);
    } catch (err) {
      console.log(err);
    }
    let savingTrainingCard = trainingCard;
    if (savingTrainingCard.assignedToGroups) {
      savingTrainingCard.assignedToGroups = savingTrainingCard.assignedToGroups.map((group) => {
        return group.id;
      });
    }
    let clone = { ...savingTrainingCard };

    if (trainingCard.start != "") {
      clone.start = startDateFormatted;
    }

    if (next == "exit") {
      dispatch("trainingCardCreatedAndBackToList", {
        trainingCard: clone,
      });
    } else {
      dispatch("trainingCardCreated", {
        trainingCard: clone,
      });
    }
  };

  const handleGoBack = () => {
    exitDialog.close();
    leavePage();
  };

  const getGroups = async () => {
    try {
      loadingGroups = true;
      const { data, last_page } = await getAtGroups(500, 1, "",);
      loadingGroups = false;
      return data;
    } catch (error) {
      loadingGroups = false;
      console.log("getGroups error", error);
    }
  };

  const removeGroupFromSelected = (group) => {
    trainingCard.assignedToGroups = trainingCard.assignedToGroups.filter((i) => i != group);
    filteredGroups = [...filteredGroups, group];
    filteredGroups = filteredGroups.sort((a, b) => a.name.localeCompare(b.name));
    formDirty = true;
    cleanGroups();
  };

  const addChip = (chip) => {
    // if (trainingCard.assignedToGroups.length >= 3) return;
    trainingCard.assignedToGroups = [...trainingCard.assignedToGroups, chip];
    cleanGroups();
    formDirty = true;
  };

  // Clean groups removing selected ones (already applied) from filtered ones
  const cleanGroups = () => {
    filteredGroups = groups;
    filteredGroups = filteredGroups.filter(x => !trainingCard.assignedToGroups.some(y => x.id === y.id));    
  }

  const leavePage = () => {
    if (customerId) {
      navigate("/customer?tab=workouts", {
        state: { customerId },
      });
    } else if (trainingCard.is_assigned_to_group || trainingCard.assignedToGroups || searchParamsType == 'assignmentToGroups') {
      if ($group && $group.id) {
        navigate("group?tab=workouts&groupId=" + $group.id);
      } else {
        navigate("/team?tab=gruppi");
      }
    } else {
      navigate("/training-cards");
    }
  }

  const handleNumericTextFieldOnInput = (e) => {
		try {
			if (e.target.value < 0) {
				e.target.value = Math.abs(e.target.value);
			}
		} catch (err) {
			console.log('abs length value error');
		}
	}

  const handleClearDate = () => {
    trainingCard.start = "";
  };

  const handleImageError = () => {
    msgError = $_("la_dimensione_massima_consentita_è_di", {
      values: {
        dimension: 16,
      },
    });
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleImageAdded = (e) => {
    formDirty = true;
    trainingCard.image = e.detail.img;
  };


  onMount(async () => {
    if (trainingCard.id && trainingCard.is_assigned_to_group) {
      trainingCard.assignedToGroups = await isTrainingCardAssignedToGroups(trainingCard.id);
      groups = await getGroups();
      cleanGroups();
    } else if (searchParamsType == 'assignmentToGroups' || preAssignedGroupId) {
      groups = await getGroups();
      if (preAssignedGroupId !== null) {
        trainingCard.assignedToGroups = groups.filter((group) => { 
          if (group.id == preAssignedGroupId) 
            return group;
        });
      } else {
        trainingCard.assignedToGroups = [];
      }
      cleanGroups();
    }
    try {
      fullscreen = 
        (trainingCard.fullscreen != undefined && 
         trainingCard.fullscreen == true || trainingCard.fullscreen == "true") 
          ? 1 
          : 0;
    } catch (err) {
      fullscreen = 0;
      console.log(err);
    }
  });

</script>

<style>
  * :global(.menuSurfaceCustom) {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    color: #fff;
    min-width: 80%;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.mdc-chip) {
    background-color: #fff;
    height: auto;
    min-height: 28px;
  }
  * :global(.mdc-chip span) {
    white-space: normal;
    word-break: break-word;
  }
  * :global(.mdc-chip__icon.mdc-chip__icon--trailing) {
    opacity: 0.2;
    font-size: 1.8em;
    width: 0.8em;
    height: 1em;
  }
  * :global(.customChip) {
    border-radius: 13.5px;
    background-color: #212121 !important;
    color: #fff !important;
    min-width: fit-content;
    text-transform: inherit;
    font-weight: normal;
    height: 2em;
    margin: auto;
    margin-left: 0.7em;
    margin-top: 0.5rem;
    height: auto;
    min-height: 28px;
  }
  * :global(.customChip span i) {
    opacity: 0.5;
    background-color: #ffffff;
    border-radius: 50%;
    color: #212;
    font-size: 1.3em;
    width: 1em;
    height: 1em;
    margin-right: 0;
  }
  * :global(.mdc-button__label) {
    text-align: left;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  .label {
    text-align: end;
    align-self: center;
    opacity: 0.8;
    font-weight: 600;
  }
  .label {
    text-align: end;
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
  }

  * :global(.icon--help) {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  * :global(.groupsLoader) {
    height: 3rem;
  }

  * :global(.groupsLoader > div) {
    flex-direction: column;
  }

  * :global(.groupsLoader .spinner) {
    position: inherit;
    height: 2rem;
    width: 2rem;
  }

  * :global(.tags-textfield) {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
  }
  
  * :global(.tags-textfield input) {
    height: 2.2rem !important;
  }

  * :global(.textUppercase) {
    text-transform: uppercase;
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
</style>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={handleGoBack} />
</Dialog>

<div class="py-3">
  <div class="row">
    {#if $userData.tc_cover_enabled}
      <div class="col-4 label">Cover</div>
      <div class="col-8">
        <ImageUploader
          bind:image={trainingCard.image}
          bind:imageThumbnail={trainingCard.image_thumbnail}
          bind:triggerCloseImageDialog
          trainingCardCover={true}
          on:error={handleImageError}
          on:updateVodImage={handleImageAdded} />
      </div>
    {/if}
    <div class="col-4 label">{customerId || preAssignedGroupId ? $_('nome_piano') : $_('nome_modello')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class="w100 my-2"
        input$maxlength={191}
        label="Es. Piano Massa Muscolare"
        bind:value={trainingCard.name}
        type="text" />
    </div>
    {#if trainingCard.is_assigned_to_group || searchParamsType == 'assignmentToGroups' || preAssignedGroupId}
      <div class="col-4 label">{$_('assegna_al_gruppo')}</div>
      <div class="col-8">
        {#if loadingGroups}
          <div class="groupsLoader d-flex justify-content-start align-items-center">
            <LoadingInline />
          </div>
        {:else}
          {#if !groups || groups.length == 0}
            <Button
              color="primary"
              variant="unelevated"
              on:click={() => {
                navigate('/new-group');
              }}>
              <Label>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing">
                  group
                </Icon>
                {$_('crea_un_gruppo')}
              </Label>
            </Button>
          {:else}
            <div class="dropdown mt-2">
              <Textfield
                disabled
                variant="outlined"
                label=""
                bind:value={selectedGroup}
                on:change={() => (formDirty = true)}
                on:click={formSurface.setOpen(true)}
                on:keyup={formSurface.setOpen(true)}
                class="w100 textfield--large tags-textfield"
                withTrailingIcon
                id="dropdownMenuButton--groupAssignment"
                aria-haspopup="true"
                aria-expanded="false">
                <div style="width: 85%;">
                  {#if trainingCard.assignedToGroups && trainingCard.assignedToGroups.length > 0}
                    {#each trainingCard.assignedToGroups as group}
                      <Button class="btn customChip">
                        <Label>
                          {group.name}
                          <!-- <Icon
                            class="material-icons"
                            on:click={() => removeGroupFromSelected(group)}>
                            close
                          </Icon> -->
                        </Label>
                      </Button>
                    {/each}
                  {/if}
                </div>
                <!-- <Icon
                  tabindex="1"
                  on:click={() => cleanGroups()}
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing">
                  keyboard_arrow_down
                </Icon> -->
              </Textfield>
              <MenuSurface
                class="menuSurfaceCustom"
                bind:this={formSurface}
                anchorCorner="BOTTOM_LEFT">
                <Set chips={filteredGroups} let:chip>
                  <Chip tabindex="0" on:click={() => addChip(chip)}>
                    <Text>{chip.name}</Text>
                  </Chip>
                </Set>
              </MenuSurface>
            </div>
          {/if}
        {/if}
      </div>
    {/if}
    <div class="col-4 label">{$_('data_inizio')}</div>
    <div class="col-8 pt-2">
      <DatePicker
        dateFormat="yyyy-mm-dd" 
        defaultDate={trainingCard.start != "" ? dateDialogItalian : ""}
        maxDate={moment().add(90, 'years').toISOString()}
        on:date={handleDate}
        on:clearDate={handleClearDate}
        width="100"
        withClearButton={true}
        />
    </div>

    <div class="col-4 label">{$_('durata_in_settimane')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class="w50 my-2"
        bind:value={trainingCard.length}
        type="number"
        on:input={handleNumericTextFieldOnInput}
        input$maxlength={999}
        input$min={0} />
    </div>
    <div class="col-4 label">{$_('livello')}</div>
    <div class="col-8">
      <Select
        enhanced
        variant="outlined"
        class="w50 mb-2"
        label=""
        bind:value={trainingCard.level}>
        {#each levelItems as { value, label }}
          <Option {value} selected={trainingCard.level === value}>
            {label}
          </Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label py-3">{isMobile ? $_('visualizzazione_semplificata') : $_('visualizzazione_semplificata_per_il_cliente')}</div>
    <div class="col-8 d-flex align-items-center py-3">
      <Switch bind:checked={fullscreen} />
      <Icon
          role="button"
          tabindex="1"
          class="ml-3 material-icons mdc-text-field__icon
                  mdc-text-field__icon--trailing opacityReduce icon--help"
          on:click={() => window.open('https://how.revoo-app.com/knowledge/scheda-semplificata-o-normale-la-differenza', '_blank')}>
          help
        </Icon>
    </div>
    <div class="col-4 label">{$_('note_generali')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="5000"
        class="fullWidth my-2"
        bind:value={trainingCard.note}
        label="" 
      >
        <CharacterCounter>
          {`${trainingCard.note.length} / 5000`}
        </CharacterCounter>
      </Textfield>
    </div>
  </div>

  <hr />

  <div class="row pushToEnd">
    <Button class="btn" on:click={() => exitDialog.open()}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-3 mr-3"
      variant="unelevated"
      on:click={onSubmit}
      disabled={!trainingCard.name}>
      <Label>
        {trainingCard.id ? $_('salva') : $_('crea_nuovo_piano')}
      </Label>
    </Button>
    {#if trainingCard.id}
      <Button
        class="btn ml-3 mr-3 button--bigger"
        variant="unelevated"
        disabled={!trainingCard.name}
        on:click={() => { 
          onSubmit('exit');
        }}>
        <Label>
          <span>{$_('salva_ed_esci')}</span>
        </Label>
      </Button>
    {/if}
  </div>
</div>
