<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher, tick } from "svelte";
  import { deleteVod } from "../../services/vodsServices";
  import { Embed } from "@vime/svelte";
  import { getCustomers } from "../../services/teamServices.js";
  import { getAtGroups } from "../../services/teamServices.js";
  import { Icon } from "@smui/common";
  import { onMount } from "svelte";
  import AssignVodDialog from "./AssignVodDialog.svelte";
  import BannerHelp from "../ReusableLayouts/BannerHelp.svelte";
  import Button from "@smui/button";
  import Card, { Content } from "@smui/card";
  import Checkbox from "@smui/checkbox";
  import Dialog from "@smui/dialog";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import List, { Item, Meta, Label } from "@smui/list";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import NoItemsToShow from "../ReusableLayouts/NoItemsToShow.svelte";
  import Pagination from "../ReusableLayouts/Pagination.svelte";
  import VodsListItem from "./VodsListItem.svelte";

  export let filtratedVods = [];
  export let loading = false;
  export let orderBy = "name";
  export let orderDesc = false;
  export let page = 1;
  export let searchName;
  export let totalPages = 1;

  const dispatch = createEventDispatcher();

  let activePage = 0;
  let allSelected = false;
  let assignmentDialog;
  let assignmentDialogClicked = false;
  let assignmentLoading = false;
  let customers;
  let filterDialog;
  let groups;
  let itemsTypeSelected = $_('atleti');
  let msgError;
  let pageReady = false;
  let play = false;
  let search = ""; // assignment dialog search
  let showButton = false;
  let vodPlayerDialog;
  let vodToBeAssigned;
  let vodToPlay = {};

  $: activePage = page - 1;

  // $: if (assignmentDialog) assignmentDialog.open();

  let isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  const switchPage = async (event) => {
    page = event.detail.page + 1;
    dispatch("updateFiltratedVods", {});
  };

  const setCheckboxes = (value) => {
    filtratedVods = filtratedVods.map((ele) => {
      ele.selected = value;
      return ele;
    });
  };

  $: {
    if (allSelected) {
      setCheckboxes(true);
    } else setCheckboxes(false);
  }

  const displayError = (err = null) => {
    let timeoutTime = 3000;
    msgError = $_("si_è_verificato_un_errore");
    if (err.message.exception && err.message.exception === 'PlanConflictException') {
      
      msgError = $_("plan_conflict_exception");
      timeoutTime = 9000000;
    }
    setTimeout(() => {
      msgError = "";
    }, timeoutTime);
  };

  const orderByField = async (field) => {
    orderDesc = orderBy == field ? !orderDesc : true;
    orderBy = field;
    page = 1;
    dispatch("updateFiltratedVods", {});
  };

  const checkboxChanged = () => {
    if (pageReady)
      dispatch("updateFiltratedVods", { goToInitialPage: true });
  };

  onMount(async () => {
    pageReady = true;
  });

  const handleFiltratedVodChecked = (e) => {
    if (e.detail.checked && !showButton) {
      showButton = true;
    } else if (
      !Object.values(filtratedVods).some((el) => el.selected === true)
    ) {
      showButton = false;
    }
  };

  const handleAssignButtonClicked = async (e) => {
    vodToBeAssigned = e.detail.vod;
    assignmentDialogClicked = true;
    await tick();
    assignmentDialog.open();
    assignmentLoading = true;
    await getClients();
    await getGroups();
    assignmentLoading = false;
    await tick();
  }

  const getClients = async () => {
    try {
      const { data } = await getCustomers(500, 1, "", search);
      customers = data;
    } catch (error) {
      console.log("getCustomers error", error);
      displayError(error);
    }
  };

  const getGroups = async () => {
    try {
      const { data } = await getAtGroups(500, 1, search);
      groups = data;
    } catch (error) {
      console.log("getAtGroups error", error);
      displayError(error);
    }
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    itemsTypeSelected == $_('atleti') ? await getClients() : await getGroups();
  };

  const handleDeleteVod = async (e) => {
    try {
      const res = await deleteVod(e.detail.id);
      dispatch("updateSubscriptionInfo");
      dispatch("updateFiltratedVods", { goToInitialPage: true });
    } catch (err) {
      console.log("handleDeleteVod err", err);
      displayError(err);
    }
  }

  const handlePlayVod = async (e) => {
    e.stopPropagation();
    console.log(e.detail);
    vodToPlay = e.detail.vod;
    play = true;
    vodPlayerDialog.open();
  }

  const closeVodPlayerDialog = () => {
    play = false; 
    vodToPlay = {}
    vodPlayerDialog.close();
  };

  onMount(() => {
    dispatch("updateFiltratedVods", {});
  });
</script>

<!-- {#if assignmentDialogClicked} -->
  <Dialog class="dialog--big" bind:this={assignmentDialog}>
    <AssignVodDialog
      bind:itemsTypeSelected
      {customers}
      {groups}
      {loading}
      {msgError}
      {vodToBeAssigned}
      on:searchChange={handleSearchChanged}
      on:closeDialog={() => assignmentDialog.close()} 
      text={$_('assegna_video')} />
  </Dialog>
<!-- {/if} -->

<Dialog bind:this={vodPlayerDialog} class="dialog--big no-click-backdrop">
  <div class="close-icon-wrapper">
    <IconButton class="material-icons" on:click={closeVodPlayerDialog}>
      close
    </IconButton>
  </div>
  {#if play}
  <div class="embed-responsive embed-responsive-1by1">
    <Embed
      class="embed-responsive-item"
      autoplay={true}
      color="#fc929e"
      embedSrc="https://player.vimeo.com/video/{vodToPlay.vimeo_id}"
      origin="https://www.vimeo.com"
      mediaTitle="{vodToPlay.name}"
      controls={0}
    />
  </div>
  {/if}
</Dialog>

<div class="container my-md-3">
  <!-- <BannerHelp state='team_list'/> -->
</div>

<div class="container fluid desktop">
  <!-- <div class="row no-gutters filterContainer py-3">
    <Button
      color="secondary"
      variant="unelevated"
      style={`visibility: ${allSelected || showButton ? 'visible' : 'hidden'}`}
      on:click={() => {
        
      }}>
      <Label style="text-transform: none;margin-bottom: 0px;">
        {$_('invia_email')}
      </Label>
    </Button>
  </div> -->
  <Card class="invisibleCard">
    <Content class="pb-0">
      <div class="row no-gutters flexy justify-content-between">
        <div class="col-2 col-md-1">
          <FormField>
            <Checkbox bind:checked={allSelected} />
          </FormField>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="row no-gutters alignCenter">
            <div
              class="demiBold textTitle"
              style="cursor:pointer"
              on:click={(event) => orderByField('name')}>
              {$_('nome_video')}
            </div>
            {#if orderBy == 'name'}
              <span
                on:click={() => {
                  orderByField('name');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if}
          </div>
        </div>
        <div class="col-md-2 d-flex justify-content-center">
          <div class="row no-gutters inerithPad">
            <div
              class="demiBold textTitle"
              style="cursor:pointer"
              on:click={(event) => orderByField('views')}>
              {$_('views_totali')}
            </div>
            {#if orderBy == 'views'}
              <span
                on:click={() => {
                  orderByField('views');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if}
          </div>
        </div>
        <div class="col-md-2">
          <div class="row no-gutters inerithPad d-flex justify-content-center">
            <div class="demiBold textTitle">
              {$_('playlist')}
            </div>
          </div>
        </div>
        <div class="offset-md-3">
        </div>
      </div>
    </Content>
  </Card>
  <hr class="line" />
</div>
<div class="subheader__options mobile">
  <Card class="boxShadowBottom mb-3">
    <Content class="container fluid card--subheader py-0">
      <div class="row no-gutters align-items-center justify-content-end">
        <div class="allCheckbox pl-1 d-none d-md-block">
          <Checkbox bind:checked={allSelected} />
        </div>
        <div style="padding-left:30px">
          <div class="row no-gutters align-self-end opacity--eighty">
            <div class="col-6 pr-0">
              <IconButton
                class="material-icons"
                on:click={() => orderByField('name')}>
                sort
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </Card>
  <!-- <div class="container fluid mobile mb-3">
    <Button
      color="secondary"
      variant="unelevated"
      style={`display: ${allSelected || showButton ? 'block' : 'none'}`}
      on:click={() => {
        // sendMultipleEmails();
      }}>
      <Label style="text-transform: none;margin-bottom: 0px;">
        {$_('invia_email')}
      </Label>
    </Button>
  </div> -->
</div>

{#if loading}
  <Loading />
{:else if filtratedVods.length > 0}
  <div class="container fluid">
    {#each filtratedVods as filtratedVod, index}
      <VodsListItem
        {index}
        {isMobile}
        {filtratedVod}
        on:deleteVod={handleDeleteVod}
        on:playVod={handlePlayVod}
        on:assignButtonClicked={handleAssignButtonClicked}
        on:filtratedVodChecked={handleFiltratedVodChecked} />
    {/each}
  </div>
  <Pagination {totalPages} {activePage} on:page={switchPage} />
{:else if !searchName}
  <div class="flexy">
    <Card class="invisibleCard">
      <Content>
        <NoItemsToShow
          upperText={$_('nessun_video')}
          biggerText={$_('aggiungi_i_tuoi_video')}
          icon="ondemand_video"
          buttonText={$_('nuovo_video')}
          pathToNavigateTo="new-video" />
      </Content>
    </Card>
  </div>
{:else}
  <div class="container-fluid my-4 text-center">
    <span
      class="text--no-elements demiBold">{$_('nessun_video_trovato')}</span>
  </div>
{/if}

<style>
  * :global(.embed-responsive-item) {
    background-color: #000;
  }
  .text--no-elements {
    font-size: 1.25rem;
    opacity: 0.5;
  }
  .opacity--eighty {
    opacity: 0.8;
  }
  * :global(.demo-list) {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .alignCenter {
    align-items: center;
  }
  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 0.875rem;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .inerithPad {
    padding: inherit;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
    border-radius: 0px;
  }
  .filterContainer {
    display: flex;
    justify-content: space-between;
  }
  .allCheckbox {
    justify-content: center;
    display: flex;
  }
  * :global(.filter .mdc-list-item__text) {
    margin: 0;
  }
  * :global(.filter .material-icons) {
    margin: 0;
  }
  * :global(.boxShadowBottom) {
    -webkit-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  }
  .close-icon-wrapper {
    position: absolute;
    right: 38px;
    transform: translate(20px, 16px);
    background-color: rgba(255, 255, 255, 0.99);
    border-radius: 50px;
    z-index: 2;
  }
  @media (min-width: 280px) and (max-width: 767px) {
    .desktop {
      display: none;
    }
    .subheader__options {
      position: -webkit-sticky;
      position: sticky;
      top: 9.95rem;
      z-index: 2;
    }
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }
  }
  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }
</style>