<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher, tick } from "svelte";
  import { group } from "../../../../lib/store";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import { removeCustomerFromGroup } from "../../../../services/teamServices";
  import AssignGroupDialog from "../AssignGroupDialog.svelte";
  import Button, { Label } from "@smui/button";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import Media from "@smui/card";

  export let assignedCustomers = [];
  export let groupId;
  export let isMobile;
  export let massAssignment;
  
  let assignmentDialog;
  let assignmentDialogLoaded = false;
  let confirmationRemoveCustomerDialog;
  let customerToBeRemoved;
  let loading = false;
  let msgError;

  $: console.log(massAssignment, $group.mass_assignment);

  const dispatch = createEventDispatcher();

  const handleRemoveCustomerFromGroup = async (customer) => {
    try {
      await removeCustomerFromGroup(customer, groupId);
      await tick();
      dispatch('updateAtGroups');
    } catch (err) {
      console.log("handleRemoveCustomerFromGroup err", err);
    }
  };

  const handleRemoveCustomerButtonClicked = (customer) => {
    customerToBeRemoved = customer;
    confirmationRemoveCustomerDialog.open();
  };

  const handleRemoveConfirmation = (customer) => {
    handleRemoveCustomerFromGroup(customer);
    confirmationRemoveCustomerDialog.close();
  };

  // Assignment Dialog | Functions
  const handleAssignButtonClicked = async () => {
    assignmentDialogLoaded = true;
    assignmentDialog.open();
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    await getClients();
  };

  const handleCloseDialog = async () => {
    dispatch('updateAtGroups');
    assignmentDialog.close();
    assignmentDialogLoaded = false;
  }
</script>

<style>
  .athletesList {
    /* background-color: #f9f9f9; */
    padding: 16px;
  }
  .hide {
    display: none;
  }
  .textToEnd {
    text-align: end;
  }
  .label {
    opacity: 0.5;
    font-size: 0.75rem;
  }
  .titleWrapper {
    text-align: start;
    align-items: center;
    display: flex;
    opacity: 0.8;
  }
  .headline {
    font-size: 1.25rem;
  }
  .rowSpace {
    padding: 1rem 0rem;
  }
  .pushToEnd {
    display: flex;
    justify-content: flex-end;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .imageWrapper {
    background-color: #f3f3f3;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
  }
  .imagecustomer {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    object-fit: cover;
  }
  * :global(.mdc-card--imageCustomer) {
    /* background-color: #f9f9f9; */
    box-shadow: none;
  }
  .customDivider {
    border: 1px dashed #212121;
    opacity: 0.2;
  }
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .textCenter {
    text-align: center;
  }
  
  * :global(.primary) {
    color: var(--primary);
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
  * :global(.spinner) {
    height: 2em;
    width: 2em;
  }
</style>

<Dialog 
  class="dialog--big" 
  bind:this={assignmentDialog}
  on:click={(e) => {e.stopPropagation();}}>
  <AssignGroupDialog
    {groupId}
    {loading}
    {msgError}
    bind:assignmentDialogLoaded
    on:closeDialog={handleCloseDialog} 
    on:searchChange={handleSearchChanged}
    text={$_('assegna_al_gruppo')}
  />
</Dialog>

<Dialog bind:this={confirmationRemoveCustomerDialog} on:click={(e) => {e.stopPropagation()}}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_rimuovere_l_atleta_dal_gruppo')}
    confirmationButtonLabel={$_('rimuovi')}
    on:closeDialog={() => confirmationRemoveCustomerDialog.close()}
    on:onConfirmation={handleRemoveConfirmation(customerToBeRemoved)} />
</Dialog>

<div class="container fluid">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="pushToEnd my-3">
          <Button
            class="ml-4"
            color="secondary"
            variant="unelevated"
            disabled={massAssignment}
            on:click={handleAssignButtonClicked}>
            <Label>
              <Icon
                role="button"
                class="material-icons mdc-text-field__icon
                  mdc-text-field__icon--trailing">
                assignment_ind
              </Icon>
              {$_('modifica_membri')}
            </Label>
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div class="athletesList">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('membri_del_gruppo')}</div>
        </div>
      </div>
    </div>
    <div class="row spaceBetween align-items-start">
      <div class="col-12 col-lg-11">
        {#if !massAssignment}
          <div class="row">
            {#if Array.isArray(assignedCustomers)}
              {#if assignedCustomers.length > 0 && assignedCustomers[0].length > 0}
                {#each assignedCustomers as customerArray, i}
                  <div class="{!isMobile ? `col-4` : `col-12`}">
                    {#each customerArray as customer, i}
                      <div 
                        class="row px-0 spaceBetween"
                        on:click|stopPropagation={() => {
                          navigate(`/customer?tab=progress`, {
                            state: { customerId: customer.id, previousUrl: 'group?tab=members&groupId=' + groupId},
                          });
                        }}>
                        <div class="col-1 col-md-2 col-xl-1 icon--action pl-2 px-sm-2"
                          on:click|stopPropagation={handleRemoveCustomerButtonClicked(customer)}>
                          <IconButton
                            data-mobile-icon
                            class="btn material-icons primary"
                            style="width: fit-content">
                            delete_outline
                          </IconButton>
                        </div>
                        <div class="col-3 col-sm-2 col-md-3 px-md-2 pr-lg-0 pl-lg-0">
                          <Media class="mdc-card--imageCustomer align-items-center">
                            {#if customer.image}
                              <img src={customer.image} alt="" class="imagecustomer" />
                            {:else}
                              <div class="imageWrapper">
                                <div style="" />
                              </div>
                            {/if}
                          </Media>
                        </div>
                        <div class="col-8 col-sm-9 col-md-7 px-0 textLeft alignCenter  align-items-center titleWrapper demiBold">
                          <span class="nameWrapper">
                            {customer.first_name + ' ' + customer.last_name}
                          </span>
                        </div>
                      </div>
                    {/each}
                  </div>
                {/each}
              {:else}
                <div class="col-12">
                  <span>{$_('nessun_cliente_assegnato_al_gruppo')}</span>
                </div>
              {/if}
            {/if}
          </div>
        {:else}
          <span>{$_('tutti_i_tuoi_clienti_sono_assegnati_al_gruppo')}</span>
        {/if}
      </div>
    </div>
  </div>
</div>