import { POST, GET, PUT, DELETE } from "../lib/http";
import { customer } from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const getCustomerTrainingCards = async (customerId) => {
  try {
    const res = await GET(`/v2/get_customer_training_cards/${customerId}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getWeight = async (customerId, date) => {
  try {
    const res = await GET(`/v2/get_weight/${customerId}/${date}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const checkPaymentSucceeded = async (customerId, internalProductId) => {
  try {
    const res = await GET(`/v2/connect/customer/check_payment_succeeded`, {
      headers: { ...commonHeaders },
      query: {
        customer_id: customerId,
        internal_product_id: internalProductId,
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getCompletedWorkout = async (
  customerId,
  trainingCardId,
  date,
  workout_id
) => {
  try {
    const res = await GET(
      `/v2/get_completed_workout/${customerId}/${trainingCardId}/${date}`,
      {
        headers: { ...commonHeaders },
        query: {
          workout_id,
        },
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCompletedWorkoutsEnabledDates = async (
  customerId,
  workoutIds
) => {
  try {
    const res = await GET(
      `/v2/get_completed_workouts_enabled_dates/${customerId}`,
      {
        headers: { ...commonHeaders },
        query: {
          workoutIds,
        },
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateCustomerImage = async (body) => {
  try {
    const res = await POST(
      "/v2/update_customer_image",
      {
        body,
        headers: { ...commonHeaders },
      },
      true,
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const deletePhotoProgress = async (customerId, body) => {
  try {
    const res = await POST(`/v2/pt_delete_photoProgress/${customerId}`, {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getPhotoProgress = async (customerId) => {
  try {
    const res = await GET(`/v2/pt_get_photoProgress/${customerId}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const savePhotoProgress = async (body) => {
  try {
    const res = await POST(
      `/v2/store_photo_progress`,
      {
        headers: { ...commonHeaders },
        body,
      },
      true,
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCustomerVodsAndPlaylists = async (id, type = 'all') => {
  try {
    const res = await GET(`/v2/get_customer_vods_and_playlists/${id}`, {
      headers: { ...commonHeaders },
      query: {
        type,
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getCustomerSubscriptions = async (id) => {
  try {
    const res = await GET(`/v2/get_customer_subscriptions/${id}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveCustomerSubscriptionInfo = async (body) => {
  console.log('saveCustomerSubscriptionInfo');
  try {
    const res = await POST(`/v2/store_customer_subscription_info`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateCustomerSubscriptionInfo = async (body) => {
  try {
    const res = await PUT(`/v2/store_customer_subscription_info`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteCustomerSubscriptionInfo = async (id) => {
  try {
    const res = await POST(`/v2/delete_customer_subscription_info`, {
      headers: { ...commonHeaders },
      body: {
        id,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getSubscriptionsNote = async (customerId) => {
  try {
    const queryObj = {
      customer_id: customerId,
    };
    const res = await GET("/v2/get_customer_subscriptions_note", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    console.log("getSubscriptionsNote err", error);
  }
}

export const setSubscriptionsNote = async (customerId, noteDescription, noteId = false) => {
  try {
    if (!noteId || isNaN(noteId)) {
      const res = await POST(
        "/v2/store_customer_subscriptions_note",
        {
          body: {
            customer_id: customerId, 
            description: noteDescription, 
          },
          headers: { ...commonHeaders },
        },
      );
      return res;
    } else {
      const res = await PUT(
        "/v2/store_customer_subscriptions_note",
        {
          body: {
            customer_id: customerId, 
            description: noteDescription,
            note_id: noteId,
          },
          headers: { ...commonHeaders },
        },
      );
      return res;
    }
  } catch (error) {
    console.log("setSubscriptionsNote err", error);
  }
}