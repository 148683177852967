<script>
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Dialog from "@smui/dialog";
  import Button, { Label } from "@smui/button";
  import IconButton from "@smui/icon-button";
  import { Icon } from "@smui/common";
  import { addImage } from "../../../lib/utils";
  import { activeSubSection } from "../../../lib/store";

  export let exerciseImage;
  export let exerciseImageThumbnail = null;
  export let triggerCloseImageDialog;
  let error;

  $: if (triggerCloseImageDialog && fullImageDialog) {
    fullImageDialog.close();
    triggerCloseImageDialog = false;
  }

  let fullImageDialog;

  const onInputChange = (e) => {
    const file = e.target.files[0];
    if (!file || file.size > 16000000) {
      error = $_("la_dimensione_massima_consentita_è_di", { values: { dimension: 16 } });
      setTimeout(() => {
        error = "";
      }, 3000);
      return;
    }
    dispatch("updateExerciseImage", {
      img: file,
    });
    addImage(file, "exerciseImg");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    dispatch("updateExerciseImage", {
      img: file,
    });
    addImage(file, "exerciseImg");
  };
</script>

<style>
  .customUploader {
    background-color: #f3f3f3;
    border-radius: 10px;
    flex-direction: column;
    height: 13em;
    position: relative;
    text-align: center;
    width: 13em;
  }
  .customFileInput {
    left: 0px;
    opacity: 0;
    position: absolute;
    z-index: 5;
  }
  .imageWrapper {
    align-items: center;
    border-radius: 10px;
    display: flex;
    height: 13em;
    overflow: hidden;
    position: relative;
    width: 13em;
  }
  * :global(.imageWrapper img) {
    bottom: 50%;
    height: unset;
    left: 0;
    margin: 0 auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    translate: (0, -50%);
  }
  .maxLabel {
    font-size: 0.8rem;
    font-weight: 600;
    opacity: 0.5;
  }
  .indications {
    font-weight: 600;
    opacity: 0.8;
  }
  * :global(.bringToFront) {
    align-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 9;
  }
  .layer {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: pointer;
  }
  .layer:hover {
    opacity: 1;
    transition: all 0.3s ease;
  }
  * :global(.customColor) {
    background-color: var(--mdc-theme-on-primary, #fff);
    color: #000;
  }
  * :global(.customColor.mdc-button--outlined:not(:disabled)) {
    background-color: transparent;
    border-color: var(--mdc-theme-on-primary, #fff);
    color: var(--mdc-theme-on-primary, #fff);
  }
  .justifyCenter {
    display: flex;
    justify-content: center;
  }
  .imgContainer {
    background-color: #000;
    width: 100%;
    height: auto;
  }
  .imgContainer > img {
    width: 100%;
    height: auto;
  }
  .close-icon-wrapper {
    position: absolute;
    right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
  }
  .image-selected-wrapper {
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }
</style>

<Dialog class="no-click-backdrop" bind:this={fullImageDialog}>
  <div class="close-icon-wrapper">
    <IconButton class="material-icons" on:click={() => fullImageDialog.close()}>
      close
    </IconButton>
  </div>
  <div class="justifyCenter imgContainer">
    <img loading="lazy" class="img-fluid" src={exerciseImage} alt="exercise" />
  </div>
</Dialog>

<div class="d-flex flex-column">
  <div class="customUploader" on:drop={handleDrop}>
    <input
      class="customUploader d-flex customFileInput"
      type="file"
      id="file"
      accept="image/jpeg, image/png, image/webp"
      on:change={onInputChange} 
      disabled={$activeSubSection == $_('progressioni')}/>
    {#if !exerciseImage}
      <div class="customUploader d-flex">
        <div class="row">
          <div class="col-12 my-4">
            <span class="maxLabel">{`${$_('max')} 16 MB`}</span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 indications">
            <span>{$_('seleziona_foto')}</span>
            <br />
            <span>{$_('o_trascina')}</span>
            <br />
            <Icon
              role="button"
              tabindex="1"
              class="material-icons mdc-text-field__icon
                mdc-text-field__icon--trailing pt-2 pb-3 primary">
              camera_alt
            </Icon>
          </div>
        </div>
      </div>
    {:else}
      <div
        class={`imageWrapper flexy ${exerciseImageThumbnail ? 'bg--grey' : 'bg--black'}`}>
        <div class="layer">
          <div class="bringToFront">
            {#if typeof exerciseImage == 'string'}
              <Button
                class="btn mt-3 customColor"
                variant="unelevated"
                target="_blank"
                on:click={() => {
                  fullImageDialog.open();
                }}>
                <Label>{$_('visualizza')}</Label>
              </Button>
            {/if}
            <Button
              class="btn mt-3 customColor"
              variant="outlined"
              target="_blank"
              on:click={() => {
                exerciseImageThumbnail = null;
                exerciseImage = null;
              }}>
              <Label>{$_('elimina')}</Label>
            </Button>
          </div>
        </div>
        {#if !exerciseImageThumbnail}
          <div class="image-selected-wrapper" id="output" />
        {:else}
          <img loading="lazy" src={exerciseImageThumbnail} alt="exercise" />
        {/if}
      </div>
    {/if}
  </div>
  <span class="pt-2 text-danger pr-2">{error ? error : ''}</span>
</div>
