<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { customer } from "../../../../lib/store";
	import { navigate } from "svelte-routing";
	import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
	import Dialog from "@smui/dialog";
	import IconButton from "@smui/icon-button";
	import SvelteTooltip from 'svelte-tooltip';

	export let customerSubscription;
	export let customerId;
	export let index;
	export let isMobile;
	export let showMobileIcons = undefined;

	const dispatch = createEventDispatcher();

	let customerSubscriptionToBeDeleted = {};
	let confirmationDeleteDialog;

	const handleDeleteButtonClicked = (customerSubscription) => {
		customerSubscriptionToBeDeleted = customerSubscription;
		confirmationDeleteDialog.open();
	};

	const handleEditButtonClicked = () => {
		dispatch('editButtonClicked', { customerSubscription });
	};
</script>

<style>
	* :global(.primary) {
		color: var(--primary);
	}
	* :global(.op-0) {
		opacity: 0 !important;
		cursor: auto;
	}
	.hide {
		display: none;
	}
	.actions > div {
		width: 35px;
	}
</style>

<Dialog bind:this={confirmationDeleteDialog}>
	<ConfirmationDialog
		text={$_('sei_sicuro_di_voler_eliminare_l_abbonamento')}
		confirmationButtonLabel={$_('elimina')}
		on:closeDialog={(e) => {
			e.stopPropagation();
			confirmationDeleteDialog.close();
		}}
		on:onConfirmation={(e) => {
			e.stopPropagation();
			dispatch('deleteAssignment', {
				customerSubscription : customerSubscriptionToBeDeleted,
				is_active : customerSubscriptionToBeDeleted.is_active,
			});
			confirmationDeleteDialog.close();
		}} />
</Dialog>

{#if isMobile}
	<div
		class="col-3 col-xs-4 col-md-2 pr-0 bg__color flexy bar--icons justify-content-end"
		on:focusout={(e) => {
			if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
			showMobileIcons = undefined;
		}}>
		<div
			class="icon--action"
			class:primary={showMobileIcons == index}
			on:click|stopPropagation={() => {
				(showMobileIcons === index 
					? handleDeleteButtonClicked(customerSubscription) 
					: (showMobileIcons = index)
				)}}>
			<IconButton 
				class="material-icons px-0" 
				style="width: fit-content">
				{showMobileIcons == index 
					? `delete` 
					: 'more_vert'}
			</IconButton>
		</div>
		<div class="flexy">
			<div
				class="icon--action"
				id="edit-icon"
				class:hide={showMobileIcons != index}
				on:click|stopPropagation={() => {
					if ($customer.active && customerSubscription.is_active) navigate(
						'/customer-subscription', {
							state: {
								customerSubscription,
								customerId,
							},
						}
					);
				}}>
				<IconButton
					disabled={!customerSubscription.is_active || !$customer.active}
					data-mobile-icon
					class="material-icons pr-1"
					style="width: fit-content">
					edit
				</IconButton>
			</div>
		</div>
	</div>
{:else}
	<div class="actions col-3 col-md-2 d-flex align-items-center justify-content-end">
		{#if ($customer.active && customerSubscription.is_active)}
			<div
				class="mr-1"
				on:click|stopPropagation={() => {
					if ($customer.active && customerSubscription.is_active) {
						handleEditButtonClicked();
					}
				}}>
				<SvelteTooltip tip="{$_('modifica')}" top >
					<IconButton
						disabled={!customerSubscription.is_active || !$customer.active}
						class="material-icons">
						edit
					</IconButton>
				</SvelteTooltip>
			</div>
			<div 
				class="mr-2"
				on:click|preventDefault={handleDeleteButtonClicked(customerSubscription)}>
				<SvelteTooltip tip="{$_('elimina')}" top >
					<IconButton
						disabled={!customerSubscription.is_active || !$customer.active}
						class={`material-icons ${!customerSubscription.is_active || !$customer.active ? '' : 'primary'}`}>
						delete
					</IconButton>
				</SvelteTooltip>
			</div>
		{:else}
			<div
				class="mr-2" 
				on:click|stopPropagation>
				<SvelteTooltip tip="{$_('elimina')}" top >
					<IconButton
						disabled={!customerSubscription.is_active || !$customer.active}
						class={`material-icons ${!customerSubscription.is_active || !$customer.active ? '' : 'primary'}`}>
						delete
					</IconButton>
				</SvelteTooltip>
			</div>
		{/if}
	</div>
{/if}
