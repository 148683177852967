<script>
  import { _ } from "../../../services/i18n";
  import SectionTitle from "./SectionTitle.svelte";
  import ChargesList from "./ChargesList.svelte";

  export let amount_due = 0;
  export let discount_amount = 0;
  export let discount_name = "Sconto Revoo";
  export let discount_percent_off = 0;
  export let end_date = "25/12/2020";
  export let invoice_items = [];
  export let name = "Abbonamento REVOO";
  export let start_date = "25/11/2020";
  export let starting_balance = 0;
  export let subtotal = 0;
  export let total = 0;
  export let total_quantity = 1;

  $: discount_amount = (discount_amount / 100).toFixed(2);
  $: total = (total / 100).toFixed(2);
  $: subtotal = (subtotal / 100).toFixed(2);
  $: amount_due = (amount_due / 100).toFixed(2);
  $: starting_balance = (starting_balance / 100).toFixed(2);
</script>

<SectionTitle
  text={$_('dettaglio_prossimo_addebito')}
  on:setComponent
  canGoBack={true} />

<ChargesList
  {amount_due}
  {discount_amount}
  {discount_name}
  {discount_percent_off} 
  {end_date}
  {invoice_items}
  {name}
  {start_date}
  {starting_balance}
  {subtotal}
  {total_quantity}
  {total}
  />
