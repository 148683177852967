<script>
  import { _ } from "../../../../services/i18n";
  import { customer, exercises, jwt } from "../../../../lib/store";
  import { getEquipment, setEquipment, } from "../../../../services/teamServices";
  import { getExercises, getExercisesList, maxPage } from "../../../../services/exercisesServices";
  import { Icon } from "@smui/common";
  import { navigate, Link } from "svelte-routing";
  import { onMount, createEventDispatcher } from "svelte";
  import AssignedTrainingCardList from "./AssignedTrainingCardsList.svelte";
  import BannerHelp from "../../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label } from "@smui/button";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import LoadingInline from "../../../ReusableLayouts/LoadingInline.svelte";
  import NoItemsToShow from "../../../ReusableLayouts/NoItemsToShow.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import Textfield from "@smui/textfield";
  
  export let customerId = null;
  export let firstCards = [];
  export let loadingTrainingCards = true;
  export let nextCards = [];

  const dispatch = createEventDispatcher();

  let equipment = '';
  let equipmentId = '';
  let equipmentLoaded = false;
  let msgError;
  let previousEquipment = '';
  let typingEquipment = false;

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  $: if (customerId && $customer.active) {
    getEquipmentData();
  }

  const getEquipmentData = async () => {
    if (equipmentLoaded === false) {
      try {
        if (customerId) {
          const res = await getEquipment(customerId);
          if (res.description && res.description != '') {
            equipment = res.description;
            equipmentId = res.id;
            previousEquipment = equipment != null ? equipment.slice() : null;
            equipmentLoaded = true;
          }
        }
      } catch (error) {
        if (error.status == 404) {
          console.log('No equipment');
        }
      }
    }
  }

  $: if (equipmentLoaded === true) {
    setTimeout(() => {
      fixTextareaHeight();
    }, 100);
  }

  const setEquipmentData = async () => {
    try {
      const res = await setEquipment(customerId, equipment, equipmentId);
      if (res) {
        equipment = res.description != null ? res.description : '';
        previousEquipment = equipment != null ? equipment.slice() : '';
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  
  const addCards = () => {
    // display 3 more cards
    const cardsToBeDisplayed = nextCards.slice(0, 5);
    firstCards = [...firstCards, ...cardsToBeDisplayed];
    nextCards = nextCards.slice(5);
  };

  const handleRemoveTrainingCard = (e) => {
    firstCards = firstCards.map((i) => {
      if (i.id == e.detail.id) i.is_active = 0;
      return i;
    });
  };

  const handleUpdateDraftTrainingCard = (e) => {
    e.detail.is_draft = e.detail.is_draft ? 1 : 0;
    firstCards = firstCards.map((c) => {
      if (c.id === e.detail.id) c.is_draft = e.detail.is_draft;
      return c;
    });
  };

  const handleUpdateCards = () => {
    dispatch('updateCards');
  }

  const fixTextareaHeight = () => {
    const tx = document.getElementById("equipmentTextarea");
    if (tx) {
      tx.setAttribute("style", "height:" + (tx.scrollHeight) + "px;overflow-y:hidden;");
      tx.addEventListener("input", OnInput, false);
    }
    function OnInput() {
      this.style.height = "auto";
      this.style.height = (this.scrollHeight) + "px";
    }
  }

  onMount(async () => {
    // await getExercises(true, 0);
    await getExercisesList(true);
    

    // If empty, get base + pt exercises and save them in local storage
    if ($jwt && (!localStorage.getItem('exercises') || $exercises.length == 0 || $exercises.filter(e => e.is_active).length === 0)) {
      // await getExercises(true, 2);
      await getExercisesList(true);
    }
  });
</script>

<style>
  .headline {
    font-size: 1.25rem;
  }
  .rowSpace {
    padding: 2rem 0rem;
  }
  .pushToEnd {
    display: flex;
    justify-content: flex-end;
  }
  * :global(.saveBtn) {
    background: #cd293d !important;
    color: #fff !important;
    border: 0 !important;
  }
  * :global(.mdc-text-field.equipmentDescription) {
    height: auto;
    width: 100% !important;
  }
  * :global(.mdc-text-field--disabled .mdc-text-field__input) {
    color: black !important;
  }
</style>

<div class="container fluid">
  <MsgError {msgError} />

  <div class="my-3">
    <BannerHelp state='assigned_training_cards'/>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('attrezzatura')}</div>
          <div class="d-flex justify-content-end">
            {#if !typingEquipment}
              <Button
                color="secondary"
                variant="outlined"
                class={$customer.active ? `` : `action-disabled`}
                disabled={!$customer.active}
                on:click={() => {
                  typingEquipment = true;
                }}>
                <Label>
                  <Icon
                    role="button"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing">
                    edit
                  </Icon>
                  {equipment != null ? $_('modifica') : $_('aggiungi')}
                </Label>
              </Button>
            {:else}
              <Button
                color="secondary"
                variant="outlined"
                class={$customer.active ? `saveBtn` : `action-disabled`}
                disabled={!$customer.active}
                on:click={() => {
                  typingEquipment = false;
                  setEquipmentData();
                }}>
                <Label>
                  <Icon
                    role="button"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing">
                    save
                  </Icon>
                  {$_('salva')}
                </Label>
              </Button>
              <Button 
                class="btn undoBtn ml-2"
                on:click={() => {
                  typingEquipment = false;
                  previousEquipment = equipment != null ? equipment.slice() : null;
              }}>
                <Label>{$_('annulla')}</Label>
              </Button>
            {/if}
          </div>
          <div class="spaceBetween">
            <Textfield
              textarea
              input$id="equipmentTextarea"
              input$maxlength="1000"
              class="fullWidth my-2 equipmentDescription"
              bind:value={equipment}
              disabled={!typingEquipment}
              label="">
              <CharacterCounter>{equipment != null && equipment != '' ? `${equipment.length} / 1000` : `0 / 1000`}</CharacterCounter>
            </Textfield>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="pushToEnd my-3">
          <Button
            class="ml-4"
            color="secondary"
            variant="unelevated"
            disabled={!$customer.active || !customerId}
            on:click={() => navigate('/training-card?tab=info&customerId=' + customerId, {
                state: { customerId },
              })}>
            <Label>
              <Icon
                role="button"
                class="material-icons mdc-text-field__icon
                  mdc-text-field__icon--trailing">
                fitness_center
              </Icon>
              {$_('nuovo_piano')}
              {#if !customerId}
                <LoadingInline />
              {/if}
            </Label>
          </Button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('allenamenti')}</div>
        </div>
      </div>
    </div>
  </div>

  {#if loadingTrainingCards}
    <Loading />
  {:else}
    {#if firstCards && firstCards.length > 0}
      <AssignedTrainingCardList
        {firstCards}
        {customerId}
        on:onError={displayError}
        on:removeTrainingCard={handleRemoveTrainingCard}
        on:updateDraftTrainingCard={handleUpdateDraftTrainingCard}
        on:updateCards={handleUpdateCards} />

      <div class="flexy my-3">
        <Button
          color="secondary"
          variant="outlined"
          disabled={nextCards.length == 0}
          on:click={addCards}>
          <Label>{$_('mostra_altre')}</Label>
        </Button>
      </div>
    {:else}
      <NoItemsToShow
        upperText={$_('nessun_allenamento')}
        biggerText={`${$_('crea_primo_piano_allenamento')} ${$customer.first_name}`}
        icon="fitness_center"
        buttonText={$_('nuovo_piano')}
        pathToNavigateTo="new-training-card"
        routeState={{ state: { customerId } }} />
    {/if}      
  {/if}
</div>
