import { POST, GET, PUT, DELETE } from "../lib/http";
import { jwt } from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const getTemplateTrainingCards = async (
  workouts = false,
  per_page,
  page,
  name,
  ascOrder
) => {
  try {
    const queryObj = {
      workouts,
      per_page,
      page,
    };
    if (name) queryObj.name = name;
    queryObj.order_by = "name";
    ascOrder ? null : (queryObj.order_by_dir = "DESC");

    const res = await GET("/v2/get_templates_training_cards", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAssignedToGroupTrainingCards = async (
  workouts = false,
  per_page,
  page,
  name,
  selectedGroups,
  ascOrder
) => {
  try {
    const queryObj = {
      workouts,
      per_page,
      page,
    };
    selectedGroups ? (queryObj.selectedGroups = selectedGroups) : null;
    if (name) queryObj.name = name;
    queryObj.order_by = "name";
    ascOrder ? null : (queryObj.order_by_dir = "DESC");

    const res = await GET("/v2/get_assigned_to_group_training_cards", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLastTrainingCardAssignedToGroup = async (groupId) => {
  try {
    const res = await GET(`/v2/get_last_training_card_assigned_to_group/${groupId}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getTrainingCards = async (per_page, page, name, ascOrder, templates = false, withGroups = false) => {
  try {
    const queryObj = {
      per_page,
      page,
    };
    if (name) queryObj.name = name;
    queryObj.order_by = "name";
    if (templates === true) queryObj.is_template = true;
    if (withGroups === true) queryObj.withGroups = true;
    ascOrder ? null : (queryObj.order_by_dir = "DESC");

    const res = await GET("/v2/get_training_cards_list", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    if (error.message.message == "Unauthenticated." || error.status == 401) {
      console.log('Unauthenticated.')
      if (jwt === "") {
        console.log('Empty jwt.')
      } else {
        console.log('Resetting jwt.')
        jwt.set("");
        window.location.reload();
      }
    }
    throw error;
  }
};

export const getTrainingCardDetails = async (id) => {
  try {
    const queryObj = {
      id,
    };
    const res = await GET("/v2/get_training_card_details", {
      headers: { ...commonHeaders },
      query: queryObj,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const saveTrainingCardInfo = async (body) => {
  try {
    const res = await POST("/v2/store_training_card_info", {
      headers: { ...commonHeaders },
      body,
    },
    true,
    "multipart/form-data");
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateTrainingCardInfo = async (body) => {
  try {
    const res = await POST("/v2/store_training_card_info", {
        headers: { ...commonHeaders },
        body,
      },
      true,
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveTrainingCardWorkouts = async (workouts, id) => {
  try {
    if (workouts.length > 0) {
      const res = await POST(`/v2/store_training_card/${id}`, {
        headers: { ...commonHeaders },
        body: { workouts },
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
};

export const saveTrainingCardProgressions = async (progressions, id) => {
  try {
    const res = await POST(`/v2/store_training_card_progressions/${id}`, {
      headers: { ...commonHeaders },
      body: { progressions },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const storeTrainingCardAttachment = async (attachment, id) => {
  try {   
    let body = {};
    if (attachment.id) body.id = attachment.id;
    body.category = attachment.category;
    body.name     = attachment.name;
    body.rank     = attachment.rank;
    if (attachment.file && attachment.file != undefined) 
      body.attachment = attachment.file;
    const res = await POST(`/v2/store_training_card_attachment/${id}`, 
      {
        body,
        headers: { ...commonHeaders }
      },
      true,
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateTrainingCardAttachmentsRank = async (sortedAttachments, trainingCardId) => {
  try {   
    const res = await POST(`/v2/update_training_card_attachments_rank/${trainingCardId}`, 
      {
        body: 
          { 
            sortedAttachments: JSON.stringify(sortedAttachments),
          },
        headers: { ...commonHeaders }
      },
      true,
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteTrainingCard = async (id) => {
  try {
    const res = await POST(`/delete_training_card`, {
      headers: { ...commonHeaders },
      body: { id },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteAttachment = async (id, trainingCardId) => {
  try {
    const res = await DELETE(`/v2/delete_training_card_attachment/${trainingCardId}`, {
      headers: { ...commonHeaders },
      body: { id },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getWorkout = async (id) => {
  try {
    const res = await GET(`/v2/get_workout/${id}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getTrainingCardWorkouts = async (id) => {
  try {
    const res = await GET(`/v2/get_workouts/${id}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getTrainingCardProgressions = async (id) => {
  try {
    const res = await GET(`/v2/get_progressions/${id}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getTrainingCardAttachments = async (id) => {
  try {
    if (id) {
      const res = await GET(`/v2/get_attachments/${id}`, {
        headers: { ...commonHeaders },
      });
      return res;
    }
    else {
      return false;
    }
  } catch (err) {
    throw err;
  }
};

export const isTrainingCardAssignedToGroups = async (id) => {
  try {
    const res = await GET(`/v2/is_training_card_assigned_to_groups/${id}`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

export const updateIsDraftTrainingCard = async (training_card_id, is_draft) => {
  try {
    const res = await POST("/v2/update_is_draft_training_card", {
      headers: { ...commonHeaders },
      body: {
        training_card_id,
        is_draft,
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const sendTrainingCardNotifications = async (training_card) => {
  try {
    const res = await POST("/v2/training_card_fcm_notification/" + training_card.id, {
      headers: { ...commonHeaders },
      body: {},
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const exportExecutedWorkouts = async (customerId, trainingCardId) => {
  try {
    const res = await GET("/v2/executed_workouts/export/" + customerId + "/" + trainingCardId, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};