<script>
  import { _ } from "../../services/i18n";
  import Tabs from "../ReusableLayouts/Tabs.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";
  import PtPricingTable from "./PtPricingTable.svelte";
  import GroupPricingTable from "./GroupPricingTable.svelte";
  import {
    getPTs,
    getGroups,
    storeGroup,
    storePricing,
    updatePTsGroup,
    deleteGroup,
  } from "../../services/adminServices";

  let component = $_("personal_trainers");
  let activeTab = $_("personal_trainers");
  let tabs = [$_("personal_trainers"), $_("gestione_gruppi")];
  let options = {
    [$_("personal_trainers")]: PtPricingTable,
    [$_("gestione_gruppi")]: GroupPricingTable,
  };

  let msgError;

  let groups = [];
  let pts = [];
  let groupsFetched = false;
  let ptsFetched = false;
  let per_page = 20;
  let page = 1;
  let ptName = '';
  let totalPages;

  let props = {};
  $: props = { pts, groups, totalPages, page };

  $: if (!groupsFetched) fetchGroups();

  $: if (!ptsFetched) fetchPTs();

  const fetchGroups = async () => {
    try {
      groups = await getGroups();
      groupsFetched = true;
    } catch (err) {
      console.log("getGroups err", err);
      displayError();
    }
  };

  const fetchPTs = async () => {
    try {
      pts = [];
      const { data, last_page } = await getPTs(per_page, page, ptName);
      totalPages = last_page;
      pts = [...data];
      ptsFetched = true;
    } catch (err) {
      console.log("fetchPTs err", err);
      displayError();
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleActiveTabChanged = (e) => {
    component = [e.detail.tab];
  };

  const handleUpdatePtPricing = async (e) => {
    try {
      const res = await storePricing(e.detail.pt);
      await fetchPTs();
    } catch (err) {
      console.log("handleUpdatePtPricing err", err);
      displayError();
    }
  };

  const handleUpdatePtGroup = async (e) => {
    try {
      const res = await updatePTsGroup(e.detail.group);
      await fetchPTs();
    } catch (err) {
      console.log("handleUpdatePtPricing err", err);
      displayError();
    }
  };

  const handleDeleteGroup = async (e) => {
    try {
      const body = { id: e.detail.id };
      const res = await deleteGroup(body);
      await fetchGroups();
    } catch (err) {
      console.log("handleDeleteGroup err", err);
      displayError();
    }
  };

  const handleUpdateGroupPrincing = async (e) => {
    try {
      const group = e.detail.group;
      if (!group.id) delete group.id;
      const res = await storeGroup(group);
      await fetchGroups();
    } catch (err) {
      console.log("handleUpdateGroupPrincing err", err);
      displayError();
    }
  };

  const handleUpdatePtsPaging = async (e) => {
    if (e.detail.page) page = e.detail.page;
    await fetchPTs();
  };

  const handleUpdatePtSearch = async (e) => {
    ptName = e.detail.search;
    await fetchPTs();
  };
</script>

<div class="row no-gutters flexy">
  <div class="col-12">
    <Tabs
      class="mdc-tab--stacked"
      {tabs}
      {activeTab}
      tabNavigationEnabled={true}
      on:activeTabChanged={handleActiveTabChanged} />
  </div>
</div>

<div class="row no-gutters flexy">
  <div class="col-12">
    <MsgError {msgError} />

    <svelte:component
      this={options[component]}
      {...props}
      on:updatePtPrincing={handleUpdatePtPricing}
      on:updateGroupPrincing={handleUpdateGroupPrincing}
      on:updatePtGroup={handleUpdatePtGroup}
      on:deleteGroup={handleDeleteGroup}
      on:updatePtsPaging={handleUpdatePtsPaging}
      on:updatePtSearch={handleUpdatePtSearch} />
  </div>
</div>
