<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Actions } from "@smui/card";
  import SectionTitle from "../PtInfo/Billing/SectionTitle.svelte";
  
  export let confirmationButtonLabel;
  export let savingDialogMod = false;
  export let showCancelButton = true;
  export let text;

  const dispatch = createEventDispatcher();

  const canGoBack = false;
  const icon = null;
</script>

<style>
  * :global(.title) {
    font-size: 1.25rem;
  }

  .centerButton {
    margin: auto;
  }
</style>

<Card>
  <Content id="{!savingDialogMod ? `` : `mandatory_content`}">
    <div class="my-3 pb-2 title">
      <SectionTitle {text} {icon} {canGoBack} />
    </div>
    <Actions style="justify-content: space-between">
      {#if showCancelButton}
        <Button on:click={(e) => {
          e.stopPropagation();
          !savingDialogMod ? dispatch('closeDialog', {}) : dispatch('continue', {});
        }}>
          <Label>{!savingDialogMod ? $_('annulla') : $_('prosegui_senza_salvare')}</Label>
        </Button>
      {/if}
      <div class={showCancelButton ? '' : 'centerButton'}>
        <Button
          class="btn"
          variant="unelevated"
          style="background: var(--primary); color: white; border: 0;"
          on:click={() => {
            !savingDialogMod ? dispatch('onConfirmation', {}) : dispatch('saveAndContinue', {});
          }}>
          <Label>{confirmationButtonLabel}</Label>
        </Button>
      </div>
    </Actions>
  </Content>
</Card>
