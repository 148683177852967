<script>
	import { _ } from "../../../../services/i18n";
	import { getPaymentsMethodsDefault, getSetupIntent, saveNewPaymentMethod, getCreditCardBrandImage, verifySetupIntent } from "../../../../services/billingServices";
	import { getToUpdateHubspotPt } from "../../../../services/teamServices";
	import { loadStripe } from "@stripe/stripe-js";
	import { onMount, createEventDispatcher, tick } from "svelte";
  import { subscriptionInfo, subscription } from "../../../../lib/store";
	import Card, { Content, Actions } from "@smui/card";
	import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
	import Textfield from "@smui/textfield";
  import Button, { Label } from "@smui/button";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import LoadingInline from "../../../ReusableLayouts/LoadingInline.svelte";

  export let formValid = false;
	export let loading = false;
	export let paymentError = false;
	export let paymentErrorLabel = "";
	export let state;
  export let selectedPriceId;
  export let selectedPriceInterval;
  export let selectedPriceNextInvoiceDate;
  export let selectedPriceValue;
  export let selectedProductId;
  export let selectedProductName;
  export let setiSetupIntentId;
  export let setiSetupIntentClientSecret;
	
  const dispatch = createEventDispatcher();

	let cardInserted = false;
	let loadingDefaultMethod = false;
	let payMethodLast4 = "";
	let step = 3;
	let stripe;
  let cardElement;
  let cardElementContainer = false;
  let cardElementUnmounted = false;
  let cardError = false;
  let cardErrorLabel = "Carta non valida. Scegli un'altra carta oppure aggiorna i dati e riprova.";
  let cardValid = false;
  let clientSecret;
  let editPaymentMethod = true;
  let msgError = "";
  let nameCardHolder = "";
  let nameInvalid = false;
  let payMethodBrand = "";
  let payMethodBrandImage = "/assets/PaymentMethods/visa.svg";
  let payMethodExpireMonth = "";
  let payMethodExpireYear = "";
  let payMethodUserName = "";
  let subscribeNowButtonLabel = $_("abbonati");
  let setupIntentError = false;
  let setupIntentErrorMessage = "";
  let setupIntentSuccess = false;
  let setupIntentSuccessMessage = "";
	
	$: if (nameCardHolder) {
    nameInvalid = nameCardHolder.length < 3;
  }

  $: switch ($subscriptionInfo.name) {
    case 'revoo' :
      switch (state) {
        case 'trialing' :
          /**
           * Se il PT non ha ancora scelto un piano per la fine del periodo di prova ($subscriptionInfo.default_trial_product === true)
           * allora viene mostrato il pulsante "abbonati", altrimenti viene mostrato il pulsante "cambia piano"
          */
          subscribeNowButtonLabel = $subscriptionInfo.default_trial_product === true ? $_("abbonati") : $_("cambia_piano");
          break;
        case 'trial_ended' :
          subscribeNowButtonLabel = $_("abbonati");
          break;
        case 'downgrade_plan' :
          subscribeNowButtonLabel = $_("cambia_piano");
          break;
        default :
          subscribeNowButtonLabel = $_("cambia_piano_ora");
      }
      break;
    case 'main' :
      switch (state) {
        case 'trialing' :
        case 'trial_ended' :
          subscribeNowButtonLabel = $_("abbonati");
          break;
        case 'pricing_change' :
        case 'pricing_change_already_scheduled' :
          subscribeNowButtonLabel = $_("conferma");
          break;
        default :
          subscribeNowButtonLabel = $_("cambia_piano_ora");
      }
      break;
    default :
      subscribeNowButtonLabel = $_("abbonati");
  }

  $: if (setiSetupIntentId && setiSetupIntentClientSecret) {
    checkSetupIntent(setiSetupIntentId, setiSetupIntentClientSecret);
  };

  $: formValid = nameCardHolder && !nameInvalid && cardValid;

  const checkSetupIntent = async (setup_intent_id, client_secret) => {
    try {
      const res = await verifySetupIntent(setup_intent_id, client_secret);
      if (res.success === true) {
        setupIntentError = false;
        setupIntentSuccess = true;
        setupIntentSuccessMessage = res.message;
      } 
      else {
        setupIntentSuccess = false;
        setupIntentError = true;
        setupIntentErrorMessage = res.message;
        // Gestire tutte le casistiche eccetto il succeeded (già verificato sopra). Al momento configurato un generico failure
        // switch (res.status) {
        //   case 'canceled' : break;
        //   case 'processing' : break;
        //   case 'requires_action': break;
        //   case 'requires_confirmation' : break;
        //   case 'requires_payment_method' : break;
        // }
      }
      console.log(res);
    } catch (err) {
      console.log('Error verifying SetupIntent', err);
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

	const defaultMethods = async () => {
    loadingDefaultMethod = true;
    try {
      const card = await getPaymentsMethodsDefault();
      if (card && card.last4) {
        payMethodBrand       = card.brand;
        payMethodLast4       = card.last4;
        payMethodExpireYear  = card.exp_year;
        payMethodExpireMonth = card.exp_month;
        payMethodUserName    = card.name;
        payMethodBrandImage  = getCreditCardBrandImage(payMethodBrand);
        editPaymentMethod    = false;
      } else {
        await startEditPaymentMethod();
			}
    } catch (err) {
      console.log("defaultMethods error", err);
      await startEditPaymentMethod();
    }
    loadingDefaultMethod = false;
  };

  const savePaymentMethod = async () => {
    cardError = false;
    try {
      loading = true;
      const { paymentMethod, error } = await stripe.createPaymentMethod(
        "card",
        cardElement,
        {
          billing_details: { name: nameCardHolder },
        }
      );

      if (error) {
        console.log("error", error);
        displayError();
        cardInserted = false;
      } else {
        const resPaymentMethod = await addNewPaymentMethod(paymentMethod, );
        if (resPaymentMethod.success) {
          await getToUpdateHubspotPt();
          await defaultMethods();
          editPaymentMethod = false;
          cardInserted = true;
        }
        else {
          console.log("resPaymentMethod", resPaymentMethod);
          cardError = true;
          cardInserted = false;
        }
        loading = false;
      }
    } catch (err) {
      console.log("savePaymentMethod err", err);
      displayError();
      loading = false;
      cardInserted = false;
      cardError = true;
    }
  };

  const initStripe = async () => {
    if (!cardElementUnmounted) {
      if (!stripe) stripe = await loadStripe(env.STRIPE_KEY);
      const elements = stripe.elements();
      cardElement = elements.create("card", {
        style: {
          base: {
            iconColor: "rgba(0, 0, 0, 0.42)",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 400,
            fontFamily: "'Avenir Next', Helvetica, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#fce883",
            },
            "::placeholder": {
              color: "rgba(0, 0, 0, 0.42)",
            },
          },
          invalid: {
            iconColor: "#cd293d",
            color: "#cd293d",
          },
        },
        hidePostalCode: true,
      });
    } 

    if (document.getElementById("card-element")) {
      cardElement.mount("#card-element");
      cardElement.on("change", (e) => {
        cardValid = e.complete ? true : false;
      });
    }
  };

  const getSetupIntentUser = async () => {
    try {
      const { client_secret } = await getSetupIntent();
      clientSecret = client_secret;
    } catch (error) {
      console.log("getSetupItent err", error);
      displayError();
    }
  };

  const addNewPaymentMethod = async (body) => {
    try {
      // Reset messages
      setupIntentSuccess = false;
      setupIntentError = false;
      const res = await saveNewPaymentMethod(
        body, 
        clientSecret,
        selectedPriceId,
        selectedPriceInterval,
        selectedPriceNextInvoiceDate,
        selectedPriceValue,
        selectedProductId,
        selectedProductName
      );
      if (res.success === true && state === 'trialing') {
        if (res.status === 'requires_action' && res.action_type === 'redirect_to_url') {
          window.location.href = res.action;
        } else if (res.status === 'requires_action' && res.action_type === 'use_stripe_sdk') {
          window.location.href = res.action;
        } else {
          // Se sembra che ci sia un'autorizzazione immediata, verificare ancora una volta il setupIntent
          checkSetupIntent(clientSecret.split('_secret')[0], clientSecret);
        }
        // Verificare se ci sono altri casi di action configurabili 
      }
      return res;
    } catch (error) {
      console.log("saveNewPaymentMethod err", error);
      displayError();
      return false;
    }
  };

  const startEditPaymentMethod = async () => {
    await initStripe();
    await getSetupIntentUser();
    editPaymentMethod = true;
  }

  const stopEditPaymentMethod = async () => {
    cardElement.unmount();
    cardElementUnmounted = true;
    editPaymentMethod = false;
  }

  const handleExitButtonClicked = async () => {
    loading = true;
    dispatch('closeDialog');
  }
  
  const handleSubscribeButtonClicked = async () => {
    loading = true;
    dispatch('updateSubscription');
  }

  onMount(async () => {
    await defaultMethods();
  });
</script>

<div class="d-flex flex-column flex-md-row justify-content-between">
  <div class="mb-3 pl-3">
    <!-- PAYMENT METHOD -->
    <header>
      <div class="pb-3 bold">
        {$_('metodo_di_pagamento')}
      </div>
    </header>
    <main class="pb-2">
      <Card class="customCard">
        <Content class="customCardContent">
          <!-- {#if !loadingDefaultMethod} -->
            <div class="row justify-content-center">
              <div class="payment-data">
                <div class="payment-method" style="display: {editPaymentMethod ? `block` : `none`};">
                  <Textfield
                    class="w100"
                    id="card-holder-name"
                    label={`${$_('nome_titolare_tessera')} *`}
                    bind:value={nameCardHolder}
                    bind:invalid={nameInvalid} />
                  {#if nameInvalid}
                    <FormMessage>
                      {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
                    </FormMessage>
                  {/if}
                  <div bind:this={cardElementContainer}>
                    <div class="input--stripe" id="card-element" />
                  </div>
                </div>
                <div class="card-layout" style="display: {!editPaymentMethod ? `flex` : `none`};">
                  <div class="card-line">
                    <div class="card-brand">
                      <img src="{payMethodBrandImage}" alt="" />
                    </div>
                    <div class="card-number">
                      <span>**** **** **** {payMethodLast4}</span>
                    </div>
                  </div>
                  <div class="card-line">
                    <div class="card-holder">
                      <span class="card-holder--label">{$_('nome')}</span>
                      <span class="card-holder--name">{payMethodUserName}</span>
                    </div>
                    <div class="card-expiration">
                      <span class="card-expiration--label">{$_('scadenza')}</span>
                      <span class="card-expiration--date">{payMethodExpireMonth}/{payMethodExpireYear}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <!-- {:else} -->
            <!-- <Loading /> -->
          <!-- {/if} -->
        </Content>
      </Card>
    </main>
    <footer>
      <div class="row">
        <div class="col-12">
          <Actions class="d-flex justify-content-end">
            {#if editPaymentMethod}
              {#if payMethodLast4}
                <Button class="btn" on:click={stopEditPaymentMethod}>
                  <Label>{$_('annulla')}</Label>
                </Button>
              {/if}
              <Button
                class="btn"
                variant="unelevated"
                data-secret={clientSecret}
                on:click={savePaymentMethod}
                type="button"
                disabled={!formValid || loading}
              >
                <Label>
                  <span>{!payMethodLast4 ? $_('aggiungi_carta') : $_('salva_carta')}</span>
                  {#if loading}
                    <LoadingInline />
                  {/if}            
                </Label>
              </Button>
            {:else}
              <Button
                class="btn"
                variant="unelevated"
                data-secret={clientSecret}
                on:click={startEditPaymentMethod}
                type="button"
                disabled={loading}
              >
                <Label>
                  <span>{$_('modifica_carta')}</span>
                </Label>
              </Button>
            {/if}
          </Actions>
        </div>
      </div>
    </footer>
  </div>
  <div class="mb-3 pr-3 d-flex flex-column justify-content-end">
    {#if cardError}
      <div class="px-2" style="max-width: 400px;">
        <div class="alert alert-danger text-right" role="alert">
          {cardErrorLabel}
        </div>
      </div>
    {/if}
    {#if paymentError}
      <div class="px-2" style="max-width: 400px;">
        <div class="alert alert-danger text-right" role="alert">
          {paymentErrorLabel}
        </div>
      </div>
    {/if}
    {#if setupIntentError}
      <div class="px-2" style="max-width: 400px;">
        <div class="alert alert-warning text-right" role="alert">
          {setupIntentErrorMessage}
        </div>
      </div>
    {/if}
    {#if setupIntentSuccess}
      <div class="px-2" style="max-width: 400px;">
        <div class="alert alert-success text-right" role="alert">
          {setupIntentSuccessMessage}<br>Clicca su "{subscribeNowButtonLabel}" per<br>completare l'operazione.
        </div>
      </div>
    {/if}
    <Actions class="d-flex justify-content-end">
      <Button 
        class="btn" 
        on:click={() => dispatch('previousStep')}
      >
        <Label>{$_('indietro')}</Label>
      </Button>
      <Button
        class="btn btn--subscribe"
        variant="unelevated"
        data-secret={clientSecret}
        on:click={handleSubscribeButtonClicked}
        type="button"
        disabled={loading || editPaymentMethod || setupIntentError}
      >
        <Label>
          <span>{subscribeNowButtonLabel}</span>
          {#if loading}
            <LoadingInline />
          {/if}
        </Label>
      </Button>
    </Actions>
  </div>
</div>

<style>
  .card-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 175px;
  }

  .card-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 7px;
  }

  .card-line span {
    display: block;
  }

  .card-number span {
    font-size: 1.7em;
    font-weight: 700;
  }

  .card-brand {
    margin-left: -15px;
  }

  .card-brand img {
    width: 100px;
    height: auto;
    outline: solid 4px #fff;
    outline-offset: -4px;
  }

  .card-holder--name, .card-expiration--date {
    font-size: 1.3em;
    font-weight: 700;
  }

  .card-expiration span {
    text-align: right;
  }

  * :global(.customCard) {
    border-radius: 20px;
    border: none;
    margin: 0 auto;
    width: 400px;
    min-height: 207px;
  }

  .payment-method {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 12px 40px 30px;
  }
  .input--stripe {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 20px 0 18px;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 0.5rem;
  }

  .payment-data {
    width: 100%;
  }

  :global(.btn.btn--subscribe) {
    width: 230px;
    height: 70px;
  }

  :global(.btn.btn--subscribe) span {
    font-size: 1.4em;
  }

  * :global(.justifyContentBetween) {
    display: flex;
    justify-content: space-between;
  }

  * :global(.customCardContent) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
