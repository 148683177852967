import { GET, POST } from "../lib/http";
import { jwt, user } from "../lib/store";
import { getTags } from "./exercisesServices";
import { hsLogin } from "./hubspot";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const login = async (body) => {
  try {
    const res = await POST("/v2/login", {
      body,
      headers: { ...commonHeaders },
    });
    jwt.set(res.idToken);
    user.set(res);
    hsLogin(res.email);
    try {
      const mailId = res.id.toString();
      smartlook('record', { api:true, emails:true, forms:true, ips:true, numbers:true })

      smartlook("identify", mailId, {
        email: res.email,
        name: res.first_name + ' ' + res.last_name
      });
    } catch (err) {
      if (window.location.host == "houstonstag.revoo-app.com" || 
          window.location.host == "train234.revoo-app.com" ||
          window.location.host == "trainmobile.revoo-app.com") {
        console.log("smartlook err", err);
      }
    }

    // One Signal Web Push
    try {
      const OneSignalId = res.one_signal_id;
      if (env.API_URL == 'https://devbe.revoo-app.com/api') {
        OneSignal.push(function () {
          OneSignal.setExternalUserId(OneSignalId);
        });
      }
    } catch (err) {
      console.log("OneSignalId err", err);
    }

    // se ricordami è flaggato??
    getTags();
    return res;
  } catch (err) {
    throw err;
  }
};

export const register = async (body) => {
  const res = await POST("/v2/register", {
    body,
    headers: { ...commonHeaders },
  });
  jwt.set(res.idToken);
  user.set(res.user);
  hsLogin(res.user.email);
  try {
    const mailId = res.user.id.toString();
    smartlook('record', { api:true, emails:true, forms:true, ips:true, numbers:true })
    smartlook("identify", mailId, {
      email: res.email,
      name: res.first_name + ' ' + res.last_name
    });
  } catch (err) {
    if (window.location.host == "houstonstag.revoo-app.com" || 
        window.location.host == "train234.revoo-app.com" ||
        window.location.host == "trainmobile.revoo-app.com") {
      console.log("smartlook err", err);
    }
  }
  getTags();
  return res;
};

export const recoverPassword = async (body) => {
  try {
    const res = await POST("/password_recover", {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const completeRegistration = async (body) => {
  const res = await POST(
    "/v2/complete_registration",
    {
      body,
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  user.set(res);
  return res;
};

export const existEmail = async (body) => {
  try {
    const res = await POST("/v2/emails", {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const existReferral = async (body) => {
  try {
    const res = await POST("/v2/check_referral", {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const restartTrial = async (body = {}) => {
  try {
    const res = await POST("/v2/restart_trial", {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const restorePassword = async (body) => {
  try {
    const res = await POST("/password_restore", {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const smartlookRefresh = async (id, email, first_name, last_name) => {
  try {
    const mailId = id.toString();
    smartlook('record', { api:true, emails:true, forms:true, ips:true, numbers:true })
    smartlook("identify", mailId, {
      email: email,
      name: first_name + ' ' + last_name
    });
    console.log('Smartlook Refresh');
  } catch (err) {
    if (window.location.host == "houstonstag.revoo-app.com" || 
        window.location.host == "train234.revoo-app.com" ||
        window.location.host == "trainmobile.revoo-app.com") {
      console.log("smartlook err", err);
    }
  }
};

export const addScriptToHead = (scriptContent) => {
  var head = document.getElementsByTagName('head')[0];
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = scriptContent;
  head.appendChild(script);
};