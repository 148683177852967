<script>
    import { _ } from "svelte-i18n";
    import Card, { Content } from "@smui/card";
    import Button, { Label } from "@smui/button";
    import SectionTitle from "../SectionTitle.svelte";

    export let nextCharge;
    export let chargeDate;
</script>

<style>
    .importantInfoBold {
        font-weight: bold;
        font-size: 1.7em;
    }
</style>

<Card>
    <Content class="customCardContent h100">
        <SectionTitle text={$_('stima_prossimo_addebito')} />
        <div class="row mb-2">
            <div class="col-12">
                <span
                    class="importantInfoBold">{`${nextCharge} ${$_('euro')} `}</span>
                <span class="aBitSmaller">{`(${$_('iva_inclusa')})`}</span>
            </div>
            <span
                class="col-12">{`${$_('prossimo_addebito')}: ${chargeDate}`}</span>
        </div>
        <div style="text-align:end" class="mt-2">
            <Button variant="unelevated" on:click>
                <Label>{$_('vedi_dettagli')}</Label>
            </Button>
        </div>
    </Content>
</Card>
