<script>
  import { createEventDispatcher } from "svelte";
  import Switch from "@smui/switch";
  import { updateIsDraftTrainingCard } from "../../../../services/trainingCardServices";

  export let value;
  export let trainingCardId;

  const dispatch = createEventDispatcher();

  const changeDraftStatus = async () => {
    try {
      const res = await updateIsDraftTrainingCard(trainingCardId, !value);
      dispatch("updateDraftTrainingCard", {id: trainingCardId, is_draft: !value});
    } catch (err) {
      console.log("changeDraftStatus err", err);
    }
  };
</script>

<Switch 
  bind:checked={value} 
  on:change={changeDraftStatus} />
