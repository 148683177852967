<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher, onMount } from "svelte";
	import { getFeature, getFeatureValueFromProduct, getIntegerSection, getDecimalSection } from "../../../../services/subscriptionServices";
	import { Icon } from "@smui/common";
	import { subscription, user } from "../../../../lib/store";
	import Button, { Label } from "@smui/button";
	import Card, { Content, Actions } from "@smui/card";
	import SvelteTooltip from "svelte-tooltip";
	import Switch from "@smui/switch";

	export let availableProducts = [];
	export let state = "trial_ended";
	export let trigger = "";
	export let viewportWidth;

	const dispatch = createEventDispatcher();

	let showPricePerYear = false;
	let slidesPerView = 3;
	let spaceBetween = 62;
	let step = 1;

	// $: console.log('slidesPerView', slidesPerView);
	// $: console.log('spaceBetween', spaceBetween);
	// $: console.log('viewportWidth', viewportWidth);

	$: if (viewportWidth > 1100) {
		slidesPerView = 3;
		// spaceBetween = 62;
	} else if (viewportWidth > 960) {
		// spaceBetween = 32;
		slidesPerView = 2;
	} else {
		slidesPerView = 1;
		// spaceBetween = 32;
	}

  	const onProgress = (e) => {
	    const [swiper, progress] = e.detail;
		// console.log(progress);
  	};

  	const onSlideChange = (e) => {
    	// console.log('slide changed')
  	}

	const getFeatures = (product = {}) => {
		let features = [];

		// Max Customers Quantity
		features.push({name: 'max_customers', label: getFeature(product, 'max_customers')});

		// Groups
		if (getFeature(product, 'groups')) {
			features.push({name: 'groups', label: $_('gruppi')});
		}

		// Automations
		if (getFeature(product, 'automations')) {
			features.push({name: 'automations', label: $_('automazioni')});
		}

		// Chat
		if (getFeature(product, 'chat')) {
			features.push({name: 'chat', label: $_('chat')});
		}

		// Max Vods Quantity
		if (getFeature(product, 'max_vods')) {
			features.push({name: 'max_vods', label: getFeature(product, 'max_vods')});
		}

		// REVOO Pay
		if (getFeature(product, 'revoo_pay')) {
			features.push({name: 'revoo_pay', label: 'REVOO Pay'});
		}

		// Custom App
		if (getFeature(product, 'custom_app')) {
			features.push({name: 'custom_app', label: 'App Personalizzata'});
		}

		return features;
	};

	const triggerCheck = (product) => {
		return (
			$subscription.name === 'revoo' 
			&&
			product.rank > $subscription.product_rank
			&& 
			!(
				(
					!showPricePerYear && 
					$subscription.price_id == product.price_per_month_id
				) 
				|| 
				(
					showPricePerYear && 
					$subscription.price_id == product.price_per_year_id
				) 
			)
			&&
			(
				(
					trigger === 'vods' 
					&&
					getFeatureValueFromProduct(product, 'max_vods') == 5
					&&
					$subscription.current_vods_count === 5
				)
				||
				(
					trigger === 'revoo_pay' 
					&&
					getFeatureValueFromProduct(product, 'revoo_pay') === false
				)
				||
				(
					trigger === 'custom_app' 
					&&
					getFeatureValueFromProduct(product, 'custom_app') === false
				)
			)
		);
	}

	$: if (trigger === 'vods') {
		if ($subscription.current_vods_count === 0) {
			trigger = 'no_vods';
		}
	}

	onMount(() => {
		showPricePerYear = $subscription.price_interval == 'year' ? true : false;
	})

</script>

<header class="pb-0" style="padding-left: 12px; padding-right: 12px;">
	{#if state === 'trialing'} 
		<span>{@html $_('popup_trial_ciao', {values: { firstName: $user.first_name },})}</span>
		<span>{@html $_('popup_trial_in_corso_scegli_il_piano', {values: { endsAt: $subscription.next_invoice_date },})}</span>
	{:else if state === 'trial_ended'} 
		<span>{@html $_('popup_trial_ciao', {values: { firstName: $user.first_name },})}</span>
		<span>{@html $_('popup_trial_per_attivare')}</span>
	{:else if state === 'upgrade_plan'}
		{#if trigger === 'customers'} 		<span>{@html $_('popup_cambio_abbonamento_sottotitolo_trigger_customers')}</span>
		{:else if trigger == 'no_vods'} 	<span>{@html $_('popup_cambio_abbonamento_sottotitolo_trigger_no_vods')}</span>
		{:else if trigger == 'vods'} 		<span>{@html $_('popup_cambio_abbonamento_sottotitolo_trigger_vods')}</span>
		{:else if trigger == 'revoo_pay'} 	<span>{@html $_('popup_cambio_abbonamento_sottotitolo_trigger_revoo_pay')}</span>
		{:else if trigger == 'custom_app'}  <span>{@html $_('popup_cambio_abbonamento_sottotitolo_trigger_custom_app')}</span>
		{:else} 							<span>{@html $_('popup_cambio_abbonamento_sottotitolo')}</span>
		{/if}
	{:else if state === 'pricing_change'}
		<span>{@html $_('popup_da_abbonamento_vecchio_a_nuovo_sottotitolo')}</span>
	{:else if state === 'pricing_change_already_scheduled'}
		<span>{@html $_('popup_da_abbonamento_vecchio_a_nuovo_sottotitolo_update', { values: { endsAt: $subscription.next_invoice_date }})}</span>
	{/if}
</header>
<swiper-container 
	keyboard={true}
	mousewheel={true}
	navigation={viewportWidth > 768 ? true : false}
	free-mode={viewportWidth < 768 ? true : false}
	grab-cursor={true}
	breakpoints={{
		1100: {
			slidesPerView: 3,
			spaceBetween: 62,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 62,
		},
		100: {
			slidesPerView: 1,
			spaceBetween: 62,
		},
	}}
  on:progress={onProgress}
  on:slidechange={onSlideChange}
>
	{#each Object.values(availableProducts) as product, index}
  		<swiper-slide class="product">
			<Card class="product-card {product.not_available ? `product-card__disabled` : ``}" variant="outlined">
				<Content class="product-content">
					<div class="product-header">
						<div class="product-name">
							<span>{product.name}</span>
						</div>
						<div class="product-price">
							<span class="integer-section">€{getIntegerSection(showPricePerYear ? product.price_per_year : product.price_per_month)}</span>
							<span class="decimal-section">,{getDecimalSection(showPricePerYear ? product.price_per_year : product.price_per_month)}</span>
						</div>
						<small>
							{showPricePerYear ? $_('all_anno') : $_('al_mese')}
						</small>
						<hr style="margin-top: 0.7rem;">
					</div>
					<div class="product-features">
						<ul>
							{#each getFeatures(product) as feature}
								{#if feature.label !== null}
									<li>
										{#if feature.name == 'custom_app' || feature.name == 'revoo_pay'}
											<Icon class="material-icons text-secondary">check_circle</Icon>
											<SvelteTooltip tip={$_('prodotto_acquistabile_separatamente')} top>
												<span>{feature.label}</span>
											</SvelteTooltip>
										{:else}
											<Icon class="material-icons">check_circle</Icon>
											<span>{feature.label}</span>
										{/if}
									</li>
								{/if}
							{/each}
						</ul>
					</div>
					<Actions class="d-flex justify-content-center">
						<Button
							class="justify-content-center"
							style={triggerCheck(product) ? `background-color: #4a4a4a;` : ``}
							variant="unelevated"
							disabled={
								product.not_available 
								|| 
								(
									$subscription.name == 'revoo'
									&&
									(
										(!showPricePerYear && ($subscription.price_id == product.price_per_month_id)) 
										|| 
								 		(showPricePerYear && ($subscription.price_id == product.price_per_year_id))
									)									
								)
							}
							on:click={() => dispatch('nextStep', {
								selectedPriceId		  : showPricePerYear ? product.price_per_year_id : product.price_per_month_id,
								selectedPriceInterval : showPricePerYear ? 'year' : 'month',
								selectedPriceValue	  : showPricePerYear ? product.price_per_year : product.price_per_month,
								selectedProductId	  : product.id,
								selectedProductName	  : product.name,
								selectedProductRank	  : product.rank,
								step
							})}
						>
						  <Label>{$_('scegli')}</Label>
						</Button>
					</Actions>
				</Content>
			</Card>
  		</swiper-slide>
	{/each}
</swiper-container>
<footer class="d-flex justify-content-center align-items-center dialog-footer">
	<span class="p-1 {!showPricePerYear ? `font-weight-bold` : ``}">{$_('mensile')}</span>
	<span class="p-2">
		<Switch bind:checked={showPricePerYear} />
	</span>
	<span class="p-1 {showPricePerYear ? `font-weight-bold` : ``}">{$_('annuale')}</span>
</footer>

<style>
	* :global(swiper-slide.product) {
		width: 235px !important;
		max-width: 235px;
	}

	* :global(.product-card) {
		/* margin: 10px 5px; */
		padding: 7px;
		border: none;
		border-radius: 15px;
	}

	* :global(.product-card.product-card__disabled) {
		background-color: rgb(229, 229, 229);
	}

	* :global(.product-content) {
		padding-bottom: 8px;
    	padding-top: 8px;
	    display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	}

	* :global(.product-header) {
		text-align: center;
	}

	* :global(.product-header *) {
		text-align: center;
	}

	* :global(.product-name) {
		padding-bottom: 20px;
		font-size: 1em;
		font-weight: bold;
		text-transform: uppercase;
	}

	* :global(.product-name::after) {
		content: "";
		display: block;
		width: 30px;
		height: 3px;
		margin: 0 auto;
		background-color: var(--primary);
	}

	* :global(.product-card__disabled .product-name::after) {
		background-color: grey;
	}

	* :global(.product-price .integer-section) {
		margin-right: -5px;
		font-size: 2em;
		font-weight: 800;
	}

	* :global(.product-price .decimal-section) {
		font-size: 0.8em;
	}

	* :global(.product-features) {
		min-height: 180px;
	}
	
	* :global(.product-features ul) {
		list-style: none;
		padding: 0;
	}

	* :global(.product-features ul li) {
		display: flex;
		align-items: center;
	}

	* :global(.product-features ul li span) {
		/* padding-left: 7px; */
	}

	* :global(.product-features .material-icons) {
		font-size: 1em;
		margin-right: 7px;
		color: var(--primary);
	}

	* :global(.product-card__disabled .product-features .material-icons) {
		color: grey;
	}

	* :global(.dialog-footer span) {
		padding: 7px;
	}

	* :global(.primary) {
    	color: var(--primary) !important;
  	}
	
	/* * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
	    border-color: var(--primary);
  	}

  	* :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    	border-color: var(--primary);
  	} */
</style>