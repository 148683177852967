<script>
	import { _ } from "../../../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { deleteProduct, sortList, updateProductsRank, sendProductNotification } from "../../../../services/productServices";
  import { dndzone } from 'svelte-dnd-action';
  import { navigate } from 'svelte-routing';
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import ListHeader from "./ListHeader.svelte";
  import ListItem from "./ListItem.svelte";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import NoItemsToShow from "../../../ReusableLayouts/NoItemsToShow.svelte";

	export let list = [];
  export let loading = true;
  export let stripeAccount;

  const dispatch = createEventDispatcher();

  let biggerText = $_('crea_il_tuo_primo_prodotto'); 
  let biggerTextConnect = 'Connetti il tuo account REVOO a Stripe';
  let buttonText = $_('nuovo_prodotto');
  let buttonTextConnect = 'Connetti';
  let confirmationDeleteDialog;
  let deletingProduct = false;
  let firstSort = false;
  let icon = 'add';
  let iconConnect = 'account_balance_wallet';
  let isMobile = false;
  let items = [];
  let pathToNavigateTo = 'product';
  let productIdToDelete = null;
  let shortExpiringDate = false;
  let sortedList = null;
  let upperText = $_('nessun_prodotto');
  let upperTextConnect = 'Nessun account';

  // First sort
  $: {
    if (list.length > 0 && firstSort === false) {
      list = sortList(list, 'rank', true);
      firstSort = true;
    }
  }

  const handleRedirectTo = (e) => {
    const productId = e.detail.productId;
    let tab = "info";
    
    navigate(`/product?tab=` + tab + `&productId=` + productId, {
      replace: true,
      state: { productId },
    });
  }

  const handleCloneProduct = async (e) => {
    //
  };

  const handleDeleteProduct = async (e) => {
    productIdToDelete = e.detail.productId;
    confirmationDeleteDialog.open();
  };

  const handleRankSort = async (e) => {
    list = e.detail.items;
  }

  const handleRankSortFinalized = async (e) => {
    list = e.detail.items;
    sortedList = [];
    list.forEach(a => {
      sortedList.push(a.id);
    });
    await updateProductsRank(sortedList);
  };

  const handleEditProduct = async (e) => {
    handleRedirectTo(e);
  };

  const confirmDeleteProduct = async () => {
    confirmationDeleteDialog.close();
    if (productIdToDelete !== null && !isNaN(productIdToDelete)) {
      deletingProduct = true;
      const res = await deleteProduct(productIdToDelete);
      if (res.deleted === true) {
        dispatch('updateList');
      }
      deletingProduct = false;
    }
    productIdToDelete = null;
  };

  // Check window size
  const windowSizeUpdated = () => {
		shortExpiringDate = document.documentElement.clientWidth >= 992 && document.documentElement.clientWidth < 1200 || document.documentElement.clientWidth < 576;
    isMobile = document.documentElement.clientWidth < 576;
	}

	onMount(() => {
		window.addEventListener("resize", windowSizeUpdated);
		windowSizeUpdated();
	});	
</script>

<Dialog bind:this={confirmationDeleteDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_l_esercizio')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => confirmationDeleteDialog.close()}
    on:onConfirmation={confirmDeleteProduct} />
</Dialog>

{#if loading}
  <div class="d-flex position-relative pt-4">
    <Loading />
  </div>
{:else}
  {#if stripeAccount}
    {#if list.length > 0}
      <ListHeader />
      <section use:dndzone={{items: list}} on:consider={handleRankSort} on:finalize={handleRankSortFinalized}>
        {#each list as item(item.id)}
          <ListItem 
            i={item.id}
            {item}
            {isMobile} 
            on:cloneProduct={handleCloneProduct}
            on:deleteProduct={handleDeleteProduct}
            on:editProduct={handleEditProduct}
            on:redirectTo={handleRedirectTo}
          />
        {/each}
      </section>
    {:else}
      <NoItemsToShow 
        {biggerText}
        {buttonText}
        {icon}
        {pathToNavigateTo} 
        {upperText}
      />
    {/if}
  {:else}
    <NoItemsToShow 
      biggerText={biggerTextConnect}
      buttonText={buttonTextConnect}
      icon={iconConnect}
      pathToNavigateTo={'revoo_pay_onboarding'}
      upperText={upperTextConnect}
      startConnectOnboarding={true}
    />
  {/if}
{/if}

<style>
	* :global(.range-select .mdc-select__native-control) {
		text-align: right;
    padding-right: 24px;
    padding-top: 6px;
    border: 0;
	}

  * :global(.mdc-card) {
    text-align: left;
  }  
</style>