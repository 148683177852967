<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { Icon } from "@smui/common";
  import { onMount } from "svelte";
  import BannerHelp from "../ReusableLayouts/BannerHelp.svelte";
  import Button from "@smui/button";
  import Card, { Content } from "@smui/card";
  import Checkbox from "@smui/checkbox";
  import Dialog from "@smui/dialog";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import List, { Item, Meta, Label } from "@smui/list";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import NoItemsToShow from "../ReusableLayouts/NoItemsToShow.svelte";
  import Pagination from "../ReusableLayouts/Pagination.svelte";
  import TeamListItem from "./TeamListItem.svelte";

  const dispatch = createEventDispatcher();
  
  export let loading = false;
  export let filtratedCustomers = [];
  export let selectedStatus = [];
  export let orderBy = "last_name";
  export let orderDesc = false;
  export let searchName;
  export let totalPages = 1;
  export let page = 1;

  let activePage = 0;
  $: activePage = page - 1;

  let filterDialog;
  let allSelected = false;
  let showButton = false;
  let pageReady = false;

  let filterValue;
  $: filterValue = $_("filtra") + ` (${selectedStatus.length})`;
  let filterOptions = [
    { key: "active", value: $_("molto_attivo") },
    { key: "not_very_active", value: $_("attivo") },
    { key: "inactive", value: $_("poco_attivo") },
    { key: "no_training", value: $_("mai_allenato") },
    { key: "disabled", value: $_("disattivato") },
  ];

  const switchPage = async (event) => {
    //idex from 0
    page = event.detail.page + 1;
    dispatch("updateFiltratedCustomers", {});
  };

  const setCheckboxes = (value) => {
    filtratedCustomers = filtratedCustomers.map((ele) => {
      ele.selected = value;
      return ele;
    });
  };

  $: {
    if (allSelected) {
      setCheckboxes(true);
    } else setCheckboxes(false);
  }

  const sendMultipleEmails = () => {
    const destinations = [];
    filtratedCustomers.map((ele) => {
      ele.selected ? destinations.push(ele.email) : null;
    });
    const to = destinations.toString().replace(",", ";");
    window.location.href = `mailto:${to}`;
  };

  const orderByField = async (field) => {
    orderDesc = orderBy == field ? !orderDesc : true;
    orderBy = field;
    page = 1;
    dispatch("updateFiltratedCustomers", {});
  };

  const checkboxChanged = () => {
    if (pageReady)
      dispatch("updateFiltratedCustomers", { goToInitialPage: true });
  };

  onMount(async () => {
    pageReady = true;
  });

  const handleFiltratedCustomerChecked = (e) => {
    if (e.detail.checked && !showButton) {
      showButton = true;
    } else if (
      !Object.values(filtratedCustomers).some((el) => el.selected === true)
    ) {
      showButton = false;
    }
  };
</script>

<style>
  .text--no-elements {
    font-size: 1.25rem;
    opacity: 0.5;
  }
  .opacity--eighty {
    opacity: 0.8;
  }
  * :global(.demo-list) {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .alignCenter {
    align-items: center;
  }
  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 0.875rem;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .inerithPad {
    padding: inherit;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
    border-radius: 0px;
  }
  .filterContainer {
    display: flex;
    justify-content: space-between;
  }
  .allCheckbox {
    justify-content: center;
    display: flex;
  }
  * :global(.filter .mdc-list-item__text) {
    margin: 0;
  }
  * :global(.filter .material-icons) {
    margin: 0;
  }
  * :global(.boxShadowBottom) {
    -webkit-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  }
  @media (min-width: 280px) and (max-width: 767px) {
    .desktop {
      display: none;
    }
    .subheader__options {
      position: -webkit-sticky;
      position: sticky;
      top: 9.95rem;
      z-index: 2;
    }
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }
  }
  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }
</style>

<div class="container my-3">
  <BannerHelp state='team_list'/>
</div>

<div class="container fluid desktop">
  <div class="row no-gutters filterContainer py-3">
    <Button
      color="secondary"
      variant="unelevated"
      style={`visibility: ${allSelected || showButton ? 'visible' : 'hidden'}`}
      on:click={() => {
        sendMultipleEmails();
      }}>
      <Label style="text-transform: none;margin-bottom: 0px;">
        {$_('invia_email')}
      </Label>
    </Button>
    <div class="dropdown">
      <Button
        color="secondary"
        variant="outlined"
        class="customButtom filter"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <Label>
          {filterValue}
          <Icon class="material-icons">keyboard_arrow_down</Icon>
        </Label>
      </Button>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
        on:click={(e) => e.stopPropagation()}>
        <List checklist class="list">
          {#each filterOptions as option}
            <Item class="w100">
              <Meta class="ml-0">
                <Checkbox
                  bind:group={selectedStatus}
                  value={option.key}
                  on:change={checkboxChanged} />
              </Meta>
              <Label class="mb-0">{option.value}</Label>
            </Item>
          {/each}
        </List>
      </div>
    </div>
  </div>
  <Card class="invisibleCard">
    <Content class="pb-0">
      <div class="row no-gutters flexy justify-content-between">
        <div class="col-2 col-md-1">
          <FormField>
            <Checkbox bind:checked={allSelected} />
          </FormField>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="row no-gutters alignCenter">
            <div
              class="demiBold textTitle"
              style="cursor:pointer"
              on:click={(event) => orderByField('last_name')}>
              {$_('cliente')}
            </div>
            {#if orderBy == 'last_name'}
              <span
                on:click={() => {
                  orderByField('last_name');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if}
          </div>
        </div>
        <div class="col-md-2">
          <div class="row no-gutters inerithPad pr-lg-3">
            <div class="demiBold textTitle">
              {$_('gruppi')}
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="row no-gutters inerithPad">
            <div
              class="demiBold textTitle"
              style="cursor:pointer"
              on:click={(event) => orderByField('status')}>
              {$_('status')}
            </div>
            {#if orderBy == 'status'}
              <span
                on:click={() => {
                  orderByField('status');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if}
          </div>
        </div>
        <div class="col-md-2">
          <div class="row no-gutters inerithPad">
            <div
              class="demiBold textTitle"
              style="cursor:pointer"
              on:click={(event) => orderByField('last_workout')}>
              {$_('ultimo_allenamento')}
            </div>
            {#if orderBy == 'last_workout'}
              <span
                on:click={() => {
                  orderByField('last_workout');
                }}>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing"
                  style="opacity: 0.5;padding-top: 3px;">
                  {orderDesc ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
            {/if}
          </div>
        </div>
        <div class="col-md-1">
          <!-- <IconButton
              class="material-icons"
              title="Dismiss"
              on:click={() => {
                console.log('change menu');
              }}>
              menu
            </IconButton> -->
        </div>
      </div>
    </Content>
  </Card>
  <hr class="line" />
</div>
<div class="subheader__options mobile">
  <Card class="boxShadowBottom mb-3">
    <Content class="container fluid card--subheader py-0">
      <div class="row no-gutters align-items-center justify-content-between">
        <div class="allCheckbox pl-1">
          <Checkbox bind:checked={allSelected} />
        </div>
        <div style="padding-left:30px">
          <div class="row no-gutters align-self-end opacity--eighty">
            <div class="col-6 pr-0">
              <IconButton
                class="material-icons"
                on:click={() => orderByField('last_name')}>
                sort
              </IconButton>
            </div>

            <div class="col-6 pl-0 pr-4">
              <IconButton
                class="material-icons"
                on:click={() => filterDialog.open()}>
                filter_list
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </Card>
  <div class="container fluid mobile mb-3">
    <Button
      color="secondary"
      variant="unelevated"
      style={`display: ${allSelected || showButton ? 'block' : 'none'}`}
      on:click={() => {
        sendMultipleEmails();
      }}>
      <Label style="text-transform: none;margin-bottom: 0px;">
        {$_('invia_email')}
      </Label>
    </Button>
  </div>
</div>

{#if loading}
  <Loading />
{:else if filtratedCustomers.length > 0}
  <div class="container fluid">
    {#each filtratedCustomers as filtratedCustomer}
      <TeamListItem
        {filtratedCustomer}
        on:filtratedCustomerChecked={handleFiltratedCustomerChecked} />
    {/each}
  </div>
  <Pagination {totalPages} {activePage} on:page={switchPage} />
{:else if !searchName}
  <div class="flexy">
    <Card class="invisibleCard">
      <Content>
        <NoItemsToShow
          upperText={$_('nessun_cliente')}
          biggerText={$_('aggiungi_i_tuoi_atleti')}
          icon="person_add"
          buttonText={$_('nuovo_cliente')}
          pathToNavigateTo="new-customer" />
      </Content>
    </Card>
  </div>
{:else}
  <div class="container-fluid my-4 text-center">
    <span
      class="text--no-elements demiBold">{$_('nessun_cliente_trovato')}</span>
  </div>
{/if}

<div>
  <Dialog
    bind:this={filterDialog}
    aria-labelledby="list-title"
    aria-describedby="list-content">
    <div class="row no-gutters flexy pt-3">
      <div class="col-10 demiBold d-flex justify-content-center">
        {$_('filtra')}
      </div>
      <div class="col-2">
        <IconButton
          class="material-icons"
          on:click={() => filterDialog.close()}>
          clear
        </IconButton>
      </div>
    </div>
    <Content component={List}>
      {#each filterOptions as option}
        <Item>
          <Meta class="m-0">
            <Checkbox
              bind:group={selectedStatus}
              value={option.key}
              on:change={checkboxChanged} />
          </Meta>
          <Label class="mb-0">{option.value}</Label>
        </Item>
      {/each}
    </Content>
  </Dialog>
</div>