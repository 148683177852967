<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import { _ } from "../../../services/i18n";
  import { navigate } from "svelte-routing";
  import Card, { Content, Actions, Media } from "@smui/card";
  import Button, { Label } from "@smui/button";
  import { Icon } from "@smui/common";
  import IconButton from "@smui/icon-button";
  import Textfield, { Input } from "@smui/textfield";
  import { exercises, workouts } from "../../../lib/store";
  import GroupLabels from "../../ReusableLayouts/GroupLabels.svelte";
  import {
    getTrainingCardDetails,
  } from "../../../services/trainingCardServices";

  export let msgError;
  export let cards = [];
  let panels = [];

  let levelsMap = {
    elementary: $_("elementare"),
    beginner: $_("principiante"),
    intermediate: $_("intermedio"),
    advanced: $_("avanzato"),
  };

  $: if (cards) panels = new Array(cards.length).fill(false);

  const getCombinationIcon = (comb) => {
    if (comb == "normal") return "remove";
    else if (comb == "time_circuit") return "timer";
    else return "sync";
  };

  const getDetails = async (item, i) => {
    if (!panels[i]) {
      const res = await getTrainingCardDetails(item.id);
      if (res) {
        item.workouts = res;
      }
    }
    panels[i] = !panels[i];
  };

  const importWorkout = (id) => {
    dispatch("importWorkout", { id });
  };

  const importCard = (id) => {
    dispatch("importCard", { id });
  };

</script>

<style>
  h5 {
    padding: 0;
  }
  .subtitle {
    opacity: 0.8;
    letter-spacing: 0.57px;
  }
  .header {
    background-color: #f9f9f9;
    align-items: center;
  }
  .spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hide {
    display: none;
  }
  .textOpacity {
    opacity: 0.8;
  }
  .alignStart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .exercisesWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .combinationsWrapper {
    display: flex;
    align-items: flex-start;
  }
  .labelLevel {
    font-weight: 400;
    font-size: 0.9rem;
  }
  .label--groups {
    opacity: 1;
    background-color: #05d3c8;
    border-radius: 3px;
  }
  .label--groups .label {
    opacity: 1;
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
  }
</style>

<div class="header p-4">
  <div class="container fluid">
    <div class="row no-gutters spaceBetween">
      <h5 class="bold">{$_('aggiungi_allenamento')}</h5>
      <IconButton
        class="material-icons"
        on:click={() => dispatch('closeDialog', {})}>
        close
      </IconButton>
    </div>
    <div class="subtitle">{$_('dai_tuoi_modelli_di_allenamento_standard_e_dai_gruppi')}</div>
  </div>
</div>

<div class="px-4">
  <div class="container fluid py-3">

    {#if msgError}
      <div class="alert alert-danger fade show" role="alert">{msgError}</div>
    {/if}

    {#each cards as card, i}
      <div class="row no-gutters text">
        <div class="col-12 spaceBetween">
          <div style="flex-grow: 1;">
            <div
              class="d-flex flex-column align-items-start justify-content-center">
              {#if card.is_assigned_to_group}
                <GroupLabels groups={card.groups} />
              {/if}
              <div>
                <span class="demiBold textOpacity">
                  {card.name}
                </span>
              </div>
              {#if (card.level)}
                <div>
                  <span class="labelLevel d-block">{levelsMap[card.level]}</span>
                </div>
              {/if}
            </div>
          </div>
          <div class="demiBold textOpacity align-self-end">
            <IconButton
              class="material-icons ml-3"
              on:click={() => importCard(card.id)}>
              assignment_returned
            </IconButton>
          </div>
          <IconButton
            class="material-icons"
            on:click={getDetails(card, i)}>
            {panels[i] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </IconButton>
        </div>
      </div>
      <div class={`row px-3 ${panels[i] ? '' : 'hide'}`}>
        {#if card.workouts && card.workouts.length > 0}
          {#each card.workouts as workout, i}
            <div class="col-12">
              <span class="alignStart demiBold textOpacity">
                {workout.name}
                <IconButton
                  class="material-icons ml-3"
                  on:click={() => importWorkout(workout.workout_id)}>
                  assignment_returned
                </IconButton>
              </span>
            </div>
            {#each workout.combinations as comb}
              <div
                class={`col-12 textOpacity ${comb.method == 'normal' ? 'alignStart' : 'combinationsWrapper'}`}>
                <IconButton class="material-icons">
                  {getCombinationIcon(comb.method)}
                </IconButton>
                <div
                  class="exercisesWrapper"
                  style={comb.method == 'normal' ? '' : 'padding-top: 0.9rem'}>
                  {#each comb.exercises as ex}
                    <span>{ex}</span>
                  {/each}
                </div>
              </div>
            {/each}
          {/each}
        {/if}
      </div>
      <hr class={i == cards.length - 1 ? 'hide' : ''} />
    {/each}

  </div>
</div>
