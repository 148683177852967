<script>
  import { createEventDispatcher, tick } from "svelte";
  import { _ } from "../../../services/i18n";
  import { navigate } from "svelte-routing";
  import moment from "moment";
  import Dialog from "@smui/dialog";
  import Button, { Icon, Label } from "@smui/button";
  import Textfield from "@smui/textfield";
  import AssignVodAndPlaylistDialog from "../AssignVodAndPlaylistDialog.svelte";
  import VodVideoUploader from "./Uploaders/VodVideoUploader.svelte";
  import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
  import DatePicker from "../../ReusableLayouts/DatePicker.svelte";
  import { activeAssignment, customer, group } from "../../../lib/store";
  import { 
    getVodsAndPlaylists
  } from "../../../services/vodsServices";
  
  export let vod;
  export let playlist = [];
  export let customerId = null;
  export let groupId = null;
  export let assignment;
  
  const dispatch = createEventDispatcher();
  const currentDate = moment(Date.now()).format("DD/MM/YYYY");
  const currentDateFormatted = moment(Date.now()).format("YYYY-MM-DD");
  
  let assignmentDialog;
  let assignmentDialogClicked = false;
  let assignmentLoading = false;
  let dateDialogItalian; 
  let dialogAssignmentText = '';
  let exitDialog;
  let itemToBeAssigned;
  let msgError;
  let noLengthDialog;
  let noLengthDialogOpened = false;
  let search = ""; // assignment dialog search
  let startDateFormatted = moment(assignment.start).isValid()
    ? assignment.start
    : currentDateFormatted;
  let triggerCloseVideo = false;
  let vodsAndPlaylists;
  
  $: if (groupId) {
    dialogAssignmentText = $_('assegna_al_gruppo') + ' ' + $group.name;
  } else if (customerId) {
    dialogAssignmentText = $_('assegna_a') + ' ' + $customer.first_name + ' ' + $customer.last_name;
  }

  $: dateDialogItalian = assignment.start
      ? moment(assignment.start).format("DD/MM/YYYY")
      : currentDate;

  const handleDate = (e) => {
    if (moment(e.detail.date, "YYYY-MM-DD", true).isValid() === true) {
      assignment.start = e.detail.date;
      startDateFormatted = moment(assignment.start).format("YYYY-MM-DD");  
    }
  };

  // AssignmEnt Vod and Playlist dialog
  const handleAssignButtonClicked = async (e) => {
    // vodToBeAssigned = e.detail.vod;
    assignmentDialogClicked = true;
    await tick();
    assignmentDialog.open();
    assignmentLoading = true;
    await getVodsAndPlaylistsData();
    assignmentLoading = false;
    await tick();
  }

  const getVodsAndPlaylistsData = async () => {
    try {
      vodsAndPlaylists = await getVodsAndPlaylists(search);
    } catch (error) {
      console.log("getVodsAndPlaylists error", error);
      displayError();
    }
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    await getVodsAndPlaylistsData();
  };

  const handleItemSelected = (e) => {

    itemToBeAssigned = e.detail.item;

    assignment.id = $activeAssignment.id != "" && 
                    (($activeAssignment.is_vod && itemToBeAssigned.is_vod) || 
                     ($activeAssignment.is_playlist && itemToBeAssigned.is_playlist))
        ? $activeAssignment.id
        : null;

    assignment.description        = itemToBeAssigned.description;
    assignment.is_active          = itemToBeAssigned.is_active;
    assignment.is_playlist        = itemToBeAssigned.is_playlist ? true : false;
    assignment.is_vod             = itemToBeAssigned.is_vod ? true : false;
    assignment.name               = itemToBeAssigned.name;
    assignment.playlist_id        = itemToBeAssigned.playlist_id;
    assignment.status             = itemToBeAssigned.status;
    assignment.vimeo_id           = itemToBeAssigned.vimeo_id;
    assignment.vod_id             = itemToBeAssigned.vod_id;
    assignment.vimeo_thumbnail_id = itemToBeAssigned.vimeo_thumbnail_id;
    assignment.thumbnail          = itemToBeAssigned.thumbnail;
    assignment.weeks              = assignment.weeks ?? "";
  };

  const onSubmit = () => {
    if (noLengthDialogOpened) {
      noLengthDialog.close();
    }

    if (isNaN(assignment.weeks))
      assignment.weeks = '';
  
    let clone = { ...assignment };
    clone.start = startDateFormatted;
    dispatch("submit", {
      assignment: clone,
    });
  };

  const handleGoBack = () => {
    exitDialog.close();
    if (customerId) {
      navigate("/customer?tab=vods", {
        state: { customerId },
      });
    } else if (groupId) {
      navigate("/group?tab=vods&groupId=" + groupId);
    } else {
      navigate("/vods");
    }
  };  
</script>
  
<style>
  * :global(.menuSurfaceCustom) {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    color: #fff;
    min-width: 80%;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.mdc-chip) {
    background-color: #fff;
    height: auto;
    min-height: 28px;
  }
  * :global(.mdc-chip span) {
    white-space: normal;
    word-break: break-word;
  }
  * :global(.mdc-chip__icon.mdc-chip__icon--trailing) {
    opacity: 0.2;
    font-size: 1.8em;
    width: 0.8em;
    height: 1em;
  }
  * :global(.customChip) {
    border-radius: 13.5px;
    background-color: #212121 !important;
    color: #fff !important;
    min-width: fit-content;
    text-transform: inherit;
    font-weight: normal;
    height: 2em;
    margin: auto;
    margin-left: 0.7em;
    margin-top: 0.5rem;
    height: auto;
    min-height: 28px;
  }
  * :global(.customChip span i) {
    opacity: 0.5;
    background-color: #ffffff;
    border-radius: 50%;
    color: #212;
    font-size: 1.3em;
    width: 1em;
    height: 1em;
    margin-right: 0;
  }
  * :global(.mdc-button__label) {
    text-align: left;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  .label {
    text-align: end;
    align-self: center;
    opacity: 0.8;
    font-weight: 600;
  }
  .label {
    text-align: end;
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
  }

  * :global(.icon--help) {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  * :global(.groupsLoader) {
    height: 3rem;
  }

  * :global(.groupsLoader > div) {
    flex-direction: column;
  }

  * :global(.groupsLoader .spinner) {
    position: inherit;
    height: 2rem;
    width: 2rem;
  }

  * :global(.tags-textfield) {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
  }
  
  * :global(.tags-textfield input) {
    height: 2.2rem !important;
  }

  * :global(.textUppercase) {
    text-transform: uppercase;
  }

  * :global(.close-icon-wrapper) {
    background-color: rgb(255,255,255,.99) !important;
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
  * :global(.mdc-text-field--outlined.mdc-text-field--disabled) {
      border-color: rgba(0, 0, 0, 0.87) !important;
      color: rgba(0, 0, 0, 0.87) !important;
  }

</style>
  
{#if assignmentDialogClicked}
  <Dialog class="dialog--big" bind:this={assignmentDialog}>
    <AssignVodAndPlaylistDialog
      text={dialogAssignmentText}
      bind:itemToBeAssigned
      items={vodsAndPlaylists}
      {msgError}
      loading={assignmentLoading}
      on:itemSelected={handleItemSelected}
      on:searchChange={handleSearchChanged}
      on:closeDialog={() => assignmentDialog.close()} />
  </Dialog>
{/if}

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={handleGoBack} />
</Dialog>

<Dialog bind:this={noLengthDialog}>
  <ConfirmationDialog
    text={assignment.is_vod 
            ? $_('se_non_assegni_una_durata_video')
            : $_('se_non_assegni_una_durata_playlist')}
    confirmationButtonLabel={$_('continua')}
    on:closeDialog={() => noLengthDialog.close()}
    on:onConfirmation={onSubmit} />
</Dialog>

<div class="py-3">
  <div class="row">
    <div class="col-4 label">
      {#if assignment.is_playlist}
        {$_('playlist')}
      {:else}
        {$_('video')}
      {/if}
    </div>
    {#if assignment && assignment.thumbnail}
      <div class="col-8">
        <VodVideoUploader
          isItemAssignment={true}
          bind:triggerCloseVideo
          bind:item={assignment}
          bind:thumbnail={assignment.thumbnail}
          on:changeItemSelection={handleAssignButtonClicked} />
      </div>
    {:else}
      <div class="col-8">
        <Button
          color="secondary"
          variant="unelevated"
          on:click={handleAssignButtonClicked}>
          <Label>
            <Icon
              role="button"
              class="material-icons mdc-text-field__icon
                mdc-text-field__icon--trailing">
                note_add
            </Icon>
            {$_('scegli')}
          </Label>
        </Button>
      </div>
    {/if}
    <div class="col-4 label">{$_('nome')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class="w100 my-2"
        bind:value={assignment.name}
        type="text"
        disabled />
    </div>
    <div class="col-4 label">{$_('descrizione')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class="w100 my-2"
        bind:value={assignment.description}
        type="text"
        disabled />
    </div>
    <div class="col-4 label">{$_('data_inizio')}</div>
    <div class="col-8 pt-2">
      <DatePicker
        on:date={handleDate}
        maxDate={moment().add(90, 'years').toISOString()}
        defaultDate={dateDialogItalian}
        width="100"
        dateFormat="yyyy-mm-dd" />
    </div>

    <div class="col-4 label">{$_('durata_in_settimane')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class="w50 my-2"
        bind:value={assignment.weeks}
        type="number" />
    </div>
  </div>

  <hr />

  <div class="row pushToEnd">
    <Button class="btn" on:click={() => exitDialog.open()}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-3 mr-3"
      variant="unelevated"
      on:click={() => {
        if (assignment.weeks && !isNaN(assignment.weeks)) {
          onSubmit();
        } else {
          noLengthDialogOpened = true;
          noLengthDialog.open();
        }
      }}
      disabled={!assignment.name}>
      <Label class="textUppercase">
        {#if $activeAssignment.vod_assignment_id || $activeAssignment.playlist_assignment_id} 
          {$_('salva')}
        {:else if assignment.is_vod}
          {$_('assegna_video')}
        {:else if assignment.is_playlist}
          {$_('assegna_playlist')}
        {:else}
          {$_('assegna')}
        {/if}
      </Label>
    </Button>
  </div>
</div>
  