<script>
  import { _ } from "../../services/i18n";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import ExerciseDetail from "./ExerciseDetail.svelte";
  import { refreshExerciseData } from "../../services/exercisesServices";
  import { exercisesActiveSearch, exercisesActiveDropdownTagFilter, exercisesActiveTagsFilter, exercises } from "../../lib/store";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  const triggerCloseImageDialog = false;
  const triggerCloseVideo = false;

  let exercise = {
    base: "",
    name: "",
    note: "",
    link: "",
    tagsSelected: [],
    image: "",
    video: "",
    image_thumbnail: "",
    video_thumbnail: "",
  };

  if (location && location.state && location.state.exercise) {
    const { exercise: ex } = location.state;
    exercise = {
      id: ex.id,
      base: ex.base,
      name: ex.name,
      note: ex.note || "",
      link: ex.video_youtube || "",
      tagsSelected: ex.tags,
      image: ex.image,
      image_thumbnail: ex.image_thumbnail,
      video: ex.video,
      video_emc_status: ex.video_emc_status,
      video_thumbnail: ex.video_thumbnail,
      video_thumbnail_emc_status: ex.video_thumbnail_emc_status,
    };
  } else if (location && location.state && location.state.exerciseToBeCloned) {
    const { exerciseToBeCloned: ex } = location.state;
    exercise = {
      base: ex.base,
      name: ex.name,
      note: ex.note || "",
      link: ex.video_youtube || "",
      tagsSelected: ex.tags,
      image: ex.image,
      image_thumbnail: ex.image_thumbnail,
      video: ex.video,
      video_emc_status: ex.video_emc_status,
      video_thumbnail: ex.video_thumbnail,
      video_thumbnail_emc_status: ex.video_thumbnail_emc_status,
    };
  }

  $: if (exercise && exercise.video !== null && exercise.video_emc_status === 0) {
    setTimeout(() => {
      checkConvertedMedia();
    }, 5000);
  }

  const checkConvertedMedia = async () => {
    let itemUpdated = await refreshExerciseData(exercise.id);
    if (itemUpdated) {
      if (exercise.id === itemUpdated.id) {
        exercise.video = itemUpdated.video;
        exercise.video_emc_status = itemUpdated.video_emc_status;
        exercise.video_thumbnail = itemUpdated.video_thumbnail;
        exercise.video_thumbnail_emc_status = itemUpdated.video_thumbnail_emc_status;
      };

      $exercises.map(exercise => {
        if (exercise.id === itemUpdated.id) {
          exercise.video = itemUpdated.video;
          exercise.video_emc_status = itemUpdated.video_emc_status;
          exercise.video_thumbnail = itemUpdated.video_thumbnail;
          exercise.video_thumbnail_emc_status = itemUpdated.video_thumbnail_emc_status;
        }
      });
      $exercises = $exercises;
    }
  }
</script>

<style>
  .subTitle {
    opacity: 0.8;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
  }
  .titleWrapper {
    align-items: center;
    display: flex;
    cursor: pointer;
  }
</style>

<div class="container fluid">
  <div class="row">
    <div class="col-12 my-4">
      <div
        class="mdc-typography--headline6 primary titleWrapper"
        on:click={() => {
          navigate('exercises', { 
            state: { 
              activeSearch: $exercisesActiveSearch, 
              activeTagFilter: $exercisesActiveTagsFilter,
              activeDropdownTagFilter: $exercisesActiveDropdownTagFilter, 
              activePagination: true, 
            }
          });
        }}>
        <Icon role="button" tabindex="1" class="material-icons">
          keyboard_arrow_left
        </Icon>
        <span>
          {location && location.state && location.state.exercise 
            ? location.state.exercise.name 
            : $_('nuovo_esercizio')}
        </span>
      </div>
    </div>
  </div>

  <span class="subTitle">
    <Icon role="button" tabindex="1" class="material-icons mr-2">lock</Icon>
    {$_('i_tuoi_esercizi_sono_privati')}
  </span>

  <ExerciseDetail
    {exercise}
    {triggerCloseImageDialog}
    {triggerCloseVideo}
    on:goBack={() => {
      navigate('exercises', { 
        state: { 
          activeSearch: $exercisesActiveSearch, 
          activeTagFilter: $exercisesActiveTagsFilter,
          activeDropdownTagFilter: $exercisesActiveDropdownTagFilter,
          activePagination: true, 
        }
      });
    }}
    on:onSubmit={() => {
      navigate('exercises', { 
        state: { 
          activeSearch: $exercisesActiveSearch, 
          activeTagFilter: $exercisesActiveTagsFilter,
          activeDropdownTagFilter: $exercisesActiveDropdownTagFilter, 
          activePagination: true,
        }
      });
    }} />
</div>
