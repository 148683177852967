<script>

import { _ } from "../../services/i18n";
import { Icon } from "@smui/common";
import { navigate } from "svelte-routing";
import Button, { Label } from "@smui/button";

export let msgError;

</script>

{#if msgError}
  <div class="alert alert-danger text-center fade show" role="alert">
    {@html msgError}
    {#if msgError === $_("plan_conflict_exception")}
      <Button
        class="btn banner-button ml-2"
        variant="unelevated"
        on:click={() => {
          navigate('/pt-info?tab=billing');
        }}>
        <Icon class="material-icons-outlined">payment</Icon>
        <Label class="textUppercase">{$_('vedi_dettaglio')}</Label>
      </Button>
    {/if}
  </div>
{/if}