<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { deleteVodAssignment, deletePlaylistAssignment } from "../../../../services/vodsServices";
  import AssignedVodActions from "./AssignedVodActions.svelte";
  import AssignedVodInfo from "./AssignedVodInfo.svelte";
  import Card, { Content } from "@smui/card";

  export let groupId;
  export let firstAssignments = [];

  const dispatch = createEventDispatcher();

  let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1; 
  let showMobileIcons;
  let withoutErasedEmptyFilteredAssignments = [];

  $: if (firstAssignments) {
    withoutErasedEmptyFilteredAssignments = firstAssignments.filter((fc) => {
      if (!fc.is_active)
        return;
      else return fc;
    });
  }

  const handlePlayVod = (e) => {
    dispatch("playVod", { vod: e.detail.vod });
  }

  const handleDeleteAssignment = async ({ detail }) => {
    const { assignment } = detail;
    try {
      if (assignment.vod_assignment_id) {
        await deleteVodAssignment(assignment.vod_assignment_id)
      } else if (assignment.playlist_id) {
        await deletePlaylistAssignment(assignment.playlist_assignment_id)
      }
      dispatch("removeAssignment", { assignment });
    } catch (err) {
      console.log("delete error", err);
      dispatch("onError", {});
    }
  };
</script>

<style>
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
</style>

{#each withoutErasedEmptyFilteredAssignments as assignment, i}
  <Card class="mb-3">
    <Content>
      <div class="row px-2 spaceBetween">
        <AssignedVodInfo {assignment} {isMobile} on:updateDraftTrainingCard on:playVod={handlePlayVod} />
        <AssignedVodActions
          bind:showMobileIcons
          {assignment}
          {groupId}
          {isMobile}
          index={i}
          on:deleteAssignment={handleDeleteAssignment} />
      </div>
    </Content>
  </Card>
{/each}
