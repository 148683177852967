import { writable } from "svelte/store";
import moment from "moment";

export const calendar = writable(
    [
        {
            title: 'event 1', start: moment().toISOString(), end: moment().add(2, "hours").toISOString()
        },
        { title: 'event 2', date: '2021-12-17' },
    ],
)

export const appointmentDefault = writable({
    type: 0, //0 appointment - 1 reminder
    title: "",
    startDate: moment().format("YYYY-MM-DD"),
    startHour: moment().add(60, "minutes").startOf('hour').format("HH:mm"),
    endDate: moment().format("YYYY-MM-DD"),
    endHour: moment().add(60, "minutes").startOf('hour').add(30, "minutes").format("HH:mm"),
    site: 0, // required, integer //corrisponde al campo "Luogo" della form, 0: In fisico, 1: Videocall, 2: Telefono, 3: Xtravision
    url: "",
    notifications: [
        {
            notification_time_value: 1,
            notification_time_unit: "h",
        },
    ],
    customers: [],
    at_groups: [],
    note: "",
    xtravision_customers_class_link: "",
    xtravision_schedule_id: "",
    xtravision_trainer_class_link: "",
})

export const reminderDefault = writable({
    type: 1,
    title: "",
    startDate: moment().format("YYYY-MM-DD"),
    startHour: moment().add(60, "minutes").startOf('hour').format("HH:mm"),
    endDate: moment().format("YYYY-MM-DD"),
    endHour: moment().add(60, "minutes").startOf('hour').add(30, "minutes").format("HH:mm"),
    site: 0,
    url: "",
    notifications: [
        {
            notification_time_value: 1,
            notification_time_unit: "h",
        },
    ],
    customers: [],
    at_groups: [],
    note: "",
    send_email_update: false,
})