<script>
  import "../../AssignTrainingCardDialog.scss";
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, tick } from "svelte";
  import { Icon } from "@smui/common";
  import { progressionsPage, activeSubSection } from "../../../../lib/store";
	import { validateCombinationValues } from "../workoutsValidation";
  import AddNewExercise from "../AddNewExercise.svelte";
  import ExerciseSecondRow from "./ExerciseSecondRow.svelte";
  import IconButton from "@smui/icon-button";
  import Lazy from "svelte-lazy";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import NoteDialog from "./NoteDialog.svelte";

  const dispatch = createEventDispatcher();

  export let exercise;
  export let combination;
  export let isProgression;
  export let isMobile;

  let dirtyExercise;

  let noteDialog = false;
  let fieldEmpty = false;
  let noteDialogOpened = false;

  $: dispatch("dirtyExercise", { dirtyExercise });

  $: if (exercise) exercise.note = exercise.note ? exercise.note : "";

  // Realtime combination values validation
  $: {
    exercise.details.forEach((i) => {
      // Sets
      if (!isNaN(i.sets)) {
        i.sets = validateCombinationValues(i.sets, 'sets');
      }
      // Reps & Weight
      [i.reps, i.weight].forEach((el) => {
        if (!isNaN(el.value)) {
          el.value = validateCombinationValues(el.value, el.type);
        } 
        else if (el.type !== 'time' && el.value !== undefined) {
          el.value = '';
        }
        if (!isNaN(el.value_high)) {
          el.value_high = validateCombinationValues(el.value_high, el.type);
        } 
        else if (el.type !== 'time' && el.value !== undefined) {
          el.value = '';
        }
      });
    });
  }
  
  const deleteExercise = () => {
    dirtyExercise = false;
    dispatch("dirtyExercise", { dirtyExercise });
    dispatch("deleteExercise", { sortId: exercise.sortId });
  };

  const handleExerciseSelected = (e) => {
    exercise.name = e.detail.exercise.name;
    exercise.id = e.detail.exercise.id;
    dispatch('checkAndUpdateDuplicates', { 
      exerciseId: exercise.id, 
      newExerciseName: exercise.name,
    });
  };

  const handleSearchExercisesClicked = async () => {
    dispatch('openExercisesDialog', { 
      component: 'Exercise',
      combination: combination,
      exercise: exercise,
    });
  };

  const onloadLazy = (node) => {};
  const handleFieldEmpty = () => (fieldEmpty = true);
  const handleFieldFilled = () => (fieldEmpty = false);

</script>

<style>
  .label {
    opacity: 0.7;
    font-size: 0.8rem;
    font-weight: 500;
  }
  * :global(.exerciseActions) {
    justify-content: center;
  }
  * :global(.exerciseActions--left) {
    justify-content: start !important;
  }
  * :global(.mdc-text-field.text-center > .mdc-text-field__input) {
    text-align: center;
  }
  * :global(.mdc-text-field__input) {
    max-height: 100%;
    padding: 0 0.5em;
  }
  * :global(.mdc-text-field) {
    height: 2.5em;
  }
  * :global(.mdc-dialog__surface) {
    min-width: 25rem;
  }
  * :global(.cursorPointer) {
    cursor: pointer;
  }
  * :global(.text-center) {
    text-align: center;
  }
  .dirtyExerciseLabel {
    color: var(--primary);
    margin-left: 7%;
  }
</style>

<NoteDialog bind:noteDialog bind:note={exercise.note} bind:noteDialogOpened {exercise} />

<div class="row py-2">
  <div class="col-1 px-0 flexy align-items-end">
    <div class="exercises-drag-handle">
      <IconButton class="material-icons px-0">drag_indicator</IconButton>
    </div>
  </div>
  <div class="col-9 px-0">
    <AddNewExercise
      draggable={true}
      search={exercise.name}
      bind:dirtyExercise
      on:exerciseSelected={handleExerciseSelected}
      on:fieldEmpty={handleFieldEmpty}
      on:fieldFilled={handleFieldFilled}
      exerciseSelected={exercise}
      inputLabel={$_('esercizio')} />
  </div>
  {#if fieldEmpty}
    <div class="col-2 pl-0">
      <span class="label">{$_('cerca')}</span>
      <div class="py-2">
        <Icon
          class="material-icons searchIcon cursorPointer"
          on:click={handleSearchExercisesClicked}>
          search
        </Icon>
        <Icon
          class="material-icons cursorPointer primary ml-3 deleteCombinationExercise"
          on:click={deleteExercise}>
          delete_outline
        </Icon>
      </div>
    </div>
  {:else}
    {#if $progressionsPage != 1}
      <div class="col-2 pl-0 text-center">
        <span class="label">{$_('nota')}</span>
        <div class="flexy py-2 exerciseActions">
          <Icon class="material-icons cursorPointer" on:click={() => {
              noteDialog.open();
              noteDialogOpened = true;
            }}>
            {exercise.note ? 'edit' : 'note_add'}
          </Icon>
          <Icon
            class="material-icons cursorPointer primary ml-3 exerciseDelete"
            on:click={deleteExercise}>
            delete_outline
          </Icon>
        </div>
      </div>
    {/if}
  {/if}
</div>
{#if dirtyExercise}
  <span class="dirtyExerciseLabel label">{$_('select-or-delete-exercise')}</span>
{/if}

{#if isProgression !== false}
    <ExerciseSecondRow bind:exercise {combination} {isMobile} />
{:else}
  <Lazy placeholder={Loading} onload={onloadLazy}>
    <ExerciseSecondRow bind:exercise {combination} {isMobile} />
  </Lazy>
{/if}

