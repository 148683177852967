import { POST, GET, DELETE } from "../lib/http";

const commonHeaders = {
    ["Auth"]: "REVOO2019",
    ["Access-Control-Allow-Origin"]: "*",
    ["Accept"]: "application/json",
};

export const getCometChatUserId = async (id) => {
    try {
        const res = await GET(`/v2/chat/pt/user`, {
            headers: { ...commonHeaders }
        });
        return res;
    } catch (err) {
        throw err;
    }
};

export const cometChatLogout = async () => {
    console.log('cometChatLogout...');
    CometChatWidget.logout().then(response => {
        console.log("Logout completed successfully");
        }, error =>{
        console.log("Logout failed with exception:",{error});
        }
    );
}