<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import Button, { Label } from "@smui/button";
  import DatePicker from "../../../ReusableLayouts/DatePicker.svelte";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import LoadingInline from "../../../ReusableLayouts/LoadingInline.svelte";
  import moment from "moment";
  import Textfield from "@smui/textfield";

  export let isSubmitting = false;
  export let note = {
    title: "",
    date: moment().format(dateDBFormat),
    content: "",
  };

  const dateDBFormat = "YYYY-MM-DD";
  const datePickerFormat = "DD/MM/YYYY";
  const dispatch = createEventDispatcher();

  let contentDirty   = false;
  let contentInvalid = false;
  let formInvalid    = false;
  let titleDirty     = false;
  let titleInvalid   = false;

  $: titleInvalid    = note.title.length < 1 && titleDirty;
  $: contentInvalid  = note.content.length < 1 || (note.content.length >= 1 && note.content.replace(/\s+/g, '').length < 1);
  $: formInvalid     = titleInvalid || contentInvalid;
  $: noteId          = new URLSearchParams(location.search).get("noteId");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (noteId) {
      dispatch("updateNote");
    } else {
      dispatch("saveNote");
    }
  };

  const handleCancel = () => {
    dispatch("cancel");
  };

  onMount(() => {
    if (window.history.state?.note) {
      note = window.history.state.note;
    }
  });
</script>

<style>
  .note-container {
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .input-label {
    text-transform: capitalize;
    text-align: end;
    align-self: center;
    font-weight: bold;
    opacity: 0.8;
  }
  .input-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .input-col:not(:last-child) {
    margin-bottom: 8px;
  }
  .pushToEnd {
    display: flex;
    justify-content: end;
  }
  .label-col {
    align-items: center;
    justify-content: end;
    display: flex;
  }
  .textbox {
    width: 100%;
  }

  * :global(.mdc-text-field.noteDescription) {
    height: auto;
    width: 100% !important;
  }
</style>

<form class="note-container" on:submit={handleSubmit}>
  <div class="row mt-16">
    <div class="col-4 label-col">
      <label class="input-label" for="title">{`${$_('titolo')} *`}</label>
    </div>
    <div class="input-col col-8">
      <Textfield
        variant="outlined"
        name="title"
        class="textbox"
        bind:value={note.title}
        bind:invalid={titleInvalid}
        bind:dirty={titleDirty}
        type="text" />
      {#if titleInvalid}
        <FormMessage>{$_('inserisci_un_titolo_valido')}</FormMessage>
      {/if}
    </div>
    <div class="col-4 label-col">
      <label class="input-label" for="date">{`${$_('data')} *`}</label>
    </div>
    <div class="col-8 input-col">
      <DatePicker
        on:date={(e) => (note.date = moment(e.detail?.date, datePickerFormat).format(dateDBFormat))}
        defaultDate={moment(note.date, dateDBFormat).format(datePickerFormat)}
        maxDate={moment().add(18, 'years').toISOString()}
        width="100" />
    </div>
    <div class="col-4 label-col">
      <label class="input-label" for="textarea">{`${$_('contenuto')} *`}</label>
    </div>
    <div class="col-8 input-col">
      <Textfield
        variant="outlined"
        name="textarea"
        style="width: 100%;"
        id="noteTextarea"
        class={`textbox fullWidth noteDescription ${contentInvalid && contentDirty ? 'mdc-text-field--invalid' : ''}`}
        bind:value={note.content}
        bind:dirty={contentDirty}
        textarea />
      {#if contentInvalid && contentDirty}
        <FormMessage>{$_('inserisci_un_contenuto_valido')}</FormMessage>
      {/if}
    </div>
  </div>
  <hr />
  <div class="row pushToEnd">
    <Button class="btn" type="button" on:click={handleCancel}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn mx-3"
      variant="unelevated"
      type="submit"
      disabled={formInvalid || isSubmitting}>
      <Label>
        {!noteId ? $_('crea_nota') : $_('aggiorna_nota')}
        {#if isSubmitting}
          <LoadingInline />
        {/if}
      </Label>
    </Button>
  </div>
</form>
