<script>
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher, onDestroy, onMount, tick } from "svelte";
  import { getAvailableProducts, getSubscription, updateSubscription } from "../../../services/subscriptionServices.js";
  import { getToUpdateHubspotPt } from "../../../services/teamServices.js";
  import { jwt, subscription, subscriptionInfo, user } from "../../../lib/store";
  import { restartTrial } from "../../../services/authServices.js";
  import Billing from "./UpdateSubscriptionSteps/Billing.svelte";
  import Button, { Label } from "@smui/button";
  import Dialog, { Title, Content, Actions } from '@smui/dialog';
  import Final from "./UpdateSubscriptionSteps/Final.svelte";
  import moment from "moment";
  import Payment from "./UpdateSubscriptionSteps/Payment.svelte";
  import Plans from "./UpdateSubscriptionSteps/Plans.svelte";
  import SubscriptionDialogHeader from "./SubscriptionDialogHeader.svelte";
  
  export let setiPriceId;
  export let setiPriceValue;
  export let setiPriceInterval;
  export let setiPriceNextInvoiceDate;
  export let setiProductId;
  export let setiProductName;
  export let setiSetupIntentClientSecret;
  export let setiSetupIntentId;
  export let setiSourceType;
  export let setiState;
  export let state = "trial_ended";
  export let trigger = "";

  const dispatch = createEventDispatcher();

  let availableProducts = [];
  let expiredTrialDialog;
  let expiredTrialOverPeriodDialog;
  let loading = false;
  let maxCustomers = null;
  let nextInvoiceDate = "";
  let paymentError = false;
  let paymentErrorLabel = "Errore nel pagamento. Aggiorna i dati della carta e riprova.";
  let processingExpiredTrial = false;
  let processingExpiredTrialOverPeriod = false;
  let selectedPriceId = null;
  let selectedPriceInterval = "";
  let selectedPriceNextInvoiceDate = "";
  let selectedPriceValue = 0;
  let selectedProductId = null;
  let selectedProductName = "";
  let selectedProductRank = "";
  let status;
  let step = 0;
  let title = "";
  let viewportWidth = window.innerWidth;

  // $: console.log('processingExpiredTrial', processingExpiredTrial);
  // $: console.log('processingExpiredTrialOverPeriod', processingExpiredTrialOverPeriod);
  // $: console.log('state', state);
  // $: console.log('status', status);
  // $: console.log('step', step);
  // $: console.log('subscription, subscriptionInfo', $subscription, $subscriptionInfo)
  // $: console.log('title', title);

  $: if (setiPriceId && setiProductId) {
    selectedPriceId              = setiPriceId;
    selectedPriceInterval        = setiPriceInterval;
    // selectedPriceNextInvoiceDate = setiPriceNextInvoiceDate; // Capire se va passato ed eventualmente in che formato
    selectedPriceValue           = parseFloat(setiPriceValue);
    selectedProductId            = setiProductId;
    selectedProductName          = setiProductName; 
    step = 3;
  }

  const getCurrentAvailableProducts = async () => {
    availableProducts = await getAvailableProducts();
  };
  
  $: {
    switch (step) {
      case 0 :
      case 1 : 
        switch (state) {
          case 'trialing'                         : title = $_('popup_scelta_piano_trial_in_corso');           break;
          case 'trial_ended'                      : title = $_('popup_trial_periodo_di_prova_scaduto');        break;
          case 'downgrade_plan'                   : 
          case 'upgrade_plan'                     : title = $_('popup_cambio_abbonamento_titolo');             break;
          case 'pricing_change'                   : title = $_('popup_da_abbonamento_vecchio_a_nuovo');        break;
          case 'pricing_change_already_scheduled' : title = $_('popup_da_abbonamento_vecchio_a_nuovo_update'); break;
        }
        break;
      case 2  : 
        title = $subscription.billingInfoFilled === false 
          ? $_('dati_fatturazione') 
          : $_('dati_fatturazione_verifica');
        break;
      case 3  : 
        switch (state) {
          case 'pricing_change' : title = $_('subscription_dialog_payment_step_title'); break;
          case 'downgrade_plan' : 
          default               : title = $_('il_seguente_piano_sara_attivato');
        }
        break;
      case 4  : 
        switch (state) {
          case 'trial_ended'    : title = $_('popup_trial_abbonamento_attivo');                      break;
          case 'pricing_change' : title = $_('subscription_dialog_final_step_pricing_change_title'); break;
          case 'downgrade_plan' :
          default               : title = $_('popup_trial_abbonamento_aggiornato');
        }
        break;
      default : title = $_('popup_trial_periodo_di_prova_scaduto');
    }
  }

  $: if (
      $subscription.trial_days_remaining != undefined && 
      $subscription.days_before_new_trial != undefined
    ) {
    if (
        $subscription.trial_days_remaining >= -Math.abs($subscription.days_before_new_trial) ||
        !$subscriptionInfo.trial_over
      ) {
      status = 'withinPeriod';
    } else {
      status = 'overPeriod';
    }
  }

  $: if (status && $jwt && $jwt != undefined && $jwt.length > 0 && expiredTrialOverPeriodDialog) {
    if (status == "overPeriod" && !processingExpiredTrial) { 
      processingExpiredTrialOverPeriod = true;
      expiredTrialOverPeriodDialog.open();
    } else if (status == "withinPeriod" && !processingExpiredTrialOverPeriod) {
      processingExpiredTrial = true;
      if (setiPriceId && setiProductId) {
        step = 3;
      } else {
        step = 1;
      }
      expiredTrialDialog.open();
    }
  }

  $: if (!$jwt && expiredTrialOverPeriodDialog != undefined) {
    expiredTrialOverPeriodDialog.close();
  }

  $: (async () => { 
    if ($subscription) {
      maxCustomers = await getFeatureValue($subscription, 'max_customers');
    }
  });

  const handleCloseDialog = () => {
    expiredTrialDialog.close();
    expiredTrialOverPeriodDialog.close();
    dispatch('closeDialog');
  };

  const onSubmit = async () => {
    if (processingExpiredTrialOverPeriod) {
      // Restart trial adding 10 days
      await restartTrial();
      await getToUpdateHubspotPt();
      // Refresh subscription variables in local storage
      await getSubscription();
      await tick();
      expiredTrialOverPeriodDialog.close();
      window.location.reload();
    }
  }

  const handleStepsCompleted = async () => {
    if (processingExpiredTrial) {
      expiredTrialDialog.close();
      // Pulizia dell'url dai parametri del SetupIntent e reload della pagina
      window.location.replace(window.location.href.split("?")[0] + '?tab=billing');
    }
  }

  const handleNextStep = async (e) => {
    switch (e.detail.step) {
      case 1: 
        selectedPriceId       = e.detail.selectedPriceId;
        selectedPriceInterval = e.detail.selectedPriceInterval;
        selectedPriceValue    = e.detail.selectedPriceValue;
        selectedProductId     = e.detail.selectedProductId;
        selectedProductName   = e.detail.selectedProductName;
        selectedProductRank   = e.detail.selectedProductRank;
        nextInvoiceDate       = getNextInvoiceDate(selectedPriceInterval);

        // Define if upgrade or downgrade
        if (state === 'upgrade_plan') {
          state = $subscription.product_rank > selectedProductRank ? 'downgrade_plan' : 'upgrade_plan';
        }

        step = e.detail.step + 1;
        break;
      default:
        step = e.detail.step + 1;
    }
  }

  const handlePreviousStep = async () => {
    step = step - 1;
  }

  const handleUpdateSubscription = async () => {
    try {
      paymentError = false;
      const res = await updateSubscription(selectedProductId, selectedPriceId);
      if (res.success === true) {
        if (res.message.status === 'requires_action' && res.message.action_type === 'redirect_to_url') {
          window.location.href = res.message.action;
        } else if (res.message.status === 'requires_action' && res.message.action_type === 'use_stripe_sdk') {
          window.location.href = res.message.action;
        } else {
          step = 4;
        }
      }
      else {
        paymentError = true;
        loading = false;
      }
    } catch (err) {
      loading = false;
      paymentError = true;
      console.log('err: ', err);
    }
  }

  const getNextInvoiceDate = (interval = 'month') => {
    let today = moment();
    let date = interval === 'month'
      ? today.add(1, 'month')
      : today.add(1, 'year');
    return date.format('DD/MM/YYYY');
  }

  onMount(() => {
    getCurrentAvailableProducts();
  });

  onDestroy(() => {
    expiredTrialOverPeriodDialog.close();
    expiredTrialDialog.close();
  })

  // $: if (viewportWidth > 1000) {
  //   console.log('1 viewportWidth: ', viewportWidth);
  // } else {
  //   console.log('2 viewportWidth: ', viewportWidth);
  // }
</script>

<svelte:window bind:innerWidth={viewportWidth} />

<Dialog
  bind:this={expiredTrialOverPeriodDialog}
  aria-describedby="mandatory-content"
  aria-labelledby="mandatory-title"
  class="expired-dialog over"
  escapeKeyAction=""
  scrimClickAction=""
>
  <Title id="mandatory-title" class="text-center py-2">
    {title}
  </Title>
  <Content id="mandatory-content">
    <p class="py-2 text-center">
      <span>
        {@html $_('popup_trial_ciao', {values: { firstName: $user.first_name },})}
      </span>
      <span>
        {$_('popup_trial_over_ultime_novita')}
      </span>
    </p>
    <p class="text-center">
      {$_('popup_trial_over_ricevi_altri_giorni_di_prova')}
    </p>
  </Content>
  <Actions class="justify-content-center mb-3">
    <Button
      variant="unelevated"
      on:click={() => onSubmit()}>
      <Label>{$_('popup_trial_over_prova_revoo')}</Label>
    </Button>
  </Actions>
</Dialog>

<Dialog
  bind:this={expiredTrialDialog}
  on:onConfirmation={() => onSubmit(step)}
  aria-describedby="mandatory-content"
  aria-labelledby="mandatory-title"
  class="expired-dialog"
  escapeKeyAction=""
  scrimClickAction=""
>
  <Title id="mandatory-title" class="py-2" style="padding-left: 36px; padding-right: 36px;">
    <SubscriptionDialogHeader 
      on:closeDialog={handleCloseDialog} 
      {maxCustomers}
      {state}
      {step}
      {title} 
      currentCustomers={0}
      nextInvoiceDate={getNextInvoiceDate()}
      priceInterval={selectedPriceInterval}
      priceValue={selectedPriceValue}
      productName={selectedProductName}
      productRank={selectedProductRank}
    />
  </Title>
  <Content id="mandatory-content">
    {#if step === 1}
      <Plans 
        on:nextStep={handleNextStep} 
        {availableProducts} 
        {state} 
        {trigger}
        {viewportWidth}
      />
    {:else if step === 2}
      <Billing 
        on:nextStep={handleNextStep}
        on:previousStep={handlePreviousStep}
        {state}
        {viewportWidth}
      />
    {:else if step === 3}
      <Payment 
        bind:loading
        on:closeDialog={handleCloseDialog}
        on:previousStep={handlePreviousStep}
        on:updateSubscription={handleUpdateSubscription}
        {paymentError}
        {paymentErrorLabel}
        {selectedPriceId}
        {selectedPriceInterval}
        {selectedPriceNextInvoiceDate}
        {selectedPriceValue}
        {selectedProductId}
        {selectedProductName}
        {setiSetupIntentId}
        {setiSetupIntentClientSecret}
        {state}
        {viewportWidth}
      />
    {:else if step === 4}
      <Final on:stepsCompleted={handleStepsCompleted} {state} {viewportWidth} />
    {/if}
  </Content>
</Dialog>

<style>
  * :global(.title) {
    font-size: 1.25rem;
  }
</style>