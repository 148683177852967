<script>
  export let paths = [];
  export let width = 30;
  export let height = 30;
  export let fill = "";
  export let viewBox = "0 0 30 30";
</script>

<style>
  .icon {
    position: relative;
    display: inline-block;
  }
</style>

<div class="icon">
  <svg
    class="icon"
    {width}
    {height}
    {fill}
    {viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="enable-background:new 0 0 40 40;"
    xml:space="preserve">
    {#each paths as path}
      {#if path.type === 'g'}
        <g>
          {#each path.g as { id, d, fill }}
            <path {id} {d} {fill} />
          {/each}
        </g>
      {:else if path.type === 'e'}
        <ellipse
          id={path.id}
          cx={path.cx}
          cy={path.cy}
          rx={path.rx}
          ry={path.ry}
          fill={path.fill} />
      {:else}
        <path id={path.id} d={path.d} fill={path.fill} transform={path.transform} transform-origin={path.transformOrigin} />
      {/if}
    {/each}
  </svg>
</div>
