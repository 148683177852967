<script>
	import { createEventDispatcher, tick } from "svelte";
	import { _ } from "../../../../services/i18n";
	import { navigate } from "svelte-routing";
	import SvelteTooltip from 'svelte-tooltip';
	import Dialog from "@smui/dialog";
	import IconButton from "@smui/icon-button";
	import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
	import { customer } from "../../../../lib/store";

	export let assignment;
	export let customerId;
	export let index;
	export let isMobile;
	export let showMobileIcons = undefined;

	const dispatch = createEventDispatcher();

	let assignmentToBeDeleted = {};
	let confirmationDeleteDialog;
	let confirmationRemoveFromGroupDialog;
	let customerToBeRemovedFromGroups;
	let dialogText = '';
	let groupsRemoving = [];
	let groupsRemovingNames = [];

	const handleDeleteButtonClicked = (assignment) => {
		assignmentToBeDeleted = assignment;
		confirmationDeleteDialog.open();
	};

	const handleRemoveFromGroupsButtonClicked = (assignment, removingCustomer) => {
		customerToBeRemovedFromGroups = removingCustomer;
		groupsRemoving = assignment.at_groups;
		dialogText = groupsRemovingNames.length > 1 
			? $_('sei_sicuro_di_voler_rimuovere_l_atleta_dai_gruppi_no_question') + ' '
			: $_('sei_sicuro_di_voler_rimuovere_l_atleta_dal_gruppo_no_question') + ' ';
		groupsRemoving.forEach((group, i) => {
			dialogText += group.name;
			if      ((i + 1) == (groupsRemoving.length)) dialogText += '?';
			else if ((i + 2) == (groupsRemoving.length)) dialogText += ' ' + $_('e') + ' ';
			else                                         dialogText += ', ';
		});
		confirmationRemoveFromGroupDialog.open();
	}
</script>

<style>
	* :global(.primary) {
		color: var(--primary);
	}
	* :global(.op-0) {
		opacity: 0 !important;
		cursor: auto;
	}
	.hide {
		display: none;
	}
	.actions > div {
		width: 35px;
	}
</style>

<Dialog bind:this={confirmationDeleteDialog}>
	<ConfirmationDialog
		text={assignment.vod_id 
			? $_('sei_sicuro_di_voler_eliminare_il_video_assegnato')
			: $_('sei_sicuro_di_voler_eliminare_la_playlist_assegnata')
		}
		confirmationButtonLabel={$_('elimina')}
		on:closeDialog={(e) => {
			e.stopPropagation();
			confirmationDeleteDialog.close();
		}}
		on:onConfirmation={(e) => {
			e.stopPropagation();
			dispatch('deleteAssignment', {
				assignment : assignmentToBeDeleted,
				is_active  : assignmentToBeDeleted.is_active,
			});
			confirmationDeleteDialog.close();
		}} />
</Dialog>

<Dialog bind:this={confirmationRemoveFromGroupDialog}>
	<ConfirmationDialog
	  text={dialogText}
	  confirmationButtonLabel={$_('rimuovi')}
	  on:closeDialog={(e) => {
		e.stopPropagation();
		confirmationRemoveFromGroupDialog.close();
	  }}
	  on:onConfirmation={(e) => {
		e.stopPropagation();
		dispatch('removeCustomerFromGroups', {
			customer: customerToBeRemovedFromGroups,
			groupsRemoving,
		});
		confirmationRemoveFromGroupDialog.close();
	  }} />
</Dialog>

{#if isMobile}
	<div
		class="col-3 col-xs-4 col-md-3 pr-0 bg__color flexy bar--icons justify-content-end"
		on:focusout={(e) => {
			if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
			showMobileIcons = undefined;
		}}>
		<div
			class="icon--action"
			class:primary={showMobileIcons == index}
			on:click|stopPropagation={() => {
				(showMobileIcons === index 
					? !assignment.at_group_id 
						? handleDeleteButtonClicked(assignment) 
						: handleRemoveFromGroupsButtonClicked(assignment, $customer) 
					: (showMobileIcons = index)
				)}}>
			<IconButton 
				class="material-icons px-0" 
				style="width: fit-content">
				{showMobileIcons == index 
					? !assignment.at_group_id 
						? `delete` 
						: `person_remove` 
					: 'more_vert'}
			</IconButton>
		</div>
		<div class="flexy">
			{#if !assignment.at_group_id}
				<div
					class="icon--action"
					id="edit-icon"
					class:hide={showMobileIcons != index}
					on:click|stopPropagation={() => {
						if ($customer.active && assignment.is_active) navigate(
							'/vod-assignment', {
								state: {
									assignment,
									customerId,
								},
							}
						);
					}}>
					<IconButton
						disabled={true}
						data-mobile-icon
						class="material-icons pr-1"
						style="width: fit-content">
						edit
					</IconButton>
				</div>
			{/if}
		</div>
		<!-- <div>
			<IconButton class={`material-icons ${assignment.workouts.length != 0 ? 'visible' : 'invisible'}`}>keyboard_arrow_down</IconButton>
		</div> -->
	</div>
{:else}
	<div class="actions col-3 col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
		{#if ($customer.active && assignment.is_active)}
			<div 
				class="mr-2"
				on:click|preventDefault={
					!assignment.at_group_id 
						? handleDeleteButtonClicked(assignment) 
						: handleRemoveFromGroupsButtonClicked(assignment, $customer)}>
				<SvelteTooltip tip="{!assignment.at_group_id ? $_('elimina') : $_('rimuovi_dal_gruppo')}" top >
					<IconButton
						disabled={!assignment.is_active || !$customer.active || !assignment.at_group_id}
						class={`material-icons ${!assignment.is_active || !$customer.active ? '' : 'primary'}`}>
						{!assignment.at_group_id ? `delete` : `person_remove`}
					</IconButton>
				</SvelteTooltip>
			</div>
		{:else}
			<div
				class="mr-2" 
				on:click|stopPropagation>
				<SvelteTooltip tip="{!assignment.at_group_id ? $_('elimina') : $_('rimuovi_dal_gruppo')}" top >
					<IconButton
						disabled={!assignment.is_active || !$customer.active || !assignment.at_group_id}
						class={`material-icons ${!assignment.is_active || !$customer.active ? '' : 'primary'}`}>
						{!assignment.at_group_id ? `delete` : `person_remove`}
					</IconButton>
				</SvelteTooltip>
			</div>
		{/if}
		
		{#if !assignment.is_assigned_to_group}
			<div
				class="mr-1"
				on:click|stopPropagation={() => {
					if ($customer.active && assignment.is_active && !assignment.at_group_id) 
						navigate('vod-assignment', { 
							state: { 
								assignment, 
								customerId 
							} 
						});
				}}>
				<SvelteTooltip tip="{$_('modifica')}" top >
					<IconButton
						class="material-icons">
						edit
					</IconButton>
				</SvelteTooltip>
			</div>
		{/if}
		<!-- <div class="d-none d-sm-block d-lg-block d-xl-block">
			<IconButton class={`material-icons ${tc.workouts.length != 0 ? 'visible' : 'invisible'}`}>keyboard_arrow_down</IconButton>
		</div> -->
		<!-- <IconButton class={`material-icons ${tc.workouts.length != 0 ? 'visible' : 'invisible'}`}>keyboard_arrow_down</IconButton> -->
	</div>
{/if}
