<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import Tab, { Icon } from "@smui/tab";
  import Tabs from "./Tabs.svelte";
  import { activeSection, activeSubSection, previousSection, previousSubSection } from "../../lib/store";

  export let text;
  export let asTextAndIcon;
  export let asTitle;
  export let noBackground;
  export let tabNavigationEnabled;
  export let tabs;
  export let activeTab;
  export let component;
  export let props;
  export let subTitle;
  export let summary;
  export let sticky;

  const handleOpenInvitationDialog = () => {
    dispatch("openInvitationDialog");
  }

  $: if (activeTab) {
    $previousSubSection = $activeSubSection;
    $activeSubSection = activeTab;
  }
</script>

<style>
  .wrapper {
    background-color: #f9f9f9;
  }
  .titleWrapper {
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .headerTitle {
    font-size: 40px;
    font-weight: bold;
  }
  .subtitle {
    opacity: 0.8;
    font-size: 1rem;
    padding-left: 10px;
    text-align: start;
  }
  .summary {
    font-size: 1.2rem;
  }  
  * :global(.material-icons), * :global(.headerText) {
    z-index: 1030;
  }
  * :global(.mdc-tab__content) {
    text-transform: none;
  }
  @media (max-width: 767px) {
    * :global(.mdc-tab__text-label) {
      font-size: 1rem;
    }
  }
</style>

<div class={`${noBackground ? '' : 'wrapper'} ${!sticky ? '' : 'headerBackground'}`}>
  <div class="container fluid">
    {#if asTextAndIcon || asTitle}
      <div class="row no-gutters">
        <div class="col-12 my-4">
          {#if asTextAndIcon}
            <div
              class="mdc-typography--headline6 primary titleWrapper"
              on:click={() => {
                dispatch('goBack', {});
              }}>
              <Icon role="button" tabindex="1" class="material-icons">
                keyboard_arrow_left
              </Icon>
              <span class="headerText demiBold">{text}</span>
            </div>
          {:else if asTitle}
            <div class="headerTitle titleWrapper py-3"><span>{text}</span></div>
          {/if}
        </div>
      </div>
    {/if}

    {#if component}
      <svelte:component this={component} {...props} on:onComponentEvent on:openInvitationDialog={handleOpenInvitationDialog}/>
    {/if}

    {#if tabs}
      <Tabs {tabs} {tabNavigationEnabled} {activeTab} on:activeTabChanged />
    {/if}
  </div>
  {#if summary}
    <div class="container fluid pb-2">
      <div class="summary pb-3">{@html summary}</div>
    </div>
  {/if}
  {#if subTitle}
    <div class="container fluid pt-1">
      <div class="mdc-typography--headline6 subtitle">{subTitle}</div>
    </div>
  {/if}
</div>
