<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import Card, { Content, Media } from "@smui/card";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import CustomersGroupLabels from "../ReusableLayouts/CustomersGroupLabels.svelte";
  import IconButton from "@smui/icon-button";
  import SvelteTooltip from 'svelte-tooltip';
  
  export let filtratedCustomer = {};

  const dispatch = createEventDispatcher();

  const sendEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };

  // Format date from YYYY-MM-DD to DD/MM/YYYY
  const formatDate = (date) => {
    const dateArray = date.split("-");
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
  };

  const handleChecked = (e) => {
    dispatch("filtratedCustomerChecked", {
      checked: filtratedCustomer.selected,
    });
  };
</script>

<style>
  .textWrapper {
    opacity: 0.8;
    word-break: break-word;
  }
  .imageWrapper {
    background-color: grey;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  .imagecustomer {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    align-items: center;
    object-fit: cover;
  }
  * :global(.mdc-card.customerCard) {
    cursor: pointer;
  }
  .statusCircle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    height: 15px;
    width: 15px;
    margin-right: 10px;
    margin-top: 4px;
  }
  .centered {
    margin: auto;
  }
  @media (max-width: 767px) {
    .statusCircle {
      margin: 0;
    }
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }
  }
</style>

<Card
  class="customerCard mb-2"
  on:click={(event) => {
    if (event.target.tagName != 'INPUT' && event.target.tagName != 'BUTTON') {
      navigate(`/customer?tab=progress`, {
        state: { customerId: filtratedCustomer.id },
      });
    }
  }}>
  <Content>
    <div class="row no-gutters flexy justify-content-between">
      <div class="col-1 col-md-1 col-lg-1" style="padding-left:0px">
        <FormField>
          <Checkbox
            bind:checked={filtratedCustomer.selected}
            on:change={handleChecked} />
        </FormField>
      </div>
      <div class="col-2 offset-1 offset-md-0 offset-lg-0 col-md-1 col-lg-1">
        <Media class="card-media-square customUploader" aspectRatio="square">
          {#if filtratedCustomer.image}
            <img
              src={filtratedCustomer.image}
              alt=""
              class="imagecustomer d-flex" />
          {:else}
            <div class="imageWrapper d-flex align-items-center">
              <div style="" />
            </div>
          {/if}
        </Media>
      </div>
      <div class="col-6 offset-1 offset-md-0 offset-lg-0 col-md-3 col-lg-3 px-md-3 px-lg-0">
        <div class="demiBold textWrapper">
          {filtratedCustomer.first_name + ' ' + filtratedCustomer.last_name}
        </div>
      </div>
      <div class="d-none d-md-block col-md-2 col-lg-2 textWrapper pr-lg-3 justify-content-center">
        <!-- new way (expect BE update)-->
        {#if filtratedCustomer.groups && filtratedCustomer.groups.length > 0}
          <CustomersGroupLabels groupNames={filtratedCustomer.groupNames} />
        <!-- old way -->
        {:else if filtratedCustomer.group && filtratedCustomer.group.length > 0}
          {#each filtratedCustomer.group as assignedGroup, i}
            <div class="flexy justify-content-center">
              <span class="w100">
                {assignedGroup}{(i + 1) != filtratedCustomer.group.length ? ',' : ''}
              </span>
            </div>
          {/each}
        {/if}
      </div>
      <span class="col-1 col-md-5 col-lg-5">
        <div class="row no-gutters justify-content-end">
          <div class="col-md-5 col-lg-5 centered" style="display:flex">
            {#if filtratedCustomer.last_workout == null && filtratedCustomer.status != 'disabled'}
              <div class="statusCircle userIconBgr-inactive" />
              <div class="textWrapper d-none d-md-block">
                <SvelteTooltip tip="{$_('atleta_non_si_è_mai_allenato')}" top >
                  {$_('mai_allenato')}
                </SvelteTooltip>
              </div>
            {:else if filtratedCustomer.status == 'active'}
                <div class="statusCircle userIconBgr-active" />
                <div class="textWrapper d-none d-md-block">
                  <SvelteTooltip tip="{$_('atleta_si_allena_regolarmente')}" top >
                    {$_('molto_attivo')}
                  </SvelteTooltip>
                </div>
            {:else if filtratedCustomer.status == 'not_very_active'}
              <div class="statusCircle userIconBgr-notVeryActive" />
              <div class="textWrapper d-none d-md-block">
                <SvelteTooltip tip="{$_('atleta_non_si_allena_7_giorni')}" top >
                  {$_('attivo')}
                </SvelteTooltip>
              </div>
            {:else if filtratedCustomer.status == 'inactive'}
              <div class="statusCircle userIconBgr-inactive" />
              <div class="textWrapper d-none d-md-block">
                <SvelteTooltip tip="{$_('atleta_non_si_allena_15_giorni')}" top >
                  {$_('poco_attivo')}
                </SvelteTooltip>
              </div>
            {:else}
              <div class="statusCircle userIconBgr-disactive" />
              <div class="textWrapper d-none d-md-block">
                {$_('disattivato')}
              </div>
            {/if}
          </div>
          <div class="col-md-5 col-lg-5 d-none d-md-block centered">
            <div class="textWrapper">
              {filtratedCustomer.last_workout ? formatDate(filtratedCustomer.last_workout.substring(0, 10)) : ' '}
            </div>
          </div>
          <div class="col-md-2 col-lg-2 d-none d-md-block" style="padding:0px">
            <SvelteTooltip tip="{$_('invia_email')}" top >
              <IconButton
                class="material-icons"
                title="Dismiss"
                on:click={() => {
                  sendEmail(filtratedCustomer.email);
                }}>
                email
              </IconButton>
            </SvelteTooltip>
          </div>
        </div>
      </span>
    </div>
  </Content>
</Card>
