<script>
  import { _ } from "../../../services/i18n";
  import { checkEmail } from "./BillingValidation/billingValidation";
  import { onMount, createEventDispatcher } from "svelte";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Actions } from "@smui/card";
  import countries from "../../../lib/countryCode.js";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import Select, { Option } from "@smui/select";
  import CodiceFiscale from "codice-fiscale-js";
  import Textfield, { Input } from "@smui/textfield";

  const dispatch = createEventDispatcher();

  export let billingDetails;
  export let billingInfoComplete;
  export let loading = false;
  export let state = "";
  export let subscriptionDialogOpen = false;

  /**
   * Popola il form quando il componente viene chiamato nella modale SubscriptionDialog
  */
  $: if (billingDetails) {
    setInitialValues();
  }

  let addressInvalid           = false;
  let billingAddress           = "";
  let billingUniqueCode        = "";
  let billingUniqueCodeInvalid = false;
  let city                     = "";
  let cityInvalid              = false;
  let countries_code           = [];
  let country_code             = "IT";
  let country_name             = "Italia";
  let editPaymentMethod        = false;
  let fcInvalid                = false;
  let fiscalCode               = "";
  let formValid                = false;
  let isItaly                  = false;
  let msgError                 = "";
  let name                     = "";
  let nameInvalid              = false;
  let pec                      = "";
  let pecInvalid               = false;
  let province                 = "";
  let provinceInvalid          = false;
  let vatInvalid               = false;
  let VATNumber                = "";
  let type                     = "person";
  let zipCode                  = "";
  let zipInvalid               = false;

  for (const [key, value] of Object.entries(countries)) {
    countries_code.push(value);
  }

  $: if (
    name &&
    billingAddress &&
    city &&
    zipCode &&
    country_name &&
    ((VATNumber && !vatInvalid) || (fiscalCode && !fcInvalid))
  ) {
    formValid =
      !nameInvalid &&
      !addressInvalid &&
      !provinceInvalid &&
      !fcInvalid &&
      !zipInvalid &&
      !pecInvalid &&
      !billingUniqueCodeInvalid;
  } else {
    formValid = false;
  }

  $: if (name)           nameInvalid     = name.toString().length < 3;
  $: if (billingAddress) addressInvalid  = billingAddress.toString().length < 5;
  $: if (city)           addressInvalid  = city.toString().length < 2;
  $: if (province)       provinceInvalid = isItaly && province.toString().length != 2;
  $: if (country_code)   isItaly         = country_code == "IT";

  $: if (zipCode && country_code)    zipInvalid = isItaly && zipCode.toString().length != 5;
  $: if (VATNumber && country_code)  vatInvalid = isItaly && ((VATNumber.toString().length > 0 && VATNumber.toString().length < 11) || VATNumber.toString().match(/^[a-zA-Z]{2}/));
  $: if (fiscalCode && country_code) fcInvalid  = isItaly && !CodiceFiscale.check(fiscalCode);
  $: if (pec && country_code)        pecInvalid = isItaly && !checkEmail(pec);

  $: if (billingUniqueCode && country_code) {
    billingUniqueCodeInvalid =
    isItaly && billingUniqueCode.toString().length != 7;
  }

  const setInitialValues = () => {
    const initialCountry = countries_code.find(
      (e) => e.name == billingDetails.country
    );

    if (billingDetails) {
      billingAddress    = billingDetails.address_street;
      billingUniqueCode = billingDetails.ei_code;
      city              = billingDetails.address_city;
      country_code      = initialCountry ? initialCountry.iso2 : country_code;
      country_name      = initialCountry ? initialCountry.name : country_name;
      fiscalCode        = billingDetails.tax_code;
      name              = billingDetails.name;
      pec               = billingDetails.certified_email;
      province          = billingDetails.address_province;
      VATNumber         = billingDetails.vat_number;
      zipCode           = billingDetails.address_postal_code;
      type              = billingDetails.type ? billingDetails.type : "person";
    } else {
      billingAddress    = "";
      billingUniqueCode = "";
      city              = "";
      country_code      = "IT";
      country_name      = "Italia";
      fiscalCode        = "";
      name              = "";
      pec               = "";
      province          = "";
      type              = "person";
      VATNumber         = "";
      zipCode           = "";
    }
  };

  $: if (!billingInfoComplete) {
    editPaymentMethod = true;
  } else {
    editPaymentMethod = false;
  }

  const undoEdit = () => {
    setInitialValues();
    editPaymentMethod = !editPaymentMethod;
  };

  const submit = () => {
    dispatch("saveBillingInfo", {
      body: {
        nome                : name,
        indirizzo_via       : billingAddress,
        indirizzo_cap       : zipCode.toString(),
        indirizzo_citta     : city,
        indirizzo_provincia : province,
        paese_iso           : country_code,
        piva                : VATNumber,
        cf                  : fiscalCode,
        mail                : pec,
        pec,
        PA_codice           : billingUniqueCode ? billingUniqueCode : "",
        type,
      },
    });
    if (!subscriptionDialogOpen) {
      editPaymentMethod = false;
    }
  };

  onMount(() => {
    setInitialValues();
  });
</script>

<form name="fattureincloud-form" on:submit|preventDefault={submit} autocomplete="off">
  <Card class="customCard {state == 'trial_ended' || state == 'upgrade_plan' ? `subscription-step` : ``} {subscriptionDialogOpen ? 'subscription-dialog-open' : ''}" variant="outlined" padded>
    <Content class="flexy">
      {#if msgError}
        <div class="alert alert-danger fade show" role="alert">{msgError}</div>
      {/if}
      <div>
        <div class="row my-1">
          <div class="d-block d-md-flex justify-content-between w100 px-3 mx-3">
            <!-- NAME -->
            <Textfield
              disabled={!editPaymentMethod}
              bind:value={name}
              bind:invalid={nameInvalid}
              type="text"
              class={`textbox w100 mr-md-2 my-1 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
              label={`${$_('nome_o_ragione_sociale')} *`} />
            {#if nameInvalid}
              <FormMessage>
                {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
              </FormMessage>
            {/if}
            <!-- TYPE -->
            <Select
              disabled={!editPaymentMethod}
              enhanced
              class="w100 ml-md-2 my-1"
              bind:value={type}
              label={$_('tipologia') + ` *`}>
                <Option value={'person'} selected={type === 'person'}>
                  Persona
                </Option>
                <Option value={'company'} selected={type === 'company'}>
                  Azienda/Associazione
                </Option>
            </Select>
          </div>
          <div class="d-block d-md-flex justify-content-between w100 px-3 mx-3">
            <!-- ADDRESS -->
            <Textfield
              disabled={!editPaymentMethod}
              bind:value={billingAddress}
              bind:invalid={addressInvalid}
              type="text"
              label={`${$_('indirizzo_di_fatturazione')} *`}
              class={`textbox w100 mr-md-2 my-1 ${addressInvalid ? 'mdc-text-field--invalid' : ''}`} />
            {#if addressInvalid}
              <FormMessage>{$_('indirizzo_non_completo')}</FormMessage>
            {/if}
            <!-- CITY -->
            <Textfield
              disabled={!editPaymentMethod}
              bind:value={city}
              bind:invalid={cityInvalid}
              type="text"
              label={`${$_('città')} *`}
              class={`textbox w100 ml-md-2 my-1 ${cityInvalid ? 'mdc-text-field--invalid' : ''}`} />
            {#if cityInvalid}
              <FormMessage>{$_('la_città_inserita_non_è_valida')}</FormMessage>
            {/if}
          </div>
          <div class="d-block d-md-flex justify-content-between w100 px-3 mx-3">
            <!-- PROVINCE -->
            <Textfield
              bind:value={province}
              bind:invalid={provinceInvalid}
              disabled={!editPaymentMethod}
              type="text"
              input$maxlength={`${isItaly ? '2' : ''}`}
              label={`${$_('provincia')} *`}
              class={`textbox w100 mr-md-2 my-1 ${provinceInvalid ? 'mdc-text-field--invalid' : ''}`} />
            {#if provinceInvalid}
              <FormMessage>{$_('sigla_provincia_non_valida')}</FormMessage>
            {/if}
            <!-- ZIP -->
            <Textfield
              disabled={!editPaymentMethod}
              bind:value={zipCode}
              type="tel"
              pattern="[0-9]*"
              label={`${$_('cap')} ${isItaly ? '*' : ''}`}
              bind:invalid={zipInvalid}
              class={`textbox w100 mx-md-2 my-1 ${zipInvalid ? 'mdc-text-field--invalid' : ''}`} />
            {#if zipInvalid}
              <FormMessage>{$_('codice_postale_errato')}</FormMessage>
            {/if}
            <!-- COUNTRY CODE -->
            <Select
              disabled={!editPaymentMethod}
              enhanced
              class="w100 ml-md-2 my-1"
              bind:value={country_code}
              label={$_('stato') + ` *`}>
              {#each countries_code as item}
                <Option value={item.iso2} selected={country_code === item.iso2}>
                  {item.name}
                </Option>
              {/each}
            </Select>
          </div>
          <div class="d-block d-md-flex justify-content-between w100 px-3 mx-3">
            {#if type === 'person'}
              <Textfield
                disabled={!editPaymentMethod}
                bind:value={fiscalCode}
                bind:invalid={fcInvalid}
                type="text"
                label={`${$_('c.f.')}  ${isItaly ? '*' : ''}`}
                class={`textbox w100 mr-md-2 my-1 ${fcInvalid ? 'mdc-text-field--invalid' : ''}`} />
              {#if fcInvalid}
                <FormMessage>{$_('c_f_non_valido')}</FormMessage>
              {/if}
              <!-- {#if fiscalCode != '' && VATNumber != '' && !vatInvalid}
                <FormMessage>{$_('p_iva_gia_compilata')}</FormMessage>
              {/if} -->
            {/if}
            {#if type === 'company'}
              <Textfield
                disabled={!editPaymentMethod}
                bind:value={VATNumber}
                bind:invalid={vatInvalid}
                type="text"
                label={`${$_('p.iva')} ${isItaly ? '*' : ''}`}
                class={`textbox w100 my-1 ${vatInvalid ? 'mdc-text-field--invalid' : ''}`} />
              {#if vatInvalid}
                <FormMessage>{$_('p_iva_non_valida')}</FormMessage>
              {/if}
              <!-- {#if VATNumber != '' && fiscalCode != '' && !fcInvalid}
                <FormMessage>{$_('c_f_gia_compilato')}</FormMessage>
              {/if} -->
            {/if}
          </div>
          {#if type === 'company'}
            <div class="d-block d-md-flex justify-content-between w100 px-3 mx-3">
              <Textfield
                disabled={!editPaymentMethod}
                bind:value={pec}
                bind:invalid={pecInvalid}
                type="text"
                label={`${$_('pec')}`}
                class={`textbox w100 mr-md-2 my-1 ${pecInvalid ? 'mdc-text-field--invalid' : ''}`} />
              {#if pecInvalid}
                <FormMessage>{$_('la_pec_deve_essere_valida')}</FormMessage>
              {/if}
              <Textfield
                disabled={!editPaymentMethod}
                bind:value={billingUniqueCode}
                bind:invalid={billingUniqueCodeInvalid}
                type="text"
                label={`${$_('codice_univoco')}`}
                class={`textbox w100 ml-md-2 my-1 ${billingUniqueCodeInvalid ? 'mdc-text-field--invalid' : ''}`} />
              {#if billingUniqueCodeInvalid}
                <FormMessage>
                  {$_('il_codice_univoco_deve_avere_7_numeri')}
                </FormMessage>
              {/if}
            </div>
          {/if}
          <div class="d-block d-md-flex justify-content-start w100 px-2 mx-2 mt-4 text-align-left text-left">
            <span class="needFic col-12">{$_('serve_fattura_elettronica')}.</span>
          </div>
        </div>
      </div>
    </Content>
  </Card>
  {#if subscriptionDialogOpen}
    <Actions class="d-flex justify-content-center justify-content-md-end mt-2">
      <Button class="btn mr-2" type="button" on:click={() => dispatch('previousStep')}>
        <Label>{$_('indietro')}</Label>
      </Button>
      <Button
        class="btn"
        variant="unelevated"
        type="submit"
        disabled={!formValid || loading}>
        <Label>{$_('salva_e_prosegui')}</Label>
      </Button>
    </Actions>
  {:else}
    <Actions class="d-flex justify-content-center justify-content-md-end mt-2">
      {#if editPaymentMethod}
        <Button class="btn mr-2" type="button" on:click={undoEdit}>
          <Label>{$_('annulla')}</Label>
        </Button>
        <Button
          class="btn"
          variant="unelevated"
          type="submit"
          disabled={!formValid}>
          <Label>{$_('salva')}</Label>
        </Button>
      {:else}
        <Button
          class="btn"
          variant="unelevated"
          on:click={() => (editPaymentMethod = !editPaymentMethod)}>
          <Label>{$_('modifica')}</Label>
        </Button>
      {/if}
    </Actions>
  {/if}
</form>

<style>
  form {
    /* max-width: 400px; */
    width: 100%;
  }

  * :global(.mdc-card.subscription-dialog-open) {
    box-shadow: none !important;
  }

  * :global(.customCard) {
    margin: 0 auto;
    text-align: center;
    border: inherit;
  }

  * :global(.customCard.subscription-step) {
    margin: 0 auto;
    text-align: center;
    border: none !important;
    box-shadow: none !important;
  }

  .needFic {
    color: rgba(0, 0, 0, 0.37);
  }
</style>