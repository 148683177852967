import { POST, GET } from "../lib/http";
import { subscription, subscriptionInfo } from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const getAvailableProducts = async () => {
  try {
    const res = await GET(`/v2/subscription/plans`, {
      headers: { ...commonHeaders },
    //   query: { id: id },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getSubscription = async () => {
  try {
    const res = await GET("/v2/subscription/detail", {
      headers: { ...commonHeaders },
    });
    subscription.set(res.data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getSubscriptionInfo = async () => {
  try {
    const res = await GET("/v2/subscription/status", {
      headers: { ...commonHeaders },
    });
    subscriptionInfo.set(res);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateSubscription = async (productId, priceId) => {
  try {
    const res = await POST(`/v2/subscription/update`, {
      headers: { ...commonHeaders },
      body: { 
        product_id: productId,
        price_id: priceId
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getFeature = (product = {}, featureName) => {
  const feature = product.features.find((feature) => {
    return feature.feature_name == featureName;
  });

  if (feature) {
    switch (featureName) {
      case "max_customers":
        return feature.feature_value == "null"
          ? "Clienti illimitati"
          : "Fino a " + feature.feature_value + " clienti";
  
      case "max_vods":
        if (feature.feature_value == "0") {
          return false;
        }
        return feature.feature_value == "null" 
          ? "Video illimitati"
          : feature.feature_value + " Video on Demand";
  
      case "max_customers_value":
      case "max_vods_value":
        return feature.feature_value;
  
      default:
        return feature.feature_value == "true"
          ? true
          : false;
    }
  }

  return null;
};

export const getFeatureValue = (data, featureName) => {
  if (data != undefined && data.features != undefined) {
    const feature = data.features.find((feature) => {
      return feature.name == featureName;
    });

    if (feature) {
      switch (featureName) {
        case "max_customers":
        case "max_vods":
          if (feature.value == "null") 
            return null;  
          if (feature.value == "0")
            return false;
    
          return feature.value;
    
        default:
          return feature.value == "true"
            ? true
            : false;
      }
    }
  }

  return null;
};

export const getFeatureValueFromProduct = (product, featureName) => {
  if (product != undefined && product.features != undefined) {
    const feature = product.features.find((feature) => {
      return feature.feature_name == featureName;
    });

    if (feature) {
      switch (featureName) {
        case "max_customers":
        case "max_vods":
          if (feature.feature_value == "null")  return null;  
          if (feature.feature_value == "0")     return false;
          return feature.feature_value;    
        default:
          return feature.feature_value == "true"
            ? true
            : false;
      }
    }
  }

  return null;
};

export const isFeatureEnabled = (featureName, sub) => {
  let featureValue;

  if (sub) {
    // Le subscription 'main' o tutte quelle in fase di trialing hanno tutte le feature abilitate
    if (sub.name == 'main' || sub.status == 'trialing') {
      return true;
    }
    
    switch (featureName) {
      case 'vods' :
        featureValue = getFeatureValue(sub, 'max_vods');
  
        if (featureValue === null) {
          return true;
        }
  
        if (featureValue === false) {
          return false;
        }
  
        if (parseInt(featureValue) === 0) {
          return false;
        }
  
        if (sub.current_vods_count >= parseInt(featureValue)) {
          return false;
        }

        return true;
        
      case 'customers' :
        featureValue = getFeatureValue(sub, 'max_customers');
  
        if (featureValue === null) {
          return true;
        }
  
        if (featureValue === false) {
          return false;
        }
  
        if (parseInt(featureValue) === 0) {
          return false;
        }
  
        if (sub.current_customers_count >= parseInt(featureValue)) {
          return false;
        }
  
        return true;
  
      case 'automations' :
      case 'chat'        :
      case 'custom_app'  :
      case 'revoo_pay'   :
        featureValue = getFeatureValue(sub, featureName);
  
        if (featureValue === false) {
          return false;
        }
  
        return true;
      default : 
        return false;
    }
  }

  return true;
}

export const getIntegerSection = (number = 0) => {
  return Math.floor(number);
};

export const getDecimalSection = (number = 0) => {
  let decimalSection = Math.round((number - getIntegerSection(number)).toFixed(2) * 100);
  return decimalSection.toString().length > 1
    ? decimalSection.toString()
    : decimalSection.toString() + "0";
};