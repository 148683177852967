import moment from "moment";

export const handleDataForBE = (appointment, onEdit) => {
  let finalAppointment = {};
  Object.assign(finalAppointment, appointment);
  let startHourSplit = finalAppointment.startHour.split(":");
  let endHourSplit = finalAppointment.endHour.split(":");
  finalAppointment.start_datetime = moment(finalAppointment.startDate)
    .add({ hours: startHourSplit[0], minutes: startHourSplit[1] })
    .format("YYYY-MM-DD HH:mm:ss");
  finalAppointment.end_datetime = moment(finalAppointment.endDate)
    .add({
      hours: endHourSplit[0],
      minutes: endHourSplit[1],
    })
    .format("YYYY-MM-DD HH:mm:ss");
  delete finalAppointment.startDate;
  delete finalAppointment.startHour;
  delete finalAppointment.endDate;
  delete finalAppointment.endHour;
  delete finalAppointment.email;
  delete finalAppointment.emailTo;
  delete finalAppointment.groups;
  delete finalAppointment.place;
  delete finalAppointment.placeLink;
  let sendEmail = finalAppointment.send_email_update;
  if (!onEdit) {
    if (finalAppointment.at_groups.length == 0 && finalAppointment.customers.length == 0)
      sendEmail = false
    finalAppointment.send_email = sendEmail ? 1 : 0;
    delete finalAppointment.send_email_update
  }
  else {
    finalAppointment.send_email_update = sendEmail ? 1 : 0;
    for (const notice of finalAppointment.notifications) {
      if (!notice.delete) notice.delete = false;
    }
  }
  // if (!finalAppointment.note) delete finalAppointment.note;
  // if (finalAppointment.customers.length < 1 && finalAppointment.at_groups.length < 1) {
  //   delete finalAppointment.at_groups;
  //   delete finalAppointment.customers;
  // }
  let tempArray = [];
  if (finalAppointment.at_groups.length > 0) { tempArray = finalAppointment.at_groups; delete finalAppointment.customers; }
  else if (finalAppointment.customers.length > 0) { tempArray = finalAppointment.customers; delete finalAppointment.at_groups; }

  //clean null value for only email customer
  for (const element of tempArray) {
    for (const [key, value] of Object.entries(element)) {
      if (value === null) delete element[key];
    }
  }

  return finalAppointment;
};

export const convertDataFromBE = (appointment) => {
  for (let [key, value] of Object.entries(appointment)) {
    if (value === null) appointment[key] = "";
  }
  appointment.startDate = moment(appointment.start_datetime).format(
    "YYYY-MM-DD"
  );
  appointment.endDate = moment(appointment.end_datetime).format("YYYY-MM-DD");
  appointment.startHour = moment(appointment.start_datetime).format("HH:mm");
  appointment.endHour = moment(appointment.end_datetime).format("HH:mm");
  const sendEmail = appointment.send_email;
  appointment.send_email_update = sendEmail == 1 ? true : false;
  delete appointment.pt_id;
  return appointment;
};
