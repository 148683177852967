<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import { navigate } from "svelte-routing";
  import { _ } from "../../../../services/i18n";
  import Textfield from "@smui/textfield";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import Button, { Label } from "@smui/button";

  export let edit;
  export let playlistToBeEdited;

  let name = "";
  let nameInvalid = false;
  let nameNotModified = false;

  $: formValid = !nameInvalid && !nameNotModified;
  $: nameInvalid = name.length < 3 || name.length > 191;
  $: if (name && edit === true) {
    nameNotModified = name === playlistToBeEdited.name;
  }

  let next = "";
  let nextTab = "";
  let nextUrl = "/vods?tab=playlist";

  $: next    = new URLSearchParams(location.search).get("next");
  $: nextTab = new URLSearchParams(location.search).get("nextTab");
  $: if (next != null && nextTab != null) nextUrl = '/' + next + '?tab=' + nextTab;

  const setInitialValues = () => {
    name = "";
    navigate(nextUrl);
  };

  const onSubmit = () => {
    if (edit === true) {
      let playlist = {...playlistToBeEdited, newPlaylistName: name};
      dispatch("editPlaylist", {
        playlist,
      });
    } else if (edit !== true) {
      console.log(nextUrl);
      dispatch("createPlaylist", {
        playlist: { name },
        nextUrl,
      });
    }
  };

  onMount(() => {
    name = edit === true ? playlistToBeEdited.name : "";
  })
</script>

<style>
  .label {
    text-align: end;
    align-self: center;
    font-weight: bold;
    opacity: 0.8;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
</style>

<div class="py-3">
  <div class="row">
    <div class="col-4 label">{`${$_('nome')} *`}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class={`textbox w100 my-2 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
        bind:value={name}
        type="text" />
      {#if nameInvalid}
        <FormMessage>
          {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
        </FormMessage>
      {/if}
    </div>
  </div>
  <hr />
  <div class="row pushToEnd">
    <Button class="btn" on:click={setInitialValues}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn mx-3"
      variant="unelevated"
      on:click={onSubmit}
      disabled={!formValid}>
      <Label>{edit === false ? $_('crea_playlist') : $_('rinomina_playlist')}</Label>
    </Button>
  </div>
</div>
