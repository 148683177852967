<script>
	import "../../AssignTrainingCardDialog.scss";
	import { _ } from "svelte-i18n";
	import { generateUID, getRepsFormatItems, getRepsItems, getWeightFormatItems, getWeightItems } from "../../../../lib/utils";
	import { Icon } from "@smui/common";
	import { progressionsPage, workouts } from "../../../../lib/store";
	import { tick } from "svelte";
	import CustomSelect from "../CustomSelect.svelte";
	import MaskInput from "svelte-input-mask";
	import NumberInput from 'svelte-input-mask/NumberInput.svelte';
	import Textfield from "@smui/textfield";

	export let exercise;
	export let combination;
	export let isMobile;

	const repsItems 		= getRepsItems($_);
	const repsFormatItems 	= getRepsFormatItems($_);
	const tutMask 			= "*-*-*-*";
	const tutMaskString 	= "0-0-0-0";
	const weightItems 		= getWeightItems($_);
	const weightFormatItems = getWeightFormatItems($_);
	// const tutMaskFormat = /[xX|0-9]/;
	
	let commaInserted = false;

	const manageSingleCombinationSets = async (index) => {
		if (index == exercise.details.length - 1) {
			const newDetail = JSON.parse(
				JSON.stringify(exercise.details[index])
			);

			if (newDetail && !newDetail.sets) newDetail.sets = "";
			if (newDetail && !newDetail.tut) newDetail.tut = "";
			if (newDetail && newDetail.reps && !newDetail.reps.value) newDetail.reps.value = "";
			if (newDetail && newDetail.weight && !newDetail.weight.value) newDetail.weight.value = "";

			exercise.details = [...exercise.details, newDetail];
		} else {
			let clone = [...exercise.details];
			clone.splice(index, 1);
			exercise.details = clone;

			//Fix per drag&drop dopo eliminazione set da piramidale: aggiunto un reset del SortId della combinazione
			$workouts.map((w) => {
				Object.assign(w.combinations).map((comb) => {
					if (comb.sortId == combination.sortId) {
						comb.sortId = generateUID();
					}
				});
			});
			await tick();
			$workouts = $workouts;
		}
	};

	const handleChangeItem = (detail) => {
		//
	}

	const handleChangeItemWeight = (detail) => {
		//
	}

	const handleNumericTextFieldOnInput = (e) => {
		try {
			if (e.target.value < 0) {
				e.target.value = Math.abs(e.target.value);
			}
		} catch (err) {
			console.log('abs weight value error');
		}
	}
</script>

<style>
	.label {
		opacity: 0.7;
		font-size: 0.8rem;
		font-weight: 500;
	}
	* :global(.mdc-text-field.text-center > .mdc-text-field__input) {
		text-align: center;
	}
	* :global(.mdc-text-field__input) {
		max-height: 100%;
		padding: 0 0.5em;
	}
	* :global(.mdc-text-field) {
		height: 2.5em;
	}
	.customInput {
		max-height: 100%;
		padding: 0 0.5em;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.87);
	}

	.type-select {
		/* width: 100%; */
		width: 4.7em;
		height: 1.7em;
	}

	.format-select {
		align-self: end;
	}

	* :global(.type-select select),
	* :global(.format-select select) {
		text-align: center !important;
	}

	* :global(.format-select .mdc-select.smui-select--standard) {
		width: 4em;
	}

	* :global(.type-select .mdc-select.smui-select--standard) {
		height: 1.7em !important;
	}
	
	* :global(.type-select .mdc-select .mdc-select__native-control) {
		width: 100%;
		height: 1.7em !important;
		padding: 0 !important;
		text-align: center;
		background: #f9f9f9;
		color: black;
		border: 1px solid rgba(0, 0, 0, 0.38);
		width: 7em;
		font-weight: 600;
		font-size: 0.8em;
		border-radius: 4px;
		border-bottom-color: rgba(0, 0, 0, 0.38) !important;
	}

	* :global(.type-select .mdc-select__dropdown-icon), * :global(.format-select .mdc-select__dropdown-icon)  {
		display: none;
	}

	* :global(.setsInput > input) {
		padding-left: 0;
		padding-right: 0;
	}
	* :global(.select-weight .mdc-select) {
			/* max-width: 75px !important; */
	}
	* :global(.set-input) {
		max-width: 36px !important;
    	min-width: 36px !important;
	}

	* :global(.input-rest) {
		max-width: 4em;
		width: 4em;
	}

	* :global(.input-tut) {
		max-width: 6.3em;
	}

	* :global(.input-weight), * :global(.input-reps) {
		min-width: 75px !important;
		width: 75px !important;
	}

	* :global(.select-reps), * :global(.select-weight) {
		min-width: 14em;
		justify-content: center;
	}

	/* BREAKPOINTS */

	@media screen and (min-width:896px) and (max-width:991px) {
		* :global(.select-reps), * :global(.select-weight) {
			min-width: 8em;
		}
	}

	@media screen and (min-width:992px) and (max-width:1200px) {
		* :global(.select-reps), * :global(.select-weight) {
			min-width: 12em;
		}
	}

	* :global(.range-wrapper .input-weight), * :global(.range-wrapper .input-reps) {
		min-width: 60px !important;
		width: 60px !important;
	}
	* :global(.select-reps .mdc-select__dropdown-icon) {
			right: 3px !important;
	}
	* :global(.select-weight .mdc-select__dropdown-icon) {
			right: 18px !important;
	}
  
	@media screen and (min-width:896px) and (max-width:991px) {
		* :global(.select-weight .mdc-text-field__input) {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}

	@media screen and (max-width:991px) {
		.select-reps,
		.select-weight {
			align-items: center !important;
			max-width: 120px !important;
			min-width: 10em;
		}
		.input-tut {
			padding-left: 10px !important;
		}
		* :global(.input-tut .mdc-text-field__input) {
			padding-left: 1px !important;
			padding-right: 1px !important;
		}
		.input-tut,
		.input-rest {
			padding-right: 0px !important;
		}
		* :global(.select-reps .mdc-select) {
			/* width: 148px !important; */
		}
		* :global(.select-reps .customInput),
		* :global(.select-weight .mdc-text-field__input) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		* :global(.select-reps .mdc-select__dropdown-icon),
		* :global(.select-weight .mdc-select__dropdown-icon) {
			left: 3px !important;
			top: 24px !important;
		}
	}
</style>

{#each exercise.details as detail, i}
	<div class="row py-1 secondRow d-flex align-items-end">
		<div class="col-1 d-none d-md-flex justify-content-center align-items-center" style="height: 3em;">
			{#if combination.method == 'normal'}
				<slot class="label" />
				<Icon
					class={`material-icons cursorPointer px-1 addPyramidSet ${i == exercise.details.length - 1 ? '' : 'primary'}`}
					on:click={() => {
						manageSingleCombinationSets(i);
					}}
				>
					{i == exercise.details.length - 1 ? 'add_circle_outline' : 'delete_outline'}
				</Icon>
			{/if}
		</div>
		<div class="{isMobile ? `col-11` : `col-9`} d-flex align-items-end justify-content-between px-0">
			<!-- SETS -->
			<div class="px-0 flexy set-input">
				{#if combination.method == 'normal'}
					<div class="d-flex flex-column align-items-center">
						<span class="label">{$_('sets')}</span>
						<Textfield
							id={'sets' + '-' + exercise.id + '-' + i}
							class="w100 text-center setsInput"
							bind:value={detail.sets}
							disabled={$progressionsPage == 1}
							variant="outlined"
							input$min={0}
							on:input={(e) => e.target.value = Number(e.target.value)}
							type="number" />
					</div>
				{/if}
			</div>
			<!-- REPS -->
			<div class="d-flex align-items-end flex-row select-reps">
				<div class="d-flex flex-column align-items-center">
					<div class="type-select">
						<CustomSelect
							bind:selected={detail.reps.type}
							disabled={$progressionsPage == 1}
							on:changedItem={handleChangeItem(detail)}
							items={repsItems} 
						/>
					</div>
					{#if ['standard', 'per_side', 'max'].includes(detail.reps.format)}
						{#if detail.reps.type == 'time'}
							<div class="w100 mdc-text-field mdc-text-field--outlined input-reps">
								<MaskInput
									{...$$props}
									class="w100 customInput text-center"
									placeholder="mm:ss"
									mask={'00:00'}
									type="tel"
									on:change={(e) => {
										detail.reps.value = e.detail.inputState.maskedValue;
									}}
									bind:value={detail.reps.value} 
									disabled={detail.reps.format == 'max' || $progressionsPage == 1} />
								<div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
									<div class="mdc-notched-outline__leading" />
									<div class="mdc-notched-outline__notch" />
									<div class="mdc-notched-outline__trailing" />
								</div>
							</div>
						{:else}
							<input
								class="w70 text-center input-reps"
								disabled={detail.reps.format == 'max' || $progressionsPage == 1}
								min={0}
								type="number" 
								variant="outlined"
								pattern={['meters', 'km'].includes(detail.reps.type) ? "[0-9]+([,\.][0-9]+)?" : "[0-9]+"}
								bind:value={detail.reps.value}
							/>
						{/if}
					{:else if detail.reps.format == 'range'}
						<div class="d-flex justify-content-center align-items-center range-wrapper">
							{#if detail.reps.type == 'time'}
								<div class="w50 mr-1 mdc-text-field mdc-text-field--outlined input-reps">
									<MaskInput
										{...$$props}
										class="w100 customInput text-center"
										placeholder="mm:ss"
										mask={'00:00'}
										type="tel"
										on:change={(e) => {
											detail.reps.value = e.detail.inputState.maskedValue;
										}}
										bind:value={detail.reps.value} 
										disabled={$progressionsPage == 1} />
									<div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
										<div class="mdc-notched-outline__leading" />
										<div class="mdc-notched-outline__notch" />
										<div class="mdc-notched-outline__trailing" />
									</div>
								</div>
								<div class="w50 mdc-text-field mdc-text-field--outlined input-reps">
									<MaskInput
										{...$$props}
										class="w100 customInput text-center"
										placeholder="mm:ss"
										mask={'00:00'}
										type="tel"
										on:change={(e) => {
											detail.reps.value_high = e.detail.inputState.maskedValue;
										}}
										bind:value={detail.reps.value_high} 
										disabled={$progressionsPage == 1} />
									<div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
										<div class="mdc-notched-outline__leading" />
										<div class="mdc-notched-outline__notch" />
										<div class="mdc-notched-outline__trailing" />
									</div>
								</div>
							{:else}
								<input
									class="w50 text-center mr-1 input-reps"
									disabled={$progressionsPage == 1}
									min={0}
									pattern={['meters', 'km'].includes(detail.reps.type) ? "[0-9]+([,\.][0-9]+)?" : "[0-9]+"}
									type="number" 
									variant="outlined"
									bind:value={detail.reps.value}
								/>
								<input
									class="w50 text-center input-reps"
									disabled={$progressionsPage == 1}
									min={0}
									pattern={['meters', 'km'].includes(detail.reps.type) ? "[0-9]+([,\.][0-9]+)?" : "[0-9]+"}
									type="number" 
									variant="outlined"
									bind:value={detail.reps.value_high}
								/>
							{/if}
						</div>
					{/if}
				</div>
				<div class="format-select">
					<CustomSelect
						bind:selected={detail.reps.format}
						disabled={$progressionsPage == 1}
						on:changedItem={handleChangeItem(detail)}
						items={repsFormatItems}
					/>
				</div>
			</div>
			<!-- WEIGHT -->
			<div class="px-0 d-flex align-items-end flex-row select-weight">
				<div class="d-flex flex-column align-items-center">
					<div class="type-select">
						<CustomSelect
							bind:selected={detail.weight.type}
							disabled={$progressionsPage == 1}
							on:changedItem={handleChangeItemWeight(detail)}
							items={weightItems} 
						/>
					</div>
					{#if detail.weight.format != 'range'}
						<label class="mdc-text-field w70 text-center mdc-text-field--outlined mdc-text-field--no-label input-weight {detail.weight.format == 'max' || $progressionsPage == 1 ? `mdc-text-field--disabled` : ``}">
							<input
								class="mdc-text-field__input"
								on:input={handleNumericTextFieldOnInput}
								disabled={detail.weight.format == 'max' || $progressionsPage == 1}
								bind:value={detail.weight.value}
								type="number"
								min="0"
								max="9999.99"
								maxlength="6"
								pattern="[0-9]+([,\.][0-9]+)?"
								step=".5"
							/>
							<div class="mdc-notched-outline mdc-notched-outline--no-label">
								<div class="mdc-notched-outline__leading"></div>
								<div class="mdc-notched-outline__trailing"></div>
							</div>
						</label>
					{:else}
						<div class="d-flex justify-content-center align-items-center range-wrapper">
							<label class="mdc-text-field w50 text-center mdc-text-field--outlined mdc-text-field--no-label input-weight mr-1 {$progressionsPage == 1 ? `mdc-text-field--disabled` : ``}">
								<input
									class="mdc-text-field__input"
									on:input={handleNumericTextFieldOnInput}
									disabled={$progressionsPage == 1}
									bind:value={detail.weight.value}
									type="number" 
									min="0"
									max="9999.99"
									maxlength="6"
									pattern="[0-9]+([,\.][0-9]+)?"
									step=".5"
								/>
								<div class="mdc-notched-outline mdc-notched-outline--no-label">
									<div class="mdc-notched-outline__leading"></div>
									<div class="mdc-notched-outline__trailing"></div>
								</div>
							</label>
							<label class="mdc-text-field w50 text-center mdc-text-field--outlined mdc-text-field--no-label input-weight {$progressionsPage == 1 ? `mdc-text-field--disabled` : ``}">
								<input
									class="mdc-text-field__input"
									on:input={handleNumericTextFieldOnInput}
									disabled={$progressionsPage == 1}
									bind:value={detail.weight.value_high}
									type="number" 
									min="0"
									max="9999.99"
									maxlength="6"
									pattern="[0-9]+([,\.][0-9]+)?"
									step=".5"
								/>
								<div class="mdc-notched-outline mdc-notched-outline--no-label">
									<div class="mdc-notched-outline__leading"></div>
									<div class="mdc-notched-outline__trailing"></div>
								</div>
							</label>
						</div>
					{/if}
				</div>
				<div class="format-select">
					<CustomSelect
						bind:selected={detail.weight.format}
						disabled={$progressionsPage == 1}
						on:changedItem={handleChangeItem(detail)}
						items={weightFormatItems} 
					/>
				</div>
			</div>
			<!-- REST -->
			<div class="input-rest">
				{#if combination.method !== 'emom'}
					<div class="d-flex flex-column align-items-center">
						<span class="label">{$_('rest')}</span>
						<div class="w100 mdc-text-field mdc-text-field--outlined {$progressionsPage == 1 ? `mdc-text-field--disabled` : ``}">
							<MaskInput
								{...$$props}
								class="w100 customInput text-center"
								placeholder="mm:ss"
								mask={'00:00'}
								type="tel"
								on:change={(e) => {
									detail.rest = e.detail.inputState.maskedValue;
								}}
								bind:value={detail.rest} 
								disabled={$progressionsPage == 1} />
							<div
								class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
								<div class="mdc-notched-outline__leading" />
								<div class="mdc-notched-outline__notch" />
								<div class="mdc-notched-outline__trailing" />
							</div>
						</div>
					</div>
				{/if}
			</div>
		</div>
		<!-- TUT -->
		<div class="col-2 pl-0 pl-lg-3 {isMobile ? `d-none` : ``}">
			<div class="input-tut">
				{#if combination.method !== 'emom'}
					<div class="d-flex flex-column align-items-center">
						<span class="label">{$_('tut')}</span>
						<Textfield variant="outlined" class="w100 text-center {$progressionsPage == 1 ? `mdc-text-field--disabled` : ``}">
							<MaskInput
								{...$$props}
								class="mdc-text-field__input"
								maskString={tutMaskString}
								mask={tutMask}
								on:change={(e) => {
									detail.tut = e.detail.inputState.maskedValue == tutMaskString ? '' : e.detail.inputState.maskedValue;
								}}
								bind:value={detail.tut} 
								disabled={$progressionsPage == 1} />
							<div
								class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
								<div class="mdc-notched-outline__leading" />
								<div class="mdc-notched-outline__notch" />
								<div class="mdc-notched-outline__trailing" />
							</div>
						</Textfield>
					</div>
				{/if}
			</div>
		</div>
	</div>
{/each}
