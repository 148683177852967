<script>
    import { createEventDispatcher } from "svelte";
    import Button, { Label } from "@smui/button";
    import Textfield from "@smui/textfield";
    import DatePicker from "../ReusableLayouts/DatePicker.svelte";
    import moment from "moment";

    const dispatch = createEventDispatcher();

    const datePickerFormat = "DD/MM/YYYY";
    const dateDBFormat = "YYYY-MM-DD";

    export let group = {};

    const submit = () => {
        dispatch("save", {
            group: {
                id: group.groupId ? group.groupId : "",
                default: 0,
                nome: group.nome,
                descrizione: group.descrizione ? group.descrizione : "",
                plan_id: group.plan_id,
                plan_id_value: group.plan_id_value,
                plan_id_start_date: "",
                plan_id_end_date: group.plan_id_end_date
                    ? moment(group.plan_id_end_date).format(dateDBFormat)
                    : "",
                invitato_referral_id: group.invitato_referral_id,
                invitato_referral_id_value: group.invitato_referral_id_value,
                invitato_referral_id_start_date: "",
                invitato_referral_id_end_date: group.invitato_referral_id_end_date
                    ? moment(group.invitato_referral_id_end_date).format(
                          dateDBFormat
                      )
                    : "",
                invitato_referral_id_month: group.invitato_referral_id_month,
                invitante_referral_id_value: group.invitante_referral_id_value,
                invitante_referral_id_start_date: "",
                invitante_referral_id_end_date: group.invitante_referral_id_end_date
                    ? moment(group.invitante_referral_id_end_date).format(
                          dateDBFormat
                      )
                    : "",
                invitante_referral_id_month: group.invitante_referral_id_month,
            },
        });
    };

    const deleteGroup = () => {
        dispatch("deleteGroup", { id: group.groupId });
    };

    const handleDatePlan = (e) => {
        group.plan_id_end_date = moment(e.detail.date, datePickerFormat).format(
            dateDBFormat
        );
    };
</script>

<style>
    .ptPricingButtons {
        align-self: flex-end;
    }

    .label {
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1rem;
        margin-left: 0.5rem;
        opacity: 0.7;
    }

    .deleteButton {
        align-self: center;
    }
</style>

<div class="row mx-0 spaceBetween px-2">
    <div class="col-8 my-2">
        <Textfield class="w100 my-2" bind:value={group.nome} label="Nome" />
    </div>

    <div class="col-4 my-2 deleteButton">
        <Button class="btn mx-1" variant="unelevated" on:click={deleteGroup}>
            <Label>Elimina Gruppo</Label>
        </Button>
    </div>

    <div class="col-12 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={group.descrizione}
            label="Descrizione" />
    </div>

    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={group.plan_id_value}
            label="Plan value (view-only)"
            type="number">
            <div class="customLabel">
                <Label
                    class="mdc-text-field__icon mdc-text-field__icon--trailing">
                    €
                </Label>
            </div>
        </Textfield>
    </div>
    <div class="col-4 my-2">
        <span class="label">Plan end date</span>
        <DatePicker
            on:date={handleDatePlan}
            defaultDate={group.plan_id_end_date ? moment(group.plan_id_end_date).format(datePickerFormat) : ''}
            maxDate={moment().add(18, 'years').toISOString()}
            width="100" />
    </div>
    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={group.plan_id}
            label="Group ID Stripe" />
    </div>
    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={group.invitato_referral_id_value}
            label="Referral value (view-only)"
            type="number">
            <div class="customLabel">
                <Label
                    class="mdc-text-field__icon mdc-text-field__icon--trailing">
                    %
                </Label>
            </div>
        </Textfield>
    </div>
    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={group.invitato_referral_id_month}
            label="Referral months (view-only)"
            input$min={0}
            input$max={99}
            type="number" />
    </div>
    <div class="col-4 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={group.invitato_referral_id}
            label="Referral ID Stripe" />
    </div>
    <div class="col-6 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={group.invitante_referral_id_value}
            label="Referral inviting value"
            type="number">
            <div class="customLabel">
                <Label
                    class="mdc-text-field__icon mdc-text-field__icon--trailing">
                    %
                </Label>
            </div>
        </Textfield>
    </div>
    <div class="col-6 my-2">
        <Textfield
            class="w100 my-2"
            bind:value={group.invitante_referral_id_month}
            label="Referral inviting months"
            input$min={0}
            input$max={99}
            type="number" />
    </div>
</div>

<div class="row mx-2 ptPricingButtons spaceBetween p-4">
    <Button
        class="btn mx-1"
        variant="unelevated"
        color="secondary"
        on:click={() => dispatch('cancel', {})}>
        <Label>Chiudi</Label>
    </Button>
    <Button class="btn mx-1" variant="unelevated" on:click={submit}>
        <Label>Salva</Label>
    </Button>
</div>
