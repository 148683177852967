<script>
    import { createEventDispatcher } from "svelte";
    import { _ } from "svelte-i18n";
    import ImageUploader from "./ImageUploader.svelte";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import Button, { Label } from "@smui/button";
    import DatePicker from "../../../ReusableLayouts/DatePicker.svelte";
    import { savePhotoProgress } from "../../../../services/customerServices.js";
    import moment from "moment";
    import "moment/locale/en";
    import "moment/locale/it";

    const dispatch = createEventDispatcher();

    export let uploadDialog = undefined;
    export let uploadPhoto;
    export let dateDialog;
    export let dateDialogItalian;
    export let insertedPhotos;
    export let loading;
    export let customerId;

    const closeHandler = () => {
        Object.keys(uploadPhoto).forEach(
            (k) =>
                (uploadPhoto[
                    k
                ] = `https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/${k}.jpg`)
        );
    };

    const handleDateChange = (e) => {
        const { date } = e.detail;
        dateDialog = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    };

    const savePhotos = async () => {
        try {
            loading = true;
            let body = {};
            for (const k in uploadPhoto) {
                const url = uploadPhoto[k];
                body[k] =
                    typeof url === "string" && url.includes("/PlaceHolder")
                        ? null
                        : url;
            }
            body.customer_id = customerId;
            body.date = dateDialog;
            const res = await savePhotoProgress(body);
            dispatch("getPhotos");
        } catch (error) {
            console.log("savePhotoProgress err", error);
            loading = false;
            dispatch("error");
        }
    };
</script>

<style>
    .headline {
        font-size: 1.25rem;
    }
</style>

<Dialog
    class="dialog--xxsmall"
    bind:this={uploadDialog}
    on:MDCDialog:closed={closeHandler}
    aria-labelledby="simple-title"
    aria-describedby="simple-content">
    <Title id="simple-title">
        <div class="bold headline">{$_('nuove_foto_progressi')}</div>
    </Title>
    <Content>
        <div class="container-fluid">
            <div class="row">
                <div class="col-8 my-3">
                    <DatePicker
                        on:date={handleDateChange}
                        defaultDate={dateDialogItalian}
                        width="100" />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="mdc-typography--body2 mb-2">
                        {$_('frontale')}
                    </div>
                    <ImageUploader bind:image={uploadPhoto.front_view} />
                </div>
                <div class="col-6">
                    <div class="mdc-typography--body2 mb-2">
                        {$_('dorsale')}
                    </div>
                    <ImageUploader bind:image={uploadPhoto.rear_view} />
                </div>
                <div class="col-6 mt-2">
                    <div class="mdc-typography--body2 mb-2">
                        {$_('laterale_sinistro')}
                    </div>
                    <ImageUploader bind:image={uploadPhoto.left_view} />
                </div>
                <div class="col-6 mt-2">
                    <div class="mdc-typography--body2 mb-2">
                        {$_('laterale_destro')}
                    </div>
                    <ImageUploader bind:image={uploadPhoto.right_view} />
                </div>
            </div>
        </div>
        <hr />
    </Content>
    <Actions style="justify-content: center">
        <Button
            action="none"
            disabled={false}
            class="secondaryButton btn ml-2"
            variant="unelevated"
            style="background-color: white !important; color: #cd293d !important; border: 1px solid white !important;"
            on:click={() => uploadDialog.close()}>
            <Label>{$_('annulla')}</Label>
        </Button>
        <Button
            disabled={!insertedPhotos}
            class="primary btn ml-2"
            variant="unelevated"
            on:click={savePhotos}>
            <Label>{$_('salva')}</Label>
        </Button>
    </Actions>
</Dialog>
