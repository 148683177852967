<script>
  export let textAlign = "";
  export let infoMessage = false;

  const addAlignClass = (textAlign) => {
    switch (textAlign) {
      case "left":
        return "text-left";
        break;
      case "right":
        return "text-right";
        break;
      case "center":
        return "text-center";
        break;
      default:
        return "text-left";
        break;
    }
  };
</script>

<style>
  .text--danger {
    color: var(--primary);
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.03333em;
    display: inline-flex;
    text-align: left;
  }
</style>

<div class={addAlignClass(textAlign)}>
  <span class="text--danger" style={infoMessage ? `color: inherit;` : ``}>
    <slot />
  </span>
</div>
