<script>
  import { _ } from "../../../services/i18n";
  import { activeSubSection, autoSavingProgressions, group, previousSubSection, progressions, progressionsPage, workouts } from "../../../lib/store";
  import { assignIdAndSetStrings, generateUID, removeWorkoutIds } from "../../../lib/utils";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { getTrainingCardWorkouts, getWorkout, saveTrainingCardProgressions, saveTrainingCardWorkouts } from "../../../services/trainingCardServices";
  import { navigate } from "svelte-routing";
  import { validateWorkouts } from "./workoutsValidation";
  import ActionsWorkouts from "./ActionsWorkouts.svelte";
  import Button, { Label } from "@smui/button";
  import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";
  import SortableList from "@palsch/svelte-sortablejs";
  import Workout from "./Workout.svelte";

  export let customerId;
  export let trainingCard;

  const dispatch = createEventDispatcher();

  let addWorkoutDisabled;
  let dirtyExercise = false;
  let dirtyExercises = [];
  let disableButtons = false;
  let exitDialog;
  let isProgression = false;
  let quitSaving = false;
  let saving = false;

  $: if ($workouts.length == 0) {
    $workouts.push({
      workoutPlaceholder: true,
      workoutName: "",
      sortId: generateUID(),
      combinations: [],
    });
  } else if (
    $workouts.length > 1 &&
    $workouts.some((w) => w.workoutPlaceholder)
  ) {
    // $workouts = $workouts.filter((w) => !w.workoutPlaceholder);
    // Commententata perché eliminava il workout placeholder anche se popolato appena si importava una scheda o un workout
    $workouts.forEach((w) => delete w.workoutPlaceholder);
  }

  $: {
    addWorkoutDisabled = $workouts[$workouts.length - 1]
      ? $workouts[$workouts.length - 1].combinations.length == 0
      : false;
  }

  // $: if (trainingCard) logTrainingCardId('$:')

  const updateClasses = () => {
    if (dirtyExercises.length > 0) {
      switchClass("exercises-drag-handle", "exercises-drag-handle-disabled");
      switchClass("combinations-drag-handle","combinations-drag-handle-disabled");
      switchClass("workouts-drag-handle", "workouts-drag-handle-disabled");
      switchDisable('trainingCardDuplicate', true);
    } else {
      switchClass("exercises-drag-handle-disabled", "exercises-drag-handle");
      switchClass("combinations-drag-handle-disabled","combinations-drag-handle");
      switchClass("workouts-drag-handle-disabled", "workouts-drag-handle");
      switchDisable('trainingCardDuplicate', false);
    }
    return dirtyExercises.length > 0;
  }

  const switchClass = (originClass, newClass) => {
    document.querySelectorAll(`.${originClass}`).forEach((elem) => {
      elem.classList.add(newClass);
      elem.classList.remove(originClass);
    });
  };
  
  const switchDisable = (className, disabled) => {
    document.querySelectorAll(`.${className}`).forEach((elem) => {
      elem.disabled = disabled;
    });
  } 

  const sortableOptions = {
    group: "workouts",
    animation: 150,
    handle: ".workouts-drag-handle",
  };

  function getItemById(id) {
    return $workouts.find((item) => item.sortId == id);
  }

  const handleAddWorkout = () => {
    $workouts.forEach((w) => delete w.workoutPlaceholder);
    $workouts = [
      ...$workouts,
      {
        workoutName: "",
        sortId: generateUID(),
        combinations: [],
      },
    ];
  };

  const handleImportWorkout = async (e) => {
    await fetchWorkout(e.detail.id);
  };

  const handleImportCard = async (e) => {
    await getCard(e.detail.id);
  };

  const initWorkoutsWithProgressions = async () => {
    if ($activeSubSection == $_('schede') && $previousSubSection == $_('progressioni')) {
      await saveProgressions();
    }
  };

  const getCard = async (id) => {
    try {
      let res = await getTrainingCardWorkouts(id);
      const cleanedCard = res.map((w) => removeWorkoutIds(w));
      $workouts = [...$workouts, ...assignIdAndSetStrings(cleanedCard)];
    } catch (err) {
      console.log("getCardWorkouts error", err);
      dispatch("error", {});
    }
  };

  const fetchWorkout = async (id) => {
    try {
      const res = await getWorkout(id);
      const workout = removeWorkoutIds(res);
      $workouts = [...$workouts, ...assignIdAndSetStrings([workout])];
    } catch (err) {
      console.log("fetchWorkout error", err);
      dispatch("error", {});
    }
  };

  const saveWorkouts = async () => {
    try {
      // logTrainingCardId('saveWorkouts() 1)')
      const validatedWorkouts = validateWorkouts($workouts);
      await tick();
      saving = true;
      // logTrainingCardId('saveWorkouts() 2)')
      await saveTrainingCardWorkouts(validatedWorkouts, trainingCard.id);
      await tick();
      // logTrainingCardId('saveWorkouts() 3)')
      // I workout vengono quindi ricaricati, insieme ai codeId degli esercizi, così da evitare conflitti nello storage delle progressioni
      dispatch("refresh");
      saving = false;
    } catch (err) {
      saving = false;
      console.log("saveWorkouts error", err);
      dispatch("error", {});
    }
  };

  const saveWorkoutsAndExit = async () => {
    try {
      // logTrainingCardId('saveWorkoutsAndExit() 1)')
      const validatedWorkouts = validateWorkouts($workouts);
      await tick();
      quitSaving = true;
      // logTrainingCardId('saveWorkoutsAndExit() 2)')
      await saveTrainingCardWorkouts(validatedWorkouts, trainingCard.id);
      await tick();
      // logTrainingCardId('saveWorkoutsAndExit() 3)')
      quitSaving = false;
      $workouts = [];
      leavePage();
    } catch (err) {
      quitSaving = false;
      console.log("saveWorkoutsAndExit error", err);
      dispatch("error", {});
    }
  };

  const saveProgressions = async () => {
    let validatedWorkouts = [];
    try {
      if ($progressions && $progressions.length > 0 && trainingCard.id) {
        $progressions.forEach((p) => { 
          validatedWorkouts.push(validateWorkouts(p));
        });
        console.log('Autosaving progressions...', validatedWorkouts);
        $autoSavingProgressions = true;
        await saveTrainingCardProgressions(validatedWorkouts, trainingCard.id);
        $autoSavingProgressions = false;
        console.log('Progressions saved!');
      }
    } catch (err) {
      $autoSavingProgressions = false;
      console.log("saveProgressions error", err);
      dispatch("error", {});
    }
  };  

  const handleCheckAndUpdateDuplicates = (e) => {
    const exerciseId = e.detail.exerciseId;
    const newExerciseName = e.detail.newExerciseName;
    $workouts.map(w => {
      w.combinations.map(c => { 
        c.exercises.map(e => { 
          if (e.id === exerciseId) {
            e.name = newExerciseName;
          } 
        })
      });
    });
    $workouts = $workouts;
  }

  const handleGoBack = () => {
    exitDialog.close();
    leavePage();
  };

  const leavePage = () => {
    if (customerId) {
      navigate("/customer?tab=workouts", {
        state: { customerId },
      });
    } else if (trainingCard.is_assigned_to_group || trainingCard.assignedToGroups) {
      if ($group && $group.id) {
        navigate("group?tab=workouts&groupId=" + $group.id);
      } else {
        navigate("/team?tab=gruppi");
      }
    } else {
      navigate("/training-cards");
    }
  }

  const handleDirtyExercise = (e) => {
    if (e.detail.dirtyExercise == true) {
      dirtyExercises = [...dirtyExercises, e.detail.dirtyExercise];
    } else {
      dirtyExercises.pop();
    }
    disableButtons = updateClasses();
  };

  // const logTrainingCardId = (text = '') => {
  //   try { 
  //     console.log('TCW | ' + text + ' | trainingCard.id => ', trainingCard.id);
  //   } catch (error) { 
  //     console.log('TCW | ' + text + ' | trainingCard.id ERROR => ', error);
  //   }
  // }

  // Se si effettua lo switch tra tab progressioni e tab schede, vengono salvate le progressioni
  initWorkoutsWithProgressions();
</script>

<style>
  :global(.list-no-style) {
    padding-inline-start: unset;
    list-style-type: none;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  .justifyCenter {
    justify-content: center;
  }
  * :global(.button--bigger) {
    min-width: 8rem;
  }
  @media (max-width: 1023px) {
    .onlyDesktop {
      display: none;
    }
  }
</style>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={handleGoBack} />
</Dialog>

<ActionsWorkouts
  {addWorkoutDisabled}
  on:addWorkout={handleAddWorkout}
  on:importWorkout={handleImportWorkout}
  on:importCard={handleImportCard} />

<div class="row mx-0 justifyCenter" data-recording-gdpr-safe>
  <!-- {#if !env.isProd}
    <div class="col-3 onlyDesktop">
      <ExercisesLayout on:error />
    </div>
  {/if} -->
  <!-- <div class="col-12 {!env.isProd ? 'col-lg-9' : ''} px-0"></div> -->
  <div class="col-12 px-0" data-recording-gdpr-safe>
    <SortableList
      {sortableOptions}
      bind:items={$workouts}
      {getItemById}
      idKey="sortId"
      ulClass="list-no-style"
      liClass="mb-2"
      let:item>
      <Workout workout={item} bind:isProgression on:dirtyExercise={handleDirtyExercise} on:checkAndUpdateDuplicates={handleCheckAndUpdateDuplicates} />
    </SortableList>
  </div>
</div>

<ActionsWorkouts
  {addWorkoutDisabled}
  on:addWorkout={handleAddWorkout}
  on:importWorkout={handleImportWorkout}
  on:importCard={handleImportCard} />

<hr />

<div class="row pushToEnd">
  <Button 
    class="btn" 
    disabled={saving || quitSaving}
    on:click={() => exitDialog.open()}>
    <Label>{$_('annulla')}</Label>
  </Button>
  <Button
    class="btn ml-5 mr-3 button--bigger"
    variant="unelevated"
    disabled={disableButtons || quitSaving || saving}
    on:click={saveWorkouts}>
    <Label>
      <span class="{saving ? `pr-2` : ``}">{$_('salva')}</span>
      {#if saving}
        <LoadingInline />
      {/if}
    </Label>
  </Button>
  <Button
    class="btn ml-5 mr-3 button--bigger"
    variant="unelevated"
    disabled={disableButtons || quitSaving || saving}
    on:click={saveWorkoutsAndExit}>
    <Label>
      <span class="{quitSaving ? `pr-2` : ``}">{$_('salva_ed_esci')}</span>
      {#if quitSaving}
        <LoadingInline />
      {/if}
    </Label>
  </Button>
</div>
