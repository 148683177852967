<script>
  import { onMount } from "svelte";
  import { _ } from "../../services/i18n";
  import moment from "moment";
  import Button, { Label, Icon } from "@smui/button";
  import Textfield, { Input } from "@smui/textfield";
  import FormMessage from "../ReusableLayouts/FormMessage.svelte";
  import DatePicker from "../ReusableLayouts/DatePicker.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";
  import Select, { Option } from "@smui/select";
  import { getGenderItems, fieldsForGooglePlace } from "../../lib/utils";
  import { user } from "../../lib/store";
  import { updatePersonalData } from "../../services/userServices";
  import CC from "../../lib/countryCode";

  let gender = $user.gender;
  let birth_date = $user.date_of_birth || "1990-01-01";
  let country = $user.country;
  let city = $user.active_city;

  const genderItems = getGenderItems($_);
  let countries_code = [];
  for (const [key, value] of Object.entries(CC)) {
    countries_code.push(value);
  }

  let msgError;
  let formValid = false;
  let cityInvalid = false;
  $: formValid = gender && !cityInvalid;

  let birth_dateFormatted = $user.date_of_birth || "1990-01-01";
  let dateDialogItalian;
  $: {
    dateDialogItalian = birth_date
      ? moment(birth_date, "YYYY-MM-DD").format("DD/MM/YYYY")
      : "";
  }

  const handleDate = (e) => {
    birth_date = e.detail.date;
    birth_dateFormatted = birth_date; // moment(birth_date, 'DD/MM/YYYY').format("YYYY-MM-DD");
  };

  let autocomplete;
  let countryRestrict = { country: "it" };
  let placeSelected;

  $: {
    if (country && autocomplete) {
      setAutocompleteCountry();
    }
  }

  const onPlaceChanged = () => {
    let place = autocomplete.getPlace();
    delete place.utc_offset;
    if (place.geometry) {
      placeSelected = place;
      city = placeSelected.address_components[0].long_name;
      cityInvalid = false;
    } else {
      placeSelected = null;
      city = null;
    }
  };

  const setAutocompleteCountry = () => {
    if (!country) {
      autocomplete.setComponentRestrictions({ country: [] });
    } else {
      autocomplete.setComponentRestrictions({ country: country });
    }
  };

  const init = () => {
    autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */ document.getElementById("autocomplete"),
      {
        types: ["(cities)"],
        componentRestrictions: countryRestrict,
        fields: fieldsForGooglePlace,
      }
    );
    autocomplete.addListener("place_changed", onPlaceChanged);
  };

  const submit = async () => {
    try {
      const personalData = {
        country,
        city: placeSelected,
        gender,
        birth_date: birth_dateFormatted,
      };
      const res = await updatePersonalData(personalData);
      $user.country = res.country;
      $user.active_city = res.city;
      $user.gender = res.gender;
      $user.date_of_birth = res.birth_date;
    } catch (err) {
      console.log("err updateUserPersonalData err", err);
      displayError();
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  onMount(() => {
    init();
  });
</script>

<style>
  .label {
    text-align: end;
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
  }
  .textOpacity {
    opacity: 0.8;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
</style>

<div class="py-3">
  <div class="row">
    <div class="col-12 py-4">
      <span class="textOpacity">
        {$_('queste_informazioni_non_saranno_visibili_ai_tuoi_clienti')}
      </span>
    </div>
    <div class="col-4 label">{$_('data_di_nascita')}</div>
    <div class="col-8">
      <DatePicker
        on:date={handleDate}
        defaultDate={dateDialogItalian}
        width="100"
        dateFormat="yyyy-mm-dd" />
    </div>
    <div class="col-4 label">{`${$_('sesso')} *`}</div>
    <div class="col-8">
      <Select
        enhanced
        variant="outlined"
        class="w50 mb-2"
        label=""
        bind:value={gender}>
        {#each genderItems as { value, text }}
          <Option {value} selected={gender === value}>{text}</Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label">{$_('paese')}</div>
    <div class="col-8">
      <Select
        enhanced
        variant="outlined"
        label=""
        class="w100 mb-2"
        bind:value={country}>
        {#each countries_code as item}
          <Option value={item.iso2} selected={country === item.iso2}>
            {item.name}
          </Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label">{$_('città')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class={`textbox w100 my-2 ${cityInvalid ? 'mdc-text-field--invalid' : ''}`}>
        <input
          type="text"
          id="autocomplete"
          bind:value={city}
          on:input={(e) => {
            if (!e.target.value) {
              cityInvalid = false;
              placeSelected = null;
              city = null;
            }
            if (e.target.value && !placeSelected) {
              cityInvalid = true;
            }
          }}
          class="mdc-text-field__input" />
        <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
          <div class="mdc-notched-outline__leading" />
          <div class="mdc-notched-outline__notch" />
          <div class="mdc-notched-outline__trailing" />
        </div>
      </Textfield>
      {#if cityInvalid}
        <FormMessage>{$_('indirizzo_non_completo')}</FormMessage>
      {/if}
    </div>
  </div>

  <hr />

  <MsgError {msgError} />

  <div class="row pushToEnd">
    <Button
      class="btn ml-5 mr-3"
      variant="unelevated"
      on:click={submit}
      disabled={!formValid}>
      <Label>{$_('salva')}</Label>
    </Button>
  </div>
</div>
