<script>
  import { _ } from "../../../../services/i18n";
  import { allGroups } from "../../../../lib/store";
  import { checkUrl,fixUrl } from "../../../Team/TeamValidation/teamValidation.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { getGroupsList } from "../../../../services/calendar";
  import { getPaymentTypes, getPriceOptions } from "../../../../lib/utils.js";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import AddGroups from "../../../Calendar/InnerComponents/AddGroups.svelte";
  import Button, { Label } from "@smui/button";
  import CharacterCounter from "../../../ReusableLayouts/CharacterCounter.svelte";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import FormMessage from "../../../ReusableLayouts/FormMessage.svelte";
  import IconButton from "@smui/icon-button";
  import Select, { Option } from "@smui/select";
  import SvelteTooltip from 'svelte-tooltip';
  import Textfield from "@smui/textfield";
  
  export let customerId = null;
  export let product;

  const dispatch = createEventDispatcher();

  let exitDialog;
  let group = { name: "" };
  let groupsAdded = [];
  let isFormValid = false;
  let linkInvalid = false;
  let paymentTypeOptions = getPaymentTypes($_);
  let priceOptions = getPriceOptions($_);
  let sendToReviewDialog;
  let showPriceOptions = true;

  $: showPriceOptions = product.payment_type == 0; // 0 => "abbonamento", 1 => "acquisto unico"
  $: isFormValid = 
       product.name.length >= 3
    && product.short_description.length >= 3
    && product.price > 0 
    && product.price < 9999
    && !linkInvalid;
  
  // Max value for product.price
  $: if (product && product.price) {
    if (product.price >= 10000) product.price = 9999;
  }

  $: if (product && product.description.length == 0) {
    product.description.push('');
  }

  $: linkInvalid = product.url_after_purchase ? !checkUrl(product.url_after_purchase) : false;

  const onSubmit = (e) => {
    let savingProduct = product;
    let clone = { ...savingProduct };
    sendToReviewDialog.close();

    dispatch("storeProduct", {
      product: clone,
    });
  };

  const handleGoBack = () => {
    exitDialog.close();
    leavePage();
  };

  const leavePage = () => {
    navigate("/products");
  }

  const handleGroupSelected = (e) => {
    if (e.detail.group.hasOwnProperty("id")) {
      product.at_groups = [
        ...product.at_groups,
        { group_id: e.detail.group.id },
      ];
    }
  };

  const handleGroupRemoved = (e) => {
    if (e.detail.group.group_id) {
      product.at_groups = product.at_groups.filter(
        (el) => el.group_id != e.detail.group.group_id
      );
    }
  };

  const getGroups = async () => {
    const filtratedGroups = await getGroupsList();
    allGroups.set(filtratedGroups);
  };

  const updateGroupsAdded = () => {
    groupsAdded = product.at_groups.map((group) => {
      if (group.hasOwnProperty("name")) return group;
    });
  };

  onMount(async () => {
    await getGroups();
    updateGroupsAdded();
  })
</script>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={handleGoBack} />
</Dialog>

<Dialog bind:this={sendToReviewDialog}>
  <ConfirmationDialog
    text={$_('il_prodotto_sara_inviato_in_revisione')}
    confirmationButtonLabel={$_('salva_e_invia')}
    on:closeDialog={sendToReviewDialog.close()}
    on:onConfirmation={onSubmit} />
</Dialog>


<div class="py-3">
  <div class="row">
    <div class="col-4 label">{$_('nome_prodotto')}</div>
    <div class="col-8">
      <Textfield
        bind:value={product.name}
        class="w100 my-2"
        input$maxlength="24"
        label="Es. Abbonamento Premium"
        type="text" 
        variant="outlined"
      >
        <CharacterCounter text={product.name} max={24} />
      </Textfield>
    </div>
    <div class="col-4 label">{$_('descrizione_breve')}</div>
    <div class="col-8">
      <Textfield
        bind:value={product.short_description}
        class="w100 my-2"
        input$maxlength="34"
        label="Descrizione breve del prodotto"
        type="text"
        variant="outlined"
      >
        <CharacterCounter text={product.short_description} max={34} />
      </Textfield>
    </div>
    <div class="col-4 label"></div>
    <div class="col-8 label d-flex justify-content-start pt-2">
      <span class="label">{$_('il_prodotto_include')}</span>
    </div>
    <div class="col-4 label">{$_('descrizione')}</div>
    <div class="col-8">
      <div class="row d-flex align-items-center">
        {#each product.description as bulletPoint, index}
          <div class="col-11">
            <Textfield
              bind:value={bulletPoint}
              class="w100 my-2"
              input$maxlength="36"
              type="text"
              variant="outlined"
            >
              <CharacterCounter text={bulletPoint} max={36} />
            </Textfield>
          </div>
          <div class="col-1 pl-1">
            {#if index == product.description.length - 1 && product.description.length < 8}
              <span class="material-icons cursorPointer mt-2" on:click={() => {
                product.description.push('');
                product.description = product.description;
              }}>
                add_circle_outline
              </span>
            {:else}
              <span class="material-icons cursorPointer mt-2" on:click={() => {
                product.description.splice(index, 1);
                product.description = product.description;
              }}>
                delete
              </span>
            {/if}
          </div>
        {/each}
      </div>
    </div>
    <div class="col-4 label">{$_('tipo_di_pagamento')}</div>
    <div class="col-8">
      <Select
        enhanced
        variant="outlined"
        class="w100 mb-2"
        label=""
        bind:value={product.payment_type}>
        {#each paymentTypeOptions as { value, label }}
          <Option {value} selected={product.payment_type === value}>
            {label}
          </Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label">{$_('prezzo_finale')}</div>
    <div class="col-8">
      <div class="row">
        <div class="col-5">
          <label class="mdc-text-field w100 my-2 mdc-text-field--outlined mdc-text-field--no-label">
            <input
              class="mdc-text-field__input"
              placeholder="Es. 199,00"
              bind:value={product.price}
              type="number" 
              min="0"
              max="9999.99"
              maxlength="6"
              step=".01"
              />
            <div class="mdc-notched-outline mdc-notched-outline--no-label">
              <div class="mdc-notched-outline__leading"></div>
              <div class="mdc-notched-outline__trailing"></div>
            </div>
          </label>
        </div>
        {#if showPriceOptions}
          <div class="col-1 label">{$_('ogni')}</div>
          <div class="col-6">
            <Select
              enhanced
              variant="outlined"
              class="w100 mb-2"
              label=""
              bind:value={product.price_option}>
              {#each priceOptions as { value, label }}
                <Option {value} selected={product.price_option === value}>
                  {label}
                </Option>
              {/each}
            </Select>
        </div>
        {/if}
      </div>
    </div>
    <div class="col-4 label originalPrice">
      <span>{$_('prezzo_barrato')}</span>
      <SvelteTooltip tip={$_('prezzo_barrato_tooltip')} top>
        <Icon role="button" tabindex="1" data-toggle="tooltip" data-placement="top" class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing">
          info
        </Icon>
      </SvelteTooltip>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-5">
          <label class="mdc-text-field w100 my-2 mdc-text-field--outlined mdc-text-field--no-label">
            <input
              class="mdc-text-field__input"
              placeholder="Es. 249,00"
              bind:value={product.original_price}
              type="number" 
              min="0"
              max="9999.99"
              maxlength="6"
              step=".01"
              />
            <div class="mdc-notched-outline mdc-notched-outline--no-label">
              <div class="mdc-notched-outline__leading"></div>
              <div class="mdc-notched-outline__trailing"></div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="col-4 label">{$_('assegna_ai_gruppi')}</div>
    <div class="col-8 pt-2">
      <AddGroups
        search={group.name}
        on:groupSelected={handleGroupSelected}
        on:groupRemoved={handleGroupRemoved}
        groupSelected={group}
        bind:groupsAdded
      />
    </div>
    <div class="col-4 label">{$_('messaggio_di_ringraziamento')}</div>
    <div class="col-8">
      <Textfield
        textarea
        bind:value={product.thank_you_message}
        class="fullWidth my-2"
        input$maxlength="500"
        label="Grazie per aver acquistato il mio prodotto"
        type="text"
        variant="outlined"
      >
        <CharacterCounter text={product.thank_you_message} max={150} />
      </Textfield>
    </div>
  </div>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-4 label">{$_('link_post_acquisto')}</div>
    <div class="col-8 d-flex justify-content-center align-items-center">
      <div class="w100">
        <Textfield
          id="linkInput"
          bind:value={product.url_after_purchase}
          variant="outlined"
          type="text"
          placeholder="Linkhttp"
          class={`textbox w100 my-2 ${linkInvalid ? 'mdc-text-field--invalid' : ''}`} />
        {#if linkInvalid}
          <FormMessage>{$_('il_link_inserito_non_è_valido')}</FormMessage>
        {/if}
      </div>
      <SvelteTooltip tip="{$_('apri_link')}" top >
        <IconButton
          class="material-icons ml-2"
          style="align-self: center"
          disabled={linkInvalid}
          href={fixUrl(product.url_after_purchase)}
          target="_blank">
          share
        </IconButton>
      </SvelteTooltip>
    </div>
  </div>
  <hr />

  <div class="row pushToEnd">
    <Button class="btn" on:click={() => exitDialog.open()}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-3 mr-3"
      variant="unelevated"
      on:click={sendToReviewDialog.open()}
      disabled={!isFormValid}>
      <Label>
        {product.id ? $_('salva') : $_('crea')}
      </Label>
    </Button>
  </div>
</div>

<style>
  * :global(.shareField .tooltip-wrapper) {
    display: flex; 
    align-items: center;
  }

  * :global(.emailField .tooltip-wrapper) {
    width: 100%;
  }

  * :global(.originalPrice .tooltip) {
    width: 30em;
    white-space: normal;
    line-height: 1.5em;
  }

  * :global(.emailField .tooltip.top) {
    left: 50%;
    transform: translate(-50%, -70%);
    margin-top: 8px;
  }

  * :global(.menuSurfaceCustom) {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    color: #fff;
    min-width: 80%;
  }

  * :global(.mdc-menu-surface--open) {
    background-color: rgba(255, 255, 255, 1);
  }

  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.mdc-chip) {
    background-color: #fff;
    height: auto;
    min-height: 28px;
  }
  * :global(.mdc-button__label) {
    text-align: left;
  }
  * :global(.paid_checkbox input) {
    width: 2em;
    height: 2em;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  .label {
    text-align: end;
    align-self: center;
    opacity: 0.8;
    font-weight: 600;
  }

  /* * :global(.svelte-nbzcn .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon) {
    background: white;
    padding-right: 10px;
    margin-right: -14px;
  }
  * :global(.select_duration .mdc-select__selected-text) { 
    border-bottom-color: #fff;
  }
  * :global(.select_duration .mdc-select__dropdown-icon) { 
    top: 20px;
  } */
  * :global(.icon--help) {
    color: rgba(0, 0, 0, 0.54) !important;
  }
  * :global(.tags-textfield) {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
  }
  
  * :global(.tags-textfield input) {
    height: 2.2rem !important;
  }

  * :global(.textUppercase) {
    text-transform: uppercase;
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
</style>