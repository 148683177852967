import { writable, derived } from "svelte/store";
import * as persistentLocal from "svelte-persistent-store/dist/local";
import { generateUID } from "./utils";

export const loading = writable(false);

// Check if jwt is corrupted (not an object OR not between quotation marks) before initialization
// to avoid white screen issue
if (localStorage.getItem('jwt') !== null) {
  try {
    let x = localStorage.getItem('jwt');
    if (!(x[0] === "\"" && x[x.length - 1] === "\"")) {
      localStorage.setItem('jwt', "");
    }
  } catch (e) {
    console.log('error checking jwt');
  }
}

export const jwt = persistentLocal.writable("jwt", "");

export const user = persistentLocal.writable("user", "");
export const userData = persistentLocal.writable("userData", "");

// export const subscriptionStatus = persistentLocal.writable(
//   "subscriptionStatus",
//   {}
// );

export const subscription = writable({});
export const subscriptionInfo = writable({});

export const notificationStatus = persistentLocal.writable(
  "notificationStatus",
  {}
);

export const tags = persistentLocal.writable("tags", []);
export const vodTags = persistentLocal.writable("vodTags", []);

export const groups = persistentLocal.writable("groups", []);
export const allGroups = writable([]);
export const allCustomers = writable([]);

export const customersGroupRelations = persistentLocal.writable("customersGroupRelations", []);

// export const vods = persistentLocal.writable("vods", []);

export const playlists = persistentLocal.writable("playlists", []);

export const vodsPlaylistRelations = persistentLocal.writable("vodsPlaylistRelations", []);

// export const vodsTotalPages = persistentLocal.writable(
//   "vodsTotalPages",
//   1
// );

// export const vodsActivePage = persistentLocal.writable(
//   "vodsActivePage",
//   0
// );

export const exercises = persistentLocal.writable("exercises", []);

export const exercisesTotalPages = persistentLocal.writable("exercisesTotalPages", 1);
export const exercisesActivePage = persistentLocal.writable("exercisesActivePage", 0);
export const exercisesActiveSearch = persistentLocal.writable("exercisesActiveSearch", "");
export const exercisesActiveOrder = persistentLocal.writable("exercisesActiveOrder", "");
export const exercisesActiveTagsFilter = persistentLocal.writable("exercisesActiveTagsFilter", []);
export const exercisesActiveDropdownTagFilter = persistentLocal.writable("exercisesActiveDropdownTagFilter", []);

export const sortableExercises = derived(exercises, ($exercises) =>
  $exercises.map((ex) => ({
    id: ex.id,
    base: ex.base,
    name: ex.name,
    image: ex.image,
    image_thumbnail: ex.image_thumbnail,
    image_label: ex.image_label,
    tags: ex.tags,
    video: ex.video,
    video_thumbnail: ex.video_thumbnail,
    video_youtube: ex.video_youtube,
    sortId: generateUID(),
    note: ex.note ? ex.note : "",
    details: [
      {
        sets: "",
        reps: {
          format: "standard",
          type: "reps",
          value: "",
          value_high: "",
        },
        weight: {
          format: "standard",
          type: "kg",
          value: "",
          value_high: "",
        },
        rest: "",
        tut: "",
      },
    ],
  }))
);

export const customer = persistentLocal.writable("customer", {});
export const group = persistentLocal.writable("group", {});

export const workouts = persistentLocal.writable("workouts", []);
export const trainingCard = persistentLocal.writable("trainingCard", {});
export const backToWorkoutsTab = persistentLocal.writable("backToWorkoutsTab", false);
export const progressions = persistentLocal.writable("progressions", []);
export const progressionsPage = persistentLocal.writable("progressionsPage", 0);
export const vodsQuantity = persistentLocal.writable("vodsQuantity", 0);

export const autoSavingProgressions = persistentLocal.writable("autoSavingProgressions", false);
export const autoSavingWorkouts = persistentLocal.writable("autoSavingWorkouts", false);
export const loadingProgressions = persistentLocal.writable("loadingProgressions", false);
export const loadingWorkouts = persistentLocal.writable("loadingWorkouts", false);

export const activeSection = persistentLocal.writable("activeSection", "");
export const activeSubSection = persistentLocal.writable("activeSubSection", "");
export const previousSection = persistentLocal.writable("previousSection", "");
export const previousSubSection = persistentLocal.writable("previousSubSection", "");

// Vod/playlist assignment
export const activeAssignment = persistentLocal.writable("activeAssignment", {});

export const allCombinations = derived(workouts, ($workouts) =>
  $workouts.map((w) => w.combinations).flat()
);

export const workoutExercises = derived(allCombinations, ($allCombinations) =>
  $allCombinations.map((w) => w.exercises).flat()
);

export const allExercises = derived(
  [workoutExercises, sortableExercises],
  ([$workoutExercises, $sortableExercises]) =>
    $workoutExercises.concat($sortableExercises)
);

export const oneSignalPlayerId = persistentLocal.writable("oneSignalPlayerId", "");

// Chat
export const chatUserId = persistentLocal.writable("chatUserId", "");
export const chatInitialized = persistentLocal.writable("chatInitialized", false);