<script>
	import { isFeatureEnabled } from "../../services/subscriptionServices";
	import { subscription } from "../../lib/store";
	import { startOnboardProcess } from "../../services/productServices";
  import { _ } from "../../services/i18n";
  import { createEventDispatcher, tick } from "svelte";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import Button, { Label } from "@smui/button";
  import Card, { Content } from "@smui/card";
  import SubscriptionDialog from "../PtInfo/Billing/SubscriptionDialog.svelte";
  
  const dispatch = createEventDispatcher();

  export let biggerText;
  export let buttonText;
  export let icon;
  export let pathToNavigateTo = "";
  export let routeState = "";
  export let upperText;
  export let startConnectOnboarding = false;

  let featureDisabled = false;
  let showSubscriptionDialog = false;
  let trigger = "customers";

  const handleButtonClicked = async () => {
    if (buttonText == $_('nuova_progressione')) {
      dispatch("addProgression");
    } 
    else if (featureDisabled) {
      showSubscriptionDialog = true;
    }
    else if (startConnectOnboarding) {
      let onboardProcessLink = await startOnboardProcess();
      await tick();
      window.location.replace(onboardProcessLink);
    } 
    else {
      routeState ? navigate(pathToNavigateTo, routeState) : navigate(pathToNavigateTo);
    }
  };

  $: switch (pathToNavigateTo) {
    case 'revoo_pay_onboarding' :
    case 'product' : 
    case '/product' : 
      featureDisabled = !isFeatureEnabled('revoo_pay', $subscription); 
      trigger = 'revoo_pay';
      break;

    case 'new-video' : 
    case '/new-video' : 
      featureDisabled = !isFeatureEnabled('vods', $subscription);
      trigger = 'vods';
      break;

    case 'new-customer' : 
    case '/new-customer' : 
      featureDisabled = !isFeatureEnabled('customers', $subscription); 
      trigger = 'customers';      
      break;
  }

  $: if (featureDisabled) {
    showSubscriptionDialog = true;
  }

  const handleCloseDialog = () => {
    showSubscriptionDialog = false;
  };
</script>

{#if showSubscriptionDialog}
  <SubscriptionDialog 
    state={'upgrade_plan'}
    trigger={trigger}
    on:closeDialog={handleCloseDialog} 
  />
{/if}

<div class="flexy">
  <Card class="invisibleCard h100">
    <Content>
      <div class="row">
        {#if upperText}
          <span class="col-12 py-3 upperText demiBold flexy"> {upperText} </span>
        {/if}
        {#if biggerText}
          <span class="col-12 py-3 bold headline flexy"> {biggerText} </span>
        {/if}
        {#if (buttonText && pathToNavigateTo) || (buttonText && startConnectOnboarding)}
          <div class="col-12 py-3 flexy">
            <Button
              color="primary"
              variant="unelevated"
              on:click={handleButtonClicked}>
              <Label>
                <Icon
                  role="button"
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing">
                  {icon}
                </Icon>
                {buttonText}
              </Label>
            </Button>
          </div>
        {/if}
      </div>
    </Content>
  </Card>
</div>

<style>
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }

  .headline {
    font-size: 2.5rem;
    text-align: center;
  }

  .upperText {
    font-size: 1.25rem;
    text-align: center;
  }
</style>