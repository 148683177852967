<script>
  import "flatpickr/dist/flatpickr.css";
  import "flatpickr/dist/themes/light.css";
  import { _, locale } from "../../services/i18n";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { Icon } from "@smui/common";
  import { Italian } from "flatpickr/dist/l10n/it.js";
  import flatpickr from "flatpickr";
  import IconButton from "@smui/icon-button";
  import moment from "moment";
  import SvelteTooltip from "svelte-tooltip";
  import { trainingCard } from "../../lib/store";

  const dispatch = createEventDispatcher();
  const jq = window.$;

  export let customerId = "";
  export let dateFormat = "";
  export let defaultDate;
  export let disabled = false;
  export let enabledDates = [];
  export let invokeRefreshCalendar = false;
  export let item;
  export let maxDate = Date.now();
  export let minDate;
  export let placeholder = "";
  export let width = "50";
  export let withClearButton = false;
  export let withDeleteButton = false;

  let calendar;
  let datePicker;
  let enabledDatesCache = [];
  let minDateCache = minDate;
  let selectedDate;
  let wrap = true;

  $: selectedDate = defaultDate ? defaultDate : "";

  $: if (typeof enabledDates == "boolean")
    enabledDates = [
      () => {
        return false;
      },
    ];

  $: if (placeholder && calendar) {
    document.getElementsByClassName(
      "textbox flatpickr-input"
    ).placeholder = placeholder;
  }

  let flatpickrOptions;
  $: flatpickrOptions = {
    enable: enabledDates,
    defaultDate,
    wrap: wrap,
    element: "#progress-picker",
    minDate: minDate
      ? moment(minDate).toISOString()
      : moment().subtract(90, "years").toISOString(),
    maxDate,
    dateFormat: "d/m/Y",
    locale: $locale == "it" ? Italian : "en",
    // static: true, // su mobile il calendario potrebbe venire nascosto
    disableMobile: true, //blocca il data input nativo mobile matenendo quello di flatpickr, evitando cosi il problema dei campi mancanti su mobile
    onChange: (selectedDates, dateStr, instance) => {
      if (dateStr != selectedDate) {
        selectedDate = moment(dateStr, "DD/MM/YYYY").format(
          dateFormat ? dateFormat.toUpperCase() : "DD/MM/YYYY"
        );
        dispatch("date", { date: selectedDate, dateFormat });
      }
    },
  };

  $: if (enabledDates.length > 0 && enabledDates != enabledDatesCache) {
    refreshCalendar();
    enabledDatesCache = enabledDates;
  }

  $: if (minDate && minDate != minDateCache) {
    refreshCalendar();
    minDateCache = minDate;
  }

  //refreshCalendar per ripulire il selectData dell'ultima istanza aperta
  $: if (invokeRefreshCalendar) {
    if (calendar && selectedDate != moment().format("DD/MM/YYYY")) {
      refreshCalendar();
    }
    invokeRefreshCalendar = false;
  }

  const refreshCalendar = () => {
    calendar.destroy();
    calendar = flatpickr(datePicker, flatpickrOptions);
    document.getElementsByClassName("textbox flatpickr-input").readOnly = true;
  };

  const clearDate = () => {
    dispatch('clearDate');
  };

  const deleteItem = () => {
    if (item === 'composition') {
      dispatch('deleteComposition', { selectedDate });
    }
    if (item === 'circumference') {
      dispatch('deleteCircumferences', { selectedDate });
    }
  };

  onMount(() => {
    calendar = flatpickr(datePicker, flatpickrOptions);
    document.getElementsByClassName("textbox flatpickr-input").readOnly = true;
  });
</script>

<div class={`flatpickr w${width}`} id="progress-picker" bind:this={datePicker}>
  <label
    class={`mdc-text-field w${width} mdc-text-field--outlined mdc-text-field--no-label mdc-text-field--with-trailing-icon
    `}
    aria-haspopup="true"
  >
    <Icon
      class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing"
    >
      event
    </Icon>
    <input
      variant="outlined"
      {disabled}
      class={`mdc-text-field__input textbox w${width}`}
      bind:value={selectedDate}
      withTrailingIcon
      type="text"
      data-input
    />
    <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
      <div class="mdc-notched-outline__leading" />
      <div class="mdc-notched-outline__notch" />
      <div class="mdc-notched-outline__trailing" />
    </div>
    {#if withClearButton && selectedDate != ""}
      <div on:click|preventDefault|stopPropagation={() => clearDate()}>
        <IconButton class="material-icons" style="height: 100%; margin-right: 45px; color:var(--primary);">
          clear
        </IconButton>
      </div>
    {/if}
  </label>
</div>
{#if withDeleteButton && selectedDate != "" && customerId != "" && item != ""}
  <SvelteTooltip tip={$_('elimina')} bottom>
    <div on:click|preventDefault|stopPropagation={deleteItem}>
      <IconButton class="material-icons" style="height: 100%; color:var(--primary);">
        delete
      </IconButton>
    </div>
  </SvelteTooltip>
{/if}

<style>
  *
    :global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__icon) {
    align-self: center;
    color: rgba(33, 33, 33, 0.8);
    /* padding-right: 0.5rem; */
  }

  * :global(.mdc-text-field--outlined .mdc-text-field__input) {
    padding: 0.75rem 0 0.75rem 1rem;
  }
</style>
