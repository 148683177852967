<script>
  import { chatUserId, chatInitialized, jwt, user } from "../../lib/store";
  import { CometChat } from "@cometchat-pro/chat";
  import { getCometChatUserId } from "../../services/chatServices.js";
  import { onMount } from "svelte";


  let chatIsMounted = false;
  let cometChatUserId = null;
  let appID = env.COMETCHAT_APP_ID;
  let region = "eu";
  let appSetting = 
    new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .autoEstablishSocketConnection(true)
      .build();

  $: if ($jwt != null && chatIsMounted == false) {
    cometChatLogin();
  }

  const cometChatLogin = async () => {
    if (jwt && $user.chat_enabled) {
      chatIsMounted = true;
      cometChatUserId = await getCometChatUserId($user.id);
      console.log('cometChatLogin() | cometChatLogin => ', cometChatUserId);
      if (cometChatUserId != null) {
        chatUserId.set(cometChatUserId);
        CometChat.init(appID, appSetting).then(() => {
          console.log("Initialization completed successfully");
        }, error => {
          console.log("Initialization failed with error:", error);
        });

        const userSettings = {
          "user-settings": {
              "dnd": true,
              "chat": {
                  "allow_only_mentions": true,
                  "mute_group_actions": false,
                  "mute_all_guids": false,
                  "mute_all_uids": false
              },
              "call": {
                  "mute_all_guids": false,
                  "mute_all_uids":false
              }
          }
        };

        CometChat.getLoggedinUser().then(
            (user) => {
                if(!user){
                    CometChat.login(cometChatUserId, env.COMETCHAT_AUTH_KEY).then(user => {
                        console.log("Login Successful:", { user });    
                        CometChat.callExtension('push-notification', 'POST', 'v1/user-settings', userSettings).then(response => {
                            console.log('e siamo qua', response);
                        })
                        .catch(error => {
                            console.log('e siamo qua 2', error);
                        });
                      }, error => {
                        console.log("Login failed with exception:", { error });    
                      }
                    );
                }
            }, error => {
                console.log("Some Error Occured", { error });
            }
        );

        /**
         * 
        */
        initDocketChatWidget();
      } else {
        console.log('REVOO Chat UserID not found');
      }
    }
  };
  
  const initDocketChatWidget = () => {
    const cometChatUser = new CometChatWidget.CometChat.User(cometChatUserId);
    console.log(cometChatUser);
    CometChatWidget.init({
      "appID": env.COMETCHAT_APP_ID,
      "appRegion": "eu",
      "authKey": env.COMETCHAT_AUTH_KEY
    }).then(response => {
      console.log("Initialization completed successfully");
      //You can now call login function.
      CometChatWidget.login({
        "uid": cometChatUserId,
      }).then(response => {
        $chatInitialized = true;
        CometChatWidget.launch({
          "widgetID": env.COMETCHAT_WIDGET_ID,
          "docked": "true",
          "alignment": "left", //left or right
          "roundedCorners": "true",
          "height": "450px",
          "width": "400px",
          "defaultType": 'user',
        }).then(response => {
          CometChatWidget.on("onMessageReceived", (message) => {
            console.log("CometChatWidget onMessageReceived", message);
          });
        });
      }, error => {
        $chatInitialized = false;
        console.log("User login failed with error:", error);
        //Check the reason for error and take appropriate action.
      });
    }, error => {
      $chatInitialized = false;
      console.log("Initialization failed with error:", error);
      //Check the reason for error and take appropriate action.
    });
  }
</script>