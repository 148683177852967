<script>
	import { _ } from "../../../../services/i18n";
	import { exportTransactions, fetchTransactions, getStripeAccount, getTransactions } from "../../../../services/productServices";
	import { onMount, tick } from "svelte";
	import TransactionsWrapper from "./TransactionsWrapper.svelte";
	import * as FileSaver from "file-saver";
	  
	export let number;


	let downloading = false;
	let lastUpdatedValue = 0;
	let list = [];
	let loading = true;
	let stripeAccount = false;
	let title = $_("abbonamenti");

	const handleUpdateList = async (e) => {
		loading = true;
		list = await getTransactions();
		number = list.length;
		loading = false;
	}

	const handleFetchList = async (e) => {
		loading = true;
		await fetchTransactions();
		lastUpdatedValue = 0;
		loading = false;
		handleUpdateList();
	}

	const handleExportList = async (e) => {
		downloading = true;
		const url = await exportTransactions();
		FileSaver.saveAs(url);
		downloading = false;
	}

	onMount(async () => {
		stripeAccount = await getStripeAccount();
		list = await getTransactions();
		number = list.length;
		loading = false;
	});
</script>

<div class="w100 pt-4 pt-4">
	<TransactionsWrapper 
		{downloading} 
		{lastUpdatedValue} 
		{list} 
		{loading} 
		{stripeAccount} 
		{title} 
		on:exportList={handleExportList}
		on:fetchList={handleFetchList} 
		on:updateList={handleUpdateList} 
	/>
</div>

<style>
</style>