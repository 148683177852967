<script>
  import { _, locale } from "../../../../services/i18n";

  export let measure;
</script>

<style>
  .container-image {
    background-color: #fff;
    background-image: url(/assets/human-shape.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 37.5rem;
    position: relative;
    width: 12.5rem;
  }

  .hide {
    display: none;
  }
  .show {
    display: block;
  }

  .body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
  }

  .body__chest {
    position: absolute;
    top: 25%;
  }

  .body__biceps {
    position: absolute;
    top: 30%;
  }

  .body__waist {
    position: absolute;
    top: 39%;
  }

  .body__hip {
    position: absolute;
    top: 48%;
  }

  .body__legs {
    position: absolute;
    bottom: 30%;
  }

  .body__calfs {
    position: absolute;
    bottom: 15%;
  }

  @media (max-width: 767px) {
    .container-image {
      margin: 0 auto;
      max-width: 18rem;
      height: 25rem;
    }
    .body__chest { top: 4.7rem;}
    .body__biceps { top: 6rem;}
    .body__waist { top: 7.8rem;}
    .body__hip { top: 11rem;}
  }
</style>

<div class="col-12 container-image">
  <div class="row no-gutters justify-content-center body body__chest">
    <div class={`${!measure.chest ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('petto')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.chest ? measure.chest : ''} cm`}
      </div>
    </div>
  </div>

  <div class="row no-gutters body body__biceps">
    <div class={`${!measure.bicep_r ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('bicipite_dx')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.bicep_r ? measure.bicep_r : ''} cm`}
      </div>
    </div>
    <div class={`${!measure.bicep_l ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('bicipite_sx')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.bicep_l ? measure.bicep_l : ''} cm`}
      </div>
    </div>
  </div>

  <div class="row no-gutters justify-content-center body body__waist">
    <div class={`${!measure.waist ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('vita')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.waist ? measure.waist : ''} cm`}
      </div>
    </div>
  </div>

  <div class="row no-gutters justify-content-center body body__hip">
    <div class={`${!measure.hip ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('fianchi')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.hip ? measure.hip : ''} cm`}
      </div>
    </div>
  </div>

  <div class="row no-gutters body body__legs">
    <div class={`${!measure.leg_r ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('coscia_dx')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.leg_r ? measure.leg_r : ''} cm`}
      </div>
    </div>
    <div class={`${!measure.leg_l ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('coscia_sx')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.leg_l ? measure.leg_l : ''} cm`}
      </div>
    </div>
  </div>

  <div class="row no-gutters body body__calfs">
    <div class={`${!measure.calf_r ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('polpaccio_dx')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.calf_r ? measure.calf_r : ''} cm`}
      </div>
    </div>
    <div class={`${!measure.calf_l ? 'hide' : 'show'}`}>
      <div class="mdc-typography--body2">{$_('polpaccio_sx')}</div>
      <div class="mdc-typography--headline6">
        {`${measure.calf_l ? measure.calf_l : ''} cm`}
      </div>
    </div>
  </div>
</div>
