<script>
  import { _ } from "../../services/i18n";
  import PtProfileInfo from "./PtProfileInfo.svelte";
  import NewCustomerImageUploader from "../Team/Customer/NewCustomer/NewCustomerImageUploader.svelte";
  import { updateProfileInfo } from "../../services/userServices";
  import { user } from "../../lib/store";

  let profileImage = $user.image;
  let logo = $user.logo;
  let selectedImage = $user.image;
  let selectedLogo = $user.logo;
  let imageValid = true;
  let msgError;

  const handleImageValidity = (e) => {
    imageValid = e.detail.valid;
    if (e.detail.image) selectedImage = e.detail.image;
  };

  const handleLogoValidity = (e) => {
    if (e.detail.image) selectedLogo = e.detail.image;
  };

  const handleSaveInfo = async (e) => {
    try {
      const body = {
        ...e.detail,
        image: selectedImage,
        photo_logo: selectedLogo,
      };
      const res = await updateProfileInfo(body);
      $user.bio                = res.bio;
      $user.bussiness_nickname = res.business_nickname;
      $user.country_code       = res.prefix;
      $user.directContact      = res.directContact;
      $user.email              = res.email;
      $user.facebook           = res.facebook;
      $user.first_name         = res.name;
      $user.instagram          = res.instagram;
      $user.image              = res.image;
      $user.last_name          = res.surname;
      $user.logo               = res.photo_logo;
      $user.online             = res.online;
      $user.phone              = res.phone;
      $user.show_phone_number  = res.show_phone_number;
    } catch (err) {
      console.log("handleSaveInfo err", err);
      msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 4000);
    }
  };
</script>

<style>
  * :global(.customCard) {
    border: none;
    margin: 0 auto;
    text-align: center;
  }

  * :global(.customUploader) {
    background-color: #f3f3f3;
    border-radius: 50%;
    height: 200px;
    overflow: hidden;
    width: 200px;
  }

  * :global(.mdc-card__media:first-child) {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
</style>

<div class="row py-4">
  {#if msgError}
    <div class="col-12 alert alert-danger fade show" role="alert">
      {msgError}
    </div>
  {/if}

  <div class="col-xs-12 col-lg-4">
    <NewCustomerImageUploader
      bind:profileImage
      on:imageValidityChange={handleImageValidity} />
    <NewCustomerImageUploader
      hasLogo={true}
      bind:logo
      on:imageValidityChange={handleLogoValidity} />
  </div>
  <div class="col-xs-12 col-lg-8">
    <PtProfileInfo {imageValid} on:saveInfo={handleSaveInfo} />
  </div>
</div>
