<script>
  import { _ } from "../../services/i18n";
  import { autoSavingProgressions, autoSavingWorkouts, loading, loadingProgressions, loadingWorkouts } from "../../lib/store";
  import { backToWorkoutsTab } from "../../lib/store";
  import { createEventDispatcher } from "svelte";
  import LoadingInline from "../ReusableLayouts/LoadingInline.svelte";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  
  const dispatch = createEventDispatcher();

  export let tabs;
  export let activeTab;
  export let tabNavigationEnabled;

  let switchEnabled = true;

  $: if ($backToWorkoutsTab === true) {
    activeTab = $_('schede');
    $backToWorkoutsTab = false;
  }

  $: {
    if (tabNavigationEnabled && activeTab)
      dispatch("activeTabChanged", { tab: activeTab });
  }

  $: if ((activeTab === $_("progressioni") && ($autoSavingWorkouts || $loadingProgressions)) || 
         (activeTab === $_("schede")       && ($autoSavingProgressions || $loadingWorkouts))) {
    switchEnabled = false;
  } else {
    switchEnabled = true;
  }
</script>

<style>
  * :global(.mdc-tab--active .mdc-tab__text-label) {
    color: #212121;
    font-weight: bold !important;
    opacity: 0.8 !important;
  }

  * :global(.mdc-tab .mdc-tab__text-label) {
    display: flex;
    color: #212121;
    font-weight: 500;
    opacity: 0.5;
  }

  * :global(.spinner) {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: inherit;
    bottom: inherit;
    margin-left: 0.3rem;
  }
</style>

<div class="row no-gutters">
  <div class="col-12">
    <TabBar {tabs} let:tab bind:active={activeTab}>
      <Tab {tab} minWidth disabled={!tabNavigationEnabled || !switchEnabled}>
        <Label>
          {#if tab === $_("info_piano") || tab === $_("info_abbonamento") || tab === $_("info_prodotto")}
            {tab}
          {:else if tabNavigationEnabled}
            {tab} 
            {#if (activeTab === $_("progressioni") && tab === $_("progressioni") && ($autoSavingWorkouts || $loadingProgressions)) || 
                 (activeTab === $_("schede")       && tab === $_("schede")       && $autoSavingProgressions)} 
              <LoadingInline /> 
            {/if}
          {/if}
        </Label>
      </Tab>
    </TabBar>
  </div>
</div>
