<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { exercisesActiveDropdownTagFilter, exercisesActivePage, user } from "../../lib/store";
  import { navigate } from "svelte-routing";
  import Card, { Content } from "@smui/card";
  import Checkbox from "@smui/checkbox";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import SvelteTooltip from 'svelte-tooltip';
  
  export let activePage;
  export let allSelected;
  export let activeDropdownTagFilter;
  export let exercises = [];
  export let selected = [];
  export let showButton;

  const dispatch = createEventDispatcher();

  let confirmationDeleteDialog;
  let idOfExerciseToBeDeleted;
  let showMobileIcons = undefined;

  const handleDeleteButtonClicked = (id) => {
    idOfExerciseToBeDeleted = id;
    confirmationDeleteDialog.open();
  };

  const handleDeleteConfirmation = () => {
    dispatch("deleteExercise", {
      id: idOfExerciseToBeDeleted,
    });
    confirmationDeleteDialog.close();
  };

  const selectAll = (bool) => {
    if (bool) {
      for (const ex of exercises) {
        if (!ex.base) selected = [ex.id, ...selected];
      }
    } else selected = [];
  };

  const openExercise = (exercise) => {
    activePage > 0 ? exercisesActivePage.set(activePage) : exercisesActivePage.set(0);
    if (Array.isArray(activeDropdownTagFilter) && activeDropdownTagFilter.length > 0) exercisesActiveDropdownTagFilter.set(activeDropdownTagFilter);
    navigate('exercise', { state: { exercise } });
  }

  $: selectAll(allSelected);
  $: {
    if (selected.length > 0) showButton = true;
    else showButton = false;
  }

</script>

<style>
  * :global(.primary) {
    color: var(--primary);
  }

  .imageContainer {
    height: 68px;
    max-width: 100%;
    width: auto;
  }

  .imageContainer > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .imageContainer > .image__placeholder {
    background: #fff url("/assets/placeholder_logo.png") no-repeat center;
    background-size: 5rem;
    height: 68px;
    max-height: 100%;
    max-width: 100%;
    width: 9.5rem;
  }

  .exercisePreviewImg {
    border-radius: 0.2rem;
  }

  .textWrapper {
    display: flex;
    align-items: center;
    opacity: 0.8;
    word-break: break-word;
  }
  .bold {
    font-weight: 600;
  }
  .tagWrapper {
    justify-content: center;
  }
  .center {
    align-items: center;
  }

  .hide {
    display: none;
  }
  @media (max-width: 767px) {
    .desktop {
      display: none;
    }
    * :global(.smui-card__content) {
      padding: 1rem 5px;
    }
    .menu-icons {
      position: absolute;
      right: 1rem;
    }
    .menu-icons.menu-icons--opened {
      background-color: #fff;
      border-radius: 4px;
      position: absolute;
      right: 1rem;
    }

    .icon--close {
      order: 1;
    }
    .icon--edit.menu-icons--opened {
      order: 2;
    }
    .icon--copy {
      order: 12;
    }
  }
  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }
</style>

<Dialog bind:this={confirmationDeleteDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_l_esercizio')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => confirmationDeleteDialog.close()}
    on:onConfirmation={handleDeleteConfirmation} />
</Dialog>

<div class="container fluid">
  {#each exercises as exercise, i}
    <Card class="mb-2 position-relative">
      <Content>
        <div class="row center">
          <div class="col-2 col-sm-2 col-md-1 col-lg-1">
            {#if !exercise.base}
              <Checkbox bind:group={selected} value={exercise.id} />
            {/if}
          </div>
          <div class="col-4 col-xs-4 col-md-2 col-lg-2 textWrapper">
            <div class="imageContainer d-flex align-items-center">
              {#if exercise.image_thumbnail || (exercise.video_thumbnail && exercise.video && !exercise.video.toLowerCase().endsWith('mov'))}
                <img
                  class="exercisePreviewImg"
                  loading="lazy"
                  src={exercise.image_thumbnail ? exercise.image_thumbnail : exercise.video_thumbnail}
                  alt="" />
              {:else if (exercise.video && exercise.video_thumbnail_emc_status === 0)}
                <Loading mod="exercise" />
              {:else if exercise.base}
                <div class="image__placeholder" />
              {/if}
            </div>
          </div>
          <div
            class="col-4 col-md-3 col-lg-3 bold textWrapper"
            on:click={() => openExercise(exercise)}
            style="cursor: pointer">
            {exercise.name}
          </div>
          <div class="col-4 col-md-4 col-lg-4 textWrapper tagWrapper desktop">
            {exercise.tags ? exercise.tags.map((i) => i.name).join(', ') || '-' : '-'}
          </div>
            <div
              class={`flexy menu-icons d-block d-sm-none${showMobileIcons === i ? 'menu-icons--opened' : ''}`}
              on:focusout={(e) => {
                if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
                showMobileIcons = undefined;
              }}>
              <div
                class={`icon--action icon--edit ${showMobileIcons === i ? 'menu-icons--opened' : ''}`}
                on:click|stopPropagation={() => (showMobileIcons === i ? navigate('exercise', { state: { exercise } }) : (showMobileIcons = i))}>
                <IconButton
                  class="material-icons p-0 ml-3"
                  style="width: fit-content">
                  {showMobileIcons === i ? 'edit' : 'more_vert'}
                </IconButton>
              </div>
              <div class="bg__color flexy">
                {#if !exercise.base || $user.is_admin}
                  <div
                    class="icon--action icon--close"
                    class:hide={showMobileIcons != i}
                    on:click={() => {
                      if (!selected.includes(exercise.id)) handleDeleteButtonClicked(exercise.id);
                    }}>
                    <IconButton
                      data-mobile-icon
                      class={`material-icons ${!selected.includes(exercise.id) ? 'primary' : ''}`}
                      style="width: fit-content"
                      disabled={selected.includes(exercise.id)}>
                      delete
                    </IconButton>
                  </div>
                {/if}
                <div
                  class="icon--copy"
                  class:hide={showMobileIcons != i}
                  on:click|stopPropagation={() => {
                    if (!selected.includes(exercise.id)) navigate(
                        'new-exercise',
                        {
                          state: { exerciseToBeCloned: exercise },
                        }
                      );
                  }}>
                  <IconButton
                    data-mobile-icon
                    class="material-icons p-0 ml-3"
                    style="width: fit-content"
                    disabled={selected.includes(exercise.id)}>
                    content_copy
                  </IconButton>
                </div>
              </div>
            </div>
            <div class="col-3 col-md-2 col-lg-2 flexy d-none d-sm-block">
              {#if !exercise.base || $user.is_admin}
                <SvelteTooltip tip="{$_('elimina')}" top >
                  <IconButton
                    on:click={() => handleDeleteButtonClicked(exercise.id)}
                    disabled={selected.includes(exercise.id)}
                    class={`material-icons ${!selected.includes(exercise.id) ? 'primary' : ''}`}>
                    delete
                  </IconButton>
                </SvelteTooltip>
              {/if}
              <SvelteTooltip tip="{$_('modifica')}" top >
                <IconButton
                  on:click={() => openExercise(exercise)}
                  disabled={selected.includes(exercise.id)}
                  class="material-icons">
                  edit
                </IconButton>
              </SvelteTooltip>
              <SvelteTooltip tip="{$_('duplica')}" top >
                <IconButton
                  on:click={() => navigate('new-exercise', {
                      state: { exerciseToBeCloned: exercise },
                    })}
                  disabled={selected.includes(exercise.id)}
                  class="material-icons">
                  content_copy
                </IconButton>
              </SvelteTooltip>
            </div>
        </div>
      </Content>
    </Card>
  {/each}
</div>
