<script>
  import { _ }         from "../../services/i18n";
  import { getPlaylists, getTotalViewsPerBillingCycle, getVods, getVodsAndPlaylistsQuantity } from "../../services/vodsServices";
  import { getSubscription, getSubscriptionInfo } from "../../services/subscriptionServices";
  import { navigate }  from "svelte-routing";
  import { onMount }   from "svelte";
  import { playlists, subscription } from "../../lib/store";
  import BannerHelp    from "../ReusableLayouts/BannerHelp.svelte";
  import Header        from "../ReusableLayouts/Header.svelte";
  import HeaderOneLine from "../ReusableLayouts/HeaderOneLine.svelte";
  import Loading       from "../ReusableLayouts/Loading.svelte";
  import MsgError      from "../ReusableLayouts/MsgError.svelte";
  import PlaylistList  from "./PlaylistList.svelte";
  import VodsList      from "./VodsList.svelte";
  
  export let location;
  export let navbarDisabled = false;
  export let onLocaleChanged;
  export let showNavbar = true;
  
  let activeTab = $_("video");
  let buttonLabel;
  let buttonNavigate;
  let component = [$_("video")];
  let filtratedVods = [];
  let filtratedPlaylists = [];
  let headerProps = {};
  let itemsRow = 20;
  let loading = false;
  let loadingVods = false;
  let loadingPlaylists = false;
  let msgError = "";
  let orderBy = "name";
  let orderByPlaylists = "name";
  let orderDesc = true;
  let page = 1;
  let playlistPage = 1;
  let props = {};
  let searchName = "";
  let searchParamsTab = "video";
  let sticky = true;
  let tabs = [
    $_("video"),
    $_("playlist"),
  ];
  let totalsLoaded = false;
  let totalVods;
  let totalPages = 1;
  let totalPlaylists;
  let totalPlaylistPages = 1;
  let totalViews;

  $: totalsLoaded = totalVods && totalPlaylists;
  $: headerProps = {
    nameTitle: $_("video_allenamenti"),
    number: `${totalsLoaded ? totalVods + ' ' + $_("video") + ' | ' + totalPlaylists + ' ' + $_("playlist") : ''}`,
    search: searchName,
    totalViews: totalViews ? totalViews : '',
    buttonIcon: component == $_('video') ? "ondemand_video" : "playlist_play",
    buttonLabel: buttonLabel,
    buttonNavigate: buttonNavigate,
  };  

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: if (searchParamsTab) {
    component = [mapTabsToComponent[searchParamsTab]];
    activeTab = mapTabsToComponent[searchParamsTab];
  }

  const mapTabsToComponent = {
    video: $_("video"),
    playlist: $_("playlist"),
  };

  const mapComponentsToTab = {
    [$_("video")]: "video",
    [$_("playlist")]: "playlist",
  };

  $: if (component == $_("video")) {
    buttonLabel = $_("nuovo_video");
    buttonNavigate = "new-video";
    // getFiltratedVods();
  } else {
    buttonLabel = $_("nuova_playlist");
    buttonNavigate = "new-playlist";
  }

  // For athletes tab
  const getFiltratedVods = async () => {
    try {
      loadingVods = true;
      const name = searchName ? searchName : null;
      const { data, last_page } = await getVods(
        itemsRow,
        page,
        name,
        orderBy,
        orderDesc
      );
      totalVods = data.length;
      totalPages = last_page;
      filtratedVods = data.map((ele) => {
        ele.selected = false;
        return ele;
      });
      loadingVods = false;
    } catch (error) {
      loadingVods = false;
      console.log("getFiltratedVods error", error);
      displayError(error);
    }
  };

  const handleUpdateFiltratedVods = async (e) => {
    if (e.detail.goToInitialPage) page = 1;
    await getFiltratedVods();
  };  

  // For playlists tab
  const getFiltratedPlaylists = async () => {
    try {
      loadingPlaylists = true;
      const name = searchName ? searchName : null;
      const { data, last_page } = await getPlaylists(
        itemsRow,
        playlistPage,
        name,
        orderByPlaylists,
        orderDesc
      );
      totalPlaylists = data.length;
      totalPlaylistPages = last_page;
      filtratedPlaylists = data.map((ele) => {
        ele.selected = false;
        return ele;
      });
      playlists.set(filtratedPlaylists);
      loadingPlaylists = false;
    } catch (error) {
      loadingPlaylists = false;
      console.log("getFiltratedPlaylists error", error);
      displayError(error);
    }
  };

  const handleUpdateFiltratedPlaylists = (e) => {
    if (e && e.detail != null && e.detail.goToInitialPage) playlistPage = 1;
    getFiltratedPlaylists();
  };  

  const displayError = (err = null) => {
    let timeoutTime = 3000;
    msgError = $_("si_è_verificato_un_errore");
    if (err.message.exception && err.message.exception === 'PlanConflictException') {
      
      msgError = $_("plan_conflict_exception");
      timeoutTime = 9000000;
    }
    setTimeout(() => {
      msgError = "";
    }, timeoutTime);
  };

  const handleActiveTabChanged = (e) => {
    component = [e.detail.tab];
    navigate(`/vods?tab=${mapComponentsToTab[e.detail.tab]}`, {
      replace: true,
    });
  }

  const handleSearchChanged = async (e) => {
    searchName = e.detail.search;
    await getFiltratedVods();
  };

  const handlePlaylistSearchChanged = async (e) => {
    searchName = e.detail.search;
    await getFiltratedPlaylists();
  };

  const handleUpdateSubscriptionInfo = async () => {
    await getSubscription();
    await getSubscriptionInfo();
  }

  onMount(async () => {
    showNavbar = true;
    navbarDisabled = false;
    try {
      await getSubscription();
      await getSubscriptionInfo();
      const vodViews = await getTotalViewsPerBillingCycle();
      totalViews = vodViews;
      const quantity = await getVodsAndPlaylistsQuantity();
      totalVods = quantity.vods;
      totalPlaylists = quantity.playlists;
    } catch (error) {
      console.log("getVodsAndPlaylistsQuantity error", error);
      displayError(error);
    }
  });
</script>

<style>
  :global(.headerBackground) {
    background-color: #f9f9f9;
  }

  @media (max-width: 767px) {
    :global(.headerBackground) {
      position: -webkit-sticky;
      position: sticky;
      top: 3.4rem;
      z-index: 3;
    }
  }
</style>

{#if loading}
  <Loading />
{:else}
  <Header
    {tabs}
    {activeTab}
    {sticky}
    asTitle={false}
    asTextAndIcon={false}
    text={''}
    noBackground={false}
    subTitle={false}
    summary={false}
    component={HeaderOneLine}
    on:activeTabChanged={handleActiveTabChanged}
    on:onComponentEvent={component == $_("video") ? handleSearchChanged : handlePlaylistSearchChanged}
    props={headerProps}
    tabNavigationEnabled={true} />

  <div class="container my-3">
    <BannerHelp state='vod_disclaimer'/>
  </div>

  {#if component == $_("video")}
    <MsgError {msgError} />

    <VodsList
      on:updateFiltratedVods={handleUpdateFiltratedVods}
      on:updateSubscriptionInfo={handleUpdateSubscriptionInfo}
      {...props}
      {filtratedVods}
      {searchName}
      {totalPages}
      bind:orderBy
      bind:orderDesc
      bind:page
      loading={loadingVods} />
  {:else if component == $_("playlist")}
    <MsgError {msgError} />

    <PlaylistList
      on:updateFiltratedPlaylists={handleUpdateFiltratedPlaylists}
      {...props}
      {filtratedPlaylists}
      {searchName}
      {totalPlaylistPages}
      bind:orderByPlaylists
      bind:orderDesc
      bind:playlistPage
      loading={loadingPlaylists} />
  {/if}
{/if}