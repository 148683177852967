<script>
    import { _ } from "../../../services/i18n";
    import DatePicker from "../../ReusableLayouts/DatePicker.svelte";
    import MaskInput from "svelte-input-mask";
    import moment from "moment";
    import { tick } from "svelte";

    export let appointment = {};
    export let openDialog;

    let dialogWasOpen = false;
    let refreshCalendar = false;

    let tempDate = {
        startDate: moment().format("YYYY-MM-DD"),
        startHour: moment().add(60, "minutes").format("HH:mm"),
        endDate: moment().format("YYYY-MM-DD"),
        endHour: moment().add(90, "minutes").format("HH:mm"),
    };

    $: tempDate = {
        startDate: appointment.startDate,
        startHour: appointment.startHour,
        endDate: appointment.endDate,
        endHour: appointment.endHour,
    };

    $: if (openDialog) dialogWasOpen = true;
    $: if (dialogWasOpen && !openDialog) {
        refreshCalendar = true;
        dialogWasOpen = false;
    }

    // $: console.log("tempDate", tempDate);

    $: dateStartFormatted = appointment.startDate
        ? moment(appointment.startDate).format("DD/MM/YYYY")
        : "";
    $: dateEndFormatted = appointment.endDate
        ? moment(appointment.endDate).format("DD/MM/YYYY")
        : "";

    const handleDate = (e, type) => {
        if (type == "start") {
            tempDate.startDate = appointment.startDate = e.detail.date;
        }
        if (type == "end") {
            tempDate.endDate = appointment.endDate = e.detail.date;
        }
        checkEndHourAndStartHour();
    };

    const checkTimeRegex = (mask, type) => {
        let regex1 = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g;
        let regex2 = /^([0-1]?[0-9]|2[0-3]):[0-5]$/g;
        return regex1.test(mask);
        // if (regex1.test(mask)) return true;
        // if (regex2.test(mask)) {
        //     if (type == "start") tempDate.startHour += "0";
        //     else if (type == "end") tempDate.endHour += "0";
        //     return true;
        // }
        // return false;
    };

    const resetTime = async (value) => {
        await tick();
        if (value == "start") {
            tempDate.startHour = appointment.startHour;
        }
        if (value == "end") {
            tempDate.endHour = appointment.endHour;
        }
    };

    const checkEndHourAndStartHour = () => {
        if (
            tempDate.startDate != tempDate.endDate &&
            moment(tempDate.startDate).isAfter(moment(tempDate.endDate))
        ) {
            //if startDate is after end, set end as after
            tempDate.endDate = tempDate.startDate;
            appointment.endDate = appointment.startDate;
        }
        //if start and end date are equal check the times
        if (moment(tempDate.startDate).isSame(moment(tempDate.endDate))) {
            let dates = {};
            Object.assign(dates, tempDate);
            let startHourSplit = dates.startHour.split(":");
            let endHourSplit = dates.endHour.split(":");
            dates.start_datetime = moment(dates.startDate).add({
                hours: startHourSplit[0],
                minutes: startHourSplit[1],
            });
            dates.end_datetime = moment(dates.endDate).add({
                hours: endHourSplit[0],
                minutes: endHourSplit[1],
            });
            if (dates.start_datetime.isSameOrAfter(dates.end_datetime)) {
                appointment.startDate = dates.start_datetime.format(
                    "YYYY-MM-DD"
                );
                appointment.startHour = dates.start_datetime.format("HH:mm");
                dates.end_datetime = dates.start_datetime.add(30, "minutes");
                appointment.endDate = dates.end_datetime.format("YYYY-MM-DD");
                appointment.endHour = dates.end_datetime.format("HH:mm");
            }
        }
    };

    const startHourIsLessThanMask = (mask) => {
        let dates = {};
        Object.assign(dates, tempDate);
        let startHourSplit = dates.startHour.split(":");
        let endHourSplit = mask.split(":");
        dates.start_datetime = moment(dates.startDate).add({
            hours: startHourSplit[0],
            minutes: startHourSplit[1],
        });
        dates.end_datetime = moment(dates.endDate).add({
            hours: endHourSplit[0],
            minutes: endHourSplit[1],
        });
        if (dates.start_datetime.isSameOrAfter(dates.end_datetime)) {
            appointment.endDate = dates.end_datetime.format("YYYY-MM-DD");
            appointment.endHour = dates.end_datetime.format("HH:mm");
            dates.start_datetime = dates.end_datetime.subtract(30, "minutes");
            appointment.startDate = dates.end_datetime.format("YYYY-MM-DD");
            appointment.startHour = dates.end_datetime.format("HH:mm");
        }
    };
</script>

<!-- START DATE -->
<div class="row-grid-auto mt-2 mt-sm-0">
    <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-4 label no-ws">{$_('data_di_inizio')}</div>
        <div class="col-12 col-sm-8 mt-2">
            <DatePicker
                on:date={(e) => handleDate(e, 'start')}
                bind:defaultDate={dateStartFormatted}
                width="100"
                minDate={Date.now()}
                dateFormat="yyyy-mm-dd"
                maxDate={null}
                bind:invokeRefreshCalendar={refreshCalendar}
            />
        </div>
    </div>
    <div class="row d-flex align-items-center mt-4 mt-sm-0 mx-0">
        <div class="col-auto mt-2 p-0">
            <div class="mdc-text-field mdc-text-field--outlined">
                <MaskInput
                    {...$$props}
                    class="mdc-text-field__input customInput text-center"
                    style="width:80px"
                    placeholder="HH:mm"
                    mask="00:00"
                    type="tel"
                    on:change={(e) => {
                        tempDate.startHour = e.detail.inputState.maskedValue;
                        if (checkTimeRegex(tempDate.startHour, 'start')) {
                            appointment.startHour = tempDate.startHour;
                            checkEndHourAndStartHour();
                        }
                    }}
                    on:blur={() => {
                        if (!checkTimeRegex(tempDate.startHour, 'start')) {
                            resetTime('start');
                        }
                    }}
                    bind:value={tempDate.startHour}
                />
                <div
                    class={`mdc-notched-outline mdc-notched-outline--upgraded`}
                >
                    <div class="mdc-notched-outline__leading" />
                    <div class="mdc-notched-outline__notch" />
                    <div class="mdc-notched-outline__trailing" />
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END DATE -->
<div class="row-grid-auto mt-2 mt-sm-0">
    <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-4 label no-ws">{$_('data_di_fine')}</div>
        <div class="col-12 col-sm-8 mt-2">
            <DatePicker
                on:date={(e) => handleDate(e, 'end')}
                bind:defaultDate={dateEndFormatted}
                width="100"
                dateFormat="yyyy-mm-dd"
                bind:minDate={appointment.startDate}
                maxDate={null}
            />
        </div>
    </div>
    <div class="row d-flex align-items-center mt-4 mt-sm-0 mx-0">
        <div class="col-auto mt-2 p-0">
            <div class="mdc-text-field mdc-text-field--outlined">
                <MaskInput
                    {...$$props}
                    class="mdc-text-field__input customInput text-center"
                    style="width:80px"
                    placeholder="HH:mm"
                    mask={'00:00'}
                    type="tel"
                    on:change={(e) => {
                        tempDate.endHour = e.detail.inputState.maskedValue;
                        if (checkTimeRegex(tempDate.endHour, 'end')) {
                            appointment.endHour = tempDate.endHour;
                            startHourIsLessThanMask(tempDate.endHour);
                        }
                    }}
                    on:blur={() => {
                        if (!checkTimeRegex(tempDate.endHour, 'end')) {
                            resetTime('end');
                        }
                    }}
                    bind:value={tempDate.endHour}
                />
                <div
                    class={`mdc-notched-outline mdc-notched-outline--upgraded`}
                >
                    <div class="mdc-notched-outline__leading" />
                    <div class="mdc-notched-outline__notch" />
                    <div class="mdc-notched-outline__trailing" />
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .row-grid-auto {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
    }

    .no-ws {
        white-space: nowrap;
    }

    @media (max-width: 767px) {
        .no-ws {
            white-space: normal;
        }
        .row-grid-auto {
            grid-template-columns: 60% 1fr;
            gap: 12px;
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {
        .label {
            max-width: calc((100vw - 64px) * 0.166);
        }
        .row-grid-auto {
            gap: 0px;
        }
    }

    @media (max-width: 576px) {
        .row-grid-auto {
            grid-template-columns: 1fr 80px;
        }
    }
</style>
