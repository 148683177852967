<script>
  import { createEventDispatcher, onMount } from "svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import { Icon } from "@smui/common";
  import Dialog from "@smui/dialog";
  import Textfield from "@smui/textfield";
  import PtPricing from "./PtPricing.svelte";
  import moment from "moment";
  import Pagination from "../ReusableLayouts/Pagination.svelte";
  const dispatch = createEventDispatcher();

  export let pts;
  export let groups;
  export let totalPages = 1;
  export let page = 1;
  let activePage = 0;
  $: activePage = page - 1;

  let search = "";
  let typingTimer;
  let doneTypingInterval = 600;
  let filteredPts = [
    {
      id: "999999",
      group: {
        group_id: "",
        group_name: "",
        group_default: "",
      },
    },
  ];
  $: if (pts) filteredPts = JSON.parse(JSON.stringify(pts));

  let statuses = ["All", "Inactive", "Active", "Trialing"];
  let filteredStatus = "";
  $: if (filteredStatus) {
    if (filteredStatus == "All") {
      filteredPts = JSON.parse(JSON.stringify(pts));
    } else {
      const temp = pts.filter(
        (pt) =>
          pt.subscription.toLowerCase().trim() === filteredStatus.toLowerCase()
      );
      filteredPts = JSON.parse(JSON.stringify(temp));
    }
  }

  let filteredGroup = "";
  $: if (filteredGroup) {
    if (filteredGroup == "All") {
      filteredPts = JSON.parse(JSON.stringify(pts));
    } else {
      const temp = pts.filter((pt) =>
        pt.group && pt.group.group_id
          ? pt.group.group_name
              .toLowerCase()
              .trim()
              .includes(filteredGroup.toLowerCase())
          : ""
      );
      filteredPts = JSON.parse(JSON.stringify(temp));
    }
  }

  let editPtDialog;
  const defaultPTValues = {
    ptId: "",
    plan_id: "",
    name: "",
    plan_id_value: "",
    plan_id_start_date: "",
    plan_id_end_date: "",
    invitato_referral_id: "",
    invitato_referral_id_value: "",
    invitato_referral_id_end_date: "",
    invitato_referral_id_month: "",
    invitante_referral_id_value: "",
    invitante_referral_id_end_date: "",
    invitante_referral_id_month: "",
    group_id: "",
    group_name: "",
    group_default: "",
  };
  let ptToBeEdited = defaultPTValues;

  const openDialog = (pt) => {
    ptToBeEdited = {
      ptId: pt.id,
      plan_id: pt.plan_id,
      name: pt.name,
      ...pt.pricing,
      ...pt.group,
    };
    editPtDialog.open();
  };

  const handleUpdatePtPricing = (e) => {
    dispatch("updatePtPrincing", { pt: e.detail.pt });
    ptToBeEdited = defaultPTValues;
    editPtDialog.close();
  };

  const handleUpdateGroup = (e) => {
    dispatch("updatePtGroup", { group: e.detail.group });
    ptToBeEdited = defaultPTValues;
    editPtDialog.close();
  };

  const handleCancel = () => {
    ptToBeEdited = defaultPTValues;
    editPtDialog.close();
  };

  const switchPage = async (event) => {
    //idex from 0
    page = event.detail.page + 1;
    dispatch("updatePtsPaging", { page });
  };

  const configSearch = () => {
    let searchInput = document.getElementById("searchInputPt");
    if (searchInput) {
      searchInput.addEventListener("keyup", () => {
        delaySearch();
      });
    }
  };

  const delaySearch = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      (_) => dispatch("updatePtSearch", { search }),
      doneTypingInterval
    );
  };

  onMount(() => {
    configSearch();
  });
</script>

<style>
  * :global(.mdc-text-field) {
    height: 40px;
    margin-right: 0.8em;
  }
  * :global(.mdc-text-field__input) {
    height: auto;
    margin: auto;
    padding: 0 0.5em !important;
    width: 14em;
  }
  *
    :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
      .mdc-floating-label) {
    top: 12px;
  }
  * :global(.material-icons.searchIcon) {
    padding: 10px 0px 0px 4px !important;
  }
  * :global(.primary) {
    color: var(--primary);
  }
</style>

<Dialog
  class="editPtDialogWrapper"
  bind:this={editPtDialog}
  aria-labelledby="dialog-for-new-deal"
  aria-describedby="new-deal">
  <div class="container">
    <div class="row no-gutters flexy">
      <div class="col-xs-6 demiBold">
        Configura il pricing per {ptToBeEdited.name}
      </div>
    </div>
  </div>
  <PtPricing
    pt={ptToBeEdited}
    {groups}
    on:cancel={handleCancel}
    on:save={handleUpdatePtPricing}
    on:updateGroup={handleUpdateGroup} />
</Dialog>

<div>
  <div class="row mx-0 my-3 py-4">
    <div class="col-3">
      <span class="mdc-typography--headline5">Utenti</span>
    </div>
    <div class="col-3">
      <Select
        enhanced
        variant="outlined"
        class="w100 pt-0 selectStatuses"
        label="Status"
        bind:value={filteredStatus}>
        {#each statuses as status}
          <Option value={status} selected={filteredStatus === status}>
            {status}
          </Option>
        {/each}
      </Select>
    </div>
    <div class="col-3">
      <Select
        enhanced
        variant="outlined"
        class="w100 pt-0 selectStatuses"
        label="Group"
        bind:value={filteredGroup}>
        <Option value={'All'} selected={filteredGroup === 'All'}>All</Option>
        {#each groups as group}
          <Option value={group.nome} selected={filteredGroup === group.nome}>
            {group.nome}
          </Option>
        {/each}
      </Select>
    </div>
    <div class="col-3">
      <Textfield
        variant="outlined"
        id="searchInputPt"
        withLeadingIcon
        bind:value={search}
        label="Cerca per nome">
        <div>
          <Icon class="material-icons searchIcon">search</Icon>
        </div>
      </Textfield>
    </div>
  </div>
  {#if pts.length > 0}
    <div class="row mx-0 my-3 py-4">
      <div class="col-12 admin-datatable">
        <DataTable class="mb-3">
          <Head>
            <Row>
              <Cell>PERSONAL TRAINER</Cell>
              <Cell>STATUS</Cell>
              <Cell>GRUPPO</Cell>
              <Cell>N. DI CLIENTI</Cell>
              <Cell>ULTIMO ACCESSO</Cell>
              <Cell>Pricing riservato (€)</Cell>
              <Cell>Referral inviato (%)</Cell>
              <Cell>Referral invitante (%)</Cell>
              <Cell>EDIT</Cell>
            </Row>
          </Head>
          <Body>
            {#each filteredPts as pt (pt.id)}
              <Row>
                <Cell>{pt.name}</Cell>
                <Cell>{pt.subscription}</Cell>
                <Cell>{pt.group ? pt.group.group_name : 'No Group'}</Cell>
                <Cell>{pt.customers_count}</Cell>
                <Cell>
                  {pt.last_login_at ? moment(pt.last_login_at).fromNow() : ''}
                </Cell>
                <Cell
                  class={pt.pricing && pt.pricing.plan_id_end_date && moment(pt.pricing.plan_id_end_date).isBefore(moment()) ? 'primary' : ''}>
                  {pt.pricing ? pt.pricing.plan_id_value : ''}
                </Cell>
                <Cell
                  class={pt.pricing && pt.pricing.invitato_referral_id_end_date && moment(pt.pricing.invitato_referral_id_end_date).isBefore(moment()) ? 'primary' : ''}>
                  {pt.pricing ? pt.pricing.invitato_referral_id_value : ''}
                </Cell>
                <Cell
                  class={pt.pricing && pt.pricing.invitante_referral_id_end_date && moment(pt.pricing.invitante_referral_id_end_date).isBefore(moment()) ? 'primary' : ''}>
                  {pt.pricing ? pt.pricing.invitante_referral_id_value : ''}
                </Cell>
                <Cell>
                  <Icon
                    on:click={() => openDialog(pt)}
                    class="material-icons clickable">
                    edit
                  </Icon>
                </Cell>
              </Row>
            {/each}
          </Body>
        </DataTable>
      </div>
    </div>

    <Pagination {totalPages} {activePage} on:page={switchPage} />
  {/if}
</div>
