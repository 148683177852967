<script>
    import { createEventDispatcher } from "svelte"
    import IconButton from "@smui/icon-button";
    import SubscriptionInfoCard from "./SubscriptionInfoCard.svelte";

    const dispatch = createEventDispatcher();

    export let currentCustomers;
    export let maxCustomers;
    export let nextInvoiceDate;
    export let priceInterval;
    export let priceValue;
    export let productName;
    export let state;
    export let step;
    export let title = "";

    const handleCloseDialog = () => {
        dispatch('closeDialog');
    }
</script>


{#if step === 4}
    <h4 class="text-center">{title}</h4>
{:else}
    <span class="text-left">{title}</span>
{/if}
<div class="iconButton--close">
    <IconButton class="material-icons" on:click={handleCloseDialog} tabindex="-1">
        close
    </IconButton>
</div>
{#if step == 3}
    <div class="my-3">
        <SubscriptionInfoCard
            subscriptionDialogOpen={true}
            {currentCustomers}
            {maxCustomers}
            {nextInvoiceDate}
            {priceInterval}
            {priceValue}
            {productName}
            {state} 
            {step}
        />
    </div>
{/if}

<style>
    .iconButton--close {
        position: absolute; 
        top: 10px;
        right: 20px; 
    }
</style>