<script>
    import { locale, _ } from "../../services/i18n";
    import { user, allGroups, allCustomers } from "../../lib/store";
    import { onDestroy, onMount } from "svelte";
    import BannerHelp from "../ReusableLayouts/BannerHelp.svelte";
    import Loading from "../ReusableLayouts/Loading.svelte";
    import FullCalendar from "svelte-fullcalendar";
    import interactionPlugin from "@fullcalendar/interaction";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import listWeekPlugin from "@fullcalendar/list";
    import itLocale from "@fullcalendar/core/locales/it";
    import HeaderCalendar from "../ReusableLayouts/HeaderCalendar.svelte";
    import ReminderDialog from "./ReminderDialog.svelte";
    import AppointmentDialog from "./AppointmentDialog.svelte";
    import SelectEvent from "./SelectEvent.svelte";
    import moment from "moment";
    import Events from "./Events.svelte";
    import {
        addEvent,
        getCustomersList,
        getEvent,
        getGroupsList,
        hasCustomApp,
    } from "../../services/calendar";
    import { convertDataFromBE, handleDataForBE } from "./calendarUtils";
    import TriggerUpdateLayout from "../ReusableLayouts/TriggerUpdateLayout.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";

    export let navbarDisabled = false;
    export let showNavbar;

    let appointmentDialog = false;
    let calendarRef;
    let clickData;
    let dataFromBe;
    let events;
    let headerProps = {};
    let iW;
    let loading = true;
    let msgError;
    let mounted = false;
    let navbar;
    let navbarNotFixed = false;
    let optionsFullCalendar = {};
    let ptHasCustomApp = false;
    let qs = {
        search: "",
        customer: "",
        day: "",
        month: "",
        week: "",
        year: "",
        startDate: "",
        endDate: "",
    };
    let reminderDialog = false;
    let selectData;
    let selectDialog = false;
    let updateEvents = false;

    const showCustomers = (arg) => {
        const props = Object.entries(arg.event.extendedProps).map(
            (el) => el[1]
        );
        let names = "";
        props.forEach((el, i) => {
            //last attributes is types so remome one element from props lenght
            if (i + 1 != props.length) {
                if (i + 1 < props.length - 1) names += `${el}, `;
                else names += el;
            }
        });
        return names;
    };

    $: if (navbar && window.screen.width > 768) {
        navbar.classList.add("position-relative");
        document
            .getElementsByClassName("main__content")[0]
            .classList.remove("padding-navbar");
        navbarNotFixed = true;
    }

    $: {
        optionsFullCalendar.events = events;
        optionsFullCalendar = { ...optionsFullCalendar };
    }

    optionsFullCalendar = {
        headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        displayEventEnd: false,
        initialView: window.screen.width > 768 ? "timeGridWeek" : "listWeek",
        locale: $locale,
        locales: [itLocale],
        plugins: [
            interactionPlugin,
            dayGridPlugin,
            timeGridPlugin,
            listWeekPlugin,
        ],
        editable: true,
        navLinks: window.screen.width > 768 ? true : false,
        selectable: true,
        events,
        eventTimeFormat: {
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
        },
        datesSet: (arg) => {
            const momentStart = moment(arg.start);
            const momentEnd = moment(arg.end);
            //clean querystring
            qs = {
                search: qs.search,
                customer: qs.customer,
                day: "",
                month: "",
                week: "",
                year: "",
                startDate: "",
                endDate: "",
            };
            qs.startDate = momentStart.toISOString();
            qs.endDate = momentEnd.toISOString();
            qs.year = moment(arg.start).add(1, "month").format("YYYY");
            //for month
            if (arg.view.type == "dayGridMonth") {
                const monthDate = parseInt(momentEnd.format("MM")) - 1;
                qs.month = monthDate == 0 ? 12 : monthDate;
            }
            //for week
            if (
                arg.view.type == "timeGridWeek" ||
                arg.view.type == "listWeek"
            ) {
                qs.week = momentStart.isoWeeks();
            }
            //for day
            if (arg.view.type == "timeGridDay") {
                qs.day = momentStart.format("D");
                qs.month = momentStart.format("M");
            }
            updateEvents = true;
        },
        eventContent: (arg) => {
            if (arg.view.type == "listWeek") {
                const names = showCustomers(arg);
                return {
                    html: `<span>${arg.event.title}</span>
                <br>
                <span style="font-size:14px">${names}</span>`,
                };
            }
            if (arg.view.type == "timeGridWeek") {
                const startDate = moment(arg.event.start);
                const endDate = moment(arg.event.end);
                if (endDate.diff(startDate, "minutes") <= 40)
                    return {
                        html: `<div class="fc-event-title fc-sticky" style="font-size:14px">${arg.event.title}</div>`,
                    };
                if (endDate.diff(startDate, "minutes") >= 70) {
                    let names = showCustomers(arg);
                    if (endDate.diff(startDate, "minutes") <= 90)
                        names = names.split(",").slice(0, 1);
                    else if (endDate.diff(startDate, "minutes") <= 100)
                        names = names.split(",").slice(0, 2);
                    else if (endDate.diff(startDate, "minutes") <= 130)
                        names = names.split(",").slice(0, 3);
                    else if (endDate.diff(startDate, "minutes") <= 150)
                        names = names.split(",").slice(0, 4);
                    else if (endDate.diff(startDate, "minutes") > 150)
                        names = names.split(",").slice(0, 5);
                    return {
                        html: `
                        <div class="fc-event-time">
                            ${moment(arg.event.start).format("HH:mm")}
                        </div>
                        <div class="fc-event-title-container">
                            <div class="fc-event-title fc-sticky" style="font-size:14px">
                                ${arg.event.title}
                            </div>
                            <span style="font-size:12px">${names}</span>
                        </div>`,
                    };
                }
            }
            // customizzazione degli eventi per il dayGridMonth
            // if (arg.view.type == "dayGridMonth") {
            //     console.log("arg", arg.event);
            //     return {
            //         html: `
            //         <div class="fc-daygrid-event-dot" style="border-color: ${
            //             arg.event.extendedProps.type == "reminder"
            //                 ? "#F98131"
            //                 : "#0CD2C8"
            //         };"></div>
            //         <div class="fc-event-time"
            //         style="color:${
            //             arg.event.extendedProps.type == "reminder"
            //                 ? "#F98131"
            //                 : "#0CD2C8"
            //         };">
            //             ${moment(arg.event.start).format("HH:mm")}
            //         </div>
            //         <div class="fc-event-title"
            //         style="color:${
            //             arg.event.extendedProps.type == "reminder"
            //                 ? "#F98131"
            //                 : "#0CD2C8"
            //         };">
            //             ${arg.event.title}
            //         </div>`,
            //     };
            // }
        },
        select: (info) => {
            selectData = {};
            selectData.start = moment(info.startStr).format("YYYY-MM-DD");
            selectData.startTime = moment(info.startStr).format("HH:mm");
            selectData.end = moment(info.endStr).format("YYYY-MM-DD");
            selectData.endTime = moment(info.endStr).format("HH:mm");
            selectDialog = true;
        },
        eventClick: async (info) => {
            dataFromBe = await getEvent(info.event.id);
            // console.log("dataFromBE", dataFromBe);
            if (dataFromBe.type == 0) handleAppointment();
            else handleReminder();
        },
        eventDrop: async (info) => {
            let dataForDrag = await getEvent(info.event.id);
            dataForDrag = convertDataFromBE(dataForDrag);
            dataForDrag.startDate = moment(info.event.startStr).format(
                "YYYY-MM-DD"
            );
            dataForDrag.startHour = moment(info.event.startStr).format(
                "HH:mm"
            );
            dataForDrag.endDate = moment(info.event.endStr).format(
                "YYYY-MM-DD"
            );
            dataForDrag.endHour = moment(info.event.endStr).format("HH:mm");
            const dataForBE = handleDataForBE(dataForDrag, true);
            try {
                let result = await addEvent(dataForBE);
                console.log("drag ok", result);
            } catch (error) {
                console.log("error BE", error);
            }
        },
        eventResize: async (info) => {
            let dataForResize = await getEvent(info.event.id);
            dataForResize = convertDataFromBE(dataForResize);
            dataForResize.startDate = moment(info.event.startStr).format(
                "YYYY-MM-DD"
            );
            dataForResize.startHour = moment(info.event.startStr).format(
                "HH:mm"
            );
            dataForResize.endDate = moment(info.event.endStr).format(
                "YYYY-MM-DD"
            );
            dataForResize.endHour = moment(info.event.endStr).format(
                "HH:mm"
            );
            const dataForBE = handleDataForBE(dataForResize, true);
            try {
                let result = await addEvent(dataForBE);
                console.log("resize ok", result);
            } catch (error) {
                console.log("error BE", error);
            }
        },
    };

    $: if (mounted) {
        if (iW < 768 && optionsFullCalendar.initialView != "listWeek") {
            optionsFullCalendar.initialView = "listWeek";
            optionsFullCalendar = optionsFullCalendar;
            changeCalendarView("listWeek");
        }

        if (iW >= 768 && optionsFullCalendar.initialView != "timeGridWeek") {
            optionsFullCalendar.initialView = "timeGridWeek";
            optionsFullCalendar = optionsFullCalendar;
            changeCalendarView("timeGridWeek");
        }
    }

    const changeCalendarView = (value) => {
        let calendarApi = calendarRef.getAPI();
        calendarApi.changeView(value);
        calendarApi.render();
    };

    $: headerProps = {
        buttonReminderDialog: true,
        buttonReminderlLabel: $_("nuovo_promemoria"),
        buttonLabel: $_("nuovo_appuntamento"),
        buttonNavigate: true,
        nameTitle: $_("calendario"),
        noNumber: true,
    };

    const displayError = (err = null) => {
        let timeoutTime = 3000;
        msgError = $_("si_è_verificato_un_errore");
        if (err.message.exception && err.message.exception === 'PlanConflictException') {
            msgError = $_("plan_conflict_exception");
            timeoutTime = 9000000;
        }
        setTimeout(() => {
            msgError = "";
        }, timeoutTime);
    };

    const handleReminder = () => {
        reminderDialog = reminderDialog ? false : true;
        selectData = false;
    };

    const handleAppointment = (e) => {
        appointmentDialog = appointmentDialog ? false : true;
        selectData = false;
    };

    const getGroups = async () => {
        const filtratedGroups = await getGroupsList();
        allGroups.set(filtratedGroups);
    };

    const getCustomersBE = async () => {
        const filtratedCustomers = await getCustomersList();
        allCustomers.set(filtratedCustomers);
    };

    onMount(async () => {
        navbar = document.getElementsByClassName("navbar__container")[0];
        try {
            ptHasCustomApp = await hasCustomApp();
        } catch (error) {
            console.log("hasCustomApp error", error);
        }
        try {
            loading = false;
            await getGroups();
            await getCustomersBE();
        } catch (error) {
            loading = false;
            console.log("getCustomersInfo error", error);
            displayError(error);
        }
        mounted = true
    });

    onDestroy(() => {
        if (navbar && navbarNotFixed) {
            navbar.classList.remove("position-relative");
            document
                .getElementsByClassName("main__content")[0]
                .classList.toggle("padding-navbar");
        }
    });
</script>

<TriggerUpdateLayout bind:iW_update={iW} />

{#if loading}
    <Loading />
{:else}
    <div class="headerBackground">
        <div class="container fluid px-3 px-sm-0 px-lg-3">
            <HeaderCalendar
                {...headerProps}
                bind:search={qs.search}
                on:onComponentEvent={() => (updateEvents = true)}
                on:openReminderDialog={() => handleReminder()}
                on:openAppointmentDialog={() => handleAppointment()}
            />
        </div>
    </div>

    <div class="container fluid desktop mt-4 px-3 px-sm-0 px-lg-3">
        <MsgError {msgError} />
        <FullCalendar
            bind:this={calendarRef}
            bind:options={optionsFullCalendar}
        />
    </div>

    <SelectEvent
        bind:openDialog={selectDialog}
        on:closeSelectDialog={() => (selectDialog = false)}
        on:appointment={() => handleAppointment()}
        on:reminder={() => handleReminder()}
    />

    <AppointmentDialog
        bind:openDialog={appointmentDialog}
        bind:selectData
        bind:dataFromBe
        on:closeInvitationDialog={(e) => handleAppointment(e)}
        on:eventCreated={() => (updateEvents = true)}
        on:eventDeleted={() => (updateEvents = true)}
    />

    <ReminderDialog
        bind:openDialog={reminderDialog}
        bind:selectData
        bind:dataFromBe
        on:closeInvitationDialog={() => handleReminder()}
        on:eventCreated={() => (updateEvents = true)}
        on:eventDeleted={() => (updateEvents = true)}
    />

    <Events bind:events bind:qs bind:updateEvents />
{/if}

{#if ptHasCustomApp == 1}
    <div class="container mt-4 pt-2">
        <BannerHelp state='calendar'/>
    </div>
{/if}

<style>
    :global(.headerBackground) {
        background-color: #f9f9f9;
    }

    :global(.fc .fc-view-harness) {
        height: calc(100vh - 360px) !important;
    }

    :global(.fc-daygrid-day-number, .fc-col-header-cell-cushion, .fc-list-day-text, .fc-list-day-side-text) {
        color: #2c3e50;
    }

    :global(.fc-daygrid-body .fc-event-time, .fc-daygrid-body .fc-event-title) {
        color: black;
    }

    @media (max-width: 767px) {
        :global(.headerBackground) {
            position: -webkit-sticky;
            position: sticky;
            top: 3.4rem;
            z-index: 2;
        }
        :global(.fc-button-group) :global(.fc-dayGridMonth-button),
        :global(.fc-button-group) :global(.fc-timeGridWeek-button),
        :global(.fc-button-group) :global(.fc-timeGridDay-button),
        :global(.fc-button-group) :global(.fc-listWeek-button) {
            display: none !important;
        }

        :global(.fc-toolbar-title) {
            font-size: 1.25em !important;
        }

        :global(.fc .fc-view-harness) {
            height: calc(100vh - 225px) !important;
        }
    }

    @media (max-width: 362px) {
        :global(.fc-today-button) {
            display: none !important;
        }
    }

    @media (min-aspect-ratio: 17/9) {
        :global(.fc .fc-view-harness) {
            height: calc(100vh - 140px) !important;
        }
    }
</style>
