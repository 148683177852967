<script>
  const dispatch = createEventDispatcher();
  import { _ } from "../../../services/i18n";
  import { activeSubSection } from "../../../lib/store";
  import { addVideo } from "../../../lib/utils";
  import { createEventDispatcher, onDestroy } from "svelte";
  import { Icon } from "@smui/common";
  import Button, { Label } from "@smui/button";
  import Dialog from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";

  export let converting = false;
  export let thumbnail;
  export let triggerCloseVideo;
  export let video;

  let videoDialog;
  let videoTag;
  let error;
  let play = false;

  $: if (triggerCloseVideo && videoDialog) {
    videoDialog.close();
    triggerCloseVideo = false;
  }

  const onInputChange = (e) => {
    const file = e.target.files[0];
    if (!file || file.size > 50000000) {
      error = $_("la_dimensione_massima_consentita_è_di", {
        values: { dimension: 50 },
      });
      setTimeout(() => {
        error = "";
      }, 3000);
      return;
    }
    updateVideo(file);
  };

  const updateVideo = (file) => {
    video = file;
    dispatch("updateExerciseVideo", {
      video: file,
    });
    addVideo(file, "exerciseVideo");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    updateVideo(file);
  };

  const closeVideoDialog = () => {
    play = false; 
    videoDialog.close();
    videoTag.load();
  };

  onDestroy(() => {
    videoDialog.close();
  });
</script>

<style>
  * :global(.embed-responsive-item) {
    background-color: #000;
  }
  * :global(.bringToFront) {
    align-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 9;
  }
  .customUploader {
    background-color: #f3f3f3;
    border-radius: 10px;
    flex-direction: column;
    height: 13em;
    position: relative;
    text-align: center;
    width: 13em;
  }
  .customFileInput {
    left: 0px;
    opacity: 0;
    position: absolute;
    z-index: 5;
  }
  .imageWrapper {
    align-items: center;
    border-radius: 10px;
    display: flex;
    height: 13em;
    overflow: hidden;
    position: relative;
    width: 13em;
  }
  * :global(.imageWrapper img) {
    bottom: 50%;
    height: unset;
    left: 0;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    translate: (0, -50%);
  }
  .maxLabel {
    opacity: 0.5;
    font-weight: 600;
    font-size: 0.8rem;
  }
  .indications {
    opacity: 0.8;
    font-weight: 600;
  }
  .layer {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: pointer;
  }
  .layer:hover {
    opacity: 1;
    transition: all 0.3s ease;
  }
  .videoPreview {
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px;
  }
  * :global(.customColor) {
    background-color: var(--mdc-theme-on-primary, #fff);
    color: #000;
  }
  * :global(.customColor.mdc-button--outlined:not(:disabled)) {
    background-color: transparent;
    border-color: var(--mdc-theme-on-primary, #fff);
    color: var(--mdc-theme-on-primary, #fff);
  }
  .close-icon-wrapper {
    position: absolute;
    right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    z-index: 2;
  }
</style>

<Dialog class="no-click-backdrop" bind:this={videoDialog}>
  <div class="close-icon-wrapper">
    <IconButton class="material-icons" on:click={closeVideoDialog}>
      close
    </IconButton>
  </div>
  {#if play}
    <div class="embed-responsive embed-responsive-1by1">
      <video
        controls
        bind:this={videoTag}
        preload="none"
        id="videoFull"
        class="embed-responsive-item"
        autoplay
        muted
        src={video}>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  {/if}
</Dialog>

<div class="d-flex flex-column">
  <div class="customUploader" on:drop={handleDrop}>
    <input
      class="customUploader d-flex customFileInput"
      type="file"
      id="file"
      accept="video/*"
      on:change={onInputChange} 
      disabled={$activeSubSection == $_('progressioni')} />
    {#if !video}
      <div class="customUploader d-flex">
        <div class="row">
          <div class="col-12 my-4">
            <span class="maxLabel">{`${$_('max')} 50 MB`}</span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 indications">
            <span>{$_('seleziona_video')}</span>
            <br />
            <span>{$_('o_trascina')}</span>
            <br />
            <Icon
              role="button"
              tabindex="1"
              class="material-icons mdc-text-field__icon
                mdc-text-field__icon--trailing pt-2 pb-3 primary">
              ondemand_video
            </Icon>
          </div>
        </div>
      </div>
    {:else}
      <div class={`imageWrapper ${thumbnail ? 'bg--grey' : 'bg--black'}`}>
        <div class="layer">
          <div class="bringToFront">
            {#if typeof video == 'string'}
              <Button
                class="btn mt-3 customColor"
                variant="unelevated"
                disabled={converting}
                target="_blank"
                on:click={() => {
                  if (converting === false) {
                    play = true;
                    videoDialog.open();
                  }
                }}>
                <Label>
                  {converting === false ? $_('visualizza') : $_('in_elaborazione')}
                  {#if converting}
                    <LoadingInline />
                  {/if}
                </Label>
              </Button>
            {/if}
            <Button
              class="btn mt-3 customColor"
              variant="outlined"
              target="_blank"
              on:click={() => {
                thumbnail = null;
                video = null;
              }}>
              <Label>{$_('elimina')}</Label>
            </Button>
          </div>
        </div>
        {#if !thumbnail}
          <div
            style="
          height: 100%;
          max-width: 100%;
          margin: 0 auto;"
            id="printVideo" />
        {:else}<img loading="lazy" src={thumbnail} alt="video" />{/if}
      </div>
    {/if}
  </div>
  <span class="pt-2 text-danger pr-2">{error ? error : ''}</span>
</div>
