<script>
    import { _ } from "svelte-i18n";
    import IconButton from "@smui/icon-button";
    import Dialog from "@smui/dialog";
    import Textfield from "@smui/textfield";
    import Button, { Label } from "@smui/button";
    import CharacterCounter from "@smui/textfield/character-counter/index";

    export let noteDialog;
    export let noteDialogOpened;
    export let note;
    export let exercise;

    let fixTextAreaHeightStarted = false;

    $: if (!fixTextAreaHeightStarted && noteDialogOpened && note) {
        fixTextareaHeight();
        fixTextAreaHeightStarted = true;
    }

    const fixTextareaHeight = () => {
        const tx = document.getElementById("noteTextarea-" + exercise.sortId);
    
        tx.setAttribute("style", "height:" + (tx.scrollHeight) + "px;overflow-y:hidden;");
        tx.addEventListener("input", OnInput, false);

        function OnInput() {
            this.style.height = "auto";
            this.style.height = (this.scrollHeight) + "px";
        }
    }
</script>

<style>
    .title {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
    }
    * :global(textarea) {
        height: 10em;
    }
    * :global(.fullWidth) {
        width: 100% !important;
        height: auto !important;
    }
</style>

<Dialog
    bind:this={noteDialog}
    aria-labelledby="dialog-for-note"
    aria-describedby="note"
    class="p-4">
    <div class="row p-3 mx-0">
        <div class="col-10 title">
            <div class="bold">{$_('note')}</div>
        </div>
        <div class="col-2">
            <IconButton
                class="material-icons"
                on:click={() => {
                    noteDialogOpened = false;
                    noteDialog.close();
                }}>
                close
            </IconButton>
        </div>
    </div>
    <div class="container-fluid p-3">
        <Textfield
            textarea
            class="fullWidth"
            input$id="noteTextarea-{exercise.sortId}"
            input$min-rows="8"
            input$maxlength="1000"
            bind:value={note}
            variant="outlined"
            label={`${$_('note')}`}
            type="text">
            <CharacterCounter>{note != null && note != '' ? `${note.length} / 1000` : `0 / 1000`}</CharacterCounter>
        </Textfield>
    </div>
    <div class="d-flex justify-content-between my-3">
        <Button
            disabled={false}
            class="btn ml-3"
            variant="unelevated"
            style=" background-color: white !important; color: #cd293d !important;
      border: 1px solid white !important;"
            on:click={() => {
                note = '';
                noteDialogOpened = false;
                noteDialog.close();
            }}>
            <Label>{$_('annulla')}</Label>
        </Button>
        <Button
            class="primary btn mr-3"
            variant="unelevated"
            style="color: white !important;"
            on:click={() => {
                noteDialogOpened = false;
                noteDialog.close();
            }}>
            <Label>{$_('fatto')}</Label>
        </Button>
    </div>
</Dialog>
