<script>
    import "./AddClientGroup.scss";
    import { _ } from "../../../services/i18n";
    import { createEventDispatcher, tick } from "svelte";
    import { allCustomers } from "../../../lib/store";
    import Chip, { Text } from "@smui/chips";
    import Textfield from "@smui/textfield";
    import Button, { Label } from "@smui/button";
    import { Icon } from "@smui/common";
    import List, { Item } from "@smui/list";
    import MenuSurface from "@smui/menu-surface";

    export let disabled = false;
    export let dirtyClient = false;
    export let clientSelected;
    export let inputLabel;
    export let newClientField = false;
    export let search = "";
    export let openDialog;
    export let confirmationDialog;
    export let clientsAdded = [];

    const dispatch = createEventDispatcher();

    let filtered = $allCustomers.slice(0, 5);
    let formSurface;
    let formSurfaceEnabled = false;

    $: if (!openDialog && !confirmationDialog) {
        clientsAdded = [];
    }

    $: dispatch("dirtyClient", { dirtyClient });

    const checkDirty = () => {
        if (!newClientField || (newClientField && search != "")) {
            dirtyClient = false;
        } else if (newClientField && search.length == 0) {
            dirtyClient = false;
        }
    };

    $: if (clientSelected) {
        const temp = $allCustomers.find(
            (client) => client.id == clientSelected.id
        );
        if (temp) {
            formSurfaceEnabled = false;
        }
    }

    $: filterClients(search);

    const filterClients = (search) => {
        //prima di filtrare per la ricerca, filtra per i clienti già aggiunti, cosi da rimuoverli nei passaggi successivi
        const filteredAllCustomers = $allCustomers.filter((client) => {
            let found = false;
            clientsAdded.forEach((el) => {
                if (client.id == el.id || client.email == el.email)
                    found = true;
            });
            return !found;
        });

        if (!search) {
            filtered = filteredAllCustomers.slice(0, 5);
            return;
        }

        let arraySearchLCase = [];
        let checkMatch = 0;

        const searchLCase = search.toLowerCase();

        const exactMatch = filteredAllCustomers.filter((client) => {
            if (
                client.first_name.toLowerCase() == searchLCase.trim() ||
                client.last_name.toLowerCase() == searchLCase.trim()
            ) {
                return true;
            }
        });
        const filteredClients = filteredAllCustomers.filter((client) => {
            checkMatch = 0;
            arraySearchLCase = searchLCase.trim().split(" ");
            for (let search of arraySearchLCase) {
                // Nel controllo viene esclusa l'uguaglianza, perché già gestita dal metodo exactMatch()
                if (
                    (client.first_name.toLowerCase().includes(search) &&
                        client.first_name.toLowerCase() !=
                            searchLCase.trim()) ||
                    (client.last_name.toLowerCase().includes(search) &&
                        client.last_name.toLowerCase() != searchLCase.trim())
                ) {
                    checkMatch++;
                }
            }

            if (arraySearchLCase.length === checkMatch) {
                return true;
            }
        });

        filtered =
            exactMatch.length > 0
                ? [...exactMatch, ...filteredClients].slice(0, 5)
                : filteredClients.slice(0, 5);
    };

    const selectItem = async (el) => {
        clientSelected = el;
        formSurface.setOpen(false);
        formSurfaceEnabled = false;
        handleClientSelected({ client: el });
        // dispatch("clientSelected", { client: ex });
        filtered = $allCustomers.slice(0, 5);
        search = "";
        checkDirty();
    };

    const clientNotExists = (email) => {
        let regex = /^\S+@\S+\.\S+$/;
        let matches = $allCustomers.filter((el) => {
            return el.email.toLowerCase().trim() === email.toLowerCase().trim();
        });
        return regex.test(email) && matches.length == 0;
    };

    const newClientClick = async () => {
        const client = {
            email: search,
            first_name: "new",
            last_name: "new",
        };
        formSurface.setOpen(false);
        formSurfaceEnabled = false;
        handleClientSelected({ client });
        search = "";
    };

    const handleKeyUp = async () => {
        if (clientsAdded.length < 5 && !disabled) {
            formSurfaceEnabled = true;
            await tick();
            formSurface.setOpen(true);
            checkDirty();
        }
    };

    const handleClick = async () => {
        if (clientsAdded.length < 5 && !disabled) {
            if (search.length > 0) {
                formSurfaceEnabled = true;
                await tick();
                formSurface.setOpen(true);
            }
        }
    };

    const handleFocusOut = async () => {
        if (newClientField) {
            setTimeout(() => {
                search = "";
                checkDirty();
            }, 300);
        } else {
            checkDirty();
        }
    };

    const handleClientSelected = (e) => {
        clientsAdded = [...clientsAdded, Object.assign({}, e.client)];
        dispatch("clientSelected", {
            client: e.client,
        });
    };

    const removeClientFromSelected = (client) => {
        clientsAdded = clientsAdded.filter((el) => el != client);
        dispatch("clientRemoved", { client });
    };
</script>

{#if inputLabel}
    <span class={`label ${dirtyClient && !newClientField ? 'dirtyInput' : ''}`}>
        {inputLabel}
    </span>
{/if}

<div>
    <Textfield
        variant="outlined"
        label={clientsAdded.length > 0 ? '' : $_('cerca_per_nome')}
        input$maxlength="255"
        bind:value={search}
        class="w100 textfield--large tags-textfield"
        aria-haspopup="true"
        aria-expanded="false"
        on:click={handleClick}
        on:keyup={handleKeyUp}
        on:focusout={handleFocusOut}
        bind:disabled
    >
        <div style="width: 85%;">
            {#each clientsAdded as client}
                <Button class="btn customChip">
                    <Label>
                        {#if !client.hasOwnProperty('id') || !client.id}
                            {`${client.email}`}
                        {:else}{`${client.first_name} ${client.last_name}`}{/if}
                        <Icon
                            class="material-icons"
                            on:click={() => removeClientFromSelected(client)}
                        >
                            close
                        </Icon>
                    </Label>
                </Button>
            {/each}
        </div>
    </Textfield>
</div>

{#if formSurfaceEnabled}
    <MenuSurface
        class="menuSurfaceCustom"
        bind:this={formSurface}
        anchorCorner="BOTTOM_LEFT"
    >
        <List class="list">
            {#each filtered as element}
                <div class="selectExerciseBtn" on:click={selectItem(element)}>
                    <Item>
                        <Label>
                            <img
                                class="user-image"
                                alt=""
                                src={element.profile_image}
                            />{`${element.first_name} ${element.last_name}`}
                        </Label>
                    </Item>
                </div>
            {/each}
            {#if clientNotExists(search) == true}
                <div class="addExerciseBtn" on:mousedown={newClientClick}>
                    <Item>
                        <Chip class="newExerciseBtn">
                            <Text>{`${$_('aggiungi')} '${search}'`}</Text>
                        </Chip>
                    </Item>
                </div>
            {/if}
        </List>
    </MenuSurface>
{/if}

<style>
    * :global(.mdc-chip) {
        background-color: black;
        color: white;
    }
    .header {
        align-items: center;
        background-color: #f9f9f9;
        justify-content: space-between;
    }
    .title {
        font-size: 1.25rem;
    }

    .label {
        font-size: 0.8rem;
        font-weight: 500;
        opacity: 0.7;
    }

    :global(.mdc-text-field.textfield--large) {
        min-height: 3.5rem !important;
    }

    .dirtyInput {
        border-color: var(--primary) !important;
        color: var(--primary) !important;
    }

    /* add css */

    .user-image {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 12px;
    }

    * :global(.menuSurfaceCustom) {
        background-color: rgba(0, 0, 0, 0.9);
        padding: 20px;
        color: #fff;
        min-width: 80%;
    }
    * :global(.fullWidth) {
        width: 100% !important;
    }
    * :global(.mdc-chip__icon.mdc-chip__icon--trailing) {
        opacity: 0.2;
        font-size: 1.8em;
        width: 0.8em;
        height: 1em;
    }
    * :global(.customChip) {
        border-radius: 13.5px;
        background-color: #212121 !important;
        color: #fff !important;
        min-width: fit-content;
        text-transform: inherit;
        font-weight: normal;
        height: 2em;
        margin: auto;
        margin-left: 0.7em;
        margin-top: 0.5rem;
    }
    * :global(.customChip span i) {
        opacity: 0.5;
        background-color: #ffffff;
        border-radius: 50%;
        color: #212;
        font-size: 1.3em;
        width: 1em;
        height: 1em;
        margin-right: 0;
    }
    .pushToEnd {
        justify-content: flex-end;
    }
    .label {
        text-align: end;
        align-self: center;
        opacity: 0.8;
        font-weight: 600;
    }
    .cursorPointer {
        cursor: pointer;
    }
    * :global(.tags-textfield) {
        display: flex;
        flex-wrap: wrap;
        height: auto !important;
    }

    .close-icon-wrapper {
        right: 0;
        position: absolute;
    }

    * :global(.tags-textfield input) {
        height: 2.2rem !important;
    }
</style>
