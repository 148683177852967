import { POST, GET, PUT, DELETE } from "../lib/http";
import { customer } from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const getProducts = async () => {
  try {
    const res = await GET(`/v2/connect/pt/products`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getTransactions = async () => {
  try {
    const res = await GET(`/v2/connect/pt/transactions`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchTransactions = async () => {
  try {
    const res = await GET(`/v2/connect/pt/transactions/fetch`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const exportTransactions = async () => {
  try {
    const res = await GET(`/v2/connect/pt/transactions/export`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getStripeAccount = async () => {
  try {
    const res = await GET(`/v2/connect/pt/account`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getProduct = async (id) => {
  try {
    const res = await GET(`/v2/connect/pt/products`, {
      headers: { ...commonHeaders },
      query: {
        product_id: id
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveProduct = async (body) => {
  try {
    const res = await POST(`/v2/connect/pt/product`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const startOnboardProcess = async (body) => {
  try {
    const res = await POST(`/v2/connect/pt/start_onboard_process`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateProduct = async (body) => {
  try {
    const res = await PUT(`/v2/connect/pt/product`, {
      headers: { ...commonHeaders },
      body,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteProduct = async (id) => {
  try {
    const res = await DELETE(`/v2/connect/pt/product`, {
      headers: { ...commonHeaders },
      body: {
        product_id: id,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const sortList = (list, key = 'name', ascOrder = true) => {
  let sortedList = list;
  if (key == 'name') {
    sortedList = list.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      return 0;
    });
  } else if (key == 'price') {
    sortedList = list.sort((a, b) => {
      if (a.price != b.price) return a.price - b.price;
      if (ascOrder) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      } else {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
      }
      return 0;
    });
  } else if (key == 'status') {
    sortedList = list.sort((a, b) => {
      if (a.approval_status != b.approval_status) return a.approval_status - b.approval_status;
      if (ascOrder) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      } else {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
      }
      return 0;
    });
  }
  if (!ascOrder) return sortedList.reverse();
  return sortedList;
}

export const updateProductsRank = async (sortedList) => {
  try {   
    const res = await POST(`/v2/connect/pt/update_products_rank`,
      {
        body: 
          { 
            sortedList: JSON.stringify(sortedList),
          },
        headers: { ...commonHeaders }
      },
      true,
      "multipart/form-data"
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const sendProductNotification = async (product) => {
  try {
    const res = await POST("/v2/connect/pt/notification/" + product.id, {
      headers: { ...commonHeaders },
      body: {},
    });
    return res;
  } catch (err) {
    throw err;
  }
}