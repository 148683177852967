<script>
    import { _ } from "../../../services/i18n";
    import { getIntegerSection, getDecimalSection } from "../../../services/subscriptionServices.js";
    import { subscriptionInfo, subscription } from "../../../lib/store";
    import moment from "moment";

    export let countCustomerProgress;
    export let countVodsProgress;
    export let currentCustomers;
    export let currentVods;
    export let maxCustomers;
    export let maxVods;
    export let nextInvoiceDate;
    export let priceInterval;
    export let priceValue;
    export let productName;
    export let subscriptionDialogOpen = false;
    export let state;

    let caption = "";
    let text = "";
    let customersSectionColor = "#212121";
    let vodsSectionColor = "#212121";

    // $: console.log('subscriptionInfo', $subscriptionInfo)
    // $: console.log('subscription', $subscription)
    // $: console.log('state', state)

    $: customersSectionColor = currentCustomers > maxCustomers ? '#cd293d' : '#212121';
    $: vodsSectionColor = currentVods > maxVods ? '#cd293d' : '#212121';
    $: if (maxVods === false && currentVods > maxVods) countVodsProgress = '100';

    $: {
        // Modale aggiornamento piano
        if (subscriptionDialogOpen === true) {
            if (state == 'trialing') {
                text = "Il nuovo piano si attiverà al termine del periodo di prova, in data " + $subscription.trial_ends_at + ".";
            } else if (state == 'downgrade_plan') {
                text = "Il nuovo piano si attiverà al termine del periodo di fatturazione, in data " + $subscription.next_invoice_date + ".";
            } else if (state == 'upgrade_plan') {
                text = "Il nuovo piano si attiverà immediatamente e si rinnoverà in data " + $subscription.next_invoice_date + ".";
            } else if ($subscriptionInfo.name === 'main') {
                if (state === 'pricing_change') {
                    text = $_('il_seguente_piano_sara_attivato_pricing_change', { values: {activation_date: $subscription.next_invoice_date} }) + '.';
                }
            } else {
                // SITUAZIONE FINE TRIAL
                text = $_('il_piano_si_rinnova') + ' ' +  getNextInvoiceDate(priceInterval) + '.';
            }
        } 
        // Visualizzazione informazioni piano
        else {
            if ($subscriptionInfo.status === 'trialing') {
                if ($subscriptionInfo.default_trial_product === false) {
                    text = 'Stai continuando ad usare il periodo di prova gratuita. Il nuovo piano si attiverà il ' + $subscription.trial_ends_at + '.';
                    caption = 'Se al termine del periodo di prova ci saranno più Clienti attivi o Video On Demand di quanti consentiti dal piano che hai scelto, ti verrà chiesto di effettuare un upgrade per continuare ad utilizzare REVOO.';
                } else {
                    text = $_('il_periodo_di_prova_termina')  + ' ' + $subscription.trial_ends_at + '.';
                }
            } else if ($subscription.scheduled_subscription_name != null) {
                text = "Questo piano terminerà in data " + $subscription.next_invoice_date + " e sarà sostituito dal piano " + $subscription.scheduled_subscription_name + ".";
                // text = "Questo piano terminerà in data " + getNextInvoiceDate(priceInterval) + " e sarà sostituito dal piano " + $subscription.scheduled_subscription_name + ".";
            } else if ($subscriptionInfo.default_trial_product === true && $subscriptionInfo.status !== 'trialing') {
                text = "Il tuo trial è scaduto, scegli un piano per continuare ad utilizzare REVOO.";
            } else {
                // ACTIVE
                text = $_('il_piano_si_rinnova') + ' ' +  $subscription.next_invoice_date + '.';
            }
        }
    }

    const getNextInvoiceDate = (interval = 'month') => {
        let today = moment();
        let date = interval === 'month'
        ? today.add(1, 'month')
        : today.add(1, 'year');
        return date.format('DD/MM/YYYY');
    }
</script>

<div class="subscription-detail-frame {subscriptionDialogOpen ? `subscription-detail-frame--dialog` : ``}">
    <div class="d-flex justify-content-between">
        <div style="max-width: 80%;">
            <div class="pb-2 text-left">
                <div class="title d-flex">
                    <h4 style="font-weight: 600;">{productName}</h4>
                    {#if $subscriptionInfo.default_trial_product === false && subscriptionDialogOpen === false}
                        {#if $subscriptionInfo.status === 'trialing'}
                            <span class="badge badge-info ml-2" style="height: 1.5em; margin-top: 5px;">TRIAL</span>
                        {:else if $subscriptionInfo.status === 'active'}
                            <span class="badge badge-success ml-2" style="height: 1.5em; margin-top: 5px;">ATTIVO</span>
                        {:else if $subscriptionInfo.status === 'past_due'}
                            <span class="badge badge-warning ml-2" style="height: 1.5em; margin-top: 5px;">NON PAGATO</span>
                        {:else if $subscriptionInfo.status === 'unpaid'}
                            <span class="badge badge-danger ml-2" style="height: 1.5em; margin-top: 5px;">NON PAGATO</span>
                        {/if}
                    {/if}
                </div>
                <span style={subscriptionDialogOpen ? `font-size: 0.8em;` : ``}>{text}</span>
            </div>
            {#if subscriptionDialogOpen === false}
                <!-- Clienti -->
                <div>
                    <span style="color: {customersSectionColor}">{$_('clienti_attivi')}: {currentCustomers}/{maxCustomers != null ? maxCustomers : "∞"}</span>
                    <div class="progress mt-1">
                        <div 
                            class="progress-bar"
                            role="progressbar"
                            style={`width: ${countCustomerProgress}%; background-color: ${customersSectionColor};`}
                            aria-valuenow="{countCustomerProgress}"
                            aria-valuemin="0"
                            aria-valuemax="{!isNaN(maxCustomers) ? maxCustomers : 99999}" 
                        />
                    </div>
                </div>
                <!-- Video on demand -->
                <div class="mt-2">
                    <span style="color: {vodsSectionColor}">{$_('video_allenamenti')}: {currentVods}/{maxVods !== false ? (maxVods != null ? maxVods : "∞") : "0"}</span>
                    <div class="progress mt-1">
                        <div 
                            class="progress-bar"
                            role="progressbar"
                            style={`width: ${countVodsProgress}%; background-color: ${vodsSectionColor};`}
                            aria-valuenow="{countVodsProgress}"
                            aria-valuemin="0"
                            aria-valuemax="{!isNaN(maxVods) ? maxVods : 99999}" 
                        />
                    </div>
                </div>
            {/if}
        </div>
        <div>
            <div>
                <h5>
                    <div class="product-price">
                        <span class="integer-section">€ {getIntegerSection(priceValue.toFixed(2))}</span>
                        <span class="decimal-section">,{getDecimalSection(priceValue.toFixed(2))}</span>
                        <span>/ {priceInterval == 'month' ? $_('mese') : $_('anno')}</span>
                    </div>
                </h5>
            </div>
        </div>
    </div>
    {#if caption !== ""}
        <div class="mt-2">
            <i style="font-size: 0.8em;">{caption}</i>
        </div>
    {/if}
</div>

<style>
    .subscription-detail-frame {
        width: 100%;
    }

    .subscription-detail-frame--dialog {
        background-color: rgba(0,0,0,0.05);
        padding: 20px;
    }

    .progress {
        width: 18em;
    }

    .integer-section {
		margin-right: -5px;
		font-size: 1em;
	}

	.decimal-section {
		font-size: 0.7em;
	}
</style>