<script>
  import { _ } from "../../../services/i18n";
  import { user } from "../../../lib/store";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import CustomerSubHeader from "../../Team/Customer/CustomerSubHeader.svelte";
  import Header from "../../ReusableLayouts/Header.svelte";
  import MsgError from "../../ReusableLayouts/MsgError.svelte";
  import HeaderOneLine from "../../ReusableLayouts/HeaderOneLine.svelte";
  import ProductsList from "./List/ListLayout.svelte";
  import Transactions from "./Transactions/TransactionsLayout.svelte";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  const params = new URLSearchParams(location.search);

  let active;
  let activeTab = $_("prodotti");
  let component = [$_("prodotti")];
  let headerProps;
  let msgError;
  let number;
  let options = {
    [$_("prodotti")]: ProductsList,
    [$_("transazioni")]: Transactions,
  };
  let profileImage = "";
  let props = {};
  let search = "";
  let searchParamsTab = "list";
  let subHeaderProps;
  let tabs = [
    $_("prodotti"),
    $_("transazioni"),
  ];

  $: headerProps = {
    buttonIcon: "add",
    buttonLabel: $_("nuovo_prodotto"),
    buttonNavigate: "/product",
    labelNumber: activeTab,
    nameTitle: $_("prodotti"),
    number,
    search,
  };

  $: subHeaderProps = {
    active,
    profileImage,
  };

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  $: {
    if (searchParamsTab == "list") {
      component = $_("prodotti");
      activeTab = $_("prodotti");
    } else if (searchParamsTab == "transactions") {
      component = $_("transazioni");
      activeTab = $_("transazioni");
    }
  }

  const handleActiveTabChanged = (e) => {
    if ([e.detail.tab] == $_("prodotti"))
      navigate(`/products?tab=list`, {
        replace: true,
      });
    else if ([e.detail.tab] == $_("transazioni"))
      navigate(`/products?tab=transactions`, {
        replace: true,
      });
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    activePage = 0;
    await handleUpdateTrainingCards();
  };

  onMount(async () => {
    // window.scrollTo(0, 0);
  });
</script>

<div class="headerBackground">
  <Header
    {activeTab}
    {tabs}
    asTextAndIcon={false}
    asTitle={false}
    component={HeaderOneLine}
    noBackground={true}
    props={headerProps}
    sticky={false}
    subTitle={false}
    summary={false}
    tabNavigationEnabled={true} 
    text={false} 
    on:activeTabChanged={handleActiveTabChanged}
    on:onComponentEvent={handleSearchChanged}
    />
</div>

<div class="container fluid">
  <MsgError {msgError} />

  <svelte:component
    this={options[component]}
    {...props}
    bind:number
  />
</div>

<style>
  @media (max-width: 767px) {
    :global(.headerText) {
      font-size: 0.875rem !important;
    }
  }
</style>
