export const hsLogin = (userEmail) => {
    if (userEmail && window.HubSpotConversations) {
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push(["trackPageView"]);
        _hsq.push([
            "identify",
            {
                email: userEmail
            }
        ]);
        window.HubSpotConversations.widget.refresh();
    }
}