<script>
    import { _ } from "../../../../services/i18n";
    import moment from "moment";
    import { navigate } from "svelte-routing";
    import DraftSwitcher from "./DraftSwitcher.svelte";
    import AssignedTrainingCardDeadline from "./AssignedTrainingCardDeadline.svelte";
    import GroupLabels from "../../../ReusableLayouts/GroupLabels.svelte";    

    export let tc;
    export let isMobile;

    const getDuration = (length) => {
        return length
            ? $_("settimane", {
                values: { settimane: length },
            })
            : "-";
    };
</script>

<style>
    .label {
        opacity: 0.5;
        font-size: 0.75rem;
        color: black;
    }
    .op-8 {
        opacity: 0.8;
    }
    .op-2 {
        opacity: 0.2 !important;
    }
    .tc-start {
        min-width: 89px;
    }
</style>

<div
    class="col-1 d-flex align-items-center text-start op-8"
    class:op-2={tc.is_draft}
    on:click|stopPropagation={() => {
        return;
    }}>
    {#if tc.is_active && tc.is_active_group_customer_relation !== false && !tc.is_assigned_to_group}
        <DraftSwitcher
            on:updateDraftTrainingCard
            value={tc.is_draft == 0 ? true : false}
            trainingCardId={tc.id} />
    {/if}
</div>
{#if tc.is_active && tc.is_active_group_customer_relation !== false}
    <div
        class="d-flex flex-column align-items-start justify-content-center
               col-5 col-sm-3 col-md-4 col-lg-2 
               op-8 px-0"
        class:op-2={tc.is_draft}>
        {#if tc.is_assigned_to_group}
            <GroupLabels groups={tc.groups} mx={false} />
        {/if}
        <div>
            <span>
                {tc.name}
            </span>
        </div>
    </div>
{:else}
    <div
        class="d-flex flex-column align-items-start justify-content-lg-start
               col-5 col-sm-3 col-md-4 col-lg-5 
               op-8 px-0"
        class:op-2={tc.is_draft}>
        {#if tc.is_assigned_to_group}
            <GroupLabels groups={tc.groups} mx={false} />
        {/if}
        <div>
            <span>
                {tc.name}
            </span>
        </div>
    </div>
{/if}
<div class="col-3 col-md-2 d-none d-sm-block px-2 text-center" class:op-2={tc.is_draft}>
    <AssignedTrainingCardDeadline {tc} />
</div>
{#if tc.is_active && tc.is_active_group_customer_relation !== false}
    <div
        class:op-2={tc.is_draft}
        class={`text-center px-0 d-none d-lg-block tc-start`}>
        <div class="col-12 label px-0">{$_('inizio')}</div>
        <div class="col-12 px-0">
            {tc.start ? moment(tc.start).format('DD/MM/YYYY') : '-'}
        </div>
    </div>
    <div class:op-2={tc.is_draft} class="col-lg-2 text-center d-none d-lg-block">
        <div class="col-12 label">{$_('durata')}</div>
        <div class="col-12">{getDuration(tc.length)}</div>
    </div>
{:else}
    <div class="d-none d-sm-block" />
{/if}
