<script>
    import { _ } from "svelte-i18n";
    import Card, { Content } from "@smui/card";
    import SectionTitle from "../SectionTitle.svelte";
</script>

<style>
    * :global(.customCardContentText) {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
</style>

<Card>
    <Content class="customCardContentText h100">
        <SectionTitle text={$_('fatture')} />
        <div class="row">
            <span
                class="col-12">{@html $_('fatture_inviate_automaticamente')}</span>
        </div>
    </Content>
</Card>
