<script>
  import { _ } from "../../../../services/i18n";
  import { customer } from "../../../../lib/store";
  import { getCombinationString } from "../../../../lib/utils";
  import { tick } from "svelte";

  export let location = {};

  let card = {};
  $: card = location.state ? location.state.card : "";
  $: (async () => {
    if (card.workouts) {
      setTimeout(() => {
        openPopup();
      }, 500);
    }
  })();

  const openPopup = async () => {
    let divContents = document.getElementById("training-card-to-be-printed")
      .innerHTML;
    let a = window.open("", "", "height=800, width=800");
    await tick();
    if (a && a.document !== null) {
      a.document.write("<html>");
      a.document.write("<body>");
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      a.print();
    }
  };

  let headers = [
    $_("combinazione"),
    $_("set"),
    $_("esercizio"),
    $_("reps"),
    $_("peso"),
    $_("rest"),
    $_("note"),
  ];
</script>

<div id="training-card-to-be-printed" style="padding: 3rem 3rem">
  {#if card}
    {#if (card.name && card.length && card.start && card.end)}
      <div style="display: flex; flex-direction: column; padding-bottom: .5rem">
        <span style="padding-top: .5rem"><span style="font-weight: bold">
            {$_('nome_piano')}
          </span>: {card.name}</span>
        <span style="padding-top: .5rem"><span
            style="font-weight: bold">{$_('durata')}</span>: {card.length ? $_(
                'settimane',
                {
                  values: { settimane: card.length },
                }
              ) : '-'}</span>
        <span style="padding-top: .5rem"><span
            style="font-weight: bold">{$_('nome_atleta')}</span>: {$customer.first_name}
          {$customer.last_name}</span>
        <span style="padding-top: .5rem"><span
            style="font-weight: bold">{$_('data_di_inizio')}</span>: {card.start || '-'}</span>
        <span style="padding-top: .5rem"><span
            style="font-weight: bold">{$_('data_di_fine')}</span>: {card.end || '-'}</span>
      </div>
    {/if}
    {#if (card && card.workouts && Array.isArray(card.workouts))}
      {#each card.workouts as w}
        <div style="padding: 1rem 0; font-weight: bold">{w.workoutName}</div>
        <table
          class="responsive"
          style="width: 100%;
              1px solid #cbcbcb;
            border-collapse: collapse; font-family: 'Avenir Next', Helvetica, sans-serif;">
          <thead>
            <tr>
              {#each headers as header}
                <th
                  style="font-weight: bold; text-align: left; padding: 6px; border: 1px solid #cbcbcb">
                  {header}
                </th>
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each w.combinations as comb, i}
              <tr style={i % 2 ? '' : 'background: #eee;'}>
                <td
                  style="text-align: left; padding: 6px; border: 1px solid #cbcbcb">
                  {getCombinationString($_, comb.method)}
                </td>
                <td
                  style="text-align: left; padding: 6px; border: 1px solid #cbcbcb">
                  {#if comb.method == 'emom'}
                    {comb.emom_time || '-'} / {comb.emom_sets || '-'}
                  {:else if comb.method != 'normal'}
                    {comb.supersets || '-'}
                  {:else}
                    {#each comb.exercises[0].details as detail}
                      <div style="display:flex; flex-direction: column">
                        <div>{detail.sets || '-'}</div>
                      </div>
                    {/each}
                  {/if}
                </td>
                <td
                  style="text-align: left; padding: 6px; border: 1px solid #cbcbcb; max-width: 12rem">
                  {#each comb.exercises as ex}
                    <div style="display:flex; flex-direction: column">
                      <div>{ex.name}</div>
                    </div>
                  {/each}
                </td>
                <td
                  style="text-align: left; padding: 6px; border: 1px solid #cbcbcb">
                  {#each comb.exercises as ex}
                    <div style="display:flex; flex-direction: column">
                      {#each ex.details as detail}
                        <div>
                          {detail.reps.value ? (detail.reps.value == 9999 ? 'max' : detail.reps.value) : '-'}
                        </div>
                      {/each}
                    </div>
                  {/each}
                </td>
                <td
                  style="text-align: left; padding: 6px; border: 1px solid #cbcbcb">
                  {#each comb.exercises as ex}
                    <div style="display:flex; flex-direction: column">
                      {#each ex.details as detail}
                        <div>
                          {detail.weight.value ? (detail.weight.type == 'max' ? '' : detail.weight.value) : '-'}
                          {detail.weight.type}
                        </div>
                      {/each}
                    </div>
                  {/each}
                </td>
                <td
                  style="text-align: left; padding: 6px; border: 1px solid #cbcbcb">
                  {#each comb.exercises as ex}
                    <div style="display:flex; flex-direction: column">
                      {#each ex.details as detail}
                        <div>{comb.method != 'emom' ? detail.rest : '-'}</div>
                      {/each}
                    </div>
                  {/each}
                </td>
                <td
                  style="text-align: left; padding: 6px; border: 1px solid #cbcbcb">
                  {#each comb.exercises as ex}
                    <div style="display:flex; flex-direction: column">
                      <div>{ex.note || '-'}</div>
                    </div>
                  {/each}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/each}
    {/if}
  {/if}
</div>
