<script>
    import { _, locale } from "../../services/i18n";
    import { activeSection, user } from "../../lib/store";
    import { createEventDispatcher, onMount } from "svelte";
    import { Icon } from "@smui/common";
    import { navigate } from "svelte-routing";
    import Button, { Label } from "@smui/button";
    import Textfield, { Input } from "@smui/textfield";
    import SvelteTooltip from "svelte-tooltip";

    export let buttonReminderIcon = "notification_add";
    export let buttonReminderlLabel = "";
    export let buttonReminderDialog = false;
    export let buttonIcon = "event";
    export let buttonLabel;
    export let buttonNavigate = "";
    export let labelNumber = "";
    export let noNumber = false;
    export let parseLabel = false;
    export let nameTitle = "Header";
    export let number = 0;
    export let search = "";

    const dispatch = createEventDispatcher();

    let doneTypingInterval = 600;
    let selectedSearch = false;
    let typingTimer;

    const closeSearchBar = () => {
        search = "";
        selectedSearch = false;
    };

    const configSearch = () => {
        let searchInput = document.getElementById("searchInput");
        if (searchInput) {
            searchInput.addEventListener("keyup", () => {
                delaySearch();
            });
        }
    };

    const delaySearch = () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(
            (_) => dispatch("onComponentEvent", { search }),
            doneTypingInterval
        );
    };

    onMount(() => {
        configSearch();
    });
</script>

{#if !selectedSearch}
    <div
        class={nameTitle == $_('team') && !env.isProd && $locale == 'it' ? 'header__container__team' : 'header__container'}
    >
        <div class="row no-gutters centered">
            <h1>{nameTitle}</h1>
            {#if labelNumber != $_('assegnati_ai_gruppi') && !noNumber}
                <span class="title__label">
                    {number}
                    {#if parseLabel}
                        {@html labelNumber}
                    {:else}{labelNumber}{/if}
                </span>
            {/if}
        </div>
        {#if labelNumber != $_('assegnati_ai_gruppi')}
            <div
                class="row no-gutters {$activeSection != 'Video' ? `centered` : ``}
                    desktop"
            >
                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                        <Textfield
                            class={`${selectedSearch ? 'search--opened' : ''}`}
                            variant="outlined"
                            withLeadingIcon
                            id="searchInput"
                            bind:value={search}
                            label={$_('cerca_per_nome')}
                        >
                            <Icon
                                class={`material-icons searchIcon ${selectedSearch ? 'search--opened' : ''}`}
                            >
                                search
                            </Icon>
                        </Textfield>
                        <Button
                            color="secondary"
                            class="navigateButton"
                            variant="unelevated"
                            on:click={() => dispatch('openAppointmentDialog')}
                        >
                            <Label>
                                <!-- {#if buttonIcon}
                                    <Icon
                                        role="button"
                                        class="material-icons
                                            mdc-text-field__icon
                                            mdc-text-field__icon--trailing
                                            buttonIcon"
                                    >
                                        {buttonIcon}
                                    </Icon>
                                {/if} -->
                                {buttonLabel}
                            </Label>
                        </Button>
                    </div>
                    {#if buttonReminderDialog && buttonReminderlLabel}
                        <div class="d-flex justify-content-end">
                            <Button
                                color={buttonReminderIcon == 'vpn_key' ? 'primary' : 'secondary'}
                                class="newCustomerBtn"
                                variant="unelevated"
                                on:click={() => dispatch('openReminderDialog')}
                            >
                                <!-- {#if buttonReminderIcon}
                                    <Icon
                                        role="button"
                                        class="material-icons
                                            mdc-text-field__icon
                                            mdc-text-field__icon--trailing
                                            buttonIcon"
                                    >
                                        {buttonReminderIcon}
                                    </Icon>
                                {/if} -->
                                <Label>{buttonReminderlLabel}</Label>
                            </Button>
                        </div>
                    {/if}
                </div>
            </div>
        {/if}
        {#if labelNumber != $_('assegnati_ai_gruppi')}
            <div class="row no-gutters icons centered mobile">
                <div
                    class="mdc-icon-button centered"
                    on:click={() => {
                        selectedSearch = true;
                        configSearch(true);
                    }}
                >
                    <Icon class="material-icons">search</Icon>
                </div>
                <div class="appuntamento-tip">
                    <SvelteTooltip tip={$_('crea_appuntamento')} bottom>
                        <div
                            class="mdc-icon-button centered"
                            on:click={() => dispatch('openAppointmentDialog')}
                        >
                            <Icon class="material-icons">{buttonIcon}</Icon>
                        </div>
                    </SvelteTooltip>
                </div>
                <div class="promemoria-tip">
                    <SvelteTooltip tip={$_('crea_promemoria')} bottom>
                        <div
                            class="mdc-icon-button centered"
                            on:click={() => dispatch('openReminderDialog')}
                        >
                            <Icon
                                class="material-icons mdc-text-field__icon
                                    mdc-text-field__icon--trailing buttonIcon"
                            >
                                {buttonReminderIcon}
                            </Icon>
                        </div>
                    </SvelteTooltip>
                </div>
            </div>
        {/if}
    </div>
    <hr class="mobile" />
{:else}
    <div class="header__container py-2">
        <div class="row no-gutters w100 centered justify-content-center">
            <Textfield
                variant="outlined"
                label={$_('cerca_per_nome')}
                class="w100 search--opened"
                withLeadingIcon
                withTrailingIcon
            >
                <Icon class="material-icons searchIcon search--opened">
                    search
                </Icon>
                <input
                    type="text"
                    id="searchInputMobile"
                    on:input={delaySearch}
                    bind:value={search}
                    class="mdc-text-field__input search--opened"
                />
                <Icon
                    class="material-icons searchIcon search--opened"
                    role="button"
                    tabindex="1"
                    on:click={() => {
                        closeSearchBar();
                    }}
                >
                    clear
                </Icon>
                <div
                    class={`mdc-notched-outline mdc-notched-outline--upgraded search--opened`}
                >
                    <div class="mdc-notched-outline__leading" />
                    <div class="mdc-notched-outline__notch" />
                    <div class="mdc-notched-outline__trailing" />
                </div>
            </Textfield>
        </div>
    </div>
    <hr class="mobile" />
{/if}

<style>
    * :global(.mdc-button) {
        height: 2.5rem;
    }

    * :global(.navigateButton),
    * :global(.newCustomerBtn) {
        min-width: 12.55rem;
    }
    * :global(.mdc-text-field) {
        height: 2.5rem;
        width: 12.2em;
        margin-bottom: 0 !important;
        margin-right: 0.8em;
        opacity: 0.5;
    }
    * :global(.mdc-text-field:not(.mdc-text-field--disabled)) {
        background-color: #fff;
    }
    * :global(.mdc-text-field__input) {
        height: auto;
        margin: auto;
        padding: 0 0.5em !important;
        width: 14em;
    }
    *
        :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
            .mdc-floating-label) {
        top: 12px;
    }
    * :global(.material-icons.searchIcon) {
        padding: 10px 0px 0px 4px !important;
    }

    h1 {
        font-size: 2.1em;
        font-weight: bold;
        margin-right: 0.5em;
        margin-bottom: 0;
    }
    .centered {
        align-items: center;
        align-content: center;
        display: flex;
    }
    .centrated {
        margin: auto;
    }
    .header__container {
        display: flex;
        justify-content: space-between;
        padding: 3rem 0;
    }

    .header__container__team {
        display: flex;
        justify-content: space-between;
        padding: 3rem 0 1rem 0;
    }

    .newCustomerBtnContainer {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 2rem;
    }
    .title__label {
        color: #212121;
        font-weight: 500;
        opacity: 0.8;
    }
    .hide {
        display: none;
    }
    hr {
        margin: 0;
    }
    .mobile {
        display: none;
    }

    @media (max-width: 767px) {
        .mobile {
            display: flex;
        }
        .desktop {
            display: none;
        }
        * :global(.mdc-typography--headline4) {
            font-size: 1.5rem;
        }
        * :global(.mdc-text-field.search--opened) {
            margin: 0 auto;
            width: 100%;
        }
        * :global(.mdc-text-field__input.search--opened) {
            margin-left: 0;
        }
        * :global(.material-icons.search--opened) {
            align-self: center;
            margin: 0 0.5em;
            padding: 0 !important;
        }
        * :global(.material-icons) {
            cursor: pointer;
            margin: 0;
            padding: 0 !important;
        }
        * :global(.mdc-button__label > .mdc-text-field__icon--trailing) {
            margin: 0;
        }
        * :global(.material-icons.searchIcon:not(.search--opened)) {
            padding: 0 !important;
        }
        h1 {
            font-size: 1.6rem;
            margin-bottom: 0;
        }
        .header__container {
            padding: 0.25rem 0;
        }

        .header__container__team {
            padding: 0.25rem 0;
        }
        .centered {
            padding-right: 0px;
        }
    }

    @media (max-width: 660px) {
        * :global(.promemoria-tip .tooltip.bottom) {
            left: unset;
            right: 0;
            transform: translate(0%, 100%);
        }
    }

    @media (max-width: 576px) {
        * :global(.appuntamento-tip .tooltip.bottom) {
            left: unset;
            right: -48px;
            transform: translate(0%, 100%);
        }
        h1 {
            font-size: 1.25rem;
            margin-bottom: 0;
        }
        .title__label {
            font-size: 0.875rem;
        }
    }
</style>
