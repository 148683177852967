<script>
  import "../../TrainingCards/AssignTrainingCardDialog.scss";
  import { _ } from "../../../services/i18n";
  import { customer as storedCustomer, user } from "../../../lib/store";
  import {
    getCustomerSubscriptions as getSubscriptions,
    getCustomerTrainingCards,
    getCustomerVodsAndPlaylists,
  } from "../../../services/customerServices";
  import {
    getMedicalHistories,
    getNotes,
    getCustomerInfo,
    updateCustomerProfile,
  } from "../../../services/teamServices";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import AssignedTrainingCardsLayout from "./Workouts/AssignedTrainingCardsLayout.svelte";
  import AssignedVodsLayout from "./Vods/AssignedVodsLayout.svelte";
  import CustomerCalendar from "../../Calendar/CustomerCalendar.svelte";
  import CustomerSubcriptionsLayout from "./CustomerSubscriptions/CustomerSubscriptionsLayout.svelte";
  import CustomerSubHeader from "./CustomerSubHeader.svelte";
  import Header from "../../ReusableLayouts/Header.svelte";
  import HistoriesLayout from "./MedicalHistories/HistoriesLayout.svelte";
  import moment from "moment";
  import MsgError from "../../ReusableLayouts/MsgError.svelte";
  import NotesLayout from "./Notes/NotesLayout.svelte";
  import ProfileInfo from "./ProfileInfo.svelte";
  import ProgressAndCheck from "./ProgressAndCheck/ProgressAndCheck.svelte";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  const params = new URLSearchParams(location.search);

  let active;
  let activeTab = $_("progressi_check");
  let allCards = [];
  let allCustomerSubscriptions = [];
  let allPlaylistAssignments = [];
  let allVideoAssignments = [];
  let component = [$_("progressi_check")];
  let customer;
  let customerId;
  let customerSubscriptions = [];
  let expiredCards = [];
  let expiredCustomerSubscriptions = [];
  let expiredPlaylistAssignments = [];
  let expiredVideoAssignments = [];
  let firstCards = [];
  let firstCustomerSubscriptions = [];
  let firstPlaylistAssignments = [];
  let firstVideoAssignments = [];
  let hasFetchedCustomerSubscriptions = false;
  let hasFetchedHistories = false;
  let hasFetchedPlaylistAssignments = false;
  let hasFetchedTrainingCards = false;
  let hasFetchedVideoAssignments = false;
  let histories = [];
  let notes = [];
  let hasFetchedNotes = false;
  let loadingNotes = true;
  let isMobile = false;
  let loadingHistories = true;
  let loadingPlaylists = true;
  let loadingSubscriptions = true;
  let loadingTrainingCards = true;
  let loadingVods = true;
  let msgError;
  let nextCards = [];
  let nextCustomerSubscriptions = [];
  let nextPlaylistAssignments = [];
  let nextVideoAssignments = [];
  let options = {
    [$_("progressi_check")]: ProgressAndCheck,
    [$_("allenamenti")]: AssignedTrainingCardsLayout,
    [$_("video")]: AssignedVodsLayout,
    [$_("calendario")]: CustomerCalendar,
    [$_("abbonamenti")]: CustomerSubcriptionsLayout,
    [$_("anamnesi")]: HistoriesLayout,
    [$_("profilo")]: ProfileInfo,
    [$_("note")]: NotesLayout,
  };
  let playlistAssignments = [];
  let previousUrl = "/team";
  let profileImage = "";
  let props = {};
  let searchParamsTab = "progress";
  let subHeaderProps;
  let tabs = [
    $_("progressi_check"),
    $_("allenamenti"),
    $_("video"),
    $_("calendario"),
    $_("abbonamenti"),
    $_("anamnesi"),
    $_("profilo"),
    $_("note"),
  ];
  let trainingCards = [];
  let videoAssignments = [];

  $: if ($user.is_tester) {
    options = {
      [$_("progressi_check")]: ProgressAndCheck,
      [$_("allenamenti")]: AssignedTrainingCardsLayout,
      [$_("video")]: AssignedVodsLayout,
      [$_("calendario")]: CustomerCalendar,
      [$_("abbonamenti")]: CustomerSubcriptionsLayout,
      [$_("anamnesi")]: HistoriesLayout,
      [$_("profilo")]: ProfileInfo,
      [$_("note")]: NotesLayout,
    };
    tabs = [
      $_("progressi_check"),
      $_("allenamenti"),
      $_("video"),
      $_("calendario"),
      $_("abbonamenti"),
      $_("anamnesi"),
      $_("profilo"),
      $_("note"),
    ];
  }

  $: customerId = params.has("customerId")
    ? params.get("customerId")
    : location && location.state && location.state.customerId
    ? location.state.customerId
    : $storedCustomer.customer_id;

  $: previousUrl =
    location && location.state && location.state.previousUrl
      ? location.state.previousUrl
      : "/team";

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  $: {
    if (searchParamsTab == "progress") {
      component = $_("progressi_check");
      activeTab = $_("progressi_check");
    } else if (searchParamsTab == "workouts") {
      component = $_("allenamenti");
      activeTab = $_("allenamenti");
    } else if (searchParamsTab == "vods") {
      component = $_("video");
      activeTab = $_("video");
    } else if (searchParamsTab == "calendar") {
      component = $_("calendario");
      activeTab = $_("calendario");
    } else if (searchParamsTab == "histories") {
      component = $_("anamnesi");
      activeTab = $_("anamnesi");
    } else if (searchParamsTab == "subscriptions") {
      component = $_("abbonamenti");
      activeTab = $_("abbonamenti");
    } else if (searchParamsTab == "profile") {
      component = $_("profilo");
      activeTab = $_("profilo");
    } else if (searchParamsTab == "notes") {
      component = $_("note");
      activeTab = $_("note");
    }
  }

  $: subHeaderProps = {
    active,
    customerId,
    profileImage,
  };

  $: (async () => {
    if (customer) {
      if (component == $_("profilo")) {
        props = {
          customerId,
        };
      } else if (component == $_("anamnesi")) {
        props = {
          active,
          customerId,
          histories,
          loadingHistories,
        };
        if (!hasFetchedHistories) getHistories();
      } else if (component == $_("allenamenti")) {
        if (!hasFetchedTrainingCards) await getTrainingCardsInfo();
        props = {
          customerId,
          firstCards,
          loadingTrainingCards,
          nextCards,
        };
      } else if (component == $_("video")) {
        if (!hasFetchedVideoAssignments) await getVideoAssignments();
        if (!hasFetchedPlaylistAssignments) await getPlaylistAssignments();
        props = {
          customerId,
          firstPlaylistAssignments,
          firstVideoAssignments,
          loadingPlaylists,
          loadingVods,
          nextPlaylistAssignments,
          nextVideoAssignments,
        };
      } else if (component == $_("abbonamenti")) {
        if (!hasFetchedCustomerSubscriptions) await getCustomerSubscriptions();
        props = {
          customerId,
          firstCustomerSubscriptions,
          nextCustomerSubscriptions,
          loadingSubscriptions,
        };
      } else if (component == $_("note")) {
        if (!hasFetchedNotes) await getCustomerNotes();
        props = {
          customerId,
          active,
          notes,
          loadingNotes,
        };
      } else {
        props = { customerId };
      }
    }
  })();

  $: firstCards = allCards.slice(0, 5);
  $: firstCustomerSubscriptions = allCustomerSubscriptions.slice(0, 5);
  $: firstPlaylistAssignments = allPlaylistAssignments.slice(0, 5);
  $: firstVideoAssignments = allVideoAssignments.slice(0, 5);

  $: nextCards = allCards.slice(5);
  $: nextCustomerSubscriptions = allCustomerSubscriptions.slice(5);
  $: nextPlaylistAssignments = allPlaylistAssignments.slice(5);
  $: nextVideoAssignments = allVideoAssignments.slice(5);

  const handleUpdateCards = async () => {
    await getTrainingCardsInfo();
  };

  const handleUpdateAssignedVodsAndPlaylists = async () => {
    await getVideoAssignments();
    await getPlaylistAssignments();
  };

  const handleUpdateCustomer = async (e) => {
    try {
      const body = { customer_id: customerId, ...e.detail.customer };
      const res = await updateCustomerProfile(body);
      for (let key of Object.keys(res)) {
        if (key == "country_code") {
          $storedCustomer.country_code = res.country_code || "39";
        } else {
          $storedCustomer[key] = res[key] == null ? "" : res[key];
        }
      }
    } catch (err) {
      console.log("err handleUpdateCustomer", err);
      displayError(err);
    }
  };

  const getHistories = async () => {
    try {
      const res = await getMedicalHistories(customerId);
      histories = res;
      hasFetchedHistories = true;
    } catch (err) {
      console.log("err get medical histories", err);
      displayError(err);
    }
    loadingHistories = false;
  };

  const getCustomerNotes = async () => {
    try {
      const res = await getNotes(customerId);
      notes = res.data;
      hasFetchedNotes = true;
    } catch (err) {
      console.log("err getting notes", err);
      displayError(err);
    }
    loadingNotes = false;
  };

  const sortByDates = (arrayDates) => {
    const toBeSorted = [...arrayDates];
    return toBeSorted.sort((a, b) => new Date(b.end) - new Date(a.end));
  };

  const getTrainingCardsInfo = async () => {
    try {
      trainingCards = await getCustomerTrainingCards(customerId);
      let endNullCards = [];
      let validCards = [];
      // display only cards that are not expired (or do not expire at all)
      for (const card of trainingCards) {
        const isExpired = moment(card.end).isBefore(new Date());
        if (!card.end) {
          endNullCards = [...endNullCards, card];
        } else if (!isExpired) {
          validCards = [...validCards, card];
        } else {
          expiredCards = [...expiredCards, card];
        }
      }
      const sortedCards = sortByDates(validCards);
      const sortedEndNullCards = sortByDates(endNullCards);
      const sortedExpiredCards = sortByDates(expiredCards);

      allCards = [...sortedCards, ...sortedEndNullCards, ...sortedExpiredCards];
      allCards = allCards.filter((vc) => {
        if (!vc.is_active && vc.workouts.every((w) => w.workout_count == 0))
          return;
        else return vc;
      });
      hasFetchedTrainingCards = true;
    } catch (err) {
      console.log("err getTrainingCardsInfo", err);
      displayError(err);
    }
    loadingTrainingCards = false;
  };

  const getVideoAssignments = async () => {
    try {
      videoAssignments = await getCustomerVodsAndPlaylists(customerId, "video");
      let endNullVideoAssignments = [];
      let validVideoAssignments = [];
      // display only cards that are not expired (or do not expire at all)
      for (const assignment of videoAssignments) {
        const isExpired = moment(assignment.end).isBefore(new Date());
        if (!assignment.end) {
          endNullVideoAssignments = [...endNullVideoAssignments, assignment];
        } else if (!isExpired) {
          validVideoAssignments = [...validVideoAssignments, assignment];
        } else {
          expiredVideoAssignments = [...expiredVideoAssignments, assignment];
        }
      }
      const sortedVideoAssignments = sortByDates(validVideoAssignments);
      const sortedEndNullVideoAssignments = sortByDates(
        endNullVideoAssignments
      );
      const sortedExpiredVideoAssignments = sortByDates(
        expiredVideoAssignments
      );

      allVideoAssignments = [
        ...sortedVideoAssignments,
        ...sortedEndNullVideoAssignments,
        ...sortedExpiredVideoAssignments,
      ];

      hasFetchedVideoAssignments = true;
    } catch (err) {
      console.log("err getVideoAssignments", err);
      displayError(err);
    }
    loadingVods = false;
  };

  const getPlaylistAssignments = async () => {
    try {
      playlistAssignments = await getCustomerVodsAndPlaylists(
        customerId,
        "playlist"
      );
      let endNullPlaylistAssignments = [];
      let validPlaylistAssignments = [];
      // display only cards that are not expired (or do not expire at all)
      for (const assignment of playlistAssignments) {
        const isExpired = moment(assignment.end).isBefore(new Date());
        if (!assignment.end) {
          endNullPlaylistAssignments = [
            ...endNullPlaylistAssignments,
            assignment,
          ];
        } else if (!isExpired) {
          validPlaylistAssignments = [...validPlaylistAssignments, assignment];
        } else {
          expiredPlaylistAssignments = [
            ...expiredPlaylistAssignments,
            assignment,
          ];
        }
      }
      const sortedPlaylistAssignments = sortByDates(validPlaylistAssignments);
      const sortedEndNullPlaylistAssignments = sortByDates(
        endNullPlaylistAssignments
      );
      const sortedExpiredPlaylistAssignments = sortByDates(
        expiredPlaylistAssignments
      );

      allPlaylistAssignments = [
        ...sortedPlaylistAssignments,
        ...sortedEndNullPlaylistAssignments,
        ...sortedExpiredPlaylistAssignments,
      ];

      hasFetchedPlaylistAssignments = true;
    } catch (err) {
      console.log("err getPlaylistAssignments", err);
      displayError(err);
    }
    loadingPlaylists = false;
  };

  const getCustomerSubscriptions = async () => {
    try {
      customerSubscriptions = await getSubscriptions(customerId);

      let endNullCustomerSubscriptions = [];
      let validCustomerSubscriptions = [];
      // display only cards that are not expired (or do not expire at all)
      for (const subscription of customerSubscriptions) {
        const isExpired = moment(subscription.end).isBefore(new Date());
        if (!subscription.end) {
          endNullCustomerSubscriptions = [
            ...endNullCustomerSubscriptions,
            subscription,
          ];
        } else if (!isExpired) {
          validCustomerSubscriptions = [
            ...validCustomerSubscriptions,
            subscription,
          ];
        } else {
          expiredCustomerSubscriptions = [
            ...expiredCustomerSubscriptions,
            subscription,
          ];
        }
      }
      const sortedCustomerSubscriptions = sortByDates(
        validCustomerSubscriptions
      );
      const sortedEndNullCustomerSubscriptions = sortByDates(
        endNullCustomerSubscriptions
      );
      const sortedExpiredCustomerSubscriptions = sortByDates(
        expiredCustomerSubscriptions
      );

      allCustomerSubscriptions = [
        ...sortedCustomerSubscriptions,
        ...sortedEndNullCustomerSubscriptions,
        ...sortedExpiredCustomerSubscriptions,
      ];

      hasFetchedCustomerSubscriptions = true;
    } catch (err) {
      loadingSubscriptions = false;
      console.log("err getCustomerSubscriptions", err);
      displayError(err);
    }
    loadingSubscriptions = false;
  };

  const handleActiveTabChanged = (e) => {
    if ([e.detail.tab] == $_("progressi_check"))
      navigate(`/customer?tab=progress&customerId=` + customerId, {
        replace: true,
        state: { customerId },
      });
    else if ([e.detail.tab] == $_("allenamenti"))
      navigate(`/customer?tab=workouts&customerId=` + customerId, {
        replace: true,
        state: { customerId },
      });
    else if ([e.detail.tab] == $_("video"))
      navigate(`/customer?tab=vods&customerId=` + customerId, {
        replace: true,
        state: { customerId },
      });
    else if ([e.detail.tab] == $_("abbonamenti"))
      navigate(`/customer?tab=subscriptions&customerId=` + customerId, {
        replace: true,
        state: { customerId },
      });
    else if ([e.detail.tab] == $_("anamnesi"))
      navigate(`/customer?tab=histories&customerId=` + customerId, {
        replace: true,
        state: { customerId },
      });
    else if ([e.detail.tab] == $_("profilo"))
      navigate(`/customer?tab=profile&customerId=` + customerId, {
        replace: true,
        state: { customerId },
      });
    else if ([e.detail.tab] == $_("calendario"))
      navigate(`/customer?tab=calendar&customerId=` + customerId, {
        replace: true,
        state: { customerId },
      });
    else if ([e.detail.tab] == $_("note"))
      navigate(`/customer?tab=notes&customerId=` + customerId, {
        replace: true,
        state: { customerId },
      });
  };

  const handleActiveRelationChanged = (e) => {
    active = e.detail.active;
  };

  const handleHistoryRemoved = (e) => {
    const filteredHistories = histories.filter((i) => i.id != e.detail.id);
    histories = filteredHistories;
  };

  const handleHistoryAdded = (e) => {
    histories = [e.detail.history, ...histories];
  };

  const displayError = (err = null) => {
    let timeoutTime = 3000;
    msgError = $_("si_è_verificato_un_errore");
    if (err.message.exception && err.message.exception === 'PlanConflictException') {
      
      msgError = $_("plan_conflict_exception");
      timeoutTime = 9000000;
    }
    setTimeout(() => {
      msgError = "";
    }, timeoutTime);
  };

  onMount(async () => {
    window.scrollTo(0, 0);
    try {
      const res = await getCustomerInfo(customerId);
      for (let key of Object.keys(res)) {
        if (key == "prefix" && !res[key]) res[key] = "39";
        else if (res[key] == null) res[key] = "";
      }
      storedCustomer.set(res);
      customer = res;
      profileImage = res.profile_image;
      active = res.active;
    } catch (err) {
      console.log("err get customer", err);
      displayError(err);
    }
  });
</script>

<style>
  @media (max-width: 767px) {
    :global(.headerText) {
      font-size: 0.875rem !important;
    }
  }
</style>

<Header
  text={`${isMobile ? $_('indietro') : $_('cliente')}`}
  asTextAndIcon={true}
  asTitle={false}
  noBackground={false}
  subTitle={false}
  summary={false}
  sticky={false}
  {tabs}
  {activeTab}
  component={CustomerSubHeader}
  on:onComponentEvent={handleActiveRelationChanged}
  props={subHeaderProps}
  on:activeTabChanged={handleActiveTabChanged}
  tabNavigationEnabled={true}
  on:goBack={() => navigate(previousUrl)} />

<div class="container fluid">
  <MsgError {msgError} />

  <svelte:component
    this={options[component]}
    {...props}
    on:updateCards={handleUpdateCards}
    on:updateCustomer={handleUpdateCustomer}
    on:updateAssignedVodsAndPlaylists={handleUpdateAssignedVodsAndPlaylists}
    on:historyRemoved={handleHistoryRemoved}
    on:historyAdded={handleHistoryAdded}
    bind:loadingSubscriptions />
</div>
