<script>
  import { _ } from "../../services/i18n";
  import AuthShortcut from "./AuthShortcut.svelte";
  import LoginForm from "./LoginForm.svelte";
  import PasswordRecoverForm from "./PasswordRecover/PasswordRecoverForm.svelte";
  import RegisterForm from "./RegisterForm.svelte";
  import SetNewPasswordForm from "./PasswordRecover/SetNewPasswordForm.svelte";

  const options = {
    login: LoginForm,
    register: RegisterForm,
    passRecover: PasswordRecoverForm,
    setNewPassword: SetNewPasswordForm,
  };

  export let component = "login";
  export const showNavbar = false;
</script>

<style>
  .authBackground {
    min-height: 100vh;
    background-color: white;
    padding-bottom: 5rem;
  }
  @media (min-width: 576px) {
    .authBackground {
      background-image: url(/assets/login_background.png);
      background-size: cover;
    }
  }
  @media screen and (max-width: 480px) {
    * :global(.mdc-card) {
      box-shadow: none;
    }
    * :global(.btn.btn--form) {
      height: 2.5rem;
      min-width: 8.75rem;
    }
  }
</style>

<div class="container-fluid flexy authBackground">
  <div class="card-container row justify-content-center">
    <div class="col-xs-12 col-md-6 flexy">
      <svelte:component this={options[component]} />
    </div>

    <AuthShortcut
      isOnRegisterPage={component !== 'login' && component !== 'passRecover'} 
      isOnSetNewPasswordPage={component === 'setNewPassword'} />
  </div>
</div>
