<script>
  export let white = false;
</script>

<style>
  .spinner {
    bottom: 50%;
    color: var(--primary, #cd293d);
    height: 1rem;
    left: 0;
    margin: 0 auto;
    position: relative;
    right: 0;
    width: 1rem;
  }
</style>

<span class="spinner-border spinner ml-1" style="{white ? `color: #fff;` : ``}" role="status" />
