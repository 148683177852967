<script>
    import { _ } from "../../../services/i18n";
    import { createEventDispatcher, onMount } from "svelte";
    import { dndzone } from 'svelte-dnd-action';
    import { deleteAttachment, getTrainingCardAttachments, updateTrainingCardAttachmentsRank } from "../../../services/trainingCardServices";
    import { group } from "../../../lib/store";
    import { Icon } from "@smui/common";
    import { navigate } from "svelte-routing";
    import AttachmentDialog from "./AttachmentDialog.svelte";
    import BannerHelp from "../../ReusableLayouts/BannerHelp.svelte";
    import Button, { Label } from "@smui/button";
    import Card, { Content } from "@smui/card";
    import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
    import Dialog, { Title, Actions } from "@smui/dialog";
    import IconButton from "@smui/icon-button";
    import Loading from "../../ReusableLayouts/Loading.svelte";
    import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";
    import LoadingIconButton from "../../ReusableLayouts/LoadingIconButton.svelte";
    import NoItemsToShow from "../../ReusableLayouts/NoItemsToShow.svelte";
    import SvelteTooltip from 'svelte-tooltip';
  
    export let customerId;
    export let trainingCard;

    const dispatch = createEventDispatcher();

    let attachment = {
      name: '',
      category: 0,
      rank: 0,
    };
    let attachmentDialog = false;
    let attachmentDialogButtonText =  $_('carica');
    let attachments = [];
    let attachmentsLoaded = false;
    let biggerText = $_('non_ci_sono_allegati_al_piano');
    let buttonText = $_('aggiungi_file');
    let categories = [];
    let confirmationDeleteDialog;
    let deleting = false;
    let deletingId = null;
    let disableButtons = false;
    let icon = 'note_add';
    let loading = true;
    let openDialog;
    let quitSaving = false;
    let saving = false;
    let sortedAttachments = [];
    let upperText = $_('nessun_allegato');

    categories = [
      { label: $_("alimentazione"), value: 0 },
      { label: $_("modulo_progressi"), value: 1 },
      { label: $_("calendario"), value: 2 },
      { label: $_("guida"), value: 3 },
      { label: $_("altro"), value: 4 },
    ];

    $: if (trainingCard) {
      if (!attachmentsLoaded) {
        getAttachments();
        attachmentsLoaded = true;
      }
    }

    const displayError = () => {
      let msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 3000);
    };

    const getAttachments = async () => {
      try {
        loading = true;
        const res = await getTrainingCardAttachments(trainingCard.id);
        attachments = res;
        loading = false;
      } catch (err) {
        console.log("getAttachments err", err);
        displayError();
        loading = false;
      }
    };
    
    const leavePage = () => {
      if (customerId) {
        navigate("/customer?tab=workouts", {
          state: { customerId },
        });
      } else if (trainingCard.is_assigned_to_group || trainingCard.assignedToGroups) {
        if ($group && $group.id) {
          navigate("group?tab=workouts&groupId=" + $group.id);
        } else {
          navigate("/team?tab=gruppi");
        }
      } else {
        navigate("/training-cards");
      }
    }

    const handleEditButtonClicked = (item) => {
      attachment = item;
      attachmentDialogButtonText = $_('sostituisci');
      openDialog = true;
    }

    const handleViewButtonClicked = (item) => {
      window.open(item.url, '_blank');
    }

    const handleNewAttachmentButtonClicked = () => {
      attachment.rank = attachments.length > 0 
        ? attachments.length - 1
        : 0;
      attachmentDialogButtonText = $_('carica');
      openDialog = true;
    }

    const handleDeleteButtonClicked = (item) => {
      deleting = true;
      deletingId = item.id;
      confirmationDeleteDialog.open();      
    }

    const handleDeleteConfirmationFromDialog = async () => {
      confirmationDeleteDialog.close();
      await deleteAttachment(deletingId, trainingCard.id);
      deleting = false;
      deletingId = null;
      getAttachments();
    }

    const handleUndoDeleteFromDialog = async () => {
      deleting = false;
      deletingId = null;
      confirmationDeleteDialog.close();
    }

    const handleSort = async (e) => {
      attachments = e.detail.items;
    }

    const handleSortFinalized = async (e) => {
      attachments = e.detail.items;
      sortedAttachments = [];
      attachments.forEach(a => {
        sortedAttachments.push(a.id);
      });
      await updateTrainingCardAttachmentsRank(sortedAttachments, trainingCard.id);
    };

    const handleCloseAttachmentDialog = (e) => {
      openDialog = false;
      setAttachmentDefaultValues();
    }

    const setAttachmentDefaultValues = () => {      
      attachment = {
        name: '',
        category: 0,
        rank: 0,
      }
    }

    const getCategory = (categoryId) => {
      let category;
      categories.map(i => { if (i.value === categoryId) category = i.label });
      return category;
    }
  </script>
  
  <style>
    :global(.list-no-style) {
      padding-inline-start: unset;
      list-style-type: none;
    }
    * :global(.button--bigger) {
      min-width: 8rem;
    }
    * :global(.invisibleCard--loader) {
      min-height: 40vh;
    }
    @media (max-width: 1023px) {
      .onlyDesktop {
        display: none;
      }
    }

    .nameWrapper {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
    }

    * :global(.primary) {
        color: var(--primary);
    }

    .label {
      font-size: 0.8rem;
      font-weight: 500;
      opacity: 0.7;
    }
  </style>
  
  <Dialog bind:this={confirmationDeleteDialog}>
    <ConfirmationDialog
      text={$_('sei_sicuro_di_voler_eliminare_l_allegato')}
      confirmationButtonLabel={$_('elimina')}
      on:closeDialog={handleUndoDeleteFromDialog}
      on:onConfirmation={handleDeleteConfirmationFromDialog} />
  </Dialog>
  
  <AttachmentDialog 
    {attachment}
    {categories}
    {openDialog}
    buttonText={attachmentDialogButtonText}
    trainingCardId={trainingCard.id}
    on:closeAttachmentDialog={handleCloseAttachmentDialog} 
    on:fetchAttachments={getAttachments} />
  
  
  <div class="container my-3">
    <BannerHelp state="attachments"/>
  </div>

  {#if !loading}
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end align-items-center my-3">
          <Button
            class="ml-4"
            color="secondary"
            variant="unelevated"
            disabled={attachments && attachments.length > 2}
            on:click={() => handleNewAttachmentButtonClicked()}>
            <Label>
              <Icon
                role="button"
                class="material-icons mdc-text-field__icon
                  mdc-text-field__icon--trailing">
                note_add
              </Icon>
              {$_('aggiungi_file')}
            </Label>
          </Button>
        </div>
      </div>
    </div>
    {#if attachments.length > 0}
      <div class="row mx-0 justify-content-center" data-recording-gdpr-safe>
        <div class="col-12 px-0" data-recording-gdpr-safe>
          <section use:dndzone={{items: attachments}} on:consider={handleSort} on:finalize={handleSortFinalized}>
            {#each attachments as item(item.id)}
              <Card class="mb-2">
                <Content>
                  <div class="row">
                    <div class="col-1 d-none d-sm-flex align-items-center pl-3">
                      <Icon class="material-icons">drag_indicator</Icon>
                    </div>
                    <div class="col-12 col-sm-11 pl-3 pl-sm-0 d-flex justify-content-between align-items-center">
                      <div class="d-flex flex-column">
                        <span class="label">{getCategory(item.category)}</span>
                        <span class="nameWrapper demiBold">
                          {item.name}
                        </span>
                      </div>
                      <div class="d-flex">
                        <SvelteTooltip tip="{$_('visualizza')}" top >
                          <IconButton
                            on:click={() => handleViewButtonClicked(item)}
                            class="material-icons trainingCardDuplicate"
                            disabled={deletingId && deletingId == item.id}>
                            visibility
                          </IconButton>
                        </SvelteTooltip>
                        <SvelteTooltip tip="{$_('modifica')}" top >
                          <IconButton
                            on:click={() => handleEditButtonClicked(item)}
                            class="material-icons trainingCardDuplicate"
                            disabled={deletingId && deletingId == item.id}>
                            edit
                          </IconButton>
                        </SvelteTooltip>
                        <SvelteTooltip tip="{$_('elimina')}" top >
                          <IconButton
                            on:click={() => handleDeleteButtonClicked(item)}
                            class="material-icons ml-1 primary workoutDelete"
                            disabled={deleting && deletingId == item.id}>
                            {#if deleting && deletingId == item.id}
                              <LoadingIconButton />
                            {:else}
                              delete_outline
                            {/if}
                          </IconButton>
                        </SvelteTooltip>
                      </div>
                    </div>
                  </div>
                </Content>
              </Card>
            {/each}
          </section>
        </div>
      </div>
      <div class="row justify-content-end mt-3">
        <!-- <Button 
          class="btn" 
          disabled={saving || quitSaving}
          on:click={() => exitDialog.open()}>
          <Label>{$_('annulla')}</Label>
        </Button>
        <Button
          class="btn ml-5 mr-3 button--bigger"
          variant="unelevated"
          disabled={disableButtons || saving || quitSaving}
          on:click={() => { updateAttachmentsRank(false) }}>
          <Label>
            <span class="{saving ? `pr-2` : ``}">{$_('salva')}</span>
            {#if saving}
              <LoadingInline />
            {/if}
          </Label>
        </Button> -->
        <Button
          class="btn ml-5 mr-3 button--bigger"
          variant="unelevated"
          disabled={disableButtons || saving || quitSaving}
          on:click={leavePage}>
          <Label>
            <span class="{quitSaving ? `pr-2` : ``}">{$_('salva_ed_esci')}</span>
            {#if quitSaving}
              <LoadingInline />
            {/if}
          </Label>
        </Button>
      </div>
    {:else}
      <NoItemsToShow 
        {biggerText}
        {upperText}
      />
    {/if}
  {:else}
    <Loading />
  {/if}
  