<script>
  import { createEventDispatcher } from "svelte";
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import Button, { Icon, Label } from "@smui/button";
  import { _ } from "../../../../services/i18n";
  import Loading from "../../../ReusableLayouts/Loading.svelte";

  const dispatch = createEventDispatcher();

  export let openUploadingDialog;
  export let uploadingPercentage;
  export let uploadStatus = "";

  $: uploadingPercentage = uploadingPercentage ?? 0;

  let dialog;

  $: 
    {
      if (dialog != undefined && openUploadingDialog == true) {
        dialog.open();
      }
      if (dialog && dialog.isOpen()) {
        dispatch("close");
      }
    }

  const handleCloseUploadingDialog = () => {
    dialog.close();
  }
</script>

<style>
  h5 {
    padding: 0;
  }
  * :global(.uploadingDialog .mdc-dialog__surface) {
    height: 480px;
    min-height: 480px;
  }
  * :global(.uploadingDialog .mdc-select) {
    width: 100%;
  }
  * :global(.uploadingDialog select) {
    height: 2.7rem !important;
  }
  * :global(.mdc-button) {
    background-color: #cd293d !important;
    color: white !important;
    border: 1px solid #cd293d !important;
    width: 160px !important;
  }
  * :global(.mdc-button .secondaryButton) {
    background-color: white !important;
    color: #cd293d !important;
    border: 1px solid white !important;
    width: 160px !important;
  }

  * :global(.mdc-dialog__title + .mdc-dialog__content) {
    padding: 0px 24px;
  }
  * :global(.mdc-dialog__title) {
    display: flex;
    padding: 10px 39px;
    background-color: #f9f9f9;
    opacity: 0.8;
  }
  * :global(.mdc-button.outlinedCustom) {
    background-color: #fff !important;
    border-color: black !important;
    color: black !important;
    max-width: 105px !important;
  }

  * :global(.mdc-button.outlinedCustom span) {
    font-size: 0.9rem;
  }
  * :global(.mdc-text-field__icon--trailing.customLabel) {
    color: #212121;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    opacity: 0.5;
  }
  * :global(.plicoResults.mdc-dialog__content) {
    padding-top: 0;
    padding-bottom: 0;
  }

  * :global(.spinner) {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
  }

  @media (max-width: 767px) {
    * :global(.mdc-dialog__content) {
      max-height: fit-content;
      flex-grow: 0;
    }
  }
</style>

<div>
  <Dialog 
    class="uploadingDialog dialog--xxsmall" 
    bind:this={dialog}
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="mandatory-title"
    aria-describedby="mandatory-content"
  >
    <Title class="d-flex justify-content-center">
      <h5 class="bold pt-3 d-flex align-items-center">
        <span class="pr-2">
          {$_('caricamento_in_corso')}
        </span>
        <span>
          <Loading />
        </span>
      </h5>
    </Title>
    <Content class="plicoResults">
      <div class="container-fluid">
        <div class="row my-4">
          <div class="col-12 py-1 text-center">
            <span>Non lasciare questa pagina fino al termine del caricamento</span>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-12 py-1 text-center">
            <span>Status: {uploadStatus}</span>
          </div>
        </div>
        <hr />
        <div class="row my-4">
          <div class="col-12 py-1 text-center">
            <span>{$_('avanzamento')}</span>
          </div>
          <div class="col-12 py-1 text-center">
            <strong style="font-size: 1.5rem;">{uploadingPercentage}%</strong>
          </div>
        </div>
      </div>
    </Content>
    <Actions style="justify-content: center">
      <Button
        class="secondaryButton btn"
        variant="unelevated"
        style=" background-color: white !important; color: #cd293d !important;
        border: 1px solid white !important; opacity: 0;"
        on:click={handleCloseUploadingDialog}>
        <Label>{$_('annulla')}</Label>
      </Button>
    </Actions>
  </Dialog>
</div>
