<script>
    import { _ } from "../../../services/i18n";
    import AddClients from "./AddClients.svelte";
    import AddGroups from "./AddGroups.svelte";
    import CalendarNotifications from "./CalendarNotifications.svelte";
    import CharacterCounter from "@smui/textfield/character-counter";
    import DatePicker from "../../ReusableLayouts/DatePicker.svelte";
    import MaskInput from "svelte-input-mask";
    import moment from "moment";
    import Textfield from "@smui/textfield";

    export let automaticReopen;
    export let closedReminderDialog = false;
    export let createOnCustomer;
    export let onEdit;
    export let openDialog;
    export let openOnSelect;
    export let reminder;

    let client = { first_name: "" };
    let group = { name: "" };
    let alreadyCallHandleArraysOnEdit = false;
    let clientsAdded, groupsAdded;
    let addNotice = false;

    $: if (!openDialog) addNotice = alreadyCallHandleArraysOnEdit = false;

    $: if (closedReminderDialog) {
        client = { first_name: ""};
        group = { name: "" };
        clientsAdded = [];
        groupsAdded = [];
    }

    $: if (
        (onEdit || automaticReopen || (openDialog && createOnCustomer)) &&
        !alreadyCallHandleArraysOnEdit
    ) {
        handleArraysOnEdit();
        alreadyCallHandleArraysOnEdit = true;
    }

    const handleArraysOnEdit = () => {
        clientsAdded = reminder.customers.map((client) => {
            if (client.hasOwnProperty("first_name"))
                return {
                    id: client.customer_id,
                    email: client.customer_email,
                    ...client,
                };
        });

        groupsAdded = reminder.at_groups.map((group) => {
            if (group.hasOwnProperty("name")) return group;
        });
    };

    const handleClientSelected = (e) => {
        if (e.detail.client.hasOwnProperty("id"))
            reminder.customers = [
                ...reminder.customers,
                { customer_id: e.detail.client.id },
            ];
        else if (e.detail.client.email)
            reminder.customers = [
                ...reminder.customers,
                { customer_email: e.detail.client.email },
            ];
    };

    const handleClientRemoved = (e) => {
        if (e.detail.client.id)
            reminder.customers = reminder.customers.filter(
                (el) => el.customer_id != e.detail.client.id
            );
        else if (e.detail.client.email)
            reminder.customers = reminder.customers.filter(
                (el) => el.customer_email != e.detail.client.email
            );
    };

    const handleGroupSelected = (e) => {
        if (e.detail.group.hasOwnProperty("id"))
            reminder.at_groups = [
                ...reminder.at_groups,
                { group_id: e.detail.group.id },
            ];
    };

    const handleGroupRemoved = (e) => {
        if (e.detail.group.group_id)
            reminder.at_groups = reminder.at_groups.filter(
                (el) => el.group_id != e.detail.group.group_id
            );
    };

    $: dateStartFormatted = reminder.startDate
        ? moment(reminder.startDate).format("DD/MM/YYYY")
        : "";

    $: {
        if (!openOnSelect) {
            let regex2 = /^([0-1]?[0-9]|2[0-3]):[0-5]$/g;
            if (regex2.test(reminder.startHour)) reminder.startHour += "0";
            let dates = {};
            Object.assign(dates, reminder);
            let startHourSplit = dates.startHour.split(":");
            dates.start_datetime = moment(dates.startDate).add({
                hours: startHourSplit[0],
                minutes: startHourSplit[1],
            });
            dates.end_datetime = dates.start_datetime.add(30, "minutes");
            reminder.endDate = dates.end_datetime.format("YYYY-MM-DD");
            reminder.endHour = dates.end_datetime.format("HH:mm");
        }
    }

    const handleDate = (e) => {
        reminder.startDate = e.detail.date;
        openOnSelect = false;
        dateStartFormatted = moment(reminder.startDate).format("YYYY-MM-DD");
    };
</script>

<div class="container-fluid px-0 px-sm-3">
    <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 label">{$_('titolo')}</div>
        <div class="col-12 col-sm-10">
            <Textfield
                variant="outlined"
                class={`textbox w100 my-2 `}
                input$maxlength="255"
                bind:value={reminder.title}
                type="text"
                label={$_('nuovo_promemoria')}
            />
        </div>
    </div>
    <div class="row-grid-auto mt-2 mt-sm-0">
        <div class="row d-flex align-items-center">
            <div class="col-12 col-sm-4 label no-ws">{$_('scadenza')}</div>
            <div class="col-12 col-sm-8 mt-2">
                <DatePicker
                    on:date={(e) => handleDate(e)}
                    defaultDate={dateStartFormatted}
                    width="100"
                    dateFormat="yyyy-mm-dd"
                    maxDate={null}
                />
            </div>
        </div>
        <div class="row d-flex align-items-center mt-4 mt-sm-0">
            <div class="col-auto mt-2">
                <div class="mdc-text-field mdc-text-field--outlined">
                    <MaskInput
                        {...$$props}
                        class="mdc-text-field__input customInput text-center"
                        style="width:80px"
                        placeholder="HH:mm"
                        mask="00:00"
                        type="tel"
                        on:change={(e) => {
                            reminder.startHour = e.detail.inputState.maskedValue;
                        }}
                        on:blur={() => {
                            let temp = reminder.startHour.split(':');
                            let hour = parseInt(temp[0]) >= 23 ? '23' : temp[0];
                            let minute;
                            if (parseInt(temp[0]) < 24) minute = parseInt(temp[1]) >= 60 ? '59' : temp[1];
                            else if (parseInt(temp[0]) >= 24) minute = '59';
                            reminder.startHour = `${hour}:${minute}`;
                        }}
                        bind:value={reminder.startHour}
                    />
                    <div
                        class={`mdc-notched-outline mdc-notched-outline--upgraded`}
                    >
                        <div class="mdc-notched-outline__leading" />
                        <div class="mdc-notched-outline__notch" />
                        <div class="mdc-notched-outline__trailing" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CalendarNotifications bind:object={reminder} bind:addNotice bind:closedReminderDialog />
    <div class="row d-flex align-items-center mt-5 mt-sm-4">
        <div class="col-12 col-sm-2 label">{$_('cliente')}</div>
        <div class="col-12 col-sm-10 mt-2">
            <AddClients
                disabled={reminder.at_groups.length > 0}
                search={client.first_name}
                on:clientSelected={handleClientSelected}
                on:clientRemoved={handleClientRemoved}
                clientSelected={client}
                bind:openDialog
                bind:clientsAdded
                bind:groupsAdded
            />
        </div>
    </div>
    <div class="row d-flex align-items-center mt-2 mt-sm-0">
        <div class="col-12 col-sm-2 label">{$_('gruppo')}</div>
        <div class="col-12 col-sm-10 mt-2">
            <AddGroups
                disabled={reminder.customers.length > 0}
                search={group.name}
                on:groupSelected={handleGroupSelected}
                on:groupRemoved={handleGroupRemoved}
                groupSelected={group}
                bind:openDialog
                bind:groupsAdded
            />
        </div>
    </div>
    <div class="row d-flex align-items-center mt-5 mt-sm-4">
        <div class="col-12 col-sm-2 label">{$_('note')}</div>
        <div class="col-12 col-sm-10">
            <Textfield
                textarea
                input$maxlength="500"
                variant="outlined"
                class={`textbox w-100 my-2`}
                bind:value={reminder.note}
                type="text"
                label=""
            >
                <CharacterCounter>
                    {`${reminder.note.length} / 500`}
                </CharacterCounter>
            </Textfield>
        </div>
    </div>
</div>

<style>
    .row-grid-auto {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
    }

    .row-grid-date {
        display: grid;
        grid-template-columns: repeat(2, 47%);
        gap: 6%;
        align-items: center;
    }
    /* *:global(.row-grid-date .mdc-text-field__input) {
    text-align: center;
  } */

    * :global(.customChip) {
        border-radius: 13.5px;
        background-color: #212121 !important;
        color: #fff !important;
        min-width: fit-content;
        text-transform: inherit;
        font-weight: normal;
        height: 2em;
        margin: auto;
        margin-left: 0.7em;
        margin-top: 0.5rem;
    }
    * :global(.customChip span i) {
        opacity: 0.5;
        background-color: #ffffff;
        border-radius: 50%;
        color: #212;
        font-size: 1.3em;
        width: 1em;
        height: 1em;
        margin-right: 0;
    }

    @media (max-width: 767px) {
        .no-ws {
            white-space: normal;
        }
        .row-grid-auto {
            grid-template-columns: 60% 1fr;
            gap: 12px;
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {
        .label {
            max-width: calc((100vw - 64px) * 0.166);
        }
        .row-grid-auto {
            gap: 0px;
        }
    }

    @media (max-width: 576px) {
        .row-grid-auto {
            grid-template-columns: 1fr 80px;
        }
    }
</style>
