<script>
	import { _ } from "../../../services/i18n";
	import { getExpiringVodsAndPlaylists } from "../../../services/dashboardServices";
	import { onMount } from "svelte";
	import DashboardListWrapper from "../../ReusableLayouts/DashboardListWrapper.svelte";
	
	let attribute = $_('nome');
	let emptyMessage = $_("non_ci_sono_video_on_demand_in_scadenza");
	let list = [];
	let loading = true;
	let title = $_("video_allenamenti");

	const handleUpdateList = async (e) => {
		loading = true;
		emptyMessage = e.detail.range == "next_7" || e.detail.range == "next_14"
			? $_("non_ci_sono_video_on_demand_in_scadenza")
			: $_("non_ci_sono_video_on_demand_scaduti");
		list = await getExpiringVodsAndPlaylists(e.detail.range);
		loading = false;
	}

	onMount(async () => {
		list = await getExpiringVodsAndPlaylists();
		loading = false;
	});
</script>

<div class="w100 pt-4 pt-lg-0 pl-lg-4">
	<DashboardListWrapper {attribute} {list} {title} {loading} {emptyMessage} on:updateList={handleUpdateList} />
</div>

<style>
</style>