<script>
	import { _ } from "../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
	import { LightPaginationNav } from 'svelte-paginate'
  import { navigate } from 'svelte-routing';
  import { sortList } from "../../services/dashboardServices";
  import ListHeader from "../ReusableLayouts/DashboardListHeader.svelte";
  import ListItem from "../ReusableLayouts/DashboardListItem.svelte";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import NoItemsToShow from "../ReusableLayouts/NoItemsToShow.svelte";
  import Select, { Option } from "@smui/select";

	export let activePage = 1;
	export let ascOrderByExpiringDate = true;
	export let ascOrderByName = true;
	export let attribute;
  export let emptyMessage = "";
	export let itemsPerPage = 5;
	export let list = [];
  export let loading = true;
	export let title = "";

  const dispatch = createEventDispatcher();

  let isMobile = false;
  let firstSort = false;
  let paginatedItems = [];
	let ranges = [
    { 
      range: 'next_7',
      label: $_('prossimi_n_giorni', { values: { days: 7 }}), 
    },
    { 
      range: 'next_14',
      label: $_('prossimi_n_giorni', { values: { days: 14 }}),
    },
    { 
      range: 'previous_7',
      label: $_('scaduti_n_giorni', { values: { days: 7 }}),
    },
    { 
      range: 'previous_14',
      label: $_('scaduti_n_giorni', { values: { days: 14 }}), 
    },
  ];
  let rangeValue = 'next_7';
  let shortExpiringDate = false;

  // Pagination
  $: if (list && Array.isArray(list) && list.length > 0) {
    paginatedItems = list.slice(
      (activePage - 1) * itemsPerPage,
      (activePage - 1) * itemsPerPage + itemsPerPage
    )
  }

  // Sorting List
  const handleSortList = (e) => {
    let key = e.detail.key;
    let ascOrder = e.detail.ascOrder;
    if (key == 'assignee_name') ascOrderByName = !ascOrderByName;
    if (key == 'expiring_date') ascOrderByExpiringDate = !ascOrderByExpiringDate;
    list = sortList(list, key, ascOrder);
  }

  // Update list
  $: if (rangeValue) {
    dispatch('updateList', { range: rangeValue });
    activePage = 1;
  }

  // First sort
  $: {
    if (list.length > 0 && firstSort === false) {
      list = sortList(list, 'expiring_date', true);
      firstSort = true;
    }
  }

  const handleRedirectTo = (e) => {
    const customerId = e.detail.customerId;
    let tab = "workouts";
    if (attribute === $_('abbonamento'))       tab = "subscriptions";
    if (attribute === $_('piano_allenamento')) tab = "workouts"; 
    if (attribute === $_('nome') && title === $_('video_allenamenti')) tab = "vods";
    
    navigate(`/customer?tab=` + tab + `&customerId=` + customerId, {
      replace: true,
      state: { customerId },
    });
  }

  // Check window size
  const windowSizeUpdated = () => {
		shortExpiringDate = document.documentElement.clientWidth >= 992 && document.documentElement.clientWidth < 1200 || document.documentElement.clientWidth < 576;
    isMobile = document.documentElement.clientWidth < 576;
	}

	onMount(() => {
		window.addEventListener("resize", windowSizeUpdated);
		windowSizeUpdated();
	});	
</script>

<div class="d-flex justify-content-between align-items-center">
	<span class="list-title">{title}</span>

	<div>
    <Select 
      bind:value={rangeValue} 
      class="range-select">
      {#each ranges as option}
        <Option value="{option.range}">{option.label}</Option>
      {/each}
    </Select>
  </div>
</div>

<ListHeader 
  bind:ascOrderByExpiringDate
  bind:ascOrderByName
  bind:attribute
  bind:rangeValue
  on:sortList={handleSortList}
/>

{#if loading}
  <div class="d-flex position-relative pt-4">
    <Loading />
  </div>
{:else}
  {#if list.length > 0}
    {#each paginatedItems as item, i}
      <ListItem {item} {isMobile} {shortExpiringDate} on:redirectTo={handleRedirectTo} />
    {/each}
    {#if list.length > itemsPerPage}
      <LightPaginationNav
        totalItems="{list.length}"
        pageSize="{itemsPerPage}"
        currentPage="{activePage}"
        limit="{2}"
        showStepOptions="{true}"
        on:setPage="{(e) => activePage = e.detail.page}"
      />
    {/if}
  {:else}
    <NoItemsToShow upperText={emptyMessage} />
  {/if}
{/if}

<style>
	.list-title {
		font-size: 1.1rem;
		font-weight: bold;
	}

	* :global(.range-select .mdc-select__native-control) {
		text-align: right;
    padding-right: 24px;
    padding-top: 6px;
    border: 0;
	}

  * :global(.mdc-card) {
    text-align: center;
  }  

  /**
   * Light Pagination Nav
   */
  :global(.light-pagination-nav) :global(.pagination-nav) {
    box-shadow: none !important;
  }

  :global(.light-pagination-nav) :global(span.option.number) {
    text-decoration: none !important;
    font-weight: 400 !important;
    opacity: .5 !important;
  }

  :global(.light-pagination-nav) :global(span.option.number.active) {
    text-decoration: underline !important;
    font-weight: 700 !important;
    color: inherit !important;
    opacity: 1 !important;
  }

  :global(.light-pagination-nav) :global(.option.number),
  :global(.light-pagination-nav) :global(.option.ellipsis) {
    padding: 7px !important;
  }
</style>