<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Media } from "@smui/card";
  import IconButton from "@smui/icon-button";
  import Loading from "../ReusableLayouts/Loading.svelte";
  import MsgError from "../ReusableLayouts/MsgError.svelte";
  import Select, { Option } from "@smui/select";
  import Textfield from "@smui/textfield";
  import VirtualList from "@sveltejs/svelte-virtual-list";
  
  export let customers = [];
  export let groups = [];
  export let itemsTypeSelected = $_('atleti');
  export let loading = true;
  export let msgError;
  export let playlistToBeAssigned = "";
  export let text;

  const dispatch = createEventDispatcher();

  let doneTypingInterval = 600;
  let itemsTypes = [
    $_('atleti'), 
    $_('gruppi'),
  ];
  let items = [];
  let search = "";
  let typingTimer;

  $: if (itemsTypeSelected == $_('atleti')) {
    items = customers;
  } else if (itemsTypeSelected == $_('gruppi')) {
    items = groups;
  }

  const configSearch = () => {
    let searchInput = document.getElementById("searchInputAssignment");
    if (searchInput) {
      searchInput.addEventListener("keyup", () => {
        delaySearch();
      });
    }
  };

  const delaySearch = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      (_) => dispatch("searchChange", { search }),
      doneTypingInterval
    );
  };

  const getCustomerStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "userIconBgr-active";
        break;
      case "Not very active":
        return "userIconBgr-notVeryActive";
        break;
      case "Inactive":
        return "userIconBgr-inactive";
        break;
      case "Disabled":
        return "userIconBgr-disactive";
        break;
      default:
        return "userIconBgr-inactive";
        break;
    }
  };

  onMount(() => {
    configSearch();
  });
</script>

<style>
  * :global(.mdc-text-field) {
    height: 56px;
    margin-right: 0.8em;
  }
  * :global(.mdc-text-field__input) {
    height: auto;
    margin: auto;
    padding: 0 0.5em !important;
  }
  * :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label) {
    top: 18px;
  }
  * :global(.material-icons.searchIcon) {
    padding: 16px 0px 0px 8px !important;
  }
  
  .justifyContentEnd {
    display: flex;
    justify-content: flex-end;
  }
  .select-items-category {
    min-width: 160px;
  }
  .alignCenter {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .textLeft {
    text-align: left;
  }
  .nameWrapper {
    font-weight: 600;
    opacity: 0.8;
  }
  .bold {
    font-weight: bold;
  }
  .imageWrapper {
    background-color: grey;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
  }
  .imageWrapper__group {
    background-color: #05d3c8;
  }
  .imagecustomer {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    object-fit: cover;
  }
  .statusCircle {
    border-radius: 50%;
    display: inline-block;
    height: 0.9rem;
    margin-left: 1rem;
    width: 0.9rem;
  }
  .header {
    background-color: #f9f9f9;
    align-items: center;
  }
  * :global(.mdc-menu-surface--open .mdc-menu-surface) {
    z-index: 10000 !important;
  }
  .alignBaseline {
    align-content: baseline;
    align-items: baseline;
    display: flex;
  }
  * :global(.mdc-button--raised), * :global(.mdc-button--unelevated) {
    height: 2rem;
  }
  .searchbar {
    order: initial;
  }
* :global(.customIcon) {
    margin: 0 auto;
    color: white;
    font-size: 2em;
  }
  @media (max-width: 767px) {
    .searchbar {
      order: 3;
    }
    .header__title {
      margin: 0 !important;
    }
    * :global(.mdc-text-field__input) {
      margin-left: 0;
    }
    .desktop {
      display: none;
    }
  }
</style>

<div class="header px-4 py-2 row no-gutters">
  <div class="header__title col-11 my-2">
    <div class="mdc-typography--headline6 bold">
      {`${text} "${playlistToBeAssigned.name ? playlistToBeAssigned.name : ''}"`}
    </div>
  </div>
  <div class="col-1 col-md-1 justifyContentEnd">
    <IconButton
      class="material-icons"
      on:click={() => dispatch('closeDialog', {})}>
      close
    </IconButton>
  </div>
  <div class="col-12 col-md-8 mb-2">
    <Select
      bind:value={itemsTypeSelected}
      class="w50"
      enhanced
      label={$_('scegli_una_tipologia')}
      menu$class="select-items-category"
      variant="outlined">
      {#each itemsTypes as value}
        <Option 
          {value} 
          on:click={() => search = ""}
          selected={itemsTypeSelected == value}>
          {value}
        </Option>
      {/each}
    </Select>
  </div>
  <div class="col-12 col-md-4 d-flex align-contents-end searchbar">
    <Textfield
      bind:value={search}
      class="w100 pt-1"
      id="searchInputAssignment"
      label={$_('cerca_per_nome')}
      variant="outlined"
      withLeadingIcon>
      <div class="centrated">
        <Icon class="material-icons searchIcon">search</Icon>
      </div>
    </Textfield>
  </div>
</div>

<div class="container fluid py-3">
  <MsgError {msgError} />

  {#if !loading}
    <div class={`${items.length > 0 ? 'containerCustomer' : 'container fluid py-2'}`}>
      {#if items.length > 0}
        <VirtualList items={items} let:item>
          <Card class="mb-2">
            <Content>
              <div class="row no-gutters alignCenter">
                <div class="col-2 col-md-1 mr-2">
                  <Media>
                    {#if itemsTypeSelected == $_('atleti')}
                      {#if item.image}
                        <img src={item.image} alt="" class="imagecustomer" />
                      {:else}
                        <div class="imageWrapper">
                          <div style="" />
                        </div>
                      {/if}
                    {:else if itemsTypeSelected == $_('gruppi')}
                      <div class="imageWrapper imageWrapper__group">
                        <Icon tabindex="1" class="material-icons customIcon">
                          groups
                        </Icon>
                      </div>
                    {/if}
                  </Media>
                </div>
                <div class="col-5 ml-2 textLeft alignCenter">
                  {#if itemsTypeSelected == $_('atleti')}
                    <span class="nameWrapper">
                      {item.first_name + ' ' + item.last_name}
                    </span>
                    <span class={`statusCircle desktop ${getCustomerStatusColor(item.status)}`} />
                  {:else}
                    <span class="nameWrapper">
                      {item.name}
                    </span>
                  {/if}
                </div>
                <div class="col-4 col-md-3 ml-auto justifyContentEnd">
                  <Button
                    variant="unelevated"
                    disabled={item.status == "Disabled"}
                    on:click={() => {
                      if (itemsTypeSelected == $_('atleti')) {
                        navigate('/vod-assignment', {
                          state: {
                            assignment: playlistToBeAssigned,
                            customerId: item.id,
                          },
                        });
                      } else if (itemsTypeSelected == $_('gruppi')) {
                        navigate('/vod-assignment', {
                          state: {
                            assignment: playlistToBeAssigned,
                            groupId: item.id,
                          },
                        }); 
                      }
                    }}>
                    <Label>{$_('assegna')}</Label>
                  </Button>
                </div>
              </div>
            </Content>
          </Card>
        </VirtualList>
      {/if}
    </div>
  {:else}
    <div class="row no-gutters position-relative">
      <Loading />
    </div>
  {/if}
</div>
