<script>
  import { _ } from "../../../../services/i18n";
  import { addDaysToYmdDate, dateTodayDifferenceInDays, getSubscriptionDurations } from "../../../../lib/utils.js";
  import { createEventDispatcher } from "svelte";
  import { navigate } from "svelte-routing";
  import { user, subscriptionInfo } from "../../../../lib/store";
  import Button, { Icon, Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import DatePicker from "../../../ReusableLayouts/DatePicker.svelte";
  import Dialog from "@smui/dialog";
  import moment from "moment";
  import Select, { Option } from "@smui/select";
  import SvelteTooltip from "svelte-tooltip";
  import Switch from "@smui/switch";
  import Textfield from "@smui/textfield";
  
  export let customerId = null;
  export let customerSubscription;

  const dispatch = createEventDispatcher();

  let commaInserted = false;
  let dateDialogItalian; 
  let deactivateSwitchEnabled = true;
  let duration = 0;
  let durationOptions = getSubscriptionDurations($_);
  let endDateFormatted = null;
  let exitDialog;
  let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
  let paymentDateFormatted = moment(customerSubscription.payment_date).isValid() ? customerSubscription.payment_date : '';
  let startDateFormatted = moment(customerSubscription.start).isValid() ? customerSubscription.start : '';
  let visibleToCustomer = false;

  $: if (customerSubscription && customerSubscription.visible_to_customer) {
    visibleToCustomer = customerSubscription.visible_to_customer;
  }

  $: dateDialogItalian = customerSubscription.start
      ? moment(customerSubscription.start).format("DD/MM/YYYY")
      : '';

  $: paymentDateDialogItalian = customerSubscription.payment_date
      ? moment(customerSubscription.payment_date).format("DD/MM/YYYY")
      : '';

  // Max value for customerSubscription.duration
  $: if (customerSubscription && customerSubscription.duration) {
    if (customerSubscription.duration > 999 || isNaN(customerSubscription.duration)) {
      customerSubscription.duration = "";
    }
  }

  // Max value for customerSubscription.price
  $: if (customerSubscription && customerSubscription.price) {
    if (customerSubscription.price >= 10000) customerSubscription.price = 9999;
  }

  $: {
    if (dateDialogItalian && !isNaN(customerSubscription.duration) && customerSubscription.duration > 0 && !isNaN(customerSubscription.duration_type)) {     
      checkDeactivateSwitchEnabled();
    }
  }

  const handleStartDate = (e) => {
    if (moment(e.detail.date, "YYYY-MM-DD", true).isValid() === true) {
      customerSubscription.start = e.detail.date;
      startDateFormatted = moment(customerSubscription.start).format("YYYY-MM-DD");  
    } else {
      customerSubscription.start = '';
      startDateFormatted = '';  
    }
  };

  const checkDeactivateSwitchEnabled = () => {
    switch (parseInt(customerSubscription.duration_type)) {
      case 0  : duration = customerSubscription.duration;      break;
      case 1  : duration = customerSubscription.duration * 7;  break;
      case 2  : duration = customerSubscription.duration * 31; break;
      default : duration = customerSubscription.duration;
    }
    endDateFormatted = addDaysToYmdDate(startDateFormatted, duration);
    deactivateSwitchEnabled = dateTodayDifferenceInDays(endDateFormatted) >= 0;
    if (deactivateSwitchEnabled === false && customerSubscription.auto_deactivate === true) {
      customerSubscription.auto_deactivate = false;
    }
  }

  const handlePaymentDate = (e) => {
    if (moment(e.detail.date, "YYYY-MM-DD", true).isValid() === true) {
      customerSubscription.payment_date = e.detail.date;
      paymentDateFormatted = moment(customerSubscription.payment_date).format("YYYY-MM-DD");  
    } else {
      customerSubscription.payment_date = '';
      paymentDateFormatted = '';  
    }
  };

  const onSubmit = (e) => {
    let savingCustomerSubscription = customerSubscription;
    let clone = { ...savingCustomerSubscription };
    clone.start = startDateFormatted;
    clone.payment_date = paymentDateFormatted;

    dispatch("storeCustomerSubscription", {
      customerSubscription: clone,
    });
  };

  const handleGoBack = () => {
    exitDialog.close();
    leavePage();
  };

  const leavePage = () => {
    navigate("/customer?tab=subscriptions", {
      state: { customerId },
    });
  }

</script>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={handleGoBack} />
</Dialog>

<div class="py-3">
  <div class="row">
    <div class="col-4 label">{$_('nome_abbonamento')}</div>
    <div class="col-8">
      <Textfield
        variant="outlined"
        class="w100 my-2"
        input$maxlength="191"
        label="Es. Clienti Premium"
        bind:value={customerSubscription.name}
        type="text" />
    </div>
    <div class="col-4 label">{$_('data_inizio')}</div>
    <div class="col-8 pt-2">
      <DatePicker
        on:date={handleStartDate}
        maxDate={moment().add(90, 'years').toISOString()}
        defaultDate={dateDialogItalian}
        width="100"
        dateFormat="yyyy-mm-dd" />
    </div>
    <div class="col-4 label">{$_('durata')}</div>
    <div class="col-3 col-md-2">
      <Textfield
        variant="outlined"
        input$maxlength="3"
        input$maxvalue="999"
        class="w100 my-2"
        bind:value={customerSubscription.duration}
        type="number" />
    </div>
    <div class="col-4 select_duration">
      <Select
        enhanced
        class="w30 mb-2"
        bind:value={customerSubscription.duration_type}>
        {#each durationOptions as { value, label }}
          <Option {value} selected={customerSubscription.duration_type === value}>
            {label}
          </Option>
        {/each}
      </Select>
    </div>
    <div class="col-4 label py-3">{isMobile ? $_('disattiva_il_cliente_alla_scadenza') : $_('disattiva_il_cliente_allo_scadere')}</div>
    <div class="col-1 d-flex align-items-center py-3">
      {#if $user.is_beta_tester || $subscriptionInfo.name === 'revoo'}
        <Switch 
          bind:checked={customerSubscription.auto_deactivate} 
          disabled={!deactivateSwitchEnabled} 
        />
      {:else}
        <SvelteTooltip tip={$_('funzione_premium')} top>
          <Switch disabled={true} />
        </SvelteTooltip>
      {/if}
      <Icon
        role="button"
        tabindex="1"
        class="ml-3 material-icons mdc-text-field__icon mdc-text-field__icon--trailing opacityReduce icon--help"
        on:click={() => window.open("https://how.revoo-app.com/knowledge/scheda-semplificata-o-normale-la-differenza", "_blank")}>
        help
      </Icon>
    </div>
    <div class="col-7 d-flex align-items-center py-3">
      {#if $user.is_beta_tester || $subscriptionInfo.name === 'revoo'}
        <span class="{!deactivateSwitchEnabled ? `d-inline` : `d-none`}">{$_('non_puoi_disattivare_in_automatico_se_abbonamento_scaduto')}</span>
      {/if}
    </div>
    <div class="col-4 label py-3">{$_('rendi_visibile_al_cliente')}</div>
    <div class="col-1 d-flex align-items-center py-3">
      {#if $user.is_beta_tester}
        <Switch 
          bind:checked={customerSubscription.visible_to_customer} 
          disabled={!deactivateSwitchEnabled || !$user.is_beta_tester}
        />
      {:else}
        <SvelteTooltip tip={$_('funzione_premium')} top>
          <Switch 
            disabled={!deactivateSwitchEnabled || !$user.is_beta_tester} 
          />
        </SvelteTooltip>
      {/if}
    </div>
    <div class="col-7 d-flex align-items-center py-3"></div>
    <div class="col-4 label">{$_('prezzo_')}</div>
    <div class="col-8">
      <label class="mdc-text-field w100 my-2 mdc-text-field--outlined mdc-text-field--no-label">
        <input
          class="mdc-text-field__input"
          placeholder="Es. 199,00"
          bind:value={customerSubscription.price}
          type="number" 
          min="0"
          max="9999.99"
          maxlength="6"
          step=".01"
          />
        <div class="mdc-notched-outline mdc-notched-outline--no-label">
          <div class="mdc-notched-outline__leading"></div>
          <div class="mdc-notched-outline__trailing"></div>
        </div>
      </label>
    </div>
    <div class="col-4 label">{$_('pagato')}</div>
    <div class="col-1 d-flex align-items-center paid_checkbox">
      <Checkbox bind:checked={customerSubscription.paid}/>
    </div>
    {#if isMobile}
      <div class="offset-7"></div>  
      <div class="col-4 label">{$_('in_data')}</div>
      <div class="col-8 pt-2">
        <DatePicker
          on:date={handlePaymentDate}
          maxDate={moment().add(90, 'years').toISOString()}
          defaultDate={paymentDateDialogItalian}
          width="100"
          dateFormat="yyyy-mm-dd" />
      </div>
    {:else}
      <div class="col-1 label">{$_('in_data')}</div>
      <div class="col-6 pt-2">
        <DatePicker
          on:date={handlePaymentDate}
          maxDate={moment().add(90, 'years').toISOString()}
          defaultDate={paymentDateDialogItalian}
          width="100"
          dateFormat="yyyy-mm-dd" />
      </div>
    {/if}

    <div class="col-4 label">{$_('note')}</div>
    <div class="col-8">
      <Textfield
        textarea
        input$maxlength="2000"
        class="fullWidth my-2"
        bind:value={customerSubscription.note}
      >
        <CharacterCounter>{`${customerSubscription.note.length} / 2000`}</CharacterCounter>
      </Textfield>
    </div>
  </div>

  <hr />

  <div class="row pushToEnd">
    <Button class="btn" on:click={() => exitDialog.open()}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-3 mr-3"
      variant="unelevated"
      on:click={onSubmit}
      disabled={customerSubscription.name.length < 3}>
      <Label>
        {customerSubscription.id ? $_('salva') : $_('crea_nuovo_abbonamento')}
      </Label>
    </Button>
  </div>
</div>

<style>
  * :global(.menuSurfaceCustom) {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    color: #fff;
    min-width: 80%;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.mdc-chip) {
    background-color: #fff;
    height: auto;
    min-height: 28px;
  }
  * :global(.mdc-button__label) {
    text-align: left;
  }
  * :global(.paid_checkbox input) {
    width: 2em;
    height: 2em;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  .label {
    text-align: end;
    align-self: center;
    opacity: 0.8;
    font-weight: 600;
  }

  * :global(.svelte-nbzcn .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon) {
    background: white;
    padding-right: 10px;
    margin-right: -14px;
  }
  * :global(.select_duration .mdc-select__selected-text) { 
    border-bottom-color: #fff;
  }
  * :global(.select_duration .mdc-select__dropdown-icon) { 
    top: 20px;
  }
  * :global(.icon--help) {
    color: rgba(0, 0, 0, 0.54) !important;
  }
  * :global(.tags-textfield) {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
  }
  
  * :global(.tags-textfield input) {
    height: 2.2rem !important;
  }

  * :global(.textUppercase) {
    text-transform: uppercase;
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
</style>