export default {
  en: {
    "blocco_upload_video": "Service temporarily unavailable. Please try again later.",
    "%": "%",
    "1_ora_prima": "1 hour before",
    n_ore_prima: "{value} hours before",
    "1_giorno_prima": "1 day before",
    n_giorni_prima: "{value} days before",
    "1_volta_a_settimana": "once a week",
    "2_volte_a_settimana": "twice a week",
    "3_volte_a_settimana": "3 times a week",
    "4_volte_a_settimana": "4 times a week",
    "5_volte_a_settimana": "5 times a week ",
    "6_volte_a_settimana": "6 times a week",
    "7_volte_a_settimana": "7 times a week ",
    abbonamenti: "Subscriptions",
    abbonamenti_in_scadenza: "Expiring subscriptions",
    abbonamento_annullato_contattaci_in_chat:
      "Your REVOO subscription has been cancelled. Contact us to reactivate it today!",
    abbonamento_revoo: "REVOO Subscription",
    abbonamento: "Subscription",
    abbonati: "Subscribe",
    accedi_per_gestire_i_tuoi_clienti: "Log in to manage your customers",
    accedi: "Log in",
    accetto_i_termini_e_le_condizioni:
      "I accept <a href='https://www.iubenda.com/termini-e-condizioni/65979137' target='_blank'>terms & cons *</a>",
    accetto_la_privacy_policy:
      "I accept the <a href='https://www.iubenda.com/privacy-policy/65979137' target='_blank'>Privacy Policy *</a>",
    account: "Account",
    acquisto_singolo: "One time payment",
    acquisto_unico: "One time payment",
    addome: "Core",
    admin: "Admin",
    aggiorna_dati: "Update your data",
    aggiorna_dati_pagamento: "Update payment data",
    aggiorna_dati_fatturazione: "Update billing data",
    aggiorna: "Update",
    aggiorna_nota: "Update note",
    aggiungi_abbonamento: "Add Subscription",
    aggiungi_allenamento: "Add workout",
    aggiungi_allegato: "Add Attachment",
    aggiungi_carta: "Add Card",
    aggiungi_codice_sconto: "Add discount code",
    aggiungi_file: "Upload a file",
    aggiungi_esercizio: "Add Exercise",
    aggiungi_i_tuoi_atleti: "Add your Customers",
    aggiungi_i_tuoi_gruppi: "Add your Groups",
    aggiungi_i_tuoi_video: "Add your Videos",
    aggiungi_le_tue_playlist: "Add your Playlists",
    aggiungi_progressione: "Add Progression",
    aggiungi_scheda: "Add Training Card",
    aggiungi_set: "Add Set",
    aggiungi_un_abbonamento_per: "Add a Subscription for",
    aggiungi_un_link_ad_una_cartella:
      "Add a link to a shared folder to share documents with your customers (e.g. nutrition plan).",
    aggiungi_un_video: "Add a Video",
    aggiungi_una_playlist: "Add a Playlist",
    aggiungi_una_progressione: "Add a progressions",
    aggiungi: "Add",
    aggiunto_in_data: "Added on",
    al_mese: "per Month",
    all_anno: "per Year",
    alimentare: "Nutrition",
    alimentazione: "Nutrition",
    all_aperto: "Outdoors",
    allegati: "Attachments",
    allegato: "Attachment",
    allenamenti: "Workouts",
    allenamento: "Workout",
    allergie: "Allergies",
    altezza_inserita_non_è_valida: "The value inserted is not valid",
    altezza: "Height",
    altre_patologie: "Other pathologies",
    altro: "Other",
    anamnesi: "Medical history",
    anni_: "Years",
    anni_abbr: "{anni_abbr, plural, =1 {# years} other {# years}}",
    anni: "{anni, plural, =1 {# year} other {# years}}",
    anno: "Year",
    annuale: "Yearly",
    annulla: "Cancel",
    app_personalizzata: "Custom mobile app",
    appuntamento: "Appointment",
    appuntamenti_e_promemoria: "Appointments and Reminders",
    appuntamenti_e_promemoria_prenotati: "Booked Appointments and Reminders",
    apri_link: "Open link",
    ascellare: "Axillary",
    asma: "Asthma",
    assegna_a: "Assign to",
    assegna_ai_gruppi: "Assign to groups",
    assegna_al_gruppo: "Assign to group",
    assegna_allenamento: "Assign training card",
    assegna_copia_di: "Assign copy of",
    assegna_il_primo_video_o_la_prima_playlist_per:
      "Assign a video or a playlist to",
    assegna_il_primo_video_per: "Assign a video to",
    assegna_la_prima_playlist_per: "Assign a playlist to",
    assegna_playlist: "Assign Playlist",
    assegna_video_alla_playlist: "Add video to the Playlist",
    assegna_video_alla_playlist: "Add video to the Playlist",
    assegna_video: "Assign Video",
    assegna_tutti_i_tuoi_clienti: "Add all your customers to this group",
    assegna: "Assign",
    assegnati_agli_atleti: "Assigned to customers",
    assegnati_ai_gruppi: "Assigned to groups",
    assegnati: "Assigned",
    atleta_già_assegnato_a_te:
      "Warning: this customer is already assigned to you",
    atleta_già_assegnato_ad_altro_pt:
      "Warning: this customer is already assigned to another PT",
    atleta_non_ha_attivato_sincronizzazione:
      "Your customer hasn't synced the app with his mobile phone",
    atleta_non_si_allena_15_giorni:
      "Customer has not trained for at least 15 days",
    atleta_non_si_allena_7_giorni:
      "Customer has not trained for more than 7 days",
    atleta_non_si_è_mai_allenato: "Customer has never trained",
    atleta_rimosso_dal_gruppo: "Customer removed from group",
    atleta_si_allena_regolarmente: "Customer is training regularly",
    atleta: "Customer",
    atleti: "Customers",
    attenzione: "Warning",
    attenzione_sono_stati_selezionati_solo_placeholder:
      "Warning: only placeholder has been selected",
    attenzione_vod_funzione_premium:
      "This is a premium feature. Exceeding 50 monthly views, the paid plan will be automatically activated. Click for more information",
    attiva_chat_per_i_nuovi_membri_del_gruppo: "Activate Chat for new members of this group",
    attiva_chat_per_i_nuovi_clienti: "Activate Chat for new customers",
    attiva_chat_per_questo_cliente: "Activate Chat for this customer",
    attiva_l_atleta: "Activate customer",
    attivato: "Activated",
    attivi: "Active",
    attivita_fisica: "Physical activity",
    attivo: "Active",
    attrezzatura: "Equipment",
    aumento_massa_muscolare: "Muscle gain",
    automazioni: "Automations",
    avanti: "Continue",
    avanzamento: "Upload processing",
    avanzato: "Advanced",
    bentornato: "Welcome back!",
    benvenuto: "Welcome!",
    bf: "BF",
    bicipite_destro: "Right Biceps",
    bicipite_dx: "Biceps R",
    bicipite_sinistro: "Left Biceps",
    bicipite_sx: "Biceps L",
    bio: "Bio",
    bmr: "BMR",
    body_water: "Body Water",
    bones: "Bones",
    bozza: "Draft",
    business_nickname: "Business Nickname",
    c_f_gia_compilato:
      "Identity Code slready inserted. Cannot insert both fields.",
    c_f_non_valido: "Identity Code not valid",
    "c.f.": "Identity Code",
    cal: "cal",
    calcola_bf_con_plicometria: "Calculate BF% with plicometry",
    calcola: "Calculate",
    calendario: "Calendar",
    calorie_bruciate: "Calories burned",
    cambia_abbonamento: "Update Subscription",
    cambia_piano_ora: "Update plan now",
    cambia_piano: "Update plan",
    cancella: "Delete",
    cap: "ZIP Code",
    carboidrati: "Carbs",
    carica: "Upload",
    carica_documento: "Upload file",
    caricamento_in_corso: "Uploading",
    carta_di_credito: "Credit card",
    categoria: "Category",
    cerca_per_nome_atleta_o_piano: "Search by customer name or plan",
    cerca_per_nome_o_tag: "Search by name or tag",
    cerca_per_nome: "Search by name",
    cerca_per_piano: "Seach by plan",
    cerca: "Find",
    chat: "Chat",
    chiudi: "Close",
    ciclo_irregolare: "Irregular Period",
    circuito_a_tempo: "Time Circuit",
    circuito: "Circuit",
    città: "City",
    cliente_non_ha_consentito_a_fornirti_i_dati:
      "Your customer has not allowed you to see these data",
    cliente: "Customer",
    clienti_aggiunti: "Added customers",
    clienti_attivi: "Active customers",
    clienti_gruppi: "Customers / Groups",
    clienti: "customers",
    cm: "cm",
    coaching_diretto: "Direct coaching with the customer",
    coaching_online: "Online or remote coaching",
    codice_fiscale: "Identity Code",
    codice_invito_clienti_default_attivi:
      "Your customer's account will be activated by default at the end of registration, and will be able to train immediately.",
    codice_invito_clienti_default_non_attivi:
      "Your customer's account will be deactivated by default at the end of registration, and will not be able to train until you activate them manually.",
    codice_invito_label_gruppo:
      "Share this invitation code with your new customers to add them to the group.",
    codice_invito_label: "Share this invitation code with your new customers.",
    codice_invito: "Invitation code",
    codice_postale_errato: "Wrong ZIP Code",
    codice_referral: "Referral Code",
    codice_sconto: "Discount Code",
    codice_univoco: "SDI Unique code",
    cognome: "Last Name",
    combinazione: "Combination ",
    come_funziona: "How does it work",
    come_lo_hai_conosciuto: "How did you meet him/her?",
    completa_il_tuo_profilo_per_iniziare:
      "Complete your profile to get started",
    composizione: "Composition",
    con_sfondo_trasparente: "with trasnsparent background",
    consulenza: "Consultation",
    conferma: "Confirm",
    conferma_assegnazione_di_massa_attivata:
      "Are you sure you want to assign all of your current and future customers to this group? (Any automations for removing from the group will be removed)",
    conferma_assegnazione_di_massa_disattivata:
      "All your customers will be removed from this group. Are you sure you want to continue?",
    confermata: "Confirmed",
    continua: "Continue",
    controlla_la_tua_inbox: "Check your inbox.",
    copia_allenamento_di_atleta: "Customer workout copy",
    copia_negli_appunti: "Copy",
    copia_piano: "Copy plan",
    copia_scheda: "Copy training card",
    correzione_posturale: "Postural correction",
    cosa_vuoi_aggiungere: "What do you want to add?",
    coscia_dx: "Right thigh",
    coscia_sx: "Left thigh",
    coscia: "Leg",
    coupon_percent: "Discount",
    crea: "Create",
    crea_nota: "Create note",
    crea_appuntamento: "Create appointment",
    crea_gruppo: "Add group",
    crea_i_tuoi_esercizi: "Add your exercises",
    crea_i_tuoi_piani_per_i_gruppi: "Add your training cards for groups",
    crea_i_tuoi_piani: "Add your training cards",
    crea_il_tuo_sito_web: "Add your website",
    crea_il_tuo_primo_prodotto: "Add your first product",
    crea_la_prima_anamnesi: "Add your first medical history",
    crea_la_prima_nota: "Create the first note",
    crea_nuovo_abbonamento: "Add new subscription",
    crea_nuovo_esercizio: "Add new exercise",
    crea_nuovo_piano: "Add new plan",
    crea_nuovo_video: "Add new Video",
    crea_playlist: "Add Playlist",
    crea_primo_piano_allenamento: "Add first workout",
    crea_promemoria: "Add reminder",
    crea_un_cliente_di_prova:
      'Add a test customer: click on "New customer" button!',
    crea_un_gruppo: "Add a group",
    creata_da: "Added by",
    credito_e_codici_sconto: "Credit and Discount Codes",
    credito_e_codici_sconto: "Credit and Referral Codes",
    credito_residuo: "Credit left",
    contenuto: "content",
    da_atleta: "From customer",
    da_modello: "From templates",
    da_pagare: "To pay",
    da_remoto: "Remote",
    da: "from",
    dai_tuoi_modelli_di_allenamento_standard_e_dai_gruppi:
      "From your standard and groups workout templates",
    dai_tuoi_modelli_di_allenamento_standard:
      "From your standard workout templates",
    dal_loro_ingresso: "From their entry",
    dal_vivo_e_remoto: "Both in person and in distance",
    dal_vivo: "In person",
    data_addebito: "Charge date",
    data_di_fine: "End date",
    data_di_inizio: "Start date",
    data_di_nascita: "Date of birth",
    data_di_scadenza: "Expiry date",
    data_inizio: "Start date",
    data: "Date",
    dati_fatturazione: "Add your billing information",
    dati_fatturazione_verifica: "Verify your billing information",
    dati_fatturazione_mancanti_mobile:
      "Incomplete billing information. In order to continue, please login from your browser and add your billing information.",
    dati_fatturazione_mancanti:
      "Incomplete billing information. In order to continue, add your billing information.",
    dati_non_completi: "Incomplete Data",
    dati_personali: "Personal info",
    definizione: "Shred",
    descrivi_obiettivo: "Describe goal",
    descrizione_maiusc: "DESCRIPTION",
    descrizione: "Description",
    descrizione_breve: "Short description",
    dettagli: "Details",
    dettagli_di_fatturazione: "Billing information",
    dettagli_di_pagamento_e_fatturazione: "Payment and billing details",
    dettagli_esercizio: "Excercise details",
    dettaglio_iva:
      "The value shown is VAT included and it will be explicited in the invoice",
    dettaglio_prossimo_addebito: "Next bill detail",
    dettaglio: "Details",
    diabete: "Diabetes",
    digita_la_localita_e_scegli_una_voce_dal_menu_a_tendina:
      "Type location and choose a result from dropdown menu",
    disattiva_il_cliente_allo_scadere:
      "Deactivate customer if the subscription expires",
    disattiva_il_cliente_alla_scadenza:
      "Deactivate customer if the subscription expires",
    disattiva_l_atleta: "Deactivate customer",
    disattivato: "Disabled",
    disattivo: "Deactivated",
    distanza: "Distance",
    disturbi_alimentari: "Food disorders",
    dorsale: "Dorsal",
    dopo: "After",
    duplica: "Duplicate",
    durata_in_settimane: "Duration in weeks",
    durata: "Duration",
    e: "and",
    edita_immagine: "Edit image",
    elementare: "Elementary",
    elimina_gruppo: "Delete group",
    elimina_profilo_overlay:
      "You must deactivate your customer before deleting it",
    elimina_profilo: "Delete profile",
    elimina: "Delete",
    elimina_account: "Delete account",
    eliminazione_account: "Account deletion",
    eliminata: "Deleted",
    email_errata: "Wrong email address",
    email_esiste_già: "This email address already exists",
    email_non_registrata: "Email address not registered",
    email_non_valida: "Invalid email address",
    email_reinviata_con_successo: "E-mail successfully resent.",
    email: "E-mail",
    emom: "EMOM",
    entrambi: "Both of them",
    errore_pagamento_mobile:
      "Something went wrong with your payment! To continue, please login from your browser and update your data",
    errore_pagamento:
      "Something went wrong with your payment! To continue, update your payment method",
    esci: "Exit",
    esporta: "Export",
    espandi: "Expand",
    esercizi_privato:
      "exercises (<span class='material-icons' style='font-size: 1em'>lock</span> privato)",
    esercizi: "Exercises",
    esercizio: "Exercise",
    esercizio_revoo: "REVOO Exercise",
    esiste_già_un_esercizio_con_questo_nome:
      "You already have an exercise with this name",
    estremamente_attivo: "Extremely active",
    età_metabolica: "Metabolic age",
    età: "Age",
    euro: "Euro",
    facoltativo: "(optional)",
    fase_di_testing_gratuito:
      "This feature is still under beta testing. In the future, 300 monthly views will be for free for all trainers; if you need more views you will need to purchase an upgrade. Don't worry, your customers will be able to continue watching the video even if you exceed the limit for now.",
    fase_di_testing_gratuito_short: "This feature is still under beta testing.",
    fatto: "Done",
    fatturazione: "Billing",
    fatture_inviate_automaticamente:
      "Invoices are automatically sent to your email address. <br/>In case of need, please contact us in chat or send an email to <b>invoice@revoo-app.com</b>",
    fatture_inviate_automaticamente_clean:
      "Invoices are automatically sent to your email address. In case of need, please contact us in chat or send an email to <b>invoice@revoo-app.com</b>",
    fatture: "Invoices",
    femmina: "Female",
    fianchi: "Hips",
    file: "File",
    file_caricato: "Uploaded file",
    filtra_per_tag: "Filter by tag",
    filtra: "Filter",
    fisica: "PAR-Q",
    fisico: "In person",
    for_you: "For You",
    formato_del_numero_di_telefono_non_corretto: "Phone number format is invalid",
    foto_progressi: "Progress photo",
    fratture: "Fractures",
    frequenza_settimanale_di_allenamento: "Weekly training frequency",
    front_view: "Front",
    frontale: "Front",
    funzione_premium: "Premium feature, contact us in chat",
    g: "g",
    gamba_destra: "Right leg",
    gamba_sinistra: "Left leg",
    generale: "General",
    genera_link: "Generate link",
    gestione_gruppi: "Group Management",
    giorni_: "Days",
    giorni: "{giorni, plural, =1 {# day} other {# days}}",
    giorni_label: "{giorni_label, plural, =1 {day} other {days}}",
    grassi: "Fat",
    grasso_viscerale: "Visceral fat",
    gruppi: "Groups",
    gruppi_aggiunti: "Added Groups",
    gruppo_associato_eliminato: "Group deleted",
    gruppo_sanguigno: "Blood group",
    gruppo: "Group",
    guida: "Help",
    hai_un_account: "Do you have an account?",
    help: "Help",
    i_gruppi_ti_permettono_di_assegnare:
      "Groups allow you to assign same workouts to multiple clients at the same time!",
    i_tuoi_esercizi_sono_privati:
      "Your exercises are private. No other Personal Trainer will be able to see them.",
    i_tuoi_video_sono_privati:
      "Your Videos are private. No other Personal Trainer will be able to see them.",
    il_codice_fiscale_non_è_valido: "The identity code is not valid",
    il_codice_inserito_non_è_valido: "The code entered is invalid",
    il_codice_univoco_deve_avere_7_numeri:
      "The unique code must have 7 numbers",
    il_cognome_deve_essere_di_almeno_3_caratteri:
      "The last name must be at least 3 characters long",
    il_cognome_é_obbligatorio: "The last name is mandatory",
    il_link_inserito_non_è_valido: "The link entered is not valid",
    il_link_inserito_supera_i_caratteri_consentiti:
      "The link entered exceeds characters limit",
    il_nome_deve_essere_di_almeno_3_caratteri:
      "The name must be at least 3 characters long",
    il_nome_é_obbligatorio: "The name is mandatory",
    il_periodo_di_prova_termina: "The trialing period ends on",
    il_piano_si_rinnova: "The plan automatically renews on",
    il_prodotto_e_in_revisione: "The product Pending approval",
    il_prodotto_sara_inviato_in_revisione:
      "The product will be sent for review",
    il_prodotto_e_stato_approvato: "Product approved",
    il_prodotto_include: "The product includes:",
    il_peso_inserito_non_è_valido: "The weight entered is invalid",
    il_seguente_piano_sara_attivato: "The following plan will be activated on your account",
    il_seguente_piano_sara_attivato_pricing_change: "The following plan will be activated on your account on {activation_date}",
    il_tuo_abbonamento: "Your subscription",
    il_tuo_brand_commerciale: "Your brand",
    il_valore_inserito_non_è_valido: "The entered value is not valid",
    immagine_quadrata: "The image must be square",
    importa_da_atleta: "Import from customer",
    importa_da_modello: "Import from template",
    importo_dovuto: "Amount due",
    impostazioni_profilo: "Profile settings",
    impostazioni: "Settings",
    in_alternativa_al_codice_fiscale: "(optional)",
    in_casa_con_poca_attrezzatura: "At home with basic equipment",
    in_data: "Date",
    in_elaborazione: "Processing",
    in_palestra: "In the gym",
    in_revisione: "Pending review",
    inattivi: "Inactive",
    inattivo: "Inactive",
    indietro: "Back",
    indirizzo_completo: "Full address",
    indirizzo_di_fatturazione: "Billing address",
    indirizzo_non_completo: "Address not complete",
    indirizzo_non_è_corretto: "Incorrect address",
    info_abbonamento: "Subscription Info",
    info_piano: "Plan info",
    info_prodotto: "Product info",
    inglese: "English",
    inizia_a_lavorare: "Get started!",
    inizia_la_ricerca: "Start searching",
    inizia_ricerca: "Start searching",
    inizio: "Start",
    inserire_almeno_un_immagine: "Insert at least one image",
    inserisci_email_registrazione:
      "Please enter the login email to recover your password.",
    inserisci: "Insert",
    inserisci_un_titolo_valido: "Insert a valid title",
    inserisci_un_contenuto_valido: "Insert a valid content",
    intermedio: "Intermediate",
    intestatario: "Card Holder Name",
    intolleranze: "Intolerance",
    invia_email: "Send email",
    invia_notifica_al_cliente: "Send notification to customer",
    invia_notifica_ai_clienti: "Send notification to customers",
    invia_notifica_al_gruppo: "Send notification to group",
    invia: "Send ",
    invia_email_invitati: "Notify to guests",
    inviata_il: "Sent on",
    invio_recupero_password:
      "We have sent you an email to recover your password.",
    invita_amico_sconto_amico:
      "Your friend gets {invitato_value} % discount for {invitato_month} months.",
    invita_amico_sconto_pt:
      "You earn {invitante_value} % discount on your friend’s REVOO bill for the next {invitante_month} months.",
    invita_amico_su_revoo: "Refer a trainer to REVOO",
    invita_amico:
      "If you invite a personal trainer to try REVOO with your Referral code:",
    invita_cliente: "Invite customer",
    invita_clienti: "Invite customers",
    invita_nuovi_clienti_al_gruppo: "Invite new customers to group",
    invita_nuovi_clienti: "Invite new customers",
    invita_un_amico: "Invite friends",
    invita: "Invite",
    irregolarita_intestinale: "Gut disorders",
    italiano: "Italian",
    iva_inclusa: "Vat Included",
    iva: "VAT",
    kcal: "kcal",
    kg: "kg",
    l_email_è_obbligatoria: "Email is required",
    la_città_é_obbligatoria: "City is required",
    la_città_inserita_non_è_valida: "City is not valid",
    la_dimensione_massima_consentita_è_di:
      "The maximum size allowed is {dimension} MB",
    la_password_non_rispetta_i_criteri:
      "The password does not meet the criteria",
    la_pec_deve_essere_valida: "CEM must be a valid CEM address",
    laterale_destro: "Right side",
    laterale_sinistro: "Left side",
    left_view: "Left side",
    letargico: "Slow-Paced",
    lingua: "Language",
    link_a_video_youtube: "Link a Youtube, Vimeo or Dropbox video",
    link_cartella_condivisa: "Shared folder link",
    link_della_videochiamata: "Videocall link",
    link_trainer: "Trainer Link",
    link_post_acquisto: "After-Purchase Link",
    link_clienti: "Customers Link",
    livello: "Level",
    login: "Log in",
    logo_business: "Business Logo",
    logout: "Log out",
    luogo: "Place",
    luogo_di_allenamento: "Training place",
    m: "m",
    maggiori_info: "More info",
    mai_allenato: "No training",
    maschio: "Male",
    massa_grassa: "Fat mass",
    massa_magra: "Muscle Mass Kg",
    massa_ossea: "Bone Mass Kg",
    max: "Max",
    membri_del_gruppo: "Group members",
    membri: "Members",
    mensile: "Monthly",
    mese: "Month",
    mesi_: "Months",
    mesi: "{mesi, plural, =1 {# month} other {# months}}",
    messaggio_di_ringraziamento: "Thank you message",
    metri: "meters",
    metodo_di_pagamento: "Payment method",
    minutes: "Minutes",
    minuti: "{minuti, plural, =1 {minute} other {minutes}}",
    minuti_abbreviato: "min",
    misure: "Measures",
    mm: "mm",
    modelli: "Templates",
    modifica_appuntamento: "Edit appointment",
    modifica_allegato: "Edit attachment",
    modifica_dettagli_di_pagamento: "Change payment details",
    modifica_membri: "Edit members",
    modifica_password: "Change password",
    modifica_plicometria: "Edit plicometry",
    modifica_promemoria: "Edit reminder",
    modifica_rilevazione: "Change",
    modifica: "Edit",
    modifica_carta: "Edit Card",
    modulo_progressi: "Progress module",
    molto_attivo: "Very active",
    mostra_altre: "Show others",
    mostra_altro: "Show others",
    mostra_numero_di_telefono_ai_clienti: "Show phone number to customers",
    nascondi_lista_esercizi: "Hide excercise list",
    nd: "N.A.",
    nessun_abbonamento: "No Subscriptions",
    nessun_allegato: "No attached files",
    nessun_allenamento_assegnato_al_gruppo: "No workouts assigned to group",
    nessun_allenamento_assegnato: "No assigned workouts",
    nessun_allenamento: "No workouts",
    nessun_cliente_trovato: "No customer found",
    nessun_cliente: "No customers",
    nessun_cliente_assegnato_al_gruppo: "No customers assigned to group",
    nessun_esercizio_trovato: "No exercise found",
    nessun_esercizio: "No excercises",
    nessun_gruppo_a_cui_assegnare_un_piano:
      "No groups found to assign a training card",
    nessun_gruppo: "No groups",
    nessun_piano_assegnato_ai_gruppi: "No plans assigned to your groups",
    nessun_piano_di_allenamento_trovato: "No training plan found",
    nessun_piano: "No plans",
    nessun_prodotto: "No products",
    nessun_video_assegnato: "No assigned videos.",
    nessun_video_da_assegnare:
      "No video to assign. Upload a new video and assign it to your customers.",
    nessun_video_trovato: "No videos found",
    nessun_video: "No Videos",
    nessuna_anamnesi: "No Medical Histories",
    nessuna_nota: "No notes",
    nessuna_foto_da_mostrare: "No photos to show",
    nessuna_playlist_trovata: "No playlists found",
    nessuna_playlist: "No Playlists",
    nessuna_progressione: "No progressions",
    nessuna_scadenza: "No expiration",
    no_credit_card_required: "No credit card required",
    nome_abbonamento: "Subscription name",
    nome_allegato: "Attachment name",
    nome_atleta: "Customer name",
    nome_esercizio: "Excercise name",
    nome_gruppo: "Group name",
    nome_modello: "Template name",
    nome_o_ragione_sociale: "Full Name or Company Name",
    nome_piano: "Plan name",
    nome_prodotto: "Product name",
    nome_scheda: "Card name",
    nome_titolare_tessera: "Name of the card holder",
    nome_video: "Video name",
    nome: "First Name",
    non_approvato: "Not approved",
    non_ci_sono_allegati_al_piano:
      "There are no attached files to this training card",
    non_ci_sono_abbonamenti_in_scadenza: "There are no expiring subscriptions",
    non_ci_sono_abbonamenti_scaduti: "There are no expired subscriptions",
    non_ci_sono_allenamenti_eseguiti_da_consultare:
      "There are no executed workouts to check",
    non_ci_sono_dati_presenti: "There is no data for the selected time period",
    non_ci_sono_fatture_da_mostrare: "There are no invoces to show",
    non_ci_sono_piani_di_allenamento_in_scadenza:
      "There are no expiring training cards",
    non_ci_sono_piani_di_allenamento_scaduti:
      "There are no expired training cards",
    non_ci_sono_video_on_demand_in_scadenza:
      "There are no expiring video on demand",
    non_ci_sono_video_on_demand_scaduti: "There are no expired video on demand",
    non_confermata: "Unconfirmed",
    non_hai_codici_sconto_attivi: "You have no active discount codes",
    non_hai_un_account: "Don't have an account yet?",
    non_puoi_eliminare_un_prodotto_in_revisione:
      "You can't delete a product pending for approval",
    non_puoi_modificare_un_prodotto_in_revisione:
      "You can't edit a product pending for approval",
    non_puoi_aggiungere_un_pt_o_te_stesso:
      "You can't add a personal trainer or yourself as a customer. Please use a different email address",
    non_puoi_disattivare_in_automatico_se_abbonamento_scaduto:
      "You can't auto-deactivate the customer if subscription is already expired",
    non_puoi_inserire_più_di_2000_caratteri:
      "You cannot enter more than 2000 characters",
    non_so: "I don't know",
    non_sono_stati_inseriti_i_seguenti_dati:
      "The following data have not been entered",
    not_a_pt:
      "If you want to use REVOO click <a href='https://www.revoo-app.com/scarica-app/' target='_blank'>here</a> to download the app",
    nota: "Note",
    note_generali: "General notes",
    note: "Notes",
    note_abbonamento: "Subscription notes",
    notifica: "Notification",
    notifica_inviata: "Notification sent",
    notifica_in_corso: "Sending notification",
    notifiche: "Notifications",
    notifiche_push_beta:
      "Push notifications on mobile app are currently in beta.",
    notifiche_attivare_disattivare:
      "You can enable or disable individual notifications:",
    numero_atleti: "Atlethes",
    numero_cellulare: "Mobile number",
    numero_video: "Number of Videos",
    numero: "Number",
    nuova_anamnesi: "New Medical History",
    nuova_password: "New Password",
    nuova_playlist: "New Playlist",
    nuova_plicometria: "New plicometry",
    nuova_progressione: "New pProgression",
    nuova_rilevazione: "New data",
    nuova_nota: "New note",
    nuove_foto_progressi: "New Progress Photo",
    nuove_foto: "New photo",
    nuove_misure: "New measures",
    nuovo: "New",
    nuovo_abbonamento: "New Subscription",
    nuovo_appuntamento: "New Appointment",
    nuovo_cliente: "New Customer",
    nuovo_esercizio: "New Exercise",
    nuovo_gruppo: "New Group",
    nuovo_piano_di_allenamento: "New Training Plan (draft)",
    nuovo_piano: "New Plan",
    nuovo_prodotto: "New Product",
    nuovo_promemoria: "New Reminder",
    nuovo_rilevamento_composizione: "New Body Composition Data",
    nuovo_rilevamento_misure: "New Measurements",
    nuovo_video: "New Video",
    o_trascina: "or drag",
    obbligatorio: "Mandatory",
    obiettivi: "Goals",
    obiettivo: "Goal",
    oggi_fromNow: "Today",
    ok_capito: "Understood",
    oggi: "Today",
    ogni: "each",
    onsite: "On-site",
    operazioni_chirurgiche: "Surgeries",
    ora: "Time",
    ore: "{ore, plural, =1 {hour} other {hours}}",
    p_iva_gia_compilata:
      "There is already a VAT Number. Cannot insert both fields.",
    p_iva_non_valida: "VAT N. not valid",
    "p.iva": "VAT Number",
    paese: "Country",
    pagato: "Paid",
    palestra_casalinga: "Home gym",
    palestra: "Gym",
    parametri_modificabili_da_sezione_schede:
      "The values for 'Week 1' Progression can be updated from the 'Cards' tab",
    passaparola: "Word of mouth",
    passi: "Steps",
    password_modificata_con_successo: "Password changed successfully",
    password_non_coincidono: "The two passwords do not match",
    password_non_corretta: "Incorrect password",
    password: "Password",
    patologie_diagnosticate: "Diagnosed disorders",
    pec: "CEM",
    pensiamo_a_tutto:
      "We'll take care of everything, the invited customers will receive the link by email.",
    per_lato: "Per side",
    perdita_di_grasso: "Lose weight",
    periodo_prova_scade_oggi: "Your trial period ends today. To continue, update your payment data",
    periodo_prova_scade_oggi_new_pricing: "Your trial period ends today. To continue, choose a plan.",
    periodo_prova_scade: "Trial period ends in {days} days",
    periodo_prova_scaduto_mobile: "Your trial period is over! To continue, please login from browser and add your payment data",
    periodo_prova_scaduto_mobile_new_pricing: "Your trial period is over! To continue, please choose a plan",
    periodo_prova_scaduto: "Your trial period is over! To continue, please add your payment data",
    periodo_prova_scaduto_new_pricing: "Your trial period is over! To continue, please choose a plan",
    personal_trainers: "Personal Trainers",
    peso_attuale: "Current weight",
    peso: "Weight",
    petto: "Chest",
    pettorale: "Chest",
    piani_allenamento: "Training cards",
    piani_di_allenamento_in_scadenza: "Expiring training cards",
    piani_di_allenamento: "Templates",
    piani: "Plans",
    piano_di_allenamento: "Training plan",
    piano: "Plan",
    pillola_contraccettiva: "Contraceptive pill",
    plan_conflict_exception: "Incompatible parameters with the current plan. Update the number of customers or video on demand.",
    playlist: "Playlists",
    plicometria: "Plicometry",
    poco_attivi: "Not very active",
    poco_attivo: "Not very active",
    polpaccio_destro: "Right calf",
    polpaccio_dx: "Calf R",
    polpaccio_sinistro: "Left calf",
    polpaccio_sx: "Calf L",
    popup_cambio_abbonamento_titolo: "Update Subscription",
    popup_cambio_abbonamento_sottotitolo: "Choose a new monthly or yearly plan.",
    popup_cambio_abbonamento_sottotitolo_trigger_customers: "You have reached the maximum number of customers. Upgrade to add more.",
    popup_cambio_abbonamento_sottotitolo_trigger_vods: "You have reached the maximum number of videos. Upgrade to add more.",
    popup_cambio_abbonamento_sottotitolo_trigger_no_vods: "This feature is not included in your plan. Upgrade to add videos.",
    popup_cambio_abbonamento_sottotitolo_trigger_revoo_pay: "Upgrade your subscription to purchase and use the REVOO PAY service.",
    popup_cambio_abbonamento_sottotitolo_trigger_custom_app: "Upgrade your subscription to purchase custom app mobile.",
    popup_scelta_piano_trial_in_corso: "Choose the plan that will be activated at the end of the trial",
    popup_trial_abbonamento_attivo: "Subscription activated",
    popup_trial_abbonamento_aggiornato: "Subscription updated",
    popup_trial_ciao: "Hi <strong>{firstName}</strong>!",
    popup_trial_complimenti:
      "Congratulations <strong>{firstName}</strong>, your subscription is now active!",
    popup_trial_complimenti_piano_aggiornato:
      "Congratulations <strong>{firstName}</strong>, your subscription is updated as requested!",
    popup_trial_grazie: "Thank you for choosing REVOO.",
    popup_trial_grazie_per_la_fiducia: "Thank you for trusting us.",
    popup_trial_iva_inclusa: "(VAT included)",
    popup_trial_over_ricevi_altri_giorni_di_prova:
      "Click below to get 10 more days to try REVOO for free.",
    popup_trial_over_ultime_novita:
      "The trial period is over, do you want to try the latest features?",
    popup_trial_over_prova_revoo: "Try REVOO now",
    popup_trial_in_corso_scegli_il_piano:
      "Choose one of the following Plans for your REVOO subscription. You will continue to enjoy the free trial period until it expires.",
    popup_trial_per_attivare:
      "Your trial period has expired, to activate your REVOO subscription choose one of the following Plans.",
    popup_trial_periodo_di_prova_scaduto: "Trial period is over",
    popup_trial_quanto_costa_al_mese:
      "2€/month for each active customer, up to 24 clients.",
    popup_trial_quanto_costa_flat: "49€/month with 25 or more clients.",
    popup_trial_quanto_costa: "How much is REVOO?",
    porta_un_amico_trainer:
      "Bring a trainer to REVOO and save on your subscription. Here is your referral code.",
    porta_un_amico: "Refer a friend",
    potremmo_utilizzare_questo_numero:
      "We may use this phone number to contact you for security or support reasons",
    prefisso: "Prefix",
    preparazione_atletica: "Athletic training",
    pressione_arteriosa: "Blood pressure",
    prezzo_: "Price",
    prezzo_barrato: "Strikethrough Price",
    prezzo_barrato_tooltip:
      "If you decide to offer the product at a discounted price, you can enter its original price here; it will be shown as 'strikethrough price' to increase conversions. Attention, this is not the final sale price!",
    prezzo_finale: "Final Price",
    prezzo_unitario: "PRICE PER UNIT",
    prezzo: "AMOUNT",
    prezzo: "Price",
    principiante: "Beginner",
    problemi_cardiaci: "Heart diseases",
    problemi_posturali: "Postural diseases",
    problemi_tiroide: "Thyroid diseases",
    prodotto: "Product",
    prodotto_acquistabile_separatamente: "Product not included, available for separate purchase",
    prodotti: "Products",
    professione: "Job",
    profilo_revoo: "REVOO profile",
    profilo: "Profile",
    progressi_check: "Progress and check",
    progressioni: "Progressions",
    promemoria: "Reminder",
    prosegui: "Next step",
    prosegui_senza_salvare: "Continue without saving",
    prossimi_n_giorni: "Next {days} days",
    prossimo_addebito: "Next Charge",
    proteine: "Proteins",
    protocollo_donna_3_pliche: "3 plics protocol (female)",
    protocollo_donna_7_pliche: "7 plics protocol (female)",
    protocollo_uomo_3_pliche: "3 plics protocol (male)",
    protocollo_uomo_7_pliche: "7 plics protocol (male)",
    provincia: "Province",
    prozis: "Prozis",
    punto_vita: "Waistline",
    quantità: "QUANTITY",
    queste_informazioni_non_saranno_visibili_ai_tuoi_clienti:
      "This information will not be visible to your customers",
    questo_è_il_credito:
      "This is the credit earned through a discount code or referral code. It will be deducted from your next invoice",
    qui_puoi_inserire_link_video:
      "Here you can insert the link of the video <a href = 'https: //how.revoo-app.com/knowledge/quali-sono-i-video-file-which-can-insert-in-training-plan target = '_ blank'> Find out how </a>. <br/> Attention! If you enter the external video link, you will hide the current video.",
    rank: "Rank",
    rinnovo_abbonamento: "Subscription renewal",
    rear_view: "Back",
    recupera_password: "Recover password",
    referral_code_applicato: "Referral code added successfully",
    referral_code_errato: "Referral code wrong",
    referral_code_invalido: "Referral code not valid",
    referral_code_non_esistente: "Referral code not found",
    referral_code_personale: "Personal referral code",
    registrati_su_revoo: "Register on REVOO to manage your customers",
    registrati: "Register",
    registrazione_non_riuscita: "Registration failed",
    regola_lo_zoom: "Regulate zoom",
    reinvia: "Resend",
    reinvia_password: "Resend Password",
    remoto: "In distance",
    rendi_visibile_al_cliente: "Show subscription to customer",
    report: "Report",
    reps: "Reps",
    reset_password: "Reset Password",
    rest: "Rest",
    riabilitazione: "Rehabilitation",
    ricevute: "Receipts",
    richiedi_webinar: "Request Webinar",
    ricordami: "Remember me",
    rigenera_codice_invito: "Refresh code",
    right_view: "Right side",
    rimuovi_dal_gruppo_programmaticamente: "Automate the removal of customers from group",
    rimuovi_dal_gruppo: "Remove from group",
    rimuovi_set: "Remove set",
    rimuovi: "Remove",
    rinomina_gruppo: "Rename group",
    rinomina_playlist: "Rename playlist",
    ripeti_nuova_password: "Repeat new password",
    riproduci: "Play",
    rm: "RM",
    rpe: "RPE",
    ruota_schermo: "Flip the screen to continue",
    saldo_applicato: "Credit Balance",
    salva_automazioni: "Save automations",
    salva_automazione: "Save automation",
    salva_come_bozza: "Save as draft",
    salva_come_nuovo: "Save as new",
    salva_e_prosegui: "Save and continue",
    salva_e_inizia: "Save and start",
    salva_e_invia: "Save and send",
    salva_e_invita_cliente: "Save and invite customer",
    salva_ed_esci: "Save and exit",
    salva_modifiche: "Save Changes",
    salva: "Save",
    salva_carta: "Save Card",
    scadenza: "Deadline",
    scadenze: "Deadlines",
    scaduti_n_giorni: "Expired last {days} days",
    scaduto: "Expired",
    scarica_ricevuta: "Download receipt",
    scegli_clienti: "Select clients",
    scegli_file: "Choose file",
    scegli_piano: "Choose Plan",
    scegli_una_tipologia: "Choose a type",
    scegli_video: "Choose a Video",
    scegli_video: "Select videos",
    scegli: "Choose",
    schede_: "{schede, plural, =1 {# workout} other {# workouts}}",
    schede: "Cards",
    scopri_di_piu: "Discover More",
    scopri_i_nostri_servizi: "Discover our services",
    scopri_il_programma_referral:
      "Discover REVOO's referral program: share your referral code with your trainer friends,<br/>once they have signed up for REVOO, you will earn credits.",
    se_non_assegni_una_durata_playlist:
      "If you don't insert a deadline, the playlist will be visible forever. Do you want to continue?",
    se_non_assegni_una_durata_video:
      "If you don't insert a deadline, the video will be visible forever. Do you want to continue?",
    se_vuoi_aggiungere_un_cliente:
      "If you want to add a test customer, enter a fantasy email, you can delete it later.",
    seconds: "Seconds",
    sedentario: "Sedentary",
    sei_mesi: "Six months",
    sei_settimane: "Six weeks",
    sei_sicuro_di_voler_confermare_invio_email:
      "Are you sure you want to notify the customers?",
    sei_sicuro_di_voler_disattivare:
      "Are you sure you want to deactivate the customer? You will not be able to take any action on a deactivated customer.",
    sei_sicuro_di_voler_eliminare_il_gruppo:
      "Are you sure you want to delete the group?",
    sei_sicuro_di_voler_eliminare_il_piano:
      "Are you sure you want to delete the training plan?",
    sei_sicuro_di_voler_eliminare_il_video_assegnato:
      "Are you sure you want to delete the assigned video?",
    sei_sicuro_di_voler_eliminare_il_video:
      "Are you sure you want to delete the video?",
    sei_sicuro_di_voler_eliminare_l_abbonamento:
      "Are you sure you want to delete the subscription?",
    sei_sicuro_di_voler_eliminare_l_allegato:
      "Are you sure you want to delete the attachment?",
    sei_sicuro_di_voler_eliminare_l_anamnesi:
      "Are you sure you want to delete the medical history?",
    sei_sicuro_di_voler_eliminare_l_esercizio:
      "Are you sure you want to delete the exercise?",
    sei_sicuro_di_voler_eliminare_la_nota:
      "Are you sure you want to delete the note?",
    sei_sicuro_di_voler_eliminare_gli_esercizi:
      "Are you sure you want to delete the exercises?",
    sei_sicuro_di_voler_eliminare_l_evento:
      "Are you sure you want to delete this event from calendar?",
    sei_sicuro_di_voler_eliminare_la_playlist_assegnata:
      "Are you sure you want to delete the assigned playlist?",
    sei_sicuro_di_voler_eliminare_la_playlist:
      "Are you sure you want to delete the playlist?",
    sei_sicuro_di_voler_modificare_il_piano_gruppi:
      "Are you sure you want to edit the training plan? (Customers assigned to group could lose suggestions of last executed workout)",
    sei_sicuro_di_voler_modificare_il_piano:
      "Are you sure you want to edit the training plan? (The customer could lose suggestions of last executed workout)",
    sei_sicuro_di_voler_resettare_la_password_del_cliente:
      "Are you sure you want to reset the customer password? (The customer will receive new password by email)",
    sei_sicuro_di_voler_rimuovere_l_atleta_dai_gruppi_no_question:
      "Are you sure you want to remove customer from groups",
    sei_sicuro_di_voler_rimuovere_l_atleta_dai_gruppi:
      "Are you sure you want to remove customer from groups?",
    sei_sicuro_di_voler_rimuovere_l_atleta_dal_gruppo_no_question:
      "Are you sure you want to remove customer from group",
    sei_sicuro_di_voler_rimuovere_l_atleta_dal_gruppo:
      "Are you sure you want to remove customer from group?",
    sei_sicuro_di_voler_uscire:
      "Are you sure you want to exit this window? Unsaved changes will be lost.",
    sei_sicuro_di_voler_eliminare_la_composizione_del:
      "Are you sure you want to delete the composition of {date}? (Irreversible action)",   
    sei_sicuro_di_voler_eliminare_le_circonferenze_del:
      "Are you sure you want to delete the circumference of {date}? (Irreversible action)",
    "select-or-delete-exercise": "Select an exercise or update the combination",
    seleziona_foto: "Select photo",
    seleziona_video: "Select video",
    serve_fattura_elettronica:
      "If you need to receive an e-invoice on the italian SDI system please fill one of the field (CEM or SDI Unique Code)",
    sesso: "Sex",
    set: "Set",
    sets: "Sets",
    settimana: "Week",
    settimane_: "Weeks",
    settimane_abbr: "{settimane_abbr, plural, =1 {# wk} other {# wks}}",
    settimane: "{settimane, plural, =1 {# week} other {# weeks}}",
    si_è_verificato_un_errore: "An error has occurred",
    si_è_verificato_un_errore_scrivici_in_chat:
      "An error has occurred. Contact us via live chat.",
    si: "Yes",
    sicuro_di_voler_eliminare_il_cliente:
      "Are you sure you want to delete the customer?",
    sigla_provincia_non_valida: "Province code is invalid (insert 2 letters)",
    simbolo_c: "°C",
    singolo: "Single",
    solo_l_atleta_puo_modificare_l_email:
      "Email address is only editable by your customer in the app settings",
    soprailiaca: "Suprailiac",
    sostituisci: "Change",
    sottoscapolare: "Subscapularis",
    stai_andando_alla_sezione_info_piano:
      "You are moving to Plan Info section, unsaved changes will be lost.",
    stai_andando_alla_sezione_allegati:
      "You are moving to Attachments section, unsaved changes will be lost.",
    stampa: "Print",
    stato: "Status",
    status: "Status",
    stile_di_vita: "Lifestyle",
    stima_prossimo_addebito: "Estimate next charge",
    storico_ricevute: "Receipt history",
    subscription_dialog_final_step_pricing_change_message: "Congratulations {firstName}, your new subscription will be active from {activation_date}.",
    subscription_dialog_final_step_pricing_change_title: "Subscription change successfully requested",
    subscription_dialog_payment_step_title: "Summary and confirmation",
    subtotale: "Subtotal",
    subtotale: "Subtotal",
    suddiviso: "Subdivided",
    superset: "Superset",
    tag: "Tag",
    tags: "Tags (max 3)",
    team: "Team",
    telefono: "Phone",
    temperatura_corporea: "Body temp.",
    time: "Time",
    tipo_di_cliente: "Customer type",
    tipo_di_pagamento: "Payment type",
    tipologia: "Type",
    titolo: "Title",
    torna_al_login: "Back to login",
    torna_a_revoo: "Back to REVOO",
    totale: "Total",
    transazioni: "Transactions",
    trascina_i_video_per_ordinarli: "Drag and drop videos to sort them",
    trascina_immagine_per_tagliare:
      "Drag the image to select the area to be cut",
    tre_mesi: "Three months",
    trial_scaduto_banner: "Your trial period has expired. Choose a plan to continue using REVOO.",
    tricipite: "Tricep",
    trova_nuovi_clienti: "Find new clients",
    tut: "TUT",
    tutte_le_date: "All dates",
    tutte_le_schede: "All cards",
    tutti_i_tag: "All tags",
    tutti_i_tuoi_clienti_sono_assegnati_al_gruppo:
      "All your customers are assigned to this group",
    tutti: "All",
    ultima_rilevazione_pressione: "Last pressure reading",
    ultima_settimana: "Last week",
    ultimi_3_mesi: "Last 3 months",
    ultimi_30_giorni: "Last 30 days",
    ultimi_6_mesi: "Last 6 months",
    ultimo_allenamento: "Last training",
    ultimo_aggiornamento: "Last update",
    ultimo_anno: "Last year",
    ultimo_step_e_ci_siamo: "The last step and here we are!",
    ultimo: "last",
    upgrade: "Upgrade",
    utilizzo_farmaci: "Drugs intake",
    utilizzo_integratori: "Supplement intake",
    valida: "Valid",
    vecchia_password: "old password",
    vedi_dettagli: "See details",
    vedi_dettaglio: "See details",
    vedi_di_piu: "See more",
    vedi_lista_esercizi: "See exercises list",
    versione_classica: "Classic version",
    video_allenamenti: "Video on Demand",
    video_assegnati: "Assigned Videos",
    video: "Video",
    videocall: "Videocall",
    views_totali: "Total views",
    visualizza: "View",
    visualizza_la_libreria_di_esercizi_revoo: "Show REVOO exercises library",
    visualizza_la_libreria_di_esercizi_revoo_mobile: "Show REVOO exercises",
    visualizzazione_semplificata_per_il_cliente:
      "Simplified view for the customer",
    visualizzazione_semplificata: "Simplified view",
    visualizzazioni_del_tuo_mese_di_abbonamento: "Billing cycle views",
    visualizzazioni_mensili: "Monthly views",
    visualizzazioni_totali: "Total views",
    visualizzazioni: "Views",
    vita: "Waist",
    vod_disclaimer:
      "Videos on Demand have 50 free monthly views. Exceeding this threshold a dedicated paid subscription will be automatically activated. Click here for more information. ",
    volume: "Volume",
    vuoi_sapere_come_condividere_video_allenamenti:
      "Do you want to know how to share Videos on Demand with your customers?",
    vuoi_sapere_come_creare_esercizi_personalizzati:
      "Do you want to know how to create custom exercises?",
    vuoi_sapere_come_creare_un_anamnesi:
      "Do you want to know how to create a history?",
    vuoi_sapere_come_creare_un_piano_di_allenamento_per_il_tuo_cliente:
      "Do you want to know how to create a training card for your customer",
    vuoi_sapere_come_gestire_i_progressi:
      "Do you want to know how to manage your customers' progress?",
    vuoi_sapere_come_si_crea_un_piano_di_allenamento:
      "Do you want to know how to create a training card and how to assign it to a customer?",
    vuoi_sapere_come_si_crea_una_scheda:
      "Do you want to know how to create a training card?",
  },
  it: {
    "blocco_upload_video": "Servizio temporaneamente sospeso. Riprova più tardi.",
    "%": "%",
    "1_ora_prima": "1 ora prima",
    n_ore_prima: "{value} ore prima",
    "1_giorno_prima": "1 giorno prima",
    n_giorni_prima: "{value} giorni prima",
    "1_volta_a_settimana": "1 volta a settimana",
    "2_volte_a_settimana": "2 volte a settimana",
    "3_volte_a_settimana": "3 volte a settimana",
    "4_volte_a_settimana": "4 volte a settimana",
    "5_volte_a_settimana": "5 volte a settimana",
    "6_volte_a_settimana": "6 volte a settimana",
    "7_volte_a_settimana": "7 volte a settimana",
    abbonamenti: "Abbonamenti",
    abbonamenti_in_scadenza: "Abbonamenti in scadenza",
    abbonamento_annullato_contattaci_in_chat:
      "Il tuo abbonamento a REVOO è stato annullato. Contattaci in Chat per ripartire subito!",
    abbonamento_revoo: "Abbonamento REVOO",
    abbonamento: "Abbonamento",
    abbonati: "Abbonati",
    accedi_per_gestire_i_tuoi_clienti: "Accedi per gestire i tuoi clienti.",
    accedi: "Accedi",
    accetto_i_termini_e_le_condizioni:
      "Accetto i <a href='https://www.iubenda.com/termini-e-condizioni/65979137' target='_blank'>Termini e Condizioni *</a>",
    accetto_la_privacy_policy:
      "Accetto la <a href='https://www.iubenda.com/privacy-policy/65979137' target='_blank'>Privacy Policy *</a>",
    account: "Account",
    acquisto_singolo: "Acquisto singolo",
    acquisto_unico: "Acquisto unico",
    addome: "Addome",
    admin: "Admin",
    aggiorna_dati: "Aggiorna dati",
    aggiorna_dati_pagamento: "Dati pagamento",
    aggiorna_dati_fatturazione: "Dati fatturazione",
    aggiorna: "Aggiorna",
    aggiorna_nota: "Aggiorna nota",
    aggiungi_abbonamento: "Aggiungi Abbonamento",
    aggiungi_allenamento: "Aggiungi allenamento",
    aggiungi_allegato: "Aggiungi allegato",
    aggiungi_carta: "Aggiungi carta",
    aggiungi_codice_sconto: "Aggiungi Codice Sconto",
    aggiungi_file: "Aggiungi file",
    aggiungi_esercizio: "Aggiungi Esercizio",
    aggiungi_i_tuoi_atleti: "Aggiungi i tuoi clienti",
    aggiungi_i_tuoi_gruppi: "Aggiungi i tuoi gruppi",
    aggiungi_i_tuoi_video: "Aggiungi i tuoi Video",
    aggiungi_le_tue_playlist: "Aggiungi le tue Playlist",
    aggiungi_progressione: "Aggiungi progressione",
    aggiungi_scheda: "Aggiungi scheda",
    aggiungi_set: "Aggiungi set",
    aggiungi_un_abbonamento_per: "Aggiungi un Abbonamento per",
    aggiungi_un_link_ad_una_cartella:
      "Aggiungi un link ad una cartella condivisa per condividere documenti con i tuoi clienti (es. Dieta).",
    aggiungi_un_video: "Aggiungi un Video",
    aggiungi_una_playlist: "Aggiungi una Playlist",
    aggiungi_una_progressione: "Aggiungi una progressione",
    aggiungi: "Aggiungi",
    aggiunto_in_data: "Aggiunto in data",
    al_mese: "al Mese",
    all_anno: "all'Anno",
    alimentare: "Alimentare",
    alimentare: "Alimentare",
    alimentazione: "Alimentazione",
    all_aperto: "All'aperto",
    allegati: "Allegati",
    allegato: "Allegato",
    allenamenti: "Allenamenti",
    allenamento: "Allenamento",
    allergie: "Allergie",
    altezza_inserita_non_è_valida: "L'altezza inserita non è valida",
    altezza: "Altezza",
    altre_patologie: "Altre patologie",
    altro: "Altro",
    anamnesi: "Anamnesi",
    anni_: "Anni",
    anno: "Anno",
    annuale: "Annuale",
    anni_abbr: "{anni_abbr, plural, =1 {# anni} other {# anni}}",
    anni: "{anni, plural, =1 {# anno} other {# anni}}",
    annulla: "Annulla",
    app_personalizzata: "Crea la tua app",
    appuntamento: "Appuntamento",
    appuntamenti_e_promemoria: "Appuntamenti e promemoria",
    appuntamenti_e_promemoria_prenotati: "Appuntamenti e promemoria prenotati",
    apri_link: "Apri link",
    ascellare: "Ascellare",
    asma: "Asma",
    assegna_a: "Assegna a",
    assegna_ai_gruppi: "Assegna ai gruppi",
    assegna_al_gruppo: "Assegna al gruppo",
    assegna_allenamento: "Assegna allenamento",
    assegna_copia_di: "Assegna copia di",
    assegna_il_primo_video_o_la_prima_playlist_per:
      "Assegna il primo video o la prima playlist per",
    assegna_il_primo_video_per: "Assegna il primo video per",
    assegna_la_prima_playlist_per: "Assegna la prima playlist per",
    assegna_playlist: "Assegna Playlist",
    assegna_video_alla_playlist: "Assegna video alla playlist",
    assegna_video_alla_playlist: "Assegna video alla playlist",
    assegna_video: "Assegna Video",
    assegna_tutti_i_tuoi_clienti:
      "Assegna tutti i tuoi clienti a questo gruppo",
    assegna: "Assegna",
    assegnati_agli_atleti: "Assegnati agli clienti",
    assegnati_ai_gruppi: "Assegnati ai gruppi",
    assegnati: "Assegnati",
    atleta_già_assegnato_a_te:
      "Attenzione: questo cliente è già assegnato a te",
    atleta_già_assegnato_ad_altro_pt:
      "Attenzione: questo cliente è già assegnato ad un altro PT",
    atleta_non_ha_attivato_sincronizzazione:
      "Il tuo cliente non ha attivato la sincronizzazione con lo smartphone",
    atleta_non_si_allena_15_giorni:
      "Il cliente non si allena da almeno 15 giorni",
    atleta_non_si_allena_7_giorni:
      "Il cliente non si allena da più di 7 giorni",
    atleta_non_si_è_mai_allenato: "Il cliente non si è mai allenato",
    atleta_rimosso_dal_gruppo: "Cliente rimosso dal gruppo",
    atleta_si_allena_regolarmente: "Il cliente si allena regolarmente",
    atleta: "Cliente",
    atleti: "Clienti",
    attenzione: "Attenzione",
    attenzione_sono_stati_selezionati_solo_placeholder:
      "Attenzione: Sono stati selezionati solo placeholder",
    attenzione_vod_funzione_premium:
      "Attenzione! Questa è una funzione premium, superate le 50 visualizzazioni mensili verrà automaticamente attivato il piano a pagamento. Clicca per maggiori informazioni",
    attiva_chat_per_i_nuovi_membri_del_gruppo: "Attiva Chat per i nuovi membri del gruppo",
    attiva_chat_per_i_nuovi_clienti: "Attiva Chat per i nuovi clienti",
    attiva_chat_per_questo_cliente: "Attiva Chat per questo cliente",
    attiva_l_atleta: "Attiva il cliente",
    attivato: "Attivato",
    attivi: "attivi",
    attivita_fisica: "Attività fisica",
    attivo: "Attivo",
    attrezzatura: "Attrezzatura",
    aumento_massa_muscolare: "Aumento massa muscolare",
    automazioni: "Automazioni",
    avanti: "Avanti",
    avanzamento: "Avanzamento",
    avanzato: "Avanzato",
    bentornato: "Bentornato!",
    benvenuto: "Benvenuto!",
    bf: "BF",
    bicipite_destro: "Bicipite Destro",
    bicipite_dx: "Bicipite dx",
    bicipite_sinistro: "Bicipite Sinistro",
    bicipite_sx: "Bicipite sx",
    bio: "Bio",
    bmr: "BMR",
    body_water: "Body water",
    bones: "Bones",
    bozza: "bozza",
    business_nickname: "Business Nickname",
    c_f_gia_compilato:
      "Hai già inserito il Codice Fiscale. Non puoi inserire entrambi i campi.",
    c_f_non_valido: "Codice Fiscale non valido",
    "c.f.": "Codice Fiscale",
    cal: "cal",
    calcola_bf_con_plicometria: "Calcola BF% <br/> con plicometria",
    calcola: "Calcola",
    calendario: "Calendario",
    calorie_bruciate: "Calorie bruciate",
    cambia_abbonamento: "Cambia Abbonamento",
    cambia_piano_ora: "Cambia piano ora",
    cambia_piano: "Cambia piano",
    cancella: "Cancella",
    cap: "CAP",
    carboidrati: "Carboidrati",
    carica: "Carica",
    carica_documento: "Carica_documento",
    caricamento_in_corso: "Caricamento in corso",
    carta_di_credito: "Carta di credito",
    categoria: "Categoria",
    cerca_per_nome_atleta_o_piano: "Cerca per nome cliente o piano",
    cerca_per_nome_o_tag: "Cerca per nome o tag",
    cerca_per_nome: "Cerca per nome",
    cerca_per_piano: "Cerca per piano",
    cerca: "Cerca",
    chat: "Chat",
    chiudi: "Chiudi",
    ciclo_irregolare: "Ciclo irregolare",
    circuito_a_tempo: "Circuito a tempo",
    circuito: "Circuito",
    città: "Città",
    cliente_non_ha_consentito_a_fornirti_i_dati:
      "Il tuo cliente non ha consentito a fornirti i dati",
    cliente: "Cliente",
    clienti_aggiunti: "Clienti aggiunti",
    clienti_attivi: "Clienti attivi",
    clienti_gruppi: "Clienti / Gruppi",
    clienti: "clienti",
    cm: "cm",
    coaching_diretto: "Coaching diretto con il cliente",
    coaching_online: "Coaching online o da remoto",
    codice_fiscale: "Codice Fiscale",
    codice_invito_clienti_default_attivi:
      "Il cliente che si registra con il codice sarà automaticamente inserito come ATTIVATO e potrà allenarsi da subito.",
    codice_invito_clienti_default_non_attivi:
      "Il cliente che si registra con il codice sarà automaticamente inserito come DISATTIVATO e non potrà ancora allenarsi.",
    codice_invito_label_gruppo:
      "Condividi questo codice invito con il tuo nuovo cliente per farlo registrare al gruppo",
    codice_invito_label:
      "Condividi questo codice invito con il tuo nuovo cliente per collegarlo al tuo account REVOO.",
    codice_invito: "Codice invito",
    codice_postale_errato: "Codice postale errato",
    codice_referral: "Codice Referral",
    codice_sconto: "Codice Sconto",
    codice_univoco: "Codice univoco",
    cognome: "Cognome",
    combinazione: "Combinazione",
    come_funziona: "Come funziona",
    come_lo_hai_conosciuto: "Come lo hai conosciuto",
    completa_il_tuo_profilo_per_iniziare:
      "Completa il tuo profilo per iniziare",
    composizione: "Composizione",
    con_sfondo_trasparente: "con sfondo trasparente",
    consulenza: "Consulenza",
    conferma: "Conferma",
    conferma_assegnazione_di_massa_attivata:
      "Sei sicuro di voler assegnare tutti i tuoi clienti attuali e futuri a questo gruppo? (Eventuali automazioni di rimozione dal gruppo saranno rimosse)",
    conferma_assegnazione_di_massa_disattivata:
      "Tutti i tuoi clienti saranno rimossi da questo gruppo. Sei sicuro di voler proseguire?",
    confermata: "confermata",
    continua: "Continua",
    controlla_la_tua_inbox: "Controlla la tua inbox.",
    copia_allenamento_di_atleta: "Copia allenamento di cliente",
    copia_negli_appunti: "Copia negli appunti",
    copia_piano: "Copia piano",
    copia_scheda: "Copia scheda",
    correzione_posturale: "Correzione posturale",
    cosa_vuoi_aggiungere: "Cosa vuoi aggiungere?",
    coscia_dx: "Coscia dx",
    coscia_sx: "Coscia sx",
    coscia: "Coscia",
    coupon_percent: "sconto del",
    crea: "Crea",
    crea_nota: "Crea nota",
    crea_appuntamento: "Crea appuntamento",
    crea_gruppo: "Crea gruppo",
    crea_i_tuoi_esercizi: "Crea i tuoi esercizi",
    crea_i_tuoi_piani_per_i_gruppi: "Crea i tuoi piani per i gruppi",
    crea_i_tuoi_piani: "Crea i tuoi piani di allenamento",
    crea_il_tuo_sito_web: "Crea il tuo sito web",
    crea_il_tuo_primo_prodotto: "Crea il tuo primo prodotto",
    crea_la_prima_anamnesi: "Crea la prima anamnesi",
    crea_la_prima_nota: "Crea la prima nota",
    crea_nuovo_abbonamento: "Crea nuovo abbonamento",
    crea_nuovo_esercizio: "Crea nuovo esercizio",
    crea_nuovo_piano: "Crea nuovo piano",
    crea_nuovo_video: "Crea nuovo Video",
    crea_playlist: "Crea Playlist",
    crea_primo_piano_allenamento: "Crea il primo piano di allenamento per",
    crea_promemoria: "Crea promemoria",
    crea_un_cliente_di_prova:
      'Crea un cliente di prova: clicca su "Nuovo cliente"!',
    crea_un_gruppo: "Crea un gruppo",
    creata_da: "Creata da",
    credito_e_codici_sconto: "Credito e Referral",
    credito_residuo: "Credito residuo",
    contenuto: "contenuto",
    da_atleta: "da cliente",
    da_modello: "da modelli",
    da_pagare: "Da pagare",
    da_remoto: "Da remoto",
    da: "da",
    dai_tuoi_modelli_di_allenamento_standard_e_dai_gruppi:
      "Dai tuoi modelli di allenamento standard e dai gruppi",
    dai_tuoi_modelli_di_allenamento_standard:
      "Dai tuoi modelli di allenamento standard",
    dal_loro_ingresso: "Dal loro ingresso",
    dal_vivo_e_remoto: "Dal vivo e remoto",
    dal_vivo: "Dal vivo",
    data_addebito: "Data addebito",
    data_di_fine: "Data di fine",
    data_di_inizio: "Data di inizio",
    data_di_nascita: "Data di nascita",
    data_di_scadenza: "Data di scadenza",
    data_inizio: "Data inizio",
    data: "Data",
    dati_fatturazione: "Aggiungi i tuoi dati di fatturazione",
    dati_fatturazione_verifica: "Verifica i tuoi dati di fatturazione",
    dati_fatturazione_mancanti_mobile:
      "Dati di fatturazione non presenti. Per continuare accedi a REVOO dal browser e aggiorna i dati.",
    dati_fatturazione_mancanti:
      "Dati di fatturazione non presenti. Per continuare aggiorna i dati.",
    dati_non_completi: "Dati non completi",
    dati_personali: "Dati personali",
    definizione: "Definizione",
    descrivi_obiettivo: "Descrivi obiettivo",
    descrizione_maiusc: "DESCRIZIONE",
    descrizione: "Descrizione",
    descrizione_breve: "Descrizione breve",
    dettagli: "Dettagli",
    dettagli_di_fatturazione: "Dettagli di fatturazione",
    dettagli_di_pagamento_e_fatturazione:
      "Dettagli di pagamento e fatturazione",
    dettagli_esercizio: "Dettagli esercizio",
    dettaglio_iva:
      "Il valore indicato è comprensivo di IVA al 22% se dovuta e sarà reso esplicito in sede di emissione di fattura",
    dettaglio_prossimo_addebito: "Dettaglio prossimo addebito",
    dettaglio: "Dettaglio",
    diabete: "Diabete",
    digita_la_localita_e_scegli_una_voce_dal_menu_a_tendina:
      "Digita la località e scegli una voce dal menu a tendina",
    disattiva_il_cliente_allo_scadere:
      "Disattiva il cliente allo scadere dell'abbonamento",
    disattiva_il_cliente_alla_scadenza: "Disattiva il cliente alla scadenza",
    disattiva_l_atleta: "Disattiva il cliente",
    disattivato: "Disattivato",
    disattivo: "Disattivo",
    distanza: "Distanza",
    disturbi_alimentari: "Disturbi alimentari",
    dorsale: "Dorsale",
    dopo: "Dopo",
    duplica: "Duplica",
    durata_in_settimane: "Durata in settimane",
    durata: "Durata",
    e: "e",
    edita_immagine: "Edita immagine",
    elementare: "Elementare",
    elimina_gruppo: "Elimina gruppo",
    elimina_profilo_overlay:
      "Devi disattivare il tuo cliente prima di eliminarlo",
    elimina_profilo: "Elimina profilo",
    elimina: "Elimina",
    elimina_account: "Elimina account",
    eliminazione_account: "Eliminazione account",
    eliminata: "eliminata",
    email_errata: "Email errata",
    email_esiste_già: "L'email esiste già",
    email_non_registrata: "Email non registrata",
    email_non_valida: "Email non valida",
    email_reinviata_con_successo: "Email reinviata con successo.",
    email: "Email",
    emom: "EMOM",
    entrambi: "Entrambi",
    errore_pagamento_mobile:
      "C’è stato un errore con la tua carta! Per continuare accedi a REVOO dal browser e aggiorna i dati",
    errore_pagamento:
      "C’è stato un errore con la tua carta! Per continuare aggiorna i dati",
    esci: "Esci",
    esporta: "Esporta",
    espandi: "Espandi",
    esercizi_privato:
      "esercizi (<span class='material-icons' style='font-size: 1em'>lock</span> privato)",
    esercizi: "Esercizi",
    esercizio: "Esercizio",
    esercizio_revoo: "Esercizio REVOO",
    esiste_già_un_esercizio_con_questo_nome:
      "Esiste già un esercizio con questo nome",
    estremamente_attivo: "Estremamente attivo",
    età_metabolica: "Età Metabolica",
    età: "Età",
    euro: "Euro",
    facoltativo: "(facoltativo)",
    fase_di_testing_gratuito:
      "Questa funzione è in fase di testing gratuito. In futuro, le prime 300 visualizzazioni mensili saranno gratuite per tutti i personal trainer; se ti serviranno più visualizzazioni dovrai acquistare un pacchetto aggiuntivo. Non ti preoccupare, per il momento, i tuoi clienti potranno continuare a vedere il video anche se oltrepasserai il limite.",
    fase_di_testing_gratuito_short:
      "Questa funzione è in fase di testing gratuito.",
    fatto: "Fatto",
    fatturazione: "Fatturazione",
    fatture_inviate_automaticamente:
      "Le fatture sono inviate automaticamente al tuo indirizzo email.<br/>Per qualsiasi necessità scrivici in chat oppure scrivi a <b>fatture@revoo-app.com</b>",
    fatture_inviate_automaticamente_clean:
      "Le fatture sono inviate automaticamente al tuo indirizzo email. Per qualsiasi necessità scrivici in chat oppure scrivi a <b>fatture@revoo-app.com</b>",
    fatture: "Fatture",
    femmina: "Femmina",
    fianchi: "Fianchi",
    file: "File",
    file_caricato: "File caricato",
    filtra_per_tag: "Filtra per tag",
    filtra: "Filtra",
    fisica: "Fisica",
    fisico: "Di persona",
    for_you: "Per Te",
    formato_del_numero_di_telefono_non_corretto: "Formato del numero di telefono non corretto",
    foto_progressi: "Foto progressi",
    fratture: "Fratture",
    frequenza_settimanale_di_allenamento:
      "Frequenza settimanale di allenamento",
    front_view: "Frontale",
    frontale: "Frontale",
    funzione_premium: "Funzione premium, contattaci in chat",
    g: "g",
    gamba_destra: "Gamba Destra",
    gamba_sinistra: "Gamba Sinistra",
    generale: "Generale",
    genera_link: "Genera link",
    gestione_gruppi: "Gestione Gruppi",
    giorni_: "Giorni",
    giorni: "{giorni, plural, =1 {# giorno} other {# giorni}}",
    giorni_label: "{giorni_label, plural, =1 {giorno} other {giorni}}",
    grassi: "Grassi",
    grasso_viscerale: "Grasso Viscerale",
    gruppi: "Gruppi",
    gruppi_aggiunti: "Gruppi aggiunti",
    gruppo_associato_eliminato: "Gruppo eliminato",
    gruppo_sanguigno: "Gruppo sanguigno",
    gruppo: "Gruppo",
    guida: "Guida",
    hai_un_account: "Hai un account?",
    help: "Guida",
    i_gruppi_ti_permettono_di_assegnare:
      "I gruppi ti permettono di assegnare gli stessi workout a più clienti contemporaneamente!",
    i_tuoi_esercizi_sono_privati:
      "I tuoi esercizi sono privati. Nessun altro Personal Trainer potrà vederli.",
    i_tuoi_video_sono_privati:
      "I tuoi Video sono privati. Nessun altro Personal Trainer potrà vederli.",
    il_codice_fiscale_non_è_valido: "Il codice fiscale non è valido",
    il_codice_inserito_non_è_valido: "Il codice inserito non è valido",
    il_codice_univoco_deve_avere_7_numeri:
      "Il codice univoco deve avere 7 numeri",
    il_cognome_deve_essere_di_almeno_3_caratteri:
      "Il cognome deve essere di almeno 3 caratteri",
    il_cognome_é_obbligatorio: "Il cognome è obbligatorio",
    il_link_inserito_non_è_valido: "Il link inserito non è valido",
    il_link_inserito_supera_i_caratteri_consentiti:
      "Il link inserito supera il limite di caratteri consentiti",
    il_nome_deve_essere_di_almeno_3_caratteri:
      "Il nome deve essere di almeno 3 caratteri",
    il_nome_é_obbligatorio: "Il nome è obbligatorio",
    il_periodo_di_prova_termina: "Il periodo di prova termina in data",
    il_piano_si_rinnova: "Il piano si rinnova automaticamente in data",
    il_prodotto_e_in_revisione: "Il prodotto è in revisione",
    il_prodotto_sara_inviato_in_revisione:
      "Il prodotto sarà inviato in revisione",
    il_prodotto_e_stato_approvato: "Il prodotto è stato approvato",
    il_prodotto_include: "Il prodotto include:",
    il_peso_inserito_non_è_valido: "Il peso inserito non è valido",
    il_tuo_abbonamento: "Il tuo abbonamento",
    il_seguente_piano_sara_attivato: "Il seguente piano sarà attivato sul tuo account",
    il_seguente_piano_sara_attivato_pricing_change: "Il seguente piano sarà attivato il {activation_date} sul tuo account",
    il_tuo_brand_commerciale: "Il tuo brand commerciale",
    il_tuo_brand_commerciale: "Il tuo brand commerciale",
    il_valore_inserito_non_è_valido: "Il valore inserito non è valido",
    immagine_quadrata: "L'immagine deve essere quadrata",
    importa_da_atleta: "Importa da cliente",
    importa_da_modello: "Importa da modello",
    importo_dovuto: "Importo dovuto",
    impostazioni_profilo: "Impostazioni profilo",
    impostazioni: "Impostazioni",
    in_alternativa_al_codice_fiscale: "(in alternativa al codice fiscale)",
    in_casa_con_poca_attrezzatura: "In casa con poca attrezzatura",
    in_data: "In data",
    in_elaborazione: "In elaborazione",
    in_palestra: "In palestra",
    in_revisione: "In revisione",
    inattivi: "Inattivi",
    inattivo: "Inattivo",
    indietro: "Indietro",
    indirizzo_completo: "Indirizzo completo",
    indirizzo_di_fatturazione: "Indirizzo di fatturazione",
    indirizzo_non_completo: "L'indirizzo non è completo",
    indirizzo_non_è_corretto: "L'indirizzo non è corretto",
    info_abbonamento: "Info Abbonamento",
    info_piano: "Info piano",
    info_prodotto: "Info Prodotto",
    inglese: "inglese",
    inizia_a_lavorare: "Inizia a lavorare!",
    inizia_la_ricerca: "Inizia la ricerca",
    inizia_la_ricerca: "Inizia la ricerca",
    inizia_ricerca: "Inizia la ricerca",
    inizio: "Inizio",
    inserire_almeno_un_immagine: "Inserire almeno un'immagine",
    inserisci_email_registrazione:
      "Inserisci l'email con cui ti sei registrato per recuperare la password.",
    inserisci: "Inserisci",
    inserisci_un_titolo_valido: "Inserisci un titolo valido",
    inserisci_un_contenuto_valido: "Inserisci un contenuto valido",
    intermedio: "Intermedio",
    intestatario: "Intestatario",
    intolleranze: "Intolleranze",
    invia_email: "Invia email",
    invia_notifica_al_cliente: "Invia notifica al cliente",
    invia_notifica_ai_clienti: "Invia notifica ai clienti",
    invia_notifica_al_gruppo: "Invia notifica al gruppo",
    invia: "Invia",
    invia_email_invitati: "Notifica agli invitati",
    inviata_il: "Inviata il",
    invio_recupero_password:
      "Ti abbiamo inviato una email per recuperare la password.",
    invita_amico_sconto_amico:
      "Il tuo amico ha diritto a {invitato_value} % di sconto per {invitato_month} mesi.",
    invita_amico_sconto_pt:
      "Tu hai diritto a {invitante_value} % di guadagno per {invitante_month} mesi sull’ammontare che il tuo amico spende su REVOO.",
    invita_amico_su_revoo: "Porta un amico in REVOO",
    invita_amico: "Se porti un amico in REVOO con il tuo referral-code:",
    invita_cliente: "Invita cliente",
    invita_clienti: "Invita clienti",
    invita_nuovi_clienti_al_gruppo: "Invita nuovi clienti al gruppo",
    invita_nuovi_clienti: "Invita nuovi clienti",
    invita_un_amico: "Invita un amico",
    invita: "Invita",
    irregolarita_intestinale: "Irregolarità intestinale",
    italiano: "italiano",
    iva_inclusa: "iva inclusa",
    iva: "IVA",
    kcal: "kcal",
    kg: "kg",
    l_email_è_obbligatoria: "L'email è obbligatoria",
    la_città_é_obbligatoria: "La città è obbligatoria",
    la_città_inserita_non_è_valida: "la città inserita non è valida",
    la_dimensione_massima_consentita_è_di:
      "La dimensione massima consentita è di {dimension} MB",
    la_password_non_rispetta_i_criteri: "La password non rispetta i criteri",
    la_pec_deve_essere_valida: "La PEC deve essere un indirizzo PEC valido",
    laterale_destro: "Laterale destro",
    laterale_sinistro: "Laterale sinistro",
    left_view: "Laterale sinistro",
    letargico: "Letargico",
    lingua: "Lingua",
    link_a_video_youtube: "Link a video Youtube, Vimeo o Dropbox",
    link_cartella_condivisa: "Link cartella condivisa",
    link_della_videochiamata: "Link della videochiamata",
    link_post_acquisto: "Link Post-Acquisto",
    link_trainer: "Link Trainer",
    link_clienti: "Link Clienti",
    livello: "Livello",
    login: "Login",
    logo_business: "Logo business",
    logout: "Logout",
    luogo: "Luogo",
    luogo_di_allenamento: "Luogo di allenamento",
    m: "m",
    maggiori_info: "Maggiori Info",
    mai_allenato: "Mai allenato",
    maschio: "Maschio",
    massa_grassa: "Massa Grassa",
    massa_magra: "Massa Muscolare Kg",
    massa_ossea: "Massa Ossea Kg",
    max: "Max",
    membri_del_gruppo: "Membri del gruppo",
    membri: "Membri",
    mensile: "Mensile",
    mese: "Mese",
    mesi_: "Mesi",
    mesi: "{mesi, plural, =1 {# mese} other {# mesi}}",
    messaggio_di_ringraziamento: "Messaggio di ringraziamento",
    metri: "Metri",
    metodo_di_pagamento: "Metodo di pagamento",
    minuti: "{minuti, plural, =1 {minuto} other {minuti}}",
    minutes: "Minuti",
    minuti_abbreviato: "min",
    misure: "Misure",
    mm: "mm",
    modelli: "Modelli",
    modifica_appuntamento: "Modifica appuntamento",
    modifica_allegato: "Modifica allegato",
    modifica_dettagli_di_pagamento: "Modifica dettagli di pagamento",
    modifica_membri: "Modifica membri",
    modifica_password: "Modifica Password",
    modifica_plicometria: "Edit plicometry",
    modifica_plicometria: "Modifica plicometria",
    modifica_promemoria: "Modifica promemoria",
    modifica_rilevazione: "Modifica rilevazione",
    modifica_carta: "Modifica Carta",
    modifica: "Modifica",
    modulo_progressi: "Modulo Progressi",
    molto_attivo: "Molto attivo",
    mostra_altre: "Mostra altre",
    mostra_altro: "Mostra altro",
    mostra_numero_di_telefono_ai_clienti:
      "Mostra numero di telefono ai clienti",
    nascondi_lista_esercizi: "Nascondi lista esercizi",
    nd: "N.D.",
    nessun_abbonamento: "Nessun Abbonamento",
    nessun_allegato: "Nessun Allegato",
    nessun_allenamento_assegnato_al_gruppo:
      "Nessun alenamento assegnato al gruppo",
    nessun_allenamento_assegnato: "Nessun allenamento assegnato",
    nessun_allenamento: "Nessun allenamento",
    nessun_cliente_trovato: "Nessun cliente trovato",
    nessun_cliente_assegnato_al_gruppo: "Nessun cliente assegnato al gruppo",
    nessun_cliente: "Nessun cliente",
    nessun_esercizio_trovato: "Nessun esercizio trovato",
    nessun_esercizio: "Nessun esercizio",
    nessun_gruppo_a_cui_assegnare_un_piano:
      "Nessun gruppo a cui assegnare un piano",
    nessun_gruppo: "Nessun gruppo",
    nessun_piano_assegnato_ai_gruppi: "Nessun piano assegnato ai tuoi gruppi",
    nessun_piano_di_allenamento_trovato: "Nessun piano di allenamento trovato",
    nessun_piano: "Nessun piano",
    nessun_prodotto: "Nessun Prodotto",
    nessun_video_assegnato: "Nessun video assegnato.",
    nessun_video_da_assegnare:
      "Nessun video da assegnare. Carica un video e assegnalo ai tuoi clienti.",
    nessun_video_trovato: "Nessun video trovato",
    nessun_video: "Nessun Video",
    nessuna_anamnesi: "Nessuna anamnesi",
    nessuna_nota: "Nessuna nota",
    nessuna_foto_da_mostrare: "Nessuna foto da mostrare",
    nessuna_playlist_trovata: "Nessuna playlist trovata",
    nessuna_playlist: "Nessuna Playlist",
    nessuna_progressione: "Nessuna progressione",
    nessuna_scadenza: "Nessuna scadenza",
    no_credit_card_required: "No credit card required",
    nome_abbonamento: "Nome abbonamento",
    nome_allegato: "Nome allegato",
    nome_atleta: "Nome cliente",
    nome_esercizio: "Nome esercizio",
    nome_gruppo: "Nome gruppo",
    nome_modello: "Nome modello",
    nome_o_ragione_sociale: "Nome completo o Ragione Sociale",
    nome_piano: "Nome piano",
    nome_prodotto: "Nome Prodotto",
    nome_scheda: "Nome scheda",
    nome_titolare_tessera: "Nome del titolare della tessera",
    nome_video: "Nome Video",
    nome: "Nome",
    non_approvato: "Non approvato",
    non_ci_sono_allegati_al_piano: "Non ci sono file allegati al piano",
    non_ci_sono_allenamenti_eseguiti_da_consultare:
      "Non ci sono allenamenti eseguiti da consultare",
    non_ci_sono_dati_presenti:
      "Non sono presenti dati per il periodo di tempo selezionato",
    non_ci_sono_fatture_da_mostrare: "Non ci sono fatture da mostrare",
    non_ci_sono_piani_di_allenamento_in_scadenza:
      "Non ci sono Piani di Allenamento in scadenza",
    non_ci_sono_piani_di_allenamento_scaduti:
      "Non ci sono Piani di Allenamento scaduti",
    non_ci_sono_video_on_demand_in_scadenza:
      "Non ci sono Video on Demand in scadenza",
    non_ci_sono_video_on_demand_scaduti: "Non ci sono Video on Demand scaduti",
    non_ci_sono_abbonamenti_in_scadenza: "Non ci sono Abbonamenti in scadenza",
    non_ci_sono_abbonamenti_scaduti: "Non ci sono Abbonamenti scaduti",
    non_confermata: "non confermata",
    non_hai_codici_sconto_attivi: "Non hai codici sconto attivi",
    non_hai_un_account: "Non hai un account?",
    non_puoi_eliminare_un_prodotto_in_revisione:
      "Non puoi eliminare un prodotto mentre è in revisione",
    non_puoi_modificare_un_prodotto_in_revisione:
      "Non puoi modificare un prodotto mentre è in revisione",
    non_puoi_aggiungere_un_pt_o_te_stesso:
      "Non puoi aggiungere un pt o te stesso come cliente",
    non_puoi_disattivare_in_automatico_se_abbonamento_scaduto:
      "Non puoi effettuare questa operazione se l'abbonamento è già scaduto",
    non_puoi_inserire_più_di_2000_caratteri:
      "Non puoi inserire più di 2000 caratteri",
    non_so: "Non so",
    non_sono_stati_inseriti_i_seguenti_dati:
      "Non sono stati inseriti i seguenti dati",
    not_a_pt:
      "Per usare REVOO clicca <a href='https://www.revoo-app.com/scarica-app/' target='_blank'>qui</a> per scaricare la app",
    nota: "Nota",
    note_generali: "Note generali",
    note: "Note",
    note_abbonamento: "Note abbonamento",
    notifica: "Notifica",
    notifica_inviata: "Notifica inviata",
    notifica_in_corso: "Notifica in corso",
    notifiche: "Notifiche",
    notifiche_push_beta:
      "Le notifiche push sull'app mobile sono attualmente in versione beta.",
    notifiche_attivare_disattivare:
      "Puoi attivare o disattivare le singole notifiche:",
    numero_atleti: "N° clienti",
    numero_cellulare: "Numero cellulare",
    numero_video: "Numero Video",
    numero: "Numero",
    nuova_anamnesi: "Nuova anamnesi",
    nuova_password: "Nuova password",
    nuova_playlist: "Nuova Playlist",
    nuova_plicometria: "Nuova plicometria",
    nuova_progressione: "Nuova progressione",
    nuova_rilevazione: "Nuova rilevazione",
    nuova_nota: "Nuova nota",
    nuove_foto_progressi: "Nuove foto progressi",
    nuove_foto: "Nuove foto",
    nuove_misure: "Nuove misure",
    nuovo: "Nuovo",
    nuovo_abbonamento: "Nuovo abbonamento",
    nuovo_appuntamento: "Nuovo appuntamento",
    nuovo_cliente: "Nuovo cliente",
    nuovo_esercizio: "Nuovo esercizio",
    nuovo_gruppo: "Nuovo gruppo",
    nuovo_piano_di_allenamento: "Nuovo piano di allenamento (bozza)",
    nuovo_piano: "Nuovo piano",
    nuovo_prodotto: "Nuovo prodotto",
    nuovo_promemoria: "Nuovo promemoria",
    nuovo_rilevamento_composizione: "Nuovo rilevamento composizione",
    nuovo_rilevamento_misure: "Nuovo rilevamento misure",
    nuovo_video: "Nuovo Video",
    o_trascina: "o trascina",
    obbligatorio: "Obbligatorio",
    obiettivi: "Obiettivi",
    obiettivo: "Obiettivo",
    oggi_fromNow: "oggi",
    oggi: "Oggi",
    ok_capito: "Ok, ho capito",
    ogni: "ogni",
    onsite: "On-site",
    operazioni_chirurgiche: "Operazioni chirurgiche",
    ora: "Ora",
    ore: "{ore, plural, =1 {ora} other {ore}}",
    p_iva_gia_compilata:
      "Hai già inserito la P.IVA. Non puoi inserire entrambi i campi.",
    p_iva_non_valida: "P.IVA non valida",
    "p.iva": "P. IVA",
    paese: "Paese",
    pagato: "Pagato",
    palestra_casalinga: "Palestra casalinga",
    palestra: "Palestra",
    parametri_modificabili_da_sezione_schede:
      "I parametri della Progressione 'Settimana 1' sono modificabili dalla sezione 'Schede'",
    passaparola: "Passaparola",
    passi: "Passi",
    password_modificata_con_successo: "Password modificata con successo",
    password_non_coincidono: "Le due password non coincidono",
    password_non_corretta: "Password non corretta",
    password: "Password",
    patologie_diagnosticate: "Patologie diagnosticate",
    pec: "PEC",
    pensiamo_a_tutto:
      "Pensiamo a tutto noi, ai clienti invitati arriverà il link tramite email.",
    per_lato: "Per lato",
    perdita_di_grasso: "Perdita di grasso",
    periodo_prova_scade_oggi: "Il periodo di prova termina oggi. Per continuare aggiorna i dati",
    periodo_prova_scade_oggi_new_pricing: "Il periodo di prova termina oggi. Scegli un piano per continuare.",
    periodo_prova_scade: "Il periodo di prova termina tra {days} giorni.",
    periodo_prova_scaduto_mobile: "Il periodo di prova è terminato! Per continuare accedi a REVOO dal browser e aggiorna i dati.",
    periodo_prova_scaduto_mobile_new_pricing: "Il periodo di prova è terminato! Scegli un piano per continuare.",
    periodo_prova_scaduto: "Il periodo di prova è terminato! Per continuare aggiorna i dati.",
    periodo_prova_scaduto_new_pricing: "Il periodo di prova è terminato! Scegli un piano per continuare.",
    personal_trainers: "Personal Trainers",
    peso_attuale: "Peso attuale",
    peso: "Peso",
    petto: "Petto",
    pettorale: "Pettorale",
    piani_di_allenamento_in_scadenza: "Piani di allenamento in scadenza",
    piani_di_allenamento: "Modelli",
    piani_allenamento: "Piani di allenamento",
    piani: "Piani",
    piano_di_allenamento: "Piano di allenamento",
    piano: "Piano",
    pillola_contraccettiva: "Pillola contraccettiva",
    plan_conflict_exception: "Parametri incompatibili con il piano corrente. Aggiornare il numero di clienti o di video on demand.",
    playlist: "Playlist",
    plicometria: "Plicometria",
    poco_attivi: "Poco attivi",
    poco_attivo: "Poco attivo",
    polpaccio_destro: "Polpaccio Destro",
    polpaccio_dx: "Polpaccio dx",
    polpaccio_sinistro: "Polpaccio Sinistro",
    polpaccio_sx: "Polpaccio sx",
    popup_da_abbonamento_vecchio_a_nuovo: "Scegli un nuovo piano",
    popup_da_abbonamento_vecchio_a_nuovo_update: "Aggiorna il nuovo piano",
    popup_da_abbonamento_vecchio_a_nuovo_sottotitolo: "Aggiorna il tuo abbonamento scegliendo un nuovo piano, che si attiverà al termine del periodo di fatturazione corrente.",
    popup_da_abbonamento_vecchio_a_nuovo_sottotitolo_update: "Hai già richiesto il passaggio ad un nuovo piano, che avverrà in data {endsAt}. Se vuoi cambiare il piano scelto, scegli tra le seguenti opzioni.",
    popup_cambio_abbonamento_titolo: "Cambia Abbonamento",
    popup_cambio_abbonamento_sottotitolo: "Scegli un nuovo piano mensile o annuale.",
    popup_cambio_abbonamento_sottotitolo_trigger_customers: "Hai raggiunto il massimo numero di clienti. Effettua l'upgrade per aggiungerne altri.",
    popup_cambio_abbonamento_sottotitolo_trigger_vods: "Hai raggiunto il massimo numero di video. Effettua l'upgrade per aggiungerne altri.",
    popup_cambio_abbonamento_sottotitolo_trigger_no_vods: "I video on demand non sono inclusi nel tuo piano. Effettua l'upgrade per utilizzarli.",
    popup_cambio_abbonamento_sottotitolo_trigger_revoo_pay: "Per acquistare ed utilizzare il servizio REVOO PAY effettua l'upgrade del tuo abbonamento.",
    popup_cambio_abbonamento_sottotitolo_trigger_custom_app: "Per creare la tua app personalizzata effettua l'upgrade del tuo abbonamento.",
    popup_scelta_piano_trial_in_corso: "Scegli il piano che sarà attivato alla fine del periodo di prova",
    popup_trial_abbonamento_attivo: "Abbonamento attivo",
    popup_trial_abbonamento_aggiornato: "Abbonamento aggiornato",
    popup_trial_ciao: "Ciao <strong>{firstName}</strong>!",
    popup_trial_complimenti_abbonamento_attivo:
      "Complimenti <strong>{firstName}</strong>, il tuo abbonamento è attivo!",
    popup_trial_complimenti_piano_aggiornato:
      "Complimenti <strong>{firstName}</strong>, il tuo piano è stato aggiornato come richiesto!",
    popup_trial_grazie: "Grazie per aver scelto REVOO.",
    popup_trial_grazie_per_la_fiducia: "Grazie per la fiducia in noi.",
    popup_trial_iva_inclusa: "IVA inclusa se dovuta",
    popup_trial_over_ultime_novita:
      "Il periodo di prova è terminato, vuoi provare le ultime novità?",
    popup_trial_over_ricevi_altri_giorni_di_prova:
      "Clicca qui sotto per ricevere altri 10 giorni di prova gratuita.",
    popup_trial_over_prova_revoo: "Prova REVOO ora",
    popup_trial_in_corso_scegli_il_piano:
      "Scegli uno dei seguenti Piani per il tuo abbonamento a REVOO. Continuerai ad usufruire del periodo di prova gratuita fino al {endsAt}.",
    popup_trial_per_attivare:
      "Il tuo periodo di prova è terminato, scegli uno dei seguenti Piani per attivare il tuo abbonamento a REVOO.",
    popup_trial_periodo_di_prova_scaduto: "Il periodo di prova è scaduto",
    popup_trial_quanto_costa_al_mese:
      "Al mese per ogni cliente attivo, fino a 24 clienti.",
    popup_trial_quanto_costa_flat:
      "Prezzo fisso al mese oltre 25 clienti attivi.",
    popup_trial_quanto_costa: "Quanto costa REVOO?",
    porta_un_amico_trainer:
      "Porta un amico trainer in REVOO e risparmia sul tuo abbonamento. Ecco qui il tuo referral-code.",
    porta_un_amico: "Porta un amico",
    potremmo_utilizzare_questo_numero:
      "Potremmo utilizzare questo numero di telefono per contattarti in merito a eventi di sicurezza o supporto",
    prefisso: "Prefisso",
    preparazione_atletica: "Preparazione atletica",
    pressione_arteriosa: "Pressione arteriosa",
    prezzo_: "Prezzo",
    prezzo_barrato: "Prezzo barrato",
    prezzo_barrato_tooltip:
      "Se decidi di offrire il prodotto ad un prezzo scontato puoi inserire qui il suo prezzo originale; verrà mostrato nell’app “barrato” per aumentare le conversioni. Attenzione, questo non è il prezzo finale di vendita!",
    prezzo_finale: "Prezzo finale",
    prezzo_unitario: "PREZZO UNITARIO",
    prezzo: "IMPORTO",
    principiante: "Principiante",
    problemi_cardiaci: "Problemi cardiaci",
    problemi_posturali: "Problemi posturali",
    problemi_tiroide: "Problemi tiroidei",
    prodotti: "Prodotti",
    prodotto: "Prodotto",
    prodotto_acquistabile_separatamente: "Prodotto non incluso, acquistabile separatamente",
    professione: "Professione",
    profilo_revoo: "Profilo REVOO",
    profilo: "Profilo",
    progressi_check: "Progressi e check",
    progressioni: "Progressioni",
    promemoria: "Promemoria",
    prosegui: "Prosegui",
    prosegui_senza_salvare: "Prosegui senza salvare",
    prossimi_n_giorni: "Prossimi {days} giorni",
    prossimo_addebito: "Prossimo addebito",
    proteine: "Proteine",
    protocollo_donna_3_pliche: "Protocollo a 3 pliche (donna)",
    protocollo_donna_7_pliche: "Protocollo a 7 pliche (donna)",
    protocollo_uomo_3_pliche: "Protocollo a 3 pliche (uomo)",
    protocollo_uomo_7_pliche: "Protocollo a 7 pliche (uomo)",
    provincia: "Provincia",
    prozis: "Prozis",
    punto_vita: "Punto vita",
    quantità: "QUANTITÀ",
    queste_informazioni_non_saranno_visibili_ai_tuoi_clienti:
      "Queste informazioni non saranno visibili ai tuoi clienti",
    questo_è_il_credito:
      "Questo è il credito guadagnato tramite codice sconto o referral code. Ti sarà stornato dalla prossima fattura",
    qui_puoi_inserire_link_video:
      "Qui puoi inserire il link a video YouTube, Vimeo e Dropbox. <a href='https://how.revoo-app.com/knowledge/quali-sono-i-file-video-che-posso-inserire-nel-piano-di-allenamento' target='_blank'>Scopri come fare</a>. <br/> Attenzione! Se inserisci il link del video esterno, nasconderai il video attuale.",
    rank: "Rank",
    rear_view: "Dorsale",
    recupera_password: "Recupera password",
    recupera_password: "Recupera password",
    referral_code_applicato: "Codice applicato correttamente",
    referral_code_errato: "Codice errato",
    referral_code_invalido: "Codice non valido",
    referral_code_non_esistente: "Codice inesistente",
    referral_code_personale: "Referral-code Personale",
    registrati_su_revoo: "Registrati su REVOO per gestire i tuoi clienti",
    registrati: "Registrati",
    registrazione_non_riuscita: "Registrazione non riuscita",
    regola_lo_zoom: "Regola lo zoom",
    reinvia: "Reinvia",
    reinvia_password: "Reinvia Password",
    remoto: "Remoto",
    rendi_visibile_al_cliente: "Rendi visibile al cliente",
    report: "Report",
    reps: "Reps",
    reset_password: "Reset Password",
    rest: "Rest",
    riabilitazione: "Riabilitazione",
    ricevute: "Ricevute",
    richiedi_webinar: "Richiedi Webinar",
    ricordami: "Ricordami",
    rigenera_codice_invito: "Rigenera codice invito",
    right_view: "Laterale destro",
    rimuovi_dal_gruppo_programmaticamente: "Automatizza la rimozione dei clienti dal gruppo",
    rimuovi_dal_gruppo: "Rimuovi dal gruppo",
    rimuovi_set: "Rimuovi set",
    rimuovi: "Rimuovi",
    rinnovo_abbonamento: "Rinnovo abbonamento",
    rinomina_gruppo: "Rinomina gruppo",
    rinomina_playlist: "Rinomina Playlist",
    ripeti_nuova_password: "Ripeti nuova password",
    riproduci: "Riproduci",
    rm: "RM",
    rpe: "RPE",
    ruota_schermo: "Ruota lo schermo per continuare",
    saldo_applicato: "Credito residuo applicato",
    salva_automazione: "Salva automazione",
    salva_automazioni: "Salva automazioni",
    salva_come_bozza: "Salva come bozza",
    salva_come_nuovo: "Salva come nuovo",
    salva_e_inizia: "Salva e inizia",
    salva_e_invia: "Salva e invia",
    salva_e_invita_cliente: "Salva e invita cliente",
    salva_ed_esci: "Salva ed esci",
    salva_e_prosegui: "Salva e prosegui",
    salva_modifiche: "Salva modifiche",
    salva: "Salva",
    salva_carta: "Salva Carta",
    scadenza: "Scadenza",
    scadenze: "Scadenze",
    scaduti_n_giorni: "Scaduti da {days} giorni",
    scaduto: "Scaduto",
    scarica_ricevuta: "Scarica ricevuta",
    scegli_clienti: "Scegli clienti",
    scegli_file: "Scegli file",
    scegli_piano: "Scegli Piano",
    scegli_una_tipologia: "Scegli una tipologia",
    scegli_video: "Scegli Video",
    scegli_video: "Scegli video",
    scegli: "Scegli",
    schede_: "{schede, plural, =1 {# scheda} other {# schede}}",
    schede: "Schede",
    scopri_di_piu: "Scopri di più",
    scopri_i_nostri_servizi: "Scopri i nostri servizi",
    scopri_il_programma_referral:
      "Scopri il programma referral di REVOO: condividi il tuo referral-code con i tuoi amici trainer,<br/>loro si registrano a REVOO e tu guadagnerai dei crediti.",
    se_non_assegni_una_durata_playlist:
      "Se non inserisci una scadenza, la playlist sarà visibile per sempre. Vuoi proseguire?",
    se_non_assegni_una_durata_video:
      "Se non inserisci una scadenza, il video sarà visibile per sempre. Vuoi proseguire?",
    se_vuoi_aggiungere_un_cliente:
      "Se vuoi aggiungere un cliente di prova, inserisci una mail di fantasia, potrai eliminarla anche dopo.",
    seconds: "Secondi",
    sedentario: "Sedentario",
    sei_mesi: "Sei mesi",
    sei_settimane: "Sei settimane",
    sei_sicuro_di_voler_confermare_invio_email:
      "Sei sicuro di voler inviare la notifica agli invitati?",
    sei_sicuro_di_voler_disattivare:
      "Sei sicuro di voler disattivare il cliente? Non potrai effettuare nessuna azione su un cliente disattivato.",
    sei_sicuro_di_voler_eliminare_il_gruppo:
      "Sei sicuro di voler eliminare il gruppo?",
    sei_sicuro_di_voler_eliminare_il_piano:
      "Sei sicuro di voler eliminare il piano di allenamento?",
    sei_sicuro_di_voler_eliminare_il_video_assegnato:
      "Sei sicuro di voler eliminare il video assegnato?",
    sei_sicuro_di_voler_eliminare_il_video:
      "Sicuro di voler eliminare il video?",
    sei_sicuro_di_voler_eliminare_l_abbonamento:
      "Sei sicuro di voler eliminare l'abbonamento?",
    sei_sicuro_di_voler_eliminare_l_allegato:
      "Sei sicuro di voler eliminare l'allegato?",
    sei_sicuro_di_voler_eliminare_l_anamnesi:
      "Sei sicuro di voler eliminare l'anamnesi?",
    sei_sicuro_di_voler_eliminare_l_esercizio:
      "Sei sicuro di voler eliminare l'esercizio?",
    sei_sicuro_di_voler_eliminare_gli_esercizi:
      "Sei sicuro di voler eliminare gli esercizi?",
    sei_sicuro_di_voler_eliminare_l_evento:
      "Sei sicuro di voler eliminare questo evento dal calendario?",
    sei_sicuro_di_voler_eliminare_la_playlist_assegnata:
      "Sei sicuro di voler eliminare la playlist assegnata?",
    sei_sicuro_di_voler_eliminare_la_nota:
      "Sei sicuro di voler eliminare la nota?",
    sei_sicuro_di_voler_eliminare_la_playlist:
      "Sei sicuro di voler eliminare la playlist?",
    sei_sicuro_di_voler_modificare_il_piano_gruppi:
      "Sei sicuro di voler modificare il piano? (I clienti assegnati al gruppo potrebbero perdere i suggerimenti dell'ultimo allenamento eseguito)",
    sei_sicuro_di_voler_modificare_il_piano:
      "Sei sicuro di voler modificare il piano? (Il cliente potrebbe perdere i suggerimenti dell'ultimo allenamento eseguito)",
    sei_sicuro_di_voler_resettare_la_password_del_cliente:
      "Sei sicuro di voler resettare la password del cliente? (Il cliente riceverà un'email con la nuova password)",
    sei_sicuro_di_voler_rimuovere_l_atleta_dai_gruppi_no_question:
      "Sei sicuro di voler rimuovere il cliente dai gruppi",
    sei_sicuro_di_voler_rimuovere_l_atleta_dai_gruppi:
      "Sei sicuro di voler rimuovere il cliente dai gruppi?",
    sei_sicuro_di_voler_rimuovere_l_atleta_dal_gruppo_no_question:
      "Sei sicuro di voler rimuovere il cliente dal gruppo",
    sei_sicuro_di_voler_rimuovere_l_atleta_dal_gruppo:
      "Sei sicuro di voler rimuovere il cliente dal gruppo?",
    sei_sicuro_di_voler_uscire:
      "Sei sicuro di voler uscire? Le modifiche non salvate saranno perse.",
    sei_sicuro_di_voler_eliminare_la_composizione_del:
      "Sei sicuro di voler eliminare la composizione del {date}? (Azione irreversibile)",
    sei_sicuro_di_voler_eliminare_le_circonferenze_del:
      "Sei sicuro di voler eliminare le circonferenze del {date}? (Azione irreversibile)",
    "select-or-delete-exercise":
      "Inserisci un esercizio o elimina la combinazione",
    seleziona_foto: "Seleziona foto",
    seleziona_video: "Seleziona video",
    serve_fattura_elettronica:
      "Se sei tenuto a ricevere la fattura elettronica inserisci l'indirizzo PEC o il codice univoco SDI",
    sesso: "Sesso",
    set: "Set",
    sets: "Sets",
    settimana: "Settimana",
    settimane_: "Settimane",
    settimane_abbr: "{settimane_abbr, plural, =1 {# sett.} other {# sett.}}",
    settimane: "{settimane, plural, =1 {# settimana} other {# settimane}}",
    si_è_verificato_un_errore: "Si è verificato un errore",
    si_è_verificato_un_errore_scrivici_in_chat:
      "Si è verificato un errore. Scrivici in chat.",
    si: "Si",
    sicuro_di_voler_eliminare_il_cliente:
      "Sicuro di voler eliminare il cliente?",
    sigla_provincia_non_valida: "Sigla non valida, inserisci 2 caratteri",
    simbolo_c: "°C",
    singolo: "Singolo",
    solo_l_atleta_puo_modificare_l_email:
      "Solo il tuo cliente può modificare l'email tramite l'app",
    soprailiaca: "Soprailiaca",
    sostituisci: "Sostituisci",
    sottoscapolare: "Sottoscapolare",
    stai_andando_alla_sezione_info_piano:
      "Stai andando alla sezione Info Piano, le modifiche non salvate saranno perse.",
    stai_andando_alla_sezione_allegati:
      "Stai andando alla sezione Allegati, le modifiche non salvate saranno perse.",
    stampa: "Stampa",
    stato: "Stato",
    status: "Status",
    stile_di_vita: "Stile di vita",
    stima_prossimo_addebito: "Stima prossimo addebito",
    storico_ricevute: "Storico ricevute",
    subscription_dialog_final_step_pricing_change_message: "Congratulazioni {firstName}, il tuo nuovo abbonamento sarà attivo dal {activation_date}.",
    subscription_dialog_final_step_pricing_change_title: "Cambio piano richiesto",
    subscription_dialog_payment_step_title: "Riepilogo e conferma",
    subtotale: "Subtotale",
    suddiviso: "Suddiviso",
    superset: "Superset",
    tag: "Tag",
    tags: "Tags (max 3)",
    team: "Team",
    telefono: "Telefono",
    temperatura_corporea: "Temp. Corporea",
    time: "Tempo",
    tipo_di_cliente: "Tipo di cliente",
    tipo_di_pagamento: "Tipo di pagamento",
    tipologia: "Tipologia",
    titolo: "Titolo",
    torna_al_login: "Torna al login",
    torna_a_revoo: "Torna a REVOO",
    totale: "Totale",
    transazioni: "Transazioni",
    trascina_i_video_per_ordinarli: "Trascina i video per ordinarli",
    trascina_immagine_per_tagliare:
      "Trascina l'immagine per selezionare l'area da tagliare",
    tre_mesi: "Tre mesi",
    trial_scaduto_banner: "Il tuo periodo di prova è scaduto. Scegli un piano per continuare ad utilizzare REVOO.",
    tricipite: "Tricipite",
    trova_nuovi_clienti: "Trova nuovi clienti",
    tut: "TUT",
    tutte_le_date: "Tutte le date",
    tutte_le_schede: "Tutte le schede",
    tutti_i_tag: "Tutti i Tag",
    tutti_i_tuoi_clienti_sono_assegnati_al_gruppo:
      "Tutti i tuoi clienti sono assegnati a questo gruppo",
    tutti: "Tutti",
    ultima_rilevazione_pressione: "Ultima rilevazione pressione",
    ultima_settimana: "Ultima settimana",
    ultimi_3_mesi: "Ultimi 3 mesi",
    ultimi_30_giorni: "Ultimi 30 giorni",
    ultimi_6_mesi: "Ultimi 6 mesi",
    ultimo_allenamento: "Ultimo allenamento",
    ultimo_aggiornamento: "Ultimo aggiornamento",
    ultimo_anno: "Ultimo anno",
    ultimo_step_e_ci_siamo: "L'ultimo step e ci siamo!",
    ultimo: "ultimo",
    upgrade: "Upgrade",
    utilizzo_farmaci: "Utilizzo farmaci",
    utilizzo_integratori: "Utilizzo integratori",
    valida: "Valida",
    vecchia_password: "Vecchia password",
    vedi_dettagli: "Vedi dettagli",
    vedi_dettaglio: "Vedi dettaglio",
    vedi_di_piu: "Vedi di più",
    vedi_lista_esercizi: "Vedi lista esercizi",
    versione_classica: "Versione Classica",
    video_allenamenti: "Video on Demand",
    video_assegnati: "Video assegnati",
    video: "Video",
    videocall: "Videochiamata",
    views_totali: "Views totali",
    visualizza: "Visualizza",
    visualizza_la_libreria_di_esercizi_revoo:
      "Visualizza la libreria di esercizi REVOO",
    visualizza_la_libreria_di_esercizi_revoo_mobile: "Mostra esercizi REVOO",
    visualizzazione_semplificata_per_il_cliente:
      "Visualizzazione semplificata per il cliente",
    visualizzazione_semplificata: "Visualizzazione semplificata",
    visualizzazioni_mensili: "Visualizzazioni mensili",
    visualizzazioni_del_tuo_mese_di_abbonamento:
      "Visualizzazioni del tuo mese di abbonamento",
    visualizzazioni_totali: "Visual. totali",
    visualizzazioni: "Visualizzazioni",
    vita: "Vita",
    vod_disclaimer:
      "I Video on Demand hanno 50 visualizzazioni mensili gratuite. Superata questa soglia verrà automaticamente attivato un abbonamento dedicato. Clicca per maggiori informazioni.",
    volume: "Volume",
    vuoi_sapere_come_condividere_video_allenamenti:
      "Vuoi sapere come condividere Video on Demand con i tuoi clienti?",
    vuoi_sapere_come_creare_esercizi_personalizzati:
      "Vuoi sapere come creare esercizi personalizzati?",
    vuoi_sapere_come_creare_un_anamnesi: "Vuoi sapere come creare un'anamnesi?",
    vuoi_sapere_come_creare_un_piano_di_allenamento_per_il_tuo_cliente:
      "Vuoi sapere come creare un piano di allenamento per il tuo cliente?",
    vuoi_sapere_come_gestire_i_progressi:
      "Vuoi sapere come gestire i progressi dei tuoi clienti?",
    vuoi_sapere_come_si_crea_un_piano_di_allenamento:
      "Vuoi sapere come si crea un piano di allenamento e come si assegna ad un cliente?",
    vuoi_sapere_come_si_crea_una_scheda: "Vuoi sapere come si crea una scheda?",
  },
};
