<script>
  import { _ } from "../../../services/i18n";
  import GroupImageUploader from "./GroupImageUploader.svelte";
  import GroupLabel from "../../ReusableLayouts/GroupLabel.svelte";
  import { updateCustomerImage } from "../../../services/customerServices";
  import { group } from "../../../lib/store";

  export let profileImage = "";

  const handleImageUpdated = async (e) => {
    try {
      const res = await updateCustomerImage({
        customer_id: customerId,
        image: e.detail.image,
      });
      group.image = res.image;
    } catch (err) {
      console.log("err handleImageUpdated", err);
    }
  };
</script>

<style>
  .nameClass {
    font-weight: bold;
    display: inline;
    font-size: 25px;
  }
  * :global(.active) {
    color: #0cd2c8;
  }
  * :global(.smallIcon) {
    font-size: 1.125rem;
  }
  @media (min-width: 576px) {
    .nameClass {
      font-weight: bold;
      display: inline;
      font-size: 40px;
    }
  }
</style>

<div class="row no-gutters mb-3">
  <div class="col-3 col-md-2">
    <GroupImageUploader
      {profileImage}
      on:imageUpdated={handleImageUpdated} />
  </div>
  <div class="col-9 col-md-10">
    <div class="row no-gutters ml-1 h100 align-items-center">
      <div class="col-12 d-flex-column">
        <GroupLabel mx={false}/>
        <span class="nameClass">
          {$group.name}
        </span>
      </div>
    </div>
  </div>
</div>
