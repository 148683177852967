<script>
  import "./BillingOverview.scss";
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte"
  import { getBalanceReferral, getBillingInfo, getCustomerPortalUrl, getNextPayments, getPaymentsMethodsDefault, storeBillingInfo } from "../../../services/billingServices";
  import { getSubscription, getFeatureValue } from "../../../services/subscriptionServices";
  import { getToUpdateHubspotPt } from "../../../services/teamServices";
  import { subscription, subscriptionInfo } from "../../../lib/store";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Actions } from "@smui/card";
  import FattureInCloudForm from "./FattureInCloudForm.svelte";
  import FirstCard from "./BillingCards/FirstCard.svelte";
  import FourthCard from "./BillingCards/FourthCard.svelte";
  import Loading from "../../ReusableLayouts/Loading.svelte";
  import SecondCard from "./BillingCards/SecondCard.svelte";
  import SubscriptionDialog from "./SubscriptionDialog.svelte";
  import SubscriptionInfoCard from "./SubscriptionInfoCard.svelte";
  import ThirdCard from "./BillingCards/ThirdCard.svelte";

  const dispatch = createEventDispatcher();

  let balance = 0;
  let balanceReferral = {};
  let billingDetails = {
    address_city        : "",
    address_postal_code : "",
    address_province    : "",
    address_street      : "",
    certified_email     : "",
    country             : { name: "Italia", code: "IT" },
    ei_code             : "",
    name                : "",
    tax_code            : "",
    type                : "",
    vat_number          : "",
  };
  let billingInfoComplete           = true;
  let chargeDate                    = "";
  let countCustomerProgress         = 0;
  let countVodsProgress             = 0;
  let currentCustomers              = 0;
  let currentVods                   = 0;
  let endsAt                        = null;
  let loading                       = false;
  let maxCustomers                  = "";
  let maxVods                       = "";
  let nextCharge                    = 0;
  let nextPayment                   = {};
  let payMethodExpireMonth          = "";
  let payMethodExpireYear           = "";
  let payMethodLast4                = "";
  let payMethodUserName             = "";
  let referral_code                 = "XXXXXX";
  let searchParamsSub               = "";
  let setiPriceNextInvoiceDate      = null;
  let setiPriceId                   = null;
  let setiPriceInterval             = null;
  let setiPriceValue                = null;
  let setiProductId                 = null;
  let setiProductName               = null;
  let setiSetupIntentClientSecret   = null;
  let setiSetupIntentId             = null;
  let setiSourceType                = null;
  let setiState                     = null;
  let showSubscriptionDialog        = false;
  let subscriptionDialogState       = 'upgrade_plan';
  let updateSubscriptionButtonLabel = $_('cambia_abbonamento');
  let upgradeRequested              = false;

  $: if ($subscriptionInfo || $subscription) {
    prepareSubscriptionDialogState();
  };

  $: switch ($subscription.name) {
    case 'main':
        updateSubscriptionButtonLabel = $_('cambia_abbonamento');
        break
    case 'revoo':
      updateSubscriptionButtonLabel = $_('cambia_piano');
      if ($subscriptionInfo.default_trial_product === true) {
        updateSubscriptionButtonLabel = $_('scegli_piano');  
      }
      break;
    default: 
      updateSubscriptionButtonLabel = $_('cambia_abbonamento');
  }
  
  const prepareSubscriptionDialogState = () => {
    switch ($subscriptionInfo.name) {
      case 'revoo' : 
        // È attivo il trial con prodotto di default ("REVOO | Trial")?
        if ($subscriptionInfo.default_trial_product === true) {
          subscriptionDialogState = 
            $subscriptionInfo.status === 'trialing' 
              ? 'trialing' 
              : 'trial_ended';
        } 
        else {
          subscriptionDialogState =
            $subscriptionInfo.status === 'trialing' 
              ? 'trialing' 
              : 'upgrade_plan';
        }
        break;
      case 'main' : 
        subscriptionDialogState = 
          $subscription.ends_at === null 
            ? 'pricing_change' 
            : 'pricing_change_already_scheduled';        
        break;
      default : 
        subscriptionDialogState = 'upgrade_plan';
    }
  }

  // $: console.log('subscriptionDialogState', subscriptionDialogState)

  const goToPaymentMethod = () => {
    dispatch("setComponent", {
      component: "payment",
      props: {
        payMethodLast4,
        payMethodUserName,
        payMethodExpireYear,
        payMethodExpireMonth,
        billingDetails,
      },
    });
  };

  const goToNextPaymentDetails = async () => {
    dispatch("setComponent", {
      component: "nextCharge",
      props: { ...nextPayment },
    });
  };

  const goToReferralDetails = () => {
    dispatch("setComponent", {
      component: "referral",
      props: { ...balanceReferral },
    });
  };

  const nextPayments = async () => {
    try {
      nextPayment = await getNextPayments();
      nextCharge = (nextPayment.amount_due / 100).toFixed(2);
      chargeDate = nextPayment.end_date;
    } catch (err) {
      console.log("nextPayments error", err);
    }
  };

  const balanceReferrals = async () => {
    try {
      balanceReferral = await getBalanceReferral();
      balance = balanceReferral.balance / 100;
      balance = balance.toFixed(2);
      referral_code = balanceReferral.referral_code;
    } catch (err) {
      console.log("balanceReferrals error", err);
    }
  };

  const defaultMethods = async () => {
    try {
      const card = await getPaymentsMethodsDefault();
      payMethodUserName    = card.name      ? card.name      : "";
      payMethodLast4       = card.last4     ? card.last4     : "";
      payMethodExpireYear  = card.exp_year  ? card.exp_year  : "";
      payMethodExpireMonth = card.exp_month ? card.exp_month : "";
    } catch (err) {
      console.log("defaultMethods error", err);
    }
  };

  const updateBillingInfo = async () => {
    try {
      const res = await getBillingInfo();
      if (res) {
        billingDetails.address_city        = res.address_city;
        billingDetails.address_postal_code = res.address_postal_code;
        billingDetails.address_province    = res.address_province;
        billingDetails.address_street      = res.address_street;
        billingDetails.certified_email     = res.certified_email;
        billingDetails.country             = res.country;
        billingDetails.ei_code             = res.ei_code;
        billingDetails.name                = res.name;
        billingDetails.tax_code            = res.tax_code;
        billingDetails.type                = res.type;
        billingDetails.vat_number          = res.vat_number;
        billingInfoComplete = true;
      }
    } catch (error) {
      console.log("getBillingInfo err", error);
      billingInfoComplete = false;
    }
  };

  const handleSaveBillingInfo = async (e) => {
    try {
      const body = e.detail.body;
      const res = await storeBillingInfo(body);
      billingDetails.address_city        = res.address_city;
      billingDetails.address_postal_code = res.address_postal_code;
      billingDetails.address_province    = res.address_province;
      billingDetails.address_street      = res.address_street;
      billingDetails.certified_email     = res.certified_email;
      billingDetails.country             = res.country;
      billingDetails.ei_code             = res.ei_code;
      billingDetails.name                = res.name;
      billingDetails.tax_code            = res.tax_code;
      billingDetails.type                = res.type;
      billingDetails.vat_number          = res.vat_number;
      await getToUpdateHubspotPt();
      await getSubscription();
    } catch (error) {
      console.log("saveBillingInfo err", error);
      displayError();
    }
  };

  const handleStartCustomerPortal = async () => {
    try {
      const res = await getCustomerPortalUrl();
      if (res.success === true) {
        window.location.href = res.data.url;
      }
    } catch (err) {
      console.log('handleStartCustomerPortal Error =>', err);
    }
  }

  const handleCloseDialog = () => {
    showSubscriptionDialog = false;
  };

  const getProgressBarData = (feature = 'max_customers') => {
    switch (feature) {
      case 'max_customers' : 
        countCustomerProgress = Math.floor(currentCustomers * 100 / maxCustomers).toFixed(2);
        break;
      case 'max_vods' : 
        countVodsProgress = Math.floor(currentVods * 100 / maxVods).toFixed(2);
        break;
    }
  }

  const handleUpdateSubscription = () => {
    showSubscriptionDialog = true;
    // console.log('handleUpdateSubscription');
    // console.log('showSubscriptionDialog', showSubscriptionDialog);
    // console.log('subscription, subscriptionInfo', $subscription, $subscriptionInfo)
  }

  onMount(async () => {
    loading = true;
    await getToUpdateHubspotPt();
    await getSubscription();
    currentCustomers = $subscription.current_customers_count;
    maxCustomers = await getFeatureValue($subscription, 'max_customers');
    currentVods = $subscription.current_vods_count;
    maxVods = await getFeatureValue($subscription, 'max_vods');
    // console.log('currentVods', currentVods);
    // console.log('maxVods', maxVods);
    getProgressBarData();
    await nextPayments();
    await balanceReferrals();
    await defaultMethods();
    await updateBillingInfo();
    loading = false;

    // Handle SetupIntent to verify if is OK or failed
    setiSetupIntentId           = new URLSearchParams(location.search).get("setup_intent");
    setiSetupIntentClientSecret = new URLSearchParams(location.search).get("setup_intent_client_secret");
    setiSourceType              = new URLSearchParams(location.search).get("source_type");
    setiProductId               = new URLSearchParams(location.search).get("product_id");
    setiProductName             = new URLSearchParams(location.search).get("product_name");
    setiPriceId                 = new URLSearchParams(location.search).get("price_id");
    setiPriceInterval           = new URLSearchParams(location.search).get("price_interval");
    setiPriceValue              = new URLSearchParams(location.search).get("price_value");
    setiPriceNextInvoiceDate    = new URLSearchParams(location.search).get("price_next_invoice_date");
    setiState                   = new URLSearchParams(location.search).get("state");

    // console.log({
    //   'setiSetupIntentId'           : setiSetupIntentId,
    //   'setiSetupIntentClientSecret' : setiSetupIntentClientSecret,
    //   'setiSourceType'              : setiSourceType,
    //   'setiProductId'               : setiProductId,
    //   'setiProductName'             : setiProductName,
    //   'setiPriceId'                 : setiPriceId,
    //   'setiPriceInterval'           : setiPriceInterval,
    //   'setiPriceValue'              : setiPriceValue,
    //   'setiPriceNextInvoiceDate'    : setiPriceNextInvoiceDate,
    //   'setiState'                   : setiState,
    // })

    if (setiProductId && setiPriceId) {
      showSubscriptionDialog = true;
    }
    
    // Handle sub-tab for payment details
    searchParamsSub = new URLSearchParams(location.search).get("sub");
    if (searchParamsSub && searchParamsSub == "payment") {
      dispatch("setComponent", {
        component: "payment",
        props: {
          payMethodLast4,
          payMethodUserName,
          payMethodExpireYear,
          payMethodExpireMonth,
          billingDetails,
        },
      });
    }
  });

  $: if ($subscriptionInfo) {
    upgradeRequested = $subscriptionInfo.ends_at ? true : false;
    endsAt = $subscriptionInfo.ends_at;
  }

</script>

{#if loading}
  <Loading />
{:else}
  {#if showSubscriptionDialog}
    <SubscriptionDialog 
      state={subscriptionDialogState}
      trigger={""}
      {setiSetupIntentId}
      {setiSetupIntentClientSecret}
      {setiSourceType}
      {setiProductId}
      {setiProductName}
      {setiPriceId}
      {setiPriceInterval}
      {setiPriceValue}
      {setiPriceNextInvoiceDate}
      {setiState}
      on:closeDialog={handleCloseDialog} 
    />
  {/if}
  {#if $subscription && $subscription.name === 'revoo'}
    <!-- SUBSCRIPTION -->
    <div class="pt-4 mt-4 pb-3">
      <span class="importantInfoBold">{$_('il_tuo_abbonamento')}</span>
    </div>
    <Card class="customCard" variant="outlined" padded>
      <Content class="flexy">
        <SubscriptionInfoCard 
          {countCustomerProgress}
          {maxCustomers}
          {countVodsProgress}
          {maxVods}
          currentCustomers={$subscription.current_customers_count}
          currentVods={$subscription.current_vods_count}
          nextInvoiceDate={$subscription.next_invoice_date}
          priceInterval={$subscription.price_interval}
          priceValue={$subscription.price_value}
          productName={$subscription.subscription_items[0].name}
        />
      </Content>
      {#if $subscriptionInfo.status === 'unpaid' || $subscriptionInfo.status === 'past_due' || $subscriptionInfo.status === 'unsolved'}
        <div class="row">
          <div class="col-12">
            <span class="primary px-3 pb-4" style="cursor: pointer; font-weight: 600; color: var(--safety-orange);" on:click={handleStartCustomerPortal}>Problema nel pagamento, clicca qui per aggiornare i dati della tua carta.</span>
          </div>
        </div>
      {/if}
    </Card>
    <div class="d-flex justify-content-center justify-content-md-end mt-2">
      <Actions>
        {#if $subscriptionInfo.default_trial_product !== true}
          <Button class="btn mr-2" type="button" on:click={handleStartCustomerPortal}>
            <Label>{$_('dettagli')}</Label>
          </Button>
        {/if}
        <Button
        class="btn"
          variant="unelevated"
          type="submit"
          on:click={handleUpdateSubscription}
        >
          <Label>{updateSubscriptionButtonLabel}</Label>
        </Button>
      </Actions>
    </div>

    <!-- BILLING DATA -->
    <div class="pt-4 mt-4 pb-3">
      <span class="importantInfoBold">{$_('dettagli_di_fatturazione')}</span>
    </div>
    {#if billingDetails}
      <FattureInCloudForm
        {billingDetails}
        on:saveBillingInfo={handleSaveBillingInfo} 
      />
    {/if}
    <div class="mt-4">
      <hr/>
      <span>
        {@html $_('fatture_inviate_automaticamente_clean')}
      </span>
    </div>
  {:else}
    <div class="p-2">
      <span class="importantInfoBold">{$_('il_tuo_abbonamento')}</span>
    </div>
    <div class="row subscription-layout__old">
      <div class="col-xs-12 col-md-6 flexy mb-4 subscription-detail__old">
        <FirstCard {nextCharge} {chargeDate} on:click={goToNextPaymentDetails} />
      </div>
      <div class="col-xs-12 col-md-6 flexy mb-4 subscription-detail__old">
        <SecondCard {referral_code} {balance} on:click={goToReferralDetails} />
      </div>
      <div class="col-xs-12 col-md-6 flexy mb-4 subscription-detail__old">
        <ThirdCard />
      </div>
      <div class="col-xs-12 col-md-6 flexy mb-4 subscription-detail__old">
        <FourthCard
          {endsAt}
          {payMethodLast4}
          {payMethodUserName}
          {payMethodExpireMonth}
          {payMethodExpireYear}
          {upgradeRequested}
          bind:billingInfoComplete
          on:click={goToPaymentMethod} 
          on:startCustomerPortal={handleStartCustomerPortal}
          on:changeSubscription={handleUpdateSubscription}
        />
      </div>
    </div>
  {/if}
{/if}

<style>
  .importantInfoBold {
    font-weight: bold;
    font-size: 1.7em;
  }
  * :global(.subscription-detail__old .mdc-card) {
    border-radius: 10px;
    border: solid 1px;
    height: 100%;
    width: 430px;
  }
  
  * :global(.customCard) {
    margin: 0 auto;
    text-align: center;
    border: none;
  }
</style>