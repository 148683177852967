<script>
    import { _ } from "../../../services/i18n";
    import { onMount } from "svelte";
    import ExpiringSubscriptions from "./ExpiringSubscriptionsWrapper.svelte";
    import ExpiringTrainingCards from "./ExpiringTrainingCardsWrapper.svelte";
    import ExpiringVodsAndPlaylists from "./ExpiringVodsAndPlaylistsWrapper.svelte";
  
    export let location;
    export let onLocaleChanged;
    export let showNavbar;
  
    let msgError;
  
    onMount(async () => {
        
    });
  </script>
  
  <div class="d-flex justify-content-between flex-column flex-lg-row pt-4">
    <ExpiringTrainingCards />
    <ExpiringVodsAndPlaylists />
  </div>

  <div class="d-flex justify-content-between flex-column flex-lg-row pt-4">
    <ExpiringSubscriptions />
  </div>  