<script>
  import { _ } from "../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import { Content, Media } from "@smui/card";
  import LinearProgress from "@smui/linear-progress";
  import { Icon } from "@smui/common";
  import { addImage } from "../../../lib/utils";

  export let profileImage;

  const onInputChange = (e) => {
    const file = e.target.files[0];
    if (!file || file.size > 5000000) {
      dispatch("error", {});
      return;
    }
    dispatch("updateImageProfile", {
      img: file,
    });
    addImage(file, "profileImg");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    dispatch("updateImageProfile", {
      img: file,
    });
    addImage(file, "profileImg");
  };
</script>

<style>
  * :global(.customCard) {
    margin: 0 auto;
    text-align: center;
  }

  * :global(.mdc-card__media:before) {
    content: inherit;
  }

  * :global(.customUploader) {
    height: 200px !important;
    width: 200px !important;
    border-radius: 50%;
    overflow: hidden;
  }

  .customContentInMedia {
    position: absolute !important;
    flex-direction: column !important;
    justify-content: flex-end;
  }

  .customFileInput {
    left: 0px;
    z-index: 5;
    opacity: 0;
  }

  .imageWrapper {
    background-color: black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;
    overflow: hidden;
  }

  :global(.imageWrapper img) {
    height: 100%;
    width: auto;
    max-width: none;
  }

  * :global(.primary) {
    color: var(--primary) !important;
  }
</style>

<Content class="customCard uploaderCard">
  <Media
    class="customUploader card-media-square"
    aspectRatio="square"
    on:drop={handleDrop}>
    <input
      class="mdc-card__media card-media-square mdc-card__media--square
      customUploader customContentInMedia customFileInput"
      type="file"
      id="file"
      accept="image/*"
      on:change={onInputChange} />
    {#if !profileImage}
      <div
        class="mdc-card__media card-media-square mdc-card__media--square
        customUploader customContentInMedia d-flex"
        style=" background-image: url(/assets/placeholder_profile.png); ">
        <div class="row pt-2">
          <div class="col-12">
            <span>{$_('seleziona_foto')}</span>
            <br />
            <span>{$_('o_trascina')}</span>
            <br />
            <Icon
              role="button"
              tabindex="1"
              class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing pt-2 pb-3 primary">
              camera_alt
            </Icon>
          </div>
        </div>
      </div>
    {:else if typeof profileImage == 'string'}
      <img src={profileImage} style="width: auto; height: 100%; object-fit: cover;" alt="" />
    {:else}
      <div class="imageWrapper">
        <div
          style="max-width: 100%; height: 100%; overflow: hidden; margin: 0 auto;"
          id="output" />
      </div>
    {/if}
  </Media>
</Content>
