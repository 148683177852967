<script>
  import { _ } from "../../../../services/i18n";
  import { deleteNote } from "../../../../services/teamServices";
  import { createEventDispatcher, onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import BannerHelp from "../../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label, Icon } from "@smui/button";
  import Card, { Content } from "@smui/card";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import NotesList from "./NotesList.svelte";
  import NoItemsToShow from "../../../ReusableLayouts/NoItemsToShow.svelte";

  export let active = undefined;
  export let customerId = null;
  export let notes = [];
  export let loadingNotes = true;

  const dispatch = createEventDispatcher();

  let deleteDialog;
  let noteToBeDeleted;
  let msgError;

  const handleOpenDialog = (e) => {
    noteToBeDeleted = e.detail.id;
    deleteDialog.open();
  };

  const handleDeleteNote = async () => {
    try {
      const res = await deleteNote(noteToBeDeleted);
      deleteDialog.close();
      notes = notes.filter((note) => note.id !== noteToBeDeleted);
      dispatch("noteRemoved", { id: noteToBeDeleted });
      noteToBeDeleted = "";
    } catch (err) {
      console.log("delete note error", err);
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };
</script>

<style>
  * :global(.alignInlineCenter) {
    align-content: center;
    align-items: center;
    display: inline-flex;
  }

  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 14px;
    text-align: start;
  }
  .textToEnd {
    text-align: end;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
  * :global(.mdc-card) {
    text-align: center;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }
  .headline {
    font-size: 2.5rem;
  }
  .upperText {
    font-size: 1.25rem;
  }
  * :global(.icon--notes) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
  }
</style>

<Dialog bind:this={deleteDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_la_nota')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => {
      deleteDialog.close();
      noteToBeDeleted = '';
    }}
    on:onConfirmation={handleDeleteNote} />
</Dialog>

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

<div class="my-3">
  <BannerHelp state="notes" />
</div>

<div class="row mt-5 mb-2">
  <div class="col textToEnd">
    <Button
      disabled={!active}
      color="secondary"
      variant="unelevated"
      on:click={() => {
        navigate(`/new-note?customerId=${customerId}`);
      }}>
      <Label class="alignInlineCenter">
        <Icon
          role="button"
          class="icon--notes material-icons mdc-text-field__icon
            mdc-text-field__icon--trailing">
          note_add
        </Icon>
        {$_('nuova_nota')}
      </Label>
    </Button>
  </div>
</div>

<div class="container fluid">
  <Card class="invisibleCard">
    <Content style="padding-bottom: 0px;">
      <div class="row">
        <div class="col-4 mdc-typography--headline6 textTitle">
          {$_('data')}
        </div>
        <div class="col-6 mdc-typography--headline6 textTitle">
          {$_('titolo')}
        </div>
      </div>
      <div class="">
        <div class="col-2 mdc-typography--headline6 textTitle" />
      </div>
    </Content>
  </Card>
  <hr class="line" />
</div>

{#if loadingNotes}
  <Loading />
{:else if notes && notes.length > 0}
  <NotesList {notes} {customerId} on:openDialog={handleOpenDialog} />
{:else}
  <NoItemsToShow
    biggerText={$_('crea_la_prima_nota')}
    upperText={$_('nessuna_nota')}
    buttonText={$_('nuova_nota')}
    icon={'note_add'}
    pathToNavigateTo={`/new-note?customerId=${customerId}`} />
{/if}
