<script>
  import { _ } from "../../../services/i18n";
  import Card, { Content } from "@smui/card";
  import { Icon } from "@smui/common";
  import IconButton from "@smui/icon-button";
  export let invoices;
  export let headerItems;
</script>

<style>
  .textTitle {
    color: #212121;
    opacity: 0.5;
    font-size: 14px;
  }

  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }

  * :global(.mdc-card) {
    text-align: center;
  }

  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }
</style>

<div class="container fluid">
  <Card class="invisibleCard">
    <Content style="padding-bottom: 0px;">
      <div class="row">
        {#each headerItems as item}
          <div
            class={`col-${item.colSize} mdc-typography--headline6 textTitle`}>
            {item.value}
          </div>
        {/each}
      </div>
    </Content>
  </Card>
  <hr class="line" />
</div>
<div class="container fluid">
  {#each invoices as invoice, i}
    <Card class="mb-3">
      <Content>
        <div class="row">
          <div class={`col-${headerItems[0].colSize}`}>{i + 1}</div>
          {#each Object.values(invoice) as value, i}
            <div class={`col-${headerItems[i + 1].colSize}`}>{value}</div>
          {/each}
          <div class={`col-${headerItems[headerItems.length - 1].colSize}`}>
            <Icon role="button" tabindex="1" class="material-icons">
              cloud_download
            </Icon>
          </div>
        </div>
      </Content>
    </Card>
  {/each}
</div>
