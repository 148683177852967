<script>
  import { createEventDispatcher, onMount } from "svelte";
  import Checkbox from "@smui/checkbox";
  import { Icon } from "@smui/common";
  import Dialog from "@smui/dialog";
  import Textfield, { Input } from "@smui/textfield";
  import VirtualList from "@sveltejs/svelte-virtual-list";
  import EditUser from "./EditUser.svelte";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";
  import { disableCustomers } from "../../services/adminServices";
  import Pagination from "../ReusableLayouts/Pagination.svelte";

  const dispatch = createEventDispatcher();

  export let users;
  export let totalPages = 1;
  export let page = 1;

  let activePage = 0;
  $: activePage = page - 1;

  // let search = "";
  // let filteredUsers = [];
  // $: if (search == "") filteredUsers = users;
  // $: if (search.length > 1) {
  //   const match = search.toLowerCase().trim();
  //   filteredUsers = users.filter(
  //     (i) =>
  //       i.first_name.toLowerCase().trim().includes(match) ||
  //       i.last_name.toLowerCase().trim().includes(match)
  //   );
  // }

  let search = "";
  let typingTimer;
  let doneTypingInterval = 600;
  let filteredUsers = [
    {
      id: "999999",
      group: {
        group_id: "",
        group_name: "",
        group_default: "",
      },
    },
  ];
  $: if (users) filteredUsers = JSON.parse(JSON.stringify(users));

  let selected = [];
  let deleteUsersDialog;
  let newUserDialog;
  let userToBeEdited = {
    first_name: "",
    last_name: "",
    email: "",
    details: { phone: "" },
    is_pt: false,
    is_customer: false,
    is_admin: false,
    is_tester: false,
    is_alpha_tester: false,
    is_beta_tester: false,
    is_banned: false,
  };

  const openDialog = (user) => {
    userToBeEdited = { ...user };
    if (user.details && !user.details.phone) userToBeEdited.details.phone = "";
    newUserDialog.open();
  };

  const switchPage = async (event) => {
    //idex from 0
    page = event.detail.page + 1;
    dispatch("updateUsersPaging", { page });
  };

  const configSearch = () => {
    let searchInput = document.getElementById("searchInputUser");
    if (searchInput) {
      searchInput.addEventListener("keyup", () => {
        delaySearch();
      });
    }
  };

  const delaySearch = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      (_) => dispatch("updateUserSearch", { search }),
      doneTypingInterval
    );
  };

  const handleDisableCustomers = async (e) => {
    try {
      const body = {
        user_id: e.detail.user_id, 
        action: 'disable', 
      }
      await disableCustomers(body);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const handleUpdateUser = (e) => {
    dispatch("updateUser", { user: e.detail.user });
    userToBeEdited = {
      first_name: "",
      last_name: "",
      email: "",
      details: { phone: "" },
      is_pt: false,
      is_customer: false,
      is_admin: false,
      is_tester: false,
      is_alpha_tester: false,
      is_beta_tester: false,
      is_banned: false,
    };
    newUserDialog.close();
  };

  onMount(() => {
    configSearch();
  });
</script>

<style>
  * :global(.mdc-text-field) {
    height: 40px;
    margin-right: 0.8em;
  }
  * :global(.mdc-text-field__input) {
    height: auto;
    margin: auto;
    padding: 0 0.5em !important;
    width: 14em;
  }
  *
    :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
      .mdc-floating-label) {
    top: 12px;
  }
  * :global(.material-icons.searchIcon) {
    padding: 10px 0px 0px 4px !important;
  }
  * :global(.primary) {
    color: var(--primary);
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .clickable {
    cursor: pointer;
  }

  .usersTableContainer {
    height: 55vh;
    width: 100%;
  }

  .usersTableCell {
    overflow-wrap: break-word;
    font-size: 0.875rem;
    align-self: center;
  }
</style>

<Dialog
  bind:this={newUserDialog}
  aria-labelledby="dialog-for-new-deal"
  aria-describedby="new-deal">
  <EditUser
    user={userToBeEdited}
    on:disableCustomers={handleDisableCustomers}
    on:cancel={newUserDialog.close()}
    on:save={handleUpdateUser} />
</Dialog>

<Dialog
  bind:this={deleteUsersDialog}
  aria-labelledby="delete-confirmation-dialog"
  aria-describedby="delete-confirmation-dialog">
  <ConfirmationDialog
    text="Sei sicuro di voler eliminare questi utenti"
    confirmationButtonLabel="Conferma"
    on:closeDialog={() => deleteUsersDialog.close()}
    on:onConfirmation={() => {
      dispatch('deleteUsers', { users: selected });
      deleteUsersDialog.close();
      selected = [];
    }} />
</Dialog>

<div>
  <div class="row mx-0 py-4">
    <div class="col-12 header">
      <span class="mdc-typography--headline5">Utenti</span>
      {#if selected.length > 0}
        <Icon
          on:click={() => deleteUsersDialog.open()}
          class="material-icons primary clickable">
          delete
        </Icon>
        <Icon
          on:click={() => {
            dispatch('sendEmails', { users: selected });
            selected = [];
          }}
          class="material-icons clickable">
          email
        </Icon>
      {/if}
      <Textfield
        variant="outlined"
        withLeadingIcon
        id="searchInputUser"
        bind:value={search}
        label="Cerca per nome">
        <div>
          <Icon class="material-icons searchIcon">search</Icon>
        </div>
      </Textfield>
    </div>
  </div>
  {#if users.length > 0}
    <div class="usersTableContainer mb-3">
      <div class="row">
        <div class="col-1 mdc-data-table__header-cell" checkbox>
          <Checkbox />
        </div>
        <div class="col-1 mdc-data-table__header-cell">NOME</div>
        <div class="col-1 mdc-data-table__header-cell">COGNOME</div>
        <div class="col-2 mdc-data-table__header-cell">EMAIL</div>
        <div class="col-1 mdc-data-table__header-cell">TELEFONO</div>
        <div class="col-1 mdc-data-table__header-cell">IS PT</div>
        <div class="col-1 mdc-data-table__header-cell">IS CUSTOMER</div>
        <div class="col-1 mdc-data-table__header-cell">IS ADMIN</div>
        <div class="col-1 mdc-data-table__header-cell">IS BANNED</div>
        <div class="col-1 mdc-data-table__header-cell">IS TEST</div>
        <div class="col-1 mdc-data-table__header-cell">ACTIONS</div>
      </div>
      <VirtualList items={filteredUsers} let:item>
        <div class="row mdc-data-table__row">
          <div class="col-1 usersTableCell mdc-data-table__cell" checkbox>
            <Checkbox bind:group={selected} value={item} valueKey={item.id} />
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            {item.first_name}
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            {item.last_name}
          </div>
          <div class="col-2 usersTableCell mdc-data-table__cell">
            {item.email}
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            {item.details ? item.details.phone : ''}
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            {item.is_pt}
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            {item.is_customer}
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            {item.is_admin}
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            {item.is_banned}
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            {item.is_tester}
          </div>
          <div class="col-1 usersTableCell mdc-data-table__cell">
            <Icon
              on:click={() => openDialog(item)}
              class="material-icons clickable">
              edit
            </Icon>
          </div>
        </div>
      </VirtualList>
      <Pagination {totalPages} {activePage} on:page={switchPage} />
    </div>
  {/if}
</div>
