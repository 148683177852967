import { GET, POST } from "../lib/http";
import { jwt, user } from "../lib/store";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const getInvoices = async () => {
  const res = await GET("/blabla", {
    headers: { ...commonHeaders },
  });
  return res;
};

export const getBillingDetails = async () => {
  const res = await GET("/blabla", {
    headers: { ...commonHeaders },
  });
  return res;
};

export const getNextPayments = async () => {
  try {
    const res = await GET("/v2/get_next_payment", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getBalanceReferral = async () => {
  try {
    const res = await GET("/v2/get_balance_referral", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getBillingInfo = async () => {
  try {
    const res = await GET("/v2/get_fic_customer", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const storeBillingInfo = async (body) => {
  try {
    const res = await POST("/v2/store_fic_customer", {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};


export const getSetupIntent = async () => {
  try {
    const res = await GET(`/v2/get_setup_intent`, {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveNewPaymentMethod = async (body, clientSecret, selectedPriceId, selectedPriceInterval, selectedPriceNextInvoiceDate, selectedPriceValue, selectedProductId, selectedProductName) => {
  try {
    body.clientSecret = clientSecret;
    body.selectedPriceId = selectedPriceId;
    body.selectedPriceInterval = selectedPriceInterval;
    body.selectedPriceNextInvoiceDate = selectedPriceNextInvoiceDate;
    body.selectedPriceValue = selectedPriceValue;
    body.selectedProductId = selectedProductId;
    body.selectedProductName = selectedProductName;
    const res = await POST("/v2/new_payment_method", {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const verifySetupIntent = async (setupIntentId, setupIntentClientSecret) => {
  try {  
    let body = {
      setup_intent_id: setupIntentId,
      setup_intent_client_secret: setupIntentClientSecret
    };
    const res = await POST("/v2/verify_setup_intent", {
      body,
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getPaymentsMethods = async () => {
  try {
    const res = await GET("/v2/get_payment_methods", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};
export const getPaymentsMethodsDefault = async () => {
  try {
    const res = await GET("/v2/get_payment_methods/default", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getCustomerPortalUrl = async () => {
  try {
    const res = await GET("/v2/subscription/customer_portal", {
      headers: { ...commonHeaders },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export function getCreditCardBrandImage(brand = "") {
  return '/assets/PaymentMethods/' + brand.toLowerCase() + '.svg';
}