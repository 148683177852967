<script>
  import { _ } from "../../../../services/i18n";
  import { activeSubSection, previousSubSection, subscription } from "../../../../lib/store";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import { saveProduct, getProduct, updateProduct } from "../../../../services/productServices";
  import { isFeatureEnabled } from "../../../../services/subscriptionServices";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import ProductHeader from "./ProductHeader.svelte";
  import ProductInfo from "./ProductInfo.svelte";
  import SubscriptionDialog from "../../../PtInfo/Billing/SubscriptionDialog.svelte";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  let activeTab = $_("info_prodotto");
  let component;
  let product = {
    at_groups          : [], // for Automations
    description        : [],
    duration           : "",
    duration_type      : 0,
    name               : "",
    original_price     : "",
    payment_type       : 0,
    price              : "",
    price_option       : 0,
    short_description  : "",
    thank_you_message  : "",
    url_after_purchase : "",
  };
  let exitDialog;
  let loading = true;
  let msgError;
  let options = { 
    [$_("info_prodotto")]: ProductInfo, 
  };
  let props = {};
  let showSubscriptionDialog = false;
  let tabs = [ 
    $_("info_prodotto"), 
  ];

  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  $: {
    if (searchParamsTab == 'info_prodotto') {
      activeTab = $_("info_prodotto");
      component = $_("info_prodotto");
    }
  }

  $: if (activeTab == $_("info_prodotto")) {
    props = {
      product,
    } 
  }

  $: if (!isFeatureEnabled('revoo_pay', $subscription)) {
    showSubscriptionDialog = true;
  }

  const handleGoBack = () => {
    exitDialog.close();
    navigate("/products");
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleActiveTabChanged = (e) => {
    $previousSubSection = $activeSubSection;
    $activeSubSection = e.detail.tab;
    activeTab = e.detail.tab;
    component = [e.detail.tab];
    updateTab();
  };

  const updateTab = () => {
    if (component == $_("info_prodotto"))
      navigate(`/product?tab=info&productId=` + product.id, {
        replace: true,
        state: { 
          product,
        },
      });
  };

  const storeProductInfo = async (e) => {
    try {
      if (!isFeatureEnabled('revoo_pay', $subscription)) {
        showSubscriptionDialog = true;
      } 
      else {
        if (product.id) {
          await updateProduct(e.detail.product)
          .then(leavePage);
        } else {
          await saveProduct(e.detail.product)
          .then(leavePage);
        }
      }
    } catch (err) {
      console.log("storeProductInfo err", err);
      displayError();
    }
  };

  const leavePage = () => {
    navigate("/products");
  }

  const handleCloseDialog = () => {
    showSubscriptionDialog = false;
  };

  onMount(async () => {
    if (location.pathname == "/product" && searchParamsTab == "info") {
      activeTab = $_("info_prodotto");
      component = $_("info_prodotto");
    } else {
      // Default
      activeTab = $_("info_prodotto");
      component = $_("info_prodotto");
    }
    if (location && location.state && location.state.productId) {
      loading =  true;
      const item = await getProduct(location.state.productId);
      product = {
        id                 : item.id                 ?? null,
        at_groups          : item.at_groups          ?? [], // for Automations
        description        : item.description        ?? [],
        duration           : item.duration           ?? "",
        duration_type      : item.duration_type      ?? 0,
        name               : item.name               ?? "",
        note               : item.note               ?? "",
        original_price     : item.original_price     ?? "",
        payment_date       : item.payment_date       ?? "",
        payment_type       : item.payment_type       ?? 0,
        price              : item.price              ?? "",
        price_option       : item.price_option       ?? 0,
        short_description  : item.short_description  ?? "",
        thank_you_message  : item.thank_you_message  ?? "",
        url_after_purchase : item.url_after_purchase ?? "",
      };
      loading = false;
    }
    loading = false;
  });
</script>

{#if showSubscriptionDialog}
  <SubscriptionDialog 
    state={'upgrade_plan'}
    trigger={'revoo_pay'}
    on:closeDialog={handleCloseDialog} 
  />
{/if}

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={handleGoBack} 
    confirmationButtonLabel={$_('esci')}
    text={$_('sei_sicuro_di_voler_uscire')} />
</Dialog>

<ProductHeader
  on:activeTabChanged={handleActiveTabChanged}
  on:error={() => displayError()}
  on:goBack={() => exitDialog.open()} 
  {activeTab}
  {tabs}
  name={$_('indietro')}
  tabNavigationEnabled={product.id} />

<div
  class="container fluid tc-container" data-recording-gdpr-safe>
  {#if msgError}
    <div class="alert alert-danger fade show mt-2" role="alert">{msgError}</div>
  {/if}

  {#if loading}
    <Loading />
  {:else}
    <svelte:component
      this={options[component]}
      on:error={() => displayError()}
      on:storeProduct={storeProductInfo}
      {...props} />
  {/if}
</div>

<style>
  .visible--landscape {
    display: block;
  }
  * :global(.warning) {
    background-color: #f9f9fb;
    opacity: 0.8;
  }
  * :global(.warning__icon) {
    font-size: 3rem;
  }
  .warning__message {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  @media screen and (min-width: 280px) and (max-width: 896px) and (orientation: portrait) {
    .warning--rotate-screen {
      display: block;
    }
    .visible--landscape {
      display: none;
    }
  }
  @media screen and (max-width: 896px) {
    .tc-container {
      width: 100%;
      max-width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      margin-right: auto;
      margin-left: auto;
    }
  }
</style>