<script>
  import { _ } from "../../../services/i18n";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import VodDetail from "./VodDetail.svelte";
  import SubscriptionDialog from "../../PtInfo/Billing/SubscriptionDialog.svelte";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  const triggerCloseImageDialog = false;
  const triggerCloseVideo = false;

  let showSubscriptionDialog = false;
  let vod = {
    description : "",
    id          : "",
    is_active   : "",
    name        : "",
    new_video   : "",
    size        : "",
    tags        : [],
    thumbnail   : [],
    vimeo_id    : "",
    views       : "",
  };

  if (location && location.state && location.state.vod) {
    const { vod: item } = location.state;
    vod = {
      description : item.description,
      id          : item.vod_id,
      is_active   : item.is_active,
      name        : item.name || "",
      new_video   : false,
      size        : item.size || "",
      tags        : item.tags || [],
      thumbnail   : item.thumbnail || "",
      vimeo_id    : item.vimeo_id,
      views       : item.views,
    };
  } else if (location && location.state && location.state.vodToBeCloned) {
    const { vodToBeCloned: item } = location.state;
    vod = {
      description : item.description,
      id          : item.vod_id,
      is_active   : item.is_active || "",
      name        : item.name || "",
      new_video   : false,
      size        : item.size || "",
      tags        : item.tags || [],
      thumbnail   : item.thumbnail,
      vimeo_id    : item.vimeo_id,
      views       : item.views,
    };
  }

  const handleShowSubscriptionDialog = () => {
    showSubscriptionDialog = true;
  }

  const handleCloseDialog = () => {
    showSubscriptionDialog = false;
  };
</script>

{#if showSubscriptionDialog}
  <SubscriptionDialog 
    state={'upgrade_plan'}
    trigger={'vods'}
    on:closeDialog={handleCloseDialog} 
  />
{/if}

<div class="container fluid">
  <div class="row">
    <div class="col-12 my-4">
      <div
        class="mdc-typography--headline6 primary titleWrapper"
        on:click={() => {
          navigate('vods');
        }}>
        <Icon role="button" tabindex="1" class="material-icons">
          keyboard_arrow_left
        </Icon>
        <span>
          {location && location.state && location.state.vod ? location.state.vod.name : $_('nuovo_video')}
        </span>
      </div>
    </div>
  </div>

  <span class="subTitle">
    <Icon role="button" tabindex="1" class="material-icons mr-2">lock</Icon>
    {$_('i_tuoi_video_sono_privati')}
  </span>

  <VodDetail
    {vod}
    {triggerCloseImageDialog}
    {triggerCloseVideo}
    on:showSubscriptionDialog={handleShowSubscriptionDialog}
    on:goBack={() => navigate('/vods')}
    on:onSubmit={() => navigate('/vods')} />
</div>

<style>
  .subTitle {
    opacity: 0.8;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
  }
  .titleWrapper {
    align-items: center;
    display: flex;
    cursor: pointer;
  }
</style>