<script>
  import { _ } from "../../../../services/i18n";
  import { customer } from "../../../../lib/store";
  import {
    getDateFormatted,
    getGoals,
    getLifestyles,
    getWorkoutLocations,
    getWorkoutFreqs,
  } from "../../../../lib/utils";
  import { getMedicalHistory } from "../../../../services/teamServices";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import Header from "../../../ReusableLayouts/Header.svelte";

  export let location = {};

  let customerId;
  let historyId;
  let msgError;
  let medicalHistory;
  let patologies = [];
  let possiblePatologies = [
    { key: "asthma", value: $_("asma") },
    { key: "diabetes", value: $_("diabete") },
    { key: "problems_cardiac", value: $_("problemi_cardiaci") },
    { key: "problems_thyroid", value: $_("problemi_tiroide") },
  ];
  let subTitle;

  $: customerId = new URLSearchParams(location.search).get("customerId");
  $: historyId = new URLSearchParams(location.search).get("historyId");

  $: if (medicalHistory) {
    const temp = possiblePatologies.map((ele) => {
      if (medicalHistory[ele.key] == 1) {
        return ele.value;
      } else return null;
    });
    patologies = temp.filter((ele) => ele);
  }

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  onMount(async () => {
    try {
      const res = await getMedicalHistory(customerId, historyId);
      medicalHistory = res;
      const date = medicalHistory.created_at.substring(0, 10);
      subTitle = `${$_("inviata_il")} ${getDateFormatted(date)} - ${$_("da")} ${
        medicalHistory.created_by
      }`;
    } catch (error) {
      console.log("getMedicalHistory error", error);
      displayError();
    }
  });
</script>

<style>
  .allText {
    color: #212121;
    text-align: start;
  }
  .bigTitle {
    font-size: 1.5rem;
    font-weight: 700;
    padding-left: 0px;
  }
  .textTitle {
    opacity: 0.8;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 1.5rem;
  }
  .text {
    opacity: 0.8;
    font-size: 1.1rem;
    font-weight: 600;
    white-space: pre-wrap;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
</style>

<Header
  text={`${$_('anamnesi')} - ${medicalHistory ? medicalHistory.customer : ''} `}
  asTextAndIcon={true}
  {subTitle}
  on:goBack={() => navigate(`/customer?tab=histories`, {
      state: { customerId },
    })} />

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

{#if medicalHistory}
  <div class="container fluid allText pt-3 pb-5">
    <div class="container fluid py-2 px-0">
      <span class="mdc-typography--headline3 bigTitle"> {$_('generale')} </span>
    </div>
    <div class="container fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="row mdc-typography--headline6 textTitle">
            {$_('peso_attuale')}
          </div>
          <div class="row mdc-typography--headline4 text">
            {medicalHistory.weight ? medicalHistory.weight : '-'}
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="row row mdc-typography--headline6 textTitle">
            {$_('altezza')}
          </div>
          <div class="row row mdc-typography--headline4 text">
            {medicalHistory.height ? medicalHistory.height : '-'}
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="row mdc-typography--headline6 textTitle">
            {$_('gruppo_sanguigno')}
          </div>
          <div class="row mdc-typography--headline4 text">
            {medicalHistory.blood_group ? medicalHistory.blood_group : '-'}
          </div>
        </div>
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('professione')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.job ? medicalHistory.job : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="row mdc-typography--headline6 textTitle">
            {$_('stile_di_vita')}
          </div>
          <div class="row mdc-typography--headline4 text">
            {medicalHistory.lifestyle ? getLifestyles($_).filter((i) => medicalHistory.lifestyle.includes(i.value)).map(({text}) => text) : '-'}
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="row row mdc-typography--headline6 textTitle">
            {$_('luogo_di_allenamento')}
          </div>
          <div class="row row mdc-typography--headline4 text">
            {medicalHistory.workout_location.length > 0 ? getWorkoutLocations($_).filter((i) => medicalHistory.workout_location.includes(i.value)).map(({text}) => text) : '-'}
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="row mdc-typography--headline6 textTitle">
            {$_('frequenza_settimanale_di_allenamento')}
          </div>
          <div class="row mdc-typography--headline4 text">
            {medicalHistory.workout_freq ? getWorkoutFreqs($_).filter((i) => medicalHistory.workout_freq.includes(i.value)).map(({text}) => text) : '-'}
          </div>
        </div>
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('attivita_fisica')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.sport ? medicalHistory.sport : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('obiettivo')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.goal ? getGoals($_).filter((i) => medicalHistory.goal.includes(i.value)).map(({text}) => text) : '-'}
      </div>
    </div>
    <div class="container fluid px-0 w100">
      <hr class="line" />
    </div>
    <div class="container fluid py-2 px-0">
      <span class="mdc-typography--headline3 bigTitle"> {$_('fisica')} </span>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('patologie_diagnosticate')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {patologies.length > 0 ? patologies.toString() : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div class="row mdc-typography--headline6 textTitle">
            {$_('pressione_arteriosa')}
          </div>
          <div class="row mdc-typography--headline4 text">
            {medicalHistory.mmhg || '-'}
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div class="row mdc-typography--headline6 textTitle">
            {$_('ultima_rilevazione_pressione')}
          </div>
          <div class="row mdc-typography--headline4 text">
            {medicalHistory.last_revelation ? medicalHistory.last_revelation : '-'}
          </div>
        </div>
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('operazioni_chirurgiche')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.surgical ? medicalHistory.surgical : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('problemi_posturali')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.postural ? medicalHistory.postural : '-'}
      </div>
    </div>
    {#if $customer.gender != 'male'}
      <div class="container fluid">
        <div class="row mdc-typography--headline6 textTitle">
          {$_('ciclo_irregolare')}
        </div>
        <div class="row mdc-typography--headline4 text">
          {medicalHistory.irregular_periods ? medicalHistory.irregular_periods : '-'}
        </div>
      </div>
      <div class="container fluid">
        <div class="row mdc-typography--headline6 textTitle">
          {$_('pillola_contraccettiva')}
        </div>
        <div class="row mdc-typography--headline4 text">
          {medicalHistory.contraceptive_pill && medicalHistory.contraceptive_pill == 1 ? $_('si') : '-'}
        </div>
      </div>
    {/if}
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('utilizzo_farmaci')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.drugs ? medicalHistory.drugs : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('fratture')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.fractures ? medicalHistory.fractures : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('altre_patologie')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.other_pathologies ? medicalHistory.other_pathologies : '-'}
      </div>
    </div>
    <div class="container fluid px-0 w100">
      <hr class="line" />
    </div>

    <div class="container fluid py-2 px-0">
      <span class="mdc-typography--headline3 bigTitle">
        {$_('alimentare')}
      </span>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('alimentazione')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.supply ? medicalHistory.supply : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('utilizzo_integratori')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.supplements ? medicalHistory.supplements : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('intolleranze')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.intolerance ? medicalHistory.intolerance : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('allergie')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.allergies ? medicalHistory.allergies : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('disturbi_alimentari')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.disorders ? medicalHistory.disorders : '-'}
      </div>
    </div>
    <div class="container fluid">
      <div class="row mdc-typography--headline6 textTitle">
        {$_('irregolarita_intestinale')}
      </div>
      <div class="row mdc-typography--headline4 text">
        {medicalHistory.intestinal_irregularity ? medicalHistory.intestinal_irregularity : '-'}
      </div>
    </div>
  </div>
{/if}
