<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import { _ } from "../../../services/i18n";
  import SvelteTooltip from 'svelte-tooltip';
  import Button, { Icon, Label } from "@smui/button";
  import { progressions, progressionsPage } from "../../../lib/store";

  export let loadingProgressions;

</script>

<style>
  .actions-container {
    justify-content: flex-end;
  }
  .alignItemsCenter {
    align-items: center;
  }

  * :global(.outlinedCustom) {
    border-color: black;
    color: black;
  }
  * :global(.unelevatedCustom) {
    background-color: black;
  }
  @media (min-width: 280px) and (max-width: 896px) and (orientation: landscape) {
    .actions-container {
      justify-content: space-evenly;
    }
  }
</style>

<div class="row mx-0 my-3 actions-container alignItemsCenter">
  <Button
    class="btn unelevatedCustom"
    variant="unelevated"
    disabled={loadingProgressions || $progressions.length >= 12}
    on:click={() => dispatch('addProgression', {})}>
    <Label>
      <Icon
        role="button"
        class="material-icons mdc-text-field__icon
          mdc-text-field__icon--trailing">
        note_add
      </Icon>
      {$_('aggiungi_progressione')}
    </Label>
  </Button>
  {#if $progressions.length > 0}
    <SvelteTooltip tip="{$_('elimina')}" top >
      <Button
        class="btn ml-3 outlinedCustom"
        variant="outlined"
        disabled={$progressions.length > 1 && $progressionsPage == 1}
        on:click={() => dispatch('deleteProgression', {})}>
        <Label>
          <Icon
            role="button"
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing">
            delete_outline
          </Icon>
          {$_('elimina')}
        </Label>
      </Button>
    </SvelteTooltip>
  {/if}
</div>
