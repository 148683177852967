<script>
  import "moment/locale/en";
  import "moment/locale/it";
  import { _, locale } from "../../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { customer } from "../../../../lib/store";
  import { navigate } from "svelte-routing";
  import Card, { Content } from "@smui/card";
  import IconButton from "@smui/icon-button";
  import moment from "moment";
  import SvelteTooltip from "svelte-tooltip";

  export let notes = [];
  export let customerId;

  const dispatch = createEventDispatcher();

  let isMobile = false;
  let showMobileIcons = undefined;

  $: expandedNotes = notes.map(() => false);

  $: isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  const getDateFormatted = (date) => {
    const momentDate = moment(date, "YYYY MM DD");
    return $locale == "en"
      ? momentDate.locale("en").format("YYYY MMMM DD")
      : momentDate.locale("it").format("DD MMMM YYYY");
  };

  const expandNote = (index) => {
    if (!$customer.active && expandedNotes.length > -1) return;
    expandedNotes[index] = !expandedNotes[index];
  };

  const editNote = (note) => {
    if (!$customer.active) return;
    navigate(`/note?customerId=${customerId}&noteId=${note.id}`, {
      state: { note },
    });
  };

  const deleteNote = (note) => {
    if (!$customer.active) return;
    dispatch("openDialog", {
      id: note.id,
    });
  };
</script>

<style>
  .textWrapper {
    display: flex;
    align-items: center;
    opacity: 0.8;
  }
  .dateText {
    font-weight: 600;
    font-size: 1.1rem;
  }
  * :global(.textToTheLeft) {
    text-align: start !important;
  }
  * :global(.mdc-card) {
    text-align: center;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }
  * :global(.primary) {
    color: var(--primary);
  }
  .hide {
    display: none;
  }
  .note-content {
    padding: 16px 24px;
    background-color: #f9f9f9;
  }
  .justify-end {
    justify-content: flex-end;
  }
</style>

<div class="container fluid">
  {#each notes as note, i}
    <Card class="mb-2 textToTheLeft" on:click={() => expandNote(i)}>
      <Content>
        <div class="row">
          <div class="col-4 textWrapper dateText">
            {getDateFormatted(note.date)}
          </div>
          <div class="col-4 textWrapper">{note.title}</div>
          {#if isMobile}
            <div
              class="col-4 flexy justify-end"
              on:focusout={(e) => {
                if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
                showMobileIcons = undefined;
              }}>
              <div
                class="icon--action"
                class:primary={showMobileIcons === i}
                on:click|stopPropagation={() => (showMobileIcons === i ? deleteNote(note) : (showMobileIcons = i))}>
                <IconButton
                  disabled={!$customer.active && showMobileIcons === i}
                  class="material-icons p-0"
                  style="width: fit-content">
                  {showMobileIcons === i ? 'delete' : 'more_vert'}
                </IconButton>
              </div>
              <div class="flexy">
                <div
                  class:hide={showMobileIcons != i}
                  on:click|stopPropagation={() => {
                    if ($customer.active) editNote(note);
                  }}>
                  <IconButton
                    disabled={!$customer.active}
                    class="material-icons">
                    edit
                  </IconButton>
                </div>
              </div>
              <div on:click|stopPropagation={() => expandNote(i)}>
                <SvelteTooltip tip={$_('espandi')} top>
                  <IconButton
                    disabled={!$customer.active}
                    class="material-icons">
                    {expandedNotes[i] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                  </IconButton>
                </SvelteTooltip>
              </div>
            </div>
          {:else}
            <div class="col-4 flexy justify-end">
              <div on:click|stopPropagation={deleteNote(note)}>
                <SvelteTooltip tip={$_('elimina')} top>
                  <IconButton
                    disabled={!$customer.active}
                    class={`material-icons ${$customer.active ? 'primary' : ''}`}>
                    delete
                  </IconButton>
                </SvelteTooltip>
              </div>
              <div on:click|stopPropagation={() => editNote(note)}>
                <SvelteTooltip tip={$_('modifica')} top>
                  <IconButton
                    disabled={!$customer.active}
                    class="material-icons">
                    edit
                  </IconButton>
                </SvelteTooltip>
              </div>
              <div on:click|stopPropagation={() => expandNote(i)}>
                <SvelteTooltip tip={$_('espandi')} top>
                  <IconButton
                    disabled={!$customer.active}
                    class="material-icons">
                    {expandedNotes[i] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                  </IconButton>
                </SvelteTooltip>
              </div>
            </div>
          {/if}
        </div>
      </Content>
      <div class={`note-content ${expandedNotes[i] ? '' : 'hide'}`}>
        {#each note.content.split('\n') as line }
          {line}<br>
        {/each}
      </div>
    </Card>
  {/each}
</div>
