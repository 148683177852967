<script>
    import CharacterCounter from "@smui/textfield/character-counter";
    export let text;
    export let max;
</script>

<div class="position-absolute d-flex justify-content-end align-items-end w100 h100 p-2">
    <CharacterCounter>
        {`${text.length} / ${max}`}
    </CharacterCounter>
</div>