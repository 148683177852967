<script>
  import { _ } from "../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { Icon } from "@smui/common";
  import { tags } from "../../lib/store";
  import Button, { Label } from "@smui/button";
  import Chip, { Set, Text, Checkmark } from "@smui/chips";
  import List from "@smui/list";
  
  export let mobile = false;
  export let resetFilter = false;
  
  const dispatch = createEventDispatcher();
  
  /**
   * HOUSTON-1671
   * 
   * (Sviluppo iniziato con HOUSTON-1639)
   * 
   * Utilizzare "export let filter;" al posto di "let filter = []"; 
   * per ottenere il valore salvato del filtro tag dopo aver effettuato l'accesso ad un esercizio,
   * e ritrovarsi lo stesso filtro attivo tornando alla lista degli esercizi. 
   * 
   * Attenzione: una volta fatto, va configurato questo componente ed effettuato il reset della variabile exercisesActiveDropdownTagFilter nel local storage.
   * 
   */
  
  // export let filter = [];

  let clearResults = false;
  let filter = [];
  let filterLabel = $_("tutti_i_tag");
  let filterValue;

  $: filterValue =
    filter.length > 0 ? `${$_("filtra")} (${filter.length})` : filterLabel;

  $: if (filter.length > 0) {
    dispatch("tagsFiltered", {
      tags: filter,
    });
    clearResults = true;
  }

  $: if (filter.length == 0 && clearResults) {
    dispatch("tagsFiltered", {
      tags: filter,
    });
    clearResults = false;
  }

  $: if (resetFilter) {
    console.log("resetting filter");
    filter = [];
  }
</script>

<style>
  .pushToEnd {
    text-align: end;
  }
  * :global(.list) {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 30em;
  }

  * :global(.mdc-chip-set) {
    justify-content: center;
  }

  * :global(.mdc-button.customButton.buttonTextfield) {
    background-color: transparent;
    border: 1px solid rgba(33, 33, 33, 0.3);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    position: relative;
    text-align: left;
    width: 13rem;
  }

  * :global(.mdc-button .mdc-button__label) {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    opacity: 0.8;
  }

  * :global(.mdc-button .material-icons) {
    color: #000;
    font-size: 1.5rem;
    height: 1.5rem;
    opacity: 0.5;
    width: 1.5rem;
  }
</style>

{#if !mobile}
  <div class="dropdown pushToEnd my-3">
    <Button
      variant="outlined"
      color="secondary"
      class="customButton buttonTextfield"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false">
      <Label>{filterValue}</Label>
      <Icon class="material-icons">keyboard_arrow_down</Icon>
    </Button>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="dropdownMenuButton">
      <List checklist class="list">
        <Set chips={$tags} let:chip filter bind:selected={filter}>
          <Chip tabindex="0">
            <Checkmark />
            <Text>{chip.name}</Text>
          </Chip>
        </Set>
      </List>
    </div>
  </div>
{:else}
  <List checklist class="list">
    <Set chips={$tags} let:chip filter bind:selected={filter}>
      <Chip tabindex="0">
        <Checkmark />
        <Text>{chip.name}</Text>
      </Chip>
    </Set>
  </List>
{/if}
