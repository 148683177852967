<script>
  import { _ } from "../../../../services/i18n";
  import {
    createNewMedicalHistory,
    getMedicalHistory,
    updateMedicalHistory,
  } from "../../../../services/teamServices";
  import { customer } from "../../../../lib/store";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import DietHistory from "./DietHistory.svelte";
  import GeneralHistory from "./GeneralHistory.svelte";
  import Header from "../../../ReusableLayouts/Header.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import PhysicalHistory from "./PhysicalHistory.svelte";

  export let location = {};
  export let showNavbar;

  let active = true;
  let activeTab = $_("generale");
  let component = [$_("generale")];
  let customerId;
  let exitDialog;
  let history = {};
  let historyId;
  let msgError;
  let name = $customer.first_name;
  let options = {
    [$_("generale")]: GeneralHistory,
    [$_("fisica")]: PhysicalHistory,
    [$_("alimentare")]: DietHistory,
  };
  let props = {};
  let surname = $customer.last_name;
  let tabs = [$_("generale"), $_("fisica"), $_("alimentare")];

  $: customerId = new URLSearchParams(location.search).get("customerId");
  $: historyId = new URLSearchParams(location.search).get("historyId");
  $: if (history) props = { ...history, active, customerId, history };

  const handleActiveTabChanged = (e) => {
    activeTab = e.detail.tab;
    component = [e.detail.tab];
    if (e.detail.history) {
      updateLocalHistory(e.detail.history);
    }
  };

  const updateLocalHistory = (obj) => {
    for (let [key, value] of Object.entries(obj)) {
      history[key] = value;
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleSaveHistory = async (e) => {
    try {
      updateLocalHistory(e.detail.history);
      if (historyId) {
        await updateMedicalHistory(history, historyId, customerId);
      } else {
        await createNewMedicalHistory(history, customerId);
      }
      navigate(`/customer?tab=histories`, {
        state: { customerId },
      });
    } catch (err) {
      console.log("handleSaveHistory update error", err);
      displayError();
    }
  };

  const handleUpdateHistory = async (e) => {
    try {
      updateLocalHistory(e.detail.history);
      if (historyId) {
        await updateMedicalHistory(history, historyId, customerId);
      } else {
        await createNewMedicalHistory(history, customerId);
      }
      navigate(`/customer?tab=histories`, {
        state: { customerId },
      });
    } catch (err) {
      console.log("handleUpdateHistory error", err);
      displayError();
    }
  };

  const handleGoBack = () =>
    location.pathname == "/new-history" ? exitDialog.open() : goBack();

  const goBack = () =>
    navigate(`/customer?tab=histories`, {
      state: { customerId },
    });

  const exitConfirmation = () => {
    exitDialog.close();
    goBack();
  };

  onMount(async () => {
    if (historyId) {
      try {
        const res = await getMedicalHistory(customerId, historyId);
        for (let k in res) {
          if (k == "weoght" || k == "height") {
            history[k] = res[k] ? parseFloat(res[k]) : "";
          } else if (
            [
              "problems_thyroid",
              "problems_cardiac",
              "asthma",
              "contraceptive_pill",
              "diabetes",
            ].includes[k]
          ) {
            history[k] = res[k] ? true : false;
          } else {
            history[k] = res[k] ? res[k] : "";
          }
        }
      } catch (error) {
        console.log("getMedicalHistory error", error);
        displayError();
      }
    }
  });
</script>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_uscire')}
    confirmationButtonLabel={$_('esci')}
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={exitConfirmation} />
</Dialog>

<Header
  {activeTab}
  {tabs}
  asTextAndIcon={true}
  asTitle={false}
  component={undefined}
  noBackground={false}
  on:activeTabChanged={handleActiveTabChanged}
  on:goBack={handleGoBack} 
  props={{}}
  sticky={false}
  subTitle={false}
  summary={false}
  tabNavigationEnabled={true}
  text={`${historyId ? $_('anamnesi') : $_('nuova_anamnesi')} - ${name} ${surname} ${$_('bozza')}`} />

<div class="container fluid">
  <MsgError {msgError} />
  <svelte:component
    this={options[component]}
    {...props}
    on:goForth={handleActiveTabChanged}
    on:updateHistory={handleUpdateHistory}
    on:saveHistory={handleSaveHistory} />
</div>
