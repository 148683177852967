exports.reviewCry = [
    {d: "M20,38C9.5,38,1,29.5,1,19S9.5,0,20,0s19,8.5,19,19S30.5,38,20,38z M20,2C10.6,2,3,9.6,3,19s7.6,17,17,17	s17-7.6,17-17S29.4,2,20,2z"},
    {id: "Oval_1_", d: "M13,16.7c2.8,0,5,1.9,5,0.7S15.8,14,13,14s-5,1.5-5,3.3S10.2,16.7,13,16.7z"},
    {id: "Oval_2_", d: "M27,16.7c-2.8,0-5,1.9-5,0.7s2.2-3.3,5-3.3s5,1.5,5,3.3S29.8,16.7,27,16.7z"},
    {id: "Rectangle", d: "M20,23L20,23c2.2,0,4,1.8,4,4v3c0,2.2-1.8,4-4,4l0,0c-2.2,0-4-1.8-4-4v-3C16,24.8,17.8,23,20,23 z"},

    {type: "g", g: [
        {id: "Combined-Shape_1_", fill: "#FFFFFF", d: "M27,37.3V16h2c1.1,0,2,0.9,2,2v19.1c1.2,0.2,2,0.5,2,0.9c0,0.6-1.6,1-3.5,1 S26,38.6,26,38C26,37.7,26.4,37.5,27,37.3z"},
        {d: "M29.5,39.5c-0.4,0-4,0-4-1.5c0-0.4,0.3-0.8,1-1.1V15.5H29c1.4,0,2.5,1.1,2.5,2.5v18.7c1,0.2,2,0.6,2,1.3 C33.5,39.5,29.9,39.5,29.5,39.5z M26.6,38c0.3,0.2,1.3,0.5,2.9,0.5c1.6,0,2.6-0.3,2.9-0.5c-0.2-0.1-0.6-0.3-1.5-0.4l-0.4-0.1V18 c0-0.8-0.7-1.5-1.5-1.5h-1.5v21.2l-0.4,0.1C26.9,37.9,26.7,37.9,26.6,38z"}
    ]},

    {type: "g", g: [ 
        {id: "Combined-Shape_2_", fill: "#FFFFFF", d: "M9,37.1V18c0-1.1,0.9-2,2-2h2v21.3c0.6,0.2,1,0.4,1,0.7c0,0.6-1.6,1-3.5,1S7,38.6,7,38C7,37.6,7.8,37.3,9,37.1z"},
        {d: "M10.5,39.5c-0.4,0-4,0-4-1.5c0-0.8,1-1.1,2-1.3V18c0-1.4,1.1-2.5,2.5-2.5h2.5v21.4c0.7,0.3,1,0.6,1,1.1 C14.5,39.5,10.9,39.5,10.5,39.5z M7.6,38c0.3,0.2,1.3,0.5,2.9,0.5c1.6,0,2.6-0.3,2.9-0.5c-0.1-0.1-0.3-0.1-0.6-0.2l-0.4-0.1V16.5 H11c-0.8,0-1.5,0.7-1.5,1.5v19.5l-0.4,0.1C8.2,37.7,7.8,37.9,7.6,38z"}
    ]}
];

exports.reviewOk = [
    {id: "Rectangle", d: "M11,24h18c0.6,0,1,0.4,1,1l0,0c0,0.6-0.4,1-1,1H11c-0.6,0-1-0.4-1-1l0,0C10,24.4,10.4,24,11,24z"},
    {type: 'e', id: "Oval", cx: "26", cy: "15.5", rx: "2", ry: "3.5"},
    {type: 'e', id: "Oval_1_", cx: "14", cy: "15.5", rx: "2", ry: "3.5"},
    {d: "M20,38C9.5,38,1,29.5,1,19S9.5,0,20,0s19,8.5,19,19S30.5,38,20,38z M20,2C10.6,2,3,9.6,3,19s7.6,17,17,17 s17-7.6,17-17S29.4,2,20,2z"},
    
];
exports.reviewHappy = [
    {d: "M20,38C9.5,38,1,29.5,1,19S9.5,0,20,0s19,8.5,19,19S30.5,38,20,38z M20,2C10.6,2,3,9.6,3,19s7.6,17,17,17s17-7.6,17-17S29.4,2,20,2z"},
    {type: "g", g: [
        {id: "Star_1_", fill: "#FFFFFF", d: "M10.3,18.2c0.4-0.3,0.9-0.4,1.4-0.3l4.5,1.4c0.1,0,0.2,0,0.3,0c0.3-0.1,0.4-0.4,0.3-0.6l-1.7-4.4c-0.2-0.5-0.1-1,0.2-1.4L18,9c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.3-0.5-0.5-0.5l-4.7,0.3c-0.5,0-1-0.2-1.3-0.6L8.7,4C8.7,4,8.6,3.9,8.5,3.9C8.2,3.8,7.9,3.9,7.9,4.2L6.6,8.8c-0.1,0.5-0.5,0.9-1,1l-4.5,1.5c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.2-0.1,0.5,0.1,0.7l4,2.6c0.4,0.3,0.7,0.7,0.7,1.2l0.1,4.7c0,0.1,0,0.2,0.1,0.3c0.2,0.2,0.5,0.2,0.7,0.1L10.3,18.2z"},
        {d: "M6.3,21.8c-0.3,0-0.6-0.1-0.8-0.4c-0.1-0.2-0.2-0.4-0.2-0.6l-0.1-4.7c0-0.3-0.2-0.6-0.5-0.8l-4-2.6c-0.2-0.1-0.4-0.4-0.4-0.6c-0.1-0.3,0-0.5,0.1-0.8C0.6,11.1,0.8,11,1,10.9l4.5-1.5C5.8,9.2,6.1,9,6.1,8.7l1.2-4.6c0.1-0.5,0.7-0.8,1.2-0.7C8.8,3.4,9,3.6,9.1,3.7L12,7.5c0.2,0.3,0.5,0.4,0.9,0.4l4.7-0.3c0.5,0,1,0.4,1.1,0.9c0,0.2-0.1,0.4-0.2,0.6l-2.7,3.9c-0.2,0.3-0.2,0.6-0.1,0.9l1.7,4.4c0.2,0.5-0.1,1.1-0.6,1.3c-0.2,0.1-0.4,0.1-0.7,0l-4.5-1.4c-0.3-0.1-0.7,0-0.9,0.2h0l-3.7,3C6.8,21.7,6.5,21.8,6.3,21.8z M8.3,4.3L7.1,8.9c-0.2,0.6-0.7,1.2-1.3,1.4l-4.5,1.5l4,2.6c0.6,0.4,0.9,1,0.9,1.6l0.1,4.7l3.7-3c0.5-0.4,1.2-0.6,1.8-0.4l4.5,1.4l-1.7-4.4c-0.2-0.6-0.2-1.3,0.2-1.9l2.7-3.9l-4.7,0.3c-0.7,0-1.3-0.3-1.7-0.8L8.3,4.3z"}
    ]},
    
    {type: "g", g: [ 
        {id: "Star_2_", fill: "#FFFFFF", d: "M29.7,18.2c-0.4-0.3-0.9-0.4-1.4-0.3l-4.5,1.4c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.4-0.4-0.3-0.6l1.7-4.4c0.2-0.5,0.1-1-0.2-1.4L22,9c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.3-0.5,0.5-0.5l4.7,0.3c0.5,0,1-0.2,1.3-0.6L31.3,4c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.1,0.5,0.1,0.6,0.4l1.2,4.6c0.1,0.5,0.5,0.9,1,1l4.5,1.5c0.1,0,0.2,0.1,0.3,0.2c0.2,0.2,0.1,0.5-0.1,0.7l-4,2.6c-0.4,0.3-0.7,0.7-0.7,1.2l-0.1,4.7c0,0.1,0,0.2-0.1,0.3c-0.2,0.2-0.5,0.2-0.7,0.1L29.7,18.2z"},
        {d: "M33.7,21.8c-0.2,0-0.4-0.1-0.6-0.2l-3.7-3c-0.3-0.2-0.6-0.3-0.9-0.2l-4.5,1.4c-0.2,0.1-0.4,0.1-0.7,0c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.1-0.5,0-0.8l1.7-4.4c0.1-0.3,0.1-0.7-0.1-0.9l-2.7-3.9c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.6,0.5-1,1.1-0.9l4.7,0.3c0.3,0,0.7-0.1,0.9-0.4l2.8-3.8c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.1,1.1,0.2,1.2,0.7l1.2,4.6c0.1,0.3,0.3,0.6,0.6,0.7l4.5,1.5c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.5,0.1,0.8s-0.2,0.5-0.4,0.6l-4,2.6c-0.3,0.2-0.5,0.5-0.5,0.8l-0.1,4.7c0,0.2-0.1,0.4-0.2,0.6C34.3,21.7,34,21.8,33.7,21.8z M28.7,17.4c0.5,0,0.9,0.2,1.3,0.4l0,0l3.7,3l0.1-4.7c0-0.7,0.4-1.3,0.9-1.6l4-2.6l-4.5-1.5c-0.6-0.2-1.1-0.7-1.3-1.4l-1.2-4.6l-2.8,3.8c-0.4,0.5-1,0.8-1.7,0.8l-4.7-0.3l2.7,3.9c0.4,0.5,0.5,1.2,0.2,1.9l-1.7,4.4l4.5-1.4C28.3,17.4,28.5,17.4,28.7,17.4z"}
    ]},
    {id: "Combined-Shape", d: "M8.3,24c3.4,1.3,7.5,2.1,11.8,2.1S28.6,25.3,32,24c-0.9,5.7-5.9,10.1-11.8,10.1S9.2,29.7,8.3,24z"}
];

exports.anamnesis = [{d: "M19.09,2.29A3.36,3.36,0,0,0,16.55.63a1.17,1.17,0,0,0-1-.63,1.2,1.2,0,1,0,0,2.4,1.16,1.16,0,0,0,1-.56,2.15,2.15,0,0,1,1.56,3.22l-3.82,6.61a2.17,2.17,0,0,1-3.77,0L6.65,5.06a2.18,2.18,0,0,1,0-2.18,2.13,2.13,0,0,1,1.53-1,1.19,1.19,0,0,0,1,.56,1.2,1.2,0,1,0-1-1.77A3.31,3.31,0,0,0,5.62,2.29a3.31,3.31,0,0,0,0,3.36l3.82,6.62a3.34,3.34,0,0,0,2.32,1.63v3.18c0,3.12-1.84,5.65-4.1,5.65s-4.1-2.53-4.1-5.65V13.29a3,3,0,1,0-1.19,0v3.79c0,3.77,2.38,6.84,5.29,6.84S13,20.85,13,17.08V13.9a3.36,3.36,0,0,0,2.32-1.63l3.82-6.62A3.36,3.36,0,0,0,19.09,2.29Z", transform:"scale (-1, 1)", transformOrigin:"center"}];
