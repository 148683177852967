<script>
  import { _ } from "../../../services/i18n";
  import { allExercises, progressionsPage, workouts } from "../../../lib/store";
  import { createEventDispatcher, tick } from "svelte";
  import { generateUID, getCombinations } from "../../../lib/utils";
  import { Icon } from "@smui/common";
  import { Label } from "@smui/button";
  import { validateCombinationValues } from "./workoutsValidation.js";
  import AddNewExercise from "./AddNewExercise.svelte";
  import CustomSelect from "./CustomSelect.svelte";
  import Exercise from "./exercise/Exercise.svelte";
  import MaskInput from "svelte-input-mask";
  import SortableList from "@palsch/svelte-sortablejs";
  import Textfield from "@smui/textfield";
  
  export let callCheckExercisesOnCombinationChange = false;
  export let combination;
  export let dialogSelectedExercise = false;
  export let isProgression;
  export let isMobile;

  const dispatch = createEventDispatcher();
  const exerciseSelected = null;
  const sortableOptions = {
    group: {
      name: "exercises",
      pull: ["exercises"],
      put: ["exercises", "exercises-list"],
    },
    animation: 150,
    handle: ".exercises-drag-handle",
  };
  
  let combinationItems = getCombinations($_);
  let newExerciseField = true;

  $: if (combination.supersets.toString().includes(".") ||
         combination.supersets.toString().includes(",")) {
         combination.supersets = validateCombinationValues(parseInt(combination.supersets), 'sets');
     }

  $: if (combination.method == 'emom') 
  {
    combination.emom_sets = (combination.emom_sets == '' || combination.emom_sets == null) 
      ? '' 
      : combination.emom_sets = validateCombinationValues(parseInt(combination.emom_sets), 'sets');

    if (combination.emom_time == null) {
      combination.emom_time = formatTime("1");
    }
  }

  $: if (dialogSelectedExercise !== false && dialogSelectedExercise.detail.combination.sortId) {
    handleExerciseSelected(dialogSelectedExercise);
  }

  const handleExerciseSelected = (e) => {
    // console.table([combination, dialogSelectedExercise.detail.combination], ["sortId"])
    if ((dialogSelectedExercise && 
         dialogSelectedExercise.detail.combination.sortId == combination.sortId) ||
        !dialogSelectedExercise) {
      if (combination.method == "normal" || (combination.method == "superset" && combination.exercises.length == 2)) {
        combination.method = "circuit";
        checkExercisesOnCombinationChange();
      }
      combination.exercises[0].details = [combination.exercises[0].details[0]];
      combination.exercises = [
        ...combination.exercises,
        {
          id: e.detail.exercise ? e.detail.exercise.id : e.detail.ex.id,
          name: e.detail.exercise ? e.detail.exercise.name : e.detail.ex.name,
          note: "",
          sortId: generateUID(),
          details: [
            {
              sets: "",
              reps: {
                format: "standard",
                type: combination.method == "time_circuit" ? "time" : "reps",
                value_high: "",
                value: "",
              },
              weight: {
                format: "standard",
                type: "kg",
                value_high: "",
                value: "",
              },
              rest: "",
              tut: "",
            },
          ],
        },
      ];
      $workouts = $workouts;
    }
    dialogSelectedExercise = false;
  };

  const handleDeleteExercise = (e) => {
    dispatch("dirtyExercise", { dirtyExercise: e.detail.dirtyExercise });
    combination.exercises = combination.exercises.filter(
      (i) => i.sortId != e.detail.sortId
    );
    if (combination.exercises.length == 0)
      dispatch("deleteCombination", { combination });
  };

  const checkExercisesOnCombinationChange = async (value) => {
    if (value == "normal" && combination.exercises.length > 1) {
      const newWorkouts = $workouts.map((w) => {
        let newCombinations = [];
        for (let comb of Object.assign(w.combinations)) {
          if (comb.sortId == combination.sortId) {
            const spreadCombinations = combination.exercises.map((ex) => {
              return {
                method: "normal",
                supersets: "",
                sortId: generateUID(),
                exercises: [
                  {
                    name: ex.name,
                    note: ex.note,
                    id: ex.id,
                    sortId: generateUID(),
                    reset_code_id: ex.reset_code_id ?? null,
                    details: [
                      {
                        sets: "",
                        reps: ex.details[0].reps,
                        weight: ex.details[0].weight,
                        rest: ex.details[0].rest,
                        tut: ex.details[0].tut,
                      },
                    ],
                  },
                ],
              };
            });
            newCombinations = [...newCombinations, ...spreadCombinations];
          } else {
            newCombinations.push(comb);
          }
        }
        w.combinations = newCombinations;
        return w;
      });
      $workouts = newWorkouts;
    } else if (value == "superset" && combination.exercises.length > 2) {
      const newWorkouts = $workouts.map((w) => {
        let newCombinations = [];
        for (let comb of Object.assign(w.combinations)) {
          if (comb.sortId == combination.sortId) {
            const actualSuperset = {
              method: "superset",
              supersets: "",
              sortId: generateUID(),
              exercises: [comb.exercises[0], comb.exercises[1]],
            };
            const otherExercises = combination.exercises.slice(
              2,
              combination.exercises.length
            );
            const spreadCombinations = otherExercises.map((ex) => {
              return {
                method: "normal",
                supersets: "",
                sortId: generateUID(),
                exercises: [ex],
              };
            });
            newCombinations = [
              ...newCombinations,
              actualSuperset,
              ...spreadCombinations,
            ];
          } else {
            newCombinations.push(comb);
          }
        }
        w.combinations = newCombinations;
        return w;
      });
      $workouts = newWorkouts;
    } else if (value == "emom") {
      combination.emom_sets = '';
      combination.emom_time = null;
      combination.exercises.forEach((ex) => {
        ex.details.forEach((detail) => {
          detail.rest = '';
          detail.tut = '';
        });
      });
      if (combination.exercises.length < 2) {
        if (combination.exercises[0].sortId) {
          combination.exercises[0].sortId = generateUID();
        }
        combination.exercises[0].details = [
          combination.exercises[0] ? combination.exercises[0].details[0] : "",
        ];  
      }
      if (combination.exercises.length > 3) {
        const newWorkouts = $workouts.map((w) => {
          let newCombinations = [];
          for (let comb of Object.assign(w.combinations)) {
            if (comb.sortId == combination.sortId) {
              const actualEmom = {
                method: "emom",
                supersets: "",
                emom_sets: "",
                emom_time: null,
                sortId: generateUID(),
                exercises: [comb.exercises[0], comb.exercises[1], comb.exercises[2]],
              };
              const otherExercises = combination.exercises.slice(
                3,
                combination.exercises.length
              );
              const spreadCombinations = otherExercises.map((ex) => {
                return {
                  method: "normal",
                  supersets: "",
                  sortId: generateUID(),
                  exercises: [ex],
                };
              });
              newCombinations = [
                ...newCombinations,
                actualEmom,
                ...spreadCombinations,
              ];
            } else {
              newCombinations.push(comb);
            }
          }
          w.combinations = newCombinations;
          return w;
        });
        await tick();
        $workouts = newWorkouts;
      }
    } else if (value != "normal" && combination.exercises.length < 2) {
      // Fix bug da piramidale a superset o circuito/circuito a tempo (drag/drop spaccava builder)
      if (combination.exercises[0].sortId) {
        combination.exercises[0].sortId = generateUID();
      }
      combination.exercises[0].details = [
        combination.exercises[0] ? combination.exercises[0].details[0] : "",
      ];
    } else if (value == "circuit" && combination.exercises.length == 2) {
      // Fix bug fusione due piramidali (in circuito)
      // Non rimuovere questa condizione anche se vuota
    } else {
      combination.exercises[0].details = [
        combination.exercises[0] ? combination.exercises[0].details[0] : "",
      ];
    }

    // Reset emom values
    if (value != "emom") {
      combination.emom_sets = '';
      combination.emom_time = null;
    }

    // Reset callCheckExercisesOnCombinationChange
    callCheckExercisesOnCombinationChange = false;
  };

  const formatTime = (time) => {
    if (!time || typeof time != "string") return "00:00";
    let [minutes, seconds] = time.split(":");
    if (!minutes) minutes = "00";
    else if (minutes.length == 1) minutes = `0${minutes}`;
    if (!seconds) seconds = "00";
    else if (seconds.length == 1) seconds = `${seconds}0`;
    return `${minutes}:${seconds}`;
  };

  const itemOrderChanged = (e) => {
    $workouts = $workouts;
    if (combination.exercises.length == 0) {
      dispatch("deleteCombination", { combination });
      return;
    }
    if (combination.exercises.length > 1 && combination.method == "normal") {
      combination.exercises[0].details = [combination.exercises[0].details[0]];
      combination.exercises[1].details = [combination.exercises[1].details[0]];
      combination.exercises[0].sortId = generateUID();
      combination.exercises[1].sortId = generateUID();
      combination.method = "circuit";
    } else if (combination.method == "superset" &&
               combination.exercises.length > 2) {
      combination.method = "circuit";
    } else if (combination.method == "emom" &&
               combination.exercises.length > 3) {
      combination.method = "circuit";
      combination.supersets = combination.emom_sets;
      combination.emom_sets = "";
      combination.emom_time = null;
    } 
    if (combination.method != "normal" &&
        combination.exercises.length > 1) {
      combination.exercises.forEach((ex) => {
        if (ex.details.length > 1) {
          ex.details.splice(1, ex.details.length);
        }
      });
    }
    combination.exercises = combination.exercises.map((i) => {
      if (i.hasOwnProperty("image")) {
        if (combination.method == "time_circuit")
          i.details[0].reps.type = "time";
        i = {
          name: i.name,
          id: i.id,
          sortId: i.sortId,
          note: "",
          details: i.details,
        };
      }
      return i;
    });
  };

  function getItemById(id) {
    return $allExercises.find((item) => item.sortId == id);
  }

  const handleCheckAndUpdateDuplicates = (e) => {
    dispatch('checkAndUpdateDuplicates', {
      exerciseId: e.detail.exerciseId, 
      newExerciseName: e.detail.newExerciseName, 
    });
  }

  const handleChangedItem = (e) => {
    combination.exercises.forEach((ex) => {
      ex.reset_code_id = ex.code_id;
      delete ex.code_id;
    });

    checkExercisesOnCombinationChange(e.detail.selected);
  };

  const handleSearchExercisesClicked = async () => {
    dispatch('openExercisesDialog', { 
      component: 'Combination',
      combination: combination,
    });
  };

  const handleDirtyExercise = (e) => {
    dispatch("dirtyExercise", { dirtyExercise: e.detail.dirtyExercise });
  };
</script>

<style>
  .combinationsWrapper {
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid rgba(33, 33, 33, 0.2);
    box-sizing: border-box;
    width: 150%;
  }

  .combinationsWrapper__first-progression {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    .combinationsWrapper {
      width: 150%;
    }
  }
  :global(.ulCustom) {
    list-style-type: none;
    padding-inline-start: unset;
  }
  :global(.ulCustom > li) {
    background-color: #fff;
  }
  .label {
    font-size: 0.8rem;
    opacity: 0.7;
  }
  .inputWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  * :global(.customLabel) {
    color: #212121 !important;
    font-size: 0.875rem;
    font-weight: 600;
    left: auto;
    letter-spacing: 0.5px;
    opacity: 0.5;
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
  }
  * :global(.mdc-text-field) {
    height: 2.5em;
  }

  * :global(.mdc-text-field__input) {
    max-height: 100%;
    padding: 0 0.5em;
  }

  * :global(.mdc-select .mdc-select__native-control) {
    border-bottom: none;
    font-size: 0.8rem;
    padding: unset;
  }
</style>

<div class="combinationsWrapper {$progressionsPage === 1 ? `combinationsWrapper__first-progression` : ``} p-2 mt-2">
  <div class="row">
    <div class="col-2 inputWrapper pr-0">
      <div class="pt-2 {combination.method == "normal" ? `w100` : ``}">
        <span class="label">{$_('combinazione')}</span>
        <div class="combination-select">
          <CustomSelect
            outlined={true}
            bind:selected={combination.method}
            on:changedItem={handleChangedItem}
            items={combinationItems} />
          {#if combination.method == 'time_circuit'}
            <div class="w100 mdc-text-field mdc-text-field--outlined mt-2">
              <MaskInput
                {...$$props}
                class="w100 customInput"
                placeholder="mm:ss"
                mask={'00:00'}
                on:change={(e) => {
                  combination.supersets = e.detail.inputState.maskedValue;
                }}
                bind:value={combination.supersets}
                disabled={$progressionsPage == 1 && isProgression} />
              <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
                <div class="mdc-notched-outline__leading" />
                <div class="mdc-notched-outline__notch" />
                <div class="mdc-notched-outline__trailing" />
              </div>
            </div>

            <!-- <Textfield variant="outlined" class="w100 mt-2">
              <MaskInput
                {...$$props}
                class="mdc-text-field__input"
                maskString={'00:00'}
                mask={'00:00'}
                on:change={(e) => {
                  combination.supersets = e.detail.inputState.maskedValue;
                }}
                bind:value={combination.supersets} />
              <Label
                class="mdc-text-field__icon mdc-text-field__icon--trailing
                  customLabel">
                {$_('minuti_abbreviato')}
              </Label>
              <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
                <div class="mdc-notched-outline__leading" />
                <div class="mdc-notched-outline__trailing" />
              </div>
            </Textfield> -->
          {:else if combination.method == 'emom'}
            <div class="w100 mdc-text-field mdc-text-field--outlined mt-2">
              <MaskInput
                {...$$props}
                class="w100 customInput"
                placeholder="mm:ss"
                mask={'00:00'}
                on:change={(e) => {
                  combination.emom_time = e.detail.inputState.maskedValue;
                }}
                bind:value={combination.emom_time}
                disabled={$progressionsPage == 1 && isProgression} />
              <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
                <div class="mdc-notched-outline__leading" />
                <div class="mdc-notched-outline__notch" />
                <div class="mdc-notched-outline__trailing" />
              </div>
            </div>
            <Textfield
              class="w100 mt-2"
              withTrailingIcon
              bind:value={combination.emom_sets}
              disabled={$progressionsPage == 1 && isProgression}
              variant="outlined"
              input$min={0}
              on:input={(e) => e.target.value = validateCombinationValues(parseInt(e.target.value), 'sets')}
              type="number">
              <Label
                class="mdc-text-field__icon mdc-text-field__icon--trailing
                  customLabel">
                {$_('set')}
              </Label>
            </Textfield>
          {:else if combination.method != 'normal'}
            <Textfield
              class="w100 mt-2"
              withTrailingIcon
              bind:value={combination.supersets}
              disabled={$progressionsPage == 1 && isProgression}
              variant="outlined"
              input$min={0}
              on:input={(e) => e.target.value = validateCombinationValues(parseInt(e.target.value), 'sets')}
              type="number">
              <Label
                class="mdc-text-field__icon mdc-text-field__icon--trailing
                  customLabel">
                {$_('set')}
              </Label>
            </Textfield>
          {/if}
        </div>
      </div>
    </div>
    <div class="col-10">
      <SortableList
        {sortableOptions}
        on:orderChanged={itemOrderChanged}
        bind:items={combination.exercises}
        {getItemById}
        idKey="sortId"
        ulClass="ulCustom"
        let:item>
        <Exercise
          exercise={item}
          {combination}
          {isMobile}
          bind:isProgression
          on:openExercisesDialog={(e) => {
            dispatch('openExercisesDialog', { 
              component: 'Exercise',
              combination: combination,
              exercise: e.detail.exercise,
            });
          }}
          on:checkAndUpdateDuplicates={handleCheckAndUpdateDuplicates}
          on:dirtyExercise={handleDirtyExercise}
          on:deleteExercise={handleDeleteExercise} />
      </SortableList>

      {#if combination.method != 'normal' && 
           !(combination.method == 'superset' && combination.exercises.length == 2) &&
           !(combination.method == 'emom' && combination.exercises.length == 3) &&
           isProgression === false}
        <hr />

        <div class="row py-1">
          <div class="col-1 px-0 drag-handle flexy" />
          <div class="col-9 px-0">
            <AddNewExercise
              {exerciseSelected}
              {newExerciseField}
              dirtyExercise={false}
              search=""
              on:exerciseSelected={handleExerciseSelected}
              on:dirtyExercise={handleDirtyExercise} />
          </div>
          <div class="col-1 pl-0 text-center">
            <span class="label">{$_('cerca')}</span>
            <div class="py-2">
              <Icon
                class="material-icons searchIcon cursorPointer"
                on:click={handleSearchExercisesClicked}>
                search
              </Icon>
            </div>
          </div>
          <div class="col-1 px-0" />
        </div>
      {/if}
    </div>
  </div>
</div>
