<script>
	import { _ } from "../../../../services/i18n";
	import { createEventDispatcher } from "svelte";
	import { navigate } from "svelte-routing";
	import { sendTrainingCardNotifications } from "../../../../services/trainingCardServices.js";
	import ConfirmationDialog from "../../../ReusableLayouts/ConfirmationDialog.svelte";
	import Dialog from "@smui/dialog";
	import IconButton from "@smui/icon-button";
	import LoadingInline from "../../../ReusableLayouts/LoadingInline.svelte";
	import SvelteTooltip from 'svelte-tooltip';

	export let groupId;
	export let index;
	export let isMobile;
	export let showMobileIcons = undefined;
	export let tc;

	const dispatch = createEventDispatcher();

	let cardToBeEdited;
	let confirmationDeleteDialog;
	let confirmationEditDialog;
	let dialogText = '';
	let idOfCardToBeDeleted;
	let sendingNotification = false;
	let showNotificationSent = false;

	const handleDeleteButtonClicked = (id) => {
		idOfCardToBeDeleted = id;
		confirmationDeleteDialog.open();
  };

  const handleDuplicateButtonClicked = (card) => {
		navigate(card.is_assigned_to_group ? `new-training-card?tab=info?trainingCardId=` + card.id + `&type=assignmentToGroups` : `new-training-card?trainingCardId=` + card.id, {
			state: { trainingCardId: card.id },
		});
  }

  const handleDeleteConfirmation = () => {
		dispatch("deleteTrainingCard", {
			id: idOfCardToBeDeleted,
		});
		confirmationDeleteDialog.close();
  };

  const handleEditButtonClicked = (card) => {
		if (card.is_assigned_to_group && card.has_executed_workouts) {
			cardToBeEdited = card;
			confirmationEditDialog.open();
		} else {
			redirectToTrainingCard(card);
		}
  };

  const handleNotifyButtonClicked = async () => {
		if (!showNotificationSent && !sendingNotification && tc.notification_status === 0) {
			sendingNotification = true;
			const res = await sendTrainingCardNotifications(tc);
			if (res.status == 400) {
				console.log('error handleNotifyButtonClicked', res);
				sendingNotification = false;
			} else {
				sendingNotification = false;
				showNotificationSent = true;
				setTimeout(() => {
					setNotificationStatus();
					showNotificationSent = false;
				}, 5000);
			}
		}
	};

	const setNotificationStatus = () => {
		tc.notification_status = 1;	
	}

	const redirectToTrainingCard = (card) => {
	navigate('/training-card?tab=workouts&trainingCardId=' + card.id + '&groupId=' + groupId, {
	  state: { trainingCard: card },
	});
  };
</script>

<style>
	* :global(.primary) {
		color: var(--primary);
	}
	* :global(.op-0) {
		opacity: 0 !important;
		cursor: auto;
	}
	.hide {
		display: none;
	}
	.actions > div {
		width: 35px;
	}
</style>

<Dialog bind:this={confirmationDeleteDialog}>
  <ConfirmationDialog
	text={$_('sei_sicuro_di_voler_eliminare_il_piano')}
	confirmationButtonLabel={$_('elimina')}
	on:closeDialog={() => confirmationDeleteDialog.close()}
	on:onConfirmation={handleDeleteConfirmation} />
</Dialog>

<Dialog bind:this={confirmationEditDialog}>
  <ConfirmationDialog
	text={$_('sei_sicuro_di_voler_modificare_il_piano_gruppi')}
	confirmationButtonLabel={$_('modifica')}
	on:closeDialog={(e) => confirmationEditDialog.close()}
	on:onConfirmation={(e) => {
	  redirectToTrainingCard(cardToBeEdited);
	  confirmationEditDialog.close();
	}} />
</Dialog>

{#if isMobile}
	<div
		class="col-4 col-xs-4 col-sm-3 col-lg-3 justify-content-end pr-0 bg__color flexy bar--icons
						alignContentEnd mobile"
		on:focusout={(e) => {
			if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
			showMobileIcons = undefined;
		}}>
		<div
			class="icon--action"
			class:primary={showMobileIcons === index}
			on:click|stopPropagation={() => (showMobileIcons === index ? handleDeleteButtonClicked(tc.id) : (showMobileIcons = index))}>
			<IconButton class="material-icons p-0" style="width: fit-content">
				{showMobileIcons === index ? 'delete' : 'more_vert'}
			</IconButton>
		</div>
		{#if tc.is_active && !tc.is_draft}
			{#if !sendingNotification}
				<div
					class="icon--action px-0"
					id="notification-icon"
					class:hide={showMobileIcons != index}
					on:click|stopPropagation={() => {handleNotifyButtonClicked(tc);}}>
					<IconButton disabled={tc.notification_status === 1} class="material-icons">
							{showNotificationSent ? `done` : `notifications`}
					</IconButton>
				</div>
			{:else}
				<SvelteTooltip tip="{$_('notifica_in_corso')}" top >
					<LoadingInline />
				</SvelteTooltip>
			{/if}
		{/if}
		<div class="flexy">
			<div
				class="icon--action"
				id="edit-icon"
				class:hide={showMobileIcons != index}
				on:click|stopPropagation={handleEditButtonClicked(tc)}>
				<IconButton
					data-mobile-icon
					class="material-icons p-0 ml-2"
					style="width: fit-content">
					edit
				</IconButton>
			</div>
			<!-- <div
				class:hide={showMobileIcons != index}
				on:click|stopPropagation={handleDuplicateButtonClicked(tc)}>
				<IconButton
					data-mobile-icon
					class="material-icons p-0 ml-3"
					style="width: fit-content">
					content_copy
				</IconButton>
			</div> -->
		</div>
	</div>
{:else}
	<div
		class="col-6 col-xs-6 col-md-3 col-lg-3 flexy alignContentEnd
			desktop">
		<div on:click|stopPropagation={handleDeleteButtonClicked(tc.id)}>
			<SvelteTooltip tip="{$_('elimina')}" top >
				<IconButton class="material-icons primary">delete</IconButton>
			</SvelteTooltip>
		</div>
		<div on:click|stopPropagation={handleNotifyButtonClicked(tc)}>
			{#if tc.is_active && !tc.is_draft}
				{#if !sendingNotification}
					<SvelteTooltip tip="{showNotificationSent || tc.notification_status === 1 ? $_('notifica_inviata') : $_('invia_notifica_al_gruppo')}" top >
						<IconButton disabled={tc.notification_status === 1} class="material-icons">{showNotificationSent ? `done` : `notifications`}</IconButton>
					</SvelteTooltip>
				{:else}
					<div class="px-2">
						<SvelteTooltip tip="{$_('notifica_in_corso')}" top >
							<LoadingInline />
						</SvelteTooltip>
					</div>
				{/if}
			{/if}
		</div>
		<div on:click|stopPropagation={handleEditButtonClicked(tc)}>
			<SvelteTooltip tip="{$_('modifica')}" top >
				<IconButton class="material-icons">edit</IconButton>
			</SvelteTooltip>
		</div>
		<div
			on:click|stopPropagation={handleDuplicateButtonClicked(tc)}>
			<SvelteTooltip tip="{$_('duplica')}" top >
				<IconButton class="material-icons">content_copy</IconButton>
			</SvelteTooltip>
		</div>
	</div>
{/if}
