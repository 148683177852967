<script>
  import { _ } from "../../../../services/i18n";
  import { createCustomer } from "../../../../services/teamServices.js";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import BannerHelp from "../../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label } from "@smui/button";
  import Header from "../../../ReusableLayouts/Header.svelte";
  import InvitationDialog from "./../../InvitationDialog.svelte";
  import NewCustomerImageUploader from "./NewCustomerImageUploader.svelte";
  import NewCustomerInfo from "./NewCustomerInfo.svelte";
  import SubscriptionDialog from "../../../PtInfo/Billing/SubscriptionDialog.svelte";

  export let location;
  export let onLocaleChanged;
  export let profileImage = "";
  export let showNavbar;

  let imageValid = true;
  let msgError;
  let openInvitationDialog = false;
  let redirect = "/team";
  let saving = false;
  let selectedImage = "";
  let showSubscriptionDialog = false;

  let isMobile =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  const handleImageValidity = (e) => {
    imageValid = e.detail.valid;
    if (e.detail.image) selectedImage = e.detail.image;
  };

  const handleCreateCustomer = async (e) => {
    try {
      saving = true;
      const customer = { ...e.detail.customer };
      customer.first_name = customer.name;
      customer.last_name = customer.surname;
      customer.has_chat = customer.has_chat ? 1 : 0;
      delete customer.name;
      delete customer.surname;
      const body = { image: selectedImage, ...customer };
      const res = await createCustomer(body);
      navigate("/customer?tab=workouts", { state: { customerId: res.customer_id } });
      saving = false;
    } catch (err) {
      saving = false;
      console.log("createCustomer err", err);
      msgError = $_("si_è_verificato_un_errore");
      try {
        if (err.message.error == "Non puoi aggiungere un pt o te stesso come cliente" ||
            err.message.error == "You can't add a personal trainer or yourself as a client") {
            msgError = $_("non_puoi_aggiungere_un_pt_o_te_stesso");
        } else if (err.message.error == "Attenzione: questo atleta è già assegnato a te" ||
                   err.message.error == "Warning: this athlete is already assigned to you") {
            msgError = $_("atleta_già_assegnato_a_te");
        } else if (err.message.error == "Attenzione: questo atleta è già assegnato ad un altro PT" ||
                   err.message.error == "Warning: this athlete is already assigned to another PT") {
            msgError = $_("atleta_già_assegnato_ad_altro_pt");
        }
      } catch (error) {
        msgError = $_("si_è_verificato_un_errore");
      }
      setTimeout(() => {
        msgError = "";
      }, 4000);
    }
  };

  const handleOpenInvitationDialog = () => {
    openInvitationDialog = true;
  }

  const handleCloseInvitationDialog = () => {
    openInvitationDialog = false;
  }
  
  const handleShowSubscriptionDialog = () => {
    showSubscriptionDialog = true;
  }

  const handleCloseDialog = () => {
    showSubscriptionDialog = false;
  };
</script>

{#if showSubscriptionDialog}
  <SubscriptionDialog 
    state={'upgrade_plan'}
    trigger={'customers'}
    on:closeDialog={handleCloseDialog} 
  />
{/if}

<style>
  * :global(.customCard) {
    margin: 0 auto;
    text-align: center;
    border: none;
  }
  * :global(.customUploader) {
    height: 200px;
    width: 200px;
    border-radius: 50%;
  }
</style>

<Header
  text={$_('indietro')}
  asTextAndIcon={true}
  asTitle={false}
  tabNavigationEnabled={false}
  tabs={[]}
  activeTab={undefined}
  component={undefined}
  props={{}}
  subTitle={false}
  summary={false}
  sticky={false}
  noBackground={true}
  on:goBack={() => navigate('/team')} />

<div class="container">
  <BannerHelp state='new_customer'/>
</div>

<div class="container fluid mt-4">
  {#if isMobile}
    <div class="d-flex justify-content-center pb-4">
      <div class="d-flex justify-content-end">
        <Button
          color="primary"
          class="newCustomerBtn"
          variant="unelevated"
          on:click={handleOpenInvitationDialog}>
          <Icon
            role="button"
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing buttonIcon">
              vpn_key
          </Icon>
          <Label>{$_('invita_cliente')}</Label>
        </Button>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <span class="text-center pb-4">
        oppure<br>inseriscilo manualmente
      </span>  
    </div>
  {/if}
  <div class="row">
    {#if msgError}
      <div class="col-12 alert alert-danger fade show" role="alert">
        {msgError}
      </div>
    {/if}

    <div class="col-xs-12 col-lg-4">
      <NewCustomerImageUploader
        bind:profileImage
        on:imageValidityChange={handleImageValidity} />
    </div>
    <div class="col-xs-12 col-lg-8 pb-2">
      <NewCustomerInfo 
        {imageValid} 
        {saving} 
        on:createCustomer={handleCreateCustomer} 
        on:showSubscriptionDialog={handleShowSubscriptionDialog} 
      />
    </div>
  </div>
</div>

<InvitationDialog 
  openDialog={openInvitationDialog}
  {redirect}
  on:closeInvitationDialog={handleCloseInvitationDialog} />
