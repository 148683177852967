<script>
  export let absolute = false;
  export let mod = 'default';
</script>

<style>
  .spinner {
    height: 3rem;
    width: 3rem;
    margin: 0 auto;
    color: var(--primary, #cd293d);
  }
  
  .spinner--absolute {
    bottom: 50%;
    color: var(--primary, #cd293d);
    height: 3rem;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
    width: 3rem;
  }
</style>

{#if mod == 'default'}
  <div class="d-flex justify-content-center {absolute ? `` : `my-4 py-4`} w100">
    <div class="spinner-border {absolute ? `spinner--absolute` : `spinner`}" role="status" />
  </div>
{:else if mod == 'exercise'}
  <div class="d-flex justify-content-center ml-lg-4 pl-lg-2 w100">
    <div class="spinner-border {absolute ? `spinner--absolute` : `spinner`}" role="status" />
  </div>
{/if}
