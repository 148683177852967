import { GET, PATCH, POST } from "../lib/http";
import { getTags } from "./exercisesServices";

const commonHeaders = {
  ["Auth"]: "REVOO2019",
  ["Access-Control-Allow-Origin"]: "*",
  ["Accept"]: "application/json",
};

export const updateLanguage = async (body) => {
  const res = await POST("/update_language", {
    body,
    headers: { ...commonHeaders },
  });
  getTags();
  return res;
};

export const checkCorrectPassword = async (body) => {
  const res = await POST("/v2/check_password", {
    body,
    headers: { ...commonHeaders },
  });
  return res;
};

export const updatePassword = async (body) => {
  const res = await POST("/password_update", {
    body,
    headers: { ...commonHeaders },
  });
  return res;
};

export const updatePersonalData = async (body) => {
  const res = await POST("/v2/update_pt_personal_data", {
    body,
    headers: { ...commonHeaders },
  });
  return res;
};

export const updateNotifications = async (body) => {
  const res = await POST("/v2/update_pt_notifications", {
    body,
    headers: { ...commonHeaders },
  });
  return res;
};

export const updateProfileInfo = async (body) => {
  const res = await POST(
    "/v2/update_pt_profile",
    {
      body,
      headers: { ...commonHeaders },
    },
    true,
    "multipart/form-data"
  );
  return res;
};

export const updateChatEnabledForNewCustomers = async (body) => {
  const res = await PATCH("/v2/chat/pt/enable_new_customers",
    {
      body,
      headers: { ...commonHeaders },
    },
    true,
  );
  return res;
};
