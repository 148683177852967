<script>
  import { _, locale } from "../../services/i18n";
  import { activeSection, subscription } from "../../lib/store";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { getFeatureValue, isFeatureEnabled } from "../../services/subscriptionServices";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import Button, { Label } from "@smui/button";
  import SvelteTooltip from 'svelte-tooltip';
  import Switch from "@smui/switch";
  import Textfield, { Input } from "@smui/textfield";
  import VodViewsProgressBar from "./VodViewsProgressBar.svelte";
  import SubscriptionDialog from "../PtInfo/Billing/SubscriptionDialog.svelte";

  export let buttonIcon = "";
  export let buttonInvitationDialog = false;
  export let buttonInvitationIcon = "vpn_key";
  export let buttonInvitationlLabel = "";
  export let buttonLabel;
  export let buttonNavigate = "";
  export let labelNumber = "";
  export let nameTitle = "Header";
  export let noNumber = false;
  export let number = 0;
  export let parseLabel = false;
  export let search = "";
  export let showBaseExercisesFilter;
  export let totalViews = 0;

  const dispatch = createEventDispatcher();

  let doneTypingInterval = 150;
  let selectedSearch = false;
  let showSubscriptionDialog = false;
  let trigger = 'customers';
  let typingTimer;

  // Se è l'header degli esercizi, annulla il setTimeout, perché la ricerca è effettuata dal frontend
  $ : if (nameTitle == $_("esercizi")) 
    doneTypingInterval = 0;

  $: if (showBaseExercisesFilter || !showBaseExercisesFilter) {
    dispatch('showBaseExercises');
  }

  const closeSearchBar = () => {
    search = "";
    selectedSearch = false;
  };

  const configSearch = () => {
    let searchInput = document.getElementById("searchInput");
    if (searchInput) {
      searchInput.addEventListener("keyup", () => {
        delaySearch();
      });
    }
  };

  const delaySearch = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      (_) => dispatch("onComponentEvent", { search }),
      doneTypingInterval
    );
  };

  const handleCloseDialog = () => {
    showSubscriptionDialog = false;
  };

  const handleTopButtonClicked = () => {
    switch (buttonNavigate) {
      case 'new-customer' :
      case '/new-customer' :
        if (isFeatureEnabled('customers', $subscription)) {
          navigate(buttonNavigate);
        } 
        else {
          showSubscriptionDialog = true;
          trigger = 'customers';
        }
        break;
      case 'new-video' :
      case '/new-video' :
        if (isFeatureEnabled('vods', $subscription)) {
          navigate(buttonNavigate);
        } 
        else {
          showSubscriptionDialog = true;
          trigger = 'vods';
        }
        break;
      case 'product' :
      case '/product' :
        if (isFeatureEnabled('revoo_pay', $subscription)) {
          navigate(buttonNavigate);
        } 
        else {
          showSubscriptionDialog = true;
          trigger = 'revoo_pay';
        }
        break;
      default : 
        navigate(buttonNavigate);
    }
  };

  const handleInvitationButtonClicked = () => {

    const maxCustomers = getFeatureValue($subscription, 'max_customers');
    if (
      $subscription.name == 'revoo' && 
      $subscription.status != 'trialing' &&
      buttonNavigate == 'new-customer' && 
      (maxCustomers != null && $subscription.current_customers_count >= parseInt(maxCustomers))
    ) {
      showSubscriptionDialog = true;
    } 
    else {
      dispatch('openInvitationDialog');
    }
  };

  onMount(() => {
    configSearch();
  });

  onDestroy(() => {
    showSubscriptionDialog = false;
  });
</script>

{#if showSubscriptionDialog}
  <SubscriptionDialog 
    state={'upgrade_plan'}
    trigger={trigger}
    on:closeDialog={handleCloseDialog} 
  />
{/if}

{#if !selectedSearch}
  <div
    class={nameTitle == $_('team') && !env.isProd && $locale == 'it' ? 'header__container__team' : 'header__container'}
  >
    {#if window.location.pathname == '/vods'}
      <div class="row no-gutters">
        <div class="d-flex flex-column">
          <div
            class="d-flex flex-column flex-lg-row align-items-lg-center mb-lg-3"
          >
            <h1>{nameTitle}</h1>
            <span class="title__label">
              {number}
              {#if parseLabel}
                {@html labelNumber}
              {:else}{labelNumber}{/if}
            </span>
          </div>
          {#if !$subscription || $subscription.name === 'main'}
            <div class="d-md-flex flex-row align-items-center">
              <SvelteTooltip tip={$_('visualizzazioni_del_tuo_mese_di_abbonamento')} top>
                <div class="mr-3">
                  <VodViewsProgressBar {totalViews} />
                </div>
              </SvelteTooltip>
              <div class="d-none d-md-block self-align-end">
                <SvelteTooltip tip={$_('maggiori_info')} top>
                  <Icon role="button" tabindex="1" data-toggle="tooltip" data-placement="top"
                    class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing"
                    on:click={() => window.open('https://how.revoo-app.com/knowledge/come-funziona-la-sezione-video-su-revoo', '_blank')}
                  >
                    help
                  </Icon>
                </SvelteTooltip>
              </div>
            </div>
          {/if}
        </div>
      </div>
    {:else if window.location.pathname == "/exercises"}
      <div class="row no-gutters">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column flex-lg-row align-items-lg-center mb-lg-3">
            <h1>{nameTitle}</h1>
            <span class="title__label">
              {number}
              {#if parseLabel}
                {@html labelNumber}
              {:else}{labelNumber}{/if}
            </span>
          </div>
          <div class="d-none d-md-flex flex-row align-items-center">
            <div class="mr-3">
              <Switch class="pr-2" bind:checked={showBaseExercisesFilter} />
              <Label>
                {$_('visualizza_la_libreria_di_esercizi_revoo')}
              </Label>
            </div>
          </div>
          <div class="d-sm-flex d-xs-flex d-md-none d-lg-none d-xl-none flex-row align-items-center">
            <div class="mr-3">
              <Switch class="pr-2" bind:checked={showBaseExercisesFilter} />
              <Label>
                {$_('visualizza_la_libreria_di_esercizi_revoo_mobile')}
              </Label>
            </div>
          </div>
        </div>
      </div>
    {:else}
      <div class="row no-gutters centered">
        <h1>{nameTitle}</h1>
        {#if labelNumber != $_('assegnati_ai_gruppi') && !noNumber}
          <span class="title__label">
            {number}
            {#if parseLabel}
              {@html labelNumber}
            {:else}{labelNumber}{/if}
          </span>
        {/if}
      </div>
    {/if}
    {#if labelNumber != $_('assegnati_ai_gruppi')}
      <div
        class="row no-gutters {$activeSection != 'Video' ? `centered` : ``} desktop"
      >
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2">
            <Textfield
              class={`${selectedSearch ? 'search--opened' : ''} ${search.length > 0 ? `search-active` : ``}`}
              variant="outlined"
              withLeadingIcon
              id="searchInput"
              bind:value={search}
              label={$_('cerca_per_nome')}
            >
              <Icon class={`material-icons searchIcon ${selectedSearch ? 'search--opened' : ''}`}>
                search
              </Icon>
            </Textfield>
            <Button
              color="secondary"
              class="navigateButton"
              variant="unelevated"
              on:click={handleTopButtonClicked}
            >
              <Label>
                {#if buttonIcon}
                  <Icon
                    role="button"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing buttonIcon"
                  >
                    {buttonIcon}
                  </Icon>
                {/if}
                {buttonLabel}
              </Label>
            </Button>
          </div>
          {#if buttonInvitationDialog && buttonInvitationlLabel}
            <div class="d-flex justify-content-end">
              <Button
                color={buttonInvitationIcon == 'vpn_key' ? 'primary' : 'secondary'}
                class="newCustomerBtn"
                variant="unelevated"
                on:click={handleInvitationButtonClicked}
              >
                {#if buttonInvitationIcon == 'vpn_key'}
                  <Icon
                    role="button"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing buttonIcon"
                  >
                    {buttonInvitationIcon}
                  </Icon>
                {/if}
                <Label>{buttonInvitationlLabel}</Label>
              </Button>
            </div>
          {/if}
        </div>
      </div>
    {/if}
    {#if labelNumber != $_('assegnati_ai_gruppi')}
      <div class="row no-gutters icons centered mobile">
        <div
          class="mdc-icon-button centered"
          on:click={() => {
            selectedSearch = true;
            configSearch(true);
          }}
        >
          <Icon class="material-icons">search</Icon>
        </div>
        <div
          class="mdc-icon-button centered"
          on:click={handleTopButtonClicked}
        >
          <Icon
            class="material-icons mdc-text-field__icon
              mdc-text-field__icon--trailing buttonIcon {buttonIcon == 'fitness_center' ? `templates` : ``}"
          >
            {buttonIcon}
          </Icon>
        </div>
      </div>
    {/if}
  </div>
  <hr class="mobile" />
{:else}
  <div class="header__container py-2">
    <div class="row no-gutters w100 centered justify-content-center">
      <Textfield
        variant="outlined"
        label={$_('cerca_per_nome')}
        class="w100 search--opened"
        withLeadingIcon
        withTrailingIcon
      >
        <Icon class="material-icons searchIcon search--opened">search</Icon>
        <input
          type="text"
          id="searchInputMobile"
          on:input={delaySearch}
          bind:value={search}
          class="mdc-text-field__input search--opened"
        />
        <Icon
          class="material-icons searchIcon search--opened"
          role="button"
          tabindex="1"
          on:click={() => {
            closeSearchBar();
          }}
        >
          clear
        </Icon>
        <div
          class={`mdc-notched-outline mdc-notched-outline--upgraded search--opened`}>
          <div class="mdc-notched-outline__leading {search.length > 0 ? `mdc-notched-outline__leading--orange` : ``}" />
          <div class="mdc-notched-outline__notch {search.length > 0 ? `mdc-notched-outline__leading--orange` : ``}" />
          <div class="mdc-notched-outline__trailing {search.length > 0 ? `mdc-notched-outline__leading--orange` : ``}" />
        </div>
      </Textfield>
    </div>
  </div>
  <hr class="mobile" />
{/if}

<style>
  * :global(.mdc-button) {
    height: 2.5rem;
  }

  * :global(.mdc-notched-outline__leading--orange) {
    border-color: #ff6700 !important;
    border-width: 3px !important;
  }

  * :global(.search-active > div > div) {
    border-color: #ff6700 !important;
    border-width: 3px !important;
  }

  * :global(.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline.mdc-notched-outline--upgraded.mdc-notched-outline--notched .mdc-notched-outline__leading),
  * :global(.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline.mdc-notched-outline--upgraded.mdc-notched-outline--notched .mdc-notched-outline__notch),
  * :global(.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline.mdc-notched-outline--upgraded.mdc-notched-outline--notched .mdc-notched-outline__trailing) {
    border-color: #ff6700 !important;
  }

  * :global(.mdc-floating-label.mdc-floating-label--float-above) {
    color: #ff6700 !important;
  }
  
  * :global(.navigateButton),
  * :global(.newCustomerBtn) {
    min-width: 10.55rem;
  }
  * :global(.mdc-text-field) {
    height: 2.5rem;
    width: 12.2em;
    margin-bottom: 0 !important;
    margin-right: 0.8em;
    opacity: 0.5;
  }
  * :global(.mdc-text-field:not(.mdc-text-field--disabled)) {
    background-color: #fff;
  }
  * :global(.mdc-text-field__input) {
    height: auto;
    margin: auto;
    padding: 0 0.5em !important;
    width: 14em;
  }
  *
    :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
      .mdc-floating-label) {
    top: 12px;
  }
  * :global(.material-icons.searchIcon) {
    padding: 9px 0px 0px 8px !important;
  }  
  * :global(.default-tip) {
    white-space: normal;
    width: 10em;
    text-align: center;
    line-height: 1.3em;
  }

  /**
   * Switch
   */

   * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }

  /* * :global(.tooltip.right) {
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    margin-bottom: -8px;
  } */

  h1 {
    font-size: 2.1em;
    font-weight: bold;
    margin-right: 0.5em;
    margin-bottom: 0;
  }
  .centered {
    align-items: center;
    align-content: center;
    display: flex;
  }
  .centrated {
    margin: auto;
  }
  .header__container {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;
  }

  .header__container__team {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0 1rem 0;
  }

  .newCustomerBtnContainer {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2rem;
  }
  .title__label {
    color: #212121;
    font-weight: 500;
    opacity: 0.8;
  }
  .hide {
    display: none;
  }
  hr {
    margin: 0;
  }
  .mobile {
    display: none;
  }

  :global(.mdc-switch) {
    height: 12px;
  }

  @media (max-width: 767px) {
    .mobile {
      display: flex;
    }
    .desktop {
      display: none;
    }
    * :global(.mdc-typography--headline4) {
      font-size: 1.5rem;
    }
    * :global(.mdc-text-field.search--opened) {
      margin: 0 auto;
      width: 100%;
    }
    * :global(.mdc-text-field__input.search--opened) {
      margin-left: 0;
    }
    * :global(.material-icons.search--opened) {
      align-self: center;
      margin: 0 0.5em;
      padding: 0 !important;
    }
    * :global(.material-icons) {
      cursor: pointer;
      margin: 0;
      padding: 0 !important;
    }
    * :global(.mdc-button__label > .mdc-text-field__icon--trailing) {
      margin: 0;
    }
    * :global(.material-icons.searchIcon:not(.search--opened)) {
      padding: 0 !important;
    }
    h1 {
      font-size: 1.6rem;
      margin-bottom: 0;
    }
    .header__container {
      padding: 0.25rem 0;
    }

    .header__container__team {
      padding: 0.25rem 0;
    }
    .centered {
      padding-right: 0px;
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 1.25rem;
      margin-bottom: 0;
    }
    .title__label {
      font-size: 0.875rem;
    }
  }
</style>