<script>
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";

  export let playlists = [];
  export let centered = false;
  export let mx = true;
</script>

<style>
  .label--playlists {
    opacity: 1;
    background-color: #ffed4a;
    border-radius: 3px;
  }
  .label--playlists .label {
    opacity: 1;
    font-size: 0.75rem;
    font-weight: 600;
    color: #333333;
    cursor: pointer;
  }
</style>

<div class="playlistLabels 
            d-flex flex-row flex-wrap
            {centered ? `justify-content-center` : `` }">
  {#each playlists as playlist, i}
    <div 
      class="label--playlists px-1 mb-1 d-inline-block
             {mx ? `mx-1` : `mx-0`}
             {centered ? `my-1` : `mr-2` }
             {!centered && i != 1 ? `mr-0` : `mx-1`}"
      on:click={(e) => {
        e.stopPropagation();
        navigate('/vods?tab=playlist'); // To add playlist.id detail
      }}>
      <span class="label">
        {playlist.name}
      </span>
    </div>
  {/each}
</div>
