<script>
  import { _ } from "svelte-i18n";
  import { Icon } from "@smui/common";
  import Textfield from "@smui/textfield";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";

  export let item;
  export let body;
  export let errors;
  export let show;
</script>

<div class="row no-gutters mb-3">
  <Textfield
    variant="outlined"
    bind:value={body[item.value]}
    bind:invalid={errors[item.value]}
    withTrailingIcon
    type={show[item.value] || item.value == 'email' ? 'text' : 'password'}
    label={`${$_(item.label)} *`}
    class={`textbox w100 my-2 ${errors[item.value]} ? 'mdc-text-field--invalid' : ''}`}>
    {#if item.value != 'email'}
      <Icon
        on:click={() => {
          show[item.value] = !show[item.value];
        }}
        role="button"
        tabindex="1"
        class="material-icons mdc-text-field__icon
          mdc-text-field__icon--trailing">
        {show[item.value] ? 'visibility_off' : 'visibility'}
      </Icon>
    {/if}
  </Textfield>
  {#if errors[item.value]}
    <FormMessage>{$_(item.errorMsg)}</FormMessage>
  {/if}
</div>
