export const checkHistoryValidity = (history) => {
  const bool =
    history.asthma ||
    history.problems_cardiac ||
    history.problems_thyroid ||
    history.contraceptive_pill ||
    history.diabetes ||
    history.workout_location && history.workout_location.length > 0;
  const values =
    history.weight ||
    history.height ||
    history.blood_group ||
    history.job ||
    history.workout_freq ||
    history.mmhg ||
    history.last_revelation ||
    history.supplements ||
    history.supply ||
    history.surgical ||
    history.postural ||
    history.irregular_periods ||
    history.intolerance ||
    history.allergies ||
    history.fractures ||
    history.drugs ||
    history.other_pathologies ||
    history.intestinal_irregularity ||
    history.disorders ||
    history.sport ||
    history.lifestyle ||
    history.goal ||
    history.goal_description;
  return bool || values;
};
