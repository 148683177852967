import { derived } from "svelte/store";
import { dictionary, locale, _ } from "svelte-i18n";
import locales from "./lang/locales.js";

let cachedLocale;

async function setupI18n({ withLocale: _locale } = { withLocale: "it" }) {
  dictionary.set({ [_locale]: locales[_locale] });
  cachedLocale = _locale;
  locale.set(_locale);
}

function formatDate(date, options) {
  return new Intl.DateTimeFormat(cachedLocale, options).format(new Date(date));
}

const isLocaleLoaded = derived(
  locale,
  ($locale) => typeof $locale === "string"
);

export { _, locale, setupI18n, formatDate, isLocaleLoaded };
