<script>
  import { _ } from "../../services/i18n";
  import { addEvent, deleteEvent, generateXtraLinks } from "../../services/calendar.js";
  import { appointmentDefault } from "../../lib/mockupCalendarData";
  import { beforeUpdate, createEventDispatcher, tick } from "svelte";
  import { convertDataFromBE, handleDataForBE } from "./calendarUtils";
  import { get } from "svelte/store";
  import AppointmentContent from "./InnerComponents/AppointmentContent.svelte";
  import Button, { Label } from "@smui/button";
  import ConfirmDialog from "./ConfirmDialog.svelte";
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import IconButton from "@smui/icon-button";

  export let openDialog;
  export let selectData;
  export let dataFromBe;

  let appointment = {};
  appointment = Object.assign(appointment, get(appointmentDefault));
  let automaticReopen = false;
  let clickOnClose = false;
  let closedAppointmentDialog = false;
  let confirmationDialog = false;
  let confirmationDialogText = "";
  let createOnCustomer = false;
  let dialog;
  let generatingXtraLinks = false;
  let onEdit = false;
  let quitSaving = false;
  let whoCallConfirmDialog;
  let waitingForXtravisionLinks = false;

  beforeUpdate(() => {
    if (dataFromBe && dataFromBe.type == 0 && !onEdit) {
      appointment = convertDataFromBE(dataFromBe);
      onEdit = true;
    }
    if (selectData) {
      appointment.startDate = selectData.start;
      appointment.startHour = selectData.startTime;
      appointment.endDate = selectData.end;
      appointment.endHour = selectData.endTime;
      if (selectData.hasOwnProperty("customers")) {
        createOnCustomer = true;
        appointment.customers = selectData.customers;
      }
    }
  });

  const dispatch = createEventDispatcher();

  $: quitSaving = appointment.title ? false : true;

  $: if (appointment.site == 3) {
    waitingForXtravisionLinks = appointment.xtravision_schedule_id === undefined || appointment.xtravision_schedule_id === "" 
      ? true
      : false;
  } else {
    waitingForXtravisionLinks = false;
  }

  $: {
    if (openDialog == true || automaticReopen) {
      dialog.open();
      automaticReopen = false;
      clickOnClose = false;
    }
  }

  const cleanAppointment = async () => {
    appointment = Object.assign(appointment, get(appointmentDefault));
    delete appointment.first_email_sent;
    delete appointment.send_email;
    delete appointment.xtravision_customers_class_link;
    delete appointment.xtravision_trainer_class_link;
    delete appointment.xtravision_schedule_id;
    generatingXtraLinks = false;
    await tick();
    closedAppointmentDialog = true;
  };

  const handleDialogClose = async () => {
    if (createOnCustomer) createOnCustomer = false;
    if (dialog && dialog.isOpen()) {
      dialog.close();
      clickOnClose = true;
      dispatch("closeInvitationDialog", { who: "dialogOpen" });
    }
    if (!confirmationDialog) {
      if (onEdit) {
        dataFromBe = undefined;
        onEdit = false;
      }
      if (appointment.hasOwnProperty("id")) delete appointment.id;
      await cleanAppointment();

      if (!clickOnClose)
        dispatch("closeInvitationDialog", { who: "clickOnClose" });
    }
  };

  const deleteAppointment = async () => {
    await deleteEvent(appointment.id);
    dispatch("eventDeleted");
    handleDialogClose();
  };

  const saveProgressionsAndExit = async (value) => {
    const appointmentBE = handleDataForBE(appointment, onEdit);
    // console.log("saveProgressAndExit", appointmentBE);
    try {
      let result = await addEvent(appointmentBE);
      dispatch("eventCreated");
      // console.log("all ok", result);
    } catch (error) {
      console.log("error BE", error);
    }
    if (value == "callDialogClose") handleDialogClose();
  };

  const handleCallDialog = (whoCall) => {
    if (whoCall == "invitation") {
      if (
        appointment.send_email_update == 1 &&
        (appointment.at_groups.length > 0 || appointment.customers.length > 0)
      ) {
        // apri dialog di conferma invio email solo se
        // sto creando un nuovo appuntamento quindi non sono in modifica !onEdit
        // sono in modifica e la prima email non è stata ancora inviata
        if (
          !onEdit ||
          (appointment.hasOwnProperty("first_email_sent") &&
            appointment.first_email_sent == false)
        ) {
          whoCallConfirmDialog = whoCall;
          confirmationDialog = true;
          confirmationDialogText = $_(
            "sei_sicuro_di_voler_confermare_invio_email"
          );
        } else if (onEdit) {
          saveProgressionsAndExit();
        }
      } else saveProgressionsAndExit();
    }
    if (whoCall == "delete") {
      whoCallConfirmDialog = whoCall;
      confirmationDialog = true;
      confirmationDialogText = $_("sei_sicuro_di_voler_eliminare_l_evento");
    }
  };

  const handleConfirmDialog = (response) => {
    if (response && whoCallConfirmDialog == "delete") {
      deleteAppointment();
    }
    if (!response && whoCallConfirmDialog == "delete") {
      confirmationDialog = false;
      handleDialogClose();
    }
    if (response && whoCallConfirmDialog == "invitation") {
      saveProgressionsAndExit("callDialogClose");
    }
    if (!response && whoCallConfirmDialog == "invitation") {
      automaticReopen = true;
    }
  };

  const handleGenerateXtraLinks = async (e) => {
    generatingXtraLinks = true;
    const appointmentBE = handleDataForBE(appointment, onEdit);
    const res = await generateXtraLinks(appointmentBE);
    setTimeout(() => { 
      appointment.xtravision_customers_class_link = res.xtravision_customers_class_link;
      appointment.xtravision_trainer_class_link = res.xtravision_trainer_class_link;
      appointment.xtravision_schedule_id = res.scheduleId;
      generatingXtraLinks = false; 
    }, 1000);
  }
</script>

<ConfirmDialog
  bind:openDialog={confirmationDialog}
  bind:text={confirmationDialogText}
  on:onCancel={() => handleConfirmDialog(false)}
  on:onConfirm={() => handleConfirmDialog(true)}
/>

<div>
  <Dialog
    class="dialog--xsmall appointmentDialog"
    bind:this={dialog}
    on:MDCDialog:closed={() => handleDialogClose()}
  >
    <Title class="d-flex">
      <h5 class="bold pt-3" style="flex-grow: 1;">
        {#if dataFromBe}
          {$_('modifica_appuntamento')}
        {:else}{$_('crea_appuntamento')}{/if}
      </h5>
      <IconButton class="material-icons" on:click={() => handleDialogClose()}>
        close
      </IconButton>
    </Title>
    <Content>
      <AppointmentContent
        bind:appointment
        bind:closedAppointmentDialog
        bind:confirmationDialog
        bind:createOnCustomer
        bind:onEdit
        bind:openDialog
        {generatingXtraLinks}
        on:generateXtraLinks={handleGenerateXtraLinks}
      />
    </Content>
    <Actions>
      <div class="row-grid-auto mt-0 mt-sm-3">
        <div class="col-12 col-sm-8 px-0 px-sm-3">
          {#if dataFromBe}
            <Button
              class="btn"
              variant="outlined"
              color="secondary"
              disabled={quitSaving}
              on:click={() => handleCallDialog('delete')}
            >
              <Label><span>{$_('cancella')}</span></Label>
            </Button>
          {/if}
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <Button
            class="btn d-sm-inline-flex annulla-mobile {dataFromBe ? 'd-none' : 'd-inline-flex'}"
            on:click={() => handleDialogClose()}
          >
            <Label>{$_('annulla')}</Label>
          </Button>
          <Button
            class="btn mx-0 ml-sm-5 mr-sm-3 button--bigger"
            variant="unelevated"
            disabled={quitSaving || waitingForXtravisionLinks}
            on:click={() => handleCallDialog('invitation')}
          >
            <Label style="white-space:nowrap">
              <span>{$_('salva_ed_esci')}</span>
            </Label>
          </Button>
        </div>
      </div>
    </Actions>
  </Dialog>
</div>

<style>
  h5 {
    padding: 0;
  }
  .row-grid-auto {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    width: 100%;
    justify-items: flex-end;
  }

  * :global(.material-icons) {
    font-size: 1.8rem;
  }
  * :global(.material-icons--xtra) {
    font-size: 1rem;
  }
  * :global(.mdc-dialog.appointmentDialog .mdc-dialog__surface),
  * :global(.mdc-dialog.appointmentDialog .mdc-dialog__container) {
    max-width: 80vw;
    width: 900px !important;
  }
  * :global(.mdc-dialog__title + .mdc-dialog__content) {
    padding: 24px 64px;
  }
  * :global(.mdc-dialog__title) {
    display: flex;
    padding: 10px 40px;
    background-color: #f9f9f9;
    opacity: 0.8;
  }
  * :global(.mdc-dialog__actions) {
    padding: 24px 64px;
  }
  * :global(.mdc-text-field__icon--trailing.customLabel) {
    color: #212121;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    opacity: 0.5;
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }

  :global(.mdc-menu-surface--open) {
    z-index: 10000 !important;
  }

  * :global(.spinner) {
    margin-left: 5px !important;
  }

  @media (max-width: 991px) {
    * :global(.dialog--xxsmall),
    * :global(.dialog--xsmall),
    * :global(.dialog--small),
    * :global(.dialog--medium),
    * :global(.dialog--big),
    * :global(.dialog--bigger),
    * :global(.mdc-dialog__container),
    * :global(.mdc-dialog__surface) {
      min-height: 100% !important;
    }
  }

  @media (max-width: 767px) {
    * :global(.mdc-dialog__content) {
      max-height: fit-content;
      flex-grow: 0;
      padding: 24px !important;
    }
    * :global(.mdc-dialog__actions) {
      padding: 24px !important;
    }
  }

  @media (max-width: 576px) {
    * :global(.mdc-dialog__title) {
      padding: 10px 24px;
    }
    * :global(.annulla-mobile) {
      position: absolute;
      left: 24px;
    }
  }
</style>
