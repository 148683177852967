<script>
    import { locale, _ } from "../../services/i18n";
    import {
        user,
        customer,
        allCustomers,
        allGroups,
    } from "../../lib/store";
    import { onMount, tick } from "svelte";
    import { Icon } from "@smui/common";
    import Loading from "../ReusableLayouts/Loading.svelte";
    import FullCalendar from "svelte-fullcalendar";
    import listPlugin from "@fullcalendar/list";
    import itLocale from "@fullcalendar/core/locales/it";
    import Button, { Label } from "@smui/button";
    import AppointmentDialog from "./AppointmentDialog.svelte";
    import moment from "moment";
    import {
        getCustomersList,
        getEvent,
        getGroupsList,
    } from "../../services/calendar";
    import Events from "./Events.svelte";
    import ReminderDialog from "./ReminderDialog.svelte";
    import SelectEvent from "./SelectEvent.svelte";

    // export let location = {};
    export let showNavbar = true;
    export let navbarDisabled = false;

    let loading = true;
    let reminderDialog = false;
    let appointmentDialog = false;
    let msgError;
    let selectData;
    let clickData;
    let selectDialog = false;
    let events;
    let updateEvents = false;
    let dataFromBe;
    let qs = { search: "", customer: "" };

    $: optionsFullCalendar = {
        headerToolbar: "",
        initialView: "listYear",
        views: {
            listYear: {
                type: 'list',
                duration: { days: 720 },
            }
        },
        locale: $locale,
        locales: [itLocale],
        plugins: [listPlugin],
        selectable: true,
        events,
        eventTimeFormat: {
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
        },
        eventContent: (arg) => {
            if (arg.view.type == "listWeek") {
                const props = Object.entries(arg.event.extendedProps).map(
                    (el) => el[1]
                );
                let names = "";
                props.forEach((el, i) => {
                    //last attributes is types so remome one element from props lenght
                    if (i + 1 != props.length) {
                        if (i + 1 < props.length - 1) names += `${el}, `;
                        else names += el;
                    }
                });
                return {
                    html: `<span>${arg.event.title}</span>
                    <br>
                    <span style="font-size:14px">${names}</span>`,
                };
            }
        },
        select: function (info) {
            console.log("info", info);
            selectData.start = moment(info.startStr).format("YYYY-MM-DD");
            selectData.startTime = moment(info.startStr).format("HH:mm");
            selectData.end = moment(info.endStr).format("YYYY-MM-DD");
            selectData.endTime = moment(info.startStr).format("HH:mm");
            selectDialog = true;
        },
        eventClick: async (info) => {
            dataFromBe = await getEvent(info.event.id);
            // console.log("dataFromBE", dataFromBe);
            if (dataFromBe.type == 0) handleAppointment();
            else handleReminder();
        },
    };

    const displayError = () => {
        msgError = $_("si_è_verificato_un_errore");
        setTimeout(() => {
            msgError = "";
        }, 3000);
    };

    const handleReminder = async (value) => {
        if (value == "btn") setSelectDataOnClick();
        reminderDialog = reminderDialog ? false : true;
        await tick();
        selectData = false;
    };

    const handleAppointment = async (value) => {
        if (value == "btn") setSelectDataOnClick();
        appointmentDialog = appointmentDialog ? false : true;
        await tick();
        selectData = false;
    };

    const setSelectDataOnClick = () => {
        selectData = {
            start: moment().format("YYYY-MM-DD"),
            startTime: moment().format("HH:mm"),
            end: moment().format("YYYY-MM-DD"),
            endTime: moment().add(30, "minutes").format("HH:mm"),
            customers: [
                {
                    customer_id: $customer.customer_id,
                    first_name: $customer.first_name,
                    last_name: $customer.last_name,
                    customer_email: $customer.email,
                },
            ],
        };
    };

    const getGroups = async () => {
        const filtratedGroups = await getGroupsList();
        allGroups.set(filtratedGroups);
    };

    const getCustomersBE = async () => {
        const filtratedCustomers = await getCustomersList();
        allCustomers.set(filtratedCustomers);
    };

    onMount(async () => {
        qs.customer = new URLSearchParams(location.search).get("customerId");
        // qs.year = today.format("YYYY");
        // qs.month = today.format("M");
        // qs.day = today.format("D");
        // qs.search = $customer.email;
        try {
            await getGroups();
            await getCustomersBE();
            updateEvents = true;
            loading = false;
        } catch (error) {
            loading = false;
            console.log("getCustomersInfo error", error);
            displayError();
        }
    });
    // onMount(async () => {
    //     qs.customer = new URLSearchParams(location.search).get("customerId");
    //     // qs.year = today.format("YYYY");
    //     // qs.month = today.format("M");
    //     // qs.day = today.format("D");
    //     // qs.search = $customer.email;
    //     try {
    //         loading = false;
    //         await getGroups();
    //         await getCustomersBE();
    //     } catch (error) {
    //         loading = false;
    //         console.log("getCustomersInfo error", error);
    //         displayError();
    //     }
    // });
</script>

{#if loading}
    <Loading />
{:else}
    <div class="header-calendar">
        <div class="headline bold">
            {$_('appuntamenti_e_promemoria_prenotati')}
        </div>
    
        <Button
            color="secondary"
            class="navigateButton"
            variant="unelevated"
            on:click={() => (selectDialog = true)}
        >
            <Icon
                role="button"
                class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing buttonIcon"
            >
                insert_invitation
            </Icon>
            <Label>{$_('nuovo')}</Label>
        </Button>
    </div>

    <div class="container p-0 fluid desktop">
        <FullCalendar bind:options={optionsFullCalendar} />
    </div>

    <SelectEvent
        bind:openDialog={selectDialog}
        on:closeSelectDialog={() => (selectDialog = false)}
        on:appointment={() => handleAppointment('btn')}
        on:reminder={() => handleReminder('btn')}
    />

    <AppointmentDialog
        bind:openDialog={appointmentDialog}
        bind:selectData
        bind:dataFromBe
        on:closeInvitationDialog={() => handleAppointment()}
        on:eventCreated={() => (updateEvents = true)}
        on:eventDeleted={() => (updateEvents = true)}
    />

    <ReminderDialog
        bind:openDialog={reminderDialog}
        bind:selectData
        bind:dataFromBe
        on:closeInvitationDialog={() => handleReminder()}
        on:eventCreated={() => (updateEvents = true)}
        on:eventDeleted={() => (updateEvents = true)}
    />

    <Events bind:events bind:qs bind:updateEvents />
{/if}

<style>
    * :global(.fc a:hover) {
        color: var(--mdc-theme-primary, #cd293d);
        text-decoration: unset;
        cursor: default;
    }
    .headline {
        font-size: 1.25rem;
    }
    .header-calendar {
        padding: 2rem 0px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    @media (max-width: 767px) {
        :global(.headerBackground) {
            position: -webkit-sticky;
            position: sticky;
            top: 3.4rem;
            z-index: 2;
        }
    }
</style>
