<script>
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";
  import { createEventDispatcher, tick } from "svelte";
  import { jwt, user, customer, exercises } from "../../lib/store";
  import { cometChatLogout } from "../../services/chatServices.js";
  import List, { Item, Separator, Text, Graphic } from "@smui/list";
  
  export let navbarTabsDisabled = false;

  const dispatch = createEventDispatcher();

  let path = "";
</script>

<List class="menuListItems">
  {#if $user.is_admin && !navbarTabsDisabled}
    <Item
      on:SMUI:action={() => {
        navigate('admin');
      }}>
      <Graphic class="material-icons">admin_panel_settings</Graphic>
      <Text>{$_('admin')}</Text>
    </Item>
  {/if}
  {#if !navbarTabsDisabled}
    <Item
      on:SMUI:action={() => {
        path = "pt-info?tab=profile"
        if (window.location.pathname == "/training-card" || window.location.pathname == "/new-training-card") {
          dispatch("openExitDialog", { path });
        } else {
          navigate(path);
        }
      }}>
      <Graphic class="material-icons">settings</Graphic>
      <Text>{$_('impostazioni')}</Text>
    </Item>
  {/if}
  <Item
    on:click={() => {
      window.open('https://how.revoo-app.com/knowledge', '_blank');
    }}>
    <Graphic class="material-icons">help</Graphic>
    <Text>{$_('help')}</Text>
  </Item>
  {#if ($user.is_alpha_tester)}
    <Item
      on:click={() => {
        navigate("/products");
      }}>
      <Graphic class="material-icons">inventory_2</Graphic>
      <Text>{$_('prodotti')}</Text>
    </Item>
  {/if}
  {#if (window.location.host != "trainmobile.revoo-app.com")}
    <Item
      on:click={() => window.open('http://meet.revoo-app.com/meetings/davide-p/presentazione-team-revoo', '_blank')}>
      <Graphic class="material-icons">ondemand_video</Graphic>
      <Text>{$_('richiedi_webinar')}</Text>
    </Item>
  {/if}
  <Separator />
  <Item
    on:SMUI:action={async () => {
      jwt.set('');
      customer.set({});
      exercises.set([]);
      window.localStorage.clear();
      await cometChatLogout();
      await tick();
      navigate('/login');
      window.location.reload();
    }}>
    <Graphic class="material-icons">exit_to_app</Graphic>
    <Text>{$_('logout')}</Text>
  </Item>
</List>
