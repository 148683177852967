<script>
	import { _ } from "../../../../services/i18n";
	import CustomerSubscriptionDeadline from "./CustomerSubscriptionDeadline.svelte";
	import moment from "moment";

	export let customerSubscription;
	export let isMobile;

	const getDuration = (duration, type) => {
		switch(type) {
			case 0  : return $_("giorni", 	 { values : { giorni    : duration }, });
			case 1  : return $_("settimane", { values : { settimane : duration }, });
			case 2  : return $_("mesi", 	 { values : { mesi		: duration }, });
			default : return "-";
		}
	};
</script>

<style>
	.label {
		opacity: 0.5;
		font-size: 0.75rem;
		color: black;
	}
	.op-8 {
		opacity: 0.8;
	}
	.op-2 {
		opacity: 0.2 !important;
	}
	.tc-start {
		min-width: 89px;
	}

	* :global(.playButtonWrapper) {
	position: absolute;
	opacity: 0;
  }

  * :global(.playButtonWrapper:hover), :global(.playButtonBackground:hover) {
	opacity: 1;
  }

  * :global(.playButtonWrapper > .mdc-icon-button) {
	width: 57px;
	height: 57px;
	font-size: 36px;
  }

  * :global(.playButtonBackground) {
	position: absolute;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background-color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

	* :global(.imagevod) {
		border-radius: 7px;
		height: 80px;
		width: 80px;
		align-items: center;
		object-fit: cover;
		max-width: inherit;
	}

	* :global(.imageContainer) {
		height: 80px;
		width: 80px;
	}
</style>

<div class="d-flex flex-column align-items-start justify-content-center col-3 col-md-3 col-lg-2" >
	<div class="d-flex align-items-center">
		<span>
			{customerSubscription.name}
		</span>
	</div>
</div>
<div class="col-3 col-md-2 px-1 text-center">
	<CustomerSubscriptionDeadline {customerSubscription} />
</div>
<div class="col-md-2 text-center px-0 d-none d-lg-block tc-start">
	<div class="col-12 label px-0">{$_('inizio')}</div>
	<div class="col-12 px-0">
		{customerSubscription.start ? moment(customerSubscription.start).format('DD/MM/YYYY') : '-'}
	</div>
</div>
<div class="col-md-3 col-lg-2 text-center d-none d-md-block">
	<div class="col-12 label">{$_('durata')}</div>
	<div class="col-12 px-0 px-lg-1">{getDuration(customerSubscription.duration, customerSubscription.duration_type)}</div>
</div>
<div class="col-md-2 text-center d-none d-md-block">
	<div class="col-12 px-0 px-lg-1 label">{customerSubscription.paid ? $_('pagato') : $_('da_pagare')}</div>
	<div class="col-12 px-0 px-lg-1">€ {(Math.round(customerSubscription.price * 100) / 100).toFixed(2)}</div>
</div>
