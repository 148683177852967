<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher } from "svelte";
  import { customer, vodsQuantity } from "../../../../lib/store";
  import { getSubscriptionsNote, setSubscriptionsNote } from "../../../../services/customerServices";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import BannerHelp from "../../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label } from "@smui/button";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import CustomerSubscriptionsList from "./CustomerSubscriptionsList.svelte";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import NoItemsToShow from "../../../ReusableLayouts/NoItemsToShow.svelte";
  import Textfield from "@smui/textfield";
  
  export let customerId = null;
  export let firstCustomerSubscriptions = [];
  export let loadingSubscriptions = true;
  export let nextCustomerSubscriptions = [];

  const dispatch = createEventDispatcher();

  let note = '';
  let noteId = '';
  let noteLoaded = false;
  let msgError;
  let previousNote = '';
  let typingNote = false;

  $: if (customerId) {
    getNoteData();
  }

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const getNoteData = async () => {
    if (noteLoaded === false) {
      try {
        if (customerId) {
          const res = await getSubscriptionsNote(customerId);
          if (res.description && res.description != '') {
            note = res.description;
            noteId = res.id;
            previousSubscriptionNote = note != null ? note.slice() : null;
            noteLoaded = true;
          }
        }
      } catch (error) {
        if (error.status == 404) {
          console.log('No note');
        }
      }
    }
  }

  $: if (noteLoaded === true) {
    setTimeout(() => {
      fixTextareaHeight();
    }, 100);
  }

  const setNoteData = async () => {
    try {
      const res = await setSubscriptionsNote(customerId, note, noteId);
      if (res) {
        note = res.description != null ? res.description : '';
        previousNote = note != null ? note.slice() : '';
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const fixTextareaHeight = () => {
    const tx = document.getElementById("noteTextarea");
    if (tx) {
      tx.setAttribute("style", "height:" + (tx.scrollHeight) + "px;overflow-y:hidden;");
      tx.addEventListener("input", OnInput, false);
    }
    function OnInput() {
      this.style.height = "auto";
      this.style.height = (this.scrollHeight) + "px";
    }
  }

  const addCustomerSubscriptionsCards = () => {
    // display 3 more cards
    const customerSubscriptionsToBeDisplayed = nextCustomerSubscriptions.slice(0, 5);
    firstCustomerSubscriptions = [...firstCustomerSubscriptions, ...customerSubscriptionsToBeDisplayed];
    nextCustomerSubscriptions = nextCustomerSubscriptions.slice(5);
  };

  const handleRemoveCustomerSubscription = (e) => {
    console.log(e.detail);
    if (e.detail.customerSubscription.id) {
      firstCustomerSubscriptions = firstCustomerSubscriptions.filter(i => i.id != e.detail.customerSubscription.id);
    }
  };

  const handleUpdateCustomerSubscriptions = () => {
    dispatch('updateCustomerSubscriptions');
  }
</script>

<style>
  * :global(.embed-responsive-item) {
    background-color: #000;
  }
  .headline {
    font-size: 1.25rem;
  }
  .rowSpace {
    padding: 2rem 0rem;
  }
  .pushToEnd {
    display: flex;
    justify-content: flex-end;
  }
  * :global(.saveBtn) {
    background: #cd293d !important;
    color: #fff !important;
    border: 0 !important;
  }
  * :global(.mdc-text-field.noteDescription) {
    height: auto;
    width: 100% !important;
  }
  * :global(.mdc-text-field--disabled .mdc-text-field__input) {
    color: black !important;
  }
</style>

<div class="container fluid">
  <MsgError {msgError} />

  {#if firstCustomerSubscriptions && firstCustomerSubscriptions.length < 2}
    <div class="container my-3">
      <BannerHelp state='customer_subscriptions'/>
    </div>
  {/if}

  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('note_abbonamento')}</div>
          <div class="d-flex justify-content-end">
            {#if !typingNote}
              <Button
                color="secondary"
                variant="outlined"
                class={$customer.active ? `` : `action-disabled`}
                disabled={!$customer.active}
                on:click={() => {
                  typingNote = true;
                }}>
                <Label>
                  <Icon
                    role="button"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing">
                    edit
                  </Icon>
                  {note != null ? $_('modifica') : $_('aggiungi')}
                </Label>
              </Button>
            {:else}
              <Button
                color="secondary"
                variant="outlined"
                class={$customer.active ? `saveBtn` : `action-disabled`}
                disabled={!$customer.active}
                on:click={() => {
                  typingNote = false;
                  setNoteData();
                }}>
                <Label>
                  <Icon
                    role="button"
                    class="material-icons mdc-text-field__icon
                      mdc-text-field__icon--trailing">
                    save
                  </Icon>
                  {$_('salva')}
                </Label>
              </Button>
              <Button 
                class="btn undoBtn ml-2"
                on:click={() => {
                  typingNote = false;
                  previousNote = note != null ? note.slice() : null;
              }}>
                <Label>{$_('annulla')}</Label>
              </Button>
            {/if}
          </div>
          <div class="spaceBetween">
            <Textfield
              textarea
              input$id="noteTextarea"
              input$maxlength="1000"
              class="fullWidth my-2 noteDescription"
              bind:value={note}
              disabled={!typingNote}
              label="">
              <CharacterCounter>{note != null && note != '' ? `${note.length} / 1000` : `0 / 1000`}</CharacterCounter>
            </Textfield>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="pushToEnd my-3">
          <Button
            class="ml-4"
            color="secondary"
            variant="unelevated"
            on:click={() => navigate('/customer-subscription', {
              state: { customerId },
            })}>
            <Label>
              <Icon
                role="button"
                class="material-icons mdc-text-field__icon
                  mdc-text-field__icon--trailing">
                loyalty
              </Icon>
              {$_('nuovo_abbonamento')}
            </Label>
          </Button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('abbonamenti')}</div>
        </div>
      </div>
    </div>
  </div>
  {#if loadingSubscriptions}
    <Loading />
  {:else}
    {#if firstCustomerSubscriptions && firstCustomerSubscriptions.length > 0}
      <CustomerSubscriptionsList
        {firstCustomerSubscriptions}
        {customerId}
        on:onError={displayError}
        on:removeCustomerSubscription={handleRemoveCustomerSubscription}
        on:updateCustomerSubscriptions={handleUpdateCustomerSubscriptions} />
      <div class="flexy my-3">
        <Button
          color="secondary"
          variant="outlined"
          disabled={nextCustomerSubscriptions.length == 0}
          on:click={addCustomerSubscriptionsCards}>
          <Label>{$_('mostra_altro')}</Label>
        </Button>
      </div>
    {:else}
      <NoItemsToShow
        upperText={$_('nessun_abbonamento')}
        biggerText={`${$_('aggiungi_un_abbonamento_per')} ${$customer.first_name}`}
        icon="loyalty"
        buttonText={$_('aggiungi_abbonamento')}
        pathToNavigateTo="customer-subscription"
        routeState={{ state: { customerId } }} />
    {/if}
  {/if}
</div>

