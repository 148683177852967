<script>
  import { createEventDispatcher } from "svelte";
  import Button, { Label } from "@smui/button";
  import Dialog from "@smui/dialog";
  import Switch from "@smui/switch";
  import Textfield from "@smui/textfield";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";

  const dispatch = createEventDispatcher();

  export let user = {};

  let disableCustomersDialog;
  let formValid = false;
  $: formValid = user.first_name && user.last_name && user.email;

  const submit = () => {
    dispatch("save", {
      user,
    });
  };
</script>

<style>
  * :global(.fullWidth) {
    width: 100% !important;
  }
  * :global(.mdc-dialog .mdc-dialog__scrim) {
    background-color: rgba(0, 0, 0, 0.52) !important;
  }
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
  }
</style>

<Dialog
  bind:this={disableCustomersDialog}
  aria-labelledby="delete-confirmation-dialog"
  aria-describedby="delete-confirmation-dialog"
  class="confirmation">
  <ConfirmationDialog
    text="Sei sicuro di voler disattivare tutti i clienti di {user.first_name + ' ' + user.last_name}? (Azione irreversibile)"
    confirmationButtonLabel="Conferma"
    on:closeDialog={() => disableCustomersDialog.close()}
    on:onConfirmation={() => {
      dispatch('disableCustomers', {user_id: user.id})
      disableCustomersDialog.close();
    }} />
</Dialog>

<div class="row mx-0 spaceBetween px-2">
  <div class="col-6">
    <Textfield class="w100 my-2" bind:value={user.first_name} label="Nome" />
  </div>
  <div class="col-6">
    <Textfield class="w100 my-2" bind:value={user.last_name} label="Cognome" />
  </div>
  <div class="col-6">
    <Textfield class="w100 my-2" bind:value={user.email} label="E-mail" />
  </div>
  <div class="col-6">
    <Textfield
      class="w100 my-2"
      bind:value={user.details.phone}
      label="Numero di cellulare" />
  </div>
  <div class="col-12 py-3">
    <Switch bind:checked={user.is_pt} />
    <span class="pl-2">Personal Trainer</span>
    <br/>
    <Switch bind:checked={user.is_customer} />
    <span class="pl-2">Customer</span>
    <br/>
    <br/>
    <Switch bind:checked={user.is_admin} />
    <span class="pl-2">Admin</span>
    <br/>
    <Switch bind:checked={user.is_tester} />
    <span class="pl-2">Tester</span>
    <br/>
    <Switch bind:checked={user.is_alpha_tester} />
    <span class="pl-2">Alpha Tester</span>
    <br/>
    <Switch bind:checked={user.is_beta_tester} />
    <span class="pl-2">Beta Tester</span>
    <br/>
    <Switch bind:checked={user.is_banned} />
    <span class="pl-2">Banned</span>
  </div>
</div>

<div class="row mx-0 spaceBetween py-4">
  <Button
    class="btn"
    variant="unelevated"
    on:click={() => dispatch('cancel', {})}>
    <Label>Chiudi</Label>
  </Button>
  {#if user.is_pt}
    <Button
      class="btn"
      variant="unelevated"
      on:click={() => disableCustomersDialog.open()}>
      <Label>Disabilita Clienti</Label>
    </Button>
  {/if}
  <Button
    disabled={!formValid}
    class="btn"
    variant="unelevated"
    color="secondary"
    on:click={submit}>
    <Label>Salva</Label>
  </Button>
</div>
