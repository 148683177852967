<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { _ } from "svelte-i18n";
  import { navigate } from "svelte-routing";

  import Checkbox from "@smui/checkbox";
  import Dialog from "@smui/dialog";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import AssignPlaylistDialog from "./Playlist/AssignPlaylistDialog.svelte";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";

  export let filtratedPlaylist = {};
  export let isMobile;
  export let index;
  export let panels = [];
  
  let showMobileIcons;
  let assignmentDialog;
  let assignmentDialogClicked = false;
  let playlistToBeAssigned;
  let confirmationDeleteDialog;
  let idOfPlaylistToBeDeleted;
  let msgError;
  let search = "";
  let loading = false;

  const dispatch = createEventDispatcher();

  const handleChecked = (e) => {
    dispatch("filtratedPlaylistChecked", {
      checked: filtratedPlaylist.selected,
    });
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    await getClients();
  };

  const handleAssignVodsToPlaylistButtonClicked = async (playlist) => {
    playlistToBeAssigned = playlist;
    assignmentDialogClicked = true;
    await tick();
    assignmentDialog.open();
  };

  const handleCloseDialog = () => {
    dispatch('updatePlaylists');
    assignmentDialog.close();
  }

  const handleEditButtonClicked = (playlistToBeEdited) => {
    navigate('new-playlist', { state: { playlistToBeEdited } });
  };

  const handleDeleteButtonClicked = (id) => {
    idOfPlaylistToBeDeleted = id;
    confirmationDeleteDialog.open();
  };

  const handleDeleteConfirmation = () => {
    dispatch("deletePlaylist", {
      id: idOfPlaylistToBeDeleted,
    });
    confirmationDeleteDialog.close();
  };

  const handleAssignButtonClicked = async (playlist) => {
    dispatch('assignButtonClicked', { 
      playlist
    });
  };

  onMount(() => {
    
  });
</script>

<style>
  .textWrapper {
    opacity: 0.8;
    word-break: break-word;
  }
  .imageWrapper {
    background-color: grey;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  .imagecustomer {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    align-items: center;
    object-fit: cover;
  }
  * :global(.mdc-card.customerCard) {
    cursor: pointer;
  }
  .statusCircle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    height: 15px;
    width: 15px;
    margin-right: 10px;
    margin-top: 4px;
  }
  .centered {
    margin: auto;
  }

  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  * :global(.dialog--big .mdc-dialog__container),
    :global(.dialog--big .mdc-dialog__surface) {
      min-height: 65vh !important;
      height: auto !important;
    }

  * :global(.primary) {
    color: var(--primary);
  }

  @media (max-width: 767px) {
    .statusCircle {
      margin: 0;
    }
    * :global(.smui-card__content:not(.card--subheader)) {
      padding: 1rem 5px;
    }
  }
</style>

{#if assignmentDialogClicked}
  <Dialog 
    class="dialog--big" 
    bind:this={assignmentDialog}
    on:click={(e) => {e.stopPropagation();}}>
    <AssignPlaylistDialog
      text={$_('assegna_video_alla_playlist')}
      {playlistToBeAssigned}
      {msgError}
      {loading}
      on:searchChange={handleSearchChanged}
      on:closeDialog={handleCloseDialog} />
  </Dialog>
{/if}

<Dialog bind:this={confirmationDeleteDialog} on:click={(e) => {e.stopPropagation()}}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_la_playlist')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => confirmationDeleteDialog.close()}
    on:onConfirmation={handleDeleteConfirmation} />
</Dialog>

<div class="col-1 d-none d-md-block" style="padding-left:7px">
  <FormField>
    <Checkbox
      bind:checked={filtratedPlaylist.selected}
      on:click={(e) => {e.stopPropagation();}}
      on:change={(e) => { handleChecked(e); }} />
  </FormField>
</div>
<div class="col-8 col-sm-3 col-lg-3 pl-4 pl-md-2">
  <div class="demiBold textWrapper">
    {filtratedPlaylist.name}
  </div>
</div>
<div class="col-2 col-md-2 col-lg-2 textWrapper d-none d-sm-block text-center">
  <!-- {filtratedPlaylist.views} -->
</div>
<div class="col-2 col-md-2 col-lg-2 textWrapper d-none d-sm-block text-center">
  {filtratedPlaylist.quantity}
</div>
<div class="col-4 col-sm-4">
  {#if isMobile}
    <div
      class="col-12 col-md-3 col-lg-3 pr-0 bg__color flexy bar--icons mobile justify-content-end"
      on:focusout={(e) => {
        if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
        showMobileIcons = undefined;
      }}>
      <div
        class="icon--action"
        class:primary={showMobileIcons === index}
        on:click|stopPropagation={() => (showMobileIcons === index ? handleDeleteButtonClicked(filtratedPlaylist.playlist_id) : (showMobileIcons = index))}>        
        <IconButton class="material-icons px-1" style="width: fit-content">
          {showMobileIcons === index ? 'delete' : 'more_vert'}
        </IconButton>
      </div>
      <div class="flexy">
        <div
          class="icon--action"
          id="edit-icon"
          class:hide={showMobileIcons != index}
          on:click|stopPropagation={handleEditButtonClicked(filtratedPlaylist)}>
          <IconButton
            data-mobile-icon
            class="material-icons px-2"
            style="width: fit-content">
            edit
          </IconButton>
        </div>
        <div
          class="icon--action"
          class:hide={showMobileIcons != index}
          on:click|stopPropagation={handleAssignVodsToPlaylistButtonClicked(filtratedPlaylist)}>
          <IconButton
            data-mobile-icon
            class="material-icons px-2"
            style="width: fit-content">
            video_library
          </IconButton>
        </div>
        <div
          class="icon--action"
          class:hide={showMobileIcons != index}
          on:click|stopPropagation={handleAssignButtonClicked(filtratedPlaylist)}>
          <IconButton
            data-mobile-icon
            class="material-icons px-1"
            style="width: fit-content">
            assignment_ind
          </IconButton>
        </div>
      </div>
    </div>
  {:else}
    <div class="flexy justify-content-end desktop">
      <div on:click|stopPropagation={handleDeleteButtonClicked(filtratedPlaylist.playlist_id)}>
        <IconButton class="material-icons primary">delete</IconButton>
      </div>
      <div on:click|stopPropagation={handleEditButtonClicked(filtratedPlaylist)}>
        <IconButton class="material-icons">edit</IconButton>
      </div>
      <div on:click|stopPropagation={handleAssignVodsToPlaylistButtonClicked(filtratedPlaylist)}>
        <IconButton class="material-icons">video_library</IconButton>
      </div>
      <div on:click|stopPropagation={handleAssignButtonClicked(filtratedPlaylist)}>
        <IconButton class="material-icons">assignment_ind</IconButton>
      </div>
      <!-- <div>
        <IconButton class={`material-icons visible`}>
          {!panels[index] ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
        </IconButton>
      </div> -->
    </div>
  {/if}
</div>