<script>
  import { _ } from "../../../services/i18n";
  import { checkUrl, fixUrl } from "../../Team/TeamValidation/teamValidation";
  import { createEventDispatcher, onDestroy } from "svelte";
  import { Icon } from "@smui/common";
  import { user } from "../../../lib/store";
  import AddClients from "./AddClients.svelte";
  import AddGroups from "./AddGroups.svelte";
  import Button, { Label } from "@smui/button";
  import CalendarNotifications from "./CalendarNotifications.svelte";
  import CharacterCounter from "@smui/textfield/character-counter";
  import Checkbox from "@smui/checkbox";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";
  import moment from "moment";
  import IconButton from "@smui/icon-button";
  import Select, { Option } from "@smui/select";
  import StartEndDate from "./StartEndDate.svelte";
  import SvelteTooltip from "svelte-tooltip";
  import Textfield from "@smui/textfield";

  export let appointment;
  export let closedAppointmentDialog = false;
  export let confirmationDialog;
  export let createOnCustomer;
  export let generatingXtraLinks = false;
  export let onEdit;
  export let openDialog;

  const dispatch = createEventDispatcher();

  let addNotice = false;
  let alreadyCallHandleArraysOnEdit = false;
  let client = { first_name: "" };
  let clientsAdded, groupsAdded;
  let group = { name: "" };
  let isPastDate = false;
  let linkInvalid = false;
  let places = [
    { text: $_("fisico"), value: 0 },
    { text: $_("videocall"), value: 1 },
    { text: $_("telefono"), value: 2 },
  ];

  $: if ($user.xtravision_id != null && places.length < 4) {
    places = [...places, { text: "XTRA Live Training", value: 3 }];
  }
  
  // $: if (!isPastDate) {
  //   if ($user.xtravision_id != null && places.length < 4) {
  //     places = [...places, { text: "XTRA Live Training", value: 3 }];
  //   }
  // } else {
  //   places = places.filter(p => p.value !== 3);
  // }

  $: linkInvalid = appointment.url ? !checkUrl(appointment.url) : false;
  $: if (!openDialog) addNotice = alreadyCallHandleArraysOnEdit = false;

  $: if (
    (onEdit || (openDialog && createOnCustomer)) &&
    !alreadyCallHandleArraysOnEdit
  ) {
    handleArraysOnEdit();
    alreadyCallHandleArraysOnEdit = true;
  }

  $: if (closedAppointmentDialog) {
    client = { first_name: ""};
    group = { name: "" };
    clientsAdded = [];
    groupsAdded = [];
  }

  // Check if date is in the past
  $: if (appointment.startDate && appointment.startHour) {
    let now = moment();
    let startHourSplit = appointment.startHour.split(":");
    let startDatetime = moment(appointment.startDate).add({
        hours: startHourSplit[0],
        minutes: startHourSplit[1],
    });
    isPastDate = moment(startDatetime).isSameOrBefore(now);
  }


  const handleArraysOnEdit = () => {
    clientsAdded = appointment.customers.map((client) => {
      if (client.hasOwnProperty("first_name"))
        return {
          id: client.customer_id,
          email: client.customer_email,
          ...client,
        };
    });

    groupsAdded = appointment.at_groups.map((group) => {
      if (group.hasOwnProperty("name")) return group;
    });
  };

  const handleClientSelected = (e) => {
    if (e.detail.client.hasOwnProperty("id"))
      appointment.customers = [
        ...appointment.customers,
        { customer_id: e.detail.client.id },
      ];
    else if (e.detail.client.email)
      appointment.customers = [
        ...appointment.customers,
        { customer_email: e.detail.client.email },
      ];
  };

  const handleClientRemoved = (e) => {
    if (e.detail.client.id)
      appointment.customers = appointment.customers.filter(
        (el) => el.customer_id != e.detail.client.id
      );
    else if (e.detail.client.email)
      appointment.customers = appointment.customers.filter(
        (el) => el.customer_email != e.detail.client.email
      );
  };

  const handleGroupSelected = (e) => {
    if (e.detail.group.hasOwnProperty("id"))
      appointment.at_groups = [
        ...appointment.at_groups,
        { group_id: e.detail.group.id },
      ];
  };

  const handleGroupRemoved = (e) => {
    if (e.detail.group.group_id)
      appointment.at_groups = appointment.at_groups.filter(
        (el) => el.group_id != e.detail.group.group_id
      );
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
  }
</script>

<div class="container-fluid px-0 px-sm-3">
  <div class="row d-flex align-items-center">
    <div class="col-12 col-sm-2 label">{$_('titolo')}</div>
    <div class="col-12 col-sm-10">
      <Textfield
        variant="outlined"
        class={`textbox w100 my-2 `}
        input$maxlength="255"
        bind:value={appointment.title}
        type="text"
        label={$_('nuovo_appuntamento')}
      />
    </div>
  </div>
  <StartEndDate bind:appointment bind:openDialog />
  <div class="row-grid-label-parent mt-2 mt-sm-0">
    <div class="row d-flex align-items-center">
      <div class="col-12 col-sm-4 label">{$_('luogo')}</div>
      <div class="col-12 col-sm-8">
        <Select
          enhanced
          variant="outlined"
          class="w100 mb-2"
          label=""
          bind:value={appointment.site}
        >
          {#each places as place}
            <Option
              value={place.value}
              selected={appointment.site === place.value}
            >
              {place.text}
            </Option>
          {/each}
        </Select>
      </div>
    </div>
    {#if appointment.site == 1}
      <div class="col-12 label d-flex d-sm-none p-0 mt-2 mt-sm-0">Link</div>
      <div class="col-12 d-flex align-items-center p-0">
        <Textfield
          variant="outlined"
          class={`textbox my-2 w-100 mr-2`}
          bind:value={appointment.url}
          type="text"
          label="{$_('link_della_videochiamata')}"
        />
        <div class="link-tip">
          <SvelteTooltip tip={$_('apri_link')} top>
            <IconButton
              class="material-icons"
              disabled={linkInvalid || appointment.url.length == 0}
              on:click={() => window.open(fixUrl(appointment.url), '_blank')}
            >
              open_in_new
            </IconButton>
          </SvelteTooltip>
        </div>
      </div>
    {/if}
    {#if appointment.site == 3}
      <div class="col-12 label d-flex d-sm-none p-0 mt-2 mt-sm-0">Link</div>
      <div class="col-12 d-flex align-items-center p-0">
        <div class="link-tip">
          <div class="d-flex justify-content-end">
            <SvelteTooltip tip={$_('genera_link')} top>
              <Button 
                color="primary" 
                class="newCustomerBtn" 
                variant="unelevated"
                on:click={() => dispatch('generateXtraLinks')} 
                disabled={generatingXtraLinks || appointment.xtravision_trainer_class_link || isPastDate}
              >
                  <Icon role="button" class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing buttonIcon material-icons--xtra">
                    vpn_key
                  </Icon>
                <Label>
                  <span>{$_('genera_link')}</span>
                  {#if generatingXtraLinks}
                    <LoadingInline />
                  {/if}
                </Label>
              </Button>
            </SvelteTooltip>
          </div>
        </div>
      </div>
    {/if}
  </div>
  {#if appointment.site == 1 && linkInvalid}
    <div class="row-grid-auto">
      <div />
      <div class="row d-flex align-items-center">
        <div class="col-auto">
          <FormMessage>{$_('il_link_inserito_non_è_valido')}</FormMessage>
        </div>
      </div>
    </div>
  {/if}
  {#if appointment.site == 3 && appointment.xtravision_trainer_class_link != "" && appointment.xtravision_customers_class_link != ""}
    <div class="row d-flex align-items-center">
      <div class="col-12 col-sm-2 label">{$_('link_trainer')}</div>
      <div class="col-12 col-sm-10 d-flex justify-content-between align-items-center">
        <Textfield
          variant="outlined"
          disabled={true}
          class={`textbox w90 my-2 `}
          input$maxlength="255"
          label=""
          bind:value={appointment.xtravision_trainer_class_link}
          type="text"
          on:keydown={copyUrl(appointment.xtravision_trainer_class_link)}
        />
        <SvelteTooltip tip="{$_('copia_negli_appunti')}" top >
          <IconButton
            class="material-icons"
            on:click={copyUrl(appointment.xtravision_trainer_class_link)}>
            content_copy
          </IconButton>
        </SvelteTooltip>
      </div>
    </div>
    <div class="row d-flex align-items-center">
      <div class="col-12 col-sm-2 label">{$_('link_clienti')}</div>
      <div class="col-12 col-sm-10 d-flex justify-content-between align-items-center">
        <Textfield
          variant="outlined"
          disabled={true}
          class={`textbox w90 my-2 `}
          input$maxlength="255"
          label=""
          bind:value={appointment.xtravision_customers_class_link}
          type="text"
          on:keydown={copyUrl(appointment.xtravision_customers_class_link)}
        />
        <SvelteTooltip tip="{$_('copia_negli_appunti')}" top >
          <IconButton
            class="material-icons"
            on:click={copyUrl(appointment.xtravision_customers_class_link)}>
            content_copy
          </IconButton>
        </SvelteTooltip>
      </div>
      <div class="col-12 offset-sm-2 col-sm-10">
        <FormMessage infoMessage={true}>{$_('pensiamo_a_tutto')}</FormMessage>
      </div>
    </div>
    <hr />
  {/if}
  <CalendarNotifications bind:object={appointment} bind:addNotice bind:closedAppointmentDialog />
  <div class="row d-flex align-items-center mt-5 mt-sm-4">
    <div class="col-12 col-sm-2 label">{$_('cliente')}</div>
    <div class="col-12 col-sm-10 mt-2">
      <AddClients
        disabled={appointment.at_groups.length > 0}
        search={client.first_name}
        on:clientSelected={handleClientSelected}
        on:clientRemoved={handleClientRemoved}
        clientSelected={client}
        bind:openDialog
        bind:confirmationDialog
        bind:clientsAdded
        bind:groupsAdded
      />
    </div>
  </div>
  <div class="row d-flex align-items-center mt-2 mt-sm-0">
    <div class="col-12 col-sm-2 label">{$_('gruppo')}</div>
    <div class="col-12 col-sm-10 mt-2">
      <AddGroups
        disabled={appointment.customers.length > 0}
        search={group.name}
        on:groupSelected={handleGroupSelected}
        on:groupRemoved={handleGroupRemoved}
        groupSelected={group}
        bind:openDialog
        bind:confirmationDialog
        bind:groupsAdded
      />
    </div>
  </div>
  <div class="row d-flex align-items-center py-1 mt-2 mt-sm-0">
    <div class="col-12 col-sm-2 label">{$_('invia_email_invitati')}</div>
    <div class="col-12 col-sm-10">
      <Checkbox bind:checked={appointment.send_email_update} />
    </div>
  </div>
  <div class="row d-flex align-items-center mt-5 mt-sm-4">
    <div class="col-12 col-sm-2 label">{$_('note')}</div>
    <div class="col-12 col-sm-10">
      <Textfield
        textarea
        input$maxlength="500"
        class={`fullWidth my-2`}
        bind:value={appointment.note}
        label=""
      >
        <CharacterCounter>
          {`${appointment.note.length} / 500`}
        </CharacterCounter>
      </Textfield>
    </div>
  </div>
</div>

<style>
  .row-grid-auto,
  .row-grid-label-parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }

  * :global(.fullWidth) {
    width: 100% !important;
  }

  @media (max-width: 767px) {
    .row-grid-label-parent {
      grid-template-columns: 60% 1fr;
      gap: 12px;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      max-width: calc((100vw - 64px) * 0.166);
    }
    .row-grid-label-parent {
      gap: 0px;
    }
  }

  @media (max-width: 576px) {
    .row-grid-label-parent {
      grid-template-columns: unset;
      gap: unset;
    }
    * :global(.link-tip .tooltip.top) {
      left: unset;
      right: 0;
      transform: translate(0%, -100%);
    }
  }
</style>
