<script>
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";

  export let groups = [];
  export let centered = false;
  export let mx = true;
</script>

<style>
  .label--groups {
    opacity: 1;
    background-color: #05d3c8;
    border-radius: 3px;
    cursor: pointer;
  }
  .label--groups .label {
    opacity: 1;
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
  }
</style>

<div class="groupLabels 
            d-flex flex-row flex-wrap
            {centered ? `justify-content-center` : `` }">
  {#each groups as group, i}
    <div 
      class="label--groups px-1 mb-1 d-inline-block
            {mx ? `mx-1` : `mx-0`}
            {centered ? `my-1` : `mr-2` }
            {!centered && i != 1 ? `mr-0` : `mx-1`}"
      on:click={(e) => {
        e.stopPropagation();
        navigate('/group?tab=members&groupId=' + group.id, { state: { groupId: group.id }});
      }}>
      <span class="label">
        {group.name}
      </span>
    </div>
  {/each}
</div>
