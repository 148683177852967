<script>
  import Chart from "chart.js";
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  export let chartType;
  export let labels = [];
  export let data = [];
  export let unit = undefined;

  let legend = false;
  let mounted = false;
  let emptyChart = false;
  let option = {};

  let myChart;
  let dataValue = [];
  $: if (data.length != 0) {
    dataValue = data.map((ele) => {
      if (ele) {
        return ele.value;
      } else return null;
    });
  }

  $: chartType == "line" ? (legend = false) : (legend = true);

  let max;
  let min;

  $: objectDataset =
    chartType == "line"
      ? {
          data: dataValue,
          backgroundColor: ["rgba(255, 255, 255, 0.1)"],
          borderColor: "rgba(205, 41, 61, 1)",
          borderWidth: 1,
          pointBackgroundColor: "rgba(205, 41, 61, 1)",
          pointRadius: 5,
          pointHoverRadius: 7,
        }
      : {
          data: data,
          backgroundColor: ["#0CD2C8", "#FFCB00", "#F98131"],
        };

  $: {
    if (dataValue) {
      max = Math.round(Math.max(...dataValue) * 1.03);
      min = Math.round(Math.min(...dataValue) / 1.03);
    }
    option =
    chartType == "line"
      ? {
          maintainAspectRatio: false,
          responsive: true,
          onClick: changeData,
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          elements: {},
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                  min,
                  max,
                },
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                  labelString: unit,
                },
              },
            ],
          },
        }
      : {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false,
          },
          legendCallback: (chart) => {
            const text = [];
            text.push('<ul class="0-legend">');
            const ds = myChart.data.datasets[0];
            // const sum = ds.data.reduce(function add(a, b) {
            //   return a + b;
            // }, 0);
            for (let i = 0; i < ds.data.length; i++) {
              text.push("<li>");
              text.push(
                '<span style="background-color:' +
                  ds.backgroundColor[i] +
                  '">' +
                  "</span>" +
                  myChart.data.labels[i]
              );
              text.push("</li>");
            }
            text.push("</ul>");
            return text.join("");
          },
        };
      }
  $: {
    if (
      labels.length != 0 &&
      data.length > 0 &&
      chartType == "doughnut" &&
      document.getElementById(`myChart-${chartType}`) &&
      mounted
    ) {
      if (data.every((d) => d == 0)) emptyChart = true;
      const myLegendContainer = document.getElementById("legend");
      renderChar();
      myLegendContainer.innerHTML = myChart.generateLegend();
      const legendItems = myLegendContainer.getElementsByTagName("li");
    } else if (
      labels.length != 0 &&
      data.length > 0 &&
      document.getElementById(`myChart-${chartType}`)
    ) {
      renderChar();
    }
  }

  const changeData = (e, i) => {
    // i => ChartElement
    if (Array.isArray(i) && i[0]) {
      const { _index } = i[0];
      dispatch("selectedData", { data: data[_index] });
    }
  };

  const renderChar = () => {
    try {
      const ctx = document.getElementById(`myChart-${chartType}`);
      myChart ? myChart.destroy() : null;
      myChart = new Chart(ctx, {
        type: chartType,
        data: {
          labels: labels,
          datasets: [objectDataset],
        },
        options: option,
      });
    } catch (err) {
      console.log(err);
    }
  };

  onMount(() => {
    mounted = true;
  });
</script>

<style>
  .doughnut {
    border: 50px solid rgb(33, 33, 33, 0.1);
    border-radius: 100px;
    height: 200px;
    min-width: 200px;
    margin-right: 5rem;
  }
  .custom {
    height: auto;
    min-height: 15.25rem;
    width: 100%;
  }

  #legend {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .alignCenter {
    align-items: center;
    display: flex;
  }

  .custom--donut {
    height: 12.5rem;
    width: 12.5rem;
  }

  * :global([class$="-legend"]) {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  * :global([class$="-legend"] li) {
    color: #212121;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    opacity: 0.8;
    padding: 0 5px;
  }

  * :global([class$="-legend"] li.hidden) {
    color: #aaaaaa;
  }

  * :global[class$="-legend"] li span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 10px;
    width: 10px;
  }

  @media screen and (max-width: 767px) {
    * :global(#legend) {
      display: block;
    }
    .custom--donut,
    .doughnut {
      height: 150px;
      width: 150px;
      min-width: 150px;
    }
    .custom {
      max-height: 200px;
    }
  }
</style>

{#if chartType}
  <div class={`custom ${legend ? 'alignCenter custom--donut' : ''}`}>
    {#if emptyChart}
      <div class="doughnut" />
    {:else}<canvas id={`myChart-${chartType}`} class="mr-4" />{/if}
    <div id="legend" />
  </div>
{/if}
