<script>
  import { _ } from "../../../services/i18n";
  import { getSetupIntent, getPaymentsMethodsDefault, saveNewPaymentMethod, storeBillingInfo } from "../../../services/billingServices";
  import { getSubscription } from "../../../services/subscriptionServices";
  import { getToUpdateHubspotPt } from "../../../services/teamServices";
  import { loadStripe } from "@stripe/stripe-js";
  import { onMount } from "svelte";
  import FattureInCloudForm from "./FattureInCloudForm.svelte";
  import Button, { Label } from "@smui/button";
  import Card, { Content, Actions } from "@smui/card";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import SectionTitle from "./SectionTitle.svelte";
  import Textfield from "@smui/textfield";

  export let billingDetails;
  export let payMethodExpireMonth;
  export let payMethodExpireYear;
  export let payMethodLast4;
  export let payMethodUserName;

  let cardElement;
  let cardValid = false;
  let clientSecret;
  let editPaymentMethod = false;
  let formValid = false;
  let msgError = "";
  let nameCardHolder = "";
  let nameInvalid = false;
  let stripe;

  $: if (nameCardHolder) {
    nameInvalid = nameCardHolder.length < 3;
  }

  $: (async () => {
    if (editPaymentMethod) await initStripe();
  })();

  $: formValid = nameCardHolder && !nameInvalid && cardValid;

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const savePaymentMethod = async () => {
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod(
        "card",
        cardElement,
        {
          billing_details: { name: nameCardHolder },
        }
      );

      if (error) {
        console.log("error", error);
        displayError();
      } else {
        const res = await addNewPaymentMethod(paymentMethod);
        editPaymentMethod = false;
        await getToUpdateHubspotPt();
        await getSubscription();
      }
    } catch (err) {
      console.log("savePaymentMethod err", err);
      displayError();
    }
  };

  const initStripe = async () => {
    if (!stripe) stripe = await loadStripe(env.STRIPE_KEY);

    const elements = stripe.elements();
    cardElement = elements.create("card", {
      style: {
        base: {
          iconColor: "rgba(0, 0, 0, 0.42)",
          color: "rgba(0, 0, 0, 0.87)",
          fontWeight: 400,
          fontFamily: "'Avenir Next', Helvetica, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883",
          },
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.42)",
          },
        },
        invalid: {
          iconColor: "#cd293d",
          color: "#cd293d",
        },
      },  
      hidePostalCode: true,
    });

    if (document.getElementById("card-element")) {
      cardElement.mount("#card-element");
      cardElement.on("change", (e) => {
        cardValid = e.complete ? true : false;
      });
    }
  };

  const getSetupIntentUser = async () => {
    try {
      const { client_secret } = await getSetupIntent();
      clientSecret = client_secret;
    } catch (error) {
      console.log("getSetupItent err", error);
      displayError();
    }
  };

  const getPaymentMethod = async () => {
    try {
      // currently getting always empty object
      const { billing_details, card } = await getPaymentsMethodsDefault();
      if (billing_details) {
        payMethodUserName = billing_details.name;
      }
      if (card) {
        payMethodLast4 = card.last4;
        payMethodExpireYear = card.exp_year;
        payMethodExpireMonth = card.exp_month;
      }
    } catch (error) {
      console.log("getPaymentsMethodsDefault err", error);
      displayError();
    }
  };

  const addNewPaymentMethod = async (body) => {
    try {
      const res = await saveNewPaymentMethod(body);
      await getPaymentMethod();
    } catch (error) {
      console.log("saveNewPaymentMethod err", error);
      displayError();
    }
  };

  const handleSaveBillingInfo = async (e) => {
    try {
      const body = e.detail.body;
      const res = await storeBillingInfo(body);
      billingDetails.address_city        = res.address_city;
      billingDetails.address_postal_code = res.address_postal_code;
      billingDetails.address_province    = res.address_province;
      billingDetails.address_street      = res.address_street;
      billingDetails.certified_email     = res.certified_email;
      billingDetails.country             = res.country;
      billingDetails.ei_code             = res.ei_code;
      billingDetails.name                = res.name;
      billingDetails.tax_code            = res.tax_code;
      billingDetails.type                = res.type;
      billingDetails.vat_number          = res.vat_number;
      await getToUpdateHubspotPt();
      await getSubscription();
    } catch (error) {
      console.log("saveBillingInfo err", error);
      displayError();
    }
  };

  onMount(async () => {
    await getSetupIntentUser();
  });
</script>

<style>
  * :global(.customCard) {
    margin: 0 auto !important;
    border: none;
    max-width: 720px;
  }

  .textCenter {
    text-align: center;
  }

  .input--stripe {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 20px 0 18px;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 0.5rem;
  }

  * :global(.justifyContentBetween) {
    display: flex;
    justify-content: space-between;
  }
  * :global(.customCardContent) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>

<SectionTitle
  text={$_('modifica_dettagli_di_pagamento')}
  on:setComponent
  canGoBack={true} />

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

<div class="row">
  <div class="col-12 col-lg-4">
    <div class="row mb-4">
      <Card class="customCard" variant="outlined" padded>
        <Content class="customCardContent">
          <div>
            <div class="row my-1">
              <div class="col-12 mb-3 pl-3 bold textCenter">
                {$_('metodo_di_pagamento')}
              </div>
              {#if editPaymentMethod}
                <div class="col">
                  <Textfield
                    class="w100"
                    id="card-holder-name"
                    label={`${$_('nome_titolare_tessera')} *`}
                    bind:value={nameCardHolder}
                    bind:invalid={nameInvalid} />
                  {#if nameInvalid}
                    <FormMessage>
                      {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
                    </FormMessage>
                  {/if}
                  <div class="input--stripe" id="card-element" />
                </div>
              {:else}
                <span
                  class="col-12">{`${$_('carta_di_credito')}: **** **** **** ${payMethodLast4}`}</span>
                <span
                  class="col-12">{`${$_('intestatario')}: ${payMethodUserName}`}</span>
                <span
                  class="col-12">{`${$_('data_di_scadenza')}: ${payMethodExpireMonth}/${payMethodExpireYear}`}</span>
              {/if}
            </div>
          </div>
        </Content>
        <Actions class="d-flex justify-content-end">
          {#if editPaymentMethod}
            <!-- <Button
              class="btn mr-2"
              type="button"
              on:click={() => (editPaymentMethod = !editPaymentMethod)}>
              <Label>{$_('annulla')}</Label>
            </Button> -->
            <Button
              class="btn"
              variant="unelevated"
              data-secret={clientSecret}
              on:click={savePaymentMethod}
              type="button"
              disabled={!formValid}>
              <Label>{$_('salva')}</Label>
            </Button>
          {:else}
            <Button
              class="btn"
              variant="unelevated"
              on:click={() => (editPaymentMethod = !editPaymentMethod)}>
              <Label>{$_('modifica')}</Label>
            </Button>
          {/if}
        </Actions>
      </Card>
    </div>
  </div>
  <div class="col-12 col-lg-8">
    <div class="row mb-4">
      {#if billingDetails}
        <FattureInCloudForm
          {billingDetails}
          on:saveBillingInfo={handleSaveBillingInfo} />
      {/if}
    </div>
  </div>
</div>
