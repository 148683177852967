<script>
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";
  import List, { Item, Separator, Text, Graphic } from "@smui/list";
  import { customer, exercises, jwt, user, chatUserId } from "../../lib/store";
  import { cometChatLogout } from "../../services/chatServices.js";
  import { createEventDispatcher, tick } from "svelte";
  
  export let tabs = [];

  const dispatch = createEventDispatcher();
  let path = "";
</script>

<style>
  .newLabel {
    background: var(--mdc-theme-primary, #cd293d);
    width: 3rem;
    height: 1.2rem;
    color: #fff;
    box-sizing: border-box;
    line-height: 1rem;
    font-size: 0.7rem;
    position: absolute;
    top: 0.8rem;
    right: 2rem;
    padding: 1px;
  }
</style>

<List>
  {#each tabs as tab}
    <Item
      on:click={() => {
        if (tab.isExternal) window.open(tab.externalLink, '_blank');
        else { 
          if (window.location.pathname == "/training-card" || window.location.pathname == "/new-training-card") {
            dispatch("openExitDialog", { path: tab.route});
          } else {
            navigate(tab.route);
          }
        }
      }}>
      <Graphic class="material-icons">{tab.icon}</Graphic>
      <Text>{tab.label}</Text>
      {#if tab.isExternal}<button class="btn bold newLabel">New</button>{/if}
    </Item>
  {/each}
  <Separator />
  {#if $user.is_admin}
    <Item
      on:click={() => {
        navigate('admin');
      }}>
      <Graphic class="material-icons">admin_panel_settings</Graphic>
      <Text>{$_('admin')}</Text>
    </Item>
  {/if}
  <Item
    on:click={() => {
      if (window.location.pathname == "/training-card" || window.location.pathname == "/new-training-card") {
        dispatch("openExitDialog", { path: 'pt-info?tab=profile' });
      } else {
        navigate('pt-info?tab=profile');
      }
    }}>
    <Graphic class="material-icons">settings</Graphic>
    <Text>{$_('impostazioni')}</Text>
  </Item>
  <Item
    on:click={() => window.open('https://how.revoo-app.com/knowledge', '_blank')}>
    <Graphic class="material-icons">help</Graphic>
    <Text>{$_('help')}</Text>
  </Item>
  {#if (window.location.host != "trainmobile.revoo-app.com")}
    <Item
      on:click={() => window.open('http://meet.revoo-app.com/meetings/davide-p/presentazione-team-revoo', '_blank')}>
      <Graphic class="material-icons">ondemand_video</Graphic>
      <Text>{$_('richiedi_webinar')}</Text>
    </Item>
  {/if}
  <Separator />
  <Item
    on:click={async () => {
      if (window.location.pathname == "/training-card" || window.location.pathname == "/new-training-card") {
        dispatch("openExitDialog", { path: "login", jwtReset: true, })
      } else {
        jwt.set('');
        customer.set({});
        exercises.set([]);
        window.localStorage.clear();
        await cometChatLogout();
        await tick();
        navigate('login');
        window.location.reload();
      }
    }}>
    <Graphic class="material-icons">exit_to_app</Graphic>
    <Text>{$_('logout')}</Text>
  </Item>
</List>
