<script>
  import { _ } from "../../../../services/i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { reviewCry, reviewOk, reviewHappy } from "../../../../lib/icons";
  import Button, { Icon, Label } from "@smui/button";
  import IconButton from "@smui/icon-button";
  import moment from "moment";
  import SvgIcon from "../../../ReusableLayouts/SvgIcon.svelte";

  export let customerId;
  export let downloading = false;
  export let index;
  export let isMobile;
  export let panels = [];
  export let trainingCardId;
  export let trainingCardStartDate = '';
  export let workouts = [];

  let showExportButton = false;

  const dispatch = createEventDispatcher();

  const getSatisfactionIcon = (sat) => {
    if (sat == 3) return reviewCry;
    if (sat == 2) return reviewOk;
    if (sat == 1) return reviewHappy;
  };

  const handleWorkoutClick = (workout) => {
    if (workout.workout_count > 0)
      navigate("/completed-workouts", {
        state: {
          customerId,
          trainingCardId,
          workout,
          workouts,
        },
      });
  };

  const handleExportButtonClicked = () => {
    dispatch('export', { trainingCardId });
  }

  /**
   * Abilita il bottone "esporta workout" soltanto a determinate condizioni:
   *  - La training card è stata creata a partire dal 01/01/2023
   *  - Oppure se almeno uno dei workout nella training card è stato creato a partire dal 01/01/2023
   *  - In entrambi i casi, deve esserci un numero di workout eseguiti maggiore di 0
   */
  const checkShowExportButton = () => {
    let trainingCardDateToCheck         = new Date(trainingCardStartDate);
    let firstAvailableDate              = new Date('2023-01-01');
    let showButtonByWorkoutCreationDate = false;

    let workoutsCounter = workouts.reduce((sum, workout) => {
      return sum + Number(workout.workout_count);
    }, 0);

    workouts.map((workout) => {
      if (workout.workout_creation_date && workout.workout_count > 0) {
        let workoutDateToCheck = new Date(workout.workout_creation_date);
        if (workoutDateToCheck.getTime() >= firstAvailableDate.getTime()) {
          showButtonByWorkoutCreationDate = true;
        }
      }
    });

    if ((trainingCardDateToCheck.getTime() >= firstAvailableDate.getTime() || showButtonByWorkoutCreationDate) && workoutsCounter > 0) {
      return true;
    }

    return false;
  }

  onMount(() => {
    showExportButton = checkShowExportButton();
  });
</script>

<style>
  .workoutsList {
    background-color: #f9f9f9;
    padding: 16px;
  }
  .hide {
    display: none;
  }
  .textToEnd {
    text-align: end;
  }
  .titleWrapper {
    text-align: start;
    align-items: center;
    display: flex;
    opacity: 0.8;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .customDivider {
    border: 1px dashed #212121;
    opacity: 0.2;
  }
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .textCenter {
    text-align: center;
  }
</style>

<div class={`workoutsList ${panels[index] ? '' : 'hide'}`}> 
  {#if showExportButton}
    <div class="d-flex justify-content-end">
      <Button
        class="btn unelevatedCustom"
        variant="unelevated"
        on:click={handleExportButtonClicked}>
        <Label>
          <Icon
            disabled={downloading}
            role="button"
            class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing">
            file_download
          </Icon>
          {$_('esporta')}
        </Label>
      </Button>
    </div>
    <hr class="customDivider" />
  {/if}
  {#each workouts as workout, i}
    <div class="row spaceBetween px-2" on:click={handleWorkoutClick(workout)}>
      <div
        class={`${!isMobile ? 'col-3' : 'col-4'} titleWrapper demiBold`}>
        {workout.workout_name}
      </div>
      <div class="col-2 textCenter">
        {workout.workout_count && !isMobile ? `${workout.workout_count} ${$_('allenamenti')}` : workout.workout_count && isMobile ? `${workout.workout_count}` : '-'}
      </div>
      <div class={`${!isMobile ? 'col-6' : 'col-4'} textToEnd`}>
        {#if workout.workout_count > 0}
          {!isMobile ? `${$_('ultimo')}:` : ''}
          {#if workout.workout_last_satisfaction}
            <SvgIcon
              paths={getSatisfactionIcon(workout.workout_last_satisfaction)}
              viewBox="0 0 40 40" />
          {/if}
          {moment(workout.workout_last_created_at).format('DD/MM/YYYY')}
        {/if}
      </div>
      <div class={`${!isMobile ? 'col-1' : 'col-2'} iconWrapper`}>
        {#if workout.workout_count > 0}
          <IconButton class="material-icons">
            keyboard_arrow_right
          </IconButton>
        {/if}
      </div>
    </div>
    <hr class={`customDivider ${i == workouts.length - 1 ? 'hide' : ''}`} />
  {/each}
</div>
