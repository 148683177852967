<script>
  import "./AssignTrainingCardDialog.scss";
  import { _ } from "../../services/i18n";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { getCustomers, getAtGroups } from "../../services/teamServices.js";
  import { navigate } from "svelte-routing";
  import AssignedTrainingCardDeadline from "./../Team/Customer/Workouts/AssignedTrainingCardDeadline.svelte";
  import AssignTrainingCardDialog from "./AssignTrainingCardDialog.svelte";
  import Card, { Content } from "@smui/card";
  import ConfirmationDialog from "../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog from "@smui/dialog";
  import GroupLabels from "../ReusableLayouts/GroupLabels.svelte";
  import IconButton from "@smui/icon-button";
  import moment from "moment";
  import SvelteTooltip from 'svelte-tooltip';

  export let trainingCards = [];
  export let isTemplate;
  export let isMobile = false;
  export let activeTab;

  const dispatch = createEventDispatcher();
  const jq = window.$;

  let assignmentDialog;
  let assignmentDialogClicked = false;
  let cardToBeAssigned;
  let cardToBeEdited;
  let confirmationDeleteDialog;
  let confirmationEditDialog;
  let customers;
  let groups;
  let idOfCardToBeDeleted;
  let itemsTypeSelected = $_('atleti');
  let levelsMap = {
    elementary: $_("elementare"),
    beginner: $_("principiante"),
    intermediate: $_("intermedio"),
    advanced: $_("avanzato"),
  };
  let loading = false;
  let msgError;
  let search = "";
  let showMobileIcons = undefined;

  const displayError = (err = null) => {
    let timeoutTime = 3000;
    msgError = $_("si_è_verificato_un_errore");
    if (err.message.exception && err.message.exception === 'PlanConflictException') {
      
      msgError = $_("plan_conflict_exception");
      timeoutTime = 9000000;
    }
    setTimeout(() => {
      msgError = "";
    }, timeoutTime);
  };

  const handleSearchChanged = async (e) => {
    search = e.detail.search;
    itemsTypeSelected == $_('atleti') ? await getClients() : await getGroups();
  };

  const handleDeleteButtonClicked = (id) => {
    idOfCardToBeDeleted = id;
    confirmationDeleteDialog.open();
  };

  const handleDuplicateButtonClicked = (card) => {
    navigate(card.is_assigned_to_group ? `new-training-card?trainingCardId=` + card.id + `&type=assignmentToGroups` : `new-training-card?trainingCardId=` + card.id, {
      state: { 
        trainingCardId: card.id,
        duplicate: true,
      },
    });
  }

  const handleDeleteConfirmation = () => {
    dispatch("deleteTrainingCard", {
      id: idOfCardToBeDeleted,
    });
    confirmationDeleteDialog.close();
  };

  const handleEditButtonClicked = (card) => {
    if (card.is_assigned_to_group && card.has_executed_workouts) {
      cardToBeEdited = card;
      confirmationEditDialog.open();
    } else {
      redirectToTrainingCard(card);
    }
  };

  const handleAssignButtonClicked = async (card) => {
    cardToBeAssigned = card;
    assignmentDialogClicked = true;
    await tick();
    assignmentDialog.open();
    loading = true;
    await getClients();
    await getGroups();
    loading = false;
    await tick();
  };

  const redirectToTrainingCard = (card) => {
    navigate('/training-card?tab=workouts&trainingCardId=' + card.id, {
      state: { trainingCard: card },
    });
  };

  const getClients = async () => {
    try {
      const { data } = await getCustomers(500, 1, "", search);
      customers = data;
    } catch (error) {
      console.log("getCustomers error", error);
      displayError(error);
    }
  };

  const getGroups = async () => {
    try {
      const { data } = await getAtGroups(500, 1, search);
      groups = data;
    } catch (error) {
      console.log("getAtGroups error", error);
      displayError(error);
    }
  };

  onMount(() => {
    jq(document).ready(() => {
      if (jq(".desktop").css("display") == "none") {
        isMobile = true;
      } else isMobile = false;
    });
  });
</script>

<style>
  * :global(.textCenter) {
    text-align: center;
  }
  * :global(.primary) {
    color: var(--primary);
  }
  .alignContentEnd {
    align-items: flex-end;
    justify-content: flex-end;
  }
  .label {
        opacity: 0.5;
        font-size: 0.75rem;
  }
  .alignContentCenter {
    align-items: center;
  }
  .textWrapper {
    display: flex;
    justify-content: center;
    opacity: 0.8;
    word-break: break-word;
  }
  .nameWrapper {
    cursor: pointer;
    font-weight: 600;
  }
  .textLeft {
    justify-content: start;
  }
  .breakTitle {
    word-break: break-word;
  }
  .hide {
    display: none;
  }
  .bg__color {
    background-color: #fff;
  }
  .op-2 {
    opacity: 0.2 !important;
  }

  @media (min-width: 767px) {
    .mobile {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .desktop {
      display: none;
    }
  }
</style>

{#if assignmentDialogClicked}
  <Dialog class="dialog--big" bind:this={assignmentDialog}>
    <AssignTrainingCardDialog
      {cardToBeAssigned}
      {customers}
      {groups}
      {loading}
      {msgError}
      bind:itemsTypeSelected
      on:closeDialog={() => assignmentDialog.close()} 
      on:searchChange={handleSearchChanged}
      text={$_('assegna_copia_di')}/>
  </Dialog>
{/if}

<Dialog bind:this={confirmationDeleteDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_eliminare_il_piano')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => confirmationDeleteDialog.close()}
    on:onConfirmation={handleDeleteConfirmation} />
</Dialog>

<Dialog bind:this={confirmationEditDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_modificare_il_piano_gruppi')}
    confirmationButtonLabel={$_('modifica')}
    on:closeDialog={(e) => confirmationEditDialog.close()}
    on:onConfirmation={(e) => {
      redirectToTrainingCard(cardToBeEdited);
      confirmationEditDialog.close();
    }} />
</Dialog>

{#each trainingCards as card, i}
  <Card class="mb-2">
    <Content>
      {#if isMobile && card.is_assigned_to_group}
        <GroupLabels groups={card.groups} />
      {/if}
      <div class="row no-gutters alignContentCenter">
        <div
          class="col-8 col-sm-5 col-md-3 col-lg-3 textWrapper nameWrapper
            textLeft text-left breakTitle"
          on:click={redirectToTrainingCard(card)}>
          {card.name}
        </div>
        {#if !card.is_assigned_to_group}
          <div class="col-md-2 col-lg-2 textWrapper desktop">
            {card.workouts_length ? $_('schede_', {
                  values: { schede: card.workouts_length },
                }) : '-'}
          </div>
          <div class="col-md-2 col-lg-2 textWrapper desktop">
            {card.length ? $_('settimane', {
                  values: { settimane: card.length },
                }) : '-'}
          </div>
          <div class="col-md-2 col-lg-2 textWrapper desktop">
            {card.level ? levelsMap[card.level] : '-'}
          </div>
        {:else}
          {#if isMobile}
            <div class="d-none"></div>
          {:else}
            <div class="col-md-2 col-lg-2 textWrapper d-flex flex-column">
              {#if card.is_assigned_to_group}
                <GroupLabels groups={card.groups} centered={true} />
              {:else}
                {'-'}
              {/if}
            </div>
          {/if}
          <div class="col-2 col-sm-4 col-md-2 col-lg-2 d-none d-sm-block px-2 text-center" class:op-2={card.is_draft}>
            <AssignedTrainingCardDeadline tc={card} />
          </div>
          <div
            class:op-2={card.is_draft}
            class={`col-2 col-md-2 d-none d-md-block px-2 text-center tc-start`}>
            <div class="col-12 label px-0">{$_('inizio')}</div>
            <div class="col-12 px-0">
              {card.start ? moment(card.start).format('DD/MM/YYYY') : '-'}
            </div>
          </div>
        {/if}
        {#if isMobile}
          <div
            class="col-4 col-xs-4 col-sm-3 col-lg-3 bg__color flexy bar--icons
                   alignContentEnd mobile"
            on:focusout={(e) => {
              if (e.relatedTarget && e.relatedTarget.dataset.mobileIcon) return;
              showMobileIcons = undefined;
            }}>
            <div
              class="icon--action"
              class:primary={showMobileIcons === i}
              on:click|stopPropagation={() => (showMobileIcons === i ? handleDeleteButtonClicked(card.id) : (showMobileIcons = i))}>
              <IconButton class="material-icons p-0" style="width: fit-content">
                {showMobileIcons === i ? 'delete' : 'more_vert'}
              </IconButton>
            </div>
            <div class="flexy">
              <div
                class="icon--action"
                id="edit-icon"
                class:hide={showMobileIcons != i}
                on:click|stopPropagation={handleEditButtonClicked(card)}>
                <IconButton
                  data-mobile-icon
                  class="material-icons p-0 ml-2"
                  style="width: fit-content">
                  edit
                </IconButton>
              </div>
              {#if activeTab !== $_('assegnati_ai_gruppi')}
                <div
                  class="icon--action"
                  class:hide={showMobileIcons != i}
                  on:click|stopPropagation={handleAssignButtonClicked(card)}>
                  <IconButton
                    data-mobile-icon
                    class="material-icons p-0 ml-3"
                    style="width: fit-content">
                    assignment_ind
                  </IconButton>
                </div>
              {/if}
              <div
                class:hide={showMobileIcons != i}
                on:click|stopPropagation={handleDuplicateButtonClicked(card)}>
                <IconButton
                  data-mobile-icon
                  class="material-icons p-0 ml-3"
                  style="width: fit-content">
                  content_copy
                </IconButton>
              </div>
            </div>
          </div>
        {:else}
          <div
            class="col-6 col-xs-6 col-md-3 col-lg-3 flexy alignContentEnd
              desktop">
            <div on:click|stopPropagation={handleDeleteButtonClicked(card.id)}>
              <SvelteTooltip tip="{$_('elimina')}" top >
                <IconButton class="material-icons primary">delete</IconButton>
              </SvelteTooltip>
            </div>
            <div on:click|stopPropagation={handleEditButtonClicked(card)}>
              <SvelteTooltip tip="{$_('modifica')}" top >
                <IconButton class="material-icons">edit</IconButton>
              </SvelteTooltip>
            </div>
            {#if activeTab !== $_('assegnati_ai_gruppi')}
              <div on:click|stopPropagation={handleAssignButtonClicked(card)}>
                <SvelteTooltip tip="{$_('assegna')}" top >
                  <IconButton class="material-icons">assignment_ind</IconButton>
                </SvelteTooltip>
              </div>
            {/if}
            <div
              on:click|stopPropagation={handleDuplicateButtonClicked(card)}>
              <SvelteTooltip tip="{$_('duplica')}" top >
                <IconButton class="material-icons">content_copy</IconButton>
              </SvelteTooltip>
            </div>
          </div>
        {/if}
      </div>
    </Content>
  </Card>
{/each}
