<script>
  import { createEventDispatcher } from "svelte";
  import Button, { Label } from "@smui/button";
  import Select, { Option } from "@smui/select";
  import ImageUploader from "../Team/Customer/ProgressAndCheck/ImageUploader.svelte";
  import Textfield, { Input } from "@smui/textfield";
  import ExerciseImageCropper from "../Exercises/Uploaders/ExerciseImageCropper.svelte";
  import countries from "../../lib/countries";

  const dispatch = createEventDispatcher();

  export let panel;
  export let deal;

  let brand_name = "";
  let short_text = "";
  let long_text = "";
  let URL = "";
  let country = "Italia";
  let photo = null;
  let brand_photo = null;
  let created_at = "";
  let updated_at = "";

  $: if (!panel && deal) {
    disabled = true;
    brand_name = deal.brand_name || "";
    short_text = deal.short_text || "";
    long_text = deal.long_text || "";
    country = deal.country || "";
    URL = deal.URL || "";
    photo = deal.photo;
    brand_photo = deal.brand_photo;
    created_at = deal.created_at ? deal.created_at : '';
    updated_at = deal.updated_at ? deal.updated_at : '';
  }

  let disabled = false;
  let formValid = false;
  $: formValid = brand_name && short_text && URL && long_text;

  const handlePhotoUpdated = (e) => {
    photo = e.detail.image;
  };

  const handleBrandPhotoUpdated = (e) => {
    brand_photo = e.detail.image;
  };

  const submit = () => {
    if (deal && disabled) {
      disabled = false;
    } else {
      const dealObject = {
        brand_name,
        short_text,
        long_text,
        photo,
        brand_photo,
        URL,
        country,
      };
      if (deal) {
        disabled = true;
        dealObject.id = deal.id;
      }
      dispatch("saveDeal", { deal: dealObject });
    }
  };
</script>

<style>
  * :global(.fullWidth) {
    width: 100% !important;
  }
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
  }
  .uploaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>

<div class="row spaceBetween px-2">
  <div class="col-6">
    <Textfield
      class="w100 my-2"
      bind:value={brand_name}
      {disabled}
      label="Nome brand" />
  </div>
  <div class="col-6">
    <Textfield
      class="w100 my-2"
      {disabled}
      bind:value={short_text}
      label="Nome coupon" />
  </div>
  <div class="col-12">
    <Textfield {disabled} class="w100 my-2" bind:value={URL} label="URL" />
  </div>
  <div class="col-12">
    <Textfield
      textarea
      {disabled}
      class="fullWidth my-2"
      bind:value={long_text}
      label="Descrizione coupon" />
  </div>
  <div class="col-6 py-2 uploaderWrapper">
    <div class="mdc-typography--body2 mb-2">
      Photo
    </div>
    <ImageUploader bind:image={photo} />
  </div>
  <div class="col-6 py-2 uploaderWrapper">
    <div class="mdc-typography--body2 mb-2">
        Brand Photo
    </div>
    <ImageUploader bind:image={brand_photo} />
  </div>
  <div class="col-12">
    <Select
      enhanced
      {disabled}
      variant="outlined"
      class="w50 mb-2"
      label="Paese"
      bind:value={country}>
      {#each countries as item}
        <Option value={item} selected={country === item}>{item}</Option>
      {/each}
    </Select>
  </div>
  {#if deal}
    <div class="col-12">
      <Textfield
        disabled="true"
        class="w100 my-2"
        bind:value={created_at}
        label="Creato il" />
    </div>
    <div class="col-12">
      <Textfield
        disabled="true"
        class="w100 my-2"
        bind:value={updated_at}
        label="Aggiornato il" />
    </div>
  {/if}
</div>

<div class="row mx-0 spaceBetween py-4">
  <Button
    class="btn"
    variant="unelevated"
    on:click={() => dispatch('cancel', {})}>
    <Label>cancel</Label>
  </Button>
  <Button
    disabled={!disabled && !formValid}
    class="btn"
    variant="unelevated"
    color="secondary"
    on:click={submit}>
    <Label>{disabled ? 'modifica' : 'salva'}</Label>
  </Button>
</div>
