<script>
  import "./ProfileInfo.scss";
  import "moment/locale/en";
  import "moment/locale/it";
  import { _ } from "../../../services/i18n";
  import { checkEmail, checkFiscalCode, checkUrl, fixUrl } from "../TeamValidation/teamValidation";
  import { createEventDispatcher, onMount } from "svelte";
  import { customer, user } from "../../../lib/store";
  import { deleteCustomer, resendInvitationEmail, updateChatEnabledForCustomer } from "../../../services/teamServices";
  import { fieldsForGooglePlace, getContactFunnels, getGenderItems, getTypes } from "../../../lib/utils";
  import { navigate } from "svelte-routing";
  import BannerHelp from "../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label, Icon } from "@smui/button";
  import CC from "../../../lib/countryCode";
  import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
  import DatePicker from "../../ReusableLayouts/DatePicker.svelte";
  import Dialog from "@smui/dialog";
  import FormMessage from "../../ReusableLayouts/FormMessage.svelte";
  import IconButton from "@smui/icon-button";
  import moment from "moment";
  import Select, { Option } from "@smui/select";  
  import SvelteTooltip from 'svelte-tooltip';
  import Switch from "@smui/switch";
  import Textfield from "@smui/textfield";
  
  const dispatch = createEventDispatcher();
  
  export let customerId;

  let msgError;
  let genderItems;
  let types;
  let chatEnabledForCustomer;
  let contactFunnels;
  let customerDeletionDialog = false;
  let customerPasswordResetDialog = false
  let countries_code = [];
  for (const [key, value] of Object.entries(CC)) {
    countries_code.push(value);
  }
  let emailResent;

  let birth_dateFormatted;
  let dateDialogItalian;
  $: dateDialogItalian = $customer.date_of_birth
    ? moment($customer.date_of_birth).format("DD/MM/YYYY")
    : "";

  const handleDate = (e) => {
    $customer.date_of_birth = e.detail.date;
    birth_dateFormatted = moment($customer.date_of_birth).format("YYYY-MM-DD");
  };

  let name = $customer.first_name;
  let surname = $customer.last_name;
  let birthday = $customer.date_of_birth;
  let email = $customer.email;
  let address = $customer.address;
  let phone = $customer.phone || "";
  let gender = $customer.gender;
  let country = $customer.country;
  let countryCode = $customer.country_code;
  let contactFunnel = $customer.contact_funnel;
  let type = $customer.type;
  let linkDrive = $customer.link_gdrive;
  let fc = $customer.fc;
  let fcUppercase;

  let nameInvalid = false;
  let surnameInvalid = false;
  let emailInvalid = false;
  let addressInvalid = false;
  let fiscalCodeInvalid = false;
  let linkInvalid = false;

  let autocomplete;
  let countryRestrict = { country: $customer.country ? $customer.country : "it" };
  let placeSelected;

  $: {
    if (country && autocomplete) {
      setAutocompleteCountry();
    }
  }
  $: types = getTypes($_);
  $: genderItems = getGenderItems($_);
  $: contactFunnels = getContactFunnels($_);

  $: if ($customer && $customer.has_chat != undefined) {
    chatEnabledForCustomer = $customer.has_chat;
  }

  $: formValid =
    name &&
    name.length > 2 &&
    surname &&
    surname.length > 1 &&
    email &&
    !emailInvalid &&
    !addressInvalid &&
    !fiscalCodeInvalid &&
    !linkInvalid;
  $: if (name) {
    nameInvalid = name.length < 3;
  }
  $: if (surname) {
    surnameInvalid = surname.length < 2;
  }
  $: if (email) {
    emailInvalid = !checkEmail(email);
  }
  $: if (placeSelected) addressInvalid = false;
  $: if (fc) fcUppercase = fc.toUpperCase();
  $: fiscalCodeInvalid =
    fcUppercase && country == "IT" ? !checkFiscalCode(fcUppercase) : false;
  $: linkInvalid = linkDrive ? !checkUrl(linkDrive) : false;
  $: {
    if (linkDrive == "" && autocomplete) {
      const el = document.getElementById("linkInput");
      el.childNodes[1].placeholder = "https://";
    }
  }

  const onSubmit = () => {
    dispatch("updateCustomer", {
      customer: {
        first_name: name,
        last_name: surname,
        gender,
        email,
        country,
        contact_funnel: contactFunnel,
        type,
        date_of_birth: birth_dateFormatted || birthday,
        link_gdrive: linkDrive,
        fc: fcUppercase,
        place_selected: placeSelected,
        country_code: countryCode,
        phone,
      },
    });
    $customer.address = address;
  };

  const init = () => {
    autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */ document.getElementById("autocomplete"),
      {
        types: ["address"],
        componentRestrictions: countryRestrict,
        fields: fieldsForGooglePlace,
      }
    );
    autocomplete.addListener("place_changed", onPlaceChanged);
  };

  onMount(() => {
    init();
  });

  const onPlaceChanged = () => {
    placeSelected = autocomplete.getPlace();
  };

  const setAutocompleteCountry = () => {
    if (!country) {
      autocomplete.setComponentRestrictions({ country: [] });
    } else {
      autocomplete.setComponentRestrictions({ country });
    }
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const handleDeleteCustomer = async () => {
    customerDeletionDialog.close();
    try {
      const res = await deleteCustomer(customerId);
      setTimeout(() => {
        navigate("/team");
      }, 2000);
    } catch (err) {
      console.log("err deleteCustomer", err);
      displayError();
    }
  };

  const resendEmail = async () => {
    try {
      if (customerPasswordResetDialog) {
        customerPasswordResetDialog.close();
      }
      await resendInvitationEmail(customerId, email);
      emailResent = true;
      setTimeout(() => (emailResent = false), 3000);
    } catch (err) {
      console.log("resend email err", err);
      displayError();
    }
  };

  const handleChatEnabledChanged = async () => {
    chatEnabledForCustomer = !chatEnabledForCustomer;
    await updateChatEnabledForCustomer({
      customer_id : customerId,
      chat_enabled_for_customer : chatEnabledForCustomer,
    });
  };
</script>

<style>
  .label {
    text-align: end;
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  * :global(.fullWidth) {
    width: 100% !important;
  }

  * :global(.gender-select-width) {
    min-width: 160px;
  }

  .phonePrefix {
    padding-right: 18px;
    margin-top: 2px;
  }

  .phoneCell {
    padding-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
    margin-left: -15px;
  }

  * :global(.emailField .tooltip-wrapper) {
    width: 100%;
  }

  * :global(.emailField .tooltip.top) {
    left: 50%;
    transform: translate(-50%, -70%);
    margin-top: 8px;
  }

  * :global(.shareField .tooltip-wrapper) {
    display: flex; 
    align-items: center;
  }

  * :global(.primary) {
    color: var(--primary);
  }

  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
</style>

<Dialog bind:this={customerDeletionDialog}>
  <ConfirmationDialog
    text={$_('sicuro_di_voler_eliminare_il_cliente')}
    confirmationButtonLabel={$_('elimina')}
    on:closeDialog={() => customerDeletionDialog.close()}
    on:onConfirmation={handleDeleteCustomer} />
</Dialog>

<Dialog bind:this={customerPasswordResetDialog}>
  <ConfirmationDialog
    text={$_('sei_sicuro_di_voler_resettare_la_password_del_cliente')}
    confirmationButtonLabel={$_('reset_password')}
    on:closeDialog={() => customerPasswordResetDialog.close()}
    on:onConfirmation={resendEmail} />
</Dialog>

<div class="my-3">
  <BannerHelp state='customer_profile'/>
</div>

<div class="row">
  <div class="col-4 label">{`${$_('nome')} *`}</div>
  <div class="col-8">
    <Textfield
      variant="outlined"
      disabled={!$customer.active}
      class={`textbox w100 my-2 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
      bind:value={name}
      type="text" />
    {#if nameInvalid}
      <FormMessage>
        {`${name ? $_('il_nome_deve_essere_di_almeno_3_caratteri') : $_('il_nome_é_obbligatorio')}'`}
      </FormMessage>
    {/if}
  </div>
  <div class="col-4 label">{`${$_('cognome')} *`}</div>
  <div class="col-8">
    <Textfield
      disabled={!$customer.active}
      variant="outlined"
      class={`textbox w100 my-2 ${surnameInvalid ? 'mdc-text-field--invalid' : ''}`}
      bind:invalid={surnameInvalid}
      bind:value={surname}
      type="text" />
    {#if surnameInvalid}
      <FormMessage>
        {`${surname ? $_('il_cognome_deve_essere_di_almeno_3_caratteri') : $_('il_cognome_é_obbligatorio')}'`}
      </FormMessage>
    {/if}
  </div>
  <div class="col-4 label">{$_('data_di_nascita')}</div>
  <div class="col-8 mt-2">
    <DatePicker
      on:date={handleDate}
      disabled={!$customer.active}
      defaultDate={dateDialogItalian}
      width="100"
      dateFormat="yyyy-mm-dd" />
  </div>
  <div class="col-4 label">{`${$_('sesso')} *`}</div>
  <div class="col-8">
    <Select
      disabled={!$customer.active}
      enhanced
      variant="outlined"
      class="w50 mb-2"
      label=""
      bind:value={gender}>
      {#each genderItems as { value, text }}
        <Option {value} selected={gender === value}>{text}</Option>
      {/each}
    </Select>
  </div>
  <div class="col-4 label">{$_('paese')}</div>
  <div class="col-8">
    <Select
      disabled={!$customer.active}
      enhanced
      variant="outlined"
      label=""
      class="w100 mb-2"
      bind:value={country}>
      {#each countries_code as item}
        <Option value={item.iso2} selected={country === item.iso2}>
          {item.name}
        </Option>
      {/each}
    </Select>
  </div>
  <div class="col-4 label">{$_('indirizzo_completo')}</div>
  <div class="col-8">
    <Textfield
      disabled={!$customer.active}
      variant="outlined"
      class={`textbox w100 my-2 ${addressInvalid ? 'mdc-text-field--invalid' : ''}`}>
      <input
        type="text"
        id="autocomplete"
        bind:value={address}
        on:input={(e) => {
          if (!e.target.value) {
            placeSelected = null;
            address = null;
            addressInvalid = false;
          }
          if (e.target.value && !placeSelected) addressInvalid = true;
        }}
        class="mdc-text-field__input" />
      <div class={`mdc-notched-outline mdc-notched-outline--upgraded`}>
        <div class="mdc-notched-outline__leading" />
        <div class="mdc-notched-outline__notch" />
        <div class="mdc-notched-outline__trailing" />
      </div>
    </Textfield>
    {#if addressInvalid}
      <FormMessage>{$_('indirizzo_non_è_corretto')}</FormMessage>
    {/if}
  </div>
  <div class="col-4 label">
    {`${$_('email')} *`}
    {#if !$customer.email_confirmed}
      <span class="primary">{`(${$_('non_confermata')})`}</span>
    {/if}
  </div>
  <div class="col-8 emailField" style="display: inherit;">
    <div class="w100">
      <SvelteTooltip tip="{$customer.email_confirmed ? $_('solo_l_atleta_puo_modificare_l_email') : ''}" top >
        <Textfield
          disabled={$customer.email_confirmed}
          bind:value={email}
          variant="outlined"
          type="text"
          class={`textbox w100 my-2 ${emailInvalid ? 'mdc-text-field--invalid' : ''}`} />
        {#if emailInvalid}
          <FormMessage>{$_('email_non_valida')}</FormMessage>
        {/if}
      </SvelteTooltip>
    </div>
    <Button
      class="btn w40 ml-2"
      disabled={!$customer.active || $customer.email != email}
      style="align-self: center"
      variant="unelevated"
      on:click={$customer.email_confirmed ? customerPasswordResetDialog.open() : resendEmail}>
      <Label>{$customer.email_confirmed ? $_('reset_password') : $_('reinvia_password')}</Label>
    </Button>
  </div>
  {#if emailResent}
    <div class="col-4" style="display: inherit;" />
    <div class="col-8" style="display: inherit;">
      <div class="alert alert-success fade show" role="alert">
        {$_('email_reinviata_con_successo')}
      </div>
    </div>
  {/if}
  <div class="col-4 label">{$_('numero_cellulare')}</div>
  <div class="col-4 phonePrefix">
    <Select
      disabled={!$customer.active}
      enhanced
      variant="outlined"
      class="w100"
      bind:value={countryCode}
      label="">
      <Option value="" />
      {#each countries_code as cc}
        <Option value={cc.code} selected={countryCode === cc}>
          {` +${cc.code} ${cc.iso2}`}
        </Option>
      {/each}
    </Select>
  </div>
  <div class="col-4 phoneCell">
    <Textfield
      disabled={!$customer.active}
      variant="outlined"
      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
      bind:value={phone}
      type="tel"
      class="textbox w100 my-2" />
  </div>
  <div class="col-4 label">{$_('codice_fiscale')}</div>
  <div class="col-8">
    <Textfield
      disabled={!$customer.active}
      variant="outlined"
      class={`textbox textUppercase w100 my-2 ${fiscalCodeInvalid ? 'mdc-text-field--invalid' : ''}`}
      bind:value={fc}
      type="text" />
    {#if fiscalCodeInvalid}
      <FormMessage>{$_('il_codice_fiscale_non_è_valido')}</FormMessage>
    {/if}
  </div>
  <div class="col-4 label">{$_('aggiunto_in_data')}</div>
  <div class="col-8">
    <div
      disabled="true"
      variant="outlined"
      class={`textbox textUppercase w100 my-3`}>
      <span>
        {$customer.created_at}
      </span>
    </div>
  </div>
</div>

<hr />

<div class="row">
  <div class="col-4 label">{$_('tipo_di_cliente')}</div>
  <div class="col-8">
    <Select
      disabled={!$customer.active}
      enhanced
      variant="outlined"
      class="w100 mb-2"
      label=""
      bind:value={type}>
      {#each types as { value, label }}
        <Option {value} selected={type === value}>{label}</Option>
      {/each}
    </Select>
  </div>
  <div class="col-4 label">{$_('come_lo_hai_conosciuto')}</div>
  <div class="col-8">
    <Select
      disabled={!$customer.active}
      enhanced
      variant="outlined"
      class="w100 mb-2"
      label=""
      bind:value={contactFunnel}>
      {#each contactFunnels as { value, label }}
        <Option {value} selected={contactFunnel === value}>{label}</Option>
      {/each}
    </Select>
  </div>
</div>

<hr />

<div class="row">
  <div class="col-4 label">{$_('link_cartella_condivisa')}</div>
  <div class="col-8 shareField" style="display: inherit;">
    <div class="w100">
      <Textfield
        id="linkInput"
        disabled={!$customer.active}
        bind:value={linkDrive}
        variant="outlined"
        type="text"
        placeholder="Linkhttp"
        class={`textbox w100 my-2 ${linkInvalid ? 'mdc-text-field--invalid' : ''}`} />
      {#if linkInvalid}
        <FormMessage>{$_('il_link_inserito_non_è_valido')}</FormMessage>
      {/if}
    </div>
    <SvelteTooltip tip="{$_('apri_link')}" top >
      <IconButton
        class="material-icons ml-2"
        style="align-self: center"
        disabled={linkInvalid || !$customer.active}
        href={fixUrl(linkDrive)}
        target="_blank">
        share
      </IconButton>
    </SvelteTooltip>
  </div>
</div>

<hr />

{#if $user.chat_enabled}
  <div class="row">
    <div class="col-4 label">{$_('attiva_chat_per_questo_cliente')}</div>
    <div class="col-8">
      <Switch bind:checked={chatEnabledForCustomer} on:click={handleChatEnabledChanged} />
    </div>
  </div>
  <hr />
{/if}

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

<div class="row pushToEnd profileUpdate">
  <button
    class="btn btnTooltip mdc-ripple-upgraded"
    on:click={() => customerDeletionDialog.open()}
    data-tooltip={$_('elimina_profilo_overlay')}
    disabled={$customer.active}>
    <Icon class="material-icons">delete</Icon>
    <Label>{$_('elimina_profilo')}</Label>
  </button>
  <Button
    class="btn ml-5 mr-3"
    variant="unelevated"
    on:click={onSubmit}
    disabled={!formValid || !$customer.active}>
    <Label>{$_('salva_modifiche')}</Label>
  </Button>
</div>
