<script>
	import { _ } from "../../../../services/i18n";
	import { getProducts, getStripeAccount } from "../../../../services/productServices";
	import { onMount } from "svelte";
	import ListWrapper from "./ListWrapper.svelte";
	  
	export let number;

	let list = [];
	let loading = true;
	let stripeAccount = false;
	let title = $_("abbonamenti");

	const handleUpdateList = async (e) => {
		loading = true;
		list = await getProducts();
		number = list.length;
		loading = false;
	}

	onMount(async () => {
		stripeAccount = await getStripeAccount();
		list = await getProducts();
		number = list.length;
		loading = false;
	});
</script>

<div class="w100 pt-4 pt-4">
	<ListWrapper 
		{list} 
		{loading} 
		{stripeAccount} 
		{title} 
		on:updateList={handleUpdateList} 
	/>
</div>