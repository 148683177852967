<script>
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";

  export let groupNames = [];
  export let centered = false;
  export let mx = true;

  let groupsData = [];

  const getGroupsData = (groups) => {
    groupsData = groups.split(",").map(g => g);
    return groupsData;
  };

  const getGroupName = (group) => {
    const id = group.split("##;##")[1];
    return id;
  };

  const getGroupId = (group) => {
    const name = group.split("##;##")[1];
    return name;
  };

  onMount(() => {
    getGroupsData(groupNames);
  });

</script>

<style>
  .label--groups {
    opacity: 1;
    background-color: #05d3c8;
    border-radius: 3px;
    cursor: pointer;
  }
  .label--groups .label {
    opacity: 1;
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
  }
</style>

<div class="groupLabels 
            d-flex flex-row flex-wrap
            {centered ? `justify-content-center` : `` }">
  {#each groupsData as group, i}
    <div 
      class="label--groups px-1 mb-1 d-inline-block
            {mx ? `mx-1` : `mx-0`}
            {centered ? `my-1` : `mr-2` }
            {!centered && i != 1 ? `mr-0` : `mx-1`}"
      on:click={(e) => {
        e.stopPropagation();
        navigate('/group?tab=members&groupId=' + getGroupId(group), { state: { groupId: getGroupId(group) }});
      }}>
      <span class="label">
        {getGroupName(group)}
      </span>
    </div>
  {/each}
</div>