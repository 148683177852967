<script>
    import { _ } from "../../../services/i18n";
    import { activeSubSection, autoSavingWorkouts, group, loadingProgressions, previousSubSection, progressions, progressionsPage, workouts } from "../../../lib/store";
    import { assignIdAndSetStrings, generateUID } from "../../../lib/utils";
    import { createEventDispatcher, onDestroy, tick } from "svelte";
    import { getTrainingCardProgressions, getTrainingCardWorkouts, saveTrainingCardProgressions, saveTrainingCardWorkouts } from "../../../services/trainingCardServices";
    import { navigate } from "svelte-routing";
    import { validateWorkouts } from "./workoutsValidation";
    import ActionsProgressions from "./ActionsProgressions.svelte";
    import BannerHelp from "../../ReusableLayouts/BannerHelp.svelte";
    import Button, { Label } from "@smui/button";
    import Card, { Content } from "@smui/card";
    import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
    import Dialog from "@smui/dialog";
    import IconButton from "@smui/icon-button";
    import Loading from "../../ReusableLayouts/Loading.svelte";
    import LoadingInline from "../../ReusableLayouts/LoadingInline.svelte";
    import NoItemsToShow from "../../ReusableLayouts/NoItemsToShow.svelte";
    import SortableList from "@palsch/svelte-sortablejs";
    import Workout from "./Workout.svelte";
  
    export let customerId;
    export let trainingCard;

    const dispatch = createEventDispatcher();
    const sortableOptions = {
      group: "workouts",
      animation: 150,
      handle: ".workouts-drag-handle",
    };

    let disableButtons = false;
    let exitDialog;
    let isProgression = true;
    let loading = true;
    let progressionIndex = 0;
    let quitSaving = false;
    let saving = false;
    let tempWorkouts = [];

    function getItemById(id) {
      return tempWorkouts.find((item) => item.sortId == id);
    } 

    $: if (trainingCard) initProgressions();
    $: $progressionsPage = progressionIndex + 1;
    // $: if (trainingCard) logTrainingCardId('$:')

    const getWorkouts = async (tcId) => {
      try {
        let res = await getTrainingCardWorkouts(tcId);
        tempWorkouts = assignIdAndSetStrings(res);
      } catch (err) {
        console.log("getTrainingCardWorkouts err", err);
        displayError();
      }
    };

    const getProgressions = async (tcId) => {
      try {
        let res = await getTrainingCardProgressions(tcId);
        if (res) {
          $progressions = Object.values(res.progressions);
          $progressions = $progressions.map(p => {
            return assignIdAndSetStrings(p);
          });
        }
      } catch (err) {
        console.log("getTrainingCardProgressions err", err);
        displayError();
      }
    };

    const goToPreviousProgression = () => {
      if (progressionIndex - 1  >= 0) {
        progressionIndex--;
      }
    };

    const goToNextProgression = () => {
      if (progressionIndex + 1 < $progressions.length) {
        progressionIndex++;
      }
    };
  
    const saveProgressions = async () => {
      let validatedWorkouts = [];
      try {
        $progressions.forEach((p) => { 
          validatedWorkouts.push(validateWorkouts(p));
        });
        saving = true;
        await saveTrainingCardProgressions(validatedWorkouts, trainingCard.id);
        saving = false;
      } catch (err) {
        saving = false;
        console.log("saveWorkouts error", err);
        dispatch("error", {});
      }
    };
  
    const saveProgressionsAndExit = async () => {
      let validatedWorkouts = [];
      try {
        $progressions.forEach((p) => { 
          validatedWorkouts.push(validateWorkouts(p));
        });
        quitSaving = true;
        await saveTrainingCardProgressions(validatedWorkouts, trainingCard.id);
        quitSaving = false;
        $progressions = [];
        leavePage();
      } catch (err) {
        quitSaving = false;
        console.log("saveWorkouts error", err);
        dispatch("error", {});
      }
    };

    const saveWorkouts = async () => {
      try {
        if ($workouts && $workouts.length > 0 && trainingCard.id) {
          const validatedWorkouts = validateWorkouts($workouts);
          console.log('Autosaving workouts...');
          $autoSavingWorkouts = true;
          await saveTrainingCardWorkouts(validatedWorkouts, trainingCard.id);
          $autoSavingWorkouts = false;
          // I workout vengono quindi ricaricati, insieme ai codeId degli esercizi, così da evitare conflitti nello storage delle progressioni
          dispatch("refresh");
          console.log('Workouts saved!');
        }
      } catch (err) {
        console.log("saveWorkouts error", err);
        dispatch("error", {});
      }
    };
  
    const handleGoBack = () => {
      exitDialog.close();
      leavePage();
    };

    const handleAddProgression = () => {
      if (($progressions.length === 0) && ($progressions.length <= 5)) {
        $progressions = [
          [...tempWorkouts],
        ];
      } else {
        let progressionClone = [];

        $progressions[$progressions.length - 1].forEach(w => {
          progressionClone.push(JSON.parse(JSON.stringify(w)));
        });

        progressionClone.map(w => {
          w.sortId = generateUID();
        });

        $progressions = [
          ...$progressions,
          progressionClone,
        ];
        
        progressionIndex = $progressions.length - 1;
      };
      prepareProgression();
    }

    const prepareProgression = () => {
      $progressions.map(p => {
        return assignIdAndSetStrings(p);
      });  
      $progressions.map(p => {
        p.forEach((w, index) => {
          w.rank = index;
          if (!w.parent_workout_id) {
            w.parent_workout_id = w.workout_id;
            w.workout_id = null;
          }
          w.training_card_id = trainingCard.id;
        });
      });
    }

    const handleDeleteProgression = async (e) => {
      $progressions = $progressions.filter((p, i) => {
        return i != progressionIndex;
      });

      if (progressionIndex > 0) {
        progressionIndex--;
      }
    };

    const displayError = () => {
      let msgError = $_("si_è_verificato_un_errore");
      setTimeout(() => {
        msgError = "";
      }, 3000);
    };

    const initProgressions = async () => {
      $progressions = [];
      loading = true;
      $loadingProgressions = true;
      if ($activeSubSection == $_('progressioni') && $previousSubSection == $_('schede')) {
        await saveWorkouts();
      }
      await tick();
      await getWorkouts(trainingCard.id);
      await getProgressions(trainingCard.id);
      await tick();
      loading = false;
      $loadingProgressions = false;
    };
    
    const leavePage = () => {
      if (customerId) {
        navigate("/customer?tab=workouts", {
          state: { customerId },
        });
      } else if (trainingCard.is_assigned_to_group || trainingCard.assignedToGroups || searchParamsType == 'assignmentToGroups') {
        if ($group && $group.id) {
          navigate("group?tab=workouts&groupId=" + $group.id);
        } else {
          navigate("/team?tab=gruppi");
        }
      } else {
        navigate("/training-cards");
      }
    }

    // const logTrainingCardId = (text = '') => {
    //   try { 
    //     console.log('TCP | ' + text + ' | trainingCard.id => ', trainingCard.id);
    //   } catch (error) { 
    //     console.log('TCP | ' + text + ' | trainingCard.id ERROR => ', error);
    //   }
    // }

    onDestroy(() => {
      // $progressionsPage = null;
      progressionsPage.set(0);
    });
  </script>
  
  <style>
    :global(.list-no-style) {
      padding-inline-start: unset;
      list-style-type: none;
    }
    .pushToEnd {
      justify-content: flex-end;
    }
    .justifyCenter {
      justify-content: center;
    }
    * :global(.button--bigger) {
      min-width: 8rem;
    }
    * :global(.invisibleCard--loader) {
      min-height: 40vh;
    }
    @media (max-width: 1023px) {
      .onlyDesktop {
        display: none;
      }
    }
  </style>
  
  <Dialog bind:this={exitDialog}>
    <ConfirmationDialog
      text={$_('sei_sicuro_di_voler_uscire')}
      confirmationButtonLabel={$_('esci')}
      on:closeDialog={() => exitDialog.close()}
      on:onConfirmation={handleGoBack} />
  </Dialog>

  <!-- {#if !loading}
    {#if !$progressions || ($progressions && $progressions.length == 0)}
      <div class="container my-3">
        <BannerHelp state="progressions_first_alert"/>
      </div>
    {:else}
      <div class="container my-3">
        <BannerHelp state="progressions_second_alert"/>
      </div>
    {/if}
  {/if} -->
  
  <div class="mdc-typography--headline5 flexy">
    <IconButton
      class="material-icons"
      disabled={loading || progressionIndex == 0 || $progressions.length == 0}
      on:click={goToPreviousProgression}>
      keyboard_arrow_left
    </IconButton>
    {#if loading || $progressions.length == 0}
      <span class="bold">---</span>
    {:else}
      <span class="bold">{$_('settimana')} {(progressionIndex + 1) + '/' + $progressions.length}</span>
    {/if}
    <IconButton
      class="material-icons"
      disabled={loading || progressionIndex == $progressions.length - 1 || $progressions.length == 0}
      on:click={goToNextProgression}>
      keyboard_arrow_right
    </IconButton>
  </div>

  <ActionsProgressions
    loadingProgressions={loading}
    on:addProgression={handleAddProgression}
    on:deleteProgression={handleDeleteProgression} />

  <div class="row mx-0 justifyCenter" data-recording-gdpr-safe>
    <div class="col-12 px-0" data-recording-gdpr-safe>
      {#if !loading}
        {#if $progressions.length === 0}
          <Card class="invisibleCard">
            <Content>
              <NoItemsToShow
                upperText={$_('nessuna_progressione')}
                biggerText={$_('aggiungi_una_progressione')}
                icon="note_add"
                buttonText={$_('nuova_progressione')}
                on:addProgression={handleAddProgression} />
            </Content>
          </Card>
        {:else}
          {#if $progressions[progressionIndex]}
            <SortableList
              {sortableOptions}
              bind:items={$progressions[progressionIndex]}
              {getItemById}
              idKey="sortId"
              ulClass="list-no-style"
              liClass="mb-2"
              let:item>
              <Workout
                bind:isProgression
                workout={item} />
            </SortableList>
          {/if}
        {/if}
      {:else}
        <Card class="invisibleCard invisibleCard--loader">
          <Content>
            <Loading />
          </Content>
        </Card>
      {/if}
    </div>
  </div>
  
  {#if progressions.length > 0}
    <hr />
  {/if}

  
  <div class="row pushToEnd mt-3">
    <Button 
      class="btn" 
      disabled={saving || quitSaving}
      on:click={() => exitDialog.open()}>
      <Label>{$_('annulla')}</Label>
    </Button>
    <Button
      class="btn ml-5 mr-3 button--bigger"
      variant="unelevated"
      disabled={disableButtons || saving || quitSaving}
      on:click={saveProgressions}>
      <Label>
        <span class="{saving ? `pr-2` : ``}">{$_('salva')}</span>
        {#if saving}
          <LoadingInline />
        {/if}
      </Label>
    </Button>
    <Button
      class="btn ml-5 mr-3 button--bigger"
      variant="unelevated"
      disabled={disableButtons || saving || quitSaving}
      on:click={saveProgressionsAndExit}>
      <Label>
        <span class="{quitSaving ? `pr-2` : ``}">{$_('salva_ed_esci')}</span>
        {#if quitSaving}
          <LoadingInline />
        {/if}
      </Label>
    </Button>
  </div>
  