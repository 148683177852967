<script>
  import { _ } from "../../services/i18n";
  import { navigate } from "svelte-routing";
  import BillingSmartWrapper from "./Billing/BillingSmartWrapper.svelte";
  import AccountLayout from "./AccountLayout.svelte";
  import PersonalData from "./PersonalData.svelte";
  import PtProfileInfoLayout from "./PtProfileInfoLayout.svelte";
  import Notifications from "./Notifications.svelte";
  import Header from "../ReusableLayouts/Header.svelte";

  export let onLocaleChanged;
  export let location;

  let component = [$_("profilo_revoo")];
  let activeTab = $_("profilo_revoo");

  let isMobile = 
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;    

  let searchParamsTab = "profile";
  $: searchParamsTab = new URLSearchParams(location.search).get("tab");

  let tabs = [];

  if (window.location.host == "trainmobile.revoo-app.com") {
    tabs = [
      $_("profilo_revoo"),
      $_("notifiche"),
      $_("dati_personali"),
      $_("account"),
    ];  
  } else {
    tabs = [
      $_("profilo_revoo"),
      $_("notifiche"),
      $_("dati_personali"),
      $_("fatturazione"),
      $_("account"),
    ];  
  }

  $: if (searchParamsTab) {
    component = [mapTabsToComponent[searchParamsTab]];
    activeTab = mapTabsToComponent[searchParamsTab];
  }

  const mapTabsToComponent = {
    profile: $_("profilo_revoo"),
    notifiche: $_("notifiche"),
    data: $_("dati_personali"),
    billing: $_("fatturazione"),
    account: $_("account"),
  };
  const mapComponentsToTab = {
    [$_("profilo_revoo")]: "profile",
    [$_("notifiche")]: "notifiche",
    [$_("dati_personali")]: "data",
    [$_("fatturazione")]: "billing",
    [$_("account")]: "account",
  };

  let options = {
    [$_("profilo_revoo")]: PtProfileInfoLayout,
    [$_("notifiche")]: Notifications,
    [$_("dati_personali")]: PersonalData,
    [$_("fatturazione")]: BillingSmartWrapper,
    [$_("account")]: AccountLayout,
  };
  let props = {};

  const handleActiveTabChanged = (e) =>
    navigate(`/pt-info?tab=${mapComponentsToTab[e.detail.tab]}`, {
      replace: true,
    });

  const handleLocalChanged = (e) => {
    onLocaleChanged(e.detail);
  };
</script>

<Header
  asTitle={true}
  text={$_('impostazioni_profilo')}
  {tabs}
  {activeTab}
  on:activeTabChanged={handleActiveTabChanged}
  tabNavigationEnabled={true} />

<div class="container fluid">
  <svelte:component
    this={options[component]}
    {...props}
    on:locale-changed={handleLocalChanged} />
</div>
