<script>
  import { _ } from "../../../../services/i18n";
  import moment from "moment";

  export let panels = [];
  export let index;
  export let customerSubscription;
  export let isMobile;
  export let customerId;

</script>

<style>
  .customerSubscriptionPanel {
    background-color: #f9f9f9;
    padding: 16px;
  }
  .hide {
    display: none;
  }
  .textToEnd {
    text-align: end;
  }
  .titleWrapper {
    text-align: start;
    align-items: center;
    display: flex;
    opacity: 0.8;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .customDivider {
    border: 1px dashed #212121;
    opacity: 0.2;
  }
  .spaceBetween {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .textCenter {
    text-align: center;
  }
</style>

<div class={`customerSubscriptionPanel ${panels[index] ? '' : 'hide'}`}>
  <div class="container">
    <div class="row">
      <div class="col-6 text-center px-0 tc-start">
        <div class="col-12 label px-0">{$_('inizio')}</div>
        <div class="col-12 px-0">
          {customerSubscription.start ? moment(customerSubscription.start).format('DD/MM/YYYY') : '-'}
        </div>
      </div>
      <div class="col-6 text-center">
        <div class="col-12 label">{customerSubscription.paid ? $_('pagato') : $_('da_pagare')}</div>
        <div class="col-12">€ {(Math.round(customerSubscription.price * 100) / 100).toFixed(2)}</div>
      </div>
    </div>
  </div>
</div>