<script>
    import { _ } from "../../services/i18n";
    import { addImage } from "../../lib/utils";
    import { createEventDispatcher } from "svelte";
    import { Icon } from "@smui/common";
    import Button, { Label } from "@smui/button";
    import CropDialog from "./CropDialog.svelte";
    import Dialog from "@smui/dialog";
    import IconButton from "@smui/icon-button";

    const dispatch = createEventDispatcher();
  
    export let image;
    export let imageThumbnail;
    export let triggerCloseImageDialog;
    export let trainingCardCover = false;

    let error;
    let imageTemp = false;
    let imageCrop = "";
    let imageDialog = false;
  
    $: if (triggerCloseImageDialog && fullImageDialog) {
      fullImageDialog.close();
      triggerCloseImageDialog = false;
    }
  
    let fullImageDialog;
  
    const onInputChange = (e) => {
      const file = e.target.files[0];
      if (!file || file.size > 16000000) {
        error = $_("la_dimensione_massima_consentita_è_di", { values: { dimension: 16 } });
        setTimeout(() => {
          error = "";
        }, 3000);
        return;
      }
      dispatch("updateVodImage", {
        img: file,
      });
      addImage(file, "imageUploaded");
      handleImageAdded(file);
    };

    const handleImageAdded = (file) => {
      imageThumbnail = file;
      imageTemp = URL.createObjectURL(file);
      console.log('imageTemp', imageTemp);
      imageCrop = URL.createObjectURL(file);
      imageDialog.open();
    };

    const handleCroppedImage = (e) => {
      if (e.detail.image) {
        image = e.detail.image;
        imageThumbnail = URL.createObjectURL(e.detail.image);
        const img = document.getElementById("imageUploaded");
        img.src = URL.createObjectURL(e.detail.image);
      }
      imageTemp = false;
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const file = e.dataTransfer.files[0];
      dispatch("updateImage", {
        img: file,
      });
      addImage(file, "imageUploaded");
      handleImageAdded(file);
    };
  </script>
  
  <Dialog class="no-click-backdrop" bind:this={fullImageDialog}>
    <div class="close-icon-wrapper">
      <IconButton class="material-icons" on:click={() => fullImageDialog.close()}>
        close
      </IconButton>
    </div>
    <div class="justifyCenter imgContainer">
      <img loading="lazy" class="img-fluid" src={image} alt="image_upload" />
    </div>
  </Dialog>

  <Dialog
    bind:this={imageDialog}
    aria-labelledby="dialog-for-cropping-image"
    aria-describedby="image-cropper">
    <CropDialog
      {imageCrop}
      squared={false}
      mustCrop={false}
      trainingCardCover={true}
      on:closeDialog={() => imageDialog.close()}
      on:imageHasBeenCropped={handleCroppedImage} />
  </Dialog>
  
  <div class="d-flex flex-column">
      <div class="customUploader {trainingCardCover ? `trainingCardCoverUploader` : ``}" on:drop={handleDrop}>
        <input
            class="customUploader {trainingCardCover ? `trainingCardCoverUploader` : ``} d-flex customFileInput"
            type="file"
            id="file"
            accept="image/jpeg, image/png, image/webp"
            on:change={onInputChange} 
        />
      {#if !image}
        <div class="customUploader {trainingCardCover ? `trainingCardCoverUploader` : ``} d-flex">
          <div class="row">
            <div class="col-12 my-4">
              <span class="maxLabel">{`${$_('max')} 16 MB`}</span>
            </div>
          </div>
          <div class="row {!trainingCardCover ? `mt-4` : ``}">
            <div class="col-12 indications">
              <span>{$_('seleziona_foto')}</span>
              <br />
              <span>{$_('o_trascina')}</span>
              <br />
              <Icon
                role="button"
                tabindex="1"
                class="material-icons mdc-text-field__icon
                  mdc-text-field__icon--trailing pt-2 pb-3 primary">
                camera_alt
              </Icon>
            </div>
          </div>
        </div>
      {:else}
        <div
          class={`imageWrapper ${trainingCardCover ? 'trainingCardCoverUploader' : ''} flexy ${imageThumbnail ? 'bg--grey' : 'bg--black'}`}>
          <div class="layer">
            <div class="bringToFront">
              {#if typeof image == 'string'}
                <Button
                  class="btn mt-3 customColor"
                  variant="unelevated"
                  target="_blank"
                  on:click={() => {
                    fullImageDialog.open();
                  }}>
                  <Label>{$_('visualizza')}</Label>
                </Button>
              {/if}
              <Button
                class="btn mt-3 customColor"
                variant="outlined"
                target="_blank"
                on:click={() => {
                  imageThumbnail = null;
                  image = null;
                }}>
                <Label>{$_('elimina')}</Label>
              </Button>
            </div>
          </div>
          {#if trainingCardCover}
            {#if !imageThumbnail}
              <div class="image-selected-wrapper" id="output" />
            {:else}
              <img loading="lazy" src={typeof imageThumbnail == "string" ? imageThumbnail : imageTemp} id="output" alt="image_upload1" />
            {/if}  
          {:else}
            {#if !imageThumbnail}
              <div class="image-selected-wrapper" id="output" />
            {:else}
              <img loading="lazy" src={imageThumbnail} id="output" alt="image_upload2" />
            {/if}
          {/if}
        </div>
      {/if}
    </div>
    <span class="pt-2 text-danger pr-2">{error ? error : ''}</span>
  </div>
  
  <style>
    .customUploader {
      background-color: #f3f3f3;
      border-radius: 10px;
      flex-direction: column;
      height: 13em;
      position: relative;
      text-align: center;
      width: 13em;
    }
    .customFileInput {
      left: 0px;
      opacity: 0;
      position: absolute;
      z-index: 5;
    }
    .imageWrapper {
      align-items: center;
      border-radius: 10px;
      display: flex;
      height: 13em;
      overflow: hidden;
      position: relative;
      width: 13em;
    }

    .trainingCardCoverUploader {
      height: 179px;
      width: 389px;
    }
    * :global(.imageWrapper img) {
      height: 100% !important;
      width: auto !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .maxLabel {
      font-size: 0.8rem;
      font-weight: 600;
      opacity: 0.5;
    }
    .indications {
      font-weight: 600;
      opacity: 0.8;
    }
    * :global(.bringToFront) {
      align-content: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;
      z-index: 9;
    }
    .layer {
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.33);
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      cursor: pointer;
    }
    .layer:hover {
      opacity: 1;
      transition: all 0.3s ease;
    }
    * :global(.customColor) {
      background-color: var(--mdc-theme-on-primary, #fff);
      color: #000;
    }
    * :global(.customColor.mdc-button--outlined:not(:disabled)) {
      background-color: transparent;
      border-color: var(--mdc-theme-on-primary, #fff);
      color: var(--mdc-theme-on-primary, #fff);
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
    .imgContainer {
      background-color: #000;
      width: 100%;
      height: auto;
    }
    .imgContainer > img {
      width: 100%;
      height: auto;
    }
    .close-icon-wrapper {
      position: absolute;
      right: 0;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
    }
    .image-selected-wrapper {
      max-width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  </style>