<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import VirtualList from "@sveltejs/svelte-virtual-list";
  const dispatch = createEventDispatcher();
  import { _ } from "../../../services/i18n";
  import { navigate } from "svelte-routing";
  import Card, { Content, Actions, Media } from "@smui/card";
  import Button, { Label } from "@smui/button";
  import { Icon } from "@smui/common";
  import IconButton from "@smui/icon-button";
  import Textfield, { Input } from "@smui/textfield";
  import Loading from "../../ReusableLayouts/Loading.svelte";
  import {
    getTrainingCardDetails,
  } from "../../../services/trainingCardServices";

  export let msgError;
  export let cards = [];

  export let search;
  export let ascOrder;
  export let loading = true;

  $: if (search.length > 0 || search !== "")
    delaySearch();

  let typingTimer;
  let doneTypingInterval = 600;

  const configSearch = () => {
    let searchInput = document.getElementById("searchInput");
    if (searchInput) {
      searchInput.addEventListener("keyup", () => {
        delaySearch();
      });
    }
  };

  const delaySearch = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      (_) => dispatch("searchChange", { search }),
      doneTypingInterval
    );
  };

  let panels = [];

  $: if (cards) {
    panels = new Array(cards.length).fill(false);
    for (let i = 0; i < cards.length; i++) {
      cards[i].index = i;
    }
  }

  let levelsMap = {
    elementary: $_("elementare"),
    beginner: $_("principiante"),
    intermediate: $_("intermedio"),
    advanced: $_("avanzato"),
  };

  const getCombinationIcon = (comb) => {
    if (comb == "normal") return "remove";
    else if (comb == "time_circuit") return "timer";
    else return "sync";
  };

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };

  const importCard = (id) => {
    dispatch("importCard", { id });
  };

  const importWorkout = (id) => {
    dispatch("importWorkout", { id });
  };

  const getDetails = async (item) => {
    const res = await getTrainingCardDetails(item.id);
    if (res) {
      item.workouts = res;
      panels[item.index] = !panels[item.index];
    }
  };

  const changeOrder = () => {
    dispatch("orderChange", { ascOrder: !ascOrder });
  };

  onMount(() => {
    configSearch();
  });
</script>

<style>
  .athleteDialogContainer {
    height: 90vh;
  }

  .containerVirtual {
    max-width: 100%;
    position: relative;
    height: 70vh;
  }

  h5 {
    padding: 0;
  }
  * :global(.mdc-card.invisibleCard) {
    box-shadow: none;
  }
  * :global(.mdc-text-field) {
    height: 40px;
    margin-right: 0.8em;
    width: 20em;
  }

  * :global(.mdc-text-field__input) {
    height: auto;
    margin: auto;
    padding: 0 0.5em !important;
  }
  *
    :global(.mdc-text-field--with-leading-icon.mdc-text-field--outlined
      .mdc-floating-label) {
    top: 12px;
  }
  * :global(.material-icons.searchIcon) {
    padding: 10px 0px 0px 4px !important;
  }
  .header {
    align-items: center;
    background-color: #f9f9f9;
  }
  .line {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
  .alignHeader {
    align-items: center;
    justify-content: space-between;
  }
  .pushToEnd {
    justify-content: flex-end;
    text-align: right;
  }
  .alignContentCenter {
    align-items: center;
    display: flex;
  }
  .textWrapper {
    display: flex;
    justify-content: center;
    opacity: 0.8;
    word-break: break-word;
  }
  .textTitle {
    color: #212121;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.5;
    text-align: center;
  }
  .nameWrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
  }
  .alignToBaseline {
    align-items: baseline;
  }
  .textOpacity {
    opacity: 0.8;
  }
  .workoutsList {
    background-color: #f9f9f9;
    padding: 16px;
  }
  .customDivider {
    border: 1px dashed #212121;
    opacity: 0.2;
  }
  .hide {
    display: none;
  }
  .alignStart {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
  .exercisesWrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .combinationsWrapper {
    align-items: flex-start;
    display: flex;
  }
  .imageWrapper {
    align-items: center;
    background-color: grey;
    border-radius: 50%;
    display: flex;
    height: 50px;
    width: 50px;
  }
  .imagecustomer {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
  * :global(.opacityReduce) {
    opacity: 0.8;
  }
  .searchbar {
    order: initial;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 767px) {
    .searchbar {
      order: 3;
    }
    .desktop {
      display: none;
    }
  }
</style>

<div class="athleteDialogContainer">
  <div class="header px-4 py-2">
    <div class="row no-gutters alignHeader">
      <div class="col-11 col-md-6 col-lg-7 my-4">
        <h5 class="mb-0 bold">{$_('copia_allenamento_di_atleta')}</h5>
      </div>
      <div class="col-12 col-md-5 col-lg-4 searchbar">
        <Textfield
          variant="outlined"
          withLeadingIcon
          id="searchInput"
          bind:value={search}
          class="alignContentCenter"
          label={$_('cerca_per_nome_atleta_o_piano')}>
          <Icon class="material-icons searchIcon">search</Icon>
        </Textfield>
      </div>
      <div class="col-1 col-md-1 pushToEnd">
        <IconButton
          class="material-icons"
          on:click={() => dispatch('closeDialog', {})}>
          close
        </IconButton>
      </div>
    </div>
  </div>

  {#if !loading}
    <div class="container fluid mt-3">
      {#if msgError}
        <div class="alert alert-danger fade show" role="alert">{msgError}</div>
      {/if}
      <div class="desktop">
        <Card class="invisibleCard">
          <Content class="pb-0">
            <div class="row alignContentCenter">
              <span
                class="col-6 col-xs-6 col-md-3 col-lg-2 textTitle textLeft nameWrapper textWrapper"
                style="justify-content: flex-start"
                on:click={changeOrder}>
                {$_('nome_piano')}
                <Icon
                  class="material-icons mdc-text-field__icon
                    mdc-text-field__icon--trailing ml-2">
                  {ascOrder ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
              </span>
              <div class="col-md-3 col-lg-3 textTitle">{$_('atleta')}</div>
              <div class="col-1 d-none d-lg-block d-xl-block textTitle">{$_('suddiviso')}</div>
              <div class="col-lg-2 d-none d-lg-block d-xl-block textTitle">{$_('durata')}</div>
              <div class="col-md-2 col-lg-1 textTitle">{$_('livello')}</div>
              <div class="">
                <div class="col-md-3 col-lg-3 textTitle" />
              </div>
            </div>
          </Content>
        </Card>
        <hr class="line" />
      </div>

      <div class={`${cards.length > 0 ? 'containerVirtual' : ''}`}>
        {#if cards.length > 0}
          <VirtualList items={cards} let:item>
            <Card class="mb-2">
              <Content>
                <div
                  class="row alignContentCenter"
                  on:click={() => (getDetails(item))}>
                  <div
                    class="col-3 col-lg-2 nameWrapper opacityReduce demiBold textLeft breakTitle pr-0"
                    on:click>
                    {item.name}
                  </div>
                  <div class="col-4 col-md-3 alignContentCenter">
                    {#if item.customer_image}
                      <img
                        src={item.customer_image}
                        alt=""
                        class="imagecustomer mr-2" />
                    {:else}
                      <div class="imageWrapper">
                        <div style="" />
                      </div>
                    {/if}
                    <span
                      class="textWrapper">{`${item.customer_first_name} ${item.customer_last_name}`}</span>
                  </div>
                  <div class="col-1 d-none d-lg-block d-xl-block text-center textWrapper">
                    {item.workouts_length ? $_('schede_', {
                          values: { schede: item.workouts_length },
                        }) : '-'}
                  </div>
                  <div class="col-lg-2 d-none d-lg-block d-xl-block textWrapper text-center desktop">
                    {item.length ? $_('settimane', {
                          values: { settimane: item.length },
                        }) : '-'}
                  </div>
                  <div class="col-md-2 col-lg-1 textWrapper desktop">
                    {item.level ? levelsMap[item.level] : '-'}
                  </div>
                  <div
                    class="col-5 col-md-4 col-lg-3 alignContentCenter
                      pushToEnd">
                    <Button
                      class="btn opacityReduce"
                      color="secondary"
                      variant="unelevated"
                      target="_blank"
                      on:click={() => importCard(item.id)}>
                      <Label>{$_('copia_piano')}</Label>
                    </Button>
                    <IconButton class="material-icons">
                      keyboard_arrow_down
                    </IconButton>
                  </div>
                </div>
              </Content>
              <div class={`workoutsList ${panels[item.index] ? '' : 'hide'}`}>
                {#if item.workouts && item.workouts.length > 0}
                    {#each item.workouts as workout, i}
                      <div class="row no-gutters alignToBaseline">
                        <div class="col-3 col-md-3 col-lg-3 titleWrapper demiBold">
                          {workout.name}
                        </div>
                        <div class="col-6 col-md-6 col-lg-6 textCenter">
                          {#each workout.combinations as comb}
                            <div
                              class={`col-12 textOpacity ${comb.method == 'normal' ? 'alignStart' : 'combinationsWrapper'}`}>
                              <IconButton class="material-icons">
                                {getCombinationIcon(comb.method)}
                              </IconButton>
                              <div
                                class="exercisesWrapper"
                                style={comb.method == 'normal' ? '' : 'padding-top: 0.9rem'}>
                                {#each comb.exercises as ex}<span>{ex}</span>{/each}
                              </div>
                            </div>
                          {/each}
                        </div>
                        <div class="col-3 col-md-3 col-lg-3 pushToEnd">
                          <Button
                            class="btn opacityReduce"
                            color="secondary"
                            variant="outlined"
                            target="_blank"
                            on:click={() => importWorkout(workout.workout_id)}>
                            <Label>{$_('copia_scheda')}</Label>
                          </Button>
                        </div>
                      </div>
                      <hr class={`customDivider ${i == item.workouts.length - 1 ? 'hide' : ''}`} />
                    {/each}
                  {:else}
                    <div class="row no-gutters position-relative">
                    </div>
                  {/if}
              </div>
            </Card>
          </VirtualList>
        {/if}
      </div>
    </div>
  {:else}
    <div class="row no-gutters position-relative">
      <Loading />
    </div>
  {/if}
</div>
