<script>
  import { _ } from "../../../../services/i18n";
  import { getExercises, getExercisesList, maxPage } from "../../../../services/exercisesServices";
  import { exercises, jwt, group } from "../../../../lib/store";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import { onMount, createEventDispatcher } from "svelte";
  import AssignedTrainingCardList from "./AssignedTrainingCardsList.svelte";
  import BannerHelp from "../../../ReusableLayouts/BannerHelp.svelte";
  import Button, { Label } from "@smui/button";
  import Loading from "../../../ReusableLayouts/Loading.svelte";
  import LoadingInline from "../../../ReusableLayouts/LoadingInline.svelte";
  import MsgError from "../../../ReusableLayouts/MsgError.svelte";
  import NoItemsToShow from "../../../ReusableLayouts/NoItemsToShow.svelte";
  
  export let firstCards = [];
  export let groupId = null;
  export let isMobile;
  export let loadingTrainingCards = true;
  export let nextCards = [];
  
  let msgError;
  
  const dispatch = createEventDispatcher();

  const displayError = () => {
    msgError = $_("si_è_verificato_un_errore");
    setTimeout(() => {
      msgError = "";
    }, 3000);
  };
  
  const addCards = () => {
    // display 3 more cards
    const cardsToBeDisplayed = nextCards.slice(0, 5);
    firstCards = [...firstCards, ...cardsToBeDisplayed];
    nextCards = nextCards.slice(5);
  };

  const handleRemoveTrainingCard = (e) => {
    firstCards = firstCards.map((i) => {
      if (i.id == e.detail.id) i.is_active = 0;
      return i;
    });
  };

  const handleUpdateDraftTrainingCard = (e) => {
    e.detail.is_draft = e.detail.is_draft ? 1 : 0;
    firstCards = firstCards.map((c) => {
      if (c.id === e.detail.id) c.is_draft = e.detail.is_draft;
      return c;
    });
  };

  const handleUpdateCards = () => {
    dispatch('updateCards');
  }

  onMount(async () => {
    // await getExercises(true, 0);
    await getExercisesList(true);
    
    // If empty, get base + pt exercises and save them in local storage
    if ($jwt && (!localStorage.getItem('exercises') || $exercises.length == 0 || $exercises.filter(e => e.is_active).length === 0)) {
      // await getExercises(true, 2);
      await getExercisesList(true);
    }
  });
</script>

<style>
  .headline {
    font-size: 1.25rem;
  }
  .rowSpace {
    padding: 2rem 0rem;
  }
  * :global(.saveBtn) {
    background: #cd293d !important;
    color: #fff !important;
    border: 0 !important;
  }
  * :global(.mdc-text-field.equipmentDescription) {
    height: auto;
    width: 100% !important;
  }
  * :global(.mdc-text-field--disabled .mdc-text-field__input) {
    color: black !important;
  }
</style>

<div class="container fluid">
  <MsgError {msgError} />

  <div class="my-3">
    <BannerHelp state='assigned_training_cards'/>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end my-3">
          <Button
            class="ml-4"
            color="secondary"
            variant="unelevated"
            disabled={!groupId}
            on:click={() => navigate('/new-training-card?tab=workouts&groupId=' + groupId + '&type=assignmentToGroups', {
                state: { groupId },
              })}>
            <Label>
              <Icon
                role="button"
                class="material-icons mdc-text-field__icon
                  mdc-text-field__icon--trailing">
                groups
              </Icon>
              {$_('nuovo_piano')}
              {#if !groupId}
                <LoadingInline />
              {/if}
            </Label>
          </Button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column rowSpace">
          <div class="headline bold mb-2">{$_('allenamenti')}</div>
        </div>
      </div>
    </div>
  </div>

  {#if loadingTrainingCards}
    <Loading />
  {:else}
    {#if firstCards && firstCards.length > 0}
      <AssignedTrainingCardList
        {firstCards}
        {groupId}
        {isMobile}
        on:onError={displayError}
        on:removeTrainingCard={handleRemoveTrainingCard}
        on:updateDraftTrainingCard={handleUpdateDraftTrainingCard}
        on:updateCards={handleUpdateCards} />

      <div class="flexy my-3">
        <Button
          color="secondary"
          variant="outlined"
          disabled={nextCards.length == 0}
          on:click={addCards}>
          <Label>{$_('mostra_altre')}</Label>
        </Button>
      </div>
    {:else}
      <NoItemsToShow
        upperText={$_('nessun_allenamento')}
        biggerText={`${$_('crea_primo_piano_allenamento')} ${$group.name}`}
        icon="groups"
        buttonText={$_('nuovo_piano')}
        pathToNavigateTo={`new-training-card?groupId=` + groupId + `&type=assignmentToGroups`}
        routeState={{ state: { groupId } }} />
    {/if}
  {/if}
</div>
