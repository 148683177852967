<script>
    import { _ } from "../../services/i18n";
    import { addEvent, deleteEvent } from "../../services/calendar";
    import { beforeUpdate, createEventDispatcher, tick } from "svelte";
    import { convertDataFromBE, handleDataForBE } from "./calendarUtils";
    import { get } from "svelte/store";
    import { reminderDefault } from "../../lib/mockupCalendarData";
    import Button, { Label } from "@smui/button";
    import ConfirmDialog from "./ConfirmDialog.svelte";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import IconButton from "@smui/icon-button";
    import ReminderContent from "./InnerComponents/ReminderContent.svelte";

    export let openDialog;
    export let selectData;
    export let dataFromBe;

    let reminder = {};
    reminder = Object.assign(reminder, get(reminderDefault));
    let automaticReopen = false;
    let clickOnClose = false;
    let closedReminderDialog = false;
    let confirmationDialog = false;
    let confirmationDialogText = "";
    let createOnCustomer = false;
    let dialog;
    let onEdit = false;
    let openOnSelect = false;
    let quitSaving = false;
    let whoCallConfirmDialog;

    beforeUpdate(() => {
        if (dataFromBe && dataFromBe.type == 1 && !onEdit) {
            reminder = convertDataFromBE(dataFromBe);
            onEdit = true;
        }
        if (selectData) {
            reminder.startDate = selectData.start;
            reminder.startHour = selectData.startTime;
            reminder.endDate = selectData.end;
            reminder.endHour = selectData.endTime;
            if (selectData.hasOwnProperty("customers")) {
                createOnCustomer = true;
                reminder.customers = selectData.customers;
            }
            // openOnSelect = true;
        }
    });

    const dispatch = createEventDispatcher();

    $: quitSaving = reminder.title ? false : true;

    $: {
        if (openDialog == true && !dialog.isOpen()) {
            dialog.open();
            clickOnClose = false;
        }
    }

    const cleanReminder = async () => {
        reminder = Object.assign(reminder, get(reminderDefault));
        await tick();
        closedReminderDialog = true;
    };

    const handleDialogClose = async () => {
        if (onEdit) {
            dataFromBe = undefined;
            onEdit = false;
        }
        if (createOnCustomer) createOnCustomer = false;
        if (dialog && dialog.isOpen()) {
            dialog.close();
            clickOnClose = true;
            dispatch("closeInvitationDialog");
        }
        openOnSelect = false;
        if (!clickOnClose) dispatch("closeInvitationDialog");
        if (!confirmationDialog) {
            if (reminder.hasOwnProperty("id")) delete reminder.id;
            await cleanReminder();
        }
    };

    const deleteReminder = async () => {
        await deleteEvent(reminder.id);
        dispatch("eventDeleted");
        await cleanReminder();
    };

    const saveProgressionsAndExit = async () => {
        const reminderBE = handleDataForBE(reminder, onEdit);
        // console.log("saveProgressAndExit", reminderBE);
        try {
            let result = await addEvent(reminderBE);
            dispatch("eventCreated");
            // console.log("all ok", result);
        } catch (error) {
            console.log("error BE", error);
        }
    };

    const handleCallDialog = (whoCall) => {
        if (whoCall == "invitation") {
            if (appointment.send_email_update == 1) {
                whoCallConfirmDialog = whoCall;
                confirmationDialog = true;
                confirmationDialogText = $_(
                    "sei_sicuro_di_voler_confermare_invio_email"
                );
            } else saveProgressionsAndExit();
        }
        if (whoCall == "delete") {
            whoCallConfirmDialog = whoCall;
            confirmationDialog = true;
            confirmationDialogText = $_(
                "sei_sicuro_di_voler_eliminare_l_evento"
            );
        }
    };

    const handleConfirmDialog = async (response) => {
        if (response && whoCallConfirmDialog == "delete") {
            deleteReminder();
        }
        if (response && whoCallConfirmDialog == "invitation") {
            saveProgressionsAndExit();
        }
        if (!response && whoCallConfirmDialog == "invitation") {
            await tick();
            dialog.open();
            clickOnClose = false;
            automaticReopen = true;
        }
    };
</script>

<ConfirmDialog
    bind:openDialog={confirmationDialog}
    bind:text={confirmationDialogText}
    on:onCancel={() => handleConfirmDialog(false)}
    on:onConfirm={() => handleConfirmDialog(true)}
/>

<div>
    <Dialog
        class="dialog--xsmall reminderDialog"
        bind:this={dialog}
        on:MDCDialog:closed={() => handleDialogClose()}
    >
        <Title class="d-flex">
            <h5 class="bold pt-3" style="flex-grow: 1;">
                {#if dataFromBe}
                    {$_('modifica_promemoria')}
                {:else}{$_('crea_promemoria')}{/if}
            </h5>
            <IconButton
                class="material-icons"
                on:click={() => handleDialogClose()}
            >
                close
            </IconButton>
        </Title>
        <Content>
            <ReminderContent
                bind:automaticReopen
                bind:closedReminderDialog
                bind:createOnCustomer
                bind:onEdit
                bind:openDialog
                bind:openOnSelect
                bind:reminder
            />
        </Content>
        <Actions>
            <div class="row-grid-auto mt-0 mt-sm-3">
                <div class="col-12 col-sm-8 px-0 px-sm-3">
                    {#if dataFromBe}
                        <Button
                            class="btn"
                            variant="outlined"
                            color="secondary"
                            disabled={quitSaving}
                            on:click={() => handleCallDialog('delete')}
                        >
                            <Label><span>{$_('cancella')}</span></Label>
                        </Button>
                    {/if}
                </div>
                <div class="d-flex justify-content-end align-items-center">
                    <Button
                        class="btn d-sm-inline-flex annulla-mobile {dataFromBe ? 'd-none' : 'd-inline-flex'}"
                        on:click={() => handleDialogClose()}
                    >
                        <Label>{$_('annulla')}</Label>
                    </Button>
                    <Button
                        class="btn mx-0 ml-sm-5 mr-sm-3 button--bigger"
                        variant="unelevated"
                        disabled={quitSaving}
                        on:click={() => saveProgressionsAndExit()}
                    >
                        <Label><span>{$_('salva_ed_esci')}</span></Label>
                    </Button>
                </div>
            </div>
        </Actions>
    </Dialog>
</div>

<style>
    h5 {
        padding: 0;
    }
    .row-grid-auto {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        width: 100%;
        justify-items: flex-end;
    }
    * :global(.defaultActiveSwitch) {
        min-height: 100px;
    }
    * :global(.invitationCode) {
        color: #000000;
    }
    * :global(.material-icons) {
        font-size: 1.8rem;
    }
    * :global(.invitationActions) {
        padding-bottom: 8px;
    }
    * :global(.mdc-dialog.reminderDialog .mdc-dialog__surface),
    * :global(.mdc-dialog.reminderDialog .mdc-dialog__container) {
        max-width: 80vw;
        width: 900px !important;
    }

    * :global(.mdc-dialog__surface) {
        height: max-content;
    }

    * :global(.mdc-dialog__title + .mdc-dialog__content) {
        padding: 24px 64px;
    }
    * :global(.mdc-dialog__actions) {
        padding: 24px 64px;
    }
    * :global(.mdc-dialog__title) {
        display: flex;
        padding: 10px 39px;
        background-color: #f9f9f9;
        opacity: 0.8;
    }
    * :global(.whatsAppButton) {
        height: 50px;
        background-color: #22d366;
        color: white;
        border: 1px solid #ebeaea;
    }
    * :global(.mdc-text-field__icon--trailing.customLabel) {
        color: #212121;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.5px;
        opacity: 0.5;
    }
    * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
        border-color: var(--primary);
    }
    * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
        border-color: var(--primary);
    }

    @media (max-width: 991px) {
        * :global(.dialog--xxsmall),
        * :global(.dialog--xsmall),
        * :global(.dialog--small),
        * :global(.dialog--medium),
        * :global(.dialog--big),
        * :global(.dialog--bigger),
        * :global(.mdc-dialog__container),
        * :global(.mdc-dialog__surface) {
            min-height: 100% !important;
        }
    }

    @media (max-width: 767px) {
        * :global(.mdc-dialog__content) {
            max-height: fit-content;
            flex-grow: 0;
            padding: 24px !important;
        }
        * :global(.mdc-dialog__actions) {
            padding: 24px !important;
        }
    }

    @media (max-width: 576px) {
        * :global(.mdc-dialog__title) {
            padding: 10px 24px;
        }
        * :global(.annulla-mobile) {
            position: absolute;
            left: 24px;
        }
    }
</style>
