<script>
  import { _ } from "../../../services/i18n";
  import { activeSection, activeSubSection, backToWorkoutsTab, group, loadingWorkouts, previousSubSection, progressionsPage, trainingCard as storedTrainingCard, user, workouts } from "../../../lib/store";
  import { assignIdAndSetStrings, removeWorkoutIds } from "../../../lib/utils";
  import { getTrainingCardWorkouts, saveTrainingCardInfo, saveTrainingCardWorkouts, updateTrainingCardInfo } from "../../../services/trainingCardServices";
  import { Icon } from "@smui/common";
  import { navigate } from "svelte-routing";
  import { onDestroy, onMount, tick } from "svelte";
  import { validateWorkouts } from "./workoutsValidation";
  import Card, { Content } from "@smui/card";
  import ConfirmationDialog from "../../ReusableLayouts/ConfirmationDialog.svelte";
  import Dialog, { Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import MsgError from "../../ReusableLayouts/MsgError.svelte";
  import TrainingCardAttachments from "./TrainingCardAttachments.svelte";
  import TrainingCardHeader from "./TrainingCardHeader.svelte";
  import TrainingCardInfo from "./TrainingCardInfo.svelte";
  import TrainingCardProgressions from "./TrainingCardProgressions.svelte";
  import TrainingCardWorkouts from "./TrainingCardWorkouts.svelte";

  export let location;
  export let onLocaleChanged;
  export let showNavbar;

  let activeTab = $_("info_piano");
  let component;
  let customerId;
  let exitDialog;
  let isMobile = 
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;
  let msgError;
  let navbar;
  let options = {
    [$_("info_piano")]: TrainingCardInfo,
    [$_("schede")]: TrainingCardWorkouts,
    [$_("progressioni")]: TrainingCardProgressions,
    [$_("allegati")]: TrainingCardAttachments,
  };
  let preAssignedGroupId;
  let props = {};
  let saveWorkoutsDialog;
  let searchParamsGroupId = null;
  let searchParamsType = "template";
  let tabs = [
    $_("info_piano"), 
    $_("schede"),
    $_("progressioni"),
    $_("allegati"),
  ];

  if (!env.isProd || $user.is_tester) {
    // Add here customized tabs for testers
  }

  let trainingCard = {
    id: location && location.state && location.state.trainingCard && location.state.trainingCard.id 
      ? location.pathname == "/training-card" 
        ? location.state.trainingCard.id 
        : null
      : "",
    name: "",
    start: "",
    length: "",
    level: "",
    note: "",
    is_assigned_to_customer: 0,
    is_assigned_to_group: 0,
    is_template: 0,
  };
  let trainingCardCanBeDeleted;
  let workoutsToAttachmentsDialog;

  $: $storedTrainingCard = trainingCard;  

  $: if (location && location.state && location.state.customerId)
    customerId = location.state.customerId;

  $: if (location && location.state && location.state.groupId) {
    preAssignedGroupId = location.state.groupId;
  }

  $: searchParamsType = new URLSearchParams(location.search).get("type");
  $: searchParamsTab = new URLSearchParams(location.search).get("tab");
  $: searchParamsGroupId = new URLSearchParams(location.search).get("groupId");

  // $: if (trainingCard) logTrainingCardId('$:')

  $: {
    if (searchParamsTab == 'info') {
      activeTab = $_("info_piano");
      component = $_("info_piano");
    } else if (searchParamsTab == "workouts") {
      activeTab = $_("schede");
      component = $_("schede");
    } else if (searchParamsTab == "progressions") {
      activeTab = $_("progressioni");
      component = $_("progressioni");
    } else if (searchParamsTab == "attachments" && !env.isProd) {
      activeTab = $_("allegati");
      component = $_("allegati");
    }
  }

  $: if ((activeTab == $_("schede") || activeTab == $_("progressioni")) && navbar) {
    navbar.classList.add("position-relative");
    document
      .getElementsByClassName("main__content")[0]
      .classList.remove("padding-navbar");
  } else if (navbar) {
    navbar.classList.remove("position-relative");
    document
      .getElementsByClassName("main__content")[0]
      .classList.add("padding-navbar");
  }

  // Hide progressions on prod
  $: if (activeTab == $_("info_piano")) {
      props = {
        trainingCard,
        customerId,
        preAssignedGroupId,
      } 
    } else {
      props = {
        trainingCard,
        customerId,
      }
    }

  // Show progressions only on assigned customer/group training cards
  $: if ($storedTrainingCard && $storedTrainingCard.is_template === 1) {
    tabs = [
      $_("info_piano"), 
      $_("schede"),
    ];
    // Houston-2241
    progressionsPage.set(0);
  }

  $: trainingCardCanBeDeleted = component == $_("schede") && trainingCard.id;

  const handleGoBack = () => {
    exitDialog.close();
    $workouts = [];
    if (customerId) {
      navigate("/customer?tab=workouts", {
        state: { customerId },
      });
    } else if (trainingCard.is_assigned_to_group || trainingCard.assignedToGroups || searchParamsType == 'assignmentToGroups') {
      if ($group && $group.id) {
        navigate("group?tab=workouts&groupId=" + $group.id);
      } else {
        navigate("/team?tab=gruppi");
      }
    } else {
      navigate("/training-cards?tab=modelli");
    }
  };

  const getWorkouts = async (tcId, removeIds = false) => {
    try {
      $loadingWorkouts = true;
      let res = await getTrainingCardWorkouts(tcId);
      if (removeIds) res = res.map((w) => removeWorkoutIds(w));
      $workouts = assignIdAndSetStrings(res);
      await tick();
      $loadingWorkouts = false;
    } catch (err) {
      $loadingWorkouts = false;
      console.log("getTrainingCardWorkouts err", err);
      displayError(err);
    }
  };

  const displayError = (err = null) => {
    let timeoutTime = 3000;
    msgError = $_("si_è_verificato_un_errore");
    if (err.message.exception && err.message.exception === 'PlanConflictException') {
      
      msgError = $_("plan_conflict_exception");
      timeoutTime = 9000000;
    }
    setTimeout(() => {
      msgError = "";
    }, timeoutTime);
  };

  const goToWorkouts = async (e) => {
    let res;
    try {
      if (trainingCard.id) {
        res = await updateCardInfo(e.detail.trainingCard);
      } else {
        res = await saveCardInfo(e.detail.trainingCard);
      }
      if (res === true) {
        activeTab = $_("schede");
        component = [$_("schede")];
        await tick();
        updateTab();
      }
    } catch (err) {
      console.log("save or update tc err", err);
      displayError(err);
    }
  };

  const goToWorkoutsList = async (e) => {
    let res;
    try {
      if (trainingCard.id) {
        res = await updateCardInfo(e.detail.trainingCard);
      } else {
        res = await saveCardInfo(e.detail.trainingCard);
      }
      await tick();
      if (res === true) {
        leavePage();
      }
    } catch (err) {
      console.log("save or update tc err", err);
      displayError(err);
    }
  };

  const handleActiveTabChanged = (e, skipTabCheck = false) => {
    // Controlla se l'utente sta passando da Schede a Info Piano, o da Schede a Allegati e apri popup
    if ($activeSubSection == $_('schede') && e.detail.tab == $_('info_piano') && !skipTabCheck) {
      saveWorkoutsDialog.open();
    } 
    else if ($activeSubSection == $_('schede') && e.detail.tab == $_('allegati') && !skipTabCheck) {
      workoutsToAttachmentsDialog.open();
    }
    else {
      $previousSubSection = $activeSubSection;
      $activeSubSection = e.detail.tab;

      activeTab = e.detail.tab;
      component = [e.detail.tab];

      customerId = customerId ?? null;
      preAssignedGroupId = preAssignedGroupId ?? null;

      updateTab();
    }
  };

  const handleUndoChangeActiveTab = async () => {   
    activeTab = $activeSubSection;
    $backToWorkoutsTab = true;
    saveWorkoutsDialog.close();
    workoutsToAttachmentsDialog.close();
  }

  const updateTab = () => {
    if (component == $_("info_piano"))
      navigate(`/training-card?tab=info&trainingCardId=` + trainingCard.id + "&customerId=" + customerId + "&groupId=" + preAssignedGroupId, {
        replace: true,
        state: { 
          customerId: customerId,
          groupId: preAssignedGroupId, 
          trainingCard: trainingCard,
        },
      });
    else if (component == $_("schede"))
      navigate(`/training-card?tab=workouts&trainingCardId=` + trainingCard.id + "&customerId=" + customerId + "&groupId=" + preAssignedGroupId, {
        replace: true,
        state: { 
          customerId: customerId,
          groupId: preAssignedGroupId, 
          trainingCard: trainingCard,
        },
      });
    else if (component == $_("progressioni"))
      navigate(`/training-card?tab=progressions&trainingCardId=` + trainingCard.id + "&customerId=" + customerId + "&groupId=" + preAssignedGroupId, {
        replace: true,
        state: { 
          customerId: customerId,
          groupId: preAssignedGroupId, 
          trainingCard: trainingCard,
        },
      });
    else if (component == $_("allegati"))
      navigate(`/training-card?tab=attachments&trainingCardId=` + trainingCard.id + "&customerId=" + customerId + "&groupId=" + preAssignedGroupId, {
        replace: true,
        state: { 
          customerId: customerId,
          groupId: preAssignedGroupId, 
          trainingCard: trainingCard,
        },
      });    
  };

  const updateCardInfo = async (card) => {
    try {
      const res = await updateTrainingCardInfo({
        training_card_id: trainingCard.id,
        ...card,
      });
      for (let key in res) {
        if (!res[key]) res[key] = "";
      }
      trainingCard = res;
      return true;
    } catch (err) {
      console.log("updateCardInfo err", err);
      displayError(err);
      return false;
    }
  };

  const saveCardInfo = async (card) => {
    try {
      if (customerId) card.customer_id = customerId;
      if (card.fullscreen == "true")  card.fullscreen = 1;
      if (card.fullscreen == "false") card.fullscreen = 0;
      const res = await saveTrainingCardInfo(card);
      for (let key in res) {
        if (!res[key]) res[key] = "";
      }
      if (location && location.state && (location.state.workoutAssigned || location.state.duplicate)) {
        validateWorkouts($workouts);
        await saveTrainingCardWorkouts($workouts, res.id);
        if (location.state.workoutAssigned) {
          location.state.workoutAssigned = false;
        }
      }
      await tick();
      trainingCard = res;
      return true;
    } catch (err) {
      console.log("saveCardInfo err", err);
      displayError(err);
      return false;
    }
  };

  const saveWorkouts = async () => {
    try {
      const validatedWorkouts = validateWorkouts($workouts);
      await saveTrainingCardWorkouts(validatedWorkouts, trainingCard.id);
    } catch (err) {
      console.log("saveWorkouts error", err);
    }
  };

  const leavePage = () => {
    // Houston-2241
    progressionsPage.set(0);
    
    if (customerId) {
      navigate("/customer?tab=workouts", {
        state: { customerId },
      });
    } else if (trainingCard.is_assigned_to_group || trainingCard.assignedToGroups || searchParamsType == 'assignmentToGroups') {
      if ($group && $group.id) {
        navigate("group?tab=workouts&groupId=" + $group.id);
      } else {
        navigate("/team?tab=gruppi");
      }
    } else {
      navigate("/training-cards");
    }
  }

  // const logTrainingCardId = (text = '') => {
  //   try { 
  //     console.log('TCL | ' + text + ' | trainingCard.id => ', trainingCard.id);
  //   } catch (error) { 
  //     console.log('TCL | ' + text + ' | trainingCard.id ERROR => ', error);
  //   }
  // }

  onMount(async () => {
    $workouts = [];

    if (location.pathname == "/training-card" && searchParamsTab == "info") {
      activeTab = $_("info_piano");
      component = $_("info_piano");
    } 
    else if (location.pathname == "/training-card" && searchParamsTab == "workouts") {
      activeTab = $_("schede");
      component = $_("schede");
    } 
    else if (location.pathname == "/training-card" && searchParamsTab == "progressions") {
      activeTab = $_("progressioni");
      component = $_("progressioni");
    } 
    else if (location.pathname == "/training-card" && searchParamsTab == "attachments") {
      activeTab = $_("allegati");
      component = $_("allegati");
    } 
    else {
      activeTab = $_("info_piano");
      component = $_("info_piano");
    }

    navbar = document.getElementsByClassName("navbar__container")[0];
    
    if (location && location.state && location.state.trainingCard) {
      const { trainingCard: card } = location.state;
      trainingCard = {
        id                      : location.pathname == "/training-card" ? card.id     : null,
        name                    : card.name                    ? card.name            : "",
        image                   : card.image                   ? card.image           : "",
        image_thumbnail         : card.image_thumbnail         ? card.image_thumbnail : "",
        start                   : card.start                   ? card.start           : "",
        length                  : card.length                  ? card.length          : "",
        level                   : card.level                   ? card.level           : "",
        fullscreen              : card.fullscreen              ? card.fullscreen      : 0,
        note                    : card.note                    ? card.note            : "",
        is_assigned_to_group    : card.is_assigned_to_group    ? 1 : 0,
        is_assigned_to_customer : card.is_assigned_to_customer ? 1 : 0,
        is_template             : card.is_template             ? 1 : 0,
      };

      if (trainingCard.id) 
        await getWorkouts(trainingCard.id);

    } 
    else if (location && location.state && location.state.trainingCardId) {
      if (location.state.trainingCardInfo) {
        trainingCard = {
          fullscreen      : location.state.trainingCardInfo.fullscreen      ? location.state.trainingCardInfo.fullscreen      : 0,
          length          : location.state.trainingCardInfo.length          ? location.state.trainingCardInfo.length          : "",
          level           : location.state.trainingCardInfo.level           ? location.state.trainingCardInfo.level           : "",
          name            : location.state.trainingCardInfo.name            ? location.state.trainingCardInfo.name            : "",
          image           : location.state.trainingCardInfo.image           ? location.state.trainingCardInfo.image           : "",
          image_thumbnail : location.state.trainingCardInfo.image_thumbnail ? location.state.trainingCardInfo.image_thumbnail : "",
          note            : location.state.trainingCardInfo.note            ? location.state.trainingCardInfo.note            : "",
          start           : "",
          // is_assigned_to_group : location.state.trainingCardIsAssignedToGroup ? location.state.trainingCardIsAssignedToGroup : "",
        }
      }

      await getWorkouts(location.state.trainingCardId, true);
    } else {
      console.log('TCL | onMount ELSE! location.state => ', location.state);
    }

  });

  onDestroy(() => {
    if (isMobile) {
      if (navbar) {
        navbar.classList.remove("position-relative");
      }
      document
        .getElementsByClassName("main__content")[0]
        .classList.toggle("padding-navbar");
    }
    storedTrainingCard.set({});
  });
</script>

<style>
  .warning--rotate-screen {
    display: none;
  }
  .visible--landscape {
    display: block;
  }
  * :global(.warning) {
    background-color: #f9f9fb;
    opacity: 0.8;
  }
  * :global(.warning__icon) {
    font-size: 3rem;
  }
  .warning__message {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  @media screen and (min-width: 280px) and (max-width: 896px) and (orientation: portrait) {
    .warning--rotate-screen {
      display: block;
    }
    .visible--landscape {
      display: none;
    }
  }
  @media screen and (max-width: 896px) {
    .tc-container {
      width: 100%;
      max-width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      margin-right: auto;
      margin-left: auto;
    }
  }
</style>

<Dialog bind:this={exitDialog}>
  <ConfirmationDialog
    on:closeDialog={() => exitDialog.close()}
    on:onConfirmation={handleGoBack} 
    confirmationButtonLabel={$_('esci')}
    text={$_('sei_sicuro_di_voler_uscire')} />
</Dialog>

<Dialog 
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  bind:this={saveWorkoutsDialog}>
  <Title class="d-flex">
    <h5 class="bold pt-3" style="flex-grow: 1;">
        {$_('attenzione')}
    </h5>
    <IconButton
        class="material-icons"
        on:click={() => handleUndoChangeActiveTab()}
    >
        close
    </IconButton>
  </Title>
  <ConfirmationDialog
    on:saveAndContinue={(e) => {
      saveWorkouts();
      e.detail.tab = $_('info_piano');
      handleActiveTabChanged(e, true);
      saveWorkoutsDialog.close();
    }}
    on:continue={(e) => {
      e.detail.tab = $_('info_piano');
      handleActiveTabChanged(e, true);
      saveWorkoutsDialog.close();
    }}
    savingDialogMod={true}
    confirmationButtonLabel={$_('salva_modifiche')}
    text={$_('stai_andando_alla_sezione_info_piano')} />
</Dialog>

<Dialog 
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  bind:this={workoutsToAttachmentsDialog}>
  <Title class="d-flex">
    <h5 class="bold pt-3" style="flex-grow: 1;">
        {$_('attenzione')}
    </h5>
    <IconButton
        class="material-icons"
        on:click={() => handleUndoChangeActiveTab()}
    >
        close
    </IconButton>
  </Title>
  <ConfirmationDialog
    on:saveAndContinue={(e) => {
      saveWorkouts();
      e.detail.tab = $_('allegati');
      handleActiveTabChanged(e, true);
      workoutsToAttachmentsDialog.close();
    }}
    on:continue={(e) => {
      e.detail.tab = $_('allegati');
      handleActiveTabChanged(e, true);
      workoutsToAttachmentsDialog.close();
    }}
    savingDialogMod={true}
    confirmationButtonLabel={$_('salva_modifiche')}
    text={$_('stai_andando_alla_sezione_allegati')} />
</Dialog>

<TrainingCardHeader
on:activeTabChanged={handleActiveTabChanged}
on:error={() => displayError()}
on:goBack={() => exitDialog.open()} 
  {activeTab}
  {customerId}
  {preAssignedGroupId}
  {tabs}
  buttonVisible={trainingCardCanBeDeleted}
  id={trainingCard.id}
  name={$_('indietro')}
  tabNavigationEnabled={trainingCard.id} />

{#if activeTab == $_('schede')}
  <div class="container fluid warning--rotate-screen">
    <Card class="my-3 warning text-center">
      <Content class="py-4">
        <Icon class="material-icons warning__icon">screen_rotation</Icon>
        <p class="warning__message">{$_('ruota_schermo')}</p>
      </Content>
    </Card>
  </div>
{/if}

<div
  class={`container fluid tc-container ${activeTab == $_('schede') ? 'visible--landscape' : ''}`} data-recording-gdpr-safe>
  <MsgError {msgError} />

  <svelte:component
    this={options[component]}
    on:trainingCardCreated={goToWorkouts}
    on:trainingCardCreatedAndBackToList={goToWorkoutsList}
    on:error={() => displayError()}
    {...props} />

    <!-- on:refresh={() => getWorkouts(trainingCard.id)} -->

  {#if msgError}
    <div class="alert alert-danger fade show mt-2" role="alert">{msgError}</div>
  {/if}
</div>
