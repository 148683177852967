<script>
    import { _ } from "svelte-i18n";
    import { copyToClipboard } from "../../lib/utils";
    import { exercises, subscriptionInfo, user } from "../../lib/store";
    import { getBalanceReferral } from "../../services/billingServices";
    import { getCustomers } from "../../services/teamServices";
    import { getVodsAndPlaylistsQuantity, getVodDisclaimerStatus, setVodDisclaimerStatus } from "../../services/vodsServices";
    import { getTrainingCards } from "../../services/trainingCardServices";
    import { Icon } from "@smui/common";
    import { navigate } from "svelte-routing";
    import { onMount, tick } from "svelte";
    import { getExercises, getExercisesList } from "../../services/exercisesServices";
    import Button, { Label } from "@smui/button";
    import Card, { Content } from "@smui/card";
    import IconButton from "@smui/icon-button";
     
    export let state;

    let customExercises = [];
    let labels = {};
    let links = {};
    let originalState = JSON.parse(JSON.stringify(state));
    let referralCode = false;

    const initState = () => {
      state = 'off';
    }

    const countCustomers = async (x) => {
      const clients = await getCustomers(500, 1, "");
      state = clients.data.length < x ? originalState + '_helper' : 'off';
    }

    const countCustomExercises = async (x) => {
      $exercises.map(ex => {
          ex.base == 0 ? customExercises.push(ex) : '';
      });
      state = customExercises.length < x ? originalState + '_helper' : 'off';
    }

    const showVodDisclaimer = async () => {
      state = 'off';
      if ($subscriptionInfo && $subscriptionInfo.name === 'main') {
        const items = await getVodsAndPlaylistsQuantity();
        const res   = await getVodDisclaimerStatus();
        if (items.vods == 0) state = 'vod_helper';
        else if (res === 0)  state = 'vod_disclaimer_helper';
      }
    }

    const storeVodDisclaimerRead = async () => {
      await setVodDisclaimerStatus();
      state = 'off';
    }

    const countTrainingCards = async (x) => {
      let res;
      const cards = await getTrainingCards(500, 1, "", "desc", true);
      state = (cards.data.length < x) ? originalState + '_helper' : 'off';
      if (originalState == 'referral') {
        try {
          res = await getBalanceReferral();
          referralCode = res.referral_code;
        } catch (err) {
          console.log("balanceReferrals error", err);
        }
      }
    }

    const checkExercisesUpdate = async (x) => {
      const exercisesWithIsActive = $exercises.filter(e => e.is_active).length;
      state = exercisesWithIsActive === x ? originalState + '_helper' : 'off';
      if (exercisesWithIsActive === 0) {
        // await getExercises(true, 2);
        await getExercisesList(true);
      }
    }

    onMount(() => {
        initState();

        if ($subscriptionInfo.status == "trialing" && $user.language == "it") {
          switch (originalState) {
              case 'assigned_training_cards'   : countCustomers(5);          break;
              case 'attachments'               : countCustomExercises(9999); break;
              case 'calendar'                  : countCustomExercises(9999); break;
              case 'customer_profile'          : countCustomers(5);          break;
              case 'customer_subscriptions'    : countCustomExercises(7000); break;
              case 'deploy'                    : countCustomers(9999);       break;
              case 'exercises_list'            : countCustomExercises(5);    break;
              case 'exercises_update'          : checkExercisesUpdate(0);    break;
              case 'groups_list'               : countTrainingCards(100);    break;
              case 'histories'                 : countCustomers(5);          break;
              case 'new_customer'              : countCustomers(2);          break;
              case 'new_training_card'         : countTrainingCards(10);     break;
              case 'progresses'                : countCustomers(5);          break;
              case 'progressions_first_alert'  : countTrainingCards(1000);   break;
              case 'progressions_second_alert' : countTrainingCards(1000);   break;
              case 'referral'                  : countTrainingCards(100);    break;
              case 'team_list'                 : countCustomers(1);          break;
              case 'training_card_for_groups'  : countTrainingCards(1000);   break;
              case 'training_card_templates'   : countTrainingCards(10);     break;
              case 'vod_disclaimer'            : showVodDisclaimer();        break;
          }

          labels = {
              assigned_training_cards_helper   : $_("vuoi_sapere_come_creare_un_piano_di_allenamento_per_il_tuo_cliente"),
              attachments_helper               : "I tuoi clienti potranno vedere gli allegati del piano solo se hanno l'app aggiornata alla versione 4.12 o superiore.",
              calendar_helper                  : "I clienti possono vedere gli appuntamenti in app dalla versione 4.0. Scrivici in chat per saperne di più",
              customer_profile_helper          : $_("aggiungi_un_link_ad_una_cartella"),
              customer_subscriptions_helper    : "Vuoi sapere come utilizzare gli Abbonamenti per la gestione dei tuoi clienti?",
              deploy_helper                    : "REVOO non sarà disponibile per una <strong>manutenzione programmata mercoledì 14 giugno, dalle 4:30 alle 6:00 di mattina</strong>. Durante la manutenzione non sarà possibile utilizzare il software e i tuoi clienti non potranno accedere alla loro app di allenamento. Ricordati di salvare il tuo lavoro prima del nostro intervento.",
              exercises_list_helper            : $_("vuoi_sapere_come_creare_esercizi_personalizzati"),
              exercises_update_helper          : "Nuovo Aggiornamento! Per attivarlo Vai nella sezione Esercizi.",
              groups_list_helper               : $_("i_gruppi_ti_permettono_di_assegnare"),
              histories_helper                 : $_("vuoi_sapere_come_creare_un_anamnesi"),
              new_customer_helper              : $_("se_vuoi_aggiungere_un_cliente"),
              new_training_card_helper         : $_("vuoi_sapere_come_si_crea_una_scheda"),
              progresses_helper                : $_("vuoi_sapere_come_gestire_i_progressi"),
              progressions_first_alert_helper  : "Questa funzionalità è ancora in beta.<br/>Ti consigliamo di non modificare la scheda nella sezione precedente dopo aver creato le progressioni.",
              progressions_second_alert_helper : "Questa funzionalità è ancora in beta.<br/>Se dopo aver inserito i parametri delle progressioni, vai a modificare la scheda iniziale, torna in questa sezione per verificare che i dati delle progressioni siano completi.",
              referral_helper                  : "Porta un amico trainer in REVOO e risparmia sul tuo abbonamento. Ecco qui il tuo referral-code.",
              team_list_helper                 : $_("crea_un_cliente_di_prova"),
              training_card_for_groups_helper  : "Da oggi puoi gestire le schede direttamente dalla sezione Gruppi.",
              training_card_templates_helper   : $_("vuoi_sapere_come_si_crea_un_piano_di_allenamento"),
              vod_disclaimer_helper            : $_("vod_disclaimer"),
              vod_helper                       : $_("vuoi_sapere_come_condividere_video_allenamenti"),
          };

          links = {
              attachments_helper               : "https://how.revoo-app.com/knowledge/guida-funzionamento-allegati-piani-di-allenamento",
              assigned_training_cards_helper   : "https://how.revoo-app.com/knowledge/tutorial-progressi-cliente#allenamento",
              customer_profile_helper          : "https://how.revoo-app.com/knowledge/tutorial-progressi-cliente#link",
              customer_subscriptions_helper    : "https://how.revoo-app.com/knowledge/gestione-degli-abbonamenti-dei-cliente",
              exercises_list_helper            : "https://how.revoo-app.com/knowledge/tutorial-esercizi",
              exercises_update_helper          : false,
              groups_list_helper               : "https://how.revoo-app.com/knowledge/come-creare-i-tuoi-gruppi-di-allenamento",
              histories_helper                 : "https://how.revoo-app.com/knowledge/tutorial-progressi-cliente#anamnesi",
              new_customer_helper              : "https://how.revoo-app.com/knowledge/tutorial-nuovo-cliente",
              new_training_card_helper         : "https://how.revoo-app.com/knowledge/tutorial-creazione-schede",
              progresses_helper                : "https://how.revoo-app.com/knowledge/tutorial-progressi-cliente",
              progressions_first_alert_helper  : false,
              progressions_second_alert_helper : false,
              referral_helper                  : "https://how.revoo-app.com/knowledge/come-funziona-il-codice-referral-su-revoo",
              team_list_helper                 : false,
              training_card_for_groups_helper  : "/team?tab=gruppi",
              training_card_templates_helper   : "https://how.revoo-app.com/knowledge/tutorial-piano-di-allenamento",
              vod_helper                       : "https://how.revoo-app.com/knowledge/come-funziona-la-sezione-video-su-revoo",
              vod_disclaimer_helper            : "https://how.revoo-app.com/knowledge/come-funziona-la-sezione-video-su-revoo",
          }


        } else if ($subscriptionInfo.status != "trialing" && $user.language == "it") {
          switch (originalState) {
              case 'attachments'               : countCustomExercises(9999); break;  
              case 'calendar'                  : countCustomExercises(9999); break;
              case 'customer_subscriptions'    : countCustomExercises(7000); break;
              case 'deploy'                    : countCustomers(9999);       break;
              case 'exercises_update'          : checkExercisesUpdate(0);    break;
              case 'groups_list'               : countTrainingCards(100);    break;
              case 'progressions_first_alert'  : countTrainingCards(100);    break;
              case 'progressions_second_alert' : countTrainingCards(100);    break;
              case 'referral'                  : countTrainingCards(100);    break;
              case 'training_card_for_groups'  : countTrainingCards(1000);   break;
              case 'vod_disclaimer'            : showVodDisclaimer();        break;
          }

          labels = {
              attachments_helper               : "I tuoi clienti potranno vedere gli allegati del piano solo se hanno l'app aggiornata alla versione 4.12 o superiore.",
              calendar_helper                  : "I clienti possono vedere gli appuntamenti in app dalla versione 4.0. Scrivici in chat per saperne di più",
              customer_subscriptions_helper    : "Vuoi sapere come utilizzare gli Abbonamenti per la gestione dei tuoi clienti?",
              deploy_helper                    : "REVOO non sarà disponibile per una <strong>manutenzione programmata mercoledì 14 giugno, dalle 4:30 alle 6:00 di mattina</strong>. Durante la manutenzione non sarà possibile utilizzare il software e i tuoi clienti non potranno accedere alla loro app di allenamento. Ricordati di salvare il tuo lavoro prima del nostro intervento.",
              exercises_update_helper          : "Nuovo Aggiornamento! Per attivarlo Vai nella sezione Esercizi.",
              groups_list_helper               : $_("i_gruppi_ti_permettono_di_assegnare"),
              progressions_first_alert_helper  : "Questa funzionalità è ancora in beta.<br/><br/>Ti consigliamo di non modificare la scheda nella sezione precedente dopo aver creato le progressioni.",
              progressions_second_alert_helper : "Questa funzionalità è ancora in beta.<br/><br/>Se dopo aver inserito i parametri delle progressioni, vai a modificare la scheda iniziale, torna in questa sezione per verificare che i dati delle progressioni siano completi.",
              referral_helper                  : $_('porta_un_amico_trainer'),
              training_card_for_groups_helper  : "Da oggi puoi gestire le schede direttamente dalla sezione Gruppi.",
              vod_disclaimer_helper            : $_("vod_disclaimer"),
              vod_helper                       : $_("vuoi_sapere_come_condividere_video_allenamenti"),
          };

          links = {
              attachments_helper               : "https://how.revoo-app.com/knowledge/guida-funzionamento-allegati-piani-di-allenamento",  
              customer_subscriptions_helper    : "https://how.revoo-app.com/knowledge/gestione-degli-abbonamenti-dei-clienti",
              exercises_update_helper          : false,
              groups_list_helper               : "https://how.revoo-app.com/knowledge/come-creare-i-tuoi-gruppi-di-allenamento",
              progressions_first_alert_helper  : false,
              progressions_second_alert_helper : false,
              referral_helper                  : "https://how.revoo-app.com/knowledge/come-funziona-il-codice-referral-su-revoo",
              training_card_for_groups_helper  : "/team?tab=gruppi",
              vod_helper                       : "https://how.revoo-app.com/knowledge/come-funziona-la-sezione-video-su-revoo",
              vod_disclaimer_helper            : "https://how.revoo-app.com/knowledge/come-funziona-la-sezione-video-su-revoo",
          }
        }
    });
  
  </script>
  
  <style>
    .importantInfoBold {
      font-weight: bold;
      font-size: 1.4em;
    }

    * :global(.mdc-card) {
      color: black;
      background-color: white;
      border: 2px solid var(--icon-active);
    }

    * :global(.mdc-card__yellow) {
      border: 2px solid var(--icon-notVeryActive);
    }

    * :global(.banner-button.helper-button) {
      color: white;
      background-color: var(--icon-active);
      border: 0;
    }

    * :global(.banner-button.helper-button__yellow) {
      background-color: var(--icon-notVeryActive);
    }
    
    @media screen and (max-width: 367px) {
      * :global(.btn.banner-button.helper-button) {
        height: auto;
      }
    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      .importantInfoBold {
        font-size: 1.15em;
      }
    }
  </style>

  <div class:d-none={state === 'off'}>
    <Card class="{state == 'training_card_for_groups_helper' || state == 'deploy_helper' ? `mdc-card__yellow` : ``} {state == 'exercises_update_helper' ? `mdc-card__yellow` : ``}">
      <Content
        class="d-flex justify-content-between align-items-center flex-column
          flex-lg-row">
        <span class="
          {state != 'referral_helper' 
            ? state === 'vod_disclaimer_helper' 
              ? `col-lg-8` 
              : state === 'deploy_helper' 
                ? `col-lg-12` 
                : `col-lg-9` 
            : `col-lg-6`
          } py-2 text-center text-md-left">{@html labels[state]}</span>
        {#if state == 'referral_helper'}
          <div class="col-lg-3 d-flex justify-content-center">
            <div class="row pb-3 pb-lg-0">
              {#if referralCode}
                <span class="col-12 d-flex align-items-center">
                  <span class="importantInfoBold">{referralCode}</span>
                  <IconButton
                    on:click={() => copyToClipboard(referralCode)}
                    class="material-icons">
                    content_copy
                  </IconButton>
                </span>
              {:else}
                <span class="col-12">{`${$_('non_hai_codici_sconto_attivi')}`}</span>
              {/if}
            </div>
          </div>
        {/if}
        {#if links[state]}
          <div class="{state === 'vod_disclaimer_helper' ? `col-lg-4 d-flex justify-content-around` : `col-lg-3 flexy`}">
            <Button
              class="btn banner-button helper-button {state == 'training_card_for_groups_helper' ? `helper-button__yellow` : ``}"
              variant="unelevated"
              on:click={() => {
                if (state == 'training_card_for_groups_helper') {
                  navigate(links[state]);
                } else if (state != 'referral_helper') { 
                  window.open(links[state], '_blank');
                } else {
                  navigate('/referral-code');
                }
              }}>
              <Icon class="material-icons-outlined">{state == 'training_card_for_groups_helper' ? `groups` : `info`}</Icon>
              <Label class="textUppercase">{state == 'training_card_for_groups_helper' ? 'Vai ai gruppi' : $_('scopri_di_piu')}</Label>
            </Button>
            {#if state === 'vod_disclaimer_helper'}
              <Button
                class="btn banner-button helper-button"
                variant="unelevated"
                on:click={storeVodDisclaimerRead}>
                <Label class="textUppercase">{$_('ok_capito')}</Label>
              </Button>
            {/if}
          </div>
        {/if}
      </Content>
    </Card>
  </div>
  