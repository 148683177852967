<script>
  import { _ } from "../../../../services/i18n";
  import { activeSubSection } from "../../../../lib/store";
  import { addVideo } from "../../../../lib/utils";
  import { createEventDispatcher, onDestroy } from "svelte";
  import { Embed } from "@vime/svelte";
  import { Icon } from "@smui/common";
  import Button, { Label } from "@smui/button";
  import Dialog from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  
  export let item;
  export let thumbnail;
  export let triggerCloseVideo;
  export let isItemAssignment = false;

  const dispatch = createEventDispatcher();

  // $: console.log('VodVideoUploader | item', item);
  // $: console.log('VodVideoUploader | newVideoDropped', newVideoDropped);

  let video;
  let videoDialog;
  let error;
  let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1; 
  let play = false;
  let isDeletingVideo = false;
  let newVideoDropped = false;

  $: if ((item.vimeo_id != "" && !isDeletingVideo) || (item.is_playlist)) {
    video = item.name;
  }

  $: if (triggerCloseVideo && videoDialog) {
    videoDialog.close();
    triggerCloseVideo = false;
  }

  const handleDeleteVideoButtonClicked = (e) => {
    isDeletingVideo = true;
    thumbnail = null;
    video = null;
    dispatch("itemRemoved");
  }

  const onInputChange = (e) => {
    const file = e.target.files[0];
    if (!file || file.size > 5000000000) {
      error = $_("la_dimensione_massima_consentita_è_di", {
        values: { dimension: 5000 },
      });
      setTimeout(() => {
        error = "";
      }, 3000);
      return;
    }
    newVideoDropped = true;
    updateVideo(file);
  };

  const updateVideo = (file) => {
    isDeletingVideo = false;
    video = file;
    dispatch("itemSelected", {
      video: file,
    });
    if (!isMobile) {
      addVideo(file);
    } else {
      thumbnail = 'https://s3.eu-central-1.amazonaws.com/train.revoo-app/PlaceHolder/selected_video_placeholder.png'; // Aggiungere immagine placeholder
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    updateVideo(file);
  };

  const closeVideoDialog = () => {
    play = false; 
    videoDialog.close();
  };

  onDestroy(() => {
    videoDialog.close();
    item = {};
    video = {};
  });
</script>

<style>
  * :global(.embed-responsive-item) {
    background-color: #000;
  }
  * :global(.bringToFront) {
    align-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 9;
  }
  .customUploader {
    background-color: #f3f3f3;
    border-radius: 10px;
    flex-direction: column;
    height: 13em;
    position: relative;
    text-align: center;
    width: 13em;
  }
  .customFileInput {
    left: 0px;
    opacity: 0;
    position: absolute;
    z-index: 5;
  }
  .imageWrapper {
    align-items: center;
    border-radius: 10px;
    display: flex;
    height: 13em;
    overflow: hidden;
    position: relative;
    width: 13em;
  }
  * :global(.imageWrapper img) {
    height: 100% !important;
    width: auto !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  * :global(.imageWrapper img.loadedThumbnail) {
    height: 100% !important;
    width: auto !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .maxLabel {
    opacity: 0.5;
    font-weight: 600;
    font-size: 0.8rem;
  }
  .indications {
    opacity: 0.8;
    font-weight: 600;
  }
  .layer {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: pointer;
  }
  .layer:hover {
    opacity: 1;
    transition: all 0.3s ease;
  }
  .videoPreview {
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px;
  }
  * :global(.customColor) {
    background-color: var(--mdc-theme-on-primary, #fff);
    color: #000;
  }
  * :global(.customColor.mdc-button--outlined:not(:disabled)) {
    background-color: transparent;
    border-color: var(--mdc-theme-on-primary, #fff);
    color: var(--mdc-theme-on-primary, #fff);
  }
  .close-icon-wrapper {
    position: absolute;
    right: 38px;
    transform: translate(20px, 16px);
    background-color: rgba(255, 255, 255, 0.99);
    border-radius: 50px;
    z-index: 2;
  }
</style>

<Dialog bind:this={videoDialog} class="dialog--big no-click-backdrop">
  <div class="close-icon-wrapper">
    <IconButton class="material-icons" on:click={closeVideoDialog}>
      close
    </IconButton>
  </div>
  {#if play}
    <div class="embed-responsive embed-responsive-1by1">
      <Embed
        class="embed-responsive-item"
        embedSrc="https://player.vimeo.com/video/{item.vimeo_id}"
        origin="https://www.vimeo.com"
        mediaTitle="{item.name}"
        controls={0}
      />
    </div>
  {/if}
</Dialog>

<div class="d-flex flex-column">
  <div class="customUploader" on:drop={handleDrop}>
    <input
      class="customUploader d-flex customFileInput"
      type="file"
      id="file"
      accept="video/*"
      on:change={onInputChange} 
      disabled={$activeSubSection == $_('progressioni')} />
    {#if !video}
      <div class="customUploader d-flex">
        <div class="row">
          <div class="col-12 my-4">
            <span class="maxLabel">{`${$_('max')} 5 GB`}</span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 indications">
            <span>{$_('seleziona_video')}</span>
            <br />
            <span>{$_('o_trascina')}</span>
            <br />
            <Icon
              role="button"
              tabindex="1"
              class="material-icons mdc-text-field__icon
                mdc-text-field__icon--trailing pt-2 pb-3 primary">
              ondemand_video
            </Icon>
          </div>
        </div>
      </div>
    {:else}
      <div class={`imageWrapper ${thumbnail ? 'bg--grey' : 'bg--black'}`}>
        <div class="layer">
          <div class="bringToFront">
            {#if item && item.vimeo_id != "" && !newVideoDropped}
              <Button
                class="btn mt-3 customColor"
                variant="unelevated"
                target="_blank"
                on:click={e => {
                  e.stopPropagation();
                  play = true;
                  videoDialog.open();
                }}>
                <Label>{$_('visualizza')}</Label>
              </Button>
            {/if}
            {#if isItemAssignment}
              <Button
                class="btn mt-3 customColor"
                variant="outlined"
                target="_blank"
                on:click={() => {
                  dispatch('changeItemSelection');                  
                }}>
                <Label>{$_('sostituisci')}</Label>
              </Button>
            {:else}
              <Button
                class="btn mt-3 customColor"
                variant="outlined"
                target="_blank"
                on:click={handleDeleteVideoButtonClicked}>
                <Label>{$_('elimina')}</Label>
              </Button>
            {/if}
          </div>
        </div>
        {#if !thumbnail || thumbnail.length == 0}
          <div
            style="
              height: 100%;
              max-width: 100%;
              margin: 0 auto;"
            id="printVideo" />
        {:else}<img loading="lazy" class="loadedThumbnail" src={thumbnail} alt="video" />{/if}
      </div>
    {/if}
  </div>
  <span class="pt-2 text-danger pr-2">{error ? error : ''}</span>
</div>
