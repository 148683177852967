<script>
  import { _ } from "../../services/i18n";
  import { checkEmail } from "../Team/TeamValidation/teamValidation";
  import { createEventDispatcher, onMount } from "svelte";
  import { phoneNumberValid } from "../../lib/utils";
  import { user } from "../../lib/store";
  import Button, { Label, Icon } from "@smui/button";
  import CC from "../../lib/countryCode";
  import FormMessage from "../ReusableLayouts/FormMessage.svelte";
  import Select, { Option } from "@smui/select";
  import Switch from "@smui/switch";
  import Textfield from "@smui/textfield";

  const dispatch = createEventDispatcher();

  let bio = $user.bio || "";
  let business_nickname = $user.bussiness_nickname || "";
  let countries_code = [];
  let directContact = $user.directContact || false;
  let email = $user.email;
  let emailInvalid = false;
  let formValid = false;
  let facebook = $user.facebook || "";
  let instagram = $user.instagram || "";
  let msgError;
  let name = $user.first_name;
  let nameInvalid = false;
  let online = $user.online || false;
  let phone = $user.phone || "";
  let phoneNumberInvalid = false;
  let prefix = $user.country_code || "39";
  let showPhoneNumber = $user.show_phone_number && ($user.show_phone_number === 1 || $user.show_phone_number === true) ? true : false;
  let surname = $user.last_name;
  let surnameInvalid = false;

  for (const [key, value] of Object.entries(CC)) {
    countries_code.push(value);
  }

  $: phoneNumberInvalid = !phoneNumberValid(phone, prefix == "39");
  $: formValid = name.length > 2 && surname.length > 1 && email && !emailInvalid && !phoneNumberInvalid;
  $: if (email) emailInvalid = !checkEmail(email);
  $: if (name) nameInvalid = name.length < 3;
  $: if (surname) surnameInvalid = surname.length < 2;

  const submit = async () => {
    dispatch("saveInfo", {
      name,
      surname,
      business_nickname,
      email,
      facebook,
      instagram,
      prefix,
      phone,
      show_phone_number: showPhoneNumber ? 1 : 0,
      online: online ? 1 : 0,
      directContact: directContact ? 1 : 0,
      bio,
    });
  };
</script>

<style>
  .label {
    text-align: end;
    align-self: center;
    font-weight: 600;
    opacity: 0.8;
  }
  .pushToEnd {
    justify-content: flex-end;
  }
  * :global(.mdc-text-field--textarea) {
    width: inherit;
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__thumb) {
    border-color: var(--primary);
  }
  * :global(.mdc-switch.mdc-switch--checked .mdc-switch__track) {
    border-color: var(--primary);
  }
</style>

<div class="row">
  <div class="col-4 label">{`${$_('nome')} *`}</div>
  <div class="col-8">
    <Textfield
      variant="outlined"
      class={`textbox w100 my-2 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
      bind:value={name}
      type="text" />
    {#if nameInvalid}
      <FormMessage>
        {$_('il_nome_deve_essere_di_almeno_3_caratteri')}
      </FormMessage>
    {/if}
  </div>
  <div class="col-4 label">{`${$_('cognome')} *`}</div>
  <div class="col-8">
    <Textfield
      variant="outlined"
      class={`textbox w100 my-2 ${surnameInvalid ? 'mdc-text-field--invalid' : ''}`}
      bind:invalid={surnameInvalid}
      bind:value={surname}
      type="text" />
    {#if surnameInvalid}
      <FormMessage>
        {$_('il_cognome_deve_essere_di_almeno_3_caratteri')}
      </FormMessage>
    {/if}
  </div>
  <div class="col-4 label">{$_('business_nickname')}</div>
  <div class="col-8">
    <Textfield
      variant="outlined"
      withTrailingIcon
      bind:value={business_nickname}
      type="text"
      class="textbox w100 my-2">
      <Icon
        role="button"
        tabindex="1"
        data-toggle="tooltip"
        data-placement="top"
        title={$_('il_tuo_brand_commerciale')}
        class="material-icons mdc-text-field__icon
          mdc-text-field__icon--trailing">
        info
      </Icon>
    </Textfield>
  </div>
  <div class="col-4 label">Facebook</div>
  <div class="col-8">
    <Textfield
      bind:value={facebook}
      label="https://www.facebook.com/nomeutente"
      variant="outlined"
      type="text"
      class="textbox w100 my-2" />
  </div>
  <div class="col-4 label">Instagram</div>
  <div class="col-8">
    <Textfield
      bind:value={instagram}
      label="nomeutente"
      variant="outlined"
      type="text"
      class="textbox w100 my-2" />
  </div>
  <div class="col-4 label">{`${$_('email')} *`}</div>
  <div class="col-8">
    <Textfield
      bind:value={email}
      variant="outlined"
      type="text"
      class={`textbox w100 my-2 ${emailInvalid ? 'mdc-text-field--invalid' : ''}`} />
    {#if emailInvalid}
      <FormMessage>{$_('email_non_valida')}</FormMessage>
    {/if}
  </div>
  <div class="col-4 label">{$_('numero_cellulare')} *</div>
  <div class="col-5 col-sm-4 col-md-3">
    <Select
      enhanced
      variant="outlined"
      class="w100"
      bind:value={prefix}
      label="">
      <Option value="" />
      {#each countries_code as country_code}
        <Option value={country_code.code} selected={prefix === country_code}>
          {` +${country_code.code} ${country_code.iso2}`}
        </Option>
      {/each}
    </Select>
  </div>
  <div class="col-3 d-md-none d-lg-none d-xl-none"></div>
  <div class="col-4 d-md-none d-lg-none d-xl-none"></div>
  <div class="col-8 col-md-5">
    <Textfield
      bind:value={phone}
      bind:invalid={phoneNumberInvalid}
      variant="outlined"
      type="tel"
      class="textbox w100 my-2 ${phoneNumberInvalid ? 'mdc-text-field--invalid' : ``}"
    />
    {#if phoneNumberInvalid}
      <FormMessage textAlign="left">
        {name ? $_('formato_del_numero_di_telefono_non_corretto') : $_('il_numero_di_telefono_é_obbligatorio')}
      </FormMessage>
    {/if}
  </div>
  <div class="col-4 pt-2 label">{$_('mostra_numero_di_telefono_ai_clienti')}</div>
  <div class="col-8 pt-2">
    <Switch bind:checked={showPhoneNumber} />
  </div>
</div>

<hr />

<div class="row">
  <div class="col-4 label">{$_('coaching_online')}</div>
  <div class="col-8">
    <Switch bind:checked={online} />
  </div>
  <div class="col-4 label">{$_('coaching_diretto')}</div>
  <div class="col-8">
    <Switch bind:checked={directContact} />
  </div>
  <div class="col-4 label">{$_('bio')}</div>
  <div class="col-8">
    <Textfield
      textarea
      variant="outlined"
      class={`textbox w100 my-2 ${nameInvalid ? 'mdc-text-field--invalid' : ''}`}
      bind:value={bio}
      type="text" />
  </div>
</div>

{#if msgError}
  <div class="alert alert-danger fade show" role="alert">{msgError}</div>
{/if}

<div class="row pushToEnd">
  <Button
    class="btn ml-5 mr-3"
    variant="unelevated"
    on:click={submit}
    disabled={!formValid}>
    <Label>{$_('salva')}</Label>
  </Button>
</div>
