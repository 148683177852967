<script>
  import IconButton from "@smui/icon-button";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  export let totalPages = 1;
  //page as Array
  export let activePage = 1;

  let mobile;
  const pageRange = 10;

  $: mobile = window.screen.width <= 480;

  const switchPage = (page) => {
    activePage = page;
    dispatch("page", {
      page,
    });
  };
</script>

<style>
  .page.active {
    text-decoration: underline;
    font-weight: 700;
  }
  .page.noactive {
    opacity: 0.5;
  }
  .mouse-pointer:hover {
    cursor: pointer;
  }
  .page {
    padding: 4px 4px;
  }
  * :global(.mdc-icon-button) {
    padding: 0px 0px;
  }
</style>

<div class="flexy my-3">
  <IconButton
    class="material-icons"
    on:click={() => {
      if (activePage - 1 >= 0) {
        switchPage(activePage - 1);
      }
    }}>
    keyboard_arrow_left
  </IconButton>
  {#if mobile}
    {#if activePage >= 1}<span>...</span>{/if}
    <div class="page mouse-pointer" class:active={true}>{activePage + 1}</div>
    {#if activePage + 1 < totalPages}<span>...</span>{/if}
  {:else}
    {#each Array(totalPages) as _, currentPage}
      {#if currentPage > activePage - pageRange && currentPage < activePage + pageRange}
        <div
          class:active={currentPage === activePage}
          class:noactive={currentPage !== activePage}
          class="page mouse-pointer"
          on:click={() => switchPage(currentPage)}>
          {currentPage + 1}
        </div>
      {:else if currentPage == activePage - pageRange || currentPage == activePage + pageRange}
        <div
          class:active={currentPage === activePage}
          class:noactive={currentPage !== activePage}
          class="page mouse-pointer"
          on:click={() => switchPage(currentPage)}>
          ...
        </div>
      {/if}
    {/each}
  {/if}
  <IconButton
    class="material-icons"
    on:click={() => {
      if (activePage + 1 < totalPages) {
        switchPage(activePage + 1);
      }
    }}>
    keyboard_arrow_right
  </IconButton>
</div>
